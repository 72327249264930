import { joinPath } from '@eb/path-utils';
import { replace } from 'react-router-redux';
import { getInitialPath } from '../utils/routes';
import { addToWaitlist as addToWaitlistApi } from '../api/waitlist';
import { BASE_URL, WAITLIST_CONFIRMATION_PATH } from '../constants';
import { transformWaitlistEntryData } from '../api/transformations/order';
import { saveWaitlistEntryAction } from './order';

export const MARK_WAITLIST_FULL = 'markWaitlistFull';

const _markWaitlistFull = () => ({
    type: MARK_WAITLIST_FULL,
});

const _handleOnAddToWaitlistError = (dispatch, error) => {
    if (
        error &&
        error.extraData &&
        error.extraData.parsedError &&
        error.extraData.parsedError.error === 'WAITLIST_FULL'
    ) {
        dispatch(_markWaitlistFull());
    }

    throw error;
};

export const goToPageBeforeWaitlistPage = () => (dispatch, getState) => {
    const state = getState();

    dispatch(replace(joinPath(getInitialPath(state))));
};

export const addToWaitlist = (buyerData) => async (dispatch, getState) => {
    const state = getState();
    const formattedBuyerData = transformWaitlistEntryData(buyerData);

    try {
        await addToWaitlistApi(state.event.id, formattedBuyerData);
        await dispatch(saveWaitlistEntryAction(formattedBuyerData));

        dispatch(replace(joinPath(BASE_URL, WAITLIST_CONFIRMATION_PATH)));
    } catch (e) {
        _handleOnAddToWaitlistError(dispatch, e);
    }
};
