import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import { Field } from 'redux-form';
import { FormField } from '@eb/eds-form-field';
import { shouldDisplayError as defaultShouldDisplayError } from './lib';

import ValidatedElement from './ValidatedElement';
import ValidatedFormField from './ValidatedFormField';

export default class ValidationFormField extends PureComponent {
    static propTypes = {
        /**
         * Used by redux-form to populate the form object in the store.
         * If child input does not have a name, will default to this.
         */
        name: PropTypes.string.isRequired,

        /**
         * Function to determine whether we should
         * display an error given the input state. Has a sensible default.
         */
        shouldDisplayError: PropTypes.func,

        /**
         * Determines whether it should use or not a FormField wrapper.
         */
        v2: PropTypes.bool,

        /**
         * FormField propTypes
         * @prop {string} label - Text to be displayed in label
         * @prop {string} htmlFor - Id of the input to which the label is mapped; optional
         * @prop {bool} required - Whether this field is required
         * @prop {bool} hideLabel - Whether the label should be displayed or hidden
         */
        ...omit(FormField.propTypes, ['label', 'children']),
    };

    static contextTypes = {
        _reduxForm: PropTypes.object,
    };

    static defaultProps = {
        shouldDisplayError: defaultShouldDisplayError,
    };

    render() {
        const { v2, ...rest } = this.props;

        let { submitFailed } = this.props;

        const component = v2 ? ValidatedElement : ValidatedFormField;

        //Assign submitFailed so it can be passed to Field as a prop,
        //but only if it is not already defined to prevent overwriting.
        if (submitFailed === undefined && this.context._reduxForm) {
            submitFailed = this.context._reduxForm.submitFailed;
        }

        return (
            <Field
                {...rest}
                component={component}
                submitFailed={submitFailed}
            />
        );
    }
}
