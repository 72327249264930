import 'regenerator-runtime/runtime';
import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { Router, createMemoryHistory } from 'react-router';
import {
    push,
    routerMiddleware,
    syncHistoryWithStore,
} from 'react-router-redux';

import { updateHistory } from '@eb/react-router-history';
import { configureStore } from '@eb/redux-configure-store';
import {
    middleware as analyticsMiddleware,
    GA_SETTINGS_PROP_TYPE,
} from '@eb/site-analytics';

import { formAnalytics } from './middleware/formAnalytics';
import reducer from './reducers';
import {
    initializeApp as initializeAppAction,
    inflateAndResetApp as inflateAndResetAppAction,
} from './actions/initialize';
import { initializeEvent as initializeEventAction } from './actions/event';
import {
    ONE_CHECKOUT,
    ORGANIZATION_VERTICALS,
    SERVER_ERRORS_SHAPE,
    WAITING_ROOM_SHAPE,
    ENV_SHAPE_PROP_TYPE,
    REFUND_POLICY_CODE_SHAPE,
    TEAM_SETTINGS_SHAPE,
    THEME_SETTINGS_PROP,
} from './constants';
import getRoutes from './routes';
import { setLanguage } from '@eb/i18n';
import { initializePageAnalytics } from './utils/analytics';
import { getInitialPath } from './utils/routes';
import { popSession } from './session';
import { getValidThemeSettings } from './utils/validation/themeValidator';

export default class CheckoutWidgetApp extends React.Component {
    static propTypes = {
        /**
         * Environment related props
         */
        env: ENV_SHAPE_PROP_TYPE.isRequired,

        /**
         * Passed down to the App level Page component and needed for postMessage to communicate with
         * the organizer site. Required for any site other than /checkout-external
         */
        widgetParentUrl: PropTypes.string,

        /**
         * Waiting room related props
         */
        waitingRoom: WAITING_ROOM_SHAPE.isRequired,

        /**
         * An array of delivery methods
         */
        deliveryMethods: PropTypes.arrayOf(PropTypes.string),

        /**
         * Square API URL. Passed down to the App level Page component and needed for postMessage to communicate with
         * the square.
         */
        squareApiHost: PropTypes.string,

        /**
         * Eventbrite market account on Square. Passed down to the App level Page component and needed for postMessage
         * to communicate with the square.
         */
        squareMarketAccount: PropTypes.string,

        /**
         * Feature Flags
         */
        featureFlags: PropTypes.shape({
            enable3DSecure2: PropTypes.bool,
            enableEBMarketingOptIn: PropTypes.bool,
            enableEBMarketingOptInImplicit: PropTypes.bool,
            enableNonceBraintreePayments: PropTypes.bool,
            enableRefundFeeRetentionPolicy: PropTypes.bool,
            enableSquareCreditCardCheckout: PropTypes.bool,
            force3DSecure2VerifyCard: PropTypes.bool,
            launchEmbeddedCheckoutAttendeeLoginOnThirdPartyWebsite:
                PropTypes.bool,
            launchEmbeddedCheckoutRetryOnPlaceError: PropTypes.bool,
            launchSupportMultipleFacebookPixels: PropTypes.bool,
            shouldHideSeriesStartTime: PropTypes.bool,
        }),

        /**
         * Grylls configuration information
         */
        grylls: PropTypes.shape({
            trackingData: PropTypes.object,
            options: PropTypes.shape({
                enabled: PropTypes.bool,
                debugging: PropTypes.bool,
            }),
        }),
        /**
         * True if Registration terminology is enabled
         */
        isRegistrationEvent: PropTypes.bool,
        /**
         * True if Event is a Digital Content event
         */
        hasDigitalContent: PropTypes.bool,
        /**
         * Custom URL for viewing a user's tickets after completion of the order
         */
        ticketCallbackUrl: PropTypes.string,
        /**
         * Google Analytics session and user data
         */
        gaSettings: GA_SETTINGS_PROP_TYPE,
        /**
         * Errors sent from the server to be rendered on load.
         * For now, the server will send a max of 1 error at a time.
         */
        errors: SERVER_ERRORS_SHAPE,
        /**
         * Invite token to be able to bypass protected events
         */
        inviteToken: PropTypes.string,
        /**
         * Invite email to be able to prefill the email field
         */
        inviteEmail: PropTypes.string,
        /**
         * Campaign token to be able to bypass invite-only events
         */
        campaignToken: PropTypes.string,
        /**
         * Team token to be able to bypass group registration team selection screens
         */
        teamToken: PropTypes.string,
        /**
         * Team settings to be able to bypass group registration team selection screens
         */
        teamSettings: TEAM_SETTINGS_SHAPE,
        /**
         * Referrer ID: the id of the referrer to properly track affiliate codes and compensate the referrer appropriately
         */
        referrerId: PropTypes.string,
        /**
         * Promo code from request to be used to pass down to children of repeating events
         */
        requestPromoCode: PropTypes.string,
        /**
         * IOS profile, customize checkout widget for iOS devices.
         */
        useIosProfile: PropTypes.bool,
        /**
         * Android profile, customize checkout widget for Android devices.
         */
        useAndroidProfile: PropTypes.bool,
        /**
         * Whether the Postal Code should be asked when purchasing
         */
        countryWithPostalCode: PropTypes.bool,
        /**
         * Theme override data for customization
         */
        themeSettings: THEME_SETTINGS_PROP,
        /**
         * Whether we show organizer/organization marketing opt in checkbox
         */
        showOrgMarketingOptIn: PropTypes.bool,
        /**
         * Refund policy code
         */
        refundPolicyCode: REFUND_POLICY_CODE_SHAPE,
        /**
         * Trigger the PayIn flow
         */
        isPayinsFlow: PropTypes.bool,
        /**
         * Maximum amount allowed in payins
         */
        maxAllowedPayin: PropTypes.number,
        /*
         * Amount that will be sent to organizer on next payout.
         */
        refundableBalance: PropTypes.number,
        /*
         * Whether the country is within GDPR regulations.
         */
        isGDPRCountry: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        /**
         * Set up the language that is going to be used by the i18n library provided by the backend
         * as soon as the App is initialized. There's no need to rebuild the whole App
         * as we are always using lazy strings.
         */
        setLanguage(props.env.localeInfo.locale);

        // Track history in memory since we don't want the widget affecting host browser history
        const historyManagement = createMemoryHistory();

        const middleware = [
            thunk,
            routerMiddleware(historyManagement),
            analyticsMiddleware(props, () => ({
                category: ONE_CHECKOUT,
                dimensions: {
                    eventId: props.event.id,
                    organizationVertical:
                        ORGANIZATION_VERTICALS[
                            get(props, 'event.organization.vertical')
                        ],
                },
            })),
            formAnalytics,
        ];

        /*
         Load and clear any previously saved Redux state.
         We remove it immediately after loading because we want to restore the application state
         only once.
        */
        this._stateFromPreviousSession = popSession();
        this._store = configureStore({
            reducer,
            initialState: this._stateFromPreviousSession,
            middleware,
        });
        this._history = syncHistoryWithStore(historyManagement, this._store);

        initializePageAnalytics({
            historyManagement,
            store: this._store,
            appProps: props,
        });

        updateHistory(this._history, props.request);
    }

    componentWillMount() {
        const initialData = this._getInitialData();

        this._store.dispatch(initializeAppAction(initialData));

        // If there is a "cached" session, and the user performs an action
        // such as changing the widget language, we only need to
        // get updated event data, but we should not reinitialize
        // all of the form data such as promo codes and ticket selection
        if (this._stateFromPreviousSession) {
            this._store.dispatch(initializeEventAction(initialData));
        } else {
            this._store.dispatch(inflateAndResetAppAction(initialData));
        }
    }

    componentDidMount() {
        if (this._stateFromPreviousSession) {
            // This is due to https://github.com/reactjs/react-router-redux/issues/534
            // syncHistoryWithStore fails to extract the correct location from the store
            // We need here to manually push it
            const locationFromPreviousSession = get(
                this._stateFromPreviousSession,
                'routing.locationBeforeTransitions.pathname',
            );
            if (locationFromPreviousSession) {
                this._store.dispatch(push(locationFromPreviousSession));
            }
        } else {
            const state = this._store.getState();
            const initialPath = getInitialPath(state);
            this._store.dispatch(push(initialPath));
        }
    }

    componentWillReceiveProps({ request }) {
        updateHistory(this._history, request);
    }

    _getInitialData() {
        const state = this._store.getState();

        const {
            affiliateCode,
            defaultUserCountry,
            deferredPaymentDueDays,
            env: {
                isMobile,
                currencyFormat,
                locales,
                localeInfo: {
                    locale,
                    // every locale can use a different twitter account and
                    // facebook account, so we need to use them in order to build
                    // share messages
                    twitter_handle: twitterHandle,
                    facebook_locale: facebookLocale,
                    facebook_page: facebookPage,
                    country_code: envCountryCode,
                },
                serverUrl,
                signupNoReferrerUrl,
                ebDomain,
            },
            errors,
            event,
            existingOrderId,
            featureFlags,
            guestId,
            grylls: {
                options: gryllsOptions,
                trackingData: gryllsTrackingData,
            },
            inviteToken,
            inviteEmail,
            campaignToken,
            isEventbriteTLD,
            hasDigitalContent,
            isModal,
            isProd,
            isRegistrationEvent: isRegEvent,
            countryWithPostalCode,
            refundPolicyCode,
            referrerId,
            request,
            requestPromoCode,
            squareApiHost,
            squareMarketAccount,
            teamSettings,
            teamToken,
            ticketAvailabilityInfo,
            tickets,
            user,
            waitingRoom,
            waitlistCode,
            widgetParentUrl,
            showOrgMarketingOptIn,
            isPayinsFlow,
            maxAllowedPayin,
            refundableBalance,
            isGDPRCountry,
        } = this.props;

        const ticketCallbackUrl = this.props.ticketCallbackUrl
            ? this.props.ticketCallbackUrl
            : get(
                  request,
                  'params.ticket_callback_url',
                  this.props.ticketCallbackUrl,
              );

        const themeSettings = getValidThemeSettings(
            this.props.initialThemeSettings,
        );
        const useIosProfile =
            get(state, 'app.useIosProfile') || this.props.useIosProfile;
        const useAndroidProfile =
            get(state, 'app.useAndroidProfile') || this.props.useAndroidProfile;

        const facebookOptions = {
            ...this.props.facebookOptions,
            locale: facebookLocale,
            page: facebookPage,
        };

        const payins = {
            isPayinsFlow,
            maxAllowedPayin,
            refundableBalance,
        };

        const initialData = {
            affiliateCode,
            currencyFormat,
            defaultUserCountry,
            deferredPaymentDueDays,
            ebDomain,
            envCountryCode,
            errors,
            event,
            existingOrderId,
            facebookOptions,
            featureFlags,
            gryllsOptions,
            gryllsTrackingData,
            guestId,
            inviteToken,
            inviteEmail,
            campaignToken,
            hasDigitalContent,
            isEventbriteTLD,
            isMobile,
            isModal,
            isProd,
            isRegEvent,
            locale,
            locales,
            countryWithPostalCode,
            payins,
            refundPolicyCode,
            referrerId,
            requestPromoCode,
            useIosProfile,
            useAndroidProfile,
            serverUrl,
            signupNoReferrerUrl,
            squareApiHost,
            squareMarketAccount,
            teamSettings,
            teamToken,
            themeSettings,
            ticketAvailabilityInfo,
            ticketCallbackUrl,
            tickets,
            twitterHandle,
            user,
            waitingRoom,
            waitlistCode,
            widgetParentUrl,
            showOrgMarketingOptIn,
            isGDPRCountry,
        };

        return initialData;
    }

    render() {
        return (
            <Provider store={this._store}>
                <Router history={this._history} routes={getRoutes()} />
            </Provider>
        );
    }
}
