import {
    INITIALIZE_EVENT,
    SAVE_EVENT_SHIPPING_RATES,
    UPDATE_EVENT_PRIVACY_SETTING,
    UPDATE_AVAILABLE_INSTALLMENTS,
} from '../actions/event';
import { OrderActionType } from '../actions/order';
import { MARK_WAITLIST_FULL } from '../actions/waitlist';

export const eventReducer = (state = {}, action) => {
    switch (action.type) {
        case INITIALIZE_EVENT:
            // waitlistSettings is not always returned by the backend,
            // in the case it is not, we need to delete it from the state first
            // Otherwise we would use the one from the previous state and it could be from a different event.
            const { waitlistSettings, ...stateWithoutWaitlistSettings } = state;

            return {
                ...stateWithoutWaitlistSettings,
                ...action.payload.event,
            };

        case OrderActionType.saveEventShippingSettings:
            return {
                ...state,
                ...action.payload,
            };

        case MARK_WAITLIST_FULL:
            return {
                ...state,
                waitlistSettings: {
                    ...state.waitlistSettings,
                    full: true,
                },
            };

        case UPDATE_EVENT_PRIVACY_SETTING:
            return {
                ...state,
                privacySetting: action.payload,
            };

        case UPDATE_AVAILABLE_INSTALLMENTS:
            return {
                ...state,
                installments: action.payload,
            };

        case SAVE_EVENT_SHIPPING_RATES:
            return {
                ...state,
                shippingRates: action.payload.shipping_rates,
            };

        default:
            return state;
    }
};
