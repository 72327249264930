import { ShippingSettings, DeliveryMethodsByCountry } from '../models/event';
import { getDeliveryMethodsAfterCheckingShippingBackup } from '../utils/deliveryMethod';
import { OrderActionType, OrderAction } from '../actions/order';
import { WithOrderNamespace, OrderNamespace } from '../state/order';

const getAttendeesWithTicketDeliveryMethod = (
    attendees: Array<{
        ticketClass?: {
            deliveryMethods: DeliveryMethodsByCountry;
        };
    }>,
    shippingSettings: ShippingSettings,
) =>
    attendees.map(({ ticketClass = {}, ...otherProps }) => {
        const { deliveryMethods = {}, ...otherTicketClassProps } = ticketClass;

        return {
            ...otherProps,
            ticketClass: {
                ...otherTicketClassProps,
                deliveryMethods: getDeliveryMethodsAfterCheckingShippingBackup(
                    deliveryMethods,
                    shippingSettings,
                ),
            },
        };
    });

export const orderReducer = (
    state: WithOrderNamespace['order'] = {},
    action: OrderAction,
): WithOrderNamespace['order'] => {
    switch (action.type) {
        case OrderActionType.resetOrder:
            return {};

        case OrderActionType.updateOrder:
            return {
                ...state,
                ...action.payload,
            };

        case OrderActionType.updateOrderAttendees:
            return {
                ...state,
                attendees: action.payload,
            };

        case OrderActionType.updateOrderEmail:
            return {
                ...state,
                email: action.payload.email,
            };

        case OrderActionType.updateOrderStatus:
            return {
                ...state,
                status: action.payload,
            };

        case OrderActionType.updateRedirectInstructions:
            return {
                ...state,
                redirectInstructions: action.payload,
            };

        case OrderActionType.updateAvailablePaymentMethods:
            return {
                ...state,
                availablePaymentMethods: action.payload.paymentOptions,
            };

        case OrderActionType.updateBraintreeClientToken:
            return action.payload
                ? {
                      ...state,
                      braintreeClientToken: action.payload,
                  }
                : state;

        case OrderActionType.saveWaitlistEntry:
            return {
                ...state,
                waitlistEntry: action.payload,
            };

        case OrderActionType.setOrderUpdating:
            return {
                ...state,
                isUpdating: action.payload,
            };

        case OrderActionType.setCanRetrySubmit:
            return {
                ...state,
                canRetrySubmit: action.payload,
            };

        case OrderActionType.setOrderInstallments:
            return {
                ...state,
                displayInstallments: action.payload,
            };

        case OrderActionType.removeOrderInstallments:
            return {
                ...state,
                displayInstallments: undefined,
            };

        case OrderActionType.saveEventShippingSettings:
            return {
                ...state,
                attendees: getAttendeesWithTicketDeliveryMethod(
                    'attendees' in state ? state.attendees : [],
                    action.payload.shippingSettings,
                ),
            };

        case OrderActionType.taxPoliciesLoadComplete:
            return {
                ...state,
                taxIdName: action.payload,
            };

        case OrderActionType.updateTaxExemptionMessage:
            return {
                ...state,
                taxExemptionData: action.payload,
            };

        case OrderActionType.setHasVirtualIncentivesErrors:
            return {
                ...state,
                hasVirtualIncentivesErrors: action.payload,
            };

        default:
            return state;
    }
};

const getOrderId = (orderState: OrderNamespace) => orderState.id;
const getOrderCosts = (orderState: OrderNamespace) => orderState.costs;
const getRedirectInstructions = (orderState: OrderNamespace) =>
    orderState.redirectInstructions;
const getAvailablePaymentMethods = (orderState: OrderNamespace) =>
    orderState.availablePaymentMethods;
// This might look silly, but it is used also at checkoutWidget's actions/order.js
const hasMoreThanOnePaymentMethod = (orderState: OrderNamespace) =>
    orderState.availablePaymentMethods
        ? orderState.availablePaymentMethods.length > 1
        : false;

// TODO: Rename to hasAvailablePaymentMethods
const haveAvailablePaymentMethods = (orderState: OrderNamespace) =>
    !!orderState.availablePaymentMethods;

const hasNoPaymentMethodsAvailable = (orderState: OrderNamespace) =>
    orderState.availablePaymentMethods
        ? orderState.availablePaymentMethods.length === 0
        : true;

const hasOnlyOnePaymentMethodAndInstrumentType = (
    orderState: OrderNamespace,
) => {
    if (!orderState.availablePaymentMethods) {
        return false;
    }

    return orderState.availablePaymentMethods.length === 1
        ? orderState.availablePaymentMethods[0].instrumentTypes.length === 1
        : false;
};
// Again, used also at checkoutWidget's actions/order.js
// Careful: doesn't checks for non-existance of orderState
const getFirstAvailableInstrumentType = (orderState: OrderNamespace) =>
    orderState.availablePaymentMethods
        ? orderState.availablePaymentMethods[0].instrumentTypes[0]
        : null;

export const selectors = {
    getAvailablePaymentMethods,
    getFirstAvailableInstrumentType,
    getOrderCosts,
    getOrderId,
    getRedirectInstructions,
    hasMoreThanOnePaymentMethod,
    hasNoPaymentMethodsAvailable,
    haveAvailablePaymentMethods,
    hasOnlyOnePaymentMethodAndInstrumentType,
};
