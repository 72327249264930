import { replace } from 'react-router-redux';
import { reset } from 'redux-form';
import { joinPath } from '@eb/path-utils';

import {
    BASE_URL,
    FORMS_TO_RESET,
    PASSWORD_EXPIRED,
    PROTECTED_PATH,
    STATUS_PATH,
    TICKETS_WITH_CAPACITY_WAITLIST_PATH,
    UNLOCK_TICKETS_PATH,
} from '../constants';
import { getStatusForEvent } from '../utils/status';
import { getVariantPromoCode } from '../utils/promoCodes';
import { getTicketsSelectionPagePath } from '../utils/routes';
import { getEventAndTickets as getEventAndTicketsApi } from '../api/event';
import {
    EVENT_ALL_TICKETS_REQUIRE_UNLOCK_STATUS,
    EVENT_SOLD_OUT_STATUS,
    EVENT_SOLD_OUT_IN_TICKET_SELECTION_STATUS,
    EVENT_SOLD_OUT_WITH_CAPACITY_WAITLIST_STATUS,
} from '../containers/status/constants';
import { getIsEventProtected } from '../selectors/event';
import { notifyParentBackToTicketSelection as notifyParentBackToTicketSelectionAction } from './notifyParent';
import {
    initializeEvent as initializeEventAction,
    updateEventPrivacySetting as updateEventPrivacySettingAction,
} from './event';
import { resetOrderAction } from './order';
import { initializeTickets as initializeTicketsAction } from './tickets';
import {
    resetGuestToken as resetGuestTokenAction,
    resetOrderToken as resetOrderTokenAction,
} from './tokens';
import { initializeAppAction } from './app';
import { initializeWaitingRoom as initializeWaitingRoomAction } from './waitingRoom';
import { initializeTeamSettings as initializeTeamSettingsAction } from './groupRegistration';
import { initializeUserAction } from './user';

export const _initializeEventAndTickets = (initialData, error) => async (
    dispatch,
) => {
    // XXX: The getEventAndTickets endpoint needs to be updated to return new waiting room data.
    // Right now waitingRoom is empty so we need to check.
    //
    // If we decide users don't have to re-enter waiting room if they navigate back to ticket
    // selection from within the iframe (as opposed to refreshing the organizer's page), we can
    // leave this check.
    const { waitingRoom, teamSettings, teamToken } = initialData;

    if (waitingRoom) {
        dispatch(initializeWaitingRoomAction(waitingRoom));
    }

    if (teamSettings && teamToken) {
        dispatch(initializeTeamSettingsAction({ teamSettings, teamToken }));
    }

    dispatch(initializeUserAction(initialData));
    dispatch(initializeEventAction(initialData));
    dispatch(initializeTicketsAction(initialData, error));
};

const _resetForms = () => (dispatch) => {
    FORMS_TO_RESET.forEach((formName) => dispatch(reset(formName)));
};

export const initializeApp = (initialData) => async (dispatch) => {
    // first, save all the initial data into app store. That allow us to reset
    // the app state and start over whenever we want
    await dispatch(initializeAppAction(initialData));
};

export const inflateAndResetApp = (initialData) => (dispatch) => {
    // then, dispatch all those actions that make the app has all the needed data
    dispatch(_initializeEventAndTickets(initialData));
    dispatch(_resetForms());
};

/**
 * After event and tickets have been updating in state, check if we have to route to
 * a status page.
 *
 * @return  {Promise}  Promise for checking event status
 */
export const _checkEventStatus = () => (dispatch, getState) => {
    const { tickets, event, waitingRoom } = getState();

    const { isLive, wasInWaitingRoom, isActivatedForEvent } = waitingRoom;

    let eventStatus = getStatusForEvent(event, tickets);

    // TODO: Replace this routing logic with a call to getInitialPath from
    // utils/routes instead.
    // Delaying this for now to avoid any refactors to the waiting room flow.
    if (getIsEventProtected(getState())) {
        dispatch(updateEventPrivacySettingAction(PASSWORD_EXPIRED));
        return dispatch(replace(joinPath(BASE_URL, PROTECTED_PATH)));
    }
    if (eventStatus) {
        if (wasInWaitingRoom && eventStatus === EVENT_SOLD_OUT_STATUS) {
            eventStatus = EVENT_SOLD_OUT_IN_TICKET_SELECTION_STATUS;
        }
        if (eventStatus === EVENT_SOLD_OUT_WITH_CAPACITY_WAITLIST_STATUS) {
            return dispatch(
                replace(
                    joinPath(BASE_URL, TICKETS_WITH_CAPACITY_WAITLIST_PATH),
                ),
            );
        }
        if (eventStatus === EVENT_ALL_TICKETS_REQUIRE_UNLOCK_STATUS) {
            return dispatch(replace(joinPath(BASE_URL, UNLOCK_TICKETS_PATH)));
        }
        return dispatch(replace(joinPath(BASE_URL, STATUS_PATH, eventStatus)));
    }

    // NOTE: Re-visit when turning the FF for waiting room back on. Check for
    // isActivatedForEvent is consistent with what we do when routing users to the
    // initial path at componentDidMount in App.js
    if (!isActivatedForEvent || isLive) {
        return dispatch(replace(getTicketsSelectionPagePath(getState())));
    }

    return Promise.resolve();
};

/**
 * Fetches updated event status and ticket inventory, updates `event` and `tickets` in state,
 * and routes to a status page if necessary.
 *
 * @param   {eventId}  Event Id for fetch, default as null for exsiting eventId in state.
 * @return  {Promise}  Promise for refreshing event and tickets
 */
export const refreshEventAndTickets = (eventId, error) => (
    dispatch,
    getState,
) => {
    const {
        app: { waitlistCode, inviteToken, campaignToken, requestPromoCode },
        event: { id: currentEventId },
        tickets: { ticketsById },
    } = getState();

    let promoCode = requestPromoCode;

    const eventIdToFetch = eventId || currentEventId;

    if (!eventId) {
        promoCode = getVariantPromoCode(ticketsById);
    }

    // Fetch updated event and tickets data, then dispatch event and tickets actions.
    // This is for times we just want to update inventory without clearing form state/
    // performing other actions in _resetForms()
    return getEventAndTicketsApi(
        eventIdToFetch,
        promoCode,
        waitlistCode,
        inviteToken,
        campaignToken,
    )
        .then(({ response }) =>
            _initializeEventAndTickets(response, error)(dispatch, getState),
        )
        .then(() => dispatch(_checkEventStatus()));
};

/**
 * Resets event status and ticket inventory, resets `event` and `tickets` in state,
 * by removing any promocode / access code (if not defined in the URL), ticket selection...
 * DOES NOT perform any routing
 */
export const resetEventAndTickets = () => (dispatch, getState) => {
    const {
        app: { inviteToken, campaignToken, waitlistCode, requestPromoCode },
        event: { id: currentEventId },
    } = getState();

    // Fetch updated event and tickets data, then dispatch event and tickets actions.
    return getEventAndTicketsApi(
        currentEventId,
        requestPromoCode,
        waitlistCode,
        inviteToken,
        campaignToken,
    ).then(({ response }) =>
        _initializeEventAndTickets(response)(dispatch, getState),
    );
};

/**
 * Navigates to the ticket selection screen, dispatches an action to refresh the
 * inventory for an event, and clears data from the store as needed.
 *
 * This action is intended as a "reset" to be used in multiple situations, including:
 * - Returning to ticket selection after completing an order
 * - Returning to ticket selection after abandoning an order
 * - Returning to ticket selection after an order is started and the timer runs out
 * - Advancing to ticket selection from waiting room
 *
 * When we do this, we need to make sure we refresh the inventory, clear any existing
 * order data, and clear the order and guest tokens.
 *
 * The waiting room token should not be reset here. This token is used by the queue
 * server to determine whether a client should see the waiting room or ticket
 * selection, so resetting it would cause users to lose their place in line.
 *
 * @param   {eventId}  The id of the event to fetch, only pass in for series event navigation.
 * @return  {Promise}  Promise for navigating to ticket selection
 */
export const navigateToTicketSelection = (eventId) => (dispatch) =>
    dispatch(notifyParentBackToTicketSelectionAction())
        .then(() => dispatch(refreshEventAndTickets(eventId)))
        .then(() => dispatch(_resetForms()))
        .then(() => dispatch(resetOrderAction()))
        .then(() => dispatch(resetGuestTokenAction()))
        .then(() => dispatch(resetOrderTokenAction()));
