import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';

import { Calendar } from '@eb/eds-iconography';
import { Cart } from '@eb/eds-iconography';
import { Icon } from '@eb/eds-icon';
import { OrderSummary } from '@eb/eds-order-summary';
import { formatMinorMoney, formatMajorMoney } from '@eb/intl';

import gettext from '@eb/gettext';
import { getQuantityBySelectedDeliveryMethod } from '../../utils/deliveryMethod';
import { getEventTaxName } from '../../utils/event';
import { formatTicketsForOrderSummary } from '../../utils/tickets';
import { getTotalDiscountingVirtualIncentives } from '../../utils/virtualIncentives';
import {
    getTicketsAvailableOnSale,
    hasDonationTickets,
} from '../ticketSelection/utils/ticketUtils';
import {
    getBreakdownItems,
    getSummaryItems,
    getRefundPolicyContent,
    getTicketsCosts,
    getTicketsCostsByOrder,
} from './utils';
import {
    ATTENDEE_PAYER,
    CAPACITY_WAITLIST,
    ORDER_COSTS_SHAPE,
    REFUND_POLICY_PROP_SHAPE,
    SELECTED_TICKETS_SHAPE,
    TAX_COMPONENT_BASE_FEES_TAX_INCLUDABLE,
    TAX_PROCESSING_FEE_COMPONENT_NAME,
    TAX_SERVICE_FEE_COMPONENT_NAME,
    VIRTUAL_INCENTIVE_SHAPE,
    ORDER_SUMMARY_TITLE,
} from '../../constants';
import './pane.scss';

const EmptyPaneContent = ({ iconType, text }) => {
    let ctaText;

    if (text) {
        ctaText = (
            <div
                className="eds-text-bs eds-text-color--ui-500 eds-l-mar-top-2"
                data-spec="pane-empty-cart-cta-text"
            >
                {text}
            </div>
        );
    }

    return (
        <div
            className="eds-align--center ticket-page--empty-cart-container"
            data-spec="pane-empty-cart-container"
        >
            <Icon
                __containerClassName="ticket-page--empty-cart-image"
                type={iconType}
                size="medium"
                color="grey-300"
            />
            {ctaText}
        </div>
    );
};

const _ticketHasGtsOnTax = (ticket) => ticket.hasGtsTax;

const _ticketsIncludeTaxOnFees = (tickets) =>
    tickets
        .flatMap((ticket) => ticket.taxComponents)
        .filter(
            (ticket) =>
                ticket &&
                (ticket.base === TAX_COMPONENT_BASE_FEES_TAX_INCLUDABLE ||
                    ([
                        TAX_SERVICE_FEE_COMPONENT_NAME,
                        TAX_PROCESSING_FEE_COMPONENT_NAME,
                    ].includes(ticket.groupName) &&
                        ticket.payer == ATTENDEE_PAYER)),
        );

const _ticketsIncludeGtsTax = (tickets) =>
    tickets.some(
        (ticket) => ticket.tax && ticket.tax.value === 0 && ticket.hasGtsTax,
    );

export const _getTaxName = ({ ticketsById, selectedTicketsOnSale }) => {
    // Not need to check on the selected tickets because the GTS tax info is shared among all the ticket classes
    const hasGtsTax = Object.values(ticketsById).some(_ticketHasGtsOnTax);
    const customEventTaxName = getEventTaxName(selectedTicketsOnSale);

    if (hasGtsTax) {
        return gettext('Sales Tax');
    } else if (customEventTaxName) {
        return customEventTaxName;
    }
    return gettext('Tax');
};

// TODO: Resolve duplicate logic in `components/reserved/utils.js:getTotalCostInfo`
// This can be refactored into a selector in `selectors/tickets.js`
const _getTotalCostInfo = (tickets) => {
    let totalCostInfo = null;

    if (_ticketsIncludeGtsTax(tickets)) {
        totalCostInfo = gettext('Incl. sales tax');
    } else if (!isEmpty(_ticketsIncludeTaxOnFees(tickets))) {
        totalCostInfo = gettext('Price includes tax');
    }

    return totalCostInfo;
};

// TODO: Move to selectors/order.js.  This is derived state logic that does not belong inside components
// By moving to a selector we'll be able to directly test this unit, rather than testing through the component
const _getTotalCostInfoByOrder = (orderCosts) => {
    let totalCostInfoByOrder;

    if (orderCosts.hasGtsTax && !orderCosts.displayTax) {
        totalCostInfoByOrder = gettext('Incl. sales tax');
    }

    return totalCostInfoByOrder;
};

const _getShippingFees = (tickets) => {
    const shippingFees = Object.values(tickets)
        .filter(({ displayShippingFee }) => {
            return displayShippingFee?.value;
        })
        .map((ticket) => ticket.displayShippingFee);

    return shippingFees;
};

const getItemizedPaneContent = ({
    eventImageSrc,
    totalCostInfo,
    items,
    breakdownItems = [],
    totalCost,
    installments,
    currency,
    currencyFormat,
    refundPolicyProps,
    selectedVirtualIncentives,
    unformattedOrderCost,
}) => {
    const paneContentClassName = classNames(
        'pane-order-summary',
        'eds-g-cell',
        'eds-g-cell-12-12',
        'eds-g-cell-sw-10-12',
        'eds-g-offset-sw-1-12',
        'eds-l-mar-bot-10',
        'eds-l-pad-bot-10',
        'eds-l-sn-mar-top-12',
        'eds-l-sm-mar-top-12',
        'eds-l-sw-mar-top-12',
        'eds-l-sn-pad-hor-4',
        'eds-l-sm-pad-hor-6',
        'eds-l-sw-pad-hor-6',
        {
            'eds-l-mar-top-6': eventImageSrc,
            'eds-l-mar-top-20': !eventImageSrc,
        },
    );
    const isPurchaseFinanced = installments
        ? installments.numberOfInstallments > 1
        : false;

    if (!isEmpty(selectedVirtualIncentives)) {
        totalCost = formatMajorMoney(
            getTotalDiscountingVirtualIncentives(
                unformattedOrderCost,
                selectedVirtualIncentives,
            ),
            currency,
            currencyFormat,
        );
    }

    return (
        <div className="ticket-page--order-summary-container">
            <div
                className={paneContentClassName}
                data-spec="pane-order-summary"
            >
                <OrderSummary
                    breakdownItems={breakdownItems}
                    items={items}
                    title={ORDER_SUMMARY_TITLE.toString()}
                    totalContent={
                        isPurchaseFinanced
                            ? gettext('Total Purchase Financed')
                            : gettext('Total')
                    }
                    totalCostInfo={totalCostInfo}
                    totalValue={
                        installments
                            ? formatMinorMoney(
                                  installments.totalCostWithInstallments,
                                  currency,
                                  currencyFormat,
                              )
                            : totalCost
                    }
                />
                {getRefundPolicyContent(refundPolicyProps)}
            </div>
        </div>
    );
};

const PaneContent = ({
    currency,
    currencyFormat,
    hasFeeBreakdown,
    enableRefundFeeRetentionPolicy = false,
    eventImageSrc,
    isEventWithShippingFee,
    installments,
    isAddAttendeesOrder,
    isPaymentView,
    isTransferredOrder,
    isRegEvent,
    isSeriesParentPage,
    isWaitlist,
    orderCosts,
    refundPolicyProps,
    selectedDeliveryMethods,
    selectedTickets,
    selectedVirtualIncentives,
    shippingRates,
    ticketGroups,
    ticketsById,
    totalCost,
    waitlistTicketId,
    unformattedOrderCost,
}) => {
    const paneIconType = isSeriesParentPage ? <Calendar /> : <Cart />;

    let paneContent = (
        <EmptyPaneContent
            iconType={paneIconType}
            text={isSeriesParentPage ? gettext('SELECT AN EVENT') : null}
        />
    );

    // TODO: this is a temporary fix to avoid displaying order summary when it's an old
    // shipping event due to discrepancies in the values. It should be cleaned up after
    // meeting with pricing team and getting to know more about types of taxes.
    if (isEventWithShippingFee && !isPaymentView) {
        return paneContent;
    }

    if (isWaitlist) {
        let waitlistEntryName = gettext('Waitlist Entry');

        if (
            waitlistTicketId !== CAPACITY_WAITLIST &&
            ticketsById[waitlistTicketId]
        ) {
            const ticketName = ticketsById[waitlistTicketId].name;

            waitlistEntryName = gettext(`Waitlist Entry for ${ticketName}`);
        }

        const waitlistSelectedTickets = [
            {
                id: waitlistTicketId,
                name: waitlistEntryName,
                selectedQuantity: 1,
            },
        ];

        const items = getSummaryItems({
            selectedTickets: waitlistSelectedTickets,
            eventCurrency: currency,
            currencyFormat,
        });

        paneContent = getItemizedPaneContent({
            eventImageSrc,
            items,
            totalCost,
            installments,
            currency,
            currencyFormat,
            refundPolicyProps,
            selectedVirtualIncentives,
            unformattedOrderCost,
        });
    } else {
        //If we have an existing order, we consider it has been created previously by an administrator or is a transfer, and therefore will be able to access all tickets, not only the on sale ones
        const selectedTicketsOnSale =
            isAddAttendeesOrder || isTransferredOrder
                ? selectedTickets
                : getTicketsAvailableOnSale(selectedTickets);
        const hasDonationAndCost =
            hasDonationTickets(selectedTicketsOnSale) && orderCosts;

        if (selectedTicketsOnSale.length) {
            const flattenedTickets = formatTicketsForOrderSummary(
                selectedTicketsOnSale,
            );

            // UX decision to sort tickets with admission first and then addons
            const sortedTickets = sortBy(flattenedTickets, [
                (ticket) => ticket.category === 'add_on',
            ]);

            // Not need to check on the selected tickets because the GTS tax info is shared among all the ticket classes
            const eventTaxName = _getTaxName({
                ticketsById,
                selectedTicketsOnSale,
            });

            // Need to obtain how many tickets were selected by every delivery method
            const quantitySelectedDeliveryMethods = getQuantityBySelectedDeliveryMethod(
                ticketGroups,
                selectedDeliveryMethods,
            );

            // Get costs from the tickets selected (before user clicks Checkout)
            let ticketsCosts = getTicketsCosts(sortedTickets, hasFeeBreakdown);

            // NB: This is just a string, not data! Rename to totalCostItemLabel
            let totalCostInfo = _getTotalCostInfo(selectedTickets);

            const hasOrderCosts = !isEmpty(orderCosts);

            // If there is an order, get the costs from the order (after user clicks Checkout)
            if (hasDonationAndCost || hasOrderCosts) {
                ticketsCosts = getTicketsCostsByOrder(
                    orderCosts,
                    hasFeeBreakdown,
                );
                // NB: This is just a string! Rename to totalCostItemLabel
                totalCostInfo = _getTotalCostInfoByOrder(orderCosts);
            }

            // When we have an existing order, the payment is processed offline, and no fees are collected: we should not diplay any to the user.
            if (isAddAttendeesOrder) {
                delete ticketsCosts.fees;
            }

            const shippingFees = _getShippingFees(selectedTickets);

            const items = getSummaryItems({
                selectedTickets: sortedTickets,
                eventCurrency: currency,
                currencyFormat,
                shouldHandleFreeTickets: isAddAttendeesOrder,
            });

            const breakdownItems = getBreakdownItems({
                ...ticketsCosts,
                currency,
                currencyFormat,
                enableRefundFeeRetentionPolicy,
                eventTaxName,
                installments,
                isRegEvent,
                isTransferredOrder,
                orderCosts,
                quantitySelectedDeliveryMethods,
                selectedDeliveryMethods,
                shippingFees,
                shippingRates,
                selectedVirtualIncentives,
                unformattedOrderCost,
            });

            paneContent = getItemizedPaneContent({
                eventImageSrc,
                totalCostInfo,
                items,
                breakdownItems,
                totalCost,
                installments,
                currency,
                currencyFormat,
                refundPolicyProps,
                selectedVirtualIncentives,
                unformattedOrderCost,
            });
        }
    }

    return paneContent;
};

export default class Pane extends React.Component {
    static propTypes = {
        currency: PropTypes.string,
        currencyFormat: PropTypes.string,
        /** Flag to show retention fee policy message */
        enableRefundFeeRetentionPolicy: PropTypes.bool,
        eventImageSrc: PropTypes.string,
        eventTitle: PropTypes.string,
        /**
         * Boolean to indicate if we have an existing order, and thus we need to:
         *  - take into account that free tickets can have a amount > 0
         *  - include the fees and taxes in the ticket price
         */
        isAddAttendeesOrder: PropTypes.bool,
        /**
         * Boolean to indicate if there is fee breakdown
         */
        hasFeeBreakdown: PropTypes.bool,
        installments: PropTypes.shape({
            numberOfInstallments: PropTypes.number.isRequired,
            pricePerInstallment: PropTypes.number.isRequired,
            totalCostWithInstallments: PropTypes.number.isRequired,
        }),
        /** Whether the event uses registration terminology */
        isRegEvent: PropTypes.bool,
        isSeriesParentPage: PropTypes.bool,
        isWaitlist: PropTypes.bool,
        /** The cost object from order v3 api */
        orderCosts: ORDER_COSTS_SHAPE,
        refundPolicyProps: REFUND_POLICY_PROP_SHAPE,
        /**
         * Array of selected delivery methods to show in order summary
         */
        selectedDeliveryMethods: PropTypes.arrayOf(PropTypes.string),
        selectedTickets: PropTypes.arrayOf(SELECTED_TICKETS_SHAPE),
        /**
         * Array of tickets grouped by shared delivery method options
         */
        ticketGroups: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object))
            .isRequired,
        totalCost: PropTypes.string,
        /**
         * Ticket ID for the associated wait list
         */
        waitlistTicketId: PropTypes.string,
        selectedVirtualIncentives: PropTypes.arrayOf(VIRTUAL_INCENTIVE_SHAPE),
    };

    // TODO: Add isRequired or defaultProps for all props
    // static defaultProps = {};

    render() {
        const { eventTitle, eventImageSrc } = this.props;

        let eventImage = null;

        if (eventImageSrc) {
            eventImage = (
                <div className="ticket-page--event-image-container eds-show-up-mn">
                    <img
                        className="ticket-page--event-image eds-max-img"
                        alt={eventTitle}
                        src={eventImageSrc}
                        data-spec="ticket-page-image"
                    />
                    <div className="ticket-page--event-image-padding-fix" />
                </div>
            );
        }

        return (
            <aside
                id="order-summary"
                aria-label={ORDER_SUMMARY_TITLE.toString()}
                className="eds-collapsible-pane-layout__content checkout-widget__ticket-page--pane-content"
            >
                {eventImage}
                <PaneContent {...this.props} />
            </aside>
        );
    }
}
