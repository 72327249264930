import { Icon } from '@eb/eds-icon';
import { PasswordIconButton } from '@eb/eds-password';
import { PaymentIcon } from '@eb/eds-payment-icon';

export const TYPE_TEXT = 'text';
export const TYPE_PASSWORD = 'password';
export const TYPE_EMAIL = 'email';
export const TYPE_URL = 'url';
export const TYPE_TEL = 'tel';
export const TYPE_SEARCH = 'search';
export const TYPE_DATE = 'date';
export const TYPE_NUMBER = 'number';

export const TYPES = [
    TYPE_TEXT,
    TYPE_PASSWORD,
    TYPE_EMAIL,
    TYPE_URL,
    TYPE_TEL,
    TYPE_SEARCH,
    TYPE_DATE,
    TYPE_NUMBER,
];

export const CHILD_COMPONENT_WHITELIST = [
    Icon,
    PaymentIcon,
    PasswordIconButton,
];
