/*eslint-disable*/
!(function (e) {
    var i = {};
    function o(n) {
        if (i[n]) return i[n].exports;
        var t = (i[n] = { i: n, l: !1, exports: {} });
        return e[n].call(t.exports, t, t.exports, o), (t.l = !0), t.exports;
    }
    (o.m = e),
        (o.c = i),
        (o.d = function (n, t, e) {
            o.o(n, t) ||
                Object.defineProperty(n, t, { enumerable: !0, get: e });
        }),
        (o.r = function (n) {
            'undefined' != typeof Symbol &&
                Symbol.toStringTag &&
                Object.defineProperty(n, Symbol.toStringTag, {
                    value: 'Module',
                }),
                Object.defineProperty(n, 'e', { value: !0 });
        }),
        (o.t = function (t, n) {
            if ((1 & n && (t = o(t)), 8 & n)) return t;
            if (4 & n && 'object' == typeof t && t && t.e) return t;
            var e = Object.create(null);
            if (
                (o.r(e),
                Object.defineProperty(e, 'default', {
                    enumerable: !0,
                    value: t,
                }),
                2 & n && 'string' != typeof t)
            )
                for (var i in t)
                    o.d(
                        e,
                        i,
                        function (n) {
                            return t[n];
                        }.bind(null, i),
                    );
            return e;
        }),
        (o.n = function (n) {
            var t =
                n && n.e
                    ? function () {
                          return n.default;
                      }
                    : function () {
                          return n;
                      };
            return o.d(t, 'a', t), t;
        }),
        (o.o = function (n, t) {
            return Object.prototype.hasOwnProperty.call(n, t);
        }),
        (o.p = ''),
        o((o.s = 6));
})([
    ,
    ,
    function (n, t, e) {
        'use strict';
        Object.defineProperty(t, 'e', { value: !0 });
        var i,
            o = 'https://connect.squareup.com',
            r = [],
            u = {},
            c = function (n) {
                r.push(n);
            },
            a = !!window.document.documentElement.doScroll;
        function s() {
            (i = document.createElement('iframe')).setAttribute(
                'frameBorder',
                '0',
            ),
                i.setAttribute('width', '0'),
                i.setAttribute('height', '0'),
                i.setAttribute('src', o + '/payments/analytics/frame.html'),
                i.setAttribute(
                    'style',
                    'border: none !important; display: block !important; visibility: hidden !important',
                ),
                i.setAttribute('sandbox', 'allow-scripts allow-same-origin'),
                i.addEventListener('load', f),
                document.body.appendChild(i);
        }
        function f() {
            var n;
            for (window.addEventListener('message', l, !1); (n = r.shift()); )
                d(n);
            c = d;
        }
        function l(n) {
            if (n.origin === o) {
                var t = n.data;
                if ('object' == typeof t && 'string' == typeof t.instanceId) {
                    var e = u[t.instanceId];
                    'function' == typeof e && e(t);
                }
            }
        }
        function d(n) {
            i.contentWindow && i.contentWindow.postMessage(n, o);
        }
        t.default = {
            init: function () {
                return;
            },
            send: function (n) {
                c(n);
            },
            listenFor: function (n, t) {
                u[n] = t;
            },
            setOrigin: function (origin) {
                o = origin;
            },
            loadIframe: function () {
                i || s();
            },
        };
    },
    ,
    ,
    ,
    function (n, t, e) {
        'use strict';
        Object.defineProperty(t, 'e', { value: !0 });
        var i = e(7),
            o = e(2);
        'object' != typeof window.Square && (window.Square = {}),
            window.Square.Analytics instanceof i.Analytics ||
                (o.default.init(), (window.Square.Analytics = i.Analytics));
    },
    function (n, t, e) {
        'use strict';
        Object.defineProperty(t, 'e', { value: !0 });
        var i = e(2),
            o = (function () {
                function n(n, squareApiHost) {
                    (this.applicationId = n),
                        (this.instanceId =
                            'sq-analytics-' +
                            new Date().getMilliseconds() +
                            '-' +
                            100000 * Math.random()),
                        i.default.setOrigin(squareApiHost),
                        i.default.loadIframe(),
                        i.default.listenFor(
                            this.instanceId,
                            this.messageReceived.bind(this),
                        );
                }
                return (
                    (n.prototype.getToken = function (n) {
                        if (!this.callback) {
                            this.callback = n;
                            var t = {
                                applicationId: this.applicationId,
                                instanceId: this.instanceId,
                                type: 'requestToken',
                                version: 1,
                            };
                            i.default.send(t);
                        }
                    }),
                    (n.prototype.messageReceived = function (n) {
                        'receiveToken' === n.type &&
                            'function' == typeof this.callback &&
                            (this.callback(n.token), delete this.callback);
                    }),
                    n
                );
            })();
        t.Analytics = o;
    },
]);
