import { SingleValueValidator } from './types';

/**
 * The IBAN consists of up to 34 alphanumeric characters comprising:
 * - a country code
 * - two check digits
 * - Basic Bank Account Number (BBAN)
 */
// eslint-disable-next-line no-useless-escape
const VALID_IBAN = /^[A-Z]{2}\d{2}[A-Z0-9 ]+$/;

/**
 * Validator: Test that a value is a valid International Bank Account Number.
 */
export const isIBAN: SingleValueValidator<string> = (code) =>
    VALID_IBAN.test(code ?? '');
