import React, { PureComponent } from 'react';

import { Checkbox } from '@eb/eds-checkbox';

import { SELECT_ALL_TEXT } from '../constants';

/** Creates a new item to be placed on top of the list of options
 * to manage the selection of all elements in the list.
 */
export default class SelectAllOption extends PureComponent {
    render() {
        const {
            values,
            selectedValues,
            shouldShowSelectAll,
            onSelectAllChange,
        } = this.props;

        let component = null;
        const isSelected = values.length === selectedValues.length;

        if (shouldShowSelectAll) {
            component = (
                /* Accessibility is not fully developed yet because it may need extra work. See: https://jira.evbhome.com/browse/EB-73967 */
                /* eslint-disable jsx-a11y/interactive-supports-focus */
                /* eslint-disable jsx-a11y/click-events-have-key-events */
                <div className="eds-field-multiselect__list-item-header">
                    <div
                        role="option"
                        aria-selected={isSelected}
                        className="eds-field-multiselect__list-item eds-text-color--grey-700 eds-l-pad-vert-3 eds-l-pad-hor-4 eds-l-mar-vert-2"
                        onClick={onSelectAllChange}
                        data-spec="list-container-select-all"
                    >
                        <Checkbox
                            id="checkbox-select-all"
                            name="checkbox-select-all"
                            htmlValue="select-all"
                            value={isSelected}
                        >
                            {SELECT_ALL_TEXT}
                        </Checkbox>
                    </div>
                </div>
            );
            /* eslint-enable jsx-a11y/interactive-supports-focus */
            /* eslint-enable jsx-a11y/click-events-have-key-events */
        }

        return component;
    }
}
