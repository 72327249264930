import * as React from 'react';

const MastercardPaymentSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 48 32" {...props}>
        <path
            fill="#6983D7"
            d="M0 3c0-1.6 1.4-3 3-3h42c1.7 0 3 1.4 3 3v26c0 1.6-1.3 3-3 3H3c-1.6 0-3-1.4-3-3V3z"
        />
        <ellipse
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#F6635F"
            cx={17.7}
            cy={16.1}
            rx={9.6}
            ry={9.5}
        />
        <ellipse
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFB548"
            cx={31.1}
            cy={16.1}
            rx={9.6}
            ry={9.5}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FF8150"
            d="M24.4 22.9c1.8-1.7 2.9-4.1 2.9-6.8s-1.1-5-2.9-6.8c-1.8 1.7-2.9 4.1-2.9 6.8s1.1 5.1 2.9 6.8z"
        />
    </svg>
);

MastercardPaymentSvg.displayName = 'MastercardPaymentSvg';
export default MastercardPaymentSvg;
