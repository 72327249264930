import React, { PureComponent } from 'react';

// original author: dcarter
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { getFirstAvailableChildId } from '@eb/eds-utils';

import { Label } from '@eb/eds-label';
import * as constants from './constants';

import './formField.scss';

/**
 * Helper component that conditionally renders an annotation note
 * @prop {string} annotationNote - string to be displayed in annotation
 * @example
 * // <FormFieldNote annotationNote="This field is required" />
 */
const FormFieldNote = ({ annotationNote }) => {
    let component = null;

    if (annotationNote) {
        component = (
            <aside
                data-automation="eds-field-annotation"
                className="eds-field-annotation__note eds-l-pad-top-1 eds-fx--fade-in"
                role="alert"
            >
                {annotationNote}
            </aside>
        );
    }
    return component;
};

/**
 * Class that represents a general form-field wrapper.
 * Dictates how label, field message, etc are structured around form fields.
 * In simple cases, htmlFor can be automatically determined from child form element.
 * @extends PureComponent
 * @example
 * // <FormField label="My Input" required={true}>
 * //     <Input id="my-input" type="text" />
 * // </FormField>
 **/
export default class FormField extends PureComponent {
    static propTypes = {
        /**
         * FormField should be provided with a single form element.
         */
        children: PropTypes.element.isRequired,
        /**
         * Text to be displayed in label
         */
        label: PropTypes.node.isRequired,
        /**
         * Id of the input to which the label is mapped; optional
         */
        htmlFor: PropTypes.string,
        /**
         * Whether this field is required
         */
        required: PropTypes.bool,
        /**
         * Whether the label should be displayed or hidden
         */
        hideLabel: PropTypes.bool,
        /**
         * The type of annotation message that should be shown
         */
        annotationType: PropTypes.oneOf(constants.ANNOTATION_TYPES),
        /**
         * Text shown in annotation message
         */
        annotationNote: PropTypes.node,
        /**
         * The amount of spacing for the bottom
         */
        bottomSpacing: PropTypes.number,
    };

    static defaultProps = {
        required: false,
        hideLabel: false,
        annotationType: constants.ANNOTATION_TYPE_INFO,
        bottomSpacing: 5,
    };

    render() {
        const {
            label,
            required,
            hideLabel,
            annotationType,
            annotationNote,
            bottomSpacing,
            children,
            htmlFor = getFirstAvailableChildId(children),
        } = this.props;
        const containerClassname = classNames(`eds-l-mar-bot-${bottomSpacing}`);
        const labelClassname = classNames(
            {
                'eds-l-mar-bot-1': !hideLabel,
            },
            'eds-text--left',
        );
        const annotationWrapClassname = classNames({
            'eds-field-annotation': annotationNote,
            'eds-field-annotation--success':
                annotationType === constants.ANNOTATION_TYPE_SUCCESS,
            'eds-field-annotation--error':
                annotationType === constants.ANNOTATION_TYPE_ERROR,
        });

        return (
            <div className={containerClassname}>
                <div className={labelClassname}>
                    <Label
                        htmlFor={htmlFor}
                        required={required}
                        hidden={hideLabel}
                    >
                        {label}
                    </Label>
                </div>
                <div className={annotationWrapClassname}>
                    {children}
                    <FormFieldNote annotationNote={annotationNote} />
                </div>
            </div>
        );
    }
}
