import React from 'react';
import isInteger from 'lodash/isInteger';
import isEmpty from 'lodash/isEmpty';

import { Divider } from '@eb/eds-divider';

import { formatMajorMoney, formatMinorMoney } from '@eb/intl';

import gettext from '@eb/gettext';
import {
    ATTENDEE_PAYER,
    FEE_COMPONENT_MAP,
    ORGANIZER_PAYER,
    PROCESSING_FEE_COMPONENT_NAME,
    ROYALTY_COMPONENT_NAME,
    SERVICE_FEE_COMPONENT_NAME,
    SERVICE_FEE_TEXT,
    TAX_COMPONENT_BASE_FEES_TAX_INCLUDABLE,
    TAX_PROCESSING_FEE_COMPONENT_NAME,
    TAX_SERVICE_FEE_COMPONENT_NAME,
} from '../../constants';

import RefundPolicy from '../../components/refundPolicy/RefundPolicy';
import { getTaxOnFeeComponents } from '../../components/reserved/utils';
import { formatSelectedQuantityByDeliveryMethodsForDisplay } from '../../utils/deliveryMethod';
import { getSelectedVirtualIncentivesAggregatedTotal } from '../../utils/virtualIncentives';
import { getPromoCodeInformation } from '../../utils/promoCodes';
import { isDonationTicket } from '../../utils/tickets';

/**
 * Since Pricing Service provides fee component name in format
 * `eventbrite.service_fee` or `royalty`. Here we are converting
 * the response format to display format `Service fee` or `Royalty`.
 * If no name is provided, this will default to a blank string.
 *
 * @param {string} name - fee component name
 * @param {int} value - value of fee component
 * @param {*} currency
 * @param {*} currencyFormat
 */
export const getFormattedFeeContent = (
    name = '',
    value,
    currency,
    currencyFormat,
) => {
    const formattedValue = formatMajorMoney(value, currency, currencyFormat);
    const formattedContent =
        FEE_COMPONENT_MAP[name] || FEE_COMPONENT_MAP.default(name);

    return {
        id: name,
        content: formattedContent.label,
        value: formattedValue,
    };
};

/**
 * Given array of feeComponents, here we are joining ESF and EPP components
 * into a single `Service Fee` display component. If there is a fee component
 * whose name is also `service fee` (case insensitive) it will also be included.
 * @param {array} feeComponents
 */
export const joinServiceFeeComponents = (feeComponents = []) => {
    const ret = [];
    const serviceFee = feeComponents.find((e) =>
        e.name.includes(SERVICE_FEE_COMPONENT_NAME),
    );
    const eppFee = feeComponents.find((e) =>
        e.name.includes(PROCESSING_FEE_COMPONENT_NAME),
    );
    const otherServiceFees = feeComponents.filter(
        (e) => e.name.toLowerCase().trim() === SERVICE_FEE_TEXT,
    );
    const otherFees = feeComponents.filter(
        ({ name }) =>
            !(
                name.includes(SERVICE_FEE_COMPONENT_NAME) ||
                name.includes(PROCESSING_FEE_COMPONENT_NAME)
            ) && name.toLowerCase().trim() !== SERVICE_FEE_TEXT,
    );

    if (!isEmpty(serviceFee)) {
        const finalServiceFee = { ...serviceFee };

        if (!isEmpty(eppFee)) {
            finalServiceFee.value += eppFee.value;
        }
        if (!isEmpty(otherServiceFees)) {
            finalServiceFee.value += otherServiceFees.reduce(
                (a, b) => a + b.value,
                0,
            );
        }
        ret.push(finalServiceFee);
    }

    return ret.concat(otherFees);
};

export const getSummaryItems = ({
    selectedTickets,
    eventCurrency,
    currencyFormat,
    shouldHandleFreeTickets,
}) =>
    selectedTickets.map(
        ({
            cost,
            free,
            id,
            name,
            selectedQuantity: quantity,
            color,
            category,
        }) => {
            let subtotal = 0;

            if (cost) {
                const freeAfterDiscount = !free && cost.value === 0;

                if (shouldHandleFreeTickets) {
                    subtotal = cost.value * quantity;
                } else {
                    subtotal =
                        free || freeAfterDiscount ? 0 : cost.value * quantity;
                }
            }
            const formattedSubtotal = formatMajorMoney(
                subtotal,
                eventCurrency,
                currencyFormat,
            );
            const value = formattedSubtotal;
            const content = name;

            return {
                content,
                quantity: quantity.toString(),
                id: id.toString(),
                value,
                color,
                donation: isDonationTicket({ category }),
            };
        },
    );

const getItemsForFullPriceAndSubtotal = ({
    currency,
    currencyFormat,
    discount,
    fees,
    fullPrice,
    taxes,
    isTransferredOrder,
}) => {
    if (!fullPrice) {
        return [];
    }

    const hasPriceModifiers = fees || taxes || discount;

    if (!hasPriceModifiers) {
        return [];
    }

    const fullPriceValue = formatMajorMoney(
        fullPrice,
        currency,
        currencyFormat,
    );
    const fullPriceColor = 'grey-700';

    if (discount) {
        return [
            {
                content: gettext('Full Price'),
                id: 'fullprice',
                value: fullPriceValue,
                color: fullPriceColor,
            },
            {
                content: gettext('Discount'),
                id: 'discount',
                value: `- ${formatMajorMoney(
                    discount,
                    currency,
                    currencyFormat,
                )}`,
                color: 'ui-green',
            },
        ];
    }

    return [
        {
            content: isTransferredOrder
                ? gettext('Price difference')
                : gettext('Subtotal'),
            id: 'subtotal',
            value: fullPriceValue,
            color: fullPriceColor,
        },
    ];
};

const getItemsForEventbriteFee = ({
    fees,
    currency,
    currencyFormat,
    enableRefundFeeRetentionPolicy,
    feeComponents,
    shippingFee,
}) => {
    if (!fees && isEmpty(shippingFee)) {
        return [];
    }

    const feesItem = {
        content: gettext('Fees'),
        id: 'fees',
        value: formatMajorMoney(fees, currency, currencyFormat),
        color: 'grey-700',
    };

    if (enableRefundFeeRetentionPolicy) {
        feesItem.infoTooltipContent = gettext(
            "Eventbrite's fee is nonrefundable",
        );
    }

    if (!isEmpty(feeComponents) || !isEmpty(shippingFee)) {
        feesItem.expansionContent = getExpansionContent(
            feeComponents,
            shippingFee,
            currency,
            currencyFormat,
        );
    }

    return [feesItem];
};

const getTotalFees = (fees, shippingFee) => fees + (shippingFee?.value || 0);

const joinShippingFees = (shippingFees) =>
    isEmpty(shippingFees)
        ? {}
        : {
              name: 'eventbrite.shipping.item',
              value: shippingFees.reduce((total, fee) => total + fee.value, 0),
          };

const getExpansionContent = (
    feeComponents,
    shippingFee,
    currency,
    currencyFormat,
) => {
    const orderedFees = isEmpty(feeComponents)
        ? []
        : joinServiceFeeComponents(feeComponents);

    const expansionContent = orderedFees.map(({ name, value }) =>
        getFormattedFeeContent(name, value, currency, currencyFormat),
    );

    return isEmpty(shippingFee)
        ? expansionContent
        : [
              ...expansionContent,
              getFormattedFeeContent(
                  'eventbrite.shipping.item',
                  shippingFee.value,
                  currency,
                  currencyFormat,
              ),
          ];
};

const getItemsForTaxes = ({
    taxes,
    eventTaxName,
    currency,
    currencyFormat,
    taxBeforeExemption,
}) => {
    if (!taxes && !taxBeforeExemption) {
        return [];
    }

    const taxItem = {
        content: eventTaxName,
        id: 'taxes',
        value: formatMajorMoney(taxes, currency, currencyFormat),
        color: 'grey-700',
    };

    if (taxBeforeExemption) {
        taxItem.infoTooltipContent = gettext(
            'You are exempt from paying %(eventTaxName)s',
            { eventTaxName },
        );
        taxItem.value = formatMajorMoney(
            taxBeforeExemption,
            currency,
            currencyFormat,
        );
        taxItem.color = 'grey-500';
        taxItem.valueAdditionalClassNames = 'eds-text--strikethrough';
    }

    return [taxItem];
};

const getItemsForInstallments = ({
    installments,
    currency,
    currencyFormat,
}) => {
    if (!installments) {
        return [];
    }

    const {
        numberOfInstallments,
        pricePerInstallment,
        installmentInterests,
    } = installments;

    const items = [];

    if (numberOfInstallments > 1) {
        items.push({
            content: `${numberOfInstallments} x installments`,
            id: 'installments',
            value: formatMinorMoney(
                pricePerInstallment,
                currency,
                currencyFormat,
            ),
            color:
                'grey-700 eds-l-pad-top-4 u-border-top u-border-color-ui-200',
        });
    }

    if (installmentInterests.TEA) {
        items.push({
            content: gettext('APR'),
            id: 'installments-tea',
            value: installmentInterests.TEA,
            infoTooltipContent: gettext('Annual Percentage Rate'),
        });
    }

    if (installmentInterests.CFT) {
        items.push({
            content: gettext('TFC'),
            id: 'installments-cft',
            value: installmentInterests.CFT,
            infoTooltipContent: gettext('Total Financial Cost'),
        });
    }

    return items;
};

const getItemsForDeliveryMethods = ({
    selectedDeliveryMethods,
    quantitySelectedDeliveryMethods,
    isRegEvent,
    shippingRates,
    currency,
    currencyFormat,
}) => {
    if (isEmpty(selectedDeliveryMethods)) {
        return [];
    }

    const deliveryMethods = quantitySelectedDeliveryMethods.map(
        (quantitySelectedDeliveryMethod, index) => (
            <span key={index} className="eds-g-cell eds-g-cell-1-1">
                {formatSelectedQuantityByDeliveryMethodsForDisplay(
                    quantitySelectedDeliveryMethod,
                    isRegEvent,
                )}
            </span>
        ),
    );

    const totalDeliveryFee = (shippingRates || [])
        .filter((rate) => selectedDeliveryMethods.includes(rate.name))
        .reduce((a, b) => a + b.price.value, 0);

    return [
        {
            content: gettext('Delivery'),
            subcontent: deliveryMethods,
            id: 'deliveryMethods',
            color: 'grey-700',
            value: formatMajorMoney(totalDeliveryFee, currency, currencyFormat),
        },
    ];
};

const getItemsForVirtualIncentives = ({
    currency,
    currencyFormat,
    selectedVirtualIncentives,
    unformattedOrderCost,
}) => {
    if (isEmpty(selectedVirtualIncentives)) {
        return [];
    }
    let totalUsedCredits = getSelectedVirtualIncentivesAggregatedTotal(
        selectedVirtualIncentives,
    );

    if (unformattedOrderCost < totalUsedCredits) {
        totalUsedCredits = unformattedOrderCost;
    }

    return [
        {
            content: gettext('Credits'),
            id: 'selectedVirtualIncentives',
            color: 'grey-700',
            value: `- ${formatMajorMoney(
                totalUsedCredits,
                currency,
                currencyFormat,
            )}`,
        },
    ];
};

export const getBreakdownItems = ({
    currency,
    currencyFormat,
    discount,
    enableRefundFeeRetentionPolicy,
    eventTaxName,
    feeComponents,
    fees,
    fullPrice,
    installments,
    isTransferredOrder,
    isRegEvent,
    quantitySelectedDeliveryMethods,
    selectedDeliveryMethods,
    shippingFees,
    shippingRates,
    taxes,
    shouldDisplayTax,
    taxBeforeExemption,
    selectedVirtualIncentives,
    unformattedOrderCost,
    orderCosts,
}) => {
    const shippingFee = joinShippingFees(shippingFees);
    const totalFeesItems = getTotalFees(
        fees,
        isTransferredOrder || !isEmpty(orderCosts) ? 0 : shippingFee,
    );

    const fullPriceAndSubtotalItems = getItemsForFullPriceAndSubtotal({
        fullPrice,
        fees,
        taxes,
        discount,
        currency,
        currencyFormat,
        isTransferredOrder,
    });

    // For Members, fees show on the first ticket selection step
    // After the user clicks Checkout, there is no fee and the
    // discount is reflected in the price and feeComponents
    const eventbriteFeesItems = getItemsForEventbriteFee({
        fees: totalFeesItems,
        currency,
        currencyFormat,
        enableRefundFeeRetentionPolicy,
        feeComponents,
        isTransferredOrder,
        shippingFee,
    });

    const taxesItems = getItemsForTaxes({
        taxes,
        eventTaxName,
        currency,
        currencyFormat,
        shouldDisplayTax,
        taxBeforeExemption,
    });

    const installmentItems = getItemsForInstallments({
        installments,
        currency,
        currencyFormat,
    });

    const deliveryMethodsItems = getItemsForDeliveryMethods({
        selectedDeliveryMethods,
        quantitySelectedDeliveryMethods,
        isRegEvent,
        shippingRates,
        currency,
        currencyFormat,
    });

    const virtualIncentivesItems = getItemsForVirtualIncentives({
        currency,
        currencyFormat,
        selectedVirtualIncentives,
        unformattedOrderCost,
    });

    return [
        ...fullPriceAndSubtotalItems,
        ...eventbriteFeesItems,
        ...taxesItems,
        ...installmentItems,
        ...deliveryMethodsItems,
        ...virtualIncentivesItems,
    ];
};

const ticketCostsReducer = (memo, ticket) => {
    const costs = memo;
    const {
        cost,
        currency,
        displayShippingFee,
        fee,
        free,
        selectedQuantity,
        tax,
        taxComponents = [],
        taxWithoutShipping,
    } = ticket;

    // Calculate costs for paid tickets using variants information
    if (!free && cost) {
        const promoCodeInformation = getPromoCodeInformation({ ticket });
        const { originalCost, amountOff } = promoCodeInformation;
        let fullPrice = cost.value;
        let discount = 0;

        if (originalCost && amountOff) {
            fullPrice = originalCost.value;
            // In cases where the amountOff is greater than the
            // original cost of the ticket, record discount value
            // up to the original cost.
            discount = Math.min(amountOff.value, fullPrice);
        }

        if (selectedQuantity > 0) {
            const isAbsorbedTax = getTaxOnFee(taxComponents);
            const feeValue = isAbsorbedTax ? fee.value + tax.value : fee.value;
            const taxValue = getTaxValue({
                displayShippingFee,
                selectedQuantity,
                tax,
                isAbsorbedTax,
                taxWithoutShipping,
            });

            costs.fullPrice += fullPrice * selectedQuantity;
            costs.discount += discount * selectedQuantity;
            costs.currency = currency;
            costs.fees += feeValue * selectedQuantity;
            costs.taxes += taxValue;
            costs.taxComponents = taxComponents;
            costs.taxBeforeExemption = tax.taxBeforeExemption;
        }

        // If currency is undefined, then set it.
        if (!costs.currency && cost.currency) {
            costs.currency = cost.currency;
        }
    }

    return costs;
};

/**
 * Given the fee components for selectedTickets adjusted by selected
 * quantity, we are combining all fee components with the same name to
 * be presented in the fee breakdown.
 *
 * @param {*} memo - Existing fee components
 * @param {*} components - New components to add
 */
const feeBreakdownComponentsReducer = (memo, components) => {
    const existing = components;
    const feeArray = memo;
    let itemComponents = null;
    let ret = null;

    if (isEmpty(existing)) {
        return feeArray;
    }

    itemComponents = existing.reduce((acc, element) => {
        const index = acc.findIndex(
            ({ name }) =>
                name.toLowerCase().trim() === element.name.toLowerCase().trim(),
        );
        let el = element;

        if (index !== -1) {
            el = acc[index];
            el.value += element.value;
            acc.splice(index, 1);
        }
        acc.push(el);
        return acc;
    }, []);

    if (isEmpty(feeArray)) {
        return itemComponents;
    }

    // Merge the itemComponents into the feeArray
    ret = feeArray.map((element) => {
        const itemComponent = itemComponents.find(
            (e) => e.name === element.name,
        );
        let el = element;

        if (!isEmpty(itemComponent)) {
            const index = itemComponents.findIndex(
                (e) => e.name === element.name,
            );

            el = itemComponent;
            el.value += element.value;
            itemComponents.splice(index, 1);
        }
        return el;
    });

    if (!isEmpty(itemComponents)) {
        // add rempaining item components to feeArray
        ret = [...ret, ...itemComponents];
    }

    return ret;
};

/**
 * Given the all fee components for selectedTickets, we are filtering
 * the components with value, payer ateendee and adjusting by selected
 * quantity. These components will be present in the fee breakdown.
 *
 * @param {*} selectedQuantity - Tickets selected by the user.
 * @param {*} taxComponents - Tax components of the tickets selected.
 * @param {*} acc - Array to return the fee components.
 * @param {*} feeComponents - Fee component element to analize.
 */
const feeComponentsReducer = (
    selectedQuantity,
    taxComponents,
    acc,
    { name, value, groupName, payer },
) => {
    if (
        payer === ORGANIZER_PAYER ||
        (name === ROYALTY_COMPONENT_NAME && !value.value)
    ) {
        return acc;
    }
    let componentName = name;
    let adjustedValue = value.value * selectedQuantity;
    // We don't want to show taxes as a separate row if they are just taxes on the fees
    const taxOnFee = getTaxOnFee(taxComponents);

    if (groupName && name !== groupName) {
        componentName = groupName;
    }

    if (taxOnFee && componentName === SERVICE_FEE_COMPONENT_NAME) {
        // For reserved seating events, value is not nested
        const taxValue = isInteger(taxOnFee.value)
            ? taxOnFee.value
            : taxOnFee.value.value;

        adjustedValue += taxValue * selectedQuantity;
    }

    return [
        ...acc,
        {
            name: componentName,
            value: adjustedValue,
        },
    ];
};

const hasTaxComponent = (payer, value, groupName) =>
    payer === ATTENDEE_PAYER &&
    value.value &&
    [
        TAX_SERVICE_FEE_COMPONENT_NAME,
        TAX_PROCESSING_FEE_COMPONENT_NAME,
    ].includes(groupName);

/**
 * Given the all tax components for selectedTickets, we are filtering all the components
 * with value, payer ateendee, group name 'eventbrite.service_fee.tax' or 'eventbrite.payment_fee_v2.tax'
 * and adjusting by selected quantity. These components will be present in the fee breakdown.
 *
 * @param {*} selectedQuantity - Tickets selected by the user.
 * @param {*} acc - Array to return the fee components.
 * @param {*} taxComponents - Tax component element to analize.
 */
const taxComponentsReducer = (
    selectedQuantity,
    adjustedComponents,
    { value, groupName, payer },
) => {
    if (!hasTaxComponent(payer, value, groupName)) {
        return adjustedComponents;
    }

    return [
        ...adjustedComponents,
        {
            name: SERVICE_FEE_COMPONENT_NAME,
            value: value.value * selectedQuantity,
        },
    ];
};

/**
 * Given selectedTickets, for each ticket we adjust the fee components
 * for the selected quantity, and combine all ticket fee components with
 * the same name to be presented in fee breakdown.
 *
 * @param {arrayOf Object} selectedTickets - tickets selected by the user.
 * @return {object} fee components adjusted for selected quantity and
 * combined by name
 */
export const getTicketFeeComponents = (selectedTickets) => {
    const components = selectedTickets.map(
        ({ feeComponents, selectedQuantity, taxComponents = [] }) => {
            const adjustedComponents = feeComponents
                ? feeComponents.reduce(
                      feeComponentsReducer.bind(
                          null,
                          selectedQuantity,
                          taxComponents,
                      ),
                      [],
                  )
                : [];

            return isEmpty(taxComponents)
                ? adjustedComponents
                : taxComponents.reduce(
                      taxComponentsReducer.bind(null, selectedQuantity),
                      adjustedComponents,
                  );
        },
    );
    const ret = components.reduce(feeBreakdownComponentsReducer, []);

    return ret;
};

/**
 * Since basket_cost is not working we are calculating values
 * from the list of selected tickets.
 *
 * @param {arrayOf object} selectedTickets - tickets selected by the user.
 * @param {bool} hasFeeBreakdown - If should show fee breakdown.
 * @returns {object} selected tickets total fees, taxes, currency and subtotal.
 */
export const getTicketsCosts = (selectedTickets, hasFeeBreakdown) => {
    let feeComponents = [];

    if (hasFeeBreakdown) {
        feeComponents = getTicketFeeComponents(selectedTickets);
    }

    return selectedTickets.reduce(ticketCostsReducer, {
        currency: '',
        fees: 0,
        feeComponents,
        fullPrice: 0,
        discount: 0,
        taxes: 0,
        taxComponents: [],
    });
};

/**
 * Once an order is created. All the price cost info is returned with
 * API response. We can extract the ticketCosts with this orderCosts.
 *
 * @param {Object} orderCosts - costs info of the order created.
 * @return {object} selected tickets total fees, taxes, currency and subtotal.
 */
// TODO: instead of passing orderCosts, compose with a selector
export const getTicketsCostsByOrder = (orderCosts, hasFeeBreakdown) => {
    // TODO: Each item destructured here should be a separate selector in `selectors/order.js`
    // Example: `getOrderCostsPriceBeforeDiscount`, `getOrderCostFeeComponents`, etc.
    // Once this is done, then these default value logic will be handled by the selectors
    const {
        priceBeforeDiscount = {},
        discountAmount,
        displayTax,
        displayPrice,
        displayFee: { value: fees },
        tax: { taxBeforeExemption } = {},
        feeComponents = [],
        taxComponents = [],
    } = orderCosts;

    const currency = priceBeforeDiscount.currency;
    const fullPrice = taxBeforeExemption
        ? displayPrice.value
        : priceBeforeDiscount.value;

    let discount = 0;
    let taxes = 0;
    let feeItems = null;
    const hasFeeTaxIncludable = !!taxComponents.find(
        (component) =>
            component.base === TAX_COMPONENT_BASE_FEES_TAX_INCLUDABLE,
    );
    const hasTaxOnFee = !!taxComponents.find(
        (component) =>
            [
                TAX_SERVICE_FEE_COMPONENT_NAME,
                TAX_PROCESSING_FEE_COMPONENT_NAME,
            ].includes(component.groupName) &&
            component.payer == ATTENDEE_PAYER,
    );

    if (hasFeeBreakdown) {
        feeItems = feeComponents;
    }

    if (discountAmount) {
        discount = discountAmount.value;
    }

    if (hasTaxOnFee) {
        feeItems = hasFeeBreakdown
            ? getTaxOnFeeComponents(feeItems, taxComponents)
            : [];
    }

    if (displayTax && !hasFeeTaxIncludable) {
        taxes = displayTax.tax.value;
    }

    return {
        currency,
        discount,
        fees,
        feeComponents: feeItems,
        fullPrice,
        taxComponents,
        taxes,
        taxBeforeExemption,
    };
};

export const getRefundPolicyContent = (refundPolicyProps = {}) => {
    const {
        showRefundPolicy,
        refundPolicyCode,
        enableRefundFeeRetentionPolicy,
    } = refundPolicyProps;

    return showRefundPolicy && refundPolicyCode ? (
        <div data-spec="pane-refund-policy">
            <Divider />
            <RefundPolicy
                enableRefundFeeRetentionPolicy={enableRefundFeeRetentionPolicy}
                refundPolicyCode={refundPolicyCode}
            />
        </div>
    ) : null;
};

const getTaxOnFee = (taxComponents) =>
    taxComponents.find(
        (component) =>
            component.base === TAX_COMPONENT_BASE_FEES_TAX_INCLUDABLE,
    );

const getTaxValue = ({
    displayShippingFee,
    selectedQuantity,
    tax,
    isAbsorbedTax,
    taxWithoutShipping,
}) => {
    const taxValue = isAbsorbedTax ? 0 : tax.value;
    const taxWithoutShippingValue =
        displayShippingFee?.value && !isAbsorbedTax
            ? taxWithoutShipping?.value ?? 0
            : 0;

    return displayShippingFee?.value
        ? taxValue + taxWithoutShippingValue * (selectedQuantity - 1)
        : taxValue * selectedQuantity;
};
