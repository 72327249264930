import filter from 'lodash/filter';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { getImageThumbnailUrl, getFullImageUrl } from '../selectors/tickets';
import { TICKET_CLASS_CATEGORIES } from '../constants';
import { isMultipleVariantType } from '../containers/ticketSelection/utils/ticketUtils';

/**
 * See https://confluence.evbhome.com/display/RES/Checkout+Widget+Add-on+Thumbnail+Decision+Tree
 * for explanation of the logic below
 *
 * @param {Object} ticket
 * @param {Object} variant
 * @returns {Object} the ticket object for which to show the image (can be a root ticket or a variant)
 */
export const getTicketForImage = (ticket = {}, variant = {}) => {
    const findFirstVariantHasImage = (variants = []) =>
        variants.find((variant) => !!getImageThumbnailUrl(variant));
    const getDefaultTicket = () =>
        getImageThumbnailUrl(ticket)
            ? ticket
            : findFirstVariantHasImage(ticket.variants);

    if (variant) {
        return getImageThumbnailUrl(variant) ? variant : getDefaultTicket();
    }

    return getDefaultTicket();
};

/**
 * @param {object} ticket the root ticket object for which to get image data
 * @returns {object} returns an object containing image URL info for the given ticket.
 */
export const getTicketImageUrls = (ticket) => ({
    thumbnailUrl: getImageThumbnailUrl(ticket),
    fullImageUrl: getFullImageUrl(ticket),
});

export const getSelectedVariants = (variants = []) =>
    variants.filter((variant) => variant.selectedQuantity > 0);

export const getVariantsHaveSelectedDeliveryMethod = (variants = []) =>
    variants.filter((variant) => variant.selectedDeliveryMethod);

export const isDonationTicket = (ticket) =>
    ticket.category === TICKET_CLASS_CATEGORIES.DONATION;
export const isAddonTicket = (ticket) =>
    ticket.category === TICKET_CLASS_CATEGORIES.ADD_ON;

/**
 * Returns whether or not the ticket has variants
 *
 * @param {object} ticket
 * @returns {boolean}
 */
export const hasVariants = (ticket) => !isEmpty(ticket.variants);

/**
 * Returns an array of tickets with its variants flattened.
 *
 * @param {arrayOf object} selectedTickets
 * @returns {array}
 */
export const formatTicketsForOrderSummary = (selectedTickets = []) =>
    selectedTickets.reduce((memo, ticket) => {
        const {
            variants = [],
            selectedQuantity: parentSelectedQuantity,
        } = ticket;

        /**
         * If there is variants of a given ticket selected, those variants are
         * flattened at the same level as the parent ticket.
         */
        let tickets = variants
            .filter(({ selectedQuantity }) => selectedQuantity > 0)
            .map((variant) => ({
                ...variant,
                isVariant: true,
                // Public discounts do not have display_name so we use a composite of the parent
                // name and discount name
                name:
                    get(variant, 'displayName', '') ||
                    `${get(ticket, 'name', '')} ${get(variant, 'code', '')}`,
            }));

        if (parentSelectedQuantity > 0) {
            tickets = [...tickets, ticket];
        }

        return [...memo, ...tickets];
    }, []);

export const filterIndividualTickets = (tickets) =>
    filter(
        tickets,
        (ticket) =>
            get(ticket, 'groupSettings.required') !== true &&
            !isMultipleVariantType(ticket),
    );

export const filterGroupTickets = (tickets) =>
    filter(tickets, ({ groupSettings }) => !!groupSettings);

/**
 * Returns an array of filtered tickets to be displayed in Advanced Team ticket selection
 *
 * @param {object} tickets
 * @param {function} applicableFilter a function to filter by group or individual tickets depending
 * on what kind of registration the user has chosen
 */

export const filterAdvancedTeamTicketsAndVariants = (
    tickets,
    applicableFilter,
) => {
    // Get all tickets for a group or individual
    let eligibleTickets = applicableFilter(tickets);

    // Get tickets with MultiVariants for group or individual
    const eligibleTicketsWithMultiVariants = filter(
        tickets,
        (ticket) =>
            isMultipleVariantType(ticket) &&
            !isEmpty(applicableFilter(ticket.variants)),
    );

    // Merge all tickets
    eligibleTickets = eligibleTickets.concat(eligibleTicketsWithMultiVariants);

    return eligibleTickets;
};

export const filterEligibleAdvancedTeamTickets = (
    tickets,
    isIndividualSelected,
) => {
    if (isIndividualSelected) {
        return filterAdvancedTeamTicketsAndVariants(
            tickets,
            filterIndividualTickets,
        );
    }

    return filterAdvancedTeamTicketsAndVariants(tickets, filterGroupTickets);
};

export const variantAutoSelected = ({ ticket = {} }) => {
    const { variantInputType, variants = [] } = ticket;
    const variant = variants[0];

    return (
        variantInputType === 'single' &&
        variants.length === 1 &&
        variant.selectedQuantity > 0
    );
};

export const ticketWasAutoSelected = ({ ticketsById = {} }) => {
    const ticketIds = Object.keys(ticketsById);

    if (ticketIds.length !== 1) {
        return false;
    }

    const ticket = ticketsById[ticketIds[0]];
    const { selectedQuantity = 0, variantInputType } = ticket;

    return (
        (variantInputType === 'one' && selectedQuantity > 0) ||
        variantAutoSelected({ ticket })
    );
};

/**
 * When initializing tickets and there is only one, it will be auto selected.
 * This function will check if the autoselected ticket has payment constraints
 * to set on the store.
 *
 * @param {array} ticketIds
 * @param {object} ticketsById
 */
export const initializePaymentConstraints = ({
    ticketIds = [],
    ticketsById,
}) => {
    const firstTicket = ticketsById[ticketIds[0]];
    let constrainedInstrumentType = null;
    let constrainedPaymentMethods = [];
    let constrainingTicketIds = [];

    if (
        ticketWasAutoSelected({ ticketsById }) &&
        !isEmpty(firstTicket.paymentConstraints)
    ) {
        constrainedInstrumentType =
            firstTicket.paymentConstraints[0].instrumentType;
        constrainedPaymentMethods = firstTicket.paymentConstraints.map(
            ({ instrumentType, paymentMethod }) =>
                paymentMethod || instrumentType,
        );
        constrainingTicketIds = [{ id: ticketIds[0] }];
    }

    return {
        constrainedInstrumentType,
        constrainedPaymentMethods,
        constrainingTicketIds,
    };
};

/**
 * Compares constraining ticket id with selected ticket's formatted id
 *
 * constrainingId format:
 * {id: string, variantId?: string}
 *
 * @param {object} constrainingId - iteratee from constrainingTicketIds
 * @param {object} formattedConstrainingTicketId
 */
export const constrainingIdsAreEqual = ({
    constrainingId = {},
    formattedConstrainingTicketId = {},
}) => {
    if (
        !constrainingId.id ||
        !formattedConstrainingTicketId.id ||
        constrainingId.id !== formattedConstrainingTicketId.id
    ) {
        return false;
    }

    return formattedConstrainingTicketId.variantId
        ? constrainingId.variantId === formattedConstrainingTicketId.variantId
        : true;
};

/**
 * Returns an array of formatted ids of tickets with payment constraints and selected quantity > 0
 *
 * constrainingTicketIds format:
 * {id: string, variantId?: string}
 *
 * @param {array} ticketIds
 * @param {object} ticketsById
 */
export const findConstrainingTicketIds = ({
    ticketIds = [],
    ticketsById = {},
}) =>
    ticketIds.reduce((constrainingTicketIds, id) => {
        const ticket = ticketsById[id];

        if (ticket) {
            const { id: ticketId, variantInputType } = ticket;

            if (variantInputType && variantInputType === 'multiple') {
                const variantIndices = ticket.variants.map((v, i) => i);
                const constrainingVariantIds = findConstrainingTicketIds({
                    ticketIds: variantIndices,
                    ticketsById: ticket.variants,
                });
                const formattedIds = constrainingVariantIds.map(
                    ({ id: variantId }) => ({ id: ticketId, variantId }),
                );

                constrainingTicketIds.push(...formattedIds);
            } else if (
                variantInputType &&
                variantInputType === 'single' &&
                !isEmpty(ticket.paymentConstraints)
            ) {
                const hasSelectedVariant = ticket.variants.some(
                    (variant) => variant.selectedQuantity > 0,
                );

                if (hasSelectedVariant) {
                    constrainingTicketIds.push({ id: ticketId });
                }
            } else if (
                !isEmpty(ticket.paymentConstraints) &&
                ticket.selectedQuantity > 0
            ) {
                constrainingTicketIds.push({ id: ticketId });
            }
        }

        return constrainingTicketIds;
    }, []);

export const findConstrainedInstrumentType = ({
    constrainingTicketIds = [],
    ticketsById = {},
}) => {
    if (isEmpty(constrainingTicketIds) || isEmpty(ticketsById)) {
        return null;
    }

    const { id, variantId } = constrainingTicketIds[0];
    const ticketClassOrRule = ticketsById[id];
    const ticket = variantId
        ? ticketClassOrRule.variants.find(({ id }) => id === variantId)
        : ticketClassOrRule;

    return ticket.paymentConstraints[0].instrumentType;
};

/**
 * Returns an array of the common payment constraints between given tickets
 *
 * Ex:
 * Ticket1: AMEX, MASTERCARD, VISA
 * Ticket2: DISCOVER, MASTERCARD, VISA
 *
 * Result: [MASTERCARD, VISA]
 *
 * @param {object} ticketsById
 * @param {array} constrainingTicketIds
 */
export const findCommonConstrainedPaymentMethods = ({
    constrainingTicketIds = [],
    ticketsById = {},
}) => {
    if (isEmpty(ticketsById)) {
        return [];
    }

    return (
        constrainingTicketIds.reduce(
            (constrainedPaymentMethods, formattedId) => {
                const { id, variantId } = formattedId;
                const ticketClassOrRule = ticketsById[id];
                const ticket = variantId
                    ? ticketClassOrRule.variants.find(
                          ({ id }) => id === variantId,
                      )
                    : ticketClassOrRule;

                if (ticket && constrainedPaymentMethods) {
                    if (!constrainedPaymentMethods.length) {
                        return ticket.paymentConstraints.map(
                            ({ instrumentType, paymentMethod }) =>
                                paymentMethod ? paymentMethod : instrumentType,
                        );
                    }

                    const filteredPaymentMethods = constrainedPaymentMethods.filter(
                        (constraint) =>
                            ticket.paymentConstraints.some(
                                ({ instrumentType, paymentMethod }) =>
                                    constraint ===
                                    (paymentMethod || instrumentType),
                            ),
                    );

                    return filteredPaymentMethods.length
                        ? filteredPaymentMethods
                        : null;
                }

                return constrainedPaymentMethods;
            },
            [],
        ) || []
    );
};

/**
 * Compares a ticket's payment constraints to the list of current constrained payment
 * methods.
 *
 * 1.) If the ticket had a payment constraint conflict and no longer does, enable it
 * 2.) If the ticket has a payment constraint and isn't already disabled, disable it
 *
 * @param {object} ticket
 * @param {array} constrainedPaymentMethods
 */
export const enableOrDisableTicketBasedOnPaymentConstraints = ({
    constrainedPaymentMethods = [],
    ticket = {},
}) => {
    if (ticket.variantInputType === 'multiple') {
        const variants = ticket.variants.map((variant) =>
            enableOrDisableTicketBasedOnPaymentConstraints({
                ticket: variant,
                constrainedPaymentMethods,
            }),
        );

        return {
            ...ticket,
            variants,
        };
    }

    let { isDisabled, conflictingPayment, paymentConstraints = [] } = ticket;
    const hasConflictingPayment =
        !isEmpty(paymentConstraints) &&
        !isEmpty(constrainedPaymentMethods) &&
        !paymentConstraints.some(({ instrumentType, paymentMethod }) =>
            constrainedPaymentMethods.includes(paymentMethod || instrumentType),
        );

    if (conflictingPayment && !hasConflictingPayment) {
        /** This ticket no longer has conflicting payment constraints, re-enable */
        isDisabled = false;
        conflictingPayment = false;
    } else if (!isDisabled && hasConflictingPayment) {
        /** This ticket has conflicting payment constraints, disable */
        isDisabled = true;
        conflictingPayment = true;
    }

    return {
        ...ticket,
        isDisabled,
        conflictingPayment,
    };
};

/**
 * Loops through all tickets and calls enableOrDisableTicketBasedOnPaymentConstraints to
 * determine if a ticket has conflicting payment constraints
 *
 * @param {object} tickets
 * @param {array} constrainedPaymentMethods
 */
export const checkPaymentConstraintConflictsAllTickets = ({
    constrainedPaymentMethods = [],
    ticketsById = {},
}) =>
    Object.keys(ticketsById).reduce(
        (accum, id) => ({
            ...accum,
            [id]: enableOrDisableTicketBasedOnPaymentConstraints({
                ticket: ticketsById[id],
                constrainedPaymentMethods,
            }),
        }),
        {},
    );

/**
 * Formats constraint string to user readable text
 * Ex: A_CONSTRAINT_STRING => A Constraint String
 *
 * @param {string} paymentMethod
 */
export const formatConstraintText = (paymentMethod = '') => {
    if (!paymentMethod) {
        return '';
    }

    return paymentMethod
        .toLowerCase()
        .split('_')
        .map((str) => `${str[0].toUpperCase()}${str.slice(1)}`)
        .join(' ');
};
