import * as React from 'react';

const StatusDotChunkySvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 24 24" {...props}>
        <circle cx={12} cy={12} r={3} />
    </svg>
);

StatusDotChunkySvg.displayName = 'StatusDotChunkySvg';
export default StatusDotChunkySvg;
