import { asyncWait } from './wait';

export const DEFAULT_RETRY_SETTINGS = 5;
export const DEFAULT_MIN_DELAY_IN_MILLISECONDS = 2500;
export const DEFAULT_SHOULD_RETRY = () => true;
export const DEFAULT_ON_FAILED_ATTEMPT = () => {
    /* no op */
};
export const DEFAULT_ON_FAILED_LAST_ATTEMPT = () => {
    /* no op */
};

export interface RetrySettings {
    maxAttempts?: number;
    minDelayInMilliseconds?: number;
    shouldRetry?: (error: Error, attemptNumber: number) => boolean;
    onFailedAttempt?: (error: Error, attemptNumber: number) => void;
    onFailedLastAttempt?: (error: Error, attemptsCount: number) => void;
}

/**
 * @param callback Function to be attempted. Receives attempt number as parameter. First attempt is attempt number 0.
 * @param options
 * @param options.maxAttempts Maximum attempts to be performed. Use <1 to run continously until success.
 * @param options.minDelayInMilliseconds Minimum delay in milliseconds between two consecutive attempts.
 * @param options.shouldRetry Function that decides whether an attempt should be retried (return `true`) or not. Takes last attempt error and number as parameters. First attempt is attempt number 0.
 * @param options.onFailedAttempt Function called after each failed attempt and before the next one. Takes last attempt error and number as parameters. First attempt is attempt number 0.
 * @param options.onFailedLastAttempt Function called after the last attempt if it fails. Takes last attempt error and the total attempts count as parameters. If only one attempt has been performed, total attempts counts will be 1. Will be called regardless whether last error could be retried or not.
 */
export const retry = async <T>(
    callback: (attemptNumber: number) => Promise<T>,
    options: RetrySettings = {},
): Promise<T> => {
    const {
        maxAttempts = DEFAULT_RETRY_SETTINGS,
        minDelayInMilliseconds = DEFAULT_MIN_DELAY_IN_MILLISECONDS,
        shouldRetry = DEFAULT_SHOULD_RETRY,
        onFailedAttempt = DEFAULT_ON_FAILED_ATTEMPT,
        onFailedLastAttempt = DEFAULT_ON_FAILED_LAST_ATTEMPT,
    } = options;

    let lastError;

    for (let attemptNumber = 0; attemptNumber < maxAttempts; attemptNumber++) {
        try {
            const result = await callback(attemptNumber);
            return result;
        } catch (error) {
            onFailedAttempt(error, attemptNumber);

            const isLastAttempt = attemptNumber === maxAttempts - 1;
            if (isLastAttempt) {
                onFailedLastAttempt(error, attemptNumber + 1);
            }

            lastError = error;

            const isRetryableError =
                shouldRetry(error, attemptNumber) && !isLastAttempt;

            if (isRetryableError) {
                await asyncWait(minDelayInMilliseconds);
                continue;
            }

            throw error;
        }
    }

    throw lastError;
};
