import * as React from 'react';

const NaranjaPaymentSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 36 24" {...props}>
        <defs>
            <linearGradient
                x1="29.752%"
                y1="100%"
                x2="58.907%"
                y2="20.558%"
                id="naranja-payment_svg__a"
            >
                <stop stopColor="#FFF" offset="0%" />
                <stop stopColor="#F5CAB9" offset="100%" />
            </linearGradient>
        </defs>
        <g fill="none" fillRule="evenodd">
            <rect fill="#FF6602" width={36} height={24} rx={2} />
            <g fillRule="nonzero">
                <path
                    d="M0 12V9.176c2.321-.003 4.202-1.903 4.204-4.247V3 1L7 2v2.929C7 8.827 3.86 12 0 12z"
                    fill="url(#naranja-payment_svg__a)"
                    transform="translate(11 4)"
                />
                <path
                    d="M23.324 4c.73 0 1.323.63 1.323 1.407v11.789c.112.37.063.775-.142 1.115a1.424 1.424 0 01-.563.527 1.262 1.262 0 01-.618.162h-.047a1.44 1.44 0 01-1.26-.74L15.172 6.08a1.39 1.39 0 01.038-1.407c.268-.43.748-.685 1.259-.671.51.013.975.293 1.22.736l4.309 7.673L22 5.407C22 4.63 22.593 4 23.324 4z"
                    fill="#FFF"
                />
            </g>
        </g>
    </svg>
);

NaranjaPaymentSvg.displayName = 'NaranjaPaymentSvg';
export default NaranjaPaymentSvg;
