import findKey from 'lodash/findKey';
import interpolate from '@eb/interpolate-lazy-string';

import { CTA_TEXTS, DISCOUNT_CODE_SUCCESS_MESSAGES, STATUS } from './constants';
import { PROMO_CODE_TYPES } from '../../constants';
import getTerminology from '../../utils/terminology';

export const getCtaText = (status) => CTA_TEXTS[status];
export const getStatus = (props) =>
    findKey(STATUS, (value, key) => STATUS[key](props));

export const getSuccessMessage = ({
    promoCode,
    promoCodeType,
    percentOff,
    amountOff,
    numPromoCodeTickets,
    isRegEvent = false,
}) => {
    const promoCodeHtml = `<span class="promo-code-notification__code">${promoCode}&nbsp;</span>`;

    const accessCodeMessages = getTerminology(isRegEvent)
        .accessCodeSuccessMessages;
    let successMessage = DISCOUNT_CODE_SUCCESS_MESSAGES.default;

    if (promoCodeType === PROMO_CODE_TYPES.ACCESS) {
        successMessage = accessCodeMessages(numPromoCodeTickets);
    } else if (percentOff) {
        successMessage = DISCOUNT_CODE_SUCCESS_MESSAGES.percentOff;
    } else if (!percentOff && amountOff) {
        successMessage = DISCOUNT_CODE_SUCCESS_MESSAGES.amountOff;
    }
    return {
        __html: interpolate(
            successMessage,
            { promoCodeHtml, percentOff, amountOff },
            true,
        ),
    };
};
