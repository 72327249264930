export interface UserDataResponse {
    id: string;
    emails: Array<{
        email: string;
        verified: boolean;
        primary: boolean;
    }>;
    name: string;
    first_name: string;
    last_name: string;
    is_public: boolean;
    image_id: string;
}

export interface LoggedInUserData {
    publicId: string;
    email: string;
    fullName: string;
    firstName: string;
    lastName: string;
}

export interface UserSignInMethodsData {
    hasPassword: boolean;
    hasSocialLogin: boolean;
}

export const transformCurrentUserData = (
    userData: UserDataResponse,
): LoggedInUserData => {
    const loggedInUserData = {
        publicId: userData.id,
        email: userData.emails[0].email,
        fullName: userData.name,
        firstName: userData.first_name,
        lastName: userData.last_name,
    };

    return loggedInUserData;
};

export const transformUserSiginMethods = (
    siginMethods: Array<string>,
): UserSignInMethodsData => {
    const userSiginInMethods = {
        hasPassword: siginMethods.includes('password'),
        hasSocialLogin:
            siginMethods.includes('facebook_login') ||
            siginMethods.includes('apple_login'),
    };

    return userSiginInMethods;
};
