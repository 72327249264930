import { BASE_URL } from '../constants';
import { formatUrl } from 'url-lib';

export const getCheckoutWidgetLanguageUrl = (eventId, locale) =>
    `${BASE_URL}?eid=${eventId}&locale=${locale}`;

export const getCheckoutAppContextUrl = (contextQuery) =>
    formatUrl('/checkout-external', contextQuery);
/**
 * /checkout-widget-ajax
 */
export const getCheckoutWidgetAjaxUrl = (eventId, currentTime) =>
    `/checkout-widget-ajax/${eventId}/?cb=${currentTime}`;
export const getPaymentHistoryUrl = (currentTime) =>
    `/checkout-widget-ajax/payment_history/?cb=${currentTime}`;
export const getLastPaymentStatusUrl = (currentTime) =>
    `/checkout-widget-ajax/last_payment_status/?cb=${currentTime}`;

/**
 * /auth
 */
export const _getGenerateGuestTokenUrl = () => '/api/v3/auth/guest_tokens/';
export const _getLogoutUrl = () => '/api/v3/auth/logout/';

/**
 * /events
 */
export const getDeliveryMethodsUrl = (eventId) =>
    `/api/v3/events/${eventId}/delivery_methods/`;
export const getAddToWaitlistUrl = (eventId) =>
    `/api/v3/events/${eventId}/waitlist/add/`;
export const getPaymentConstraintsByEventUrl = (eventId) =>
    `/api/v3/events/${eventId}/ticket_classes/?expand=payment_constraints`;
export const getPaymentConstraintsByTicketUrl = (eventId, ticketClassId) =>
    `/api/v3/events/${eventId}/ticket_classes/${ticketClassId}/?expand=payment_constraints`;
export const getAvailablePaymentMethodIssuersInformationUrl = (eventId) =>
    `/api/v3/events/${eventId}/issuers_information/`;
export const getShippingRatesUrl = ({
    eventId,
    currency,
    envCountryCode,
    orgId,
}) => {
    let url = `/api/v3/events/${eventId}/shipping_rates/?country=${envCountryCode}&currency=${currency}`;
    if (orgId) {
        url = `${url}&organization_id=${orgId}`;
    }
    return url;
};

/**
 * /series
 */
export const SERIES_PAGE_SIZE = 12;

export const DEFAULT_SERIES_DATE_FILTER = {
    time_filter: 'current_future',
};

export const DEFAULT_SERIES_EXPANSIONS = [
    'series_dates',
    'ticket_availability',
    'event_sales_status',
    'venue',
];

export const getSeriesEventsUrl = ({
    eventId,
    expansions,
    pageSize,
    continuationToken,
    dateFilter,
} = {}) =>
    formatUrl(`/api/v3/series/${eventId}/events`, {
        ...dateFilter,
        expand: expansions && expansions.join(','),
        page_size: pageSize,
        continuation: continuationToken,
    });

/**
 * /missive
 */
export const _getMissiveOrgMarketingOptInUrl = (organizationId) =>
    `/api/v3/missive/organization/${organizationId}/contacts/`;

/**
 * /organizer
 */
export const _getOrganizerUrl = (organizationId) =>
    `/api/v3/organizers/${organizationId}/`;

/**
 * /orders
 */
export const BASE_ORDER_EXPANSION = 'questions,survey,registration_form';

// These expansions is used to get extra order data and survey responses for Transfers and Add Attendees flows
export const AUGMENTED_INFO_EXPANSION = 'augmented_info';
export const SURVEY_RESPONSES_EXPANSION = 'survey_responses';

const expand = (...expansions) => expansions.filter(Boolean).join(',');

export const _getOrderUrl = (orderId) =>
    `/api/v3/orders/${orderId}/?expand=${expand(
        BASE_ORDER_EXPANSION,
        AUGMENTED_INFO_EXPANSION,
        SURVEY_RESPONSES_EXPANSION,
    )}`;

export const _getAbandonOrderUrl = (orderId) =>
    `/api/v3/orders/${orderId}/abandon/`;
export const _getOrderAttendeesUrl = (orderId, { expansion = '', page = '' }) =>
    `/api/v3/orders/${orderId}/attendees/?expand=${expansion}&page=${page}`;
export const _getAvailablePaymentMethodsUrl = (eventId, orderId) =>
    `/api/v3/events/${eventId}/orders/${orderId}/payment_options/?include_user_instruments=true`;

export const _getCreateOrderUrl = () =>
    `/api/v3/orders/create/?expand=${expand(BASE_ORDER_EXPANSION)}`;
export const _getPlaceOrderUrl = (orderId) =>
    `/api/v3/orders/${orderId}/place/?expand=ticket_buyer_settings`;
export const _getPlacePayInUrl = (eventId) =>
    `/api/v3/events/${eventId}/payin/`;
export const _getResendEmailUrl = (orderId) =>
    `/api/v3/orders/${orderId}/resend_confirmation_email/`;
export const _getSavePaymentMethodUrl = (orderId) =>
    `/api/v3/orders/${orderId}/payment_methods/`;
export const _getClearPaymentMethodUrl = (orderId) =>
    `/api/v3/orders/${orderId}/payment_methods/`;
export const _getUpdateOrderUrl = (orderId) => `/api/v3/orders/${orderId}/`;
export const _updateEmailPreferencesUrl = (orderId) =>
    `api/v3/orders/${orderId}/email_preferences/`;
export const _getOrderEligibilityUrl = (orderId) =>
    `/api/v3/orders/${orderId}/eligibility/`;

export const BEST_AVAILABLE_NOT_FOUND_ERROR = 'ERROR_NO_MATCH_FOUND';
/**
 * /payments
 */
export const _getBraintreeClientTokenUrl = () =>
    '/api/v3/payments/braintree/client_token/';
export const _getBraintreeNonceUrl = () => '/api/v3/payments/braintree/nonce/';

/**
 * /queue_rpc
 */
export const _getQueueAcquireUrl = (queueServer, queueId, step) =>
    `${queueServer}/queue_rpc/acquire/${queueId}/?step=${step}`;
export const _getQueueRenewUrl = (
    queueServer,
    clientId,
    queueId,
    isLive,
    allocationTime,
    hashCode,
    step,
) =>
    `${queueServer}/queue_rpc/renew/${clientId}/${queueId}/${isLive}/${allocationTime}/${hashCode}/?step=${step}`;
export const _getQueueRefreshUrl = (queueServer, clientId, step) =>
    `${queueServer}/queue_rpc/renew/${clientId}/?step=${step}`;
export const _getQueueFreeUrl = (clientId, allocationTime, hashCode) =>
    `/queue_rpc/free/${clientId}/${allocationTime}/${hashCode}/`;

/**
 * /reserved
 */
export const _getBestAvailableUrl = (eventId) =>
    `/reserved/ajax/best_available/${eventId}/`;

/**
 * /users
 */
export const _getVaultInstrumentsUrl = (userId) =>
    `/api/v3/users/${userId}/vault_instruments/`;
export const _getListVaultInstrumentsUrl = (userId) =>
    `${_getVaultInstrumentsUrl(userId)}?instrument_types=CREDIT_CARD`;
export const _getUserAccountDetails = () => '/api/v3/users/lookup/';
export const _getCurrentUserAccountDetailsUrl = () => '/api/v3/users/me/';

/**
 * group registration
 */
export const _getGroupSearchUrl = (eventId, querySearch) =>
    `/api/v3/events/${eventId}/teams/search/?term=${querySearch}`;
export const _validateGroupPasswordUrl = (eventId, teamId) =>
    `/api/v3/events/${eventId}/teams/${teamId}/check_password/`;
export const _getGroupCreateUrl = (eventId) =>
    `/api/v3/events/${eventId}/teams/create/`;

/**
 * sponsorship assets
 */
export const _getSponsorshipAssetsUrl = (eventId, params) =>
    formatUrl(`/api/v3/sponsor/events/${eventId}/assets/`, {
        ...params,
    });

/**
 * tax policies
 */
export const _getTaxPolicies = (country) =>
    `/api/v3/tax_policies/?tax_country=${country}`;

export const _validateTaxIdCountry = (eventId, country, taxId) =>
    formatUrl(`/ajax/tax/validate_id_and_country_exemptions`, {
        event_id: eventId,
        country: country,
        tax_id: taxId,
    });
