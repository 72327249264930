import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import momentTimezone from 'moment-timezone';

import gettext from '@eb/gettext';
import { moment } from '@eb/date';
import {
    getFormattedDateTime,
    getFormattedTimezone,
    constants as datetimeConstants,
} from '@eb/datetime';

const {
    DEFAULT_TIME_FORMAT,
    DEFAULT_TIMEZONE,
    DEFAULT_LANGUAGE,
    DEFAULT_DATETIME_FORMAT,
} = datetimeConstants;

/**
 * getFormattedDateTimeRange
 *
 * Returns a string representing a datetime range formatted for a given
 * timezone. If no timezone is provided, use the default timezone. If showTimezone
 * is false, do not display the timezone as part of the range.
 *
 * Note: In the future, instead of a default timezone it may be better to use
 * the browser's timezone. Moment Timezone 0.5.0 has a function momentTimezone.tz.guess(),
 * which we could use if we upgrade the library. (EB-43817)
 *
 * @param  {Object} event
 * @param  {String} locale
 * @param  {String} dateTimeFormat
 * @return {String}, an event's datetime range
 */
export const getFormattedDateTimeRange = (
    event,
    locale = DEFAULT_LANGUAGE,
    dateTimeFormat = DEFAULT_DATETIME_FORMAT,
) => {
    let formattedDateTimeRange = '';
    const startDateTime = get(event, 'start.utc');
    const endDateTime = get(event, 'end.utc');
    const timezone = get(event, 'start.timezone', DEFAULT_TIMEZONE);
    const showTimezone = get(
        event,
        'listingDisplayFlags.shouldShowTimezone',
        true,
    );
    const startDateTimeAsMoment = momentTimezone
        .tz(startDateTime, timezone)
        .locale(locale);
    const endDateTimeAsMoment = momentTimezone
        .tz(endDateTime, timezone)
        .locale(locale);

    // If the two dates are on the same day in the given timezone, the end datetime
    // should be formatted to include only the time to avoid printing the same
    // information twice
    const endDateTimeFormat = startDateTimeAsMoment.isSame(
        endDateTimeAsMoment,
        'day',
    )
        ? DEFAULT_TIME_FORMAT
        : dateTimeFormat;
    const formattedStartDateTime = startDateTimeAsMoment.format(dateTimeFormat);
    const formattedEndDateTime = endDateTimeAsMoment.format(endDateTimeFormat);

    if (showTimezone) {
        // 'Tue, Feb 28, 2017 7:00 PM - Sat, Mar 4, 2017 7:00 PM EST'
        formattedDateTimeRange = gettext(
            '%(formattedStartDateTime)s - %(formattedEndDateTime)s %(timezone)s',
            {
                formattedStartDateTime,
                formattedEndDateTime,
                timezone: getFormattedTimezone(timezone, startDateTime),
            },
        );
    } else {
        // 'Tue, Feb 28, 2017 7:00 PM - Sat, Mar 4, 2017 7:00 PM'
        formattedDateTimeRange = gettext(
            '%(formattedStartDateTime)s - %(formattedEndDateTime)s',
            {
                formattedStartDateTime,
                formattedEndDateTime,
            },
        );
    }

    return formattedDateTimeRange;
};

const _getStartDayAndTime = (startDayOfTheWeek, startTime, formattedTimezone) =>
    // 'Sun, 7:00 PM PDT' or
    // 'Sun, 7:00 PM'
    gettext('%(startDayOfTheWeek)s, %(startTime)s %(formattedTimezone)s', {
        startDayOfTheWeek,
        startTime,
        formattedTimezone,
    });

const _getDayAndTimeMultiDayRange = (
    startDayOfTheWeek,
    startTime,
    endDayOfTheWeek,
    endTime,
    formattedTimezone,
) =>
    // 'Fri, 9:00 PM - Sat, 2:00 AM PDT' or
    // 'Fri, 9:00 PM - Sat, 2:00 AM'
    gettext(
        '%(startDayOfTheWeek)s, %(startTime)s - %(endDayOfTheWeek)s, %(endTime)s %(formattedTimezone)s',
        {
            startDayOfTheWeek,
            startTime,
            endDayOfTheWeek,
            endTime,
            formattedTimezone,
        },
    );

const _getSingleDayAndTimeRange = (
    startDayOfTheWeek,
    startTime,
    endTime,
    formattedTimezone,
) =>
    // 'Sun, 7:00 PM - 10:00 PM PDT' or
    // 'Sun, 7:00 PM - 10:00 PM'
    gettext(
        '%(startDayOfTheWeek)s, %(startTime)s - %(endTime)s %(formattedTimezone)s',
        {
            startDayOfTheWeek,
            startTime,
            endTime,
            formattedTimezone,
        },
    );

/**
 * Returns a localized day of the week and time or time range to display
 * for each child event on the repeating event parent page.
 * For instance:
 * (may include timezone or not)
 * Tue, 7:00 PM PST
 * Tue, 7:00 PM - 9:00 PM PST
 * Fri, 9:00 PM - Sat, 2:00 AM PST
 * ven., 21:00 - sam., 02:00 CET
 *
 * @param {*} startDateTime
 * @param {*} endDateTime
 * @param {*} showTimezone
 * @param {*} timezone
 * @param {*} locale
 */
export const getFormattedChildEventDayAndTime = ({
    startDateTime,
    endDateTime,
    timezone,
    locale,
    hideEndDate = false,
    showTimezone = true,
    shouldHideSeriesStartTime = false,
}) => {
    let formattedDayAndTime = '';

    const startDateTimeAsMoment = moment(startDateTime).locale(locale);
    const endDateTimeAsMoment = moment(endDateTime).locale(locale);

    const isMultiDayEvent = !startDateTimeAsMoment.isSame(
        endDateTimeAsMoment,
        'day',
    );

    const startDayOfTheWeek = startDateTimeAsMoment.format('ddd');
    const endDayOfTheWeek = isMultiDayEvent
        ? endDateTimeAsMoment.format('ddd')
        : '';

    const startTime = startDateTimeAsMoment.format(DEFAULT_TIME_FORMAT);
    const endTime = endDateTimeAsMoment.format(DEFAULT_TIME_FORMAT);

    const formattedTimezone = showTimezone
        ? getFormattedTimezone(timezone, startDateTime)
        : '';

    if (shouldHideSeriesStartTime) {
        // only show the start day of the week, but use the full day rather
        // than the abbreviation, e.g. 'Tuesday'
        formattedDayAndTime = startDateTimeAsMoment.format('dddd');
    } else if (hideEndDate) {
        formattedDayAndTime = _getStartDayAndTime(
            startDayOfTheWeek,
            startTime,
            formattedTimezone,
        );
    } else if (isMultiDayEvent) {
        formattedDayAndTime = _getDayAndTimeMultiDayRange(
            startDayOfTheWeek,
            startTime,
            endDayOfTheWeek,
            endTime,
            formattedTimezone,
        );
    } else {
        formattedDayAndTime = _getSingleDayAndTimeRange(
            startDayOfTheWeek,
            startTime,
            endTime,
            formattedTimezone,
        );
    }

    // must trim to remove trailing space if !showTimezone
    return formattedDayAndTime.trimEnd();
};

/**
 * Returns the full event date range string to display in the subtitle on the modal
 * and purchase confirmation page
 * @param {object} event
 * @param {string} locale
 */
export const getFormattedEventDate = (event, locale) => {
    if (isEmpty(event)) {
        return '';
    }

    const {
        listingDisplayFlags: { shouldShowTimezone } = {},
        hideStartDate,
        hideEndDate,
    } = event;

    if (hideStartDate && hideEndDate) {
        return shouldShowTimezone
            ? getFormattedTimezone(event.start.timezone)
            : '';
    }

    let eventDateTime = '';

    if (hideStartDate) {
        // 'Ends on Sun, Apr 14, 2019 10:00 PM PDT'
        eventDateTime = gettext('Ends on %(datetime)s', {
            datetime: getFormattedDateTime(
                event.end.utc,
                shouldShowTimezone,
                event.start.timezone,
                locale,
            ),
        });
    } else if (hideEndDate) {
        // 'Starts on Sun, Apr 14, 2019 7:00 PM PDT'
        eventDateTime = gettext('Starts on %(datetime)s', {
            datetime: getFormattedDateTime(
                event.start.utc,
                shouldShowTimezone,
                event.start.timezone,
                locale,
            ),
        });
    } else {
        // 'Sun, Apr 14, 2019 7:00 PM - 10:00 PM PDT'
        eventDateTime = getFormattedDateTimeRange(
            event,
            locale,
            DEFAULT_DATETIME_FORMAT,
        );
    }

    return eventDateTime;
};

/**
 * Returns the event payment due date range string
 * to display on the cash based payment method's purchase confirmation page
 * @param {object} event
 * @param {string} locale
 * @param {Number} deferredPaymentDueDays
 */
export const getFormattedDueDate = (event, locale, deferredPaymentDueDays) => {
    let dueDateFormatted = '';
    const dueDateFormat = 'LL';
    const dueDate = moment().add(deferredPaymentDueDays, 'day');

    dueDateFormatted = getFormattedDateTime(
        dueDate,
        false,
        null,
        locale,
        dueDateFormat,
    );

    return dueDateFormatted;
};
