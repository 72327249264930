import escape from 'lodash/escape';
import find from 'lodash/find';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import head from 'lodash/head';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import partialRight from 'lodash/partialRight';
import pick from 'lodash/pick';

import {
    CHECKOUT_FORM_NAME,
    ORDER_ATTENDEE_WITH_ASSIGNED_UNIT_SHAPE_OBJECT,
    ORDER_TYPE_MANUAL,
    ORDER_TYPE_TRANSFER,
    PAYMENT_METHODS,
    ROYALTY_COMPONENT_NAME,
} from '../constants';
import {
    ORDER_DISQUALIFICATIONS,
    ORDER_DISQUALIFICATIONS_FIELD_MAP,
    SEND_EMAIL_TO_ATTENDEES_FIELD,
} from '../containers/checkout/constants';
import { getDisplayCostFromValue } from '../containers/ticketSelection/utils/ticketPriceUtils';
import gettext from '@eb/gettext';
import { OrderStatus } from '../models/order';
import { createSelector } from './utils/selector';
import { getDisplayCostProps } from './tickets';
import { isUserLoggedIn } from './user';
import { getSelectedDeliveryMethodsForDisplay } from './deliveryMethod';

// Helpers
const getAssignedUnitsFromAttendees = (attendees) =>
    attendees.map((attendee) => attendee.assignedUnit);
const getAssignedUnitsFromFormattedAttendees = (attendees) =>
    getAssignedUnitsFromAttendees(attendees).filter((i) => i);

/**
 * @typedef {Object} Attendee
 * @property {Object} [assignedUnit] - Only for admission inventory
 * @property {String} team
 * @property {Object} costs
 * @property {String} resourceUri
 * @property {String} id
 * @property {String} changed
 * @property {String} created
 * @property {Number} quantity
 * @property {String} variantId
 * @property {String} profile
 * @property {Array} barcodes
 * @property {Array} answers
 * @property {Boolean} checkedIn
 * @property {Boolean} cancelled
 * @property {Boolean} refunded
 * @property {String} affiliate
 * @property {String} guestlistId
 * @property {String} invitedBy
 * @property {String} status
 * @property {String} ticketClassName
 * @property {String} deliveryMethod
 * @property {String} eventId
 * @property {String} orderId
 * @property {String} ticketClassId
 * @property {Array} survey
 * @property {Object} ticketClass
 */

const getOrderEligibility = (state) =>
    get(state, 'order.eligibility', {
        eligible: true,
        disqualifications: [],
    });

/**
 * Returns an array of attendees if an order exists. If there is no order yet, which currently is
 * common for Pick-a-seat flows, returns an empty array.
 *
 * @param {Object} state
 * @returns {Array.<Attendee>} Array of type Attendee
 */
export const getAttendees = (state) => get(state, 'order.attendees', []);

/**
 * Returns an array of attendees formatted for
 * @param {Object} state
 * @return {Array}
 */
export const getFormattedAttendeesWithAssignedUnit = (state) =>
    getAttendees(state).map((attendee) =>
        pick(
            attendee,
            Object.keys(ORDER_ATTENDEE_WITH_ASSIGNED_UNIT_SHAPE_OBJECT),
        ),
    );

/**
 * Returns an array of formatted assigned units for event attendees
 *
 * @param {Object} state
 * @returns {Array}
 */
export const getAssignedUnits = (state) =>
    getAssignedUnitsFromFormattedAttendees(
        getFormattedAttendeesWithAssignedUnit(state),
    );

/**
 * Returns an array of attendees for the event who have assigned units (ie have seat locations and
 * don't represent non-admission inventory)
 *
 * @param {Object} state
 * @returns {Array}
 */
export const getAttendeesWithAssignedUnits = (state) =>
    getFormattedAttendeesWithAssignedUnit(state).filter(
        (attendee) => attendee.assignedUnit,
    );

/**
 * Returns the assigned unit id, ie map seat location, for an attendee assigned unit
 *
 * @param {Object} state
 * @returns {String} Unit ID
 */
export const getFirstAssignedUnitIdFromOrder = (state) =>
    get(head(getAttendees(state)), 'assignedUnit.unitId', null);

/**
 * Returns true when there is an exising order ID
 *
 * @param {Object} state the application state
 */
export const hasExistingOrder = (state) =>
    Boolean(get(state, 'app.existingOrderId', false));

/**
 * Returns true when there is an exising order ID and is a manual order
 *
 * @param {Object} state the application state
 */
export const isAddAttendeesOrder = (state) => {
    return (
        hasExistingOrder(state) &&
        get(state, 'order.augmentedInfo.orderType') === ORDER_TYPE_MANUAL
    );
};

/**
 * Returns true when there is an existing order ID and orderType is `transfer`
 *
 * @param {Object} state the application state
 */
export const isTransferredOrder = (state) =>
    hasExistingOrder(state) &&
    get(state, 'order.augmentedInfo.orderType') === ORDER_TYPE_TRANSFER;

/**
 * Returns true when it is a transferred order that was started by the org
 *
 * @param {Object} state the application state
 */
export const isOrganizerInitiatedTransferOrder = (state) =>
    isTransferredOrder(state) &&
    get(state, 'order.augmentedInfo.initiatedByOrganizer', false);

/**
 * Returns true when an organizer is managing an alien pre-created order (either an attendee transfer or adding an attendee)
 *
 * @param {Object} state the application state
 */
export const isOrganizerManagingAnAlienOrder = (state) =>
    isOrganizerInitiatedTransferOrder(state) || isAddAttendeesOrder(state);

/**
 * Returns true if the gross value cost of the order is > 0 and it is not an already existing order
 *
 * @param {*} state the application state
 */
export const isPaidOrder = (state) =>
    get(state, 'order.costs.gross.value', 0) > 0 && !isAddAttendeesOrder(state);

/**
 * Returns total gross value of the order
 *
 * @param {*} state the application state
 */
export const getTotalCostsFromGrossValue = (state) =>
    get(state, 'order.costs.gross.value', 0);

/**
 * Returns total gross major value of the order
 *
 * @param {*} state the application state
 */
export const getTotalCostsFromGrossMajorValue = (state) =>
    get(state, 'order.costs.gross.majorValue', 0);

/**
 * Selector to determine if the order cart has any attendees which makes the order ineligible for checkout.
 *
 */
export const getDisqualifiedAttendees = createSelector(
    getAttendees,
    getOrderEligibility,
    (attendees, orderEligibility) => {
        const { disqualifications } = orderEligibility;

        if (isEmpty(attendees)) {
            return [];
        }

        if (!orderEligibility.eligible) {
            const attendeesGroupByTicketClassId = groupBy(
                attendees,
                'ticketClass.id',
            );
            const disqualifiedAttendees = [];
            let problemAttendees;

            disqualifications.forEach(({ reason, ticketClassId }) => {
                const attendeesForTicket =
                    attendeesGroupByTicketClassId[ticketClassId];

                if (reason === ORDER_DISQUALIFICATIONS.BELOW_MINIMUM_QUANTITY) {
                    const numOfAttendeesForTicket = attendeesForTicket.length;

                    problemAttendees = attendeesForTicket.filter(
                        (attendee) =>
                            numOfAttendeesForTicket <
                            attendee.ticketClass.minimumQuantity,
                    );
                } else {
                    problemAttendees = attendeesForTicket;
                }

                disqualifiedAttendees.push(...problemAttendees);
            });

            return disqualifiedAttendees;
        }

        return [];
    },
);

const getDisqualifiedTicketClassIdsByReason = (state, filterReason) => {
    const { disqualifications } = getOrderEligibility(state);

    if (isEmpty(disqualifications)) {
        return [];
    }

    return disqualifications
        .filter(({ reason }) => reason === filterReason)
        .map(({ ticketClassId }) => ticketClassId);
};

const getBelowMiniumQuantityTicketClassIds = partialRight(
    getDisqualifiedTicketClassIdsByReason,
    ORDER_DISQUALIFICATIONS.BELOW_MINIMUM_QUANTITY,
);

/**
 * Selector to retrieve the formatted error message for the first carted ticket that doesn't meet the minimum quantity requirement
 */
export const getMinimumQuantityErrorMessage = createSelector(
    getBelowMiniumQuantityTicketClassIds,
    getDisqualifiedAttendees,
    (belowMiniumQuantityTicketClassIds, disqualifiedAttendees) => {
        const ticketClassFieldName =
            ORDER_DISQUALIFICATIONS_FIELD_MAP.BELOW_MINIMUM_QUANTITY;
        let errorMessage;

        if (
            !isEmpty(disqualifiedAttendees) &&
            !isEmpty(belowMiniumQuantityTicketClassIds)
        ) {
            // Find the first problem attendee, the error message will be shown for one problem at one time
            const problemAttendee = find(disqualifiedAttendees, (attendee) =>
                includes(
                    belowMiniumQuantityTicketClassIds,
                    get(attendee, 'ticketClass.id'),
                ),
            );

            if (problemAttendee) {
                errorMessage = gettext(
                    '%(ticketName)s quantity must be %(minQuantity)s or more.',
                    {
                        ticketName: `<b>${escape(
                            problemAttendee.ticketClass.name,
                        )}</b>`,
                        minQuantity:
                            problemAttendee.ticketClass[ticketClassFieldName],
                    },
                );
            }
        }

        return errorMessage;
    },
);

export const getDisableEmailToAttendees = (state) => {
    const sendEmailToAttendees = get(state, [
        'form',
        CHECKOUT_FORM_NAME,
        'values',
        SEND_EMAIL_TO_ATTENDEES_FIELD,
    ]);

    return isAddAttendeesOrder(state) && sendEmailToAttendees === false;
};

/**
 * Selector that can be used to retrieve the correct email configuration for an order
 * @param {*} state
 */
export const getEmailConfiguration = (state) => {
    const {
        app: {
            featureFlags: { shouldBlockMarketingEventbriteEmails } = {},
        } = {},
    } = state;

    const shouldDisableEmailToAttendees = getDisableEmailToAttendees(state);
    const disableMarketingEmail =
        shouldBlockMarketingEventbriteEmails || shouldDisableEmailToAttendees;
    const disableConfirmationEmail = shouldDisableEmailToAttendees;

    let body = {};

    if (disableMarketingEmail || disableConfirmationEmail) {
        body = {
            /* eslint-disable camelcase */
            email_configuration: {
                disable_new_user_email: disableMarketingEmail,
                disable_confirmation_email: disableConfirmationEmail,
                disable_pdf_ticket: disableMarketingEmail,
                disable_newsletter_email: disableMarketingEmail,
                disable_attendee_news_email: disableMarketingEmail,
            },
            /* eslint-enable camelcase */
        };
    }

    return body;
};

export const getOrder = (state) => get(state, 'order', {});

/**
 * Given the orderCosts.feeComponents, we combind all order fee components
 * with the same name to be presented in order summary fee breakdown.
 *
 * @param {*} feeComponents - Order fee components from order service
 * @returns {Array} Fee components summed/combined by name and group
 * @was containers/pane/utils.js:getOrderFeeComponents
 */
export const _combineOrderFeeComponentsByName = (feeComponents) =>
    feeComponents.reduce((acc, element) => {
        const index = acc.findIndex(
            ({ groupName }) =>
                groupName.toLowerCase().trim() ===
                element.groupName.toLowerCase().trim(),
        );

        let el = element;

        if (el.groupName && el.name !== el.groupName) {
            el.name = el.groupName;
        }

        if (el.payer === 'attendee') {
            if (index !== -1) {
                el = acc[index];
                el.value += element.value;
                acc.splice(index, 1);
            }
            acc.push(el);
        }

        return acc;
    }, []);

/**
 * Gets the cost object from the order if it exists
 * @param {object} order An order as returned from order service
 * @was `utils/forms.js:getOrderCosts`
 */
export const getOrderCosts = (state, hasFeeBreakdown = false) => {
    const order = getOrder(state);
    let costs;

    if (!isEmpty(order) && order.costs) {
        costs = order.costs;
        if (costs.feeComponents) {
            costs.feeComponents = hasFeeBreakdown
                ? _combineOrderFeeComponentsByName(costs.feeComponents)
                : [];
        }
    }

    return costs;
};

/**
 * Selector that can be used to retrieve the current order token
 * @param {Object} state
 */
export const getOrderToken = (state) => get(state, 'tokens.orderToken');

/**
 * Selector to calculate shipping fees
 * @param {Object} state
 */
export const getCalculatedShippingFees = (state) => {
    const { event: { shippingRates } = {} } = state;

    // Update display order cost with MoD fee
    let shippingFee = 0;

    if (shippingRates) {
        const selectedDeliveryMethods = getSelectedDeliveryMethodsForDisplay(
            state,
        );
        selectedDeliveryMethods.forEach((deliveryMethod) => {
            const shippingRate = find(
                shippingRates,
                ({ name }) => name === deliveryMethod,
            );

            if (shippingRate) {
                shippingFee += shippingRate.price.value;
            }
        });
    }
    return shippingFee;
};

/**
 * Selector to calculate the unformatted value of order costs
 * If order costs are present, return the total cost value
 * based on the values, as they are calculated in the backend by order service.
 * Fall back to calculating in the FE using based on selected tickets.
 *
 * @param {Object} state
 * @returns {Number} returns whole number such as 1500 for a $15.00 order value
 */
export const getUnformattedOrderCost = (state) => {
    const orderCosts = getOrderCosts(state, true);

    if (orderCosts) {
        const shippingFee = getCalculatedShippingFees(state);
        return orderCosts.gross.value + shippingFee;
    }

    return getDisplayCostProps(state).totalCostUnformatted;
};

/**
 * If order costs are present, return the total cost display value
 * based on the values, as they are calculated in the backend by order service.
 * Fall back to calculating in the FE using based on selected tickets.
 *
 * @param {object} state The entire redux state
 * @was `utils/forms.js:getTotalCostsDisplay`
 */
// TODO: Disambiguate from `containers/ticketSelection/utils.js:getTotalDisplayCost
// it appears these functions do pretty much the same thing?
export const getTotalCostDisplay = (state) => {
    const orderCosts = getOrderCosts(state, true);

    // TODO: Remove this update after removing shippingRates endpoints
    if (orderCosts) {
        const shippingFee = getCalculatedShippingFees(state);
        return getDisplayCostFromValue(
            orderCosts.gross.value + shippingFee,
            state.event.currency,
            state.app.currencyFormat,
        );
    }

    return getDisplayCostProps(state).totalCostDisplay;
};

const isSelectedWithShippingFee = ({ displayShippingFee, selectedQuantity }) =>
    selectedQuantity > 0 && displayShippingFee?.value;

export const haveSelectedTicketsShippingFee = (state) => {
    const { tickets } = state;

    if (isEmpty(tickets)) {
        return false;
    }

    return !!Object.values(tickets.ticketsById).find(
        ({ displayShippingFee, selectedQuantity, variants = [] }) => {
            if (isEmpty(displayShippingFee)) {
                return variants.find(isSelectedWithShippingFee);
            }

            return isSelectedWithShippingFee({
                displayShippingFee,
                selectedQuantity,
            });
        },
    );
};

const isTicketWithShippingFee = ({ displayShippingFee }) => {
    return displayShippingFee?.value;
};

export const isEventWithShippingFee = (state) => {
    const { tickets } = state;

    if (isEmpty(tickets)) {
        return false;
    }

    return !!Object.values(tickets.ticketsById).find(
        ({ displayShippingFee, variants = [] }) => {
            if (isEmpty(displayShippingFee)) {
                return variants.find(isTicketWithShippingFee);
            }
            return isTicketWithShippingFee({ displayShippingFee });
        },
    );
};

/**
 * Selector for checking if the checkout summary pane
 * should show the fee breakdown for multiple royalties.
 * This will return true if there are multiple royalties on a ticket.
 * @param {*} state
 * @returns {Boolean} true if tickets or order costs have royalties
 * @was `containers/pane/utils.js`
 */
export const hasMultipleRoyalties = (state) => {
    const {
        tickets,
        order: { costs: { feeComponents = [] } = {} } = {},
    } = state;
    let components = [];
    let royalties = [];

    if (!isEmpty(tickets)) {
        tickets.ticketIds.forEach((id) => {
            const { feeComponents, variants } = tickets.ticketsById[id];

            if (isEmpty(feeComponents)) {
                // in reserved or tiered inventory - get variant components
                variants.forEach(({ feeComponents }) => {
                    if (!isEmpty(feeComponents)) {
                        components = components.concat(feeComponents);
                    }
                });
            } else {
                components = components.concat(feeComponents);
            }
        });
        // Old royalty name and groupName should be equal.
        // For multiple royalties name and groupName should be different.
        // TODO: document and explain this with more test cases
        // Why does it not count when name === groupName
        royalties = components.filter(
            ({ name, groupName }) =>
                name.includes(ROYALTY_COMPONENT_NAME) && name !== groupName,
        );

        if (!isEmpty(royalties)) {
            return true;
        }
    }

    // For donation only - check order costs fee components
    // as fees on donation are calculated once order created
    // Old royalty internalName and name should be equal.
    // For multiple royalties name and internalName should be different.
    // TODO: document and explain this with more test cases
    // Why does it not count when name === internalName
    if (!isEmpty(feeComponents)) {
        royalties = feeComponents.filter(
            ({ name, internalName }) =>
                internalName.includes(ROYALTY_COMPONENT_NAME) &&
                name !== internalName,
        );

        if (!isEmpty(royalties)) {
            return true;
        }
    }

    return false;
};
/**
 * Given availablePaymentMethods this returns the available credit card types
 * eg. VISA, MASTERCARD
 * Returns [] if there's no credit card available
 * @param state - redux state
 * @returns {[]}
 */
export const getAvailableCreditCardTypes = (state) => {
    const { order: { availablePaymentMethods = [] } = {} } = state;

    const eventbritePaymentMethod = availablePaymentMethods.find(
        (pm) => pm.type === 'eventbrite',
    );

    if (!eventbritePaymentMethod) return [];

    const creditCardInstrumentType = eventbritePaymentMethod.instrumentTypes.find(
        (i) => i.type.trim() === PAYMENT_METHODS.CREDIT,
    );

    return creditCardInstrumentType ? creditCardInstrumentType.variants : [];
};

export const getOrderErrorProps = (state) => {
    const {
        order: { status, id },
    } = state;

    let externalPaymentDeclinedError = undefined;

    if (status === OrderStatus.declined) {
        externalPaymentDeclinedError = gettext(
            'There was a problem with your payment. Please choose another method and try again.',
        );
    }

    return {
        orderId: id,
        externalPaymentDeclinedError,
    };
};

export const hasVirtualIncentivesErrors = (state) =>
    !!get(state, 'order.hasVirtualIncentivesErrors', false);

export const getIsOrderUpdating = (state) => !!state.order.isUpdating;

export const getbraintreeClientToken = (state) =>
    state.order.braintreeClientToken;

export const shouldManageUserSavedSurveyData = (state) =>
    isUserLoggedIn(state) && !isAddAttendeesOrder(state);
