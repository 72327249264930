import isEmpty from 'lodash/isEmpty';
import {
    CAPACITY_WAITLIST,
    WAITLIST_RELEASE_SERVER_ERRORS,
    WAITLIST_DISABLED,
} from '../constants';
import {
    EVENT_ALL_TICKETS_REQUIRE_UNLOCK_STATUS,
    EVENT_SOLD_OUT_WITH_CAPACITY_WAITLIST_STATUS,
} from '../containers/status/constants';

/**
 * Returns true if waitlist is enabled and available for new waitlist entries
 * regardless of waitlist type.
 *
 * @param   {object} waitlistSettings   the waitlistSettings on the event
 * @return  {Boolean}
 */
export const isWaitlistAvailable = (waitlistSettings) =>
    Boolean(waitlistSettings && !waitlistSettings.full);

/**
 * Returns true if waitlist is enabled and available for new waitlist entries
 * and the event's waitlist has a ticket level setting.
 * On its own, does not necessarily mean the waitlist *should* be shown as
 * this method does not check each ticket's status.
 *
 * @param   {object} waitlistSettings   the waitlistSettings on the event
 * @return  {Boolean}
 */
export const eventHasTicketLevelWaitlist = (waitlistSettings) =>
    isWaitlistAvailable(waitlistSettings) &&
    waitlistSettings.ticketId !== CAPACITY_WAITLIST;

/**
 * Returns true if the error is a waitlist related error
 * We only check the first error because the server will only send us
 * one error at a time right now
 * @param {array} errors    array of error objects
 */
const _hasWaitlistError = (errors) =>
    !isEmpty(errors) &&
    WAITLIST_RELEASE_SERVER_ERRORS.includes(errors[0].error);

/**
 * Waitlist invalid status page should only show when the server returns a Waitlist error and the event is
 * also in a state where it should show a status like "event is sold out". This happens when:
 * - a user clicks a link from the waitlist release email,
 * - their waitlist release spot has expired or is otherwise invalid,
 * - the waitlist is no longer available for sign ups / the event is in a state where we'd normally
 *   show an event status.
 * Instead of routing the user directly to that event status page, which could cause confusion, we should
 * show them the waitlist invalid status page instead with more info.
 * Exceptions: if the event status is the waitlist capacity page, that means the waitlist is still available
 * so we need to route there. And, if the event has the unlock status, we should route there (the waitlist error
 * will be shown later in the flow at tickets selection).
 * @param {string} eventStatus
 * @param {array} errors
 */
export const shouldShowWaitlistInvalidStatusPage = (eventStatus, errors) =>
    Boolean(
        eventStatus &&
            eventStatus !== EVENT_SOLD_OUT_WITH_CAPACITY_WAITLIST_STATUS &&
            eventStatus !== EVENT_ALL_TICKETS_REQUIRE_UNLOCK_STATUS &&
            _hasWaitlistError(errors),
    );

/**
 * Waitlist disabled status page should only show when the server returns us this specific error.
 * @param {array} errors
 */
export const shouldShowWaitlistDisabledStatusPage = (errors) =>
    !isEmpty(errors) && errors[0].error === WAITLIST_DISABLED;

/**
 * Event Capacity Waitlist page should only display when:
 * - shouldShowWaitlistAtCapacity is true from the backend
 *   (this also accounts for when we should show the waitlist page when all regular tickets are sold out
 *    but there are unlimited donations)
 * - a waitlist code is not passed
 *   (if it is, that means a ticket is being released and we should route to ticket selection instead)
 * - tickets are not hidden
 * @param {object} event
 * @param {object} ticketsById
 * @param {string} waitlistCode
 */
export const shouldShowEventCapacityWaitlistPage = (
    event,
    ticketsById,
    waitlistCode,
) =>
    event.listingDisplayFlags.shouldShowWaitlistAtCapacity &&
    !isEmpty(ticketsById) &&
    !waitlistCode;
