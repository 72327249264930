import PropTypes from 'prop-types';
import gettext from '@eb/gettext';

export const FORM_STATUS = {
    EMPTY: 'empty',
    FAILURE: 'submitFailed',
    SUCCESS: 'submitSucceeded',
    DEFAULT: 'default',
};

export const CTA_TEXTS = {
    [FORM_STATUS.EMPTY]: gettext('Apply'),
    [FORM_STATUS.FAILURE]: gettext('Clear'),
    [FORM_STATUS.SUCCESS]: gettext('Remove Code'),
    [FORM_STATUS.DEFAULT]: gettext('Apply'),
};

export const STATUS = {
    [FORM_STATUS.EMPTY]: ({ pristine, options: { promoCodeType } }) =>
        pristine && !promoCodeType,
    [FORM_STATUS.FAILURE]: ({ submitFailed }) => submitFailed,
    [FORM_STATUS.SUCCESS]: ({ options: { promoCodeType } }) => promoCodeType,
    [FORM_STATUS.DEFAULT]: () => true,
};

/**
 * Note: These are specific to discount codes.
 * Access code success messages with specific ticketing language
 * live in constants/terminology
 */
export const DISCOUNT_CODE_SUCCESS_MESSAGES = {
    default: gettext('%(promoCodeHtml)s applied.'),
    percentOff: gettext(
        '%(promoCodeHtml)s applied. A %(percentOff)s% discount is available.',
    ),
    amountOff: gettext(
        '%(promoCodeHtml)s applied. A %(amountOff)s discount is available.',
    ),
};

export const INPUT_LABEL = gettext('Promo Code');
export const INPUT_PLACEHOLDER = gettext('Enter code');

export const UNLOCK_TICKETS_TEXTS = {
    INPUT_LABEL: gettext('Access Code'),
    TITLE: gettext(
        'You must have an access code to view tickets for this event.',
    ),
};

export const NOT_APPLICABLE_CODE_TEXTS = {
    LABEL: gettext('Sorry, the code you entered does not apply.'),
    CTA: gettext('Clear'),
};

export const HANDLER_PROP_TYPE = PropTypes.shape({
    /**
     * Called for every change in the text input entry field
     * Passes the contents of the input
     */
    onChange: PropTypes.func.isRequired,

    /**
     * Required by Redux-form as PromoCodeForm
     * shares a Page with its parent container
     */
    onSubmit: PropTypes.func.isRequired,

    /**
     * Called for every reset of the text input entry field
     */
    onClear: PropTypes.func.isRequired,
});

export const OPTIONS_PROP_TYPE = PropTypes.shape({
    /**
     * Whether or not form is submitting
     */
    isLoading: PropTypes.bool,

    /**
     * Text to display when form submission is successful
     * Type of code : discount or access
     */
    promoCodeType: PropTypes.string,

    /**
     * Promotional code successfully applied
     */
    promoCode: PropTypes.string,

    /**
     * (String) percent-off discount that the
     * promoCode provides in the specific locale format
     */
    percentOff: PropTypes.string,

    /**
     * (String) Formatted amount-off discount that the
     * promoCode provides
     */
    amountOff: PropTypes.string,

    /**
     * Whether the PromoCodeForm should show an internal CTA
     * as suffix within the InputField
     */
    shouldShowInternalCta: PropTypes.bool,

    /**
     * Whether the PromoCodeForm should show an external CTA
     * as button under the InputField
     */
    shouldShowExternalCta: PropTypes.bool,

    /**
     * Whether the PromoCode input field should be active (focused) by default
     * Should be active by default in modal mode, not active by default otherwise.
     */
    disablePromoCodeAutoFocus: PropTypes.bool,
});

export const FORM_PROP_TYPE = {
    handlers: HANDLER_PROP_TYPE,
    options: OPTIONS_PROP_TYPE,
};

export const REDUX_FORM_PROP_TYPES = {
    handleSubmit: PropTypes.func.isRequired,
    submitFailed: PropTypes.bool,
    pristine: PropTypes.bool,
};
