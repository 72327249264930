import PropTypes from 'prop-types';

import { STYLE_PROP_TYPE } from '@eb/eds-card';

export const CARD_ITEMS_PROP_TYPE = PropTypes.arrayOf(
    PropTypes.shape({
        id: PropTypes.node.isRequired,
        content: PropTypes.node.isRequired,
        style: STYLE_PROP_TYPE,
        isSortable: PropTypes.bool,
    }),
);
