import { connect } from 'react-redux';

import { getAttendeesSortedByTicketCategory } from '../../selectors/attendees';
import {
    getSelectedDeliveryMethodsForDisplay,
    getTicketGroups,
} from '../../selectors/deliveryMethod';
import {
    getEventTitle,
    getEventImageSrc,
    getWaitlistTicketId,
} from '../../selectors/event';
import {
    getMinimumQuantityErrorMessage,
    getOrderCosts,
    getUnformattedOrderCost,
    isAddAttendeesOrder,
    hasMultipleRoyalties,
    haveSelectedTicketsShippingFee,
    isEventWithShippingFee,
    isTransferredOrder,
} from '../../selectors/order';
import { getIsPaymentView } from '../../selectors/routing';
import { getDisplayCostProps, getTicketsProps } from '../../selectors/tickets';

import { getSelectedVirtualIncentives } from '../../selectors/virtualIncentive';

const mapStateToProps = (state) => {
    const {
        currencyFormat,
        isEventbriteTLD,
        isRegEvent,
        featureFlags: { enableRefundFeeRetentionPolicy } = {},
        refundPolicyCode,
    } = state.app;

    const eventImageSrc = getEventImageSrc(state);
    const eventTitle = getEventTitle(state);
    // Determines if the fees item is an expansion panel or not
    const hasFeeBreakdown =
        hasMultipleRoyalties(state) ||
        isTransferredOrder(state) ||
        haveSelectedTicketsShippingFee(state);
    // NB: Should the feeComponents array be stripped out of the Order.costs object or not?
    // TODO: Refactor `includeFeeComponents` into a selector and test it after better understanding
    // where else its used. Stripping-out data in some scenarios and not others seems silly, so
    // hoping to eventually not need this
    const includeFeeComponents = hasFeeBreakdown;
    const installments = state.order ? state.order.displayInstallments : null;
    const isFreeEvent = state.event.isFree;
    const orderCosts = getOrderCosts(state, includeFeeComponents);
    const ticketsById = state.tickets.ticketsById;
    const waitlistTicketId = getWaitlistTicketId(state);
    const refundPolicyProps = {
        refundPolicyCode,
        showRefundPolicy: !isEventbriteTLD && !isFreeEvent,
        enableRefundFeeRetentionPolicy,
    };

    return {
        currencyFormat,
        eventImageSrc,
        enableRefundFeeRetentionPolicy,
        eventTitle,
        hasFeeBreakdown,
        installments,
        isAddAttendeesOrder: isAddAttendeesOrder(state),
        isEventWithShippingFee: isEventWithShippingFee(state),
        isPaymentView: getIsPaymentView(state),
        isTransferredOrder: isTransferredOrder(state),
        isRegEvent,
        orderCosts,
        quantityErrorMessage: getMinimumQuantityErrorMessage(state),
        refundPolicyProps,
        selectedDeliveryMethods: getSelectedDeliveryMethodsForDisplay(state),
        selectedVirtualIncentives: getSelectedVirtualIncentives(state),
        shippingRates: state.event.shippingRates,
        sortedAttendeesByTicketCategory: getAttendeesSortedByTicketCategory(
            state,
        ),
        ticketGroups: getTicketGroups(state),
        ticketsById,
        waitlistTicketId,
        unformattedOrderCost: getUnformattedOrderCost(state),
        ...getDisplayCostProps(state),
        ...getTicketsProps(state),
    };
};

export const connectPane = (ComponentClass) =>
    connect(mapStateToProps)(ComponentClass);
