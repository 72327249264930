import React from 'react';
import classnames from 'classnames';
import {
    arrayMove,
    SortableElement as sortableElement,
    SortableHandle as sortableHandle,
    SortableContainer as sortableContainer,
} from 'react-sortable-hoc';

import { Card } from '@eb/eds-card';
import { Icon } from '@eb/eds-icon';

import '../cardList.scss';

import { DragChunky } from '@eb/eds-iconography';

const SortableHandle = sortableHandle(({ children }) => {
    let content = children;

    if (!content) {
        content = <Icon type={<DragChunky />} size="small" color="grey-300" />;
    }

    return (
        <span className="eds-l-pad-left-4 eds-l-lg-pad-left-6 eds-card-list__handle">
            {content}
        </span>
    );
});

export const SortableItem = sortableElement(
    ({ dragHandle, content, style, isSelected }) => {
        const listItemClassNames = classnames('eds-card-list__sortable', {
            'eds-card-list__sortable--draggable': dragHandle === null,
        });
        const cardContentClassNames = classnames(
            'eds-align--center-vertical eds-card-list__sortable-content',
            {
                'eds-card-list__sortable--hoverable': !isSelected,
            },
        );

        return (
            <li
                className={listItemClassNames}
                data-spec="eds-card-list-sortable"
            >
                <Card style={style} isSelected={isSelected}>
                    <div className={cardContentClassNames}>
                        {dragHandle !== null && (
                            <SortableHandle>{dragHandle}</SortableHandle>
                        )}
                        {content}
                    </div>
                </Card>
            </li>
        );
    },
);

export const SortableList = sortableContainer(({ items }) => (
    <ul className="eds-card-list">{items}</ul>
));

export { arrayMove };
