import PropTypes from 'prop-types';
import { translationPropType } from '@eb/i18n';

export const GROUP_TYPE_GROUP = 'group';

export const GROUP_TYPE_TEAM = 'team';

export const GROUP_TYPE_ORGANIZATION = 'organization';

export const GROUP_TYPE_COMPANY = 'company';

export const GROUP_TYPE_ASSOCIATION = 'association';

export const REGISTRATION_TYPE_INDIVIDUAL = 'individual';

export const GROUP_TYPE_PROP_TYPE = PropTypes.oneOf([
    GROUP_TYPE_ASSOCIATION,
    GROUP_TYPE_COMPANY,
    GROUP_TYPE_GROUP,
    GROUP_TYPE_ORGANIZATION,
    GROUP_TYPE_TEAM,
]);

export const GROUP_SETTINGS_PROP_TYPE = PropTypes.shape({
    hasPassword: PropTypes.bool,
    isPasswordRequired: PropTypes.bool,
    hasPreferredStartTime: PropTypes.bool,
    isPreferredStartTimeRequired: PropTypes.bool,
    preferredStartTimeChoices: PropTypes.arrayOf(PropTypes.array),
});

export const TEAM_SETTINGS_ERRORS_PROP_TYPE = PropTypes.shape({
    name: translationPropType,
    password: translationPropType,
    preferredStartTime: translationPropType,
});

export const GROUP_NAME_MIN_LENGTH = 3;
export const GROUP_NAME_MAX_LENGTH = 60;
export const GROUP_PASSWORD_MAX_LENGTH = 20;

export const GROUP_FULL_ERROR = 'groupIsFull';
export const GROUP_DOES_NOT_EXIST_ERROR = 'groupDoesNotExist';

export const GROUP_NAME_ID = 'name';
export const GROUP_PASSWORD_ID = 'password';
export const GROUP_START_TIME_ID = 'preferredStartTime';

export const INIT_VALIDATION_FIELD = {
    isInvalid: null,
    errorMessage: null,
};
