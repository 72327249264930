import { connect } from 'react-redux';
import { getEventSalesStatus, getFeatureFlags } from '../template';
import { navigateToTicketSelection as navigateToTicketSelectionAction } from '../../actions/initialize';
import { notifyParentWidgetModalClose as notifyParentWidgetModalCloseAction } from '../../actions/notifyParent';
import {
    abandonOrder as abandonOrderAction,
    backToPathOnCancelAbandonOrder as backToPathOnCancelAbandonOrderAction,
} from '../../actions/order/common';
import { navigateToSeriesPage as navigateToSeriesPageAction } from '../../actions/series';
import { queueFree as queueFreeAction } from '../../actions/waitingRoom';
import {
    CHECKOUT_PATH,
    DELIVERY_METHODS_PATH,
    LOGIN_PATH,
    PAYMENTS_PATH,
    PICK_A_SEAT_PATH,
    RESERVED_TICKETS_CONFIRMATION_PATH,
} from '../../constants';
import { shouldShowBackToSeriesLink } from '../series/utils';
import { getEventTitle, getFormattedEventDate } from '../../selectors/event';
import { isNativeApp } from '../../utils/browser';
import { sendNativeCheckoutPurchaseAction } from '../checkout/utils';
import {
    CHECKOUT_NATIVE_ORDER_CANCELED,
    DefaultMobileCallBack,
} from '../checkout/constants';

const _mapStateToProps = (state, ownProps) => ({
    shouldShowBackToSeriesLink: shouldShowBackToSeriesLink(state),
    statusType: ownProps.routeParams.statusType,
    eventTitle: getEventTitle(state),
    eventDate: getFormattedEventDate(state),
    ...getEventSalesStatus(state),
    ...getFeatureFlags(state),
    isNativeApp: isNativeApp(state),
});

const _mapDispatchToProps = (dispatch) => ({
    // XXX: I find it a little weird to mix these "confirm action" pages with just event statuses,
    // especially since those pages don't need these props. Ticket for refactoring this: EB-59924
    closeModalActions: () =>
        dispatch(abandonOrderAction())
            .then(() => dispatch(queueFreeAction()))
            .then(() => dispatch(notifyParentWidgetModalCloseAction())),
    closeModalOnNativeActions: () =>
        dispatch(abandonOrderAction())
            .then(() => dispatch(queueFreeAction()))
            .then(() => dispatch(notifyParentWidgetModalCloseAction()))
            .then(() =>
                dispatch(
                    sendNativeCheckoutPurchaseAction(
                        CHECKOUT_NATIVE_ORDER_CANCELED,
                        DefaultMobileCallBack,
                    ),
                ),
            ),
    toLogin: () => dispatch(backToPathOnCancelAbandonOrderAction(LOGIN_PATH)),
    toTicketSelection: () =>
        dispatch(abandonOrderAction())
            .then(() => dispatch(queueFreeAction()))
            .then(() => dispatch(navigateToTicketSelectionAction())),
    toCheckout: () =>
        dispatch(backToPathOnCancelAbandonOrderAction(CHECKOUT_PATH)),
    toDeliveryMethod: () =>
        dispatch(backToPathOnCancelAbandonOrderAction(DELIVERY_METHODS_PATH)),
    toPickASeat: () =>
        dispatch(backToPathOnCancelAbandonOrderAction(PICK_A_SEAT_PATH)),
    toReservedTicketConfirmation: () =>
        dispatch(
            backToPathOnCancelAbandonOrderAction(
                RESERVED_TICKETS_CONFIRMATION_PATH,
            ),
        ),
    toPaymentSelection: () =>
        dispatch(backToPathOnCancelAbandonOrderAction(PAYMENTS_PATH)),
    onBackToSeries: () => dispatch(navigateToSeriesPageAction()),
});

export const connectStatusPage = (ComponentClass) =>
    connect(_mapStateToProps, _mapDispatchToProps)(ComponentClass);
