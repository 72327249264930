import React from 'react';
import PropTypes from 'prop-types';
import { getDonationChangeActionPayload } from './utils';
import { getTicketForImage, getTicketImageUrls } from '../../utils/tickets';
import TicketContent from './TicketContent';
import TicketImageThumbnail from '../ticketImageThumbnail/TicketImageThumbnail';
import { DATA_PROP_TYPES } from './constants';
import './ticketCardContent.scss';

export default class TicketDisplayCardContent extends React.PureComponent {
    static propTypes = {
        ...DATA_PROP_TYPES,
        /**
         * Callback for when the selected value changes
         **/
        onQuantityChange: PropTypes.func,
        /**
         * Whether or not the card is expanded initially for a ticket with public discounts
         */
        initiallyExpanded: PropTypes.bool,
        /**
         * Callback to be triggered when the content is expanded or collapsed for a ticket with public discounts
         * Ex. (isExpanded) => {};
         */
        onToggle: PropTypes.func,
        /**
         * Bool to decide whether component is disabled
         */
        isFormSubmitting: PropTypes.bool,
    };

    static defaultProps = {
        shouldHideStatus: false,
    };

    _handleQuantityChange = (variantId, quantity) => {
        const { id, onQuantityChange } = this.props;

        if (onQuantityChange) {
            let payload = {
                id,
                quantity: parseInt(quantity, 10),
            };

            if (variantId) {
                payload = {
                    ...payload,
                    variantId,
                };
            }

            onQuantityChange(payload);
        }
    };

    _handleDonationChange = (amount) => {
        const { id, currencyFormat, onDonationChange } = this.props;

        if (onDonationChange) {
            onDonationChange(
                getDonationChangeActionPayload(id, amount, currencyFormat),
            );
        }
    };

    render() {
        const ticketForImage = getTicketForImage(this.props);
        const ticketGrahic = ticketForImage ? (
            <TicketImageThumbnail
                title={ticketForImage.name}
                {...getTicketImageUrls(ticketForImage)}
            />
        ) : null;

        return (
            <TicketContent
                {...this.props}
                graphic={ticketGrahic}
                onQuantityChange={this._handleQuantityChange}
                onDonationChange={this._handleDonationChange}
            />
        );
    }
}
