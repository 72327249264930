export const LARGE_COLOR_CIRCLE_SIZE = 'large';
export const MEDIUM_COLOR_CIRCLE_SIZE = 'medium';
export const SMALL_COLOR_CIRCLE_SIZE = 'small';
export const TINY_COLOR_CIRCLE_SIZE = 'tiny';

export const COLOR_CIRCLE_SIZES = [
    LARGE_COLOR_CIRCLE_SIZE,
    MEDIUM_COLOR_CIRCLE_SIZE,
    SMALL_COLOR_CIRCLE_SIZE,
    TINY_COLOR_CIRCLE_SIZE,
];
