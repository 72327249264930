export interface CurrencyBasedValue {
    /**
     * Currency in which this value is expressed.
     * @example `USD`
     */
    currency: string;
    /**
     * Human-readable value, including currency symbol.
     * @note Avoid using this value directly and use `formatMajorMoney` from `@eb/intl`.
     */
    display: string;
    /**
     * String representation of the value, storing cents as decimals.
     * @example `'1.00'` (one dollar)
     */
    majorValue: string;
    /**
     * Numeric value, storing cents as decimals.
     * @example `1.00` (one dollar)
     */
    value: number;
}

export const isIdentificationRequiredForCurrency = {
    BRL: true,
    ARS: true,
    MXN: true,
};
