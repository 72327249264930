import React from 'react';
import gettext from '@eb/gettext';
import { GenericLazyString } from '@eb/i18n';

import { AmexPayment } from '@eb/eds-iconography';
import { BancontactPayment } from '@eb/eds-iconography';
import { BoletoPayment } from '@eb/eds-iconography';
import { Cheque } from '@eb/eds-iconography';
import { CreditCardBack } from '@eb/eds-iconography';
import { DiscoverPayment } from '@eb/eds-iconography';
import { EloPayment } from '@eb/eds-iconography';
import { HipercardPayment } from '@eb/eds-iconography';
import { IdealPayment } from '@eb/eds-iconography';
import { MaestroPayment } from '@eb/eds-iconography';
import { MastercardPayment } from '@eb/eds-iconography';
import { MonerisPayment } from '@eb/eds-iconography';
import { Money } from '@eb/eds-iconography';
import { Voucher } from '@eb/eds-iconography';
import { OxxoPayment } from '@eb/eds-iconography';
import { PagofacilPayment } from '@eb/eds-iconography';
import { PaypalPayment } from '@eb/eds-iconography';
import { RapipagoPayment } from '@eb/eds-iconography';
import { Receipt } from '@eb/eds-iconography';
import { SepaPayment } from '@eb/eds-iconography';
import { SofortPayment } from '@eb/eds-iconography';
import { VisaDebitPayment } from '@eb/eds-iconography';
import { VisaPayment } from '@eb/eds-iconography';

export enum PaymentOptionType {
    EVENTBRITE = 'eventbrite',
    OFFLINE = 'offline',
    PAYPAL = 'paypal',
    AUTHNET = 'authnet',
}

/* Types of payment methods (order service) */
export enum PaymentMethodType {
    NOT_SELECTED = '',
    BOLETO_BANCARIO = 'BOLETO_BANCARIO',
    CASH = 'CASH',
    CHECK = 'CHECK',
    CREDIT = 'CREDIT',
    FPP_PAYPAL = 'PAYPAL',
    IDEAL = 'IDEAL',
    INVOICE = 'INVOICE',
    MAESTRO_BANCONTACT = 'MAESTRO_BANCONTACT',
    NONCE_BRAINTREE = 'NONCE_BRAINTREE',
    SEPA_DIRECT_DEBIT = 'SEPA_DIRECT_DEBIT',
    SOFORT = 'SOFORT',
    USER_INSTRUMENT = 'USER_INSTRUMENT',
    BARCODE = 'BARCODE',
    RAPIPAGO = 'RAPIPAGO',
    PAGOFACIL = 'PAGOFACIL',
    OXXO = 'OXXO',
    AUTHNET = 'AUTHNET',
}

export const ALL_PAYMENT_METHOD_TYPES = [
    PaymentMethodType.BOLETO_BANCARIO,
    PaymentMethodType.CASH,
    PaymentMethodType.CHECK,
    PaymentMethodType.CREDIT,
    PaymentMethodType.FPP_PAYPAL,
    PaymentMethodType.IDEAL,
    PaymentMethodType.INVOICE,
    PaymentMethodType.MAESTRO_BANCONTACT,
    PaymentMethodType.NONCE_BRAINTREE,
    PaymentMethodType.SEPA_DIRECT_DEBIT,
    PaymentMethodType.SOFORT,
    PaymentMethodType.USER_INSTRUMENT,
    PaymentMethodType.BARCODE,
    PaymentMethodType.RAPIPAGO,
    PaymentMethodType.PAGOFACIL,
    PaymentMethodType.OXXO,
    PaymentMethodType.AUTHNET,
];

export interface PaymentMethodInfo {
    userString: string | GenericLazyString;
    customLabel?: string | GenericLazyString;
    icon: React.ReactNode;
    ignoreForm?: boolean;
    overrideIconFillColor?: boolean;
    allowsSplitPaymentsWithVirtualIncentives: boolean;
    allowsChangingVirtualIncentivesWhenSelected: boolean;
}

const UNTYPED_PAYMENT_METHODS_INFO = {
    [PaymentMethodType.CREDIT]: {
        userString: gettext('Credit or debit card'),
        icon: <CreditCardBack />,
        overrideIconFillColor: true,
        allowsSplitPaymentsWithVirtualIncentives: true,
        allowsChangingVirtualIncentivesWhenSelected: true,
    },
    [PaymentMethodType.IDEAL]: {
        userString: gettext('iDEAL'),
        icon: <IdealPayment />,
        allowsSplitPaymentsWithVirtualIncentives: false,
        allowsChangingVirtualIncentivesWhenSelected: false,
    },
    [PaymentMethodType.MAESTRO_BANCONTACT]: {
        userString: gettext('Bancontact'),
        icon: <BancontactPayment />,
        allowsSplitPaymentsWithVirtualIncentives: false,
        allowsChangingVirtualIncentivesWhenSelected: false,
    },
    [PaymentMethodType.SOFORT]: {
        userString: gettext('Sofort'),
        icon: <SofortPayment />,
        allowsSplitPaymentsWithVirtualIncentives: false,
        allowsChangingVirtualIncentivesWhenSelected: false,
    },
    [PaymentMethodType.NONCE_BRAINTREE]: {
        userString: gettext('PayPal'),
        customLabel: gettext('Pay Now or Pay Later'),
        icon: <PaypalPayment />,
        allowsSplitPaymentsWithVirtualIncentives: true,
        allowsChangingVirtualIncentivesWhenSelected: false,
    },
    [PaymentMethodType.SEPA_DIRECT_DEBIT]: {
        userString: gettext('Sepa Direct Debit'),
        icon: <SepaPayment />,
        allowsSplitPaymentsWithVirtualIncentives: false,
        allowsChangingVirtualIncentivesWhenSelected: false,
    },
    [PaymentMethodType.CASH]: {
        userString: gettext('Pay at the door'),
        icon: <Voucher />,
        ignoreForm: true,
        overrideIconFillColor: true,
        allowsSplitPaymentsWithVirtualIncentives: false,
        allowsChangingVirtualIncentivesWhenSelected: false,
    },
    [PaymentMethodType.INVOICE]: {
        userString: gettext('Pay by invoice'),
        icon: <Receipt />,
        ignoreForm: true,
        overrideIconFillColor: true,
        allowsSplitPaymentsWithVirtualIncentives: false,
        allowsChangingVirtualIncentivesWhenSelected: false,
    },
    [PaymentMethodType.CHECK]: {
        userString: gettext('Pay by check'),
        icon: <Cheque />,
        ignoreForm: true,
        overrideIconFillColor: true,
        allowsSplitPaymentsWithVirtualIncentives: false,
        allowsChangingVirtualIncentivesWhenSelected: false,
    },
    [PaymentMethodType.FPP_PAYPAL]: {
        userString: gettext('PayPal'),
        icon: <PaypalPayment />,
        allowsSplitPaymentsWithVirtualIncentives: false,
        allowsChangingVirtualIncentivesWhenSelected: false,
    },
    [PaymentMethodType.BOLETO_BANCARIO]: {
        userString: gettext('Boleto Bancario'),
        icon: <BoletoPayment />,
        allowsSplitPaymentsWithVirtualIncentives: false,
        allowsChangingVirtualIncentivesWhenSelected: false,
    },
    [PaymentMethodType.BARCODE]: {
        userString: gettext('Pay in cash with Rapipago or Pagofacil'),
        icon: <OxxoPayment />,
        overrideIconFillColor: true,
        allowsSplitPaymentsWithVirtualIncentives: false,
        allowsChangingVirtualIncentivesWhenSelected: false,
    },
    [PaymentMethodType.RAPIPAGO]: {
        userString: gettext('Pay in cash with Rapipago'),
        icon: <RapipagoPayment />,
        overrideIconFillColor: true,
        allowsSplitPaymentsWithVirtualIncentives: false,
        allowsChangingVirtualIncentivesWhenSelected: false,
    },
    [PaymentMethodType.PAGOFACIL]: {
        userString: gettext('Pay in cash with Pagofacil'),
        icon: <PagofacilPayment />,
        overrideIconFillColor: true,
        allowsSplitPaymentsWithVirtualIncentives: false,
        allowsChangingVirtualIncentivesWhenSelected: false,
    },
    [PaymentMethodType.OXXO]: {
        userString: gettext('Pay in cash with OXXO.'),
        icon: <OxxoPayment />,
        overrideIconFillColor: true,
        allowsSplitPaymentsWithVirtualIncentives: false,
        allowsChangingVirtualIncentivesWhenSelected: false,
    },
    [PaymentMethodType.AUTHNET]: {
        userString: gettext('Authorize.net'),
        icon: <CreditCardBack />,
        overrideIconFillColor: true,
        allowsSplitPaymentsWithVirtualIncentives: false,
        allowsChangingVirtualIncentivesWhenSelected: false,
    },
};
export const PAYMENT_METHODS_INFO = UNTYPED_PAYMENT_METHODS_INFO as Record<
    keyof typeof UNTYPED_PAYMENT_METHODS_INFO,
    PaymentMethodInfo
>;

export enum PaymentConstraintName {
    ADYEN_POS_PAYMENT = 'ADYEN_POS_PAYMENT',
    AMEX = 'AMEX',
    BOLETO_BANCARIO = 'BOLETO_BANCARIO',
    BRAINTREE_PAYPAL = 'BRAINTREE_PAYPAL',
    CREDIT_CARD = 'CREDIT_CARD',
    DEBIT_CARD = 'DEBIT_CARD',
    DINERS_CLUB = 'DINERS_CLUB',
    DISCOVER = 'DISCOVER',
    ELO = 'ELO',
    HIPERCARD = 'HIPERCARD',
    IDEAL = 'IDEAL',
    MAESTRO = 'MAESTRO',
    MAESTRO_BANCONTACT = 'MAESTRO_BANCONTACT',
    MASTERCARD = 'MASTERCARD',
    MERCADO_PAGO = 'MERCADO_PAGO',
    MONERIS = 'MONERIS',
    NARANJA = 'NARANJA',
    NATIVA = 'NATIVA',
    OXXO = 'OXXO',
    PAGOFACIL = 'PAGOFACIL',
    RAPIPAGO = 'RAPIPAGO',
    SEPA_DIRECT_DEBIT = 'SEPA_DIRECT_DEBIT',
    SOFORT = 'SOFORT',
    VISA = 'VISA',
    VISA_DEBIT = 'VISA_DEBIT',
    BARCODE = 'BARCODE',
}

interface PaymentConstraintVariant {
    userString: string;
    icon?: React.ReactNode;
}

export const PAYMENT_CONSTRAINT_VARIANTS: Record<
    PaymentConstraintName,
    PaymentConstraintVariant
> = {
    [PaymentConstraintName.AMEX]: {
        userString: 'American Express',
        icon: <AmexPayment />,
    },
    [PaymentConstraintName.ADYEN_POS_PAYMENT]: {
        userString: 'Adyen POS Payment',
    },
    [PaymentConstraintName.BOLETO_BANCARIO]: {
        userString: 'Boleto Bancario',
        icon: <BoletoPayment />,
    },
    [PaymentConstraintName.BARCODE]: {
        userString: gettext('Pay in cash with Rapipago or Pagofacil'),
        icon: <Money />,
    },
    [PaymentConstraintName.BRAINTREE_PAYPAL]: {
        userString: 'Paypal',
        icon: <PaypalPayment />,
    },
    [PaymentConstraintName.CREDIT_CARD]: {
        userString: gettext('Credit Card'),
    },
    [PaymentConstraintName.DEBIT_CARD]: {
        userString: gettext('Debit Card'),
    },
    [PaymentConstraintName.DINERS_CLUB]: {
        userString: gettext('Diners Club'),
    },
    [PaymentConstraintName.DISCOVER]: {
        userString: 'Discover',
        icon: <DiscoverPayment />,
    },
    [PaymentConstraintName.ELO]: {
        userString: 'Elo',
        icon: <EloPayment />,
    },
    [PaymentConstraintName.HIPERCARD]: {
        userString: 'Hipercard',
        icon: <HipercardPayment />,
    },
    [PaymentConstraintName.IDEAL]: {
        userString: 'iDEAL',
        icon: <IdealPayment />,
    },
    [PaymentConstraintName.MAESTRO]: {
        userString: 'Maestro',
        icon: <MaestroPayment />,
    },
    [PaymentConstraintName.MAESTRO_BANCONTACT]: {
        userString: 'Maestro Bancontact',
        icon: <BancontactPayment />,
    },
    [PaymentConstraintName.MASTERCARD]: {
        userString: 'Mastercard',
        icon: <MastercardPayment />,
    },
    [PaymentConstraintName.MERCADO_PAGO]: {
        userString: 'Mercado Pago',
    },
    [PaymentConstraintName.MONERIS]: {
        userString: 'Moneris',
        icon: <MonerisPayment />,
    },
    [PaymentConstraintName.NARANJA]: {
        userString: 'Naranja',
    },
    [PaymentConstraintName.NATIVA]: {
        userString: 'Nativa',
    },
    [PaymentConstraintName.OXXO]: {
        userString: 'OXXO',
        icon: <OxxoPayment />,
    },
    [PaymentConstraintName.PAGOFACIL]: {
        userString: 'Pago Fácil',
        icon: <PagofacilPayment />,
    },
    [PaymentConstraintName.RAPIPAGO]: {
        userString: 'Rapipago',
        icon: <RapipagoPayment />,
    },
    [PaymentConstraintName.SEPA_DIRECT_DEBIT]: {
        userString: 'Sepa Direct Debit',
        icon: <SepaPayment />,
    },
    [PaymentConstraintName.SOFORT]: {
        userString: 'Sofort',
        icon: <SofortPayment />,
    },
    [PaymentConstraintName.VISA]: {
        userString: 'Visa',
        icon: <VisaPayment />,
    },
    [PaymentConstraintName.VISA_DEBIT]: {
        userString: gettext('Visa Debit Card'),
        icon: <VisaDebitPayment />,
    },
};

export interface BankIdentifier {
    bankId: string;
    bankName: string;
}

export interface PaymentMethod {
    type: PaymentMethodType;
    additionalData?: {
        note?: string;
    };
}

export interface PaymentMethodUserInstrument {
    vaultId: string;
    cardType: string;
    displayDigits: string;
}

export enum BraintreeVariant {
    Paypal = 'BRAINTREE_PAYPAL',
    PaypalCredit = 'BRAINTREE_PAYPAL_CREDIT',
}
export const ALL_BRAINTREE_VARIANTS = [
    BraintreeVariant.Paypal,
    BraintreeVariant.PaypalCredit,
];

export enum InstrumentType {
    CREDIT_CARD = 'CREDIT_CARD',
    VIRTUAL_INCENTIVES_CARD = 'VIRTUAL_INCENTIVES_CARD',
}
