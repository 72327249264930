import isEmpty from 'lodash/isEmpty';

/**
 * Return true if user should see a back arrow on the tickets page header
 * and be able to go back to series page.
 *
 * If user lands on a series child event checkout widget directly, they shall
 * not be able to "go back" to parent series page.
 */
export const shouldShowBackToSeriesLink = ({ event, series }) =>
    event.isSeries && !event.isSeriesParent && !isEmpty(series.seriesEvents);
