import React from 'react';

import { Badge } from '@eb/eds-badge';
import { Icon } from '@eb/eds-icon';
import { ChevronUpChunky } from '@eb/eds-iconography';
import { ChevronDownChunky } from '@eb/eds-iconography';
import { Cart } from '@eb/eds-iconography';

import { ORDER_SUMMARY_BUTTON_LABEL } from '../../src/constants';

const CartSummary = ({ isPaneOpen, onClick, ticketQuantity, totalCost }) => {
    let ticketsCost = null;
    const openIndicatorIcon = isPaneOpen ? (
        <ChevronUpChunky />
    ) : (
        <ChevronDownChunky />
    );

    if (ticketQuantity) {
        ticketsCost = (
            <div className="eds-g-cell eds-g-cell-8-12 eds-g-offset-mn-4-12 eds-text--right eds-text--truncate eds-l-pad-vert-2 eds-l-pad-right-4">
                <span
                    className="eds-text-bm--fixed eds-text-color--grey-800 eds-text-weight--heavy"
                    data-automation="modal-footer-ticket-price"
                    data-spec="modal-footer-ticket-price"
                >
                    {totalCost}
                </span>
            </div>
        );
    }

    return (
        <button
            className="eds-show-down-sw eds-btn--block eds-btn--none"
            onClick={onClick}
            aria-label={ORDER_SUMMARY_BUTTON_LABEL.toString()}
            aria-pressed={isPaneOpen}
            aria-expanded={isPaneOpen}
            aria-controls="order-summary"
        >
            <div className="cart-summary eds-g-group" aria-hidden={true}>
                <div className="eds-l-pad-vert-2 eds-g-cell eds-g-cell-4-12">
                    <Badge count={ticketQuantity}>
                        <Icon type={<Cart />} />
                    </Badge>
                    <Icon type={openIndicatorIcon} />
                </div>
                {ticketsCost}
            </div>
        </button>
    );
};

export default CartSummary;
