import React from 'react';
import PropTypes from 'prop-types';
import pick from 'lodash/pick';

import { PASSWORD_VISIBLE_ICON, PASSWORD_HIDDEN_ICON } from './constants';
import PasswordIconButton from './PasswordIconButton';
import './password.scss';

import { InputField, TYPE_TEXT, TYPE_PASSWORD } from '@eb/eds-input-field';
import { getAdditionalProps } from '@eb/eds-utils';

const DEFAULT_INPUT_FIELD_PROPS = {
    style: 'basic',
    label: 'password',
};

export default class Password extends React.PureComponent {
    static propTypes = {
        /**
         * ID of TextInput
         */
        id: PropTypes.string.isRequired,
        /**
         * Name of TextInput
         */
        name: PropTypes.string,
        /**
         * Role of text input
         */
        role: PropTypes.string,
        /**
         * Is required
         */
        required: PropTypes.bool,
        /**
         * Set error state
         */
        hasError: PropTypes.bool,
        /**
         * If InputField should be set to a disabled state
         **/
        disabled: PropTypes.bool,
        /**
         * Default value of TextInput
         **/
        defaultValue: PropTypes.string,
        /**
         * Value of TextInput
         **/
        value: PropTypes.string,
        /**
         * {function} onChange
         */
        onChange: PropTypes.func,
        /**
         * {function} onBlur
         */
        onBlur: PropTypes.func,
        /**
         * {function} onFocus
         */
        onFocus: PropTypes.func,
        /**
         * {function} onIconClick
         */
        onIconClick: PropTypes.func,
    };

    static defaultProps = {
        disabled: false,
        defaultValue: '',
        role: 'password',
    };

    constructor(props) {
        super(props);

        const { defaultValue } = props;
        let { value } = props;

        value = value || defaultValue;

        this.state = {
            isTextVisible: false,
            isIconVisible: value.length !== 0,
        };
    }

    _handleIconClick() {
        const { isTextVisible } = this.state;
        const { onIconClick } = this.props;

        this.setState({
            isTextVisible: !isTextVisible,
        });

        if (onIconClick) {
            onIconClick();
        }
    }

    _handleOnChange(value) {
        const { onChange } = this.props;

        this.setState({
            isIconVisible: value.length !== 0,
        });

        if (onChange) {
            onChange(value);
        }
    }

    render() {
        const {
            id,
            name,
            disabled,
            role,
            hasError,
            required,
            defaultValue,
            onBlur,
            onFocus,
            value,
        } = this.props;

        const extraAttrs = getAdditionalProps(this);
        const { isTextVisible, isIconVisible } = this.state;
        const textInputType = isTextVisible ? TYPE_TEXT : TYPE_PASSWORD;
        const iconType = isTextVisible
            ? PASSWORD_HIDDEN_ICON
            : PASSWORD_VISIBLE_ICON;

        const PasswordIcon = (
            <PasswordIconButton
                showIcon={isIconVisible}
                iconType={iconType}
                togglePasswordVisibility={this._handleIconClick.bind(this)}
            />
        );
        const sharedProps = {
            id,
            name,
            onFocus,
            onBlur,
            defaultValue,
            value,
            disabled,
            required,
            hasError,
            role,
            type: textInputType,
            onChange: this._handleOnChange.bind(this),
        };

        const inputProps = {
            suffix: PasswordIcon,
            hasMinAsideSpacing: true,
            ...DEFAULT_INPUT_FIELD_PROPS,
            ...pick(this.props, ...Object.keys(InputField.propTypes)),
            ...sharedProps,
        };

        return (
            <div className="eds-password--v2">
                <InputField {...extraAttrs} {...inputProps} />
            </div>
        );
    }
}
