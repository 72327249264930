import isNil from 'lodash/isNil';
import minBy from 'lodash/minBy';
import maxBy from 'lodash/maxBy';

import { moment } from '@eb/date';
import { getFormattedDateTime, createMomentWithTz } from '@eb/datetime';
import { formatMajorMoney } from '@eb/intl';
import { statusConstants } from '@eb/eds-ticket-edit-card-content';

import {
    EMPTY_PRICE_RANGE,
    FREE_TICKET_LABEL,
    TICKET_STATUS_AVAILABLE,
    TICKET_STATUS_SOLD_OUT,
} from './constants';

const _getDate = (timeObject) => {
    let date;

    if (timeObject) {
        date = createMomentWithTz(timeObject.timezone, timeObject.utc);
    }

    return date;
};

const _isNearSalesDate = (dateAsMoment, currentTime) => {
    const millisecondsUntilSalesDate = dateAsMoment.diff(currentTime);
    const daysUntilSalesDate = moment
        .duration(millisecondsUntilSalesDate)
        .asDays();

    /**
     * We consider 'nearSalesDate' when the ticket sales end or start within two days of the current time.
     * daysUntilSalesDate is the difference between those times in unit of days as determined by moment.js
     **/
    return daysUntilSalesDate <= 2 && daysUntilSalesDate >= 0;
};

const MONTH_DAY_FORMAT = 'll';

const _getFormattedDate = (dateWithTz, currentTime, locale) => {
    let formattedDate;

    if (dateWithTz) {
        formattedDate = getFormattedDateTime(
            dateWithTz.utc,
            false,
            dateWithTz.timezone,
            locale,
            MONTH_DAY_FORMAT,
        );
        if (
            _isNearSalesDate(
                createMomentWithTz(dateWithTz.timezone, dateWithTz.utc),
                currentTime,
            )
        ) {
            formattedDate = createMomentWithTz(
                dateWithTz.timezone,
                dateWithTz.utc,
            )
                .locale(locale)
                .toNow(true);
        }
    }

    return formattedDate;
};

/**
 * _determineDateTimeDisplay
 *
 * Helper function for determineDateTimeAndStatus which handles formatting the
 * moment time object to display the appropriate time string for the ticket.
 *
 * Output sample: 'on Nov 30' or 'in 20 hours'
 *
 * @param {Object} endDateWithTz
 * @param {Object} startDateWithTz
 * @param {import('@eb/date')} currentTime
 * @param {String} locale
 * @returns {{salesStart: string, salesEnd: string}}
 **/
const _determineDateTimeDisplayFormat = (
    endDateWithTz,
    startDateWithTz,
    currentTime,
    locale,
) => ({
    salesStart: _getFormattedDate(startDateWithTz, currentTime, locale),
    salesEnd: _getFormattedDate(endDateWithTz, currentTime, locale),
});

const _determineStatusKey = (
    onSaleInfo,
    quantityRemaining,
    onSaleStatus,
    currentTime,
    salesStartAfter,
) => {
    const endDateAsMoment = _getDate(onSaleInfo.endSalesWithTz);
    const startDateAsMoment = _getDate(onSaleInfo.startSalesWithTz);

    let statusKey = statusConstants.ON_SALE_STATUS;

    if (salesStartAfter && onSaleStatus !== TICKET_STATUS_AVAILABLE) {
        statusKey = statusConstants.SALES_START_AFTER_STATUS;
    }

    if (_isNearSalesDate(endDateAsMoment, currentTime)) {
        statusKey = statusConstants.SALES_END_SOON_STATUS;
    }

    if (
        (!isNil(quantityRemaining) && quantityRemaining < 1) ||
        onSaleStatus === TICKET_STATUS_SOLD_OUT
    ) {
        statusKey = statusConstants.SOLD_OUT_STATUS;
    }

    if (onSaleInfo.unavailable) {
        statusKey = statusConstants.UNAVAILABLE_STATUS;
    }

    if (
        statusKey !== statusConstants.SOLD_OUT_STATUS &&
        endDateAsMoment &&
        endDateAsMoment.isBefore(currentTime)
    ) {
        statusKey = statusConstants.SALES_ENDED_STATUS;
    }

    const DATE_INDEPENDENT_STATUSES = [
        statusConstants.SOLD_OUT_STATUS,
        statusConstants.UNAVAILABLE_STATUS,
        statusConstants.SALES_ENDED_STATUS,
        statusConstants.SALES_START_AFTER_STATUS,
    ];

    if (DATE_INDEPENDENT_STATUSES.includes(statusKey)) {
        return statusKey;
    }

    // if ticket status says it's already available, ignore the start dates
    // when determining on-sale status.  status is the deciding factor for
    // whether tickets can be sold via the orders endpoints.  in some
    // scenarios (specifically dependent tickets where a tickets sales start
    // is determined by another's being sold out), the sales start date may
    // still be in the future but the status is active so the ticket should
    // be available.
    if (onSaleStatus !== TICKET_STATUS_AVAILABLE) {
        if (startDateAsMoment && startDateAsMoment.isAfter(currentTime)) {
            statusKey = statusConstants.SALES_NOT_STARTED_STATUS;
        }

        if (
            startDateAsMoment &&
            _isNearSalesDate(startDateAsMoment, currentTime)
        ) {
            statusKey = statusConstants.SALES_START_SOON_STATUS;
        }
    }

    return statusKey;
};

/**
 * @typedef SalesWithTimezone
 * @property {string} timezone
 * @property {string} utc
 *
 * @typedef OnSaleInfo
 * @property {SalesWithTimezone} [startSalesWithTz]
 * @property {SalesWithTimezone} endSalesWithTz
 *
 * @typedef TicketInfo
 * @property {OnSaleInfo} onSaleInfo
 * @property {number} [quantityRemaining]
 * @property {'SOLD_OUT' | 'AVAILABLE' | 'UNAVAILABLE'} onSaleStatus
 * @property {string} [statusKey]
 */

/**
 * Creates timezone based time information as passed in and using
 * the data assigns the appropriate statusKey.
 *
 * The salesStart and salesEnd are so tightly
 * related to the status of the ticket, both should be handled here. Additionally, to reduce
 * data corruption as caused by caching responses, performing the dateTime calculations
 * on the frontend.
 *
 * @param {TicketInfo} ticket
 * @param {String} [locale='en_US']
 * @returns {{statusKey: string, salesStart: string, salesEnd: string}}
 **/
export const determineDateTimeAndStatus = (ticket, locale = 'en_US') => {
    const {
        onSaleInfo,
        quantityRemaining,
        onSaleStatus,
        salesStartAfter,
    } = ticket;
    const currentTime = createMomentWithTz(onSaleInfo.endSalesWithTz.timezone);
    const { endSalesWithTz, startSalesWithTz } = onSaleInfo;

    const displayDateAndStatusInfo = _determineDateTimeDisplayFormat(
        endSalesWithTz,
        startSalesWithTz,
        currentTime,
        locale,
    );

    let { statusKey } = ticket;

    if (!statusKey) {
        statusKey = _determineStatusKey(
            onSaleInfo,
            quantityRemaining,
            onSaleStatus,
            currentTime,
            salesStartAfter,
        );
    }

    return { ...displayDateAndStatusInfo, statusKey };
};

/**
 * @typedef TicketClass
 * @property {'SOLD_OUT' | 'AVAILABLE' | 'UNAVAILABLE'} onSaleStatus
 * @property {number} quantitySold
 * @property {number} quantityTotal
 * @property {string} salesEnd
 * @property {string} salesStart
 */

/**
 * Determines the ticket date and state using the ticket class API schema.
 *
 * The resulting object can be passed to the `TicketContainer` Component.
 *
 * @param {TicketClass} ticketClass Ticket Class as returned from the v3 Ticket API
 * @param {string} eventTimezone Timezone string of the event
 * @returns {{statusKey: string, salesStart: string, salesEnd: string}}
 */
export const getTicketDateTimeAndStatus = (ticketClass, eventTimezone) => {
    const {
        onSaleStatus,
        quantitySold,
        quantityTotal,
        salesEnd,
        salesStart,
    } = ticketClass;
    const quantityRemaining = quantityTotal - quantitySold;
    const onSaleInfo = {
        endSalesWithTz: {
            timezone: eventTimezone,
            utc: salesEnd,
        },

        startSalesWithTz: {
            timezone: eventTimezone,
            utc: salesStart,
        },
    };

    return determineDateTimeAndStatus({
        onSaleInfo,
        quantityRemaining,
        onSaleStatus,
    });
};

/**
 * @typedef Cost
 * @property {number} [value=0]
 * @property {string} [currency]
 *
 * @typedef Ticket
 * @property {string} [name]
 * @property {Cost} [cost={}]
 * @property {Cost} [totalCost={}]
 * @property {boolean} useAllInPrice
 */
/**
 * Returns the value and currency of the specified ticket.
 *
 * If the ticket has an useAllInPrice boolean set to true,
 * we give you the total cost, which will include taxes and fees.
 * Otherwise we give you the ticket cost. There are legal reasons
 * for this, for example events in then Netherlands will have
 * their useAllInPrice flag set to true on all tickets
 *
 * @param {Ticket} ticket
 * @returns {Cost} Returns an object containing value and currency
 **/
export const getCountryAdjustedTicketPrice = (ticket) => {
    const {
        cost,
        totalCost: { value: totalValue = 0, currency: totalCurrency } = {},
        useAllInPrice,
    } = ticket;

    let { value = 0, currency } = cost || {};

    if (useAllInPrice) {
        value = totalValue;
        currency = totalCurrency;
    }

    return {
        value,
        currency,
    };
};

/**
 * Gets the display price range based on the specified tickets in the specified currency format
 * @param {Ticket[]} tickets
 * @param {string} currencyFormat
 * @returns {string}
 */
export const getTicketsPriceRange = (tickets = [], currencyFormat) => {
    if (tickets.length === 0) {
        return EMPTY_PRICE_RANGE;
    }

    if (tickets.length === 1) {
        const { value, currency } = getCountryAdjustedTicketPrice(tickets[0]);

        return `${formatMajorMoney(value, currency, currencyFormat)}`;
    }

    const displayedPriceValues = tickets.map((ticket) =>
        getCountryAdjustedTicketPrice(ticket),
    );

    const { value: minValue, currency: minCurrency } = minBy(
        displayedPriceValues,
        ({ value }) => value,
    );

    const { value: maxValue, currency: maxCurrency } = maxBy(
        displayedPriceValues,
        ({ value }) => value,
    );

    const isMinValueFree = minValue === 0;

    if (minValue === maxValue) {
        return isMinValueFree
            ? FREE_TICKET_LABEL
            : `${formatMajorMoney(minValue, minCurrency, currencyFormat)}`;
    }

    const formattedMinCost = isMinValueFree
        ? FREE_TICKET_LABEL
        : formatMajorMoney(minValue, minCurrency, currencyFormat);

    const formattedMaxCost = formatMajorMoney(
        maxValue,
        maxCurrency,
        currencyFormat,
    );

    return `${formattedMinCost}—${formattedMaxCost}`;
};
