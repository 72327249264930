import isEmpty from 'lodash/isEmpty';
import { Schema } from 'normalizr';
import { transformUtil } from '@eb/transformation-utils';
import {
    PaginatedResponse,
    PaginatedResponseFormatted,
} from './pagination.types';
import { UserInstrument, VirtualIncentive } from '../../models/user';

const userInstrumentsSchema = new Schema('user_instruments');

export interface SingleUserInstrumentResponse {
    instrument_type: string;
    vault_id: string;
    user_id: string;
    owner_id: string;
    owner_type: string;
    instrument: {
        instrument_type: string;
        card_type: string;
        full_card_mask: string;
        expiration_date: string;
        is_debit: any | null; // TODO: Find proper type for this
    };
    billing_information: {
        first_name: string;
        last_name: string;
        email: string;
        address: {
            address_1: string;
            postal_code: string;
            city: string;
            region: string;
            country: string;
        };
    };
    expires_at: string;
    verified: string;
    created: string;
    changed: string;
    resource_uri: string;
}

export interface UserInstrumentsResponse extends PaginatedResponse {
    user_instruments: Array<SingleUserInstrumentResponse>;
}

export interface UserInstrumentsResponseFormatted
    extends PaginatedResponseFormatted {
    userInstruments: Array<UserInstrument>;
}

export const transformUserInstruments = (data: UserInstrumentsResponse) => {
    const transformedUserInstruments = transformUtil({
        response: data,
        schema: userInstrumentsSchema,
    });

    if (!transformedUserInstruments) {
        return null;
    }

    return transformedUserInstruments.entities.user_instruments[
        transformedUserInstruments.result
    ] as UserInstrumentsResponseFormatted;
};

export interface VirtualIncentiveResponse {
    is_enabled: boolean;
    instrument_type: 'USER_INSTRUMENT';
    instrument: {
        organization_id: string;
        currency: string;
        balance: {
            currency: string;
            display: string;
            major_value: string;
            value: number;
        };
        instrument_type: 'VIRTUAL_INCENTIVES_CARD';
    };
    owner_id: string;
}

export const transformVirtualIncentive = (
    data: VirtualIncentiveResponse,
): VirtualIncentive | null => {
    const balance = data.instrument.balance;

    return !isEmpty(balance)
        ? {
              balance: {
                  currency: balance.currency,
                  display: balance.display,
                  majorValue: balance.major_value,
                  value: balance.value,
              },
          }
        : null;
};
