// External libraries
import React from 'react';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import reduce from 'lodash/reduce';

// Own libraries
import { AmexPayment } from '@eb/eds-iconography';
import { BoletoPayment } from '@eb/eds-iconography';
import { DiscoverPayment } from '@eb/eds-iconography';
import { IdealPayment } from '@eb/eds-iconography';
import { MastercardPayment } from '@eb/eds-iconography';
import { OxxoPayment } from '@eb/eds-iconography';
import { PagofacilPayment } from '@eb/eds-iconography';
import { RapipagoPayment } from '@eb/eds-iconography';
import { SepaPayment } from '@eb/eds-iconography';
import { SofortPayment } from '@eb/eds-iconography';
import { VisaPayment } from '@eb/eds-iconography';
import { VisaDebitPayment } from '@eb/eds-iconography';
import { PaypalPayment } from '@eb/eds-iconography';
import { formatMajorMoney } from '@eb/intl';
import {
    getCountryAdjustedTicketPrice,
    getTicketsPriceRange,
} from '@eb/event-tickets';

// Local imports
import gettext from '@eb/gettext';
import {
    FREE_TEXT,
    TAX_COMPONENT_BASE_FEES_TAX_INCLUDABLE,
} from '../../../constants';
import { hasVariants } from '../../../utils/tickets';
import {
    getFirstVariantTicketWithPromoCode,
    isTicketWithPromoCode,
} from '../../../utils/promoCodes';
import { isTierVariantSoldOut } from './ticketUtils';

const _determineDisplayPrice = (ticket, currencyFormat) => {
    let displayPrice;

    // displayPrice for NL customers is totalCost (ticket cost + tax/fees)
    const cost = ticket.useAllInPrice ? ticket.totalCost : ticket.cost;

    if (cost && cost.value) {
        displayPrice = formatMajorMoney(
            cost.value,
            cost.currency,
            currencyFormat,
        );
    } else if (!cost && ticket.free) {
        displayPrice = FREE_TEXT;
    }

    return displayPrice;
};

const _determineTicketWithPromoCode = (ticket) => {
    let ticketWithCode;

    if (hasVariants(ticket)) {
        ticketWithCode = getFirstVariantTicketWithPromoCode(ticket.variants);
    } else if (isTicketWithPromoCode(ticket)) {
        ticketWithCode = ticket;
    }

    return ticketWithCode;
};

const _determinePrimaryPrice = (ticket, currencyFormat) => {
    const ticketWithCode = _determineTicketWithPromoCode(ticket);
    let primaryPrice;

    if (ticketWithCode && ticketWithCode.originalCost) {
        const { originalCost } = ticketWithCode;

        primaryPrice = formatMajorMoney(
            originalCost.value,
            originalCost.currency,
            currencyFormat,
        );
    }

    return primaryPrice;
};

const _determineRemainingTickets = (ticket, wasInWaitingRoom) => {
    let remaining;

    // if we were in a waiting room situation we have to hide remaining tickets
    // on the Ticket Selection, otherwise we can safely check ticket.displayFlags.showRemaining
    // https://jira.evbhome.com/browse/EB-52136
    if (!wasInWaitingRoom) {
        if (ticket.displayFlags && ticket.displayFlags.showRemaining) {
            remaining = ticket.quantityRemaining;
        }
    }

    return remaining;
};

const getFeeText = ({
    fee,
    includeFee,
    taxOnFee,
    tax,
    currencyFormat,
    prefix,
}) => {
    /*
    Should show ticket fee if includeFee is falsey, but response will
    sometimes not include it due to other circumstances. So need to ensure
    that key is present and false in order to display.
    */
    if (!fee || !fee.value || includeFee !== false) {
        return null;
    }

    const feeValue = taxOnFee ? fee.value + tax.value : fee.value;

    const feeName = taxOnFee ? gettext('Fee & Tax') : gettext('Fee');
    const displayFee = formatMajorMoney(feeValue, fee.currency, currencyFormat);
    const feeText = gettext(`${prefix}%(displayFee)s %(feeName)s`, {
        displayFee,
        feeName,
    });

    return feeText;
};

const getTaxText = ({
    taxOnFee,
    tax,
    currencyFormat,
    hasGtsTax,
    eventTaxName,
    prefix,
}) => {
    if (taxOnFee || !tax || tax.value <= 0) {
        return null;
    }

    const displayTax = formatMajorMoney(
        tax.value,
        tax.currency,
        currencyFormat,
    );
    let taxName;

    if (hasGtsTax) {
        taxName = gettext('Sales Tax');
    } else if (eventTaxName) {
        taxName = gettext(eventTaxName);
    } else {
        taxName = gettext('Tax');
    }

    const taxText = gettext(`${prefix}%(displayTax)s %(taxName)s`, {
        displayTax,
        taxName,
    });

    return taxText;
};

const getShippingFeeText = ({ displayShippingFee, prefix, currencyFormat }) => {
    if (!displayShippingFee || !displayShippingFee.value) {
        return null;
    }

    const shippingFeeName = gettext('Shipping fee');

    const shippingFeeValue = formatMajorMoney(
        displayShippingFee.value,
        displayShippingFee.currency,
        currencyFormat,
    );

    const shippingFeeText = gettext(
        `${prefix}%(shippingFeeValue)s %(shippingFeeName)s`,
        {
            shippingFeeValue,
            shippingFeeName,
        },
    );

    return shippingFeeText;
};

/**
 * _determineFeeAndTax
 *
 * Helper function to determine what text should be displayed for the fee/tax of a ticket below the full ticket price.
 * - Will not show fee amount if there is none or it is being absorbed by the ticket price.
 * - Will not show tax amount if there is none.
 * - Will not show shipping fee amount if there is none.
 * - Will show "incl." at the beginning instead of "+" for tickets with useAllInPrice set to true (full ticket price is shown already).
 *
 * @param {Object} ticket
 * @returns {Array} display text to show
 **/
const _determineFeeAndTax = (
    {
        fee,
        tax,
        taxComponents = [],
        includeFee,
        eventTaxName,
        useAllInPrice,
        hasGtsTax,
        displayShippingFee,
    } = {},
    currencyFormat,
) => {
    const prefix = useAllInPrice ? 'incl. ' : '+';
    // We don't want to show taxes as a separate row if they are just taxes on the fees
    const taxOnFee = taxComponents.find(
        (component) =>
            component.base === TAX_COMPONENT_BASE_FEES_TAX_INCLUDABLE,
    );

    const feeText = getFeeText({
        fee,
        includeFee,
        taxOnFee,
        tax,
        currencyFormat,
        prefix,
    });

    const taxText = getTaxText({
        taxOnFee,
        tax,
        currencyFormat,
        hasGtsTax,
        eventTaxName,
        prefix,
    });

    const shippingFeeText = getShippingFeeText({
        displayShippingFee,
        prefix,
        currencyFormat,
    });

    return [feeText, taxText, shippingFeeText].filter((value) => !!value);
};

export const determinePriceInformation = (
    ticket,
    currencyFormat,
    wasInWaitingRoom,
) => ({
    displayPrice: _determineDisplayPrice(ticket, currencyFormat),
    quantityRemaining: _determineRemainingTickets(ticket, wasInWaitingRoom),
    feeAndTax: _determineFeeAndTax(ticket, currencyFormat),
    primaryPrice: _determinePrimaryPrice(ticket, currencyFormat),
});

const _getTotalCostWithShippingFee = (
    totalCost,
    totalCostWithoutShipping,
    selectedQuantity,
) =>
    totalCost && totalCostWithoutShipping && selectedQuantity > 0
        ? totalCost.value +
          totalCostWithoutShipping.value * (selectedQuantity - 1)
        : 0;

const _getTotalCostWithoutShippingFee = (totalCost, selectedQuantity) =>
    (totalCost?.value ?? 0) * (selectedQuantity ?? 0);

/**
 * getTotalCost
 *
 * Sums the total value of the selected tickets, and returns them in a major
 * value format.
 *
 * eg. (tickets) => 2342
 *
 * @param {Object} tickets
 **/

export const getTotalCost = (tickets) =>
    reduce(
        tickets,
        (mem, ticket) => {
            const {
                displayShippingFee,
                selectedQuantity,
                totalCost,
                totalCostWithoutShipping,
                variants,
            } = ticket;

            // TODO: clean when backend doesn't send displayShippingFee: { value: 0}
            const sum = displayShippingFee?.value
                ? _getTotalCostWithShippingFee(
                      totalCost,
                      totalCostWithoutShipping,
                      selectedQuantity,
                  )
                : _getTotalCostWithoutShippingFee(totalCost, selectedQuantity);
            const variantsCost = variants ? getTotalCost(variants) : 0;

            return mem + sum + variantsCost;
        },
        0,
    );

/**
 * getDisplayCostFromValue
 *
 * @param {number} value The pre-formatted cost value
 * @returns {string} Formatted currency string, or 'Free'
 */
export const getDisplayCostFromValue = (value, currency, currencyFormat) =>
    value === 0 ? FREE_TEXT : formatMajorMoney(value, currency, currencyFormat);

/**
 * getTotalDisplayCost
 *
 * Formats the total cost of all selected tickets according to currency type and
 * returns the formatted string. If total cost is zero (0), the display will
 * return the localized equivalent of the string "Free".
 *
 * eg. (tickets, currency) => '$23.42'
 *
 * @param {Object} tickets
 * @param {string} currency
 * @param {string} currencyFormat The currency format is used to determine thousands & decimal separators, and position the currency symbol
 **/

export const getTotalDisplayCost = (
    tickets,
    currency,
    currencyFormat,
    shippingFee = 0,
) =>
    getDisplayCostFromValue(
        getTotalCost(tickets) + shippingFee,
        currency,
        currencyFormat,
    );

/**
 * _getValidKeys
 *
 * Given the paymentOptions object returned from the backend,
 * returns an array of only the keys whose values are truthy.
 * Excluding 'hasPaymentOptions'
 *
 * @param {Object} paymentOptions
 **/
const _getValidKeys = (paymentOptions) =>
    Object.keys(
        pickBy(
            omit(paymentOptions, 'hasPaymentOptions'),
            (value) => value === true,
        ),
    );

// Payment options returned from the event service mapped to the corresponding
// icon type.
const PAYMENT_OPTION_ICON_MAP = {
    shouldAcceptAmexForPayment: <AmexPayment />,
    shouldAcceptBoletoBancario: <BoletoPayment />,
    shouldAcceptDiscoverForPayment: <DiscoverPayment />,
    shouldAcceptIdeal: <IdealPayment />,
    shouldAcceptMastercardForPayment: <MastercardPayment />,
    shouldAcceptOxxo: <OxxoPayment />,
    shouldAcceptPagofacil: <PagofacilPayment />,
    shouldAcceptRapipago: <RapipagoPayment />,
    shouldAcceptSepaDirectDebit: <SepaPayment />,
    shouldAcceptSofort: <SofortPayment />,
    shouldAcceptVisaForPayment: <VisaPayment />,
    shouldAcceptVisaDebitForPayment: <VisaDebitPayment />,
    shouldShowPaypalPaymentMethod: <PaypalPayment />,
};

/**
 * _getValidPaymentIconTypes
 *
 * Given an array of payment options, return the corresponding payment icon types.
 *
 * @param {Array} validKeys
 **/
const _getValidPaymentIconTypes = (validKeys) =>
    validKeys.map((paymentOption) => PAYMENT_OPTION_ICON_MAP[paymentOption]);

/**
 * getValidPaymentOptions
 *
 * Receives the paymentOptions object as defined by Event Service Response, and
 * returns an array of valid payment options formatted to be passed directly into
 * the <TicketPage />
 *
 * @param {Object} paymentOptions
 **/
export const getValidPaymentOptions = (paymentOptions) => {
    let validPaymentIconTypes;

    if (paymentOptions.hasPaymentOptions) {
        validPaymentIconTypes = _getValidPaymentIconTypes(
            _getValidKeys(paymentOptions),
        );
    }

    return validPaymentIconTypes;
};

/**
 * getVariantRangeCostForDisplay
 *
 * Display a price range given the variants, there are three common examples:
 * - If all variants are priced, a normal range is returned
 * `$50.00-$100`
 *
 * - If all variants are free, a simple "Free" text is returned
 * - If at least one variant is free, a range is returned starting from '$0.00'
 * `$0.00-$20.00`
 *
 * @param {Array} variants list of variant objects attached to tiered ticket object
 * @param {String} currencyFormat
 *
 * @return {String} the price range for the given list of variants
 */
export const getVariantRangeCostForDisplay = (variants, currencyFormat) => {
    // get variants actually representing tickets, either it has `cost` field or `free` is true
    const ticketVariants = variants.filter(({ cost, free }) => cost || free);

    const paidVariants = ticketVariants.filter(({ free }) => !free);

    if (paidVariants.length === 0) {
        return FREE_TEXT;
    }

    const variantsNotSoldOut = ticketVariants.filter(
        (variant) => !isTierVariantSoldOut(variant),
    );
    let variantsForPriceRange;

    if (isEmpty(variantsNotSoldOut)) {
        variantsForPriceRange = ticketVariants;
    } else {
        variantsForPriceRange = variantsNotSoldOut;
    }

    const transformedVariants = variantsForPriceRange.map((variant) =>
        variant.free
            ? {
                  ...variant,
                  cost: {
                      value: 0,
                      majorValue: 0,
                  },
              }
            : variant,
    );

    return getTicketsPriceRange(transformedVariants, currencyFormat);
};

/**
 * getVariantCostForDisplay
 *
 * Returns a formatted price string for a given variant.
 *
 * @param {Object} variant the `variant` object which represents a tier
 * @param {String} currencyFormat
 * @returns {String} actualy single price for the given tier variant.
 */

export const getVariantCostForDisplay = (variant, currencyFormat) => {
    const { value = 0, currency } = getCountryAdjustedTicketPrice(variant);

    return value === 0
        ? FREE_TEXT
        : `${formatMajorMoney(value, currency, currencyFormat)}`;
};

/**
 * Returns a formatted string using the `originalCost` object (type: Currency Cost) that is returned
 * on variants after a promo code is applied. For more details about return value see:
 * https://github.com/eventbrite/js-utils/tree/master/src/intl#formatmajormoney
 *
 * @param {Object} variant The `variant` object of type Tier
 * @param {String} currencyFormat
 * @returns {String}
 */
export const getVariantDiscountCostForDisplay = (variant, currencyFormat) => {
    if (!variant || !variant.originalCost) {
        return '';
    }

    const {
        originalCost: { value, currency },
    } = variant;

    return formatMajorMoney(value, currency, currencyFormat);
};
