import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { translationPropType } from '@eb/i18n';

import { PROMO_CODE_FORM_NAME } from '../../constants';
import * as constants from '../../containers/ticketSelection/constants';

import {
    OPTIONS_PROP_TYPE as PROMO_CODE_OPTIONS_PROP_TYPE,
    HANDLER_PROP_TYPE as PROMO_CODE_HANDLER_PROP_TYPE,
} from './constants';

import PromoCodeForm from './PromoCodeForm';
import { PromoCodeFormLink } from './helpers';

const _getHasSellableTickets = (status, shouldHideStatusOnTickets) =>
    !shouldHideStatusOnTickets ||
    constants.TICKET_SALES_STATUS_MAP[status].datePropKey ===
        constants.SALES_END_PROP_KEY;

const _isNoticeRightAligned = (
    shouldShowPromoCodeForm,
    hasSellableTickets,
    isPromoCodeFormOpen,
) => shouldShowPromoCodeForm && hasSellableTickets && !isPromoCodeFormOpen;

/**
 * Helper component to render a notice above the ticket list, if necessary.
 * Information that applies to the sales start and/or end dates of all tickets is
 * displayed here.
 *
 * EB-48765: We should move this ticket notice logic to the TicketsSelectionList
 * component in EDS so that it can be reused.
 */
const Notice = ({
    status,
    dateString,
    shouldHideStatusOnTickets,
    isRightAligned,
    isBelowPromoCodeLink,
}) => {
    let component = null;

    // When shouldHideStatusOnTickets is true, it means that we want to hide
    // the status on individual tickets and instead show a notice at the top
    // that applies to ALL tickets.
    if (shouldHideStatusOnTickets) {
        const statusInfo = constants.TICKET_SALES_STATUS_MAP[status];
        const classes = classNames(
            'eds-g-cell',
            {
                'eds-g-cell-1-1': !isRightAligned,
                'eds-g-cell-6-12 eds-text--right': isRightAligned,
                'eds-l-pad-top-4': isBelowPromoCodeLink,
            },
            statusInfo.textColor,
        );

        component = (
            <div className={classes} data-spec="tickets-page-notice">
                {statusInfo.getDisplayText(dateString)}
            </div>
        );
    }

    return component;
};

export default class PromoCodeBar extends React.Component {
    static propTypes = {
        /**
         * The page rendering the PromoCodeBar; passed to PromoCodeForm for tracking purposes
         */
        checkoutStep: PropTypes.string,
        /**
         * A sales status (sales end soon, sales not started, etc.) that applies to all tickets, if it exists
         */
        statusForAllTickets: PropTypes.oneOf(
            constants.TICKET_SALES_STATUS_PROP_TYPE,
        ),

        /**
         * A common sales start or end date for all tickets, if it exists
         */
        salesDateForAllTickets: translationPropType,

        /**
         * Callback provided by consuming component to handle when promo code link gets clicked
         */
        onPromoCodeLinkClick: PropTypes.func,
        /**
         * PromoCodeForm-related props
         */
        shouldShowPromoCodeForm: PropTypes.bool,
        promoCodeFormOptions: PROMO_CODE_OPTIONS_PROP_TYPE,
        promoCodeFormHandlers: PROMO_CODE_HANDLER_PROP_TYPE,
    };

    static defaultProps = {
        promoCodeFormOptions: {},
    };

    state = {
        isPromoCodeFormOpen:
            !!this.props.promoCodeFormOptions.promoCode || false,
    };

    _handlePromoCodeLinkClick() {
        const { onPromoCodeLinkClick } = this.props;

        if (onPromoCodeLinkClick) {
            onPromoCodeLinkClick();
        }

        this.setState({ isPromoCodeFormOpen: true });
    }

    _getPromoCodeLink() {
        const { shouldShowPromoCodeForm } = this.props;
        const { isPromoCodeFormOpen } = this.state;
        let promoCodeLink = null;

        if (shouldShowPromoCodeForm) {
            promoCodeLink = (
                <PromoCodeFormLink
                    onClick={this._handlePromoCodeLinkClick.bind(this)}
                />
            );

            if (isPromoCodeFormOpen) {
                promoCodeLink = null;
            }
        }

        return promoCodeLink;
    }

    _getPromoCodeForm() {
        const {
            checkoutStep,
            promoCodeFormOptions,
            promoCodeFormHandlers,
            shouldShowPromoCodeForm,
        } = this.props;
        const { isPromoCodeFormOpen } = this.state;
        let promoCodeForm = null;

        if (shouldShowPromoCodeForm && isPromoCodeFormOpen) {
            const options = {
                ...promoCodeFormOptions,
                shouldShowInternalCta: true,
            };
            const onPromoCodeSubmit = promoCodeFormHandlers.onSubmit;

            promoCodeForm = (
                <PromoCodeForm
                    form={PROMO_CODE_FORM_NAME}
                    onSubmit={onPromoCodeSubmit}
                    options={options}
                    handlers={promoCodeFormHandlers}
                    checkoutStep={checkoutStep}
                />
            );
        }

        return promoCodeForm;
    }

    render() {
        const {
            statusForAllTickets,
            salesDateForAllTickets,
            shouldShowPromoCodeForm,
        } = this.props;
        const { isPromoCodeFormOpen } = this.state;

        const shouldHideStatusOnTickets = !!(
            statusForAllTickets && salesDateForAllTickets
        );
        const hasSellableTickets = _getHasSellableTickets(
            statusForAllTickets,
            shouldHideStatusOnTickets,
        );
        const promoCodeLink = this._getPromoCodeLink();
        const isRightAligned = _isNoticeRightAligned(
            shouldShowPromoCodeForm,
            hasSellableTickets,
            isPromoCodeFormOpen,
        );

        return (
            <div>
                {this._getPromoCodeForm()}
                <div className="eds-g-grid">
                    {promoCodeLink}
                    <Notice
                        status={statusForAllTickets}
                        dateString={salesDateForAllTickets}
                        shouldHideStatusOnTickets={shouldHideStatusOnTickets}
                        isRightAligned={isRightAligned}
                        isBelowPromoCodeLink={promoCodeLink && !isRightAligned}
                    />
                </div>
            </div>
        );
    }
}
