import PropTypes from 'prop-types';
import { translationPropType } from '@eb/i18n';
import gettext from '@eb/gettext';
import {
    SHARE_ON_EMAIL,
    SHARE_ON_FACEBOOK,
    SHARE_ON_FACEBOOK_MESSENGER,
    SHARE_ON_TWITTER,
} from '@eb/eds-share-box';
import { CannedQuestionName, QuestionType } from './api/models/survey';
import { CHECKOUT_FORM_NAME } from './constants/checkoutForm';
export { CHECKOUT_FORM_NAME };

import { REFUND_POLICY_CODE } from './components/refundPolicy/constants';

import {
    PaymentMethodType,
    PAYMENT_METHODS_INFO,
    PAYMENT_CONSTRAINT_VARIANTS,
    BraintreeVariant,
    ALL_BRAINTREE_VARIANTS,
    ALL_PAYMENT_METHOD_TYPES,
    PaymentConstraintName,
    InstrumentType,
} from './models/paymentMethod';
import { DeliveryMethod } from './api/models/deliveryMethod';
// TODO: EB-144585 Don't export these
export const ALL_PAYMENT_METHODS = ALL_PAYMENT_METHOD_TYPES;
export {
    PAYMENT_METHODS_INFO,
    PAYMENT_CONSTRAINT_VARIANTS,
    ALL_BRAINTREE_VARIANTS,
};

/* Ticketing vs. Registration language */
export const TICKETING = 'ticket';
export const REGISTRATION = 'registration';
export const CASHBASED = 'cashbased';

/* Display Price in Order Summary and Footer when totalCost is 0 */
export const FREE_TEXT = gettext('Free');

/* Server error and success messages */
export const EVENT_DEFAULT_ERROR_MESSAGE = gettext('Sorry, there was an error');
export const EVENT_NOT_FOUND_MESSAGE = gettext('The event was not found');
export const PROMO_CODE_FAILURE_MESSAGE = gettext(
    'Sorry, that code is invalid',
);
export const SERIES_DEFAULT_ERROR_MESSAGE = gettext(
    'Sorry, there was an error',
);
export const TICKETS_INCLUDE_TAXES_AND_FEES = gettext(
    'Prices include all taxes and fees.',
);
export const WAITLIST_DEFAULT_ERROR_MESSAGE = gettext(
    'Sorry, there was an error',
);
export const WAITLIST_FULL_ERROR_MESSAGE = gettext(
    "We're sorry! The waitlist is now full",
);
export const WAITLIST_ARGUMENT_ERROR_MESSAGE = gettext(
    'Please make sure you have entered correctly formatted information into all fields and try again.',
);
export const INVALID_PROMO_CODE = 'INVALID_PROMO_CODE';
export const WAITLIST_LINK_INVALID = 'WAITLIST_LINK_INVALID';
export const WAITLIST_LINK_EXPIRED = 'WAITLIST_LINK_EXPIRED';
export const WAITLIST_LINK_USED = 'WAITLIST_LINK_USED';
export const WAITLIST_LINK_CANCELED = 'WAITLIST_LINK_CANCELED';
export const WAITLIST_DISABLED = 'WAITLIST_DISABLED';

export const WAITLIST_RELEASE_SERVER_ERRORS = [
    WAITLIST_LINK_INVALID,
    WAITLIST_LINK_EXPIRED,
    WAITLIST_LINK_USED,
    WAITLIST_LINK_CANCELED,
];

export const DISCOUNT_REASON_MEMBERSHIP_PLAN = 'MEMBERSHIP_PLAN';

/* Types of promotional code */
export const PROMO_CODE_TYPES = {
    ACCESS: 'access',
    DISCOUNT: 'coded',
    HOLD: 'hold',
    PUBLIC: 'public',
};

export const CLEAR_LABEL = 'clear';
export const REMOVE_LABEL = 'remove';

/* Types of payment instruments (payments service) */
export const INSTRUMENT_TYPES = {
    CREDIT_CARD: InstrumentType.CREDIT_CARD,
};

/* Types of payment methods (order service) */
export const PAYMENT_METHODS = {
    NOT_SELECTED: PaymentMethodType.NOT_SELECTED,
    BOLETO_BANCARIO: PaymentMethodType.BOLETO_BANCARIO,
    CASH: PaymentMethodType.CASH,
    CHECK: PaymentMethodType.CHECK,
    CREDIT: PaymentMethodType.CREDIT,
    FPP_PAYPAL: PaymentMethodType.FPP_PAYPAL,
    IDEAL: PaymentMethodType.IDEAL,
    INVOICE: PaymentMethodType.INVOICE,
    MAESTRO_BANCONTACT: PaymentMethodType.MAESTRO_BANCONTACT,
    NONCE_BRAINTREE: PaymentMethodType.NONCE_BRAINTREE,
    SEPA_DIRECT_DEBIT: PaymentMethodType.SEPA_DIRECT_DEBIT,
    SOFORT: PaymentMethodType.SOFORT,
    USER_INSTRUMENT: PaymentMethodType.USER_INSTRUMENT,
    BARCODE: PaymentMethodType.BARCODE,
    RAPIPAGO: PaymentMethodType.RAPIPAGO,
    PAGOFACIL: PaymentMethodType.PAGOFACIL,
    OXXO: PaymentMethodType.OXXO,
    AUTHNET: PaymentMethodType.AUTHNET,
};

/** Types of orders*/
export const ORDER_TYPE_MANUAL = 'manual';
export const ORDER_TYPE_TRANSFER = 'transfer';

/** Possible payment method types returned by payment_options endpoint */
export const PAYMENT_OPTIONS_METHODS_TYPES = {
    EVENTBRITE: 'eventbrite',
    OFFLINE: 'offline',
    PAYPAL: 'paypal',
    AUTHNET: 'authnet',
};

export const PAYMENT_METHOD_TYPES_SUPPORTING_CREDIT_CARD_INSTRUMENT = {
    [PAYMENT_OPTIONS_METHODS_TYPES.EVENTBRITE]: true,
    [PAYMENT_OPTIONS_METHODS_TYPES.AUTHNET]: true,
};

export const PAYMENT_METHOD_TYPES_SUPPORTING_MAESTRO_BANCONTACT_INSTRUMENT = {
    [PAYMENT_OPTIONS_METHODS_TYPES.EVENTBRITE]: true,
};

export const CREDIT_CARD_BASED_PAYMENT_METHODS = {
    [PAYMENT_METHODS.CREDIT]: true,
    [PAYMENT_METHODS.AUTHNET]: true,
};

/* Types of cash based payment methods */
export const CASH_BASED_PAYMENT_METHODS = {
    BOLETO_BANCARIO: 'BOLETO_BANCARIO',
    PAGOFACIL: 'PAGOFACIL',
    RAPIPAGO: 'RAPIPAGO',
    OXXO: 'OXXO',
};

export const SELECTED_PAYMENT_METHOD_SHAPE = PropTypes.oneOf(
    Object.values(PAYMENT_METHODS),
);

// TODO: EB-144585 Don't export these
export const PAYMENT_CONSTRAINT_NAMES = {
    ADYEN_POS_PAYMENT: PaymentConstraintName.ADYEN_POS_PAYMENT,
    AMEX: PaymentConstraintName.AMEX,
    BOLETO_BANCARIO: PaymentConstraintName.BOLETO_BANCARIO,
    BRAINTREE_PAYPAL: PaymentConstraintName.BRAINTREE_PAYPAL,
    CREDIT_CARD: PaymentConstraintName.CREDIT_CARD,
    DEBIT_CARD: PaymentConstraintName.DEBIT_CARD,
    DINERS_CLUB: PaymentConstraintName.DINERS_CLUB,
    DISCOVER: PaymentConstraintName.DISCOVER,
    ELO: PaymentConstraintName.ELO,
    HIPERCARD: PaymentConstraintName.HIPERCARD,
    IDEAL: PaymentConstraintName.IDEAL,
    MAESTRO: PaymentConstraintName.MAESTRO,
    MAESTRO_BANCONTACT: PaymentConstraintName.MAESTRO_BANCONTACT,
    MASTERCARD: PaymentConstraintName.MASTERCARD,
    MERCADO_PAGO: PaymentConstraintName.MERCADO_PAGO,
    MONERIS: PaymentConstraintName.MONERIS,
    NARANJA: PaymentConstraintName.NARANJA,
    NATIVA: PaymentConstraintName.NATIVA,
    OXXO: PaymentConstraintName.OXXO,
    PAGOFACIL: PaymentConstraintName.PAGOFACIL,
    RAPIPAGO: PaymentConstraintName.RAPIPAGO,
    SEPA_DIRECT_DEBIT: PaymentConstraintName.SEPA_DIRECT_DEBIT,
    SOFORT: PaymentConstraintName.SOFORT,
    VISA: PaymentConstraintName.VISA,
    VISA_DEBIT: PaymentConstraintName.VISA_DEBIT,
    BARCODE: PaymentConstraintName.BARCODE,
};

export const PAYMENT_CONSTRAINTS_SHAPE = PropTypes.shape({
    id: PropTypes.string.isRequired,
    instrumentType: PropTypes.oneOf([...Object.values(INSTRUMENT_TYPES)])
        .isRequired,
    paymentMethod: PropTypes.arrayOf(
        PropTypes.oneOf([...Object.values(INSTRUMENT_TYPES)]),
    ),
});

/* Braintree */
// TODO: EB-144585 Don't export these
export const BRAINTREE_VARIANTS = {
    PAYPAL: BraintreeVariant.Paypal,
    PAYPAL_CREDIT: BraintreeVariant.PaypalCredit,
};
export const BRAINTREE_STATUS_AUTHENTICATE_SUCCESSFUL =
    'authenticate_successful';
export const BRAINTREE_STATUS_LOOKUP_ENROLLED = 'lookup_enrolled';
export const BRAINTREE_TRANSSTATUS_AUTHENTICATION_NOT_PERFORMED = 'U';

/* Forms */
export const TICKETS_SELECTION_PAGE_FORM_NAME = 'ticketsSelectionPageForm';
export const BUY_ON_MAP_FORM_NAME = 'buyOnMapForm';
export const TICKETS_PAGE_FORM_NAME = 'ticketsPageForm';
export const WAITLIST_FORM_NAME = 'waitlistForm';
export const RESEND_EMAIL_FORM_NAME = 'resendEmailForm';
export const PROMO_CODE_FORM_NAME = 'promoCodeForm';
export const DELIVERY_METHOD_FORM_NAME = 'deliveryMethodForm';
export const FULFILLMENT_ADDRESS = 'fulfillmentAddress';
export const EVENT_SERIES_FORM_NAME = 'eventSeriesForm';

export const FORMS_TO_RESET = [
    TICKETS_PAGE_FORM_NAME,
    CHECKOUT_FORM_NAME,
    RESEND_EMAIL_FORM_NAME,
    PROMO_CODE_FORM_NAME,
    DELIVERY_METHOD_FORM_NAME,
];

export const SQ_ANALYTICS_TOKEN = 'sqAnalyticsToken';

export const POST_MESSAGE_STATUS = {
    widgetModalClose: 'widgetModalClose',
    startOrder: 'orderStart',
    completeOrder: 'orderComplete',
    backToTicketSelection: 'backToTicketSelection',
    protectCheckout: 'protectCheckout',
    ticketQuantityChange: 'ticketQuantityChange',
    checkoutLoginSuccessful: 'checkoutLoginSuccessful',
    checkoutLoginSoftAccount: 'checkoutLoginSoftAccount',
    checkoutLoginHeightChange: 'checkoutLoginHeightChange',
    checkoutContinueAsGuest: 'checkoutContinueAsGuest',
};

export const CHECKOUT_TITLE = gettext('Checkout');
export const DELIVERY_METHOD_TITLE = gettext('Choose Delivery Method');
export const ORDER_CONFIRMATION_TITLE = gettext('Order confirmation');
export const PAYMENTS_TITLE = gettext('Payment');

export const BACK_TO_TICKET_SELECTION_TITLE = gettext(
    'Back to Ticket Selection',
);
export const BACK_TO_PAYMENT_METHODS_TITLE = gettext('Back to Payment Methods');
export const BACK_TO_SERIES_EVENTS_TITLE = gettext('Back to All Events');
export const BACK_AND_CANCEL_ORDER_TITLE = gettext('Back and cancel order');
export const BACK_TO_CHECKOUT_FORM = gettext('Back to form');

export const CTA_CHECKOUT_TEXT = gettext('Checkout');
export const CTA_PAYMENTS_TEXT = gettext('Place order');
export const CTA_PICK_A_SEAT_TEXT = gettext('View on Map');
export const CTA_TEXT_PROCESSING = gettext('Processing...');
export const CTA_COMPLETE_PAID_TEXT = gettext('Place Order');
export const CTA_COMPLETE_FREE_TEXT = gettext('Register');
export const CTA_JOIN_WAITLIST = gettext('Join Waitlist');

export const CTA_VIEW_VOUCHER_CASH = {
    BOLETO_BANCARIO: gettext('Download Boleto PDF'),
    PAGOFACIL: gettext('View Pagofacil code'),
    RAPIPAGO: gettext('View Rapipago code'),
    OXXO: gettext('View OXXO code'),
};

export const DUEDATE_MESSAGE_CONFIRMATION_CASH = {
    BOLETO_BANCARIO:
        'You have to pay the Boleto in full or your order will be cancelled. Payment must be received no later than %(dueDate)s',
    PAGOFACIL:
        'You have to pay the Pagofacil voucher in full or your order will be cancelled. Payment must be received no later than %(dueDate)s',
    RAPIPAGO:
        'You have to pay the Rapipago voucher in full or your order will be cancelled. Payment must be received no later than %(dueDate)s',
    OXXO:
        'You have to pay the OXXO voucher in full or your order will be cancelled. Payment must be received no later than %(dueDate)s',
};

// NOTE: This is duplicated from @eb/site-header so that checkoutWidget
// doesn't have to depend on the package.
export const ENV_SHAPE_PROP_TYPE = PropTypes.shape({
    serverUrl: PropTypes.string.isRequired,
    ebDomain: PropTypes.string.isRequired,
    signupUrl: PropTypes.string.isRequired,
    loginUrl: PropTypes.string.isRequired,
    logoutUrl: PropTypes.string.isRequired,
    searchLocation: PropTypes.shape({
        address: PropTypes.string,
    }).isRequired,
    locales: PropTypes.arrayOf(
        PropTypes.shape({
            locale: PropTypes.string.isRequired,
            locale_name: PropTypes.string.isRequired,
        }),
    ).isRequired,
    localeInfo: PropTypes.shape({
        tld: PropTypes.string.isRequired,
        locale: PropTypes.string.isRequired,
        locale_name: PropTypes.string.isRequired,
        contact_us_path: PropTypes.string.isRequired,
        contact_us_url: PropTypes.string.isRequired,
        sales_contact_landing_page: PropTypes.string.isRequired,
        sales_contact_link_text: PropTypes.string.isRequired,
        twitter_page: PropTypes.string.isRequired,
        facebook_page: PropTypes.string.isRequired,
        linked_in_page: PropTypes.string.isRequired,
        instagram_page: PropTypes.string.isRequired,
        blog_url: PropTypes.string.isRequired,
    }).isRequired,
});

/* * * * * * * * * * * * *
 * PropTypes custom object shapes
 * * * * * * * * * * * * */
export const RESERVED_ORDER_COSTS_SHAPE = PropTypes.shape({
    basePrice: PropTypes.object,
    displayFee: PropTypes.object,
    displayPrice: PropTypes.object,
    eventbriteFee: PropTypes.object,
    gross: PropTypes.object,
    paymentFee: PropTypes.object,
    priceBeforeDiscount: PropTypes.object,
    tax: PropTypes.object,
});

export const SELECTED_TICKETS_SHAPE = PropTypes.shape({
    id: PropTypes.string.isRequired,
    free: PropTypes.bool.isRequired,
    selectedQuantity: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    cost: PropTypes.shape({
        currency: PropTypes.string.isRequired,
        display: translationPropType.isRequired,
        value: PropTypes.number.isRequired,
    }),
});

export const ORDER_COSTS_SHAPE = PropTypes.shape({
    displayFee: PropTypes.shape({
        currency: PropTypes.string.isRequired,
        display: translationPropType.isRequired,
        majorValue: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        retainRefundFeeToAttendee: PropTypes.bool,
    }),
    displayPrice: PropTypes.shape({
        currency: PropTypes.string.isRequired,
        display: translationPropType.isRequired,
        majorValue: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
    }),
    displayTax: PropTypes.shape({
        name: PropTypes.string.isRequired,
        tax: PropTypes.shape({
            currency: PropTypes.string.isRequired,
            display: translationPropType.isRequired,
            majorValue: PropTypes.string.isRequired,
            value: PropTypes.number.isRequired,
        }),
    }),
    gross: PropTypes.shape({
        currency: PropTypes.string.isRequired,
        display: translationPropType.isRequired,
        majorValue: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
    }),
});

export const ORDER_SHAPE = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    costs: ORDER_COSTS_SHAPE,
});

export const WAITING_ROOM_SHAPE = PropTypes.shape({
    queue_server: PropTypes.string,
    register_server: PropTypes.string,
    queue_id: PropTypes.string,
    is_activated_for_event: PropTypes.bool,
});

export const EVENT_INFORMATION_PROPS_SHAPE = {
    eventTitle: PropTypes.string,
    eventDate: translationPropType,
    eventLocation: translationPropType,
    hideGreyBackground: PropTypes.bool,
};

export const ORDER_CONFIRMATION_SETTINGS_SHAPE = PropTypes.shape({
    orderMessage: PropTypes.string,
    ticketMessages: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            message: PropTypes.string.isRequired,
        }),
    ),
});

export const PAYMENT_SHAPE = PropTypes.shape({
    variant: PropTypes.string,
});

export const USER_INSTRUMENT_SHAPE = PropTypes.shape({
    vaultId: PropTypes.string.isRequired,
    cardType: PropTypes.string.isRequired,
    displayDigits: PropTypes.string.isRequired,
});

export const PAYMENT_METHOD_SHAPE = PropTypes.shape({
    type: PropTypes.string.isRequired,
    additionalData: PropTypes.shape({
        note: PropTypes.string,
    }),
});

export const BANK_IDENTIFIER_SHAPE = PropTypes.shape({
    bankId: PropTypes.string.isRequired,
    bankName: PropTypes.string.isRequired,
});

export const LOCALES_SHAPE = PropTypes.shape({
    locale: PropTypes.string,
    localized_language_name: PropTypes.string,
    locale_name: PropTypes.string,
});

export const ADDRESS_SHAPE = PropTypes.shape({
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    region: PropTypes.string,
    country: PropTypes.string,
    postal: PropTypes.string,
});

export const TEAM_SETTINGS_SHAPE = PropTypes.shape({
    has_password: PropTypes.bool,
    id: PropTypes.string,
    is_full: PropTypes.bool,
    name: PropTypes.string,
    spots_left: PropTypes.number,
    token: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
});

export const SERVER_ERRORS_SHAPE = PropTypes.arrayOf(
    PropTypes.shape({
        error: PropTypes.string.isRequired,
        errorDescription: PropTypes.string.isRequired,
    }),
);

export const WAITLIST_QUESTION_MAPPING = {
    first_name: {
        questionId: CannedQuestionName.firstName,
        label: gettext('First Name'),
        type: QuestionType.text,
    },
    last_name: {
        questionId: CannedQuestionName.lastName,
        label: gettext('Last Name'),
        type: QuestionType.text,
    },
    email: {
        questionId: CannedQuestionName.email,
        label: gettext('Email'),
        type: QuestionType.email,
    },
    home_phone: {
        questionId: CannedQuestionName.homePhone,
        label: gettext('Phone number'),
        type: QuestionType.phone,
    },
};

export const THEME_SETTINGS_PROP = PropTypes.shape({
    'primary-brand': PropTypes.string,
    'primary-brand--hover': PropTypes.string,
    'inverse-primary-brand': PropTypes.string,
});

export const REFUND_POLICY_CODE_SHAPE = PropTypes.oneOf([
    REFUND_POLICY_CODE.FLEXIBLE,
    REFUND_POLICY_CODE.MODERATE,
    REFUND_POLICY_CODE.STRICT,
    REFUND_POLICY_CODE.NOT_DEFINED,
    REFUND_POLICY_CODE.NO_REFUNDS,
]);

export const REFUND_POLICY_PROP_SHAPE = PropTypes.shape({
    refundPolicyCode: PropTypes.bool,
    enableRefundFeeRetentionPolicy: PropTypes.bool,
    refundPolicyCode: REFUND_POLICY_CODE_SHAPE,
});

/* Route Related Constants */
export const ABANDON_PATH = 'abandon';
export const BASE_URL = '/checkout-external';
export const CHECKOUT_PATH = 'checkout';
export const PAYMENTS_PATH = 'payments';
export const DELIVERY_METHODS_PATH = 'selectDeliveryMethod';
export const EXTERNAL_PAYMENT_PATH = 'externalPayment';
export const EXISTING_ORDER_PATH = 'existingOrder';
export const GROUP_REG_JOIN_PATH = 'groupRegJoin';
export const GROUP_REG_CREATE_PATH = 'groupRegCreate';
export const GROUP_REG_TYPE_SELECTION_PATH = 'groupRegTypeSelection';
export const LOGIN_PATH = 'loginPath';
export const PICK_A_SEAT_PATH = 'pickASeat';
export const PROTECTED_PATH = 'protectedEventWarning';
export const PURCHASE_CONFIRMATION_PATH = 'purchaseConfirmation';
export const RESEND_EMAIL_PATH = 'resendEmail';
export const RESERVED_TICKETS_CONFIRMATION_PATH = 'reservedTicketsConfirmation';
export const RESERVED_TICKETS_PATH = 'reservedTickets';
export const SERIES_PATH = 'series';
export const STATUS_PATH = 'status';
export const TICKETS_PATH = 'tickets';
export const UNLOCK_TICKETS_PATH = 'unlockTickets';
export const WAITING_ROOM_PATH = 'waitingRoom';
export const WAITLIST_PATH = 'waitlist';
export const WAITLIST_CONFIRMATION_PATH = 'waitlistConfirmation';
export const TICKETS_WITH_CAPACITY_WAITLIST_PATH =
    'ticketsWithCapacityWaitlist';
export const TICKETS_WITH_TICKET_RESELLER_PATH = 'ticketsWithTicketReseller';
export const PAY_IN_PATH = 'payins';
export const PAY_IN_CONFIRMATION_PATH = 'payInConfirmation';

export const E_TICKET_DATA_SPEC = 'e-ticket';
export const WILL_CALL_DATA_SPEC = 'will-call';
export const STANDARD_SHIPPING_DATA_SPEC = 'standard-shipping';

// used for delivery method countries
export const DEFAULT_COUNTRY = 'US';
export const OUTSIDE_US_COUNTRY = 'Other';

/* Static text for delivery method instructions */
export const E_TICKET_INSTRUCTIONS = gettext(`
    Your tickets will be attached to your confirmation email. You can
    also access them from your Eventbrite account or mobile app.
`);
export const WILL_CALL_INSTRUCTIONS = gettext(`
    Your tickets will be waiting for you at the venue's pick-up location
    on the day of the event. Please bring photo ID and the card used for purchase.
`);
export const STANDARD_SHIPPING_INSTRUCTIONS = gettext(`
    Your tickets will be mailed to your shipping address. You should
    receive them no later than 48 hours before the event.
`);
// TODO: Move this to event model
export const DELIVERY_METHOD_INSTRUCTIONS = {
    [DeliveryMethod.eTicket]: E_TICKET_INSTRUCTIONS,
    [DeliveryMethod.willCall]: WILL_CALL_INSTRUCTIONS,
    [DeliveryMethod.starndardShipping]: STANDARD_SHIPPING_INSTRUCTIONS,
};

export const STANDARD_SHIPPING_COUNTRIES = [
    { value: DEFAULT_COUNTRY, display: gettext('United States') },
    {
        value: OUTSIDE_US_COUNTRY,
        display: gettext('Outside of the United States'),
    },
];

export const STANDARD_SHIPPING_ADDRESS_COUNTRIES = {
    isLoading: false,
    content: [{ code: 'US', label: 'United States', zip_required: true }],
};

/* GA Tracking Related */
export const UNTRACKED_QUESTION_TYPES = ['address', 'email', 'text'];

export const ORGANIZATION_VERTICALS = {
    music: 'Music',
    default: 'Core',
};

export const CAPACITY = 'Capacity';
export const TICKET_LEVEL = 'Ticket Level';

export const FORM_TO_PATH_MAPPING = {
    [CHECKOUT_FORM_NAME]: CHECKOUT_PATH,
    [DELIVERY_METHOD_FORM_NAME]: DELIVERY_METHODS_PATH,
};

export const ONE_CHECKOUT = 'one-checkout';

export const WIDGET_TRACKING = {
    ABANDON_CHECKOUT_CANCEL: {
        action: 'AbandonCheckoutCancel',
    },
    ABANDON_CHECKOUT_CONFIRM: {
        action: 'AbandonCheckoutConfirm',
    },
    ABANDON_ORDER_CLICK: {
        action: 'AbandonOrderClick',
    },
    BACK_CLICK: {
        action: 'BackClick',
    },
    DM_SELECTION_CHANGE: {
        action: 'MODSelectionChange',
    },
    DM_PAGE_SUBMIT: {
        action: 'MODContinueClick',
    },
    TIMER_EXPIRED: {
        action: 'TimerExpired',
    },
    CART_ICON_CLICK: {
        action: 'CartIconClick',
    },
    OC_PAY_IN_LOAD: {
        action: 'PayInLoad',
    },
    OC_PAY_IN_SUBMITTED: {
        action: 'PayinPlace',
    },
    OC_ORDER_SUCCESS: {
        action: 'OrderSuccess',
    },
    OC_PAY_IN_SUCCESS: {
        action: 'PayInSuccess',
    },
    OC_ORDER_FAILURE: {
        action: 'OrderFailure',
    },
    OC_PAY_IN_FAILURE: {
        action: 'PayInFailure',
    },
    OC_EDIT_EMAIL: {
        action: 'EditEmail',
    },
    OC_EDIT_EMAIL_UPDATE: {
        action: 'EditEmailUpdate',
    },
    OC_EDIT_EMAIL_CANCEL: {
        action: 'EditEmailCancel',
    },
    OC_MY_TICKETS_CLICK: {
        action: 'MyTicketsClick',
    },
    OC_SHARE_FB_CLICK: {
        action: 'ShareFBClick',
    },
    OC_SHARE_EMAIL_CLICK: {
        action: 'ShareEmailClick',
    },
    OC_SHARE_TWITTER_CLICK: {
        action: 'ShareTwitterClick',
    },
    OC_SHARE_MESSENGER_CLICK: {
        action: 'ShareMsgrClick',
    },
    TRACK_PROTECTED_CTA_CLICK: {
        action: 'ProtectedEventClick',
    },
    RF_EMAIL_EDIT_AND_LOGOUT_CLICK: {
        action: 'EmailEditClicked',
    },
    RF_LOGIN_LINK_CLICKED: {
        action: 'LoginClick',
    },
    RF_LOGOUT_LINK_CLICKED: {
        action: 'LogoutClick',
    },
    RF_LOGIN_CONTINUE_AS_GUEST_CLICKED: {
        action: 'ContinueAsGuestClick',
    },
    RF_PAYMENT_METHOD_SELECT: {
        action: 'PaymentSelection',
    },
    RF_SAVE_NEW_STORED_PAYMENT_METHOD_CHECKED: {
        action: 'SaveStoredPaymentChecked',
    },
    RF_STORED_PAYMENT_SELECT: {
        action: 'StoredPaymentSelectionChange',
    },
    RF_ORDER_SUBMITTED: {
        action: 'CompleteCheckoutClick',
    },
    RF_EB_EMAIL_OPT_IN: {
        action: 'EBEmailOptIn',
    },
    RF_EB_EMAIL_OPT_IN_CLICK: {
        action: 'EBEmailOptInClick',
    },
    RF_EMAIL_OPT_IN_ERROR: {
        action: 'EmailOptInError',
    },
    RF_ORG_EMAIL_OPT_IN: {
        action: 'OrgEmailOptIn',
    },
    RF_ORG_EMAIL_OPT_IN_CLICK: {
        action: 'OrgEmailOptInClick',
    },
    SP_DATE_SELECTION: {
        action: 'RepeatingEventDateSelected',
    },
    SP_DATE_SELECTION_CLEAR: {
        action: 'RepeatingEventDateCleared',
    },
    SP_DATE_PICKER_CLICK: {
        action: 'RepeatingEventDateFilterClick',
    },
    SP_TICKETS_CLICK: {
        action: 'RepeatingEventsTicketsClick',
    },
    TS_PROMO_LOAD: {
        action: 'PromoLoad',
    },
    TS_PROMO_CLICK: {
        action: 'PromoClick',
    },
    TS_PROMO_APPLY: {
        action: 'PromoApply',
    },
    TS_PROMO_REMOVE: {
        action: 'PromoRemove',
    },
    TS_PROMO_ERROR: {
        action: 'PromoError',
    },
    TS_JOIN_WAITLIST_CLICK: {
        action: 'JoinWaitlistClick',
    },
    TS_TICKET_SELECT: {
        action: 'TicketQuantitySelect',
    },
    TS_PAGE_SUBMIT: {
        action: 'TicketSelectionCheckoutClick',
    },
    TS_LANGUAGE_SELECT: {
        action: 'LanguageSelect',
    },
    TS_LOGO_CLICK: {
        action: 'LogoClick',
    },
    WL_SUBMIT_CLICK: {
        action: 'WaitlistEntrySubmit',
    },
    XP_BACK_TO_CHECKOUT: {
        action: 'BackToCheckoutAttempt',
    },
    XP_REOPEN_PAYMENT_TAB: {
        action: 'ReopenExternalPaymentTab',
    },
    TAX_INVOICE_SELECTION_CHANGE: {
        action: 'TaxInvoiceSelectionChange',
    },
    TAX_INVOICE_INPUT_FIELD_ENTER: {
        action: 'TaxInvoiceInputFieldEnter',
    },
    TAX_INVOICE_ADDRESS_DROPDOWN_CHANGE: {
        action: 'TaxInvoiceAddressDropdownChange',
    },
    [GROUP_REG_TYPE_SELECTION_PATH]: {
        action: 'GroupRegTypeSelectionClick',
    },
    [GROUP_REG_JOIN_PATH]: {
        action: 'GroupRegJoinClick',
    },
    [GROUP_REG_CREATE_PATH]: {
        action: 'GroupRegCreateClick',
    },
    PAYPAL_CTA_CLICK: {
        action: 'PayPalCTAClick',
    },
};

export const SOCIAL_SHARE_TRACKINGS = {
    [SHARE_ON_FACEBOOK]: WIDGET_TRACKING.OC_SHARE_FB_CLICK,
    [SHARE_ON_FACEBOOK_MESSENGER]: WIDGET_TRACKING.OC_SHARE_MESSENGER_CLICK,
    [SHARE_ON_EMAIL]: WIDGET_TRACKING.OC_SHARE_EMAIL_CLICK,
    [SHARE_ON_TWITTER]: WIDGET_TRACKING.OC_SHARE_TWITTER_CLICK,
};

export const VARIANT_SHAPE = PropTypes.shape({
    id: PropTypes.string.isRequired,
    color: PropTypes.string,
    cost: PropTypes.shape({
        currency: PropTypes.string,
        display: PropTypes.string,
        value: PropTypes.number,
    }),
    primary: PropTypes.boolean,
});

const BASE_ORDER_ATTENDEE_SHAPE_OBJECT = {
    id: PropTypes.string.isRequired,
    ticketClassId: PropTypes.string.isRequired,
    /** Ticket short name which doesn't include Tier name */
    ticketClassName: PropTypes.string.isRequired,
    ticketClass: PropTypes.object,
    costs: PropTypes.object,
    variantId: PropTypes.string.isRequired,
};

export const BASE_ORDER_ATTENDEE_SHAPE = PropTypes.shape(
    BASE_ORDER_ATTENDEE_SHAPE_OBJECT,
);

export const RESERVED_SEATING_ASSIGNED_UNIT_SHAPE_OBJECT = {
    unitId: PropTypes.string.isRequired,
    description: PropTypes.string,
    locationImage: PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number,
        url: PropTypes.string,
    }),
    titles: PropTypes.arrayOf(PropTypes.string).isRequired,
    labels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export const RESERVED_SEATING_ASSIGNED_UNIT_SHAPE = PropTypes.shape(
    RESERVED_SEATING_ASSIGNED_UNIT_SHAPE_OBJECT,
);

export const ORDER_ATTENDEE_WITH_ASSIGNED_UNIT_SHAPE_OBJECT = {
    ...BASE_ORDER_ATTENDEE_SHAPE_OBJECT,
    assignedUnit: RESERVED_SEATING_ASSIGNED_UNIT_SHAPE,
};

export const RESERVED_SEATING_ORDER_ATTENDEE_SHAPE = PropTypes.shape(
    ORDER_ATTENDEE_WITH_ASSIGNED_UNIT_SHAPE_OBJECT,
);

export const PROMO_CODE = 'promoCode';
export const PERCENT_OFF = 'percentOff';
export const AMOUNT_OFF = 'amountOff';
export const ALL_VARIANTS = 'all';
export const ONE_VARIANT = 'partialSingular';
export const SOME_VARIANTS = 'partialPlural';

export const APPLIED_PROMO_MESSAGING = {
    [PROMO_CODE]: {
        [ALL_VARIANTS]: gettext('%(promoCode)s applied'),
        [ONE_VARIANT]: gettext('%(promoCode)s applied'),
        [SOME_VARIANTS]: gettext('%(promoCode)s applied'),
    },
    [PERCENT_OFF]: {
        [ALL_VARIANTS]: gettext('%(percentOff)s% discount applied'),
        [ONE_VARIANT]: gettext(
            '%(percentOff)s% discount applied to one option',
        ),
        [SOME_VARIANTS]: gettext(
            '%(percentOff)s% discount applied to %(numOptions)s options',
        ),
    },
    [AMOUNT_OFF]: {
        [ALL_VARIANTS]: gettext('%(amountOff)s discount applied'),
        [ONE_VARIANT]: gettext('%(amountOff)s discount applied to one option'),
        [SOME_VARIANTS]: gettext(
            '%(amountOff)s discount applied to %(numOptions)s options',
        ),
    },
};

/* Length based on default event settings of 8 minutes */
export const DEFAULT_REGISTRATION_DURATION = 480;

/* Checkout steps used for waiting room */
export const CHECKOUT_STEP_VISIT = 'visit';
export const CHECKOUT_STEP_CART = 'cart';
export const CHECKOUT_STEP_CHECKOUT = 'checkout';
export const CHECKOUT_STEP_TIME_OUT = 'time_out';

/* Invalid Client Id string for waiting room */
export const INVALID_CLIENT_ID = 'invalid';

/* API pagination */
export const API_RESPONSE_PAGE_SIZE = 50;

/* Waitlist */
export const CAPACITY_WAITLIST = '0';

/* 3DSecure 2 */
export const MAX_AMOUNT_EXEMPTION_REQUEST = 30;

/**
 * TicketClass Categories
 *
 * UX consideration: sort order is important for use in displaying ticket groups in a meaningful
 * order to end-customers.
 */
export const TICKET_CLASS_CATEGORIES = {
    ADMISSION: 'admission',
    DONATION: 'donation',
    ADD_ON: 'add_on',
};
export const TICKET_CLASS_CATEGORIES_BY_ORDER = [
    TICKET_CLASS_CATEGORIES.ADMISSION,
    TICKET_CLASS_CATEGORIES.DONATION,
    TICKET_CLASS_CATEGORIES.ADD_ON,
];
/* Default option selected*/
export const DEFAULT_SELECTED_FORM_VALUE_FOR_SUBQUESTIONS = '0';

/* Privacy settings */
export const INVITATION_REQUIRED = 'invitation_required';
export const PASSWORD_REQUIRED = 'password_required';
export const PASSWORD_EXPIRED = 'password_expired';
export const UNLOCKED = 'unlocked';

/** 404 server error parsed status */
export const NOT_FOUND = 'NOT_FOUND';

/** Fees and taxes components **/
export const TAX_COMPONENT_BASE_FEES_TAX_INCLUDABLE =
    'item.fees-tax-includable';
export const SERVICE_FEE_COMPONENT_NAME = 'eventbrite.service_fee';
export const TAX_SERVICE_FEE_COMPONENT_NAME = 'eventbrite.service_fee.tax';
export const PROCESSING_FEE_COMPONENT_NAME = 'eventbrite.payment_fee';
export const TAX_PROCESSING_FEE_COMPONENT_NAME =
    'eventbrite.payment_fee_v2.tax';
export const SERVICE_FEE_TEXT = 'service fee';
export const ROYALTY_COMPONENT_NAME = 'royalty';
export const ORGANIZER_PAYER = 'organizer';
export const ATTENDEE_PAYER = 'attendee';
export const FEE_COMPONENT_MAP = {
    'item.display': {
        label: gettext('Ticket price'),
        order: 0,
    },
    'item.tax_exclusive': {
        label: gettext('Tax'),
        order: 1,
    },
    'item.tax_inclusive': {
        label: gettext('Tax'),
        order: 2,
    },
    'eventbrite.service_fee': {
        label: gettext('Service fee'),
        order: 3,
    },
    'eventbrite.service_fee.tax': {
        label: gettext('Service fee tax'),
        order: 4,
    },
    'eventbrite.payment_fee': {
        label: gettext('Processing fee'),
        order: 5,
    },
    'eventbrite.payment_fee_tax': {
        label: gettext('Processing fee tax'),
        order: 6,
    },
    'eventbrite.payment_fee_v2': {
        label: gettext('Processing fee'),
        order: 7,
    },
    'eventbrite.payment_fee_v2.tax': {
        label: gettext('Processing fee tax'),
        order: 8,
    },
    orgshare: {
        label: gettext('Ticket revenue'),
        order: 9,
    },
    royalty: {
        label: gettext('Royalty'),
        order: 10,
    },
    'eventbrite.gts.tax': {
        label: gettext('Sales Tax'),
        order: 12,
    },
    sales_tax: {
        label: gettext('Sales Tax'),
        order: 13,
    },
    transfer: {
        label: gettext('Transfer Fee'),
        order: 14,
    },
    default: (label) => ({
        label: label.replace(/^\w/, (c) => c.toUpperCase()),
        order: 15,
    }),
    'eventbrite.shipping.item': {
        label: gettext('Shipping fee'),
        order: 16,
    },
};

export const RESEND_EMAIL_FIELD = 'resendEmail';

// This constant needs to match EDS "medium-narrow-width" breakpoint spec
// https://github.com/eventbrite/eb-ui/blob/master/packages/eds/eds-breakpoints-json/src/breakpoints.json#L5
export const MEDIUM_NARROW_WINDOW_WIDTH = 792;

export const DEFAULT_TAX_EXEMPTION_MESSAGE = gettext(
    'This organizer has determined that Sales Tax exemption ' +
        'is applicable for certain EU countries, please enter the valid EU Sales Tax number of your business to see ' +
        'if you are eligible for exemption from paying Sales Tax on this order',
);

export const TAX_MESSAGE_VERIFYING_MESSAGE = gettext('Verifying...');

export const VALID_TAX_ID_AND_APPLICABLE_TAX_EXEMPTION_MESSAGE = gettext(
    'Congratulations! You are exempt from paying Sales Tax',
);

export const VALID_TAX_ID_TAX_EXEMPTION_NOT_APPLICABLE_MESSAGE = gettext(
    "You're verified, but the event organizer doesn't allow Sales tax " +
        'exemption in this case. Contact the organizer directly with questions',
);

export const INVALID_TAX_ID_MESSAGE = gettext(
    "Oh Snap!  That didn't work.  Check you entered your registration number correctly and that it matches the country you selected above.",
);

export const VIRTUAL_INCENTIVE_SHAPE = PropTypes.shape({
    balance: PropTypes.shape({
        currency: PropTypes.string.isRequired,
        display: PropTypes.string.isRequired,
        majorValue: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
    }),
});
export const NOT_ENOUGH_VI_BALANCE = 'NOT_ENOUGH_VI_BALANCE';
export const ERROR_ATTENDEE_CREDIT_PAYMENT_FAILED =
    'ERROR_ATTENDEE_CREDIT_PAYMENT_FAILED';
export const ATTENDEE_CREDIT_PAYMENT_FAILED = 'ATTENDEE_CREDIT_PAYMENT_FAILED';

export const VIRTUAL_INCENTIVES_ERRORS = [
    NOT_ENOUGH_VI_BALANCE,
    ERROR_ATTENDEE_CREDIT_PAYMENT_FAILED,
    ATTENDEE_CREDIT_PAYMENT_FAILED,
];

/* Login Page Text */
export const LOGIN_PAGE_MAIN_TEXT = gettext(
    "We're opening a new window for you to log in.",
);

export const LOGIN_POPUP_CTA = gettext(
    'Check out without logging in by closing the window, or <a href="#" id="login-popup-continue-as-guest">continue as guest</a>',
).toString();
export const LOGIN_PAGE_CTA = gettext('Continue as guest');
export const LOGIN_ERROR_HEADER = gettext('Whoops!');
export const LOGIN_ERROR_TEXT = gettext(
    "Sorry, we can't log you in right now.",
);
export const LOGIN_ERROR_CTA = gettext('Please continue as a guest');

/*  Order Summary Text */
export const ORDER_SUMMARY_TITLE = gettext('Order summary');
export const ORDER_SUMMARY_BUTTON_LABEL = gettext('Show order summary');

export const ROOT_CONTAINER_ID = 'root';
export const CHECKOUT_LOGIN_IFRAME_ID = 'checkout-login-iframe';
