import { AddressAction, AddressActionType } from '../actions/address';
import { AddressNamespace } from '../state/address';

export const addressReducer = <MobileData extends Record<string, unknown>>(
    state: AddressNamespace = {
        regions: {
            isLoading: false,
            byCountry: null,
        },
        countries: {
            isLoading: false,
            all: null,
        },
    },
    action: AddressAction<MobileData>,
): AddressNamespace => {
    switch (action.type) {
        case AddressActionType.startLoadingCountries:
            return {
                ...state,
                countries: {
                    ...state.countries,
                    isLoading: true,
                },
            };
        case AddressActionType.finishLoadingCountriesSuccessfully:
            return {
                ...state,
                countries: {
                    ...state.countries,
                    isLoading: false,
                    all: action.payload.countries,
                },
            };
        case AddressActionType.finishLoadingCountriesWithError:
            return {
                ...state,
                countries: {
                    ...state.countries,
                    isLoading: false,
                    all: null,
                },
            };

        case AddressActionType.startLoadingRegions:
            return {
                ...state,
                regions: {
                    ...state.regions,
                    isLoading: true,
                },
            };
        case AddressActionType.finishLoadingRegionsSuccessfully:
            return {
                ...state,
                regions: {
                    ...state.regions,
                    isLoading: false,
                    byCountry: action.payload.regionsByCountry,
                },
            };
        case AddressActionType.finishLoadingRegionsWithError:
            return {
                ...state,
                regions: {
                    ...state.regions,
                    isLoading: false,
                    byCountry: null,
                },
            };

        default:
            return state;
    }
};
