import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Icon } from '@eb/eds-icon';
import { Label } from '@eb/eds-label';

import './checkbox.scss';

import { CheckChunky } from '@eb/eds-iconography';

export default class Checkbox extends React.PureComponent {
    static propTypes = {
        /**
         * ID of checkbox component's wrapper
         */
        id: PropTypes.string.isRequired,
        /**
         * Initial checkbox checked state when it is created.
         * It will not override the state after it's been created.
         */
        defaultValue: PropTypes.bool,
        /**
         * The checkbox's checked state, overriding any existing state.
         */
        value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
        /**
         * Name of the checkbox
         */
        name: PropTypes.string,
        /**
         * The contents of the checkbox (i.e: its label)
         */
        children: PropTypes.node,
        /**
         * The value of the checkbox
         */
        htmlValue: PropTypes.string,
        /**
         * Value of if the checkbox is disabled
         */
        isDisabled: PropTypes.bool,
        /**
         * Function to be invoked when checkbox checked state changes.
         * Passes the checked state and checkbox value
         */
        onChange: PropTypes.func,
        /**
         * Function To be invoked when checkbox is blurred.
         * Passes the checked state and checkbox value
         */
        onBlur: PropTypes.func,
        /**
         * Function to be invoked when checkbox is focused.
         * Passes the checked state and checkbox value
         */
        onFocus: PropTypes.func,
        /**
         * Identifier for tests
         */
        'data-spec': PropTypes.string,
        /**
         * Class names for checkbox component wrapper
         */
        __containerClassName: PropTypes.string,
    };

    static defaultProps = {
        defaultValue: false,
        isDisabled: false,
        'data-spec': 'checkbox',
    };

    constructor(props) {
        super(props);

        const value =
            props.value === undefined
                ? props.defaultValue
                : Boolean(props.value);

        this.state = { value };
    }

    componentWillReceiveProps({ value }) {
        // We explicitly test for undefined for the case when the value is
        // specifically not passed
        if (value !== undefined) {
            this.setState({ value: Boolean(value) });
        }
    }

    _handleChange(e) {
        const { onChange, htmlValue } = this.props;
        const value = e.target.checked;

        this.setState({ value });

        if (onChange) {
            onChange(value, htmlValue);
        }
    }

    _handleBlur() {
        const { onBlur, htmlValue } = this.props;

        if (onBlur) {
            onBlur(this.state.value, htmlValue);
        }
    }

    _handleFocus() {
        const { onFocus, htmlValue } = this.props;

        if (onFocus) {
            onFocus(this.state.value, htmlValue);
        }
    }

    render() {
        const {
            id,
            name,
            children,
            isDisabled,
            htmlValue,
            'data-spec': dataSpec,
            __containerClassName,
        } = this.props;
        const { value } = this.state;

        const wrapperClassName = classNames(
            'eds-checkbox',
            __containerClassName,
        );

        let checkboxLabel;

        if (children) {
            checkboxLabel = (
                <Label
                    className="eds-checkbox__label"
                    htmlFor={id}
                    data-spec="checkbox-display-label"
                    data-automation={`${id}-label`}
                >
                    {children}
                </Label>
            );
        }

        const dataAutomation = `checkbox-${id}`;

        return (
            <div className={wrapperClassName}>
                <input
                    id={id}
                    type="checkbox"
                    className="eds-checkbox__input"
                    name={name}
                    value={htmlValue}
                    disabled={isDisabled}
                    checked={value}
                    data-spec={dataSpec}
                    onChange={this._handleChange.bind(this)}
                    onBlur={this._handleBlur.bind(this)}
                    onFocus={this._handleFocus.bind(this)}
                    data-automation={dataAutomation}
                />
                <Label
                    className="eds-checkbox__label eds-checkbox__delegate eds-text--center"
                    htmlFor={id}
                    data-spec="checkbox-delegate"
                >
                    <div className="eds-checkbox__background" />
                    <div className="eds-checkbox__checkmark">
                        <Icon type={<CheckChunky />} strokeColor="white" />
                    </div>
                    <div className="eds-checkbox__foreground" />
                </Label>
                {checkboxLabel}
            </div>
        );
    }
}
