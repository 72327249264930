import * as React from 'react';

const ChequeSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 24 24" {...props}>
        <path
            d="M8 9H5v1h3V9zm0-1h1v3H4V8h4zm14-2v13H2V5h20v1zm-1 0H3v12h18V6zM4 15v-1h6v1H4zm0-2v-1h12v1H4zm15.996 2.237l-1.768 1.766-1.405-1.41-1.426 1.41-1.403-1.41-1.42 1.41-.71-.7 2.13-2.123 1.403 1.413 1.426-1.413 1.405 1.413 1.068-1.06.7.704z"
            fillRule="evenodd"
        />
    </svg>
);

ChequeSvg.displayName = 'ChequeSvg';
export default ChequeSvg;
