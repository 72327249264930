import { connect } from 'react-redux';
import some from 'lodash/some';
import { joinPath } from '@eb/path-utils';
import {
    abandonOrderCloseAttemptWithStatusPath as abandonOrderCloseAttemptWithStatusPathAction,
    abandonOrderOnClose as abandonOrderOnCloseAction,
} from '../actions/order/common';
import { notifyParentWidgetModalClose as notifyParentWidgetModalCloseAction } from '../actions/notifyParent';
import { queueRenew as queueRenewAction } from '../actions/waitingRoom';
import {
    BASE_URL,
    CHECKOUT_PATH,
    CHECKOUT_TITLE,
    DELIVERY_METHODS_PATH,
    EXTERNAL_PAYMENT_PATH,
    LOGIN_PATH,
    ORDER_CONFIRMATION_TITLE,
    PAYMENTS_PATH,
    PICK_A_SEAT_PATH,
    PURCHASE_CONFIRMATION_PATH,
    RESERVED_TICKETS_CONFIRMATION_PATH,
    STATUS_PATH,
    TICKETS_PATH,
} from '../constants';
import {
    ABANDON_ORDER_ATTEMPT_STATUSES,
    CONFIRM_CLOSE_TO_LOGIN_STATUS,
    CONFIRM_CLOSE_STATUS,
    CONFIRM_CLOSE_TO_DELIVERY_METHOD_STATUS,
    CONFIRM_CLOSE_TO_PAYMENTS_STATUS,
    CONFIRM_CLOSE_TO_PICK_A_SEAT_STATUS,
    CONFIRM_CLOSE_TO_RESERVED_TICKET_CONFIRMATION_STATUS,
} from './status/constants';
import { isNativeApp, shouldPerformTablessCheckout } from '../utils/browser';
import { sendNativeCheckoutPurchaseAction } from './checkout/utils';
import {
    CHECKOUT_NATIVE_ORDER_CANCELED,
    DefaultMobileCallBack,
} from './checkout/constants';
// The names of routes which should show modal to warn users when clicking on "close" on checkout widget
const ROUTES_TO_SHOW_ABANDON_ORDER_PAGE_ON_CLOSE = [
    CHECKOUT_PATH,
    DELIVERY_METHODS_PATH,
    EXTERNAL_PAYMENT_PATH,
    LOGIN_PATH,
    PAYMENTS_PATH,
    PICK_A_SEAT_PATH,
    RESERVED_TICKETS_CONFIRMATION_PATH,
];

const _shouldShowAbandonOrderPageOnClose = (router) =>
    some(ROUTES_TO_SHOW_ABANDON_ORDER_PAGE_ON_CLOSE, (routeName) =>
        router.isActive(joinPath(BASE_URL, routeName)),
    );

const _getModalAriaLabel = (state, ownProps) => {
    const { router } = ownProps;
    const currentLocation = router.getCurrentLocation().pathname;

    switch (currentLocation) {
        case joinPath(BASE_URL, CHECKOUT_PATH):
            return CHECKOUT_TITLE;
        case joinPath(BASE_URL, PURCHASE_CONFIRMATION_PATH):
            return ORDER_CONFIRMATION_TITLE;
        default:
            return `${CHECKOUT_TITLE} ${state.event.name.text}`;
    }
};

const _mapStateToProps = (state, ownProps) => ({
    isModal: state.app.isModal,
    isNativeApp: isNativeApp(state),
    performTabless: shouldPerformTablessCheckout() || isNativeApp(state),
    themeSettings: state.app.themeSettings,
    modalAriaLabel: _getModalAriaLabel(state, ownProps),
});

export const _mapDispatchToProps = (dispatch, ownProps) => {
    const { router } = ownProps;
    const { isActive: isActiveRoute } = router;
    const isAbandonOrderAttemptPage = some(
        ABANDON_ORDER_ATTEMPT_STATUSES,
        (status) => isActiveRoute(joinPath(BASE_URL, STATUS_PATH, status)),
    );
    const dispatchProps = {
        onQueueRenew: () => dispatch(queueRenewAction()),
        abandonOrderOnClose: () => {
            if (isActiveRoute(joinPath(BASE_URL, CHECKOUT_PATH))) {
                return dispatch(abandonOrderOnCloseAction());
            }
        },
    };

    // We want to hide the close button on abandon attempt pages so the user must choose
    // between the abandon checkout and return to checkout CTAs
    if (!isAbandonOrderAttemptPage) {
        const showAbandonOrderPage = _shouldShowAbandonOrderPageOnClose(router);

        if (showAbandonOrderPage) {
            // If the user clicks the modal close button on the checkout page, delivery method page, or
            // reserved confirmation screen, we want to confirm that they really want to abandon their order
            // before closing the modal
            let statusPath = CONFIRM_CLOSE_STATUS;
            let pathName = CHECKOUT_PATH;

            if (
                isActiveRoute(
                    joinPath(BASE_URL, RESERVED_TICKETS_CONFIRMATION_PATH),
                )
            ) {
                statusPath = CONFIRM_CLOSE_TO_RESERVED_TICKET_CONFIRMATION_STATUS;
                pathName = RESERVED_TICKETS_CONFIRMATION_PATH;
            } else if (isActiveRoute(joinPath(BASE_URL, PICK_A_SEAT_PATH))) {
                statusPath = CONFIRM_CLOSE_TO_PICK_A_SEAT_STATUS;
                pathName = PICK_A_SEAT_PATH;
            } else if (
                isActiveRoute(joinPath(BASE_URL, DELIVERY_METHODS_PATH))
            ) {
                statusPath = CONFIRM_CLOSE_TO_DELIVERY_METHOD_STATUS;
                pathName = DELIVERY_METHODS_PATH;
            } else if (isActiveRoute(joinPath(BASE_URL, PAYMENTS_PATH))) {
                statusPath = CONFIRM_CLOSE_TO_PAYMENTS_STATUS;
                pathName = PAYMENTS_PATH;
            } else if (isActiveRoute(joinPath(BASE_URL, LOGIN_PATH))) {
                statusPath = CONFIRM_CLOSE_TO_LOGIN_STATUS;
                pathName = LOGIN_PATH;
            }
            dispatchProps.onClose = () => {
                dispatch(
                    abandonOrderCloseAttemptWithStatusPathAction(
                        pathName,
                        statusPath,
                    ),
                );
            };
        } else {
            dispatchProps.onClose = (isNativeApp) => {
                if (isNativeApp) {
                    dispatch(
                        sendNativeCheckoutPurchaseAction(
                            CHECKOUT_NATIVE_ORDER_CANCELED,
                            DefaultMobileCallBack,
                        ),
                    );
                }
                dispatch(notifyParentWidgetModalCloseAction(TICKETS_PATH));
            };
        }
    }

    return dispatchProps;
};

export const connectPage = (ComponentClass) =>
    connect(_mapStateToProps, _mapDispatchToProps)(ComponentClass);
