import React from 'react';
import { ReadMore } from '@eb/eds-read-more';
import { ReadMoreConstants } from '@eb/eds-read-more';
import gettext from '@eb/gettext';
import './TicketDescription.scss';

const COLLAPSED_LINK_TEXT = gettext('See more');
const EXPANDED_LINK_TEXT = gettext('See less');

const TicketDescription = ({ description }) => (
    <div className="ticket-description__container eds-text-color--grey-600 eds-text-bm">
        <ReadMore
            toggleLocation={ReadMoreConstants.TOGGLE_LOCATION_BOTTOM}
            collapsedLinkText={COLLAPSED_LINK_TEXT}
            expandedLinkText={EXPANDED_LINK_TEXT}
            collapsedDisplayAmount={ReadMoreConstants.DISPLAY_AMOUNT_SMALL}
        >
            {description}
        </ReadMore>
    </div>
);

export default TicketDescription;
