import React from 'react';
import PropTypes from 'prop-types';
import gettext from '@eb/gettext';

import { PASSWORD_ICON_TYPES, PASSWORD_HIDDEN_ICON } from './constants';
import { IconButton } from '@eb/eds-icon-button';

export default class PasswordIconButton extends React.PureComponent {
    static propTypes = {
        iconType: PASSWORD_ICON_TYPES,
        togglePasswordVisibility: PropTypes.func,
        showIcon: PropTypes.bool,
    };

    static defaultProps = {
        iconType: PASSWORD_HIDDEN_ICON,
        showIcon: false,
    };

    render() {
        const { iconType, togglePasswordVisibility, showIcon } = this.props;

        if (!showIcon) {
            return null;
        }

        return (
            <IconButton
                onClick={togglePasswordVisibility}
                title={gettext('password visibility button')}
                iconType={iconType}
            />
        );
    }
}
