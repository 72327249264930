import gettext from '@eb/gettext';
import { isRegionRequired, isPostalRequired } from '@eb/intl';

import { isProvided, makeHasMaxLength, makeMatchesRegex } from './general';
import {
    joinValidators,
    makeValidator,
    SingleFieldValidatorConfig,
} from './validation';
import { MultiFieldValidator } from './types';

// Some validation specific configuration variables
const POSTAL_PATTERNS = {
    US: /^\d{5}(-\d{4})?/,
    DEFAULT: /^[0-9a-zA-Z\- ]*$/,
};

const POSTAL_MAXLENGTHS = {
    DEFAULT: 10,
};

const POSTAL_ERRORS = {
    required: {
        US: gettext('ZIP code is required'),
        DEFAULT: gettext('Postal code is required'),
    },

    maxLength: {
        DEFAULT: gettext('Postal code must be less than 10 characters long'),
    },

    invalid: {
        US: gettext('Invalid ZIP code'),
        DEFAULT: gettext('Invalid Postal code'),
    },
};

const REGION_ERRORS = {
    required: {
        US: gettext('Please enter a valid State'),
        CA: gettext('Please enter a valid Province'),
        IE: gettext('Please enter a valid County'),
        AU: gettext('Please enter a valid State/Territory'),
        DEFAULT: gettext('Please enter a valid State/Province'),
    },

    maxLength: {
        US: gettext('State must be 30 characters or less'),
        CA: gettext('Province must be 30 characters or less'),
        IE: gettext('County must be 30 characters or less'),
        AU: gettext('State/Territory must be 30 characters or less'),
        DEFAULT: gettext('State/Province must be 30 characters or less'),
    },
};

// Internal utility functions
const _getInWithDefault = <
    DefaultValue,
    PassedObject extends object & { DEFAULT: DefaultValue },
    Key extends keyof PassedObject
>(
    obj: PassedObject,
    key?: Key | string,
): PassedObject[Key] | DefaultValue =>
    obj.hasOwnProperty(key ?? '') ? obj[key as Key] : obj.DEFAULT;

// TODO: Type this using string template literal types when we upgrade to TypeScript 4.1 » https://devblogs.microsoft.com/typescript/announcing-typescript-4-1/
const _applyPrefixToConfig = <Config extends Record<string, unknown>>(
    prefix: string,
    config: Config,
): Record<string, Config[string]> =>
    Object.keys(config).reduce(
        (prefixedConfig, key) => ({
            ...prefixedConfig,
            [prefix + key]: config[key],
        }),
        {},
    );

// TODO: Type this using string template literal types when we upgrade to TypeScript 4.1 » https://devblogs.microsoft.com/typescript/announcing-typescript-4-1/
const _makePrefixedValidator = <
    Values extends Record<string, unknown>,
    Config extends Record<
        string,
        SingleFieldValidatorConfig<any> | Array<SingleFieldValidatorConfig<any>>
    >,
    Props = undefined
>(
    prefix: string | undefined,
    config: Config,
): MultiFieldValidator<Values, Props> =>
    makeValidator(prefix ? _applyPrefixToConfig(prefix, config) : config);

// Validation methods for requiring fields
export const addressFieldRequiredValidators = ({
    prefix,
}: { prefix?: string } = {}) =>
    _makePrefixedValidator(prefix, {
        country: {
            validator: isProvided,
            errorMessage: gettext('Country is required'),
        },

        address1: {
            validator: isProvided,
            errorMessage: gettext('Address is required'),
        },

        city: {
            validator: isProvided,
            errorMessage: gettext('City is required'),
        },
    });

// Region and postal field error messages depend on the current value of the country
export const regionRequiredValidator = ({
    prefix,
    country,
}: { prefix?: string; country?: string } = {}) =>
    _makePrefixedValidator(prefix, {
        region: {
            validator: isProvided,
            errorMessage: _getInWithDefault(REGION_ERRORS.required, country),
        },
    });

export const postalRequiredValidator = ({
    prefix,
    country,
}: { prefix?: string; country?: string } = {}) =>
    _makePrefixedValidator(prefix, {
        postal: {
            validator: isProvided,
            errorMessage: _getInWithDefault(POSTAL_ERRORS.required, country),
        },
    });

/**
 * Field validators that do not change depending on the value of the country/region.
 */
export const staticAddressFieldValidators = ({
    prefix,
}: { prefix?: string } = {}) =>
    _makePrefixedValidator(prefix, {
        address1: {
            validator: makeHasMaxLength(100),
            errorMessage: gettext(
                'Address must be less than 100 characters long',
            ),
        },

        city: {
            validator: makeHasMaxLength(50),
            errorMessage: gettext('City must be less than 50 characters long'),
        },
    });

/**
 * Field validators that depend on the current value of the country to do their
 * job.
 */
export const dynamicAddressFieldValidators = ({
    prefix,
    country,
}: { prefix?: string; country?: string } = {}) =>
    _makePrefixedValidator(prefix, {
        region: {
            validator: makeHasMaxLength(30),
            errorMessage: _getInWithDefault(REGION_ERRORS.maxLength, country),
        },

        postal: [
            {
                validator: makeHasMaxLength(
                    _getInWithDefault(POSTAL_MAXLENGTHS, country),
                ),
                errorMessage: _getInWithDefault(
                    POSTAL_ERRORS.maxLength,
                    country,
                ),
            },
            {
                validator: makeMatchesRegex(
                    _getInWithDefault(POSTAL_PATTERNS, country),
                ),
                errorMessage: _getInWithDefault(POSTAL_ERRORS.invalid, country),
            },
        ],
    });

/**
 * Address validator for a form. Everything you need to validate an address
 * component in one convenient function. Dynamically validates based on selected country.
 *
 * Important: Must be called with prefix to return the validation function.
 */
export const nonRequiredAddressValidator = ({
    prefix,
}: { prefix?: string } = {}): MultiFieldValidator<
    { country: string },
    undefined
> => (values) => {
    const validators = [
        staticAddressFieldValidators({ prefix }),
        dynamicAddressFieldValidators({ prefix, country: values.country }),
    ];

    return joinValidators(...validators)(values, undefined);
};

/**
 * Required address validator for a form. Does everything the nonRequiredAddressValidator
 * does, but adds the logic for required fields. This validator expects the form
 * to receive a prop `postalRequired`, which can be achieved by using the provided
 * `mapAddressStateToFormProps` function below if using the ConnectedAddress
 * component and/or associated actions + reducers.
 */
export const requiredAddressValidator = ({
    prefix,
}: { prefix?: string } = {}): MultiFieldValidator<
    { country: string },
    { countries: Array<string> }
> => (values, { countries } = { countries: [] }) => {
    const validators = [
        nonRequiredAddressValidator({ prefix }),
        addressFieldRequiredValidators({ prefix }),
    ];

    if (isRegionRequired(values.country)) {
        validators.push(
            regionRequiredValidator({ prefix, country: values.country }),
        );
    }
    if (isPostalRequired(countries, values.country)) {
        validators.push(
            postalRequiredValidator({ prefix, country: values.country }),
        );
    }

    return joinValidators(...validators)(values, undefined);
};
