import React from 'react';
import PropTypes from 'prop-types';
import { PaymentIcon } from '@eb/eds-payment-icon';

// TODO: EB-144585 Take constants from TypeScript file
import {
    PAYMENT_CONSTRAINTS_SHAPE,
    PAYMENT_CONSTRAINT_VARIANTS,
    PAYMENT_METHODS,
    PAYMENT_METHODS_INFO,
} from '../../constants';
import { formatConstraintText } from '../../utils/tickets';
import gettext from '@eb/gettext';

export default class TicketPaymentConstraints extends React.PureComponent {
    static propTyes = {
        paymentConstraints: PropTypes.arrayOf(PAYMENT_CONSTRAINTS_SHAPE)
            .isRequired,
    };

    render() {
        const { paymentConstraints = [] } = this.props;
        const paymentMethodIcons = [];
        const paymentMethodTexts = [];
        const defaultIcon = (
            <span className="eds-l-mar-right-2">
                <PaymentIcon
                    type={PAYMENT_METHODS_INFO[PAYMENT_METHODS.CREDIT].icon}
                    size="xsmall"
                />
            </span>
        );

        paymentConstraints.forEach(({ id, instrumentType, paymentMethod }) => {
            const constraintData = paymentMethod
                ? PAYMENT_CONSTRAINT_VARIANTS[paymentMethod] || {}
                : PAYMENT_CONSTRAINT_VARIANTS[instrumentType] || {};
            const { icon, userString } = constraintData;

            paymentMethodTexts.push(
                userString ||
                    formatConstraintText(paymentMethod || instrumentType),
            );

            if (icon) {
                paymentMethodIcons.push(
                    <span key={id} className="eds-l-mar-right-2">
                        <PaymentIcon type={icon} size="xsmall" />
                    </span>,
                );
            }
        });

        const iconDisplay = paymentMethodIcons.length
            ? paymentMethodIcons
            : defaultIcon;
        const appendage =
            paymentMethodTexts.length === 1 ? ' required' : ' accepted';
        const displayText = `${paymentMethodTexts.join(', ')}${appendage}`;

        return (
            <div className="eds-l-mar-vert-3">
                {iconDisplay}
                <span className="eds-text-bs eds-text-color--ui-600">
                    {gettext(displayText)}
                </span>
            </div>
        );
    }
}
