import every from 'lodash/every';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import { isSubmitting } from 'redux-form';

import {
    formatMajorMoney,
    formatNumber,
    DEFAULT_CURRENCY_FORMAT,
} from '@eb/intl';
import { PROMO_CODE_FORM_NAME, PROMO_CODE_TYPES } from '../constants';

export const isTicketWithPromoCode = (ticket) =>
    ticket.code && ticket.discountType !== PROMO_CODE_TYPES.PUBLIC;
/**
 * Returns the first variant with a code, or an empty object
 *
 * @param {array} variants
 * @returns {object}
 */
export const getFirstVariantTicketWithPromoCode = (variants) =>
    find(variants, isTicketWithPromoCode) || {};

/**
 * getVariantPromoCode
 *
 * NOTE: Currently only one promotional code can be applied per order.
 * We may have multiple promo codes per order in the future.
 *
 * @param tickets the flattened list of Tickets (Array) or an Object of tickets.
 * @returns {string} promotional code
 */
export const getVariantPromoCode = (tickets) => {
    const ticketsAsArray = isArray(tickets) ? tickets : Object.values(tickets);
    const promoCodes = ticketsAsArray.map(
        (ticket) => getFirstVariantTicketWithPromoCode(ticket.variants).code,
    );

    return find(promoCodes, (code) => !!code);
};

/**
 * getPromoCodeInformation
 *
 * For either an Array or an Object of tickets, find a ticket with promo code applied, and
 * return an Object of promo code-related information (i.e. discountType, code, percentOff,
 * amountOff, originalCost, etc.).
 *
 * @param tickets the flattened list of Tickets (Array) or an Object of tickets.
 * @returns {Object} promotional-code-related information
 */
export const getPromoCodeInformation = (tickets) => {
    const ticketWithPromo = find(
        tickets,
        (ticket) => !!getFirstVariantTicketWithPromoCode(ticket.variants).code,
    );
    let promoCodeInformation = {};

    if (ticketWithPromo) {
        promoCodeInformation = getFirstVariantTicketWithPromoCode(
            ticketWithPromo.variants,
        );
    }

    return promoCodeInformation;
};

const _areAllTicketsUnlockedByAccessCode = (tickets) =>
    every(tickets, (ticket) => {
        const variantWithCode = getFirstVariantTicketWithPromoCode(
            ticket.variants,
        );

        return (
            variantWithCode.code &&
            variantWithCode.discountType === PROMO_CODE_TYPES.ACCESS
        );
    });

const _isPromoCodeAvailableForDiscount = (promoCodeType) =>
    promoCodeType === PROMO_CODE_TYPES.DISCOUNT ||
    promoCodeType === PROMO_CODE_TYPES.HOLD;

export const getPromoCodeFormProps = (state) => {
    const { ticketsById } = state.tickets;
    const isLoading = isSubmitting(PROMO_CODE_FORM_NAME)(state);
    const promoInformation = getPromoCodeInformation(ticketsById);
    const discountDetails = {};
    const { currencyFormat = DEFAULT_CURRENCY_FORMAT } = state.app || {};
    let promoCodeType;
    let promoCode;

    if (!isEmpty(promoInformation)) {
        promoCodeType = promoInformation.discountType;
        promoCode = promoInformation.code;

        // Additional information related to promo codes that
        // apply discounts to ticket(s)
        if (_isPromoCodeAvailableForDiscount(promoCodeType)) {
            const { percentOff, amountOff } = promoInformation;

            if (percentOff) {
                discountDetails.percentOff = formatNumber(
                    parseFloat(percentOff),
                    currencyFormat,
                );
            }

            if (amountOff) {
                const { value, currency } = amountOff;

                discountDetails.amountOff = formatMajorMoney(value, currency);
            }
        }
    }

    const shouldShowPromoCodeForm =
        !promoCode && state.event.shouldShowPromoCodeLink;
    const shouldShowPromoCodeNotification =
        promoCode &&
        state.event.shouldShowPromoCodeLink &&
        !_areAllTicketsUnlockedByAccessCode(ticketsById);

    return {
        shouldShowPromoCodeForm,
        shouldShowPromoCodeNotification,
        promoCodeFormOptions: {
            promoCode,
            isLoading,
            promoCodeType,
            ...discountDetails,
        },
    };
};
