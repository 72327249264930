// External libraries
import every from 'lodash/every';
import filter from 'lodash/filter';
import fromPairs from 'lodash/fromPairs';
import groupBy from 'lodash/groupBy';
import includes from 'lodash/includes';
import head from 'lodash/head';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import negate from 'lodash/negate';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import size from 'lodash/size';
import some from 'lodash/some';
import toPairs from 'lodash/toPairs';
import values from 'lodash/values';
import pipe from 'lodash/fp/pipe';
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';

// Own libraries
import {
    formatMajorMoney,
    getCurrencySymbol,
    isCurrencySymbolSuffix,
} from '@eb/intl';
import { determineDateTimeAndStatus } from '@eb/event-tickets';

// Local imports
import ngettext from '@eb/ngettext';
import gettext from '@eb/gettext';
import {
    determinePriceInformation,
    getTotalCost,
    getVariantRangeCostForDisplay,
} from './ticketPriceUtils';
import {
    AVAILABLE_TICKET_STATUS,
    DONATION_AMOUNT_MAX,
    DONATION_AMOUNT_MIN,
    ON_SALE,
    SEAT_EDIT_CTA,
    SOLD_OUT,
    TICKET_EDIT_CTA,
    TIERED_VARIANT_TYPE,
    VARIANT_INPUT_TYPE,
} from '../constants';
import { DISABLE_STATUSES } from '../../../components/ticketsSelectionList/constants';
import {
    ORDER_ATTENDEE_WITH_ASSIGNED_UNIT_SHAPE_OBJECT,
    TICKET_CLASS_CATEGORIES,
} from '../../../constants';
import { getDeliveryMethodsForCountries } from '../../../utils/deliveryMethod';
import { preparePriceForApi } from '../../../utils/currency';
////////////////////////////////////////////////////////////////////////////////
// Refactor in-progress - temporarily removing dependency
// TODO: Mock this properly where consumers are tested.
// Example: in connectExternalPaymentPage.unit.spec.js it was causing:
// Error: Selector creators expect all input-selectors to be functions, instead received the following types: [undefined]
// Replaced with (state) => get(state, 'order.attendees', [])
// This replacement resolved the same error in 19 test suites
// import {getAttendees} from '../../../selectors/order';
////////////////////////////////////////////////////////////////////////////////
import { createSelector } from '../../../selectors/utils/selector';
import { isDonationTicket, getSelectedVariants } from '../../../utils/tickets';

const isSingleTicketEvent = (ticketsById) => size(ticketsById) === 1;

export const _hasOnlyOneTicketOption = (ticketsById) =>
    isSingleTicketEvent(ticketsById) &&
    size(values(ticketsById)[0].variants) <= 1;
const isTicketOnSale = (ticket) =>
    ticket.onSaleStatus === ON_SALE ||
    ticket.onSaleStatus === AVAILABLE_TICKET_STATUS;

/**
 * isTierVariantBestAvailable
 *
 * Introspects a tiered ticket's variant to find out if it is "Best Available," ie the parent or primary variant from
 * an array of ticket's variants, used in UI where reserved ticket tiers are listed. TODO(rosschapman):
 * figure out better way of identifying "Best Available" tickets.
 *
 * @param {Object} ticket
 * @returns {Boolean}
 */
export const isTierVariantBestAvailable = (variant) =>
    get(variant, 'primary', false);

/**
 * isTierVariantNotBestAvailable
 *
 * @param {Object} ticket
 * @returns {Boolean}
 */
export const isTierVariantNotBestAvailable = negate(isTierVariantBestAvailable);

/**
 * isTierVariantSoldOut
 *
 * `soldOutWithWaitlist` is the property transformed from `sold_out_with_waitlist` on variant object.
 * currently it means ticket sold out AND `waitlist` not enabled, per https://github.com/eventbrite/ticket_availability_service/blob/e2847e0b5e58ab537baf5599f463db82d2f07dc0/ticket_availability_service/containers/tickets.py#L189
 * for reserved event, waitlist is not enabled so the property is actually what we need to get the sold out status.
 *
 * @param {Object} variant
 * @return {Boolean}
 **/
export const isTierVariantSoldOut = (variant) => !!variant.soldOutWithWaitlist;

/**
 * isTierVariantNotSoldOut
 *
 * @param {Object} variant
 * @returns {Boolean}
 */
export const isTierVariantNotSoldOut = negate(isTierVariantSoldOut);

export const isSingleVariantType = ({ variantInputType }) =>
    variantInputType === VARIANT_INPUT_TYPE.SINGLE;

export const isMultipleVariantType = ({ variantInputType }) =>
    variantInputType === VARIANT_INPUT_TYPE.MULTIPLE;
/**
 * _determineInitialSelectedQuantity
 *
 * @param {Object} ticket
 **/
export const _determineInitialSelectedQuantity = (ticket) => {
    let amount = 0;

    // only set the initial quantity when the ticket is on sale and available
    // Note `ticket_options_range` includes the logics of setting minimum value of selection to 1
    // https://github.com/eventbrite/ticket_availability_service/blob/5d3bad388c84040d27eb93ccbfb532d4c0f6a8c7/ticket_availability_service/containers/tickets.py#L49-L53
    if (
        !isEmpty(ticket.ticketOptionsRange) &&
        isTicketOnSale(ticket) &&
        !ticket.donation
    ) {
        amount = ticket.ticketOptionsRange[0];
    }

    return amount;
};

/**
 * Get variants with `displayName` and `displayPrice` transformed
 *
 * @param {Object} ticket
 * @param {string} currencyFormat The currency format is used to determine thousands & decimal separators, and position the currency symbol
 * @param {string} locale
 * @param {Object} wasInWaitingRoom wasInWaitingRoom tell us if we were in that state
 *
 **/
export const determineDisplayVariants = (
    ticket,
    currencyFormat,
    locale,
    wasInWaitingRoom,
) => {
    const {
        variants,
        includeFee: primaryIncludeFee,
        eventTaxName: primaryEventTaxName,
    } = ticket;

    const displayVariants = variants.reduce((acc, variant) => {
        const { name, code, primary, currency, ...otherProps } = variant;
        const includeFee = get(variant, 'includeFee', primaryIncludeFee);
        const eventTaxName = get(variant, 'eventTaxName', primaryEventTaxName);
        let displayName;
        let salesEnd;
        let salesStart;
        let statusKey;

        if (variant.onSaleInfo && variant.onSaleInfo.endSalesWithTz) {
            ({ salesEnd, salesStart, statusKey } = determineDateTimeAndStatus(
                variant,
                locale,
            ));
        }

        if (name) {
            displayName = name;
        } else if (code) {
            //public discount use `code`
            displayName = code;
        } else if (primary) {
            displayName = gettext('Full Price');
        }
        const currencySymbol = getCurrencySymbol(currency);
        const isCurrencySuffix = isCurrencySymbolSuffix(currency, locale);

        const {
            displayPrice,
            feeAndTax,
            primaryPrice,
        } = determinePriceInformation(
            {
                includeFee,
                ...variant,
                eventTaxName,
            },
            currencyFormat,
            wasInWaitingRoom,
        );

        return [
            ...acc,
            {
                name: displayName,
                displayPrice,
                feeAndTax,
                primary,
                primaryPrice,
                isCurrencySuffix,
                currencySymbol,
                salesEnd,
                salesStart,
                statusKey,
                ...otherProps,
            },
        ];
    }, []);

    return displayVariants;
};

const _getDisabledState = ({ onSaleStatus } = {}) =>
    onSaleStatus !== AVAILABLE_TICKET_STATUS;

const isTieredTicket = (ticket) =>
    ticket.variantInputType === TIERED_VARIANT_TYPE;

/**
 * addMissingFieldsToState
 *
 * The state object we receive during the INITIALIZE_EVENT_AND_TICKETS action does not contain certain fields that we
 * need to continually keep track of, not just for display logic.
 *
 * This adds these fields to each ticketObject:
 *
 * maximumQuantityWithCapacity: sets the correct ticket max depending on event capacity and total selected tickets
 * isDisabled: whether the ticket select should be disabled
 * selectedQuantity: sets the initial value correctly based on number of tickets in the response.
 *
 * @param {Object} ticketsById
 **/
export const addMissingFieldsToState = (ticketsById, error) => {
    /* eslint-disable no-param-reassign */
    const formatVariants = (ticket) =>
        map(ticket.variants, (variant) => {
            let variantInitialSelectedQuantity = 0;

            if (isSingleVariantType(ticket)) {
                variantInitialSelectedQuantity = _determineInitialSelectedQuantity(
                    {
                        ...ticket,
                        ticketOptionsRange: variant.ticketOptionsRange,
                    },
                );
            } else if (isMultipleVariantType(ticket)) {
                variantInitialSelectedQuantity = _determineInitialSelectedQuantity(
                    variant,
                );
            }

            variant.deliveryMethods = getDeliveryMethodsForCountries(
                variant.deliveryMethods,
            );
            variant.selectedQuantity =
                variant.selectedQuantity || variantInitialSelectedQuantity;

            if (!variant.primary) {
                variant.free =
                    (variant.free || !variant.cost) &&
                    !isDonationTicket(variant);
            }

            return variant;
        });

    return fromPairs(
        toPairs(ticketsById).map(([key, ticket]) => {
            let { selectedQuantity = 0 } = ticket;

            if (!isTieredTicket(ticket)) {
                selectedQuantity =
                    selectedQuantity ||
                    _determineInitialSelectedQuantity(ticket);
                if (!isEmpty(error)) {
                    selectedQuantity = 0;
                }
            }

            return [
                key,
                {
                    ...ticket,

                    // We need this value saved in the state and not just calculated at display time
                    // because we'll need it for validation later
                    maximumQuantityWithCapacity:
                        ticket.maximumQuantityWithCapacity ||
                        ticket.maximumQuantityPerOrder,

                    isDisabled: _getDisabledState(ticket),
                    variants: formatVariants(ticket),
                    selectedQuantity,
                    deliveryMethods: getDeliveryMethodsForCountries(
                        ticket.deliveryMethods,
                    ),
                },
            ];
        }),
    );
    // eslint-enable-next-line no-param-reassign
};

export const isAdmissionTicket = (ticket) =>
    get(ticket, 'category') === TICKET_CLASS_CATEGORIES.ADMISSION;
export const isAddOnTicket = (ticket) =>
    get(ticket, 'category') === TICKET_CLASS_CATEGORIES.ADD_ON;
export const isAddOnTicketWithVariants = (ticket) =>
    isAddOnTicket(ticket) && size(ticket.variants) > 1;
export const isNotAddOnTicketWithVariants = negate(isAddOnTicketWithVariants);

// Note: The sort order of the normalized object of entity objects -- in this case tickets -- is
// not guaranteed so we can use the ordered array of ids to resort.
const sortTicketsByTicketIds = (state) =>
    get(state, 'tickets.ticketIds', []).map(
        (id) => state.tickets.ticketsById[id],
    );

// get variants `priceRange`, and partition primary variant and non primary variants if primary variant exist (e.g. Public discount)
const getVariantsOptions = (variants, currencyFormat) => ({
    priceRange: getVariantRangeCostForDisplay(variants, currencyFormat),
    hasPrimaryVariant: some(variants, ({ primary }) => primary),
});

/**
 * Determines whether a ticket dropdown list is expanded for:
 *  - GA tiers
 *  - Public Discounts
 *  - Single ticket events
 *
 * @param {Object} ticketsById Object of ticket objects
 * @param {Object} ticket Ticket data object
 */
export const getVariantsInitiallyExpanded = (ticketsById, ticket) =>
    isSingleTicketEvent(ticketsById) ||
    (isMultipleVariantType(ticket) && !isAddOnTicket(ticket));

/**
 * @typedef formattedTicketsForDisplay
 * @type {Object}
 *
 * @example
 * [
 *     {
 *         "id": String,
 *         "name": String,
 *         "selectedQuantity": Number,
 *         "isDisabled": Boolean,
 *         "minimumQuantity": Number,
 *         "description": String,
 *         "displayPrice": Object,
 *         "currency": String,
 *         "currencyFormat": String,
 *         "currencySymbol": String,
 *         "isCurrencySuffix": Boolean,
 *         "donation": Boolean,
 *         "donationAmount": Number,
 *         "quantityRemaining": Number,
 *         "feeAndTax": Array,
 *         "includeFee": Boolean,
 *         "includeTax": Boolean,
 *         "salesEnd": String,
 *         "salesStart": String,
 *         "statusKey": String,
 *         "onSaleInfo": Object,
 *         "publicDiscounts": Array,
 *         "priceRange": String,
 *         "initiallyExpanded": Boolean,
 *         "primaryPrice": String,
 *         "useAllInPrice": Boolean,
 *         "maximumQuantity": Number,
 *         "variantInputType": String,
 *         "variants": Array,
 *         "showJoinWaitlist": Boolean,
 *         "category": String,
 *         "isTicketTiered": Boolean,
 *     }
 * ]
 *
 * Utility function that on initial load of the transformed ticket response, parses the data to
 * retrieve the required individual ticket information as needed by the TicketSelectionPage.
 *
 * @param {Object} ticketsById -- Formatted ticket response as received from the transformUtil
 * @param {string} currencyFormat -- The currency format is used to determine thousands & decimal
 * separators, and position the currency symbol
 * @param {Object} wasInWaitingRoom -- Tell us if we were in that situation
 */
export const formatTicketsForDisplay = (state) => {
    const {
        tickets: { ticketsById },
        app: { currencyFormat, locale },
        waitingRoom: { wasInWaitingRoom },
    } = state;

    const sortedTickets = sortTicketsByTicketIds(state);

    return sortedTickets.map((ticket) => {
        let salesEnd;
        let salesStart;
        let statusKey;

        const { currency, displayFlags, onSaleInfo } = ticket;

        if (ticket.onSaleInfo.endSalesWithTz) {
            ({ salesEnd, salesStart, statusKey } = determineDateTimeAndStatus(
                ticket,
                locale,
            ));
        }

        const {
            displayPrice,
            quantityRemaining,
            feeAndTax,
            primaryPrice,
        } = determinePriceInformation(ticket, currencyFormat, wasInWaitingRoom);
        const currencySymbol = getCurrencySymbol(currency);
        const isCurrencySuffix = isCurrencySymbolSuffix(currency, locale);
        const showJoinWaitlist = displayFlags.showWaitlist;
        const isTicketTiered = onSaleInfo.isTicketTiered;
        const variants = determineDisplayVariants(
            ticket,
            currencyFormat,
            locale,
            wasInWaitingRoom,
        );
        const initiallyExpanded = getVariantsInitiallyExpanded(
            ticketsById,
            ticket,
        );
        const variantsOptions =
            variants.length > 1
                ? getVariantsOptions(variants, currencyFormat)
                : {};

        return {
            ...ticket,
            ...variantsOptions,
            currencySymbol,
            displayPrice,
            isCurrencySuffix,
            quantityRemaining,
            feeAndTax,
            salesEnd,
            salesStart,
            statusKey,
            variants,
            initiallyExpanded,
            primaryPrice,
            maximumQuantity: ticket.maximumQuantityWithCapacity,
            showJoinWaitlist,
            isTicketTiered,
        };
    });
};

/**
 * @returns {Function} -- Returns filter function with an inner predicate function that checks
 * category equality
 */
const filterTicketsByCategory = (category) => (tickets) =>
    tickets.filter((t) => t.category === category);

/**
 * Returns a selector that will return an array of formatted TicketClasses by category
 *
 * @param {String} category
 * @returns {Function} anonymous
 */
export const ticketClassesByCategory = (category) =>
    pipe(formatTicketsForDisplay, filterTicketsByCategory(category));

/**
 * Performs a check on the formatted tickets returned by the above function to determine if
 * various statuses/sales information should be displayed ticket by ticket (each ticket has a different
 * value) or as an event level (all tickets share values) notification.
 * @param {Object} ticketsById -- Formatted response from formatTickets
 * @param {String} key -- The key to pull from the object to check if all tickets share value
 */
export const getEventLevelValueForKey = (formattedTicketsById, key) => {
    let value;
    const allTicketsShareValueForKey = every(
        values(formattedTicketsById).map((ticket) => ticket[key]),
        (val, _, ticketValuesAtKey) => val && val === head(ticketValuesAtKey),
    );

    if (allTicketsShareValueForKey) {
        value = pick(head(values(formattedTicketsById)), key)[key];
    }

    return value;
};

/**
 * Get the sum of selected quantity.
 * @param {Object || Array} tickets `ticketsById` object or `variants`
 */
export const getTotalSelectedTickets = (tickets) => {
    const ticketValues = isArray(tickets) ? tickets : values(tickets);

    return ticketValues.reduce((memo, { selectedQuantity = 0, variants }) => {
        let totalSelectedQuantity = selectedQuantity;

        if (variants) {
            totalSelectedQuantity += getTotalSelectedTickets(variants);
        }

        return memo + totalSelectedQuantity;
    }, 0);
};

const getFlattenedSelectedTickets = (selectedTickets = []) =>
    selectedTickets.reduce((acc, ticket) => {
        const selectedVariants = getSelectedVariants(ticket.variants);

        if (isEmpty(selectedVariants)) {
            acc.push(ticket);
        } else {
            acc.push(...selectedVariants);
        }

        return acc;
    }, []);

/**
 * @param {Object} selectedTickets A filtered set of objects from ticketsById
 * @returns {Array}
 */
export const getTicketsAvailableOnSale = (selectedTickets) =>
    filter(selectedTickets, (ticket) => {
        const selectedVariants = getSelectedVariants(ticket.variants);

        if (!isEmpty(selectedVariants)) {
            return some(selectedVariants, isTicketOnSale);
        }

        return isTicketOnSale(ticket);
    });

export const allTicketsFree = (tickets) => getTotalCost(tickets) === 0;

/**
 * Return if the list of selected tickets includes donation ticket
 *
 * @param {Array} selectedTickets an array of selected tickets, derived from `ticketsById`
 */
export const hasDonationTickets = (selectedTickets) =>
    some(getFlattenedSelectedTickets(selectedTickets), isDonationTicket);

/**
 * _getUpdatedSelectedAndMaximumQuantity
 *
 * Given the currently selectedQuantity and the maximumQuantityPerOrder, return
 * an object that includes the selectedQuantity up to the maximumQuantityPerOrder
 * and the maximumQuantityWithCapacity equal to the maximumQuantityPerOrder.
 *
 * @param {Number} selectedQuantity
 * @param {Number} maximumQuantityPerOrder
 * @returns {Object}
 **/
const _getUpdatedSelectedAndMaximumQuantity = (
    selectedQuantity,
    maximumQuantityPerOrder,
) => ({
    maximumQuantityWithCapacity: maximumQuantityPerOrder,
    selectedQuantity: Math.min(selectedQuantity, maximumQuantityPerOrder),
});

/**
 * getUpdatedTicket
 *
 * Given a ticket object of the current state and a ticket object from the payload
 * (for nextState), return an updated ticket object
 *
 * @param {Object} ticketFromState
 * @param {Object} ticket
 * @returns {Object}
 **/
export const getUpdatedTicket = (ticketFromState = {}, ticket) => {
    const selectedQuantity = ticketFromState.selectedQuantity || 0;
    let updatedSelectedandMaximumQuantity;
    let variants;

    if (ticket) {
        updatedSelectedandMaximumQuantity = _getUpdatedSelectedAndMaximumQuantity(
            selectedQuantity,
            ticket.maximumQuantityPerOrder,
        );
        variants = ticket.variants.map((variant) => ({
            ...variant,
            selectedQuantity: 0,
        }));
    }

    return {
        ...ticketFromState,
        ...ticket,
        ...updatedSelectedandMaximumQuantity,
        variants,
    };
};

/**
 * getUpdatedTickets
 *
 * Given an object of ticket ids, ticket object of the current state and a ticket object from the payload
 * (for nextState), return an updated ticket object
 *
 * @param {Object} ids
 * @param {Object} tickets
 * @param {Object} ticketsFromState
 * @returns {Object}
 **/
export const getUpdatedTickets = (ids, tickets, ticketsFromState = {}) => {
    let updatedTickets = {};

    ids.forEach((id) => {
        const updatedTicket = getUpdatedTicket(
            ticketsFromState[id],
            tickets[id],
        );

        updatedTickets = {
            ...updatedTickets,
            [id]: updatedTicket,
        };
    });

    return updatedTickets;
};

/**
 * getUpdatedSelectedTickets
 *
 * Given the selectedTickets of the current state and the tickets from the payload
 * (for nextState), return an updated selectedTickets object that updates the
 * discountedTicket information (if a discountedTicket is selected).
 * @param {Array} selectedTickets
 * @param {Object} tickets
 * @returns {Object}
 **/
export const getUpdatedSelectedTickets = (
    selectedTickets = [],
    tickets = {},
) => {
    const updatedSelectedTickets = selectedTickets.map((ticket) =>
        getUpdatedTicket(ticket, tickets[ticket.id]),
    );
    const updatedSelectedTicketsWithMissingFields = addMissingFieldsToState(
        updatedSelectedTickets,
    );

    // addMissingFieldsToState returns an object of objects, but selectedTickets needs to be an array of objects
    const selectedTicketsArray = Object.keys(
        updatedSelectedTicketsWithMissingFields,
    ).map((ticket) => updatedSelectedTicketsWithMissingFields[ticket]);

    return {
        selectedTickets: selectedTicketsArray,
    };
};

/**
 * getTicketWithUpdatedSelectedQuantity
 *
 * Given a selected ticket, update the selectedQuantity
 *
 * @param {Object} ticket
 * @param {Number} selectedQuantity
 * @param {String} variantId -- if provided, update the selected quantity of the matching variant
 **/
export const getTicketWithUpdatedSelectedQuantity = (
    ticket,
    selectedQuantity,
    variantId,
) => {
    let updatedQuantityInfo = { selectedQuantity };

    if (variantId) {
        const discountIndex = findIndex(
            ticket.variants,
            ({ id }) => id === variantId,
        );

        if (discountIndex > -1) {
            const variants = [...ticket.variants];

            variants[discountIndex] = {
                ...variants[discountIndex],
                selectedQuantity,
            };

            updatedQuantityInfo = { variants };
        }
    }

    return {
        ...ticket,
        ...updatedQuantityInfo,
    };
};

/**
 * @param {Number} eventCapacity
 * @param {Object} tickets -- an object of ticket objects like ticketsById defined on the tickets reducer
 * @returns {Number}
 */
export const getTotalRemainingCapacity = (eventCapacity, tickets) =>
    eventCapacity - getTotalSelectedTickets(tickets);

/**
 * getTicketWithUpdatedDonationAmount
 *
 * Given a selected donation ticket, update the cost and selectedQuantity
 *
 * @param {Object} ticket
 * @param {Number} amount  --- donation amount
 * @param {String} currencyFormat
 **/
export const getTicketWithUpdatedDonationAmount = (
    ticket,
    amount,
    currencyFormat,
) => {
    let updatedDonationInfo = {
        cost: null,
        totalCost: null,
        fee: null,
        tax: null,
        selectedQuantity: 0,
        donationAmount: 0,
    };

    if (
        isNumber(amount) &&
        amount >= DONATION_AMOUNT_MIN &&
        amount <= DONATION_AMOUNT_MAX
    ) {
        const { currency } = ticket;
        let majorValue = preparePriceForApi(amount, currency, currencyFormat);

        majorValue = parseInt(majorValue, 10);

        // Fees and Taxs will be updated with the create order API response
        const defaultFeeTax = {
            currency,
            value: 0,
            display: formatMajorMoney(0, currency, currencyFormat),
        };
        const cost = {
            currency,
            value: majorValue,
            display: formatMajorMoney(majorValue, currency, currencyFormat),
        };

        updatedDonationInfo = {
            cost,
            totalCost: cost,
            fee: defaultFeeTax,
            tax: defaultFeeTax,
            selectedQuantity: 1,
            donationAmount: amount,
        };
    }

    return {
        ...ticket,
        ...updatedDonationInfo,
    };
};

/**
 * getDonationTicketFeeAndTax
 *
 * Given a create order response ticket (attendee) and return the ticket fee, tax and cost
 *
 * @param {Object} attendeeTicket
 * @param {String} currencyFormat
 * @param {Object} {includeFee}
 **/
export const getDonationTicketFeeAndTax = (
    attendeeTicket,
    currencyFormat,
    { includeFee },
) => {
    const {
        costs: {
            gross,
            tax,
            eventbrite_fee: eventbriteFee,
            payment_fee: paymentFee,
        },
    } = attendeeTicket;

    const feeAndTax = {
        tax,
        totalCost: gross,
    };

    // If fee is already included, do nothing.
    if (!includeFee) {
        const feeValue = eventbriteFee.value + paymentFee.value;

        feeAndTax.fee = {
            value: feeValue,
            currency: eventbriteFee.currency,
            display: formatMajorMoney(
                feeValue,
                eventbriteFee.currency,
                currencyFormat,
            ),
        };
    }

    return feeAndTax;
};

/**
 * getMaxQuantity
 *
 * Given updated maximum quantity and current ticket information (i.e. does it have selection?)
 * retrieve the new max quantity for ticket or variant
 *
 * @param {Integer} updatedMaximumQuantity -- maximumQuantityPerOrder determined by top level data (actual remaining tickets, total selected, and maximumQuantityPerOrder)
 * @param {Object} ticket -- information about existing selected quantity and max quantities
 **/
const _getMaxQuantity = (
    updatedMaximumQuantity,
    { selectedQuantity, maximumQuantity, maximumQuantityWithCapacity },
) =>
    selectedQuantity <= updatedMaximumQuantity
        ? updatedMaximumQuantity
        : maximumQuantity || maximumQuantityWithCapacity;

/**
 * getTicketsByIdWithUpdatedMaxQuantity
 *
 * Given the non-selected/updated tickets, update only the tickets' max quantities as a result of
 * current selection
 *
 * @param {Object} ticketsToUpdate
 * @param {Object} remainingCapacity -- Data passed in from original call site
 **/
export const getTicketsByIdWithUpdatedMaxQuantity = (
    ticketsToUpdate,
    remainingCapacity,
) =>
    mapValues(ticketsToUpdate, (ticket) => {
        const currentSelectedQuantity = getTotalSelectedTickets({ ticket });
        const maximumQuantityWithCapacity = Math.min(
            remainingCapacity + currentSelectedQuantity,
            ticket.maximumQuantityPerOrder,
        );
        const primaryMaxQuantity = _getMaxQuantity(
            maximumQuantityWithCapacity,
            ticket,
        );
        let variants = ticket.variants;

        if (size(variants)) {
            variants = variants.map((variant) => {
                const maximumQuantity = _getMaxQuantity(
                    maximumQuantityWithCapacity,
                    variant,
                );

                return {
                    ...variant,
                    maximumQuantity,
                };
            });
        }

        const isDisabled =
            (ticket.isDisabled && !isTicketOnSale(ticket)) ||
            (currentSelectedQuantity === 0 &&
                maximumQuantityWithCapacity === 0);

        return {
            ...ticket,
            variants,
            maximumQuantityWithCapacity: primaryMaxQuantity,
            isDisabled,
        };
    });

/**
 * getTicketsWithDiscount
 *
 * Given a tickets object, return all the tickets that have a discount.
 * Returns empty if none have a discount.
 *
 * @param {Object} tickets
 * @returns {Object}
 **/
export const getTicketsWithDiscount = (tickets) =>
    pickBy(
        tickets,
        (ticket) =>
            // Consider ticket has "discount" if ticket object has discountId or its any of its `variants` has specified `discountType`
            ticket.discountId ||
            some(ticket.variants || [], (variant) => !!variant.discountType),
    );

const attendeePriceTransformMap = {
    basePrice: 'cost',
    gross: 'totalCost',
    tax: 'tax',
};

/**
 * Because the frontend doesn't know the ticket/price before allocating best available tickets, we
 * have to update the state of selectedTickets with the allocated ticket price and fees.
 */
const transformAttendeeCosts = (attendee, currency, currencyFormat) => {
    const { costs } = attendee;
    const { paymentFee, eventbriteFee } = costs;
    const totalFees = paymentFee.value + eventbriteFee.value;
    const combinedFee = {
        currency,
        value: totalFees,
        display: formatMajorMoney(totalFees, currency, currencyFormat),
    };

    const transformedPrices = Object.keys(attendeePriceTransformMap).reduce(
        (obj, originalKey) => {
            const { value, display } = costs[originalKey];
            const targetKey = attendeePriceTransformMap[originalKey];

            return {
                ...obj,
                [targetKey]: {
                    currency,
                    value,
                    display,
                },
            };
        },
        {},
    );

    return {
        ...transformedPrices,
        fee: combinedFee,
    };
};

const transformAttendeeCombinedFeeCosts = (attendee, currency) => {
    const { costs } = attendee;

    return {
        currency,
        value: costs.basePrice.value,
        display: costs.basePrice.display,
    };
};

// for tiered ticket and public promo tickets, the `selectedQuantity` needs to be set on `variant` level
// this function reset the `selectedQuantity` on ticket level to 0, but set the correct `selectedQuantity` on variant.
const getSelectedTicketWithUpdatedVariants = (
    selectedTicket,
    variantPropsToUpdateMap,
) => {
    const variants = [];

    selectedTicket.variants.forEach((variant) => {
        const variantProps = variantPropsToUpdateMap[variant.id];

        const variantToUpdate = variantProps
            ? {
                  ...variant,
                  ...variantProps,
              }
            : {
                  ...variant,
                  selectedQuantity: 0,
              };

        variants.push(variantToUpdate);
    });

    return {
        ...selectedTicket,
        selectedQuantity: 0,
        variants,
    };
};

/**
 * getSelectedTicketsFromOrderAttendees
 *
 * For reserved seating events AND in the case of an existing order,
 * get selected tickets for state update from updated order `attendees` state
 *
 * @param {Array} attendees
 * @param {Object} ticketsById  current `ticketsById` from `tickets` state
 * @param {String} currency
 * @param {String} currencyFormat
 * @return {Object}
 */
// TODO: Move to `selectors/tickets.js`
export const getSelectedTicketsFromOrderAttendees = createSelector(
    (state) => get(state, 'app.currencyFormat', ''),
    (state) => get(state, 'event.currency', ''),
    (state) => get(state, 'tickets.ticketsById', {}),
    (state) => get(state, 'order.attendees', []),
    (currencyFormat, currency, ticketsById, attendees) => {
        const selectedTickets = [];
        const attendeesHaveVariantId = attendees.filter(
            (attendee) => !isEmpty(attendee.variantId),
        );
        const attendeesGroupByVariantId = groupBy(
            attendeesHaveVariantId,
            'variantId',
        );
        const ticketIds = Object.keys(ticketsById);
        const attendeesContainsTicketVariants = (ticket) =>
            ticket.variants.some(
                (variant) => attendeesGroupByVariantId[variant.id],
            );
        const attendeesContainsTicketId = (ticket) =>
            attendees.some((attendee) => attendee.ticketClassId === ticket.id);

        ticketIds.forEach((ticketId) => {
            const ticket = ticketsById[ticketId];

            /**
             * Since add-on tickets have a tier object at the root level, the variants actually
             * represent the selectable ticket classes. Therefore we push the variants onto the
             * result array.
             *
             * Also to note: unlike admission tickets that may be selected through the Buy on Map
             * flow, add-ons don't need additional prop updates for selected quantity or costs.
             *
             * TODO: this is another pain point of the current data model that requires extra work
             * around the different ticket types: eg, single ticket classes, ticket rules, or a tier
             * with nested classes (ie add-ons, like this case).
             */
            if (isAddOnTicket(ticket)) {
                ticket.variants.forEach((variant) => {
                    if (attendeesGroupByVariantId[variant.id]) {
                        // It can happen that you get a selectedQuantity = 0 for tickets when you retrieve them from order
                        // This typically happens for a manual order.
                        // When this happens, we need to correct this value to the one returned by the server.
                        const selectedVariant = {
                            ...variant,
                        };

                        if (selectedVariant.selectedQuantity === 0) {
                            selectedVariant.selectedQuantity =
                                attendeesGroupByVariantId[variant.id].length;
                        }

                        selectedVariant.cost = {
                            ...transformAttendeeCombinedFeeCosts(
                                attendeesGroupByVariantId[variant.id][0],
                                currency,
                            ),
                        };

                        selectedTickets.push(selectedVariant);
                    }
                });
            } else if (attendeesContainsTicketVariants(ticket)) {
                const variantPropsToUpdateMap = Object.keys(
                    attendeesGroupByVariantId,
                ).reduce((acc, variantId) => {
                    const attendeesOfVariant =
                        attendeesGroupByVariantId[variantId];

                    return {
                        ...acc,
                        [variantId]: {
                            selectedQuantity: attendeesOfVariant.length,
                            ...transformAttendeeCosts(
                                attendeesOfVariant[0],
                                currency,
                                currencyFormat,
                            ),
                        },
                    };
                }, {});
                const formattedTicket = getSelectedTicketWithUpdatedVariants(
                    ticket,
                    variantPropsToUpdateMap,
                );

                selectedTickets.push(formattedTicket);
            } else if (attendeesContainsTicketId(ticket)) {
                selectedTickets.push(ticket);
            }
        });

        return selectedTickets;
    },
);

export const getShouldShowTaxFeesMessage = (ticketsById) =>
    some(ticketsById, (ticket) =>
        some(ticket.variants, (variant) => variant.useAllInPrice === true),
    );

export const getReservedSeatingAttendees = ({ order: { attendees = [] } }) => ({
    reservedSeatingAttendees: attendees.map((attendee) =>
        pick(
            attendee,
            Object.keys(ORDER_ATTENDEE_WITH_ASSIGNED_UNIT_SHAPE_OBJECT),
        ),
    ),
});

/**
 * Returns the pluralized ticket or seat count CTA to show based on the number of tickets or seats selected
 *
 * @param {Number} quantity
 * @param {Boolean} isRegEvent
 * @returns {String} CTA string
 */
export const getCtaText = (quantity, isRegEvent) =>
    isRegEvent ? getSeatsCtaText(quantity) : getTicketsCtaText(quantity);

/**
 * Returns the ticket count CTA to show based on the number of tickets selected
 *
 * @param {Number} quantity
 * @returns {String} ticket count CTA string
 */
export const getTicketsCtaText = (quantity = 0) => {
    if (quantity > 0) {
        return ngettext(
            'Get %(quantity)s Ticket',
            'Get %(quantity)s Tickets',
            quantity,
            { quantity },
        );
    }

    return gettext('Get Tickets');
};

/**
 * Returns the seat count CTA to show based on the number of seats selected
 *
 * @param {Number} quantity
 * @returns {String} seat count CTA string
 */
export const getSeatsCtaText = (quantity = 0) => {
    if (quantity > 0) {
        return ngettext(
            'Get %(quantity)s Seat',
            'Get %(quantity)s Seats',
            quantity,
            { quantity },
        );
    }

    return gettext('Get Seats');
};

export const getEditCtaText = (isRegEvent) =>
    isRegEvent ? SEAT_EDIT_CTA : TICKET_EDIT_CTA;

const getDonationTickets = (ticketsById) =>
    values(ticketsById).reduce((acc, ticket) => {
        if (isDonationTicket(ticket)) {
            acc.push(ticket);
        } else if (!isEmpty(ticket.variants)) {
            acc.push(...ticket.variants.filter(isDonationTicket));
        }

        return acc;
    }, []);

const ticketHasDonationAmount = (ticket) =>
    isDonationTicket(ticket) && !isEmpty(ticket.donationAmount);

/**
 * Returns true when the user has entered a donation amount on any donation ticket
 * @param {object} ticketsById
 */
// TODO: Move to `selectors/tickets.js`, use getTicketsById
export const hasEnteredDonationAmount = (ticketsById) =>
    some(getDonationTickets(ticketsById), ticketHasDonationAmount);

/**
 * Returns true when the ticket has a status sold out and waitlist is disabled
 * @param {Object} ticket
 * @returns {Boolean}
 */
export const shouldShowSoldOut = ({ statusKey, showJoinWaitlist }) =>
    statusKey === SOLD_OUT && !showJoinWaitlist;

/**
 * Returns true when the ticket has a status sold out and ticket resellers are available
 * @param {String} statusKey, the status of the ticket
 * @param {Object} ticketReseller
 * @returns {Boolean}
 */
export const shouldShowTicketReseller = (
    statusKey,
    { url, text, isEventSoldOut } = {},
) => Boolean(statusKey === SOLD_OUT && !isEventSoldOut && url && text);

/**
 * Returns true when the entire event has a status sold out and ticket resellers are available
 * @param {Object} ticketReseller
 * @returns {Boolean}
 */
export const shouldShowEventReseller = ({ url, text, isEventSoldOut } = {}) =>
    Boolean(isEventSoldOut && url && text);

export const shouldDisableTicketContent = ({
    statusKey,
    showJoinWaitlist,
    ticketReseller,
}) =>
    includes(DISABLE_STATUSES, statusKey) &&
    !showJoinWaitlist &&
    !shouldShowTicketReseller(statusKey, ticketReseller);

/**
 * Returns true when the ticket should show the ticket price
 * @param {Object} ticket
 * @returns {Boolean}
 */
export const shouldShowPrice = (ticket) =>
    !isDonationTicket(ticket) || shouldShowSoldOut(ticket);

const getTicketWithResetVariantsSelectedQuantity = (ticket) => ({
    ...ticket,
    variants: ticket.variants.map((variant) => ({
        ...variant,
        selectedQuantity: 0,
    })),
});

/**
 * @param {Object} ticket
 * @param {Object} action
 * @returns {Object} -- Transformed ticket
 */
export const getTicketWithUpdatedVariants = (ticket, action) => {
    const { variantId, discountId, quantity } = action.payload;

    return getTicketWithUpdatedSelectedQuantity(
        getTicketWithResetVariantsSelectedQuantity(ticket),
        quantity,
        variantId || discountId,
    );
};
