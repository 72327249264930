// ValidationFormField's default shouldDisplayError triggers every time a field is dirtied,
// which means an error displays the moment a user starts typing. We only want errors
// to start/continue showing after the user has hit submit.
//
// To do this we override shouldDisplayError to check if the field is touched instead of dirtied,
// and set touchOnBlur on our form to false. When the form is submitted, redux-form marks
// all fields as touched, which triggers error display.
//
// See: https://github.com/erikras/redux-form/issues/733#issuecomment-195935723
export const shouldDisplayError = ({ error, submitFailed, touched }) =>
    !!error && (submitFailed || touched);
