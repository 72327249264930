import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { LOCALES_SHAPE } from '../../constants';
import { LoadingOverlay } from '@eb/eds-loading-overlay';
import PoweredByEventbriteTextContainer from '../../containers/PoweredByEventbriteTextContainer';
import { LanguageSelector } from '../languageSelector/LanguageSelector';

export default class LanguageAndBrand extends React.PureComponent {
    static propTypes = {
        locales: PropTypes.arrayOf(LOCALES_SHAPE).isRequired,
        defaultLocale: PropTypes.string.isRequired,
        onSelectLanguage: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
        };
    }

    _handleOnSelectLanguage(locale) {
        this.setState({ isLoading: true });
        this.props.onSelectLanguage(locale);
    }

    render() {
        const { locales, defaultLocale, isSeriesPage } = this.props;
        const { isLoading } = this.state;
        const isVertical = this.props.isVertical || false;
        const languageSelectorClasses = classNames(
            'eds-g-cell',
            'eds-text-color--grey-600',
            {
                'eds-g-cell-6-12 eds-g-cell-sm-4-12 eds-text--right': !isVertical,
                'eds-g-cell-12-12 g-vert-full-height__language-selector': isVertical,
            },
        );
        const brandClasses = classNames('eds-g-cell', {
            'eds-g-cell-6-12 eds-g-cell-sm-8-12 eds-l-pad-bot-2': !isVertical,
            'eds-g-cell-12-12 eds-align--center g-vert-full-height__powered-by-eventbrite': isVertical,
        });
        const containerClasses = classNames({
            'eds-l-mar-vert-6': !isVertical && !isSeriesPage,
            'eds-align--center-vertical eds-align--space-between': !isVertical,
            'g-vert-full-height eds-l-mar-top-6 eds-l-mar-bot-10': isVertical,
        });

        return (
            <div
                className={containerClasses}
                data-spec="language-brand-container"
            >
                <div className={brandClasses} data-spec="brand-text">
                    <PoweredByEventbriteTextContainer />
                </div>
                <div
                    className={languageSelectorClasses}
                    data-automation="language-selector"
                    data-spec="language-selector"
                >
                    <div className="u-inline-flex">
                        <LanguageSelector
                            locales={locales}
                            defaultLocale={defaultLocale}
                            onChange={this._handleOnSelectLanguage.bind(this)}
                        />
                    </div>
                </div>
                <LoadingOverlay size="large-chunky" isShown={isLoading} />
            </div>
        );
    }
}
