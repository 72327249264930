import React from 'react';
import gettext from '@eb/gettext';

import { Icon } from '@eb/eds-icon';
import { LogoWordmarkBrand } from '@eb/eds-iconography';
import { getEventbriteUrl } from '../utils';

import './poweredByEventbriteText.scss';

// For now, this will always appear in English. See discussion here: https://jira.evbhome.com/browse/EB-60442
// You can either pass in a link (e.g. 'https://eventbrite.com') or a locale. If a locale is provided, we
// generate the link for that tld
const PoweredByEventbriteText = ({ link, onClick, locale, disableLink }) => {
    const messagePoweredByEventbrite = gettext('Powered by');
    let iconLink;

    if (disableLink) {
        iconLink = undefined;
    } else if (link) {
        iconLink = link;
    } else if (locale) {
        iconLink = getEventbriteUrl(locale);
    }

    return (
        <a
            target="_blank"
            rel="noopener noreferrer"
            className="checkout-widget__powered-by-eventbrite eds-text-color--grey-600 eds-text-bs"
            title="Powered by Eventbrite"
            href={iconLink}
            onClick={onClick}
        >
            {messagePoweredByEventbrite}
            <Icon
                color="grey-600"
                type={<LogoWordmarkBrand />}
                size="medium"
                __containerClassName="checkout-widget__powered-by-eventbrite__icon"
            />
        </a>
    );
};

export default PoweredByEventbriteText;
