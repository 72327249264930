import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';

import { translationPropType } from '@eb/i18n';
import classNames from 'classnames';

import CharacterCounter from './CharacterCounter';

import { getAdditionalProps } from '@eb/eds-utils';

import './textArea.scss';

export default class TextArea extends PureComponent {
    static propTypes = {
        /**
         * Name of textarea
         */
        name: PropTypes.string,
        /**
         * Id of textarea
         */
        id: PropTypes.string,
        /**
         * Default value of textarea
         * This is the initial value
         * After the textarea has been rendered the first time,
         * if the default value changes, it will *not* update the textarea
         */
        defaultValue: PropTypes.string,
        /**
         * Value of textarea
         * The value can override the default value if specified
         * After the textarea has been rendered the first time,
         * if the value changes, it *will* update the textarea
         */
        value: PropTypes.string,
        /**
         * Is required
         */
        required: PropTypes.bool,
        /**
         * Set error state
         */
        hasError: PropTypes.bool,
        /**
         * Default placehold of textarea
         */
        placeholder: translationPropType,
        /**
         * Character limit
         */
        maxLength: PropTypes.number,
        /**
         * Show character limit counter
         */
        showCounter: PropTypes.bool,
        /**
         * Container class name
         */
        __containerClassName: PropTypes.string,

        /**
         * {function} onChange
         */
        onChange: PropTypes.func,
        /**
         * {function} onBlur
         */
        onBlur: PropTypes.func,
        /**
         * {function} onFocus
         */
        onFocus: PropTypes.func,
        /**
         * Identifier for tests
         */
        'data-spec': PropTypes.string,
    };

    static defaultProps = {
        defaultValue: '',
        'data-spec': 'textarea',
        showCounter: false,
    };

    constructor(props) {
        super(props);

        let { value } = props;
        const { defaultValue, maxLength } = props;

        value = value || defaultValue;

        if (maxLength !== undefined) {
            value = value.substr(0, maxLength);
        }

        this.state = { value };
    }

    componentWillReceiveProps({ value, maxLength }) {
        let trimedValue = value;

        if (trimedValue !== undefined) {
            if (maxLength !== undefined) {
                trimedValue = trimedValue.substr(0, maxLength);
            }

            this.setState({
                value: trimedValue,
            });
        }
    }

    _handleChange(e) {
        const value = e.target.value;

        this.setState({ value });

        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }

    _handleBlur() {
        this.setState({ active: false });

        if (this.props.onBlur) {
            this.props.onBlur(this.state.value);
        }
    }

    _handleFocus() {
        this.setState({ active: true });

        if (this.props.onFocus) {
            this.props.onFocus(this.state.value);
        }
    }

    render() {
        const {
            name,
            id,
            maxLength,
            showCounter,
            required,
            hasError,
            placeholder,
            'data-spec': dataSpec,
            __containerClassName,
        } = this.props;
        const { active, value } = this.state;
        const wrapperClassName = classNames(
            'eds-textarea',
            {
                'eds-textarea--focus': active,
                'eds-textarea--error': hasError,
            },
            __containerClassName,
        );
        const extraAttrs = getAdditionalProps(this);

        return (
            <div className={wrapperClassName} data-spec="textarea">
                <CharacterCounter
                    value={value}
                    maxLength={maxLength}
                    showCounter={showCounter && active}
                />
                <textarea
                    {...extraAttrs}
                    className="eds-textarea__input"
                    id={id}
                    name={name}
                    placeholder={placeholder}
                    maxLength={maxLength}
                    onChange={this._handleChange.bind(this)}
                    onBlur={this._handleBlur.bind(this)}
                    onFocus={this._handleFocus.bind(this)}
                    aria-required={required}
                    aria-invalid={hasError}
                    data-spec={dataSpec}
                    value={this.state.value}
                />
            </div>
        );
    }
}
