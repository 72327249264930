import React from 'react';

import { CARD_STYLE_SIMPLE } from '@eb/eds-card';
import {
    TicketEditCardContent,
    EXAMPLE_ACTION_ITEMS,
} from '@eb/eds-ticket-edit-card-content';
import {
    ON_SALE_STATUS,
    SOLD_OUT_STATUS,
} from '@eb/eds-ticket-edit-card-content';

export const EXAMPLE_CARD_ITEMS_NO_STYLE = [
    {
        id: '1',
        content: <div className="eds-l-pad-all-3">Card 1</div>,
    },
    {
        id: '2',
        content: <div className="eds-l-pad-all-3">Card 2</div>,
    },
    {
        id: '3',
        content: <div className="eds-l-pad-all-3">Card 3</div>,
    },
];

export const EXAMPLE_CARD_ITEMS_INDIVIDUAL_STYLE = [
    {
        id: '1',
        content: <div>Card with no style</div>,
    },
    {
        id: '2',
        content: <div>Card with no style</div>,
    },
    {
        id: '3',
        content: <div>Card with simple style</div>,
        style: CARD_STYLE_SIMPLE,
    },
];

export const EXAMPLE_CARD_ITEMS_LIST_STYLE = [
    {
        id: '1',
        content: (
            <TicketEditCardContent
                id="1"
                name="Ticket Status - On Sale"
                statusKey={ON_SALE_STATUS}
                salesStart="Sales start on May 03"
                salesEnd="Sales end on May 16"
                displayPrice="$9999.99"
                totalQuantity={500}
                soldQuantity={300}
                actionItems={EXAMPLE_ACTION_ITEMS}
            />
        ),
    },
    {
        id: '2',
        content: (
            <TicketEditCardContent
                id="2"
                name="Ticket Status - Sold Out"
                statusKey={SOLD_OUT_STATUS}
                salesStart="Sales start on May 03"
                salesEnd="Just now"
                displayPrice="$9999.99"
                totalQuantity={500}
                soldQuantity={300}
                actionItems={EXAMPLE_ACTION_ITEMS}
            />
        ),
    },
];

export const EXAMPLE_CARD_ITEMS_SORTABLE = [
    {
        id: '1',
        content: <div className="eds-l-pad-all-3">Sortable Card 1</div>,
    },
    {
        id: '2',
        content: <div className="eds-l-pad-all-3">Sortable Card 2</div>,
    },
    {
        id: '3',
        content: <div className="eds-l-pad-all-3">Sortable Card 3</div>,
    },
];

export const EXAMPLE_CARD_ITEMS_SOME_NOT_SORTABLE = [
    {
        id: '1',
        content: <div className="eds-l-pad-all-3">Sortable Card 1</div>,
    },
    {
        id: '2',
        content: <div className="eds-l-pad-all-3">Sortable Card 2</div>,
    },
    {
        id: '3',
        content: <div className="eds-l-pad-all-3">Card 3</div>,
        isSortable: false,
    },
];

export const CUSTOM_DRAG_HANDLE_ELEMENT = <div>[X]</div>;
