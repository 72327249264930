import React from 'react';

import { Button } from '@eb/eds-button';

import { APPLY_SELECTION_TEXT, CANCEL_SELECTION_TEXT } from '../constants';

/** Creates the buttons for the multi select list to apply or dismiss the
 * latests changes on the selection
 */
const MultiselectFooter = ({ onCancelClick, onApplyClick }) => (
    <div className="eds-field-multiselect__footer eds-l-pad-all-2">
        <div className="eds-align--center">
            <Button style="link" onClick={onCancelClick}>
                {CANCEL_SELECTION_TEXT}
            </Button>
        </div>
        <div className="eds-align--center">
            <Button style="link" onClick={onApplyClick}>
                {APPLY_SELECTION_TEXT}
            </Button>
        </div>
    </div>
);

export default MultiselectFooter;
