import { createSelector as reselectCreateSelector } from 'reselect';

let selectorDebug = false;
const monitoredSelectors = {};
const globalEnv = typeof window === 'object' ? window : process;

/**
 * enableSelectorDebugging
 *
 * Use this function to enable enhanced selector debugging in your code.
 */
export const enableSelectorDebugging = () => {
    selectorDebug = true;
};

/**
 * enhancedCreateSelector
 *
 * This function is a wrapper around reselect's `createSelector()`
 * that enables debug logging and monitoring of selectors.
 *
 * Usage:
 *
 * To enable debugging you need to perform two actions:
 * - Call enableSelectorDebugging() before any createSelector() call
 *   (could also be on your app's index.js file to ensure all selectors are covered.)
 * - For the selectors you want to debug add a new last paramenter with a debug name
 *
 * Example:
 * ```js
 * import createSelector, {enableSelectorDebugging} from './utils/selector';
 *
 * // Call this before any selector you want to debug
 * enableSelectorDebugging();
 *
 * const mySelectorIWantToDebug = createSelector(
 *   (state) => state.a,
 *   (state) => state.b,
 *   (a, b) => a + b,
 *   'aPlusBSelector'  //<-- this is the debugName that will identify this selector.
 * );
 * ```
 *
 * On your console you should be notified when any of your monitored selectors is recalculated.
 * You can also add watch expressions on your debugger to monitor the global var `__SELECTORS__[selectorDebugName]`.
 */
const enhancedCreateSelector = (...createArgs) => {
    let debugName;

    if (typeof createArgs[createArgs.length - 1] === 'string') {
        debugName = createArgs.pop();
    }

    let selector;

    if (selectorDebug && debugName) {
        monitoredSelectors[debugName] = monitoredSelectors[debugName] || {};

        if (!globalEnv.__SELECTORS__) {
            globalEnv.__SELECTORS__ = monitoredSelectors;
        }

        const dependencies = createArgs.slice(0, -1);
        const resolver = createArgs.slice(-1)[0];
        let lastResolverArgs;
        let wasRecomputed = false;
        const enhancedResolver = (...resolverArgs) => {
            lastResolverArgs = resolverArgs;
            wasRecomputed = true;
            return resolver(...resolverArgs);
        };

        const newArgs = [...dependencies, enhancedResolver];

        selector = reselectCreateSelector(...newArgs);

        return function enhancedSelector(...selectorArgs) {
            const lastResult = selector(...selectorArgs);

            if (wasRecomputed) {
                Object.assign(monitoredSelectors[debugName], {
                    recomputations: selector.recomputations(),
                    prevInputState:
                        monitoredSelectors[debugName].lastInputState,
                    prevResolverArgs:
                        monitoredSelectors[debugName].lastResolverArgs,
                    prevResult: monitoredSelectors[debugName].lastResult,
                    lastInputState: selectorArgs[0],
                    lastResolverArgs,
                    lastResult,
                });

                /* eslint-disable no-console */
                console.groupCollapsed(` selector change ${debugName}`);
                console.log('details ', monitoredSelectors[debugName]);
                console.groupEnd();
                /* eslint-enable no-console */

                wasRecomputed = false;
            }

            return lastResult;
        };
    }

    return reselectCreateSelector(...createArgs);
};

export const createSelector = enhancedCreateSelector;
