import React from 'react';
import PropTypes from 'prop-types';
import { formatNumber } from '@eb/intl';
import { InputField } from '@eb/eds-input-field';

export default class TicketDonationField extends React.PureComponent {
    static propTypes = {
        /**
         * How the currency is formatted
         */
        currencyFormat: PropTypes.string,
        /**
         * Symbol for the currency prefix or suffix
         */
        currencySymbol: PropTypes.string,
        /**
         * ID of the ticket to be passed up along with the selected value in the callback
         */
        id: PropTypes.string.isRequired,
        /**
         * Whether the currency symbol is prefix or suffix
         */
        isCurrencySuffix: PropTypes.bool,
        /**
         * Whether the input is disabled
         */
        isDisabled: PropTypes.bool,
        /**
         * Callback to be executed on every input change
         */
        onChange: PropTypes.func.isRequired,
        /**
        Amount on the donation ticket
        */
        amount: PropTypes.number,
    };

    state = {
        value: null,
        shouldFormatInputNumber: true,
        formattedPlaceholder: null,
    };

    componentDidMount() {
        this.updatePlaceholder();
        this.updateDisplayValue();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currencyFormat !== this.props.currencyFormat) {
            this.updatePlaceholder();
            this.updateDisplayValue();
        } else if (prevProps.amount !== this.props.amount) {
            this.updateDisplayValue();
        }
    }

    updatePlaceholder = (currencyFormat = this.props.currencyFormat) => {
        this.setState({
            formattedPlaceholder: formatNumber(0, currencyFormat),
        });
    };

    updateDisplayValue = (
        currencyFormat = this.props.currencyFormat,
        amount = this.props.amount,
    ) => {
        // If amount is undefined so will displayValue, which will make the InputField display the placeholder
        let displayValue;

        if (amount >= 0) {
            displayValue = this.state.shouldFormatInputNumber
                ? this.getFormattedValue(currencyFormat, amount)
                : this.state.value;
        }

        this.setState({ displayValue });
    };

    getFormattedValue(currencyFormat, amount) {
        return amount !== undefined && amount > 0
            ? formatNumber(amount, currencyFormat)
            : undefined;
    }

    _handleOnFocus = () => {
        this.setState({ shouldFormatInputNumber: false }, () =>
            this.updateDisplayValue(),
        );
    };

    _handleOnBlur = () => {
        this.setState({ shouldFormatInputNumber: true }, () =>
            this.updateDisplayValue(),
        );
    };

    _handleOnChange = (e) => {
        this.setState({ value: e }, () => {
            this.props.onChange(e);
            this.updateDisplayValue();
        });
    };

    // EB-91524: Block submit action when user clicks enter
    // User should only be able to submit form when they click "Checkout"
    _handleOnKeyPress(e) {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    }

    render() {
        const { currencySymbol, id, isCurrencySuffix, isDisabled } = this.props;

        const dataAutomation = `ticket-input-field-${id}`;
        const suffixProp = isCurrencySuffix
            ? { suffix: currencySymbol }
            : { prefix: currencySymbol };

        return (
            <InputField
                id={id}
                label="donation-input-field"
                style="basic"
                onChange={this._handleOnChange}
                onFocus={this._handleOnFocus}
                onBlur={this._handleOnBlur}
                onKeyPress={this._handleOnKeyPress}
                placeholder={this.state.formattedPlaceholder}
                value={this.state.displayValue}
                disabled={isDisabled}
                data-automation={dataAutomation}
                {...suffixProp}
            />
        );
    }
}
