import {
    getSeriesEvents as getSeriesEventsApi,
    getSeriesEventsByDate as getSeriesEventsByDateApi,
} from '../api/series';
import {
    transformSeriesEventsData,
    transformSeriesPaginationData,
} from '../api/transformations/series';
import { navigateToTicketSelection as navigateToTicketSelectionAction } from './initialize';
import {
    getIsEventDateAvailabilityLoaded,
    getIsAnyAvailabilityLoaded,
} from '../selectors/series';

export const UPDATE_SERIES_EVENTS = 'updateSeriesEvents';
export const UPDATE_SERIES_ID = 'updateSeriesId';
export const UPDATE_SERIES_IS_LOADING = 'updateSeriesIsLoading';
export const UPDATE_SERIES_PAGINATION = 'updateSeriesPagination';

export const updateSeriesEvents = (data) => ({
    type: UPDATE_SERIES_EVENTS,
    payload: transformSeriesEventsData(data),
});

export const updateSeriesPagination = (data) => ({
    type: UPDATE_SERIES_PAGINATION,
    payload: transformSeriesPaginationData(data),
});

export const updateSeriesId = (seriesId) => ({
    type: UPDATE_SERIES_ID,
    payload: seriesId,
});

export const updateSeriesIsLoading = (isLoading) => ({
    type: UPDATE_SERIES_IS_LOADING,
    payload: isLoading,
});

export const getSeriesEvents = ({
    startDate,
    endDate,
    showLoader = true,
} = {}) => async (dispatch, getState) => {
    const {
        event: { id: eventId, isSeriesParent },
        series: { pagination },
    } = getState();

    if (isSeriesParent) {
        let effectiveDates = {};
        let continuationToken = '';

        if (startDate) {
            effectiveDates = {
                startDateFrom: startDate,
                startDateTo: endDate || startDate,
            };

            if (getIsEventDateAvailabilityLoaded(getState(), effectiveDates)) {
                return;
            }
        }

        dispatch(updateSeriesId(eventId));
        if (showLoader) {
            dispatch(updateSeriesIsLoading(true));
        }

        if (pagination) {
            continuationToken = pagination.continuationToken;
        }

        const data = await getSeriesEventsApi(eventId, {
            continuationToken,
            ...effectiveDates,
        });

        dispatch(updateSeriesEvents(data.response));
        dispatch(updateSeriesPagination(data.response));
        dispatch(updateSeriesIsLoading(false));
    }
};

export const getSeriesEventsByDate = ({ startDate }) => async (
    dispatch,
    getState,
) => {
    const effectiveDates = {
        startDateFrom: startDate,
        startDateTo: startDate,
    };

    if (getIsEventDateAvailabilityLoaded(getState(), effectiveDates)) {
        return;
    }

    const {
        event: { id: eventId },
    } = getState();

    dispatch(updateSeriesId(eventId));
    dispatch(updateSeriesIsLoading(true));

    const data = await getSeriesEventsByDateApi(eventId, startDate);

    dispatch(updateSeriesEvents(data.response));
    dispatch(updateSeriesIsLoading(false));
};

export const initializeSeriesEvents = () => (dispatch, getState) => {
    // Ensure if we already have availabiltity loaded, whenever we
    // come back to the page we don't re-request it.
    // In part becuase is not necessary, but also to not reset the
    // infinite scrolling pagination state cause that breaks the logic
    // and ends up showing unexpected spinners.
    if (!getIsAnyAvailabilityLoaded(getState())) {
        dispatch(getSeriesEvents());
    }
};

export const navigateToSeriesPage = () => (dispatch, getState) => {
    const {
        series: { seriesId },
    } = getState();

    dispatch(navigateToTicketSelectionAction(seriesId));
};
