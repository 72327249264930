import { every, flattenDeep } from 'lodash';

import { GenericLazyString } from '@eb/i18n';

import {
    SingleValueValidator,
    SingleFieldValidator,
    MultiFieldValidator,
    MultiFieldValidationError,
} from './types';

// # Internals

/**
 * take a thing:
 * if it is an array, return that array.
 * if it is not an array, put it in an array
 */
const ensureIsArray = <ItemType>(
    someThing: ItemType | Array<ItemType>,
): Array<ItemType> => (Array.isArray(someThing) ? someThing : [someThing]);

/**
 * create one function that returns true if all of the provided functions return true
 */
const combineSingleValueValidators = <Value>(
    singleValueValidators: Array<SingleValueValidator<Value>>,
): SingleValueValidator<Value> => (value) =>
    every(singleValueValidators, (func) => func(value));

/**
 * combine string values from multiple objects into new object
 */
// TODO: Refactor copy in @eb/http
export const reduceErrorObjects = (
    previous: MultiFieldValidationError | null = {},
    next: MultiFieldValidationError | null = {},
): MultiFieldValidationError =>
    Object.entries(next ?? {})
        .filter(([key, value]) => !!value)
        .reduce(
            (prevErrors, [key, value]) => ({
                ...prevErrors,
                [key]: prevErrors[key] ? `${prevErrors[key]}, ${value}` : value,
            }),
            previous ?? {},
        );

/*
# Value Validators

Value validators will take a value and return errorMessage or undefined
 */

/**
 * create a single value validator function
 */
export const makeValueValidator = <Value>(
    message: string | GenericLazyString,
    ...truthyFunctions: Array<SingleValueValidator<Value>>
): SingleFieldValidator<Value> => (value) =>
    combineSingleValueValidators(truthyFunctions)(value) ? undefined : message;

export interface SingleFieldValidatorConfig<Value> {
    errorMessage: string | GenericLazyString;
    validator: SingleValueValidator<Value> | Array<SingleValueValidator<Value>>;
}

/**
 * create a single value validator function from a configuration object
 *
 * EXAMPLE CONFIGS:
 *
 *    Single validator:
 *      {
 *          errorMessage: "Must be the word FOO",
 *          validator: (valueUnderTest) => ( valueUnderTest === 'FOO' )
 *      }
 *
 *    Multiple Validators:
 *      {
 *          errorMessage: "Must not be FOO or BAR",
 *          validator: [
 *              (valueUnderTest) => ( valueUnderTest !== 'FOO' ),
 *              (valueUnderTest) => ( valueUnderTest !== 'BAR' )
 *          ]
 *      }
 *
 */
export const makeValueValidatorFromConfig = <Value>(
    config: SingleFieldValidatorConfig<Value>,
): SingleFieldValidator<Value> =>
    makeValueValidator(config.errorMessage, ...ensureIsArray(config.validator));

/*
# Object Validators

Object validators will take an object ({key: value})
and return and object ({key: errorMessage or undefined})
 */

/**
 * creates an object validator function from multiple object validator functions
 */
export const joinValidators = <Values extends Record<string, unknown>, Props>(
    ...validators: Array<MultiFieldValidator<Values, Props>>
): MultiFieldValidator<Values, Props> => (values, props) =>
    validators
        .map((validate) => validate(values, props))
        .reduce(reduceErrorObjects);

/**
 * create an object validator function from a configuration object
 *
 * @param {object} config - configuration object (described below)
 * @param {object|array} config.X - single value validator config object or objects
 * @returns {function}
 *
 * EXAMPLE CONFIGS:
 *
 *    single validator for a property:
 *
 *      {
 *          myFavoriteVariableName: {
 *              validator: (valueUnderTest) => ( valueUnderTest !== 'Foo' ),
 *              errorMessage: 'Foo is better'
 *          }
 *      }
 *
 *    multiple validators for a property:
 *
 *      {
 *          theWorstVariableName: [
 *              {
 *                  validator: (valueUnderTest) => ( valueUnderTest === 'Foo' ),
 *                  errorMessage: 'Foo cannot be the worst variable name.'
 *              },
 *              {
 *                  validator: (valueUnderTest) => ( valueUnderTest === 'Bar' ),
 *                  errorMessage: 'What kind of a monster are you?'
 *              }
 *          ]
 *      }
 */
export const makeValidator = <
    Config extends Record<
        string,
        | SingleFieldValidatorConfig<Values[keyof Config]>
        | Array<SingleFieldValidatorConfig<Values[keyof Config]>>
    >,
    Values extends Partial<Record<keyof Config, any>>,
    Props
>(
    config: Config,
): MultiFieldValidator<Values, Props> => {
    const validators = Object.entries(config).map(([fieldName, fieldConfig]) =>
        ensureIsArray(fieldConfig).map((SingleFieldValidatorConfig) => {
            const validator = makeValueValidatorFromConfig(
                SingleFieldValidatorConfig,
            );

            return (values: Values) => ({
                [fieldName]: validator(values[fieldName]),
            });
        }),
    );

    const flattenedValidators = flattenDeep(validators);

    return joinValidators(...flattenedValidators);
};
