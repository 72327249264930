import every from 'lodash/every';
import filter from 'lodash/filter';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import remove from 'lodash/remove';
import some from 'lodash/some';
import sortBy from 'lodash/sortBy';
import union from 'lodash/union';
import values from 'lodash/values';
import { moment } from '@eb/date';
import { DEFAULT_COUNTRY } from '../constants';
import { DELIVERY_METHOD_DISPLAY_ORDER } from '../models/event';
import { DeliveryMethod } from '../api/models/deliveryMethod';
import getTerminology from './terminology';
import {
    getVariantsHaveSelectedDeliveryMethod,
    getSelectedVariants,
} from './tickets';

// * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
//
// Functions relating to available delivery methods on an array of ticket classes
//
// * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

/**
 * Return boolean indicate delivery method for country is empty or not.
 * E.g:
 * {US: [], Other: []} return true
 * {US: ['electronic'], Other: []} return false
 * @param {Object} deliveryMethods delivery method for country object
 */
const isDeliveryMethodsEmpty = (deliveryMethods) =>
    every(values(deliveryMethods), (value) => isEmpty(value));

/**
 * Return a list of tickets and variants have non-empty `deliveryMethods`
 * @param {*} tickets a list of selected tickets
 */
export const getSelectedTicketsAndVariantsWithDeliveryMethods = (tickets) =>
    tickets.reduce((acc, ticket) => {
        const { deliveryMethods = {}, variants = [] } = ticket;
        const selectedVariants =
            variants.length > 0 ? getSelectedVariants(variants) : [];
        const selectedVariantsHaveDeliveryMethods = selectedVariants.filter(
            ({ deliveryMethods = {} }) =>
                !isDeliveryMethodsEmpty(deliveryMethods),
        );
        const isDeliveryMethodsOnVariantLevel =
            isDeliveryMethodsEmpty(deliveryMethods) &&
            !isEmpty(selectedVariantsHaveDeliveryMethods);

        if (isDeliveryMethodsOnVariantLevel) {
            acc.push(...selectedVariantsHaveDeliveryMethods);
        } else {
            acc.push(ticket);
        }

        return acc;
    }, []);

/**
 * Return a list of tickets and variants have `selectedDeliveryMethod` set
 * @param {*} tickets a list of selected tickets
 */
export const getSelectedTicketsAndVariantsWithSelectedDeliveryMethod = (
    tickets,
) =>
    tickets.reduce((acc, ticket) => {
        const {
            selectedDeliveryMethod: ticketLevelSelectedDeliveryMethod,
            variants = [],
        } = ticket;
        const selectedVariants =
            variants.length > 0 ? getSelectedVariants(variants) : [];
        const selectedVariantsWithSelectedDeliveryMethod = getVariantsHaveSelectedDeliveryMethod(
            selectedVariants,
        );
        const isSelectedDeliveryMethodOnVariantLevel =
            !ticketLevelSelectedDeliveryMethod &&
            !isEmpty(selectedVariantsWithSelectedDeliveryMethod);

        if (isSelectedDeliveryMethodOnVariantLevel) {
            acc.push(...selectedVariantsWithSelectedDeliveryMethod);
        } else {
            acc.push(ticket);
        }

        return acc;
    }, []);
/**
 * Returns an unique union of delivery methods for the tickets
 * When ticket 1 has ['electronic', 'will_call'] as delivery methods and
 * ticket 2 has ['electronic'], getAllAvailableDeliveryMethods will return
 * ['electronic', 'will_call']
 *
 * @param {Array} tickets the array of selected tickets.
 * @returns {Array}
 */
export const getAllAvailableDeliveryMethods = (
    tickets,
    country = DEFAULT_COUNTRY,
) => {
    const allDeliveryMethods = getSelectedTicketsAndVariantsWithDeliveryMethods(
        tickets,
    ).reduce((result, ticket) => {
        result.push(...ticket.deliveryMethods[country]);

        return result;
    }, []);

    return union(allDeliveryMethods);
};

export const anyOrderAttendeesTicketClassHasMultipleDeliveryMethods = (
    attendees,
) =>
    some(attendees, ({ ticketClass = {} }) => {
        const { deliveryMethods } = ticketClass;

        return some(deliveryMethods, (values) => values.length > 1);
    });

export const anyOrderAttendeesTicketClassHasShipping = (
    attendees,
    country = DEFAULT_COUNTRY,
) =>
    some(attendees, ({ ticketClass = {} }) =>
        includes(
            get(ticketClass, `deliveryMethods[${country}]`, []),
            DeliveryMethod.standardShipping,
        ),
    );

// * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
//
// Functions relating to available delivery methods on a single ticket class
//
// * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

/**
 * return an array of the given delivery methods in the sorted order
 * @param {Array} deliveryMethods
 * @returns {Array}
 */
export const sortDeliveryMethods = (deliveryMethods) =>
    sortBy(deliveryMethods, [
        (method) => DELIVERY_METHOD_DISPLAY_ORDER[method],
    ]);

/**
 * return an array of the given shipping rates in the sorted order
 * @param {Array} DeliveryMethods
 * @returns {Array}
 */
const _sortQuantifiedDeliveryMethods = (quantifiedDeliveryMethods) =>
    sortBy(quantifiedDeliveryMethods, [
        ({ deliveryMethod }) => DELIVERY_METHOD_DISPLAY_ORDER[deliveryMethod],
    ]);

/**
 * @param {Array} selectedDeliveryMethods
 * @param {Array} selectedTickets
 * @returns {Array} -- objects with form (deliveryMethod, quantity)
 */
export const getQuantityBySelectedDeliveryMethod = (
    ticketGroups,
    selectedDeliverys,
) => {
    const quantityByDeliveryMethod = selectedDeliverys.map(
        (selectedDelivery) => {
            let quantityDelivery = 0;

            ticketGroups.forEach((ticketGroup) => {
                // All tickets have the same Method of Delivery
                if (
                    ticketGroup[0].attendeeDeliveryMethod === selectedDelivery
                ) {
                    quantityDelivery += ticketGroup.length;
                }
            });
            return { selectedDelivery, quantityDelivery };
        },
    );

    return _sortQuantifiedDeliveryMethods(quantityByDeliveryMethod);
};

// * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
//
// Functions relating to selected delivery methods on an array of ticket classes
//
// * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

/**
 * @param tickets the array of selected tickets.
 * @returns {Array} a unique, no undefined array of delivery method strings
 */
export const getSelectedDeliveryMethods = (tickets) =>
    union(
        filter(
            getSelectedTicketsAndVariantsWithSelectedDeliveryMethod(
                tickets,
            ).map((ticket) => ticket.selectedDeliveryMethod),
        ),
    );

/**
 * Returns a formatted string of all selected delivery methods.
 * Example: 'eTicket, Will Call'
 * @param  {array} selectedDeliveryMethods
 * @param  {object} getWithTerminology
 * @return {string}
 */
export const formatSelectedQuantityByDeliveryMethodsForDisplay = (
    { selectedDelivery, quantityDelivery },
    isRegEvent,
) => {
    const deliveryMethodDisplays = getTerminology(isRegEvent)
        .deliveryMethodDisplays;

    return `${quantityDelivery} x ${deliveryMethodDisplays[selectedDelivery]}`;
};

/**
 *
 * Return whether or not standard shipping is part of the deliveryMethods payload
 *
 * The deliveryMethods param is a list of dictionaries similar to:
 * [
 *  {'attendee_id': '1', delivery_method: 'electronic'},
 *  {'attendee_id': '2', delivery_method: 'will_call'},
 * ]
 *
 * @param deliveryMethods a list of of dictionaries as specified above
 * @returns true of false
 */
export const isShippingInDeliveryMethodsPayload = (deliveryMethods) =>
    some(deliveryMethods, {
        delivery_method: DeliveryMethod.standardShipping,
    });

/**
 * Returns true if the current UTC time is past the cutoff date
 * @param  {string} cutoffDate      UTC datetime string
 * @return {boolean}
 */
export const isPastCutoffDate = (cutoffDate) =>
    moment().isAfter(moment(cutoffDate));

/**
 * Returns deliveryMethods with backup delivery method when a given country doesn't support shipping
 * or if the cutoff date has passed for the shipping method.
 * @param {Object} deliveryMethods the formated deliveryMethods by countries, e.g. {US: ['electronic'], Other: ['electronic']}
 * @param  {Object} shippingSettings event level shipping settings, derived from `state.event.shippingSettings`
 */
export const getDeliveryMethodsAfterCheckingShippingBackup = (
    deliveryMethods,
    shippingSettings,
) => {
    const { backupDeliveryMethod, cutoffDate } = shippingSettings;

    return Object.keys(deliveryMethods).reduce((acc, country) => {
        if (isPastCutoffDate(cutoffDate)) {
            remove(
                acc[country],
                (method) => method === DeliveryMethod.standardShipping,
            );
        }
        if (isEmpty(acc[country])) {
            acc[country].push(backupDeliveryMethod);
        }
        return acc;
    }, deliveryMethods);
};

/**
 * Returns the tickets object with the deliveryMethods updated with the provided
 * backup method. A backup method is added when a given country doesn't support shipping
 * or if the cutoff date has passed for the shipping method.
 * @param  {Object} selectedTickets
 * @param  {Object} shippingSettings event level shipping settings, derived from `state.event.shippingSettings`
 * @return {Object)} the updated tickets object with the correct deliveryMethods
 */
export const getWithBackupDeliveryMethod = (
    selectedTickets,
    shippingSettings = {},
) => {
    const { backupDeliveryMethod, cutoffDate } = shippingSettings;

    if (backupDeliveryMethod && cutoffDate) {
        return getSelectedTicketsAndVariantsWithDeliveryMethods(
            selectedTickets,
        ).map((ticket) => {
            const { deliveryMethods } = ticket;

            // eslint-disable-next-line no-param-reassign
            ticket.deliveryMethods = getDeliveryMethodsAfterCheckingShippingBackup(
                deliveryMethods,
                shippingSettings,
            );

            return ticket;
        });
    }
    return selectedTickets;
};

/**
 * Returns delivery methods by country. Shipping is only supported in the US, so
 * we need to remove it from the delivery methods for other countries.
 * NOTE: we are setting a default method in the unexpected case that deliveryMethods is not returned
 * correctly from the server. See: https://jira.evbhome.com/browse/EB-108170
 */
export const getDeliveryMethodsForCountries = (
    deliveryMethods = ['electronic'],
) => {
    // if deliveryMethods is already an object with US and Other keys, just return it as is
    if (deliveryMethods.US && deliveryMethods.Other) {
        return deliveryMethods;
    }

    const otherCountryDeliveryMethods = deliveryMethods.filter(
        (method) => method !== DeliveryMethod.standardShipping,
    );

    return {
        US: deliveryMethods,
        Other: otherCountryDeliveryMethods,
    };
};

/**
 * Returns the value for the selectedDeliveryMethod for the given country
 * If the current selectedDeliveryMethod is not in the deliveryMethods for the selectedCountry,
 * set the default to the first available delivery method
 */
export const getSelectedDeliveryMethodForCountry = ({
    deliveryMethods,
    selectedDeliveryMethod,
    selectedCountry = DEFAULT_COUNTRY,
}) => {
    const firstDeliveryMethod = sortDeliveryMethods(
        deliveryMethods[selectedCountry],
    )[0];

    if (!selectedDeliveryMethod) {
        return firstDeliveryMethod;
    }
    if (deliveryMethods[selectedCountry].includes(selectedDeliveryMethod)) {
        return selectedDeliveryMethod;
    }
    return firstDeliveryMethod;
};
