import get from 'lodash/get';
import replace from 'lodash/replace';
import startsWith from 'lodash/startsWith';
import upperFirst from 'lodash/upperFirst';

import { camelToSnake } from '@eb/string-utils';
import { trackInitialPageView } from '@eb/site-analytics';

import {
    BASE_URL,
    CAPACITY,
    CAPACITY_WAITLIST,
    GROUP_REG_JOIN_PATH,
    INVITATION_REQUIRED,
    PASSWORD_REQUIRED,
    PROTECTED_PATH,
    STATUS_PATH,
    TICKET_LEVEL,
} from '../constants';
import { trackPageLoad } from '../actions/eventAnalytics';
import { isUserLoggedIn } from '../selectors/user';

export const getWaitlistLabel = (props) =>
    get(props, 'event.waitlist_settings.ticket_id', '') === CAPACITY_WAITLIST
        ? CAPACITY
        : TICKET_LEVEL;

export const getCheckoutStepFromPath = (path) => {
    const checkoutStep = replace(path, `${BASE_URL}/`, '');

    return checkoutStep;
};

// See https://analytics.google.com/analytics/web/#/a141520w171290p135375432/admin/custom-dimensions/
// For a list of custom dimensions and their corresponding index
export const DIMENSION_PARENT_URL = 'dimension39';
export const DIMENSION_EVENT_ID = 'dimension12';

/**
 * Sends a pageview hit to google analytics every time a new page inside the widget flow is loaded.
 */
export const initializeGAPageView = ({
    historyManagement,
    store,
    appProps,
}) => {
    const {
        gaSettings: contextGASettings,
        widgetParentUrl,
        event: { id: eventId },
    } = appProps;
    const isReserved = get(appProps, 'event.is_reserved_seating');
    let firstRun = true;
    let domain = 'organizerSite';
    let label;

    if (appProps.isEventbriteTLD) {
        domain = 'eventbrite';
    }

    store.dispatch(
        trackPageLoad({
            checkoutStep: 'app',
            label: domain,
        }),
    );

    if (get(appProps, 'request.params.w', '') !== '') {
        store.dispatch(
            trackPageLoad({
                checkoutStep: 'appWaitlistToken',
                label: domain,
            }),
        );
    }

    historyManagement.listen(({ pathname: page }) => {
        // Avoid double-tracking the initial page load that's already tracked on index.js
        if (firstRun && page === BASE_URL) {
            firstRun = false;
            return;
        }

        const checkoutStep = getCheckoutStepFromPath(page, { isReserved });

        // Waitlist label is tracked on all page loads for waitlist events, except for the specific
        // page loads in the logic below
        if (get(appProps, 'event.waitlist_settings.ticket_id')) {
            label = getWaitlistLabel(appProps);
        }

        if (startsWith(checkoutStep, STATUS_PATH)) {
            label = upperFirst(replace(checkoutStep, 'status/', ''));
        }

        if (startsWith(checkoutStep, PROTECTED_PATH)) {
            const privacySetting = get(
                store.getState(),
                'event.privacySetting',
            );

            switch (privacySetting) {
                case INVITATION_REQUIRED:
                    label = 'invite';
                    break;
                case PASSWORD_REQUIRED:
                    label = 'password';
                    break;
                default:
                    label = privacySetting
                        ? privacySetting
                        : 'missing event privacy setting';
                    break;
            }
        }

        if (startsWith(checkoutStep, GROUP_REG_JOIN_PATH)) {
            label = isUserLoggedIn(store.getState())
                ? 'user logged in'
                : 'user logged off';
        }

        store.dispatch(
            trackPageLoad({
                checkoutStep,
                label,
            }),
        );

        trackInitialPageView(contextGASettings, {
            page,
            [DIMENSION_PARENT_URL]: widgetParentUrl,
            [DIMENSION_EVENT_ID]: eventId,
        });

        firstRun = false;
        // Reset the label so subsequent page loads don't have a residual label from a previous page load
        // e.g. status page has label "TimeExpired", but going back to the tickets page should not have any label
        label = undefined;
    });
};

/**
 * Initializes our analytics listeners.
 */
export const initializePageAnalytics = ({
    historyManagement,
    store,
    appProps,
}) => {
    if (appProps.gaSettings) {
        initializeGAPageView({ historyManagement, store, appProps });
    }
};
