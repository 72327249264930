import filter from 'lodash/filter';
import keyBy from 'lodash/keyBy';
import { push } from 'react-router-redux';
import { joinPath } from '@eb/path-utils';
import { transformTicketsData } from '../api/transformations/tickets';
import { updateOrderAction, updateOrderAttendeesAction } from './order';
import { getAttendees } from '../selectors/order';
import { BASE_URL, CHECKOUT_PATH, WIDGET_TRACKING } from '../constants';
import { getTicketGroups } from '../selectors/deliveryMethod';
import {
    checkPaymentConstraintConflictsAllTickets,
    findCommonConstrainedPaymentMethods,
    findConstrainedInstrumentType,
    findConstrainingTicketIds,
} from '../utils/tickets';

export const QUANTITY_CHANGE = 'quantityChange';
export const DONATION_CHANGE = 'donationChange';
export const INITIALIZE_TICKETS = 'initializeTickets';
export const VARIANT_CHANGE = 'variantChange';
export const UPDATE_DONATION_FEE_AND_TAX = 'updateDonationFeeAndTax';
export const UPDATE_SELECTED_PAYMENT_CONSTRAINTS =
    'updateSelectedPaymentConstraints';

export const initializeTickets = (data, error) => ({
    type: INITIALIZE_TICKETS,
    payload: transformTicketsData(data),
    error,
});

const _ticketQuantityChange = (data, checkoutStep) => ({
    type: QUANTITY_CHANGE,
    payload: data,
    meta: {
        analytics: {
            ...WIDGET_TRACKING.TS_TICKET_SELECT,
            label: [
                data.id,
                data.variantId && ' - ',
                data.variantId || '',
            ].join(''),
            value: data.quantity,
            dimensions: {
                checkoutStep,
            },
        },
    },
});

const _ticketDonationChange = (data) => ({
    type: DONATION_CHANGE,
    payload: data,
});

export const ticketVariantChange = (data) => ({
    type: VARIANT_CHANGE,
    payload: data,
});

const _updateDonationFeeAndTax = (data) => ({
    type: UPDATE_DONATION_FEE_AND_TAX,
    payload: data,
});

export const ticketQuantityChange = (data, checkoutStep) => (
    dispatch,
    getState,
) => {
    const {
        event: { remainingCapacity },
    } = getState();

    dispatch(
        _ticketQuantityChange(
            {
                ...data,
                remainingCapacity,
            },
            checkoutStep,
        ),
    );
};

export const ticketDonationChange = (data) => (dispatch, getState) => {
    const {
        app: { currencyFormat },
        event: { remainingCapacity },
    } = getState();

    dispatch(
        _ticketDonationChange({
            ...data,
            currencyFormat,
            remainingCapacity,
        }),
    );
};

export const ticketDeliveryMethodChangeFromGroup = ({
    ticketGroupIndex,
    selectedDeliveryMethod,
}) => (dispatch, getState) => {
    const state = getState();
    const ticketGroups = getTicketGroups(state);
    const attendees = getAttendees(state);
    const ticketGroup = ticketGroups[ticketGroupIndex];

    const attendeesDeliveryMethodMap = keyBy(
        ticketGroup.map(({ attendeeId }) => ({
            attendeeId,
            deliveryMethod: selectedDeliveryMethod,
        })),
        'attendeeId',
    );
    const attendeesWithUpdatedDeliveryMethod = attendees.map((attendee) =>
        attendeesDeliveryMethodMap[attendee.id]
            ? {
                  ...attendee,
                  deliveryMethod:
                      attendeesDeliveryMethodMap[attendee.id].deliveryMethod,
              }
            : attendee,
    );

    dispatch(updateOrderAttendeesAction(attendeesWithUpdatedDeliveryMethod));
};

export const updateDonationFeeAndTax = (data) => (dispatch, getState) => {
    const {
        app: { currencyFormat },
    } = getState();

    dispatch(
        _updateDonationFeeAndTax({
            ...data,
            currencyFormat,
        }),
    );
};

/** Updates the order to include the locked seat, and updates the selected variants */
export const lockSeat = ({ order: orderResponse }) => async (dispatch) => {
    const { order, attendees } = orderResponse;
    const updatedOrder = {
        ...order,
        attendees,
    };

    await dispatch(updateOrderAction(updatedOrder));
};

/** Removes the released seat from the current order, and updates the selected variants */
export const releaseSeat = ({ unitId, order: orderResponse }) => async (
    dispatch,
    getState,
) => {
    const state = getState();
    const { order } = orderResponse;

    const updatedOrder = {
        ...order,
        attendees: filter(
            state.order.attendees,
            ({ assignedUnit }) =>
                !(assignedUnit && assignedUnit.unitId === unitId),
        ),
    };

    await dispatch(updateOrderAction(updatedOrder));
};

export const submitDeliveryMethod = () => (dispatch) => {
    dispatch(push(joinPath(BASE_URL, CHECKOUT_PATH)));
};

const _updateSelectedPaymentConstraints = (data) => ({
    type: UPDATE_SELECTED_PAYMENT_CONSTRAINTS,
    payload: data,
});

/**
 * Updates constrainedPaymentMethods and constrainingTicketIds with the selected
 * ticket's payment constraint data. Disables tickets that have conflicting payment
 * constraints with the selected ticket.
 */
export const updateSelectedPaymentConstraints = () => (dispatch, getState) => {
    const {
        tickets: { ticketsById, ticketIds },
    } = getState();

    const constrainingTicketIds = findConstrainingTicketIds({
        ticketsById,
        ticketIds,
    });
    const constrainedPaymentMethods = findCommonConstrainedPaymentMethods({
        constrainingTicketIds,
        ticketsById,
    });
    const constrainedInstrumentType = findConstrainedInstrumentType({
        constrainingTicketIds,
        ticketsById,
    });
    const updatedTicketsById = checkPaymentConstraintConflictsAllTickets({
        constrainedPaymentMethods,
        ticketsById,
    });

    const payload = {
        constrainedInstrumentType,
        constrainedPaymentMethods,
        constrainingTicketIds,
        ticketsById: updatedTicketsById,
    };

    dispatch(_updateSelectedPaymentConstraints(payload));
};

/**
 * Sets constrainedPaymentMethods which is being determined and sent from react-venue-map
 */
export const pickASeatUpdatePaymentConstraints = (data) => (
    dispatch,
    getState,
) => {
    dispatch(_updateSelectedPaymentConstraints(data));
};
