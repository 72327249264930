import gettext from '@eb/gettext';

export enum REFUND_POLICY_CODE {
    NOT_DEFINED = 'not_defined',
    FLEXIBLE = 'flexible',
    MODERATE = 'moderate',
    STRICT = 'strict',
    NO_REFUNDS = 'no_refunds',
}

export const terminology = {
    [REFUND_POLICY_CODE.FLEXIBLE]: gettext(
        'Refunds up to <b>1 day</b> before event.',
    ),
    [REFUND_POLICY_CODE.MODERATE]: gettext(
        'Refunds up to <b>7 days</b> before event.',
    ),
    [REFUND_POLICY_CODE.STRICT]: gettext(
        'Refunds up to <b>30 days</b> before event.',
    ),
    [REFUND_POLICY_CODE.NO_REFUNDS]: gettext('No Refunds.'),
    [REFUND_POLICY_CODE.NOT_DEFINED]: gettext(
        'Contact the organizer to request a refund.',
    ),
};
