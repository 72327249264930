export enum PaypalScriptStatus {
    init = 'init',
    loading = 'loading',
    loaded = 'loaded',
    failed = 'failed',
}

export interface PaypalNamespace {
    scriptStatus: PaypalScriptStatus;
}

export interface WithPaypalNamespace {
    paypal: PaypalNamespace;
}
