import get from 'lodash/get';
import { snakeToCamel } from '@eb/string-utils';
import {
    getBuiltSurvey,
    shouldIncludeInBuyerQuestions,
    shouldIncludeInBasicInfo,
    shouldIncludeInAttendeeQuestions,
} from '../models/survey';

import { CannedQuestionName } from '../api/models/survey';
import { getFieldPrefilledValue } from '../api/transformations/survey';
import {
    isTransferredOrder,
    shouldManageUserSavedSurveyData,
} from '../selectors/order';

import { createSelector } from './utils/selector';
import {
    getEmailToPrefill,
    getFirstNameToPrefill,
    getLastNameToPrefill,
} from '../utils/user';

import {
    LOCALIZED_QUESTIONS_CHOICES_BY_QUESTION_ID,
    SELECTED_QUESTION_GROUP_BUYER,
} from '../models/survey';
import { getFieldName } from '../containers/checkout/constants';

import gettext from '@eb/gettext';

export const getQuestionsCollection = (state) =>
    get(state, 'survey.questionsCollection');
export const getInteractiveQuestionsByAttendeeId = (state) =>
    get(state, 'survey.interactiveQuestionsByAttendeeId');

export const _shouldGetChoicesText = (choices, questionId) =>
    LOCALIZED_QUESTIONS_CHOICES_BY_QUESTION_ID[questionId]
        ? LOCALIZED_QUESTIONS_CHOICES_BY_QUESTION_ID[questionId]
        : choices;

const _getAttendeeSurveyTitleText = (ticketNumber, ticketClassName) =>
    gettext('Ticket %(ticketNumber)s · %(ticketClassName)s', {
        ticketNumber,
        ticketClassName,
    });

export const getCountries = (state) => get(state, 'address.countries', {});

export const _buildAttendeeSurveys = (
    attendees = [],
    questions,
    shouldIncludeQuestionHelper,
) =>
    attendees.reduce(
        (attendeeSurvey, { id, survey = [], ticketClassName }, index) => {
            if (survey.length > 0) {
                attendeeSurvey.push({
                    id,
                    survey: getBuiltSurvey(
                        questions,
                        survey,
                        shouldIncludeQuestionHelper,
                    ),
                    ticketClassName,
                    ticketNumber: index + 1,
                });
            }

            return attendeeSurvey;
        },
        [],
    );

// Return questionsForBasicInfo
export const getQuestionsForBasicInfo = createSelector(
    (state) => getQuestionsCollection(state),
    (state) => getInteractiveQuestionsByAttendeeId(state),
    (questionsCollection, interactiveQuestionsByAttendeeId) =>
        getBuiltSurvey(
            questionsCollection,
            interactiveQuestionsByAttendeeId[SELECTED_QUESTION_GROUP_BUYER] ??
                [],
            shouldIncludeInBasicInfo,
        ),
);

// Return questionsForAttendees object
export const getQuestionsForAttendees = createSelector(
    (state) => get(state, 'order.attendees'),
    (state) => getQuestionsCollection(state),
    (attendees, questionsCollection) =>
        _buildAttendeeSurveys(
            attendees,
            questionsCollection,
            shouldIncludeInAttendeeQuestions,
        ),
);

// Return questionsForBuyer object
export const getQuestionsForBuyer = createSelector(
    (state) => getQuestionsCollection(state),
    (state) => getInteractiveQuestionsByAttendeeId(state),
    (state) => get(state, 'order.taxIdName'),
    (questions, interactiveQuestionsByAttendeeId, taxIdName) =>
        getBuiltSurvey(
            questions,
            interactiveQuestionsByAttendeeId[SELECTED_QUESTION_GROUP_BUYER] ??
                [],
            shouldIncludeInBuyerQuestions,
            taxIdName,
        ),
);

export const getSurvey = (state) => ({
    questionsForAttendees: getQuestionsForAttendees(state),
    questionsForBuyer: getQuestionsForBuyer(state),
    questionsForBasicInfo: getQuestionsForBasicInfo(state),
});

export const getAttendeesSelectorOptions = createSelector(
    (state) => get(state, 'order.attendees'),
    getQuestionsForAttendees,
    (attendees, attendeeSurvey) =>
        attendeeSurvey.map(({ id, ticketClassName }) => {
            const ticketIndex = attendees.findIndex(
                ({ id: attendeeId }) => id === attendeeId,
            );

            return {
                value: id,
                display: _getAttendeeSurveyTitleText(
                    ticketIndex + 1,
                    ticketClassName,
                ),
            };
        }),
);

export const getFirstAttendeeWithSurvey = (state) => {
    const {
        order: { attendees = [] },
    } = state;

    return attendees.find(
        (attendee) => attendee.survey && attendee.survey.length,
    );
};

export const getSurveyResponseNameValues = (state) => {
    const {
        survey: {
            interactiveQuestionsByAttendeeId = {},
            surveyResponsesByAttendeeId = {},
        },
    } = state;

    const existingQuestionsByAttendee = Object.entries(
        interactiveQuestionsByAttendeeId,
    ).reduce(
        (otherAttendees, [attendeeId, interactiveQuestions]) => ({
            ...otherAttendees,
            [attendeeId]: new Set(
                interactiveQuestions.map(({ questionId }) => questionId),
            ),
        }),
        {},
    );

    const questionsCollection = getQuestionsCollection(state);

    return Object.entries(surveyResponsesByAttendeeId)
        .flatMap(([attendeeId, attendeeResponses = {}]) =>
            Object.entries(attendeeResponses).map(
                ([surveyFieldRawName, surveyFieldValue]) => ({
                    attendeeId,
                    surveyFieldRawName,
                    surveyFieldValue,
                }),
            ),
        )
        .filter(({ attendeeId, surveyFieldRawName }) =>
            existingQuestionsByAttendee[attendeeId]?.has(surveyFieldRawName),
        )
        .reduce(
            (acc, { attendeeId, surveyFieldRawName, surveyFieldValue }) => ({
                ...acc,
                ...(surveyFieldValue
                    ? {
                          [getFieldName(
                              attendeeId,
                              surveyFieldRawName,
                          )]: getFieldPrefilledValue({
                              value: surveyFieldValue,
                              type:
                                  questionsCollection[
                                      snakeToCamel(surveyFieldRawName)
                                  ].type,
                          }),
                      }
                    : {}),
            }),
            {},
        );
};

export const getPrefillSurveyInfo = (state) => ({
    emailToPrefill: getEmailToPrefill(state),
    firstNameToPrefill: getFirstNameToPrefill(state),
    lastNameToPrefill: getLastNameToPrefill(state),
    attendeeWithSurvey: getFirstAttendeeWithSurvey(state),
    isTransferredOrder: isTransferredOrder(state),
    surveyResponseNameValues: getSurveyResponseNameValues(state),
    shouldManageUserSavedSurveyData: shouldManageUserSavedSurveyData(state),
});
