import PropTypes from 'prop-types';
import { translationPropType } from '@eb/i18n';

const VARIANT_PROP_TYPE = PropTypes.shape({
    /**
     * The id of the variant
     **/
    id: PropTypes.string.isRequired,
    /**
     * The name of the variant. For public discount full ticket: `gettext('Full Price')`
     **/
    name: translationPropType.isRequired,
    /**
     * The currently selected quantity of the ticket
     **/
    selectedQuantity: PropTypes.number.isRequired,
    /**
     * The display price of the discounted ticket
     **/
    displayPrice: translationPropType,
    /**
     * The string representing the associated fees and taxes
     **/
    feeAndTax: PropTypes.array,
    /**
     * The maximum number of tickets allowed to purchase
     **/
    maxQuantity: PropTypes.number,
    /**
     * Wheter or not the ticket is disabled from purchase
     **/
    isDisabled: PropTypes.bool,
});

export const VARIANTS_PROP_TYPE = PropTypes.arrayOf(VARIANT_PROP_TYPE);
