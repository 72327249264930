import React from 'react';
import PropTypes from 'prop-types';
import { lazyGettext } from '@eb/lazy-gettext';

import { EyeOnChunky } from '@eb/eds-iconography';
import { EyeOffChunky } from '@eb/eds-iconography';

export const PASSWORD_VISIBLE_ICON = <EyeOnChunky />;
export const PASSWORD_HIDDEN_ICON = <EyeOffChunky />;

export const PASSWORD_ICON_TYPES = PropTypes.oneOf([
    PASSWORD_VISIBLE_ICON,
    PASSWORD_HIDDEN_ICON,
]);

export const PASSWORD_TOO_SHORT = lazyGettext('must be at least 8 characters');
export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_STRENGTH_TO_METER_CONVERSION = 25;
export const PASSWORD_STRENGTH_LABELS = {
    0: lazyGettext('is very weak'),
    1: lazyGettext('is weak'),
    2: lazyGettext('is moderate'),
    3: lazyGettext('is strong'),
    4: lazyGettext('is very strong'),
};
export const PASSWORD_STRENGTH_COLOR_CLASSES = {
    0: 'eds-bg-color--ui-red',
    0.25: 'eds-bg-color--ui-red',
    1: 'eds-bg-color--ui-red',
    2: 'eds-bg-color--ui-orange',
    3: 'eds-bg-color--ui-green',
    4: 'eds-bg-color--ui-green',
};
