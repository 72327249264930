import {
    REGION_LABELS_BY_COUNTRY,
    POSTAL_LABELS_BY_COUNTRY,
} from '@eb/eds-base-address-fields';
import { getSelectPropValues } from '@eb/eds-select';

/**
 * Get the label for the region field for use as a form label, given a country.
 *
 * @param country
 */
export const getRegionLabel = (country) =>
    (country && REGION_LABELS_BY_COUNTRY[country]) ||
    REGION_LABELS_BY_COUNTRY.DEFAULT;

/**
 * Get the label for the postal field for use as a form label, given a country.
 *
 * @param country
 */
export const getPostalLabel = (country) =>
    (country && POSTAL_LABELS_BY_COUNTRY[country]) ||
    POSTAL_LABELS_BY_COUNTRY.DEFAULT;

/**
 * Takes countries as returned by the API and formats them for use in a Select
 * component.
 *
 * @param countries
 * @returns {*}
 */

export const formatCountriesForDropdown = (countries = []) =>
    getSelectPropValues(countries, 'label', 'code');

/**
 * Takes regions as returned by the API and formats them for use in a Select
 * component.
 *
 * @param regions
 * @returns {*}
 */
export const formatRegionsForDropdown = (regions = []) =>
    getSelectPropValues(regions, 'label', 'code');
