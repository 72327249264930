import React from 'react';
import classNames from 'classnames';

import { Divider } from '@eb/eds-divider';
import { Icon } from '@eb/eds-icon';
import { Button } from '@eb/eds-button';

import {
    getTicketInfoFromAssignedUnits,
    hasSectionTickets,
} from './utils/ticketInfoTableUtils';

import './TicketInfoTable.scss';

import { Trash } from '@eb/eds-iconography';

const TicketRow = ({
    type,
    hasDeleteColumn,
    columnKeys,
    onClickRemoveUnit,
    rowClassName,
    row,
}) => {
    const cells = columnKeys.map((key) => (
        <div
            className="ticket-info__cell"
            data-spec="ticket-info__cell"
            key={`${type}-${row.unitId}-${key}`}
        >
            {row[key]}
        </div>
    ));

    if (hasDeleteColumn) {
        const deleteButton = (
            <div
                className="ticket-info__cell ticket-info__trash"
                data-spec="ticket-info__trash"
                key={`${type}${row.unitId}-delete`}
            >
                <Button
                    onClick={onClickRemoveUnit.bind(
                        null,
                        row.unitId,
                        row.ticketClassId,
                        type,
                    )}
                    style="none"
                >
                    <Icon type={<Trash />} size="xsmall" />
                </Button>
            </div>
        );

        cells.push(deleteButton);
    }

    return (
        <li
            className={rowClassName}
            data-spec={`${type}-ticket-info-value-row`}
        >
            {cells}
        </li>
    );
};

export const TicketTable = ({
    rows,
    columns,
    type,
    noSectionTickets,
    onClickRemoveUnit,
    hasDeleteColumn,
}) => {
    const columnKeys = Object.keys(columns);
    const colClassName = classNames(
        'ticket-info__row ticket-info__titles eds-l-mar-bot-2',
        {
            'ticket-info__2col': noSectionTickets && !hasDeleteColumn,
            'ticket-info__extra-col': hasDeleteColumn,
        },
    );
    const rowClassName = classNames('ticket-info__row eds-l-mar-bot-2', {
        'ticket-info__2col': noSectionTickets && !hasDeleteColumn,
        'ticket-info__extra-col': hasDeleteColumn,
    });

    const titleColumns = (
        <li
            className={colClassName}
            data-spec={`${type}-ticket-info-title-row`}
        >
            {columnKeys.map((key) => (
                <div
                    className="ticket-info__cell eds-text-bs"
                    key={key}
                    data-spec="ticket-info__title"
                >
                    {columns[key]}
                </div>
            ))}
        </li>
    );

    const ticketRows = rows.map((row) => (
        <TicketRow
            columnKeys={columnKeys}
            hasDeleteColumn={hasDeleteColumn}
            key={row.unitId}
            onClickRemoveUnit={onClickRemoveUnit}
            row={row}
            rowClassName={rowClassName}
            type={type}
        />
    ));

    return (
        <ul className="ticket-info__table eds-l-mar-bot-6 eds-text-color--grey-800">
            {titleColumns}
            {ticketRows}
        </ul>
    );
};

export const TicketInfo = ({ ticketTables, titleText }) => (
    <section className="ticket-info" data-spec="ticket-info__table">
        <div className="eds-l-mar-bot-4 eds-text-weight--heavy eds-text-color--grey-900">
            {titleText}
        </div>
        {ticketTables}
        <div className="eds-show-down-mn">
            <Divider color="grey-200" />
        </div>
    </section>
);

export const TicketInfoTable = ({ assignedUnits }) => {
    const ticketInfo = getTicketInfoFromAssignedUnits(assignedUnits, true);
    const ticketTables = Object.keys(ticketInfo).map((ticketType) =>
        ticketInfo[ticketType].map((ticketTable) => (
            <TicketTable
                rows={ticketTable.rows}
                columns={ticketTable.cols}
                key={ticketTable.key}
                type={ticketType}
                noSectionTickets={!hasSectionTickets(ticketInfo)}
            />
        )),
    );

    return <TicketInfo ticketTables={ticketTables} />;
};
