// All DOM elements that can get focus
export const FOCUSABLE_ELEMENTS = [
    'a[href]',
    'area[href]',
    'button:not([disabled])',
    'embed',
    'iframe',
    'input:not([disabled]):not([type=hidden])',
    'object',
    'select:not([disabled])',
    'textarea:not([disabled])',
    '*[tabindex]',
    '*[contenteditable]',
];

export const FOCUSABLE_FORM_ELEMENTS = [
    'input:not([disabled]):not([type=hidden])',
    'select:not([disabled])',
    'textarea:not([disabled])',
];
