import Cookies from 'cookies-js';
import flattenDeep from 'lodash/flattenDeep';
import map from 'lodash/map';
import toPairs from 'lodash/toPairs';
import { formatUrl } from 'url-lib';

import { LazyString } from '@eb/lazy-gettext';
import { CustomSubmissionError } from '@eb/redux-form-tools';
import eventbrite, { PAGE_KEY, CONTINUATION_KEY } from 'eventbrite';

export const sdk = eventbrite({ baseUrl: '/api/v3' });

/**
 * get the CSRF token. This uses the INIT_COOKIES because CSRF tokens should not
 * be set dynamically in the client.
 */
export const getCSRFToken = () => Cookies.get('csrftoken');

/**
 * getEBFetchOptions
 *
 * Add default fetch options and headers necessary to make v3 api requests
 *
 * @param {{[key: string]: string | object}} options fetch options to merge with EB defaults
 * @returns {{[key: string]: string | object}}
 */
const getEBFetchOptions = ({ headers, method, ...options }) => {
    let additionalHeaders = {};

    if (method && method !== 'GET') {
        additionalHeaders = {
            'Content-Type': 'application/json',
        };
    }

    return {
        method: method || 'GET',
        credentials: 'same-origin',
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRFToken': getCSRFToken(),
            ...additionalHeaders,
            ...headers,
        },
        ...options,
    };
};

const WITH_API_PREFIX_REGEX = /^\/api\/v3/;

/**
 * sdkRequest
 *
 * This is a wrapper around the eventbrite v3 sdk, with our default fetch options applied
 *
 * @param {string} url
 * @param {import('../../types').FetchOptions} options init options to pass to fetch
 * @return {Promise}
 */
export const sdkRequest = (url, options = {}) => {
    // remove /api/v3 at start of url since its added via the SDK
    const fetchUrl = url.replace(WITH_API_PREFIX_REGEX, '');

    return sdk.request(fetchUrl, getEBFetchOptions(options));
};

/**
 * Return a promise that is resolved or rejected depending on the response's
 * status code.
 * @param {Response} response
 * @returns {Promise<Response>}
 */
export const checkStatus = (response) => {
    if (response.status >= 300) {
        return Promise.reject(response);
    }

    return Promise.resolve(response);
};

/**
 * Uses fetch method of the GlobalFetch web interface to make a ajax request to our
 * server. Will default appropriate headers (including a CSRF token) and assert a
 * valid status from the server. Use the Eventbrite SDK when possible.
 *
 * @deprecated
 * @param {string} url
 * @param {import('../../types').FetchOptions} options init options to pass to fetch
 * @return {Promise<Response>}
 */
// TODO: Refactor and remove this
export const fetchEB = (url, { useCORS = false, ...options } = {}) => {
    /** @type {{[key: string]: string | object}} */
    let fetchOptions = getEBFetchOptions(options);

    if (useCORS) {
        fetchOptions = {
            mode: 'cors',
            credentials: 'include',
            ...options,
        };
    }

    return fetch(url, fetchOptions).then(checkStatus);
};

/**
 * Calls fetch on provided url with default options necessary for interacting
 * with our JSON API. Parses the JSON, provides appropriate headers, and asserts
 * a valid status from the server.
 *
 * @deprecated
 * @param {string} url resource to retrieve
 * @param {import('../../types').FetchOptions} options init options to pass to fetch
 * @returns Promise
 */
// TODO: Refactor and remove this
export const fetchJSON = (
    url,
    { headers, method, useCORS = false, ...options } = {},
) => {
    let fetchHeaders = headers;

    if (method && method !== 'GET') {
        fetchHeaders = {
            'Content-Type': 'application/json',
            ...headers,
        };
    }

    const fetchOptions = {
        useCORS,
        headers: fetchHeaders,
        method: method || 'GET',
        ...options,
    };

    return fetchEB(url, fetchOptions).then((response) => {
        let responseJSON = {};

        if ('json' in response) {
            responseJSON = response.json();
        }

        return responseJSON;
    });
};

const PAGINATION_MAP = {
    [PAGE_KEY]: {
        donePredicate: ({ page_number: pageNumber, page_count: pageCount }) =>
            pageNumber >= pageCount,
        initialPageId: 1,
    },
    [CONTINUATION_KEY]: {
        donePredicate: ({ has_more_items: hasMoreItems }) => !hasMoreItems,
        initialPageId: null,
    },
};

/**
 * _dangerouslyFetchAllPages is a function for requesting all data from a V3 paginated endpoint,
 * and returns a batch result.
 * It can handle both "classic pagination" (page number, page count, page size)
 * and continuation tokens
 * Defaults to classic pagination
 * https://docs.evbhome.com/webdev/best_practices/soa.html#pagination.
 *
 * @param {String} url              request url
 * @param {String} dataKey          name of data key where the list of data lives on the response
 * @param {String} paginationType   use either the PAGINATION or CONTINUATION constants
 * @param {Object} options          object of options.
 *                                  most options are passed through to sdkRequest, but this
 *                                  function does provide a `onPageProgress` callback that
 *                                  will be invoked after each page finishes loading.
 * @returns {Object} Combined Results
 */
export const dangerouslyFetchAllPages = (
    url,
    dataKey,
    paginationType = PAGE_KEY,
    options = {},
) => {
    const { donePredicate, initialPageId } = PAGINATION_MAP[paginationType];

    // use name onPageProgress to reduce chance of conflict with sdkRequestOptions
    const { onPageProgress, ...sdkRequestOptions } = options;

    return new Promise((resolve, reject) => {
        let totalPages;

        let currentPage = 0;

        const fetchNext = (nextPageId, existingObjects = []) => {
            currentPage++;
            const apiUrl = formatUrl(url, {
                [paginationType]: nextPageId,
            });

            sdkRequest(apiUrl, sdkRequestOptions)
                .then(({ pagination, ...response }) => {
                    const objects = [...existingObjects, ...response[dataKey]];

                    if (pagination && pagination['page_count']) {
                        totalPages = pagination['page_count'];
                    }
                    if (onPageProgress) {
                        onPageProgress({ currentPage, totalPages });
                    }

                    if (!pagination || donePredicate(pagination)) {
                        return resolve({ objects });
                    }

                    const nextPage =
                        paginationType === PAGE_KEY
                            ? nextPageId + 1
                            : pagination.continuation;

                    return fetchNext(nextPage, objects);
                })
                .catch(reject);
        };

        fetchNext(initialPageId);
    });
};

/**
 * This just expands the error to a redux-form style toplevel error if the
 * error is just a string.
 *
 * @param error
 * @private
 */
const _makeErrorObject = (error) =>
    typeof error === 'string' || LazyString.isValid(error)
        ? { _error: error }
        : error;

/**
 * Convenience function to avoid repeating code in invoking an error translation
 * function in the methods `translateArgumentErrors` and `translateServerErrors`
 * below.
 *
 * @param errorSpec
 * @param errorCode
 * @param args
 * @private
 */
export const getErrorFromSpec = (errorSpec, errorCode, ...args) => {
    const errorFunc = errorSpec[errorCode] || errorSpec.default;
    const error = errorFunc && errorFunc(...args);

    let errorObj;

    if (error) {
        errorObj = _makeErrorObject(error);
    }

    return errorObj;
};

/**
 * translateServerError takes a declarative specification of the server errors
 * and argument errors that we want to handle and translates encountered errors
 * into the format expected by redux-form in accordance with our validation
 * library. Returns a function that can be used as part of a promise chain.
 *
 * Example usage:
 *
 * let errorSpec = {
 *   COST_GREATER_THAN_FEE: () => gettext('The cost of your ticket is greater than your fee'),
 *   BAD_QUANTITIES: () => ({'quantity_total': gettext('Please enter a valid quantity')}),
 *   ARGUMENTS_ERROR: (parsedError) => gettext('One or more of your arguments are invalid'),
 *   default: () => gettext('There was an error saving your ticket')
 * };
 *
 * return fetchV3('/api/v3/events/1/ticket_classes/', {body: data})
 *      .catch(translateServerErrors(errorSpec));
 *
 */
export const translateServerErrors = (errorSpec) => ({
    response,
    parsedError,
}) => {
    const errorCode = (parsedError && parsedError.error) || 'default';
    const errorObj = getErrorFromSpec(
        errorSpec,
        errorCode,
        parsedError,
        response,
    );

    throw new CustomSubmissionError(errorObj, { response, parsedError });
};

/**
 * combine string values from multiple objects into new object
 *
 * @param {object} previous
 * @param {object} next
 * @returns {object}
 */
// TODO: Refactor copy in @eb/validators
export const reduceErrorObjects = (previous, next) => {
    const errors = { ...previous };

    toPairs(next).forEach(([key, value]) => {
        if (value) {
            errors[key] = errors[key] ? `${errors[key]}, ${value}` : value;
        }
    });
    return errors;
};

/**
 * translateArgumentErrors is designed to be used to further translate
 * argumentErrors from a declarative spec.
 *
 * @param errorSpec obj
 */
export const translateArgumentErrors = (errorSpec) => (
    parsedError,
    response,
) => {
    const errorObjects = Object.keys(parsedError.argumentErrors || {})
        // filter down to only those attributes that are in `errorSpec`
        .filter((errorAttr) => !!errorSpec[errorAttr])

        // build up a 2-D array of error objects for each `errorAttr`
        .map((errorAttr) =>
            // return an array of error objects, one for each errorCode
            // null or undefined may be returned
            map(parsedError.argumentErrors[errorAttr], (errorCode) =>
                getErrorFromSpec(
                    errorSpec[errorAttr],
                    errorCode,
                    parsedError,
                    response,
                ),
            ),
        );

    const flatErrorObjects = flattenDeep(errorObjects)
        // remove any of the null/undefined error objects
        .filter((error) => !!error);

    // From the errors, compose an error object, or the default func if no errors were matched
    const errorObj = flatErrorObjects.length
        ? flatErrorObjects.reduce(reduceErrorObjects)
        : getErrorFromSpec(errorSpec, 'default', parsedError, response);

    return errorObj;
};

/**
 *
 * Promise.race returns a promise that fulfills or rejects as soon as one of the promises in an
 * iterable fulfills or rejects, with the value or reason from that promise.
 * @param {Function} promiseFn
 * @param {Int} timeout time in milliseconds
 *
 */
export const promiseWithTimeout = (promiseFn, timeout = 2000) => (...args) =>
    Promise.race([
        promiseFn(...args),
        new Promise((_, reject) =>
            setTimeout(
                () =>
                    reject(
                        new CustomSubmissionError('Timeout has occured', {
                            timeout,
                        }),
                    ),
                timeout,
            ),
        ),
    ]);
