import { Bugsnag } from '@bugsnag/browser';
import logger from '@eb/logger-bugsnag';

export const isIosWebView = (ua: string): boolean =>
    /iphone|ipod|ipad/.test(ua.toLowerCase());

export const isAndroidWebView = (ua: string): boolean =>
    ua.toLowerCase().includes('android');

export const isFacebookWebView = (ua: string): boolean =>
    /fbav|fbios/.test(ua.toLowerCase());

export const isInstagramWebView = (ua: string): boolean =>
    ua.toLowerCase().includes('instagram');

export const isLinkedInWebView = (ua: string): boolean =>
    ua.toLowerCase().includes('linkedinapp');

/*
 * When having an asynchronous payment such as Sofort or Bancontact, the checkout flow
 * relies on having a second tab where the user performs the payment while the first one
 * stays idle on EB.
 * This two-tabs mechanics doesn't work for in-app browsers such as Facebook's, Instagram's
 * or LinkedIn on iOS as they simply do not support tabs. We therefore us an alternative flow
 * where EB's tab performs a redirection on demand by the user.
 * For more info on the implementation details, please refer to: EB-95856.
 *
 * This methods determine whether the first or the second flow should be followed.
 */
export const shouldPerformTablessCheckout = (): boolean => {
    const userAgent =
        window.navigator.userAgent ||
        window.navigator.vendor ||
        (window as any).opera; // TODO: Remove window.opera since it is not holding user-agent string (http://help.dottoro.com/ljmqvpgc.php)
    const isOffendingAppWebView =
        isFacebookWebView(userAgent) ||
        isInstagramWebView(userAgent) ||
        isLinkedInWebView(userAgent);
    const isWebView = isIosWebView(userAgent) || isAndroidWebView(userAgent);

    return isOffendingAppWebView && isWebView;
};

export interface StateWithMobileProfileFlags {
    app: {
        useIosProfile: boolean;
        useAndroidProfile: boolean;
    };
}

export const isNativeApp = <State extends StateWithMobileProfileFlags>(
    state: State,
): boolean => Boolean(state.app.useIosProfile || state.app.useAndroidProfile);

export enum RedirectionTechnique {
    'window.location = url' = 'window.location = url',
    'window.location.href = url' = 'window.location.href = url',
    'window.location.replace(url)' = 'window.location.replace(url)',
    'window.location.assign(url)' = 'window.location.assign(url)',
}

export const redirectTo = (url: string): Promise<RedirectionTechnique> =>
    new Promise((resolve, reject) => {
        /**
         * Workaround to redirect with multiple fallback support
         * https://stackoverflow.com/questions/31223216/why-isnt-window-location-href-not-forwarding-to-page-using-safari
         */
        const onExceptionRedirect = setTimeout(function () {
            const metadata = {
                redirectionTechnique:
                    RedirectionTechnique['window.location.assign(url)'],
                url,
            };
            const notificationOverrides = {
                beforeSend: (report: Bugsnag.Report) => {
                    // eslint-disable-next-line no-param-reassign
                    report.groupingHash = 'tabless-environment-redirection';
                },
            };

            const onTimeoutReject = setTimeout(function () {
                logger.error(
                    'Redirection fallback failed',
                    metadata,
                    notificationOverrides,
                );

                reject(new Error(''));
            }, 100);

            logger.warn(
                'Redirection falled back to window.location.assign(url). This means there has been some unhanlded exception in the redirection process',
                metadata,
                notificationOverrides,
            );

            window.location.assign(url);

            clearTimeout(onTimeoutReject);
            resolve(RedirectionTechnique['window.location.assign(url)']);
        }, 300);

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if ((window.location = url)) {
            clearTimeout(onExceptionRedirect);

            return resolve(RedirectionTechnique['window.location = url']);
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if ((window.location.href = url)) {
            clearTimeout(onExceptionRedirect);

            return resolve(RedirectionTechnique['window.location.href = url']);
        }

        clearTimeout(onExceptionRedirect);

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.location.replace(url);

        resolve(RedirectionTechnique['window.location.replace(url)']);
    });
