import { replace } from 'react-router-redux';
import { joinPath } from '@eb/path-utils';
import { BASE_URL, PURCHASE_CONFIRMATION_PATH } from '../constants';
import {
    isAddAttendeesOrder,
    isOrganizerInitiatedTransferOrder,
} from '../selectors/order';
import { notifyParentProtectCheckout as notifyParentProtectCheckoutAction } from './notifyParent';
import { completeOrderActions } from './order/common';

export const showPurchaseConfirmation = () => async (dispatch, getState) => {
    const state = getState();
    const {
        app: { isEventbriteTLD },
        order: { ticketBuyerSettings: { redirectUrl } = {} },
        event: { id: eventId },
    } = state;

    await dispatch(completeOrderActions());

    if (isEventbriteTLD && redirectUrl) {
        dispatch(notifyParentProtectCheckoutAction({ disable: true }));
    }

    // In Add Attendees and in transfers initiated by the organizer,
    // the confirmation is handled by the iframe container.
    if (
        isAddAttendeesOrder(state) ||
        isOrganizerInitiatedTransferOrder(state)
    ) {
        return null;
    }

    return dispatch(replace(joinPath(BASE_URL, PURCHASE_CONFIRMATION_PATH)));
};
