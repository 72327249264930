import { Schema } from 'normalizr';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { deepKeysToCamel, transformUtil } from '@eb/transformation-utils';

import { SELECTED_QUESTION_GROUP_BUYER } from '../../models/survey';
import { getDeliveryMethodsForCountries } from '../../utils/deliveryMethod';
import { DeliveryMethod } from '../models/deliveryMethod';
import { OrderResponse, OrderResponseFormatted } from '../models/order';
import { QuestionsResponses, CannedQuestionName } from '../models/survey';

const orderSchema = new Schema('order');

const _transformAttendeeDeliveryMethodsForCountries = <
    Input extends {
        ticketClass?: { deliveryMethods?: Array<DeliveryMethod> };
    }
>({
    ticketClass = {},
    ...rest
}: Input) => {
    const { deliveryMethods = [] } = ticketClass;
    const availableDeliveryMethodsForCountries = getDeliveryMethodsForCountries(
        deliveryMethods,
    );

    return {
        ...rest,
        ticketClass: {
            ...ticketClass,
            deliveryMethods: availableDeliveryMethodsForCountries,
        },
    };
};

export const transformOrderData = (data: OrderResponse) => {
    // NB: normalizr does a special type of transformation !== deepKeysToCamel,
    // This only affects keys in `order.questions` which are tightly coupled
    // with `order.attendee[n].survey[m].questionId`
    const transformedOrder = transformUtil({
        response: data,
        schema: orderSchema,
    });

    const formattedOrder = transformedOrder.entities.order[
        transformedOrder.result
    ] as OrderResponseFormatted;

    const nonTransformedSurveyResponses = get(data, 'survey_responses', {});

    const order = isEmpty(nonTransformedSurveyResponses)
        ? formattedOrder
        : { ...formattedOrder, surveyResponses: nonTransformedSurveyResponses };

    const attendees = order.attendees ?? [];

    return isEmpty(attendees)
        ? order
        : {
              ...order,
              attendees: attendees.map(
                  _transformAttendeeDeliveryMethodsForCountries,
              ),
              costs: {
                  ...order.costs,
                  feeComponents: deepKeysToCamel(
                      get(data, 'costs.fee_components', []),
                  ),
              },
          };
};

export const transformWaitlistEntryData = (buyerData: {
    [CannedQuestionName.firstName]: string;
    [CannedQuestionName.lastName]: string;
    [CannedQuestionName.email]: string;
    [CannedQuestionName.homePhone]?: string;
}) => {
    const transformedEntry = {
        firstName: buyerData[CannedQuestionName.firstName],
        lastName: buyerData[CannedQuestionName.lastName],
        email: buyerData[CannedQuestionName.email],
    };

    const phone = buyerData[CannedQuestionName.homePhone];

    return phone
        ? {
              ...transformedEntry,
              phone,
          }
        : transformedEntry;
};

export const getSurveyResponsesByAttendeeIdFromOrderResponse = (
    order: OrderResponse,
): Record<string, QuestionsResponses> => {
    const attendees = order.attendees ?? [];

    const attendeeLevelSurveyAnswersByAttendee = attendees.reduce(
        (otherAnswersByAttendee, currentAttendee) => ({
            ...otherAnswersByAttendee,
            [currentAttendee.id]: currentAttendee.survey_responses ?? {},
        }),
        {} as Record<string, QuestionsResponses>,
    );

    return {
        ...attendeeLevelSurveyAnswersByAttendee,
        [SELECTED_QUESTION_GROUP_BUYER]: order?.survey_responses ?? {},
    };
};
