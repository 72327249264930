import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from '@eb/eds-button';
import { CTA_JOIN_WAITLIST } from '../../constants';
import { TYPE_LINK } from '@eb/eds-button';

import './ResellerFooter.scss';

export default class ResellerFooter extends React.PureComponent {
    static propTypes = {
        resellerUrl: PropTypes.string.isRequired,
        resellerText: PropTypes.string,
    };

    render() {
        const { resellerUrl, resellerText } = this.props;

        let ctaText = resellerText;

        if (!resellerText) {
            ctaText = CTA_JOIN_WAITLIST;
        }

        const footerClasses = classNames(
            'eds-align--right',
            'eds-l-pad-vert-4',
            'eds-l-pad-hor-16',
        );

        return (
            <div
                className="reseller-footer__container"
                data-spec="reseller-footer-container"
            >
                <div className={footerClasses}>
                    <Button
                        style="fill"
                        type={TYPE_LINK}
                        href={resellerUrl}
                        target="_blank"
                    >
                        {ctaText}
                    </Button>
                </div>
            </div>
        );
    }
}
