import React from 'react';
import { render } from 'react-dom';
import App from './app';
import {
    initDimensionsFromState,
    trackInitialPageView,
} from '@eb/site-analytics';
import GenericErrorMessage from './components/errorNotification/GenericErrorMessage';
import logger from '@eb/logger-bugsnag';

const rootElement = document.getElementById('root');
const appContext = window.__SERVER_DATA__;

const main = () => {
    if (!appContext) {
        throw new Error('appContext is missing');
    }
    if (!rootElement) {
        throw new Error('rootElement is null');
    }

    render(<App {...appContext} />, rootElement);
    initDimensionsFromState(appContext);
    trackInitialPageView(appContext.gaSettings);
};

try {
    main();
} catch (error) {
    logger.error('checkoutWidget main error:', {
        error: error.message ? error.message : error,
        appContext,
    });
    if (rootElement) {
        try {
            render(<GenericErrorMessage />, rootElement);
        } catch (e) {
            logger.error('GenericErrorMessage error:', {
                error: e,
            });
        }
    }
}
