import { TERMINOLOGY } from '../constants/terminology';
import { TICKETING, REGISTRATION } from '../constants';

/**
 * Returns the correct object to pull strings
 * @param  {Boolean} isRegEvent, true if the event has Registration terminology
 * @return {Object}
 */
export const getTerminology = (isRegEvent) =>
    isRegEvent ? TERMINOLOGY[REGISTRATION] : TERMINOLOGY[TICKETING];

export default getTerminology;
