import * as React from 'react';

const WaitingRoomIllustrationSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 360 154" {...props}>
        <defs>
            <clipPath id="waiting-room-illustration_svg__Waiting-room-illustration-clip-path">
                <path fill="none" d="M0 0h360v154H0z" />
            </clipPath>
            <style>
                {
                    '\n            .waiting-room-illustration_svg__Waiting-room-illustration-cls-3{fill:#fff}.waiting-room-illustration_svg__Waiting-room-illustration-cls-5{fill:#f05537}.waiting-room-illustration_svg__Waiting-room-illustration-cls-6{fill:#c2c2cc}.waiting-room-illustration_svg__Waiting-room-illustration-cls-7{fill:#324b8c}.waiting-room-illustration_svg__Waiting-room-illustration-cls-8{fill:#1e0a3c}.waiting-room-illustration_svg__Waiting-room-illustration-cls-10{fill:#bf4d28}\n        '
                }
            </style>
        </defs>
        <g clipPath="url(#waiting-room-illustration_svg__Waiting-room-illustration-clip-path)">
            <path
                className="waiting-room-illustration_svg__Waiting-room-illustration-cls-3"
                d="M324.9-38.5l-2.6 53.9v.2c.5 2.3 7.8 33.7 10.4 88.5h-16.9s-.9-4.3-2.2-4.3-16 .2-16 .2-11.9-49.8-15.5-70.9c-1.4-8.6-1.5-17.3-3.9-48.1-.5-6.8 4.8-21.3 4.8-21.3z"
            />
            <path
                d="M326.3 5.3l5.8-46.3c.2-6.6-1.5-14-1.2-17.7h-16.6l2.2 6.6s.4 8.5.4 9.8a194.92 194.92 0 003.7 38.4s-.2 5.4-.5 6.6c-.6 2.4-1.1 3.6-.7 5.2.1.6-.2 6.3.8 6.1s-.9-.2 0 0c2.3.5-.4 2.4 1.1 5.2.9 1.6 2.3-3.3 4.6-6.1 1-1.1.4-7.8.4-7.8"
                fill="#da5a2b"
            />
            <path
                className="waiting-room-illustration_svg__Waiting-room-illustration-cls-5"
                d="M324.5 131.1l3.4-6v-22H318l2 16 2.6 7.3 1.9 4.7zM306.5 98.1l1.6 16-15.4 5.4 3.5 1.3 18.5-2.2 1-21.2-9.2.7z"
            />
            <path
                className="waiting-room-illustration_svg__Waiting-room-illustration-cls-6"
                d="M332.6 104.1l1.2-11.5-20.8.9 2.8 10.6h16.8z"
            />
            <path
                className="waiting-room-illustration_svg__Waiting-room-illustration-cls-6"
                d="M295.8 100.6l-2.7-9.8 19.9-.6 1.7 9.8-18.9.6z"
            />
            <path
                className="waiting-room-illustration_svg__Waiting-room-illustration-cls-6"
                d="M294.9 11.8c-.4 1.1.5 18.1.8 19.2l15.7 69h2.9l-2.9-7.5 3.3-2.4-14-58.3"
            />
            <path
                className="waiting-room-illustration_svg__Waiting-room-illustration-cls-7"
                d="M288.5 120.9v1.7s23.7 1.1 26.9 0l.9-.6.3-1.3z"
            />
            <path
                className="waiting-room-illustration_svg__Waiting-room-illustration-cls-8"
                d="M316 108s1.9 12.6.3 12.9c-6.5 1.2-27.9.5-27.8 0s1.3-2.8 7.8-2.7 9.1-9 9.1-9z"
            />
            <path
                className="waiting-room-illustration_svg__Waiting-room-illustration-cls-7"
                d="M305.5 111.4c.7.6 1.5 1.1 1.8.9s.2-.4.2-.6-.1-.3-.1-.4a4.21 4.21 0 00-1.9.1zm1.7 1.8c-1.1 0-2.6-1.4-2.9-1.8a.63.63 0 01-.1-.4.32.32 0 01.3-.3c.4-.1 2.5-.8 3.5 0a1.24 1.24 0 01.4 1.2c-.1.9-.5 1.2-.8 1.3z"
            />
            <path
                className="waiting-room-illustration_svg__Waiting-room-illustration-cls-7"
                d="M302.8 112.8c-.1 0-.2 0-.2-.1a.37.37 0 01-.16-.51.24.24 0 01.06-.09 4 4 0 011-1 1.49 1.49 0 01-.7-1.4c0-.2.3-.4.5-.4s.4.3.4.5c-.1.5.9.8 1.1.9a.56.56 0 01.3.5.43.43 0 01-.4.4c-.8 0-1.4 1-1.4 1-.2.1-.3.2-.5.2M296.5 120.1c-.2 0-.3-.1-.4-.3l-.7-1.6a.45.45 0 01.8-.4l.7 1.6a.48.48 0 01-.2.6c-.1.1-.1.1-.2.1M299.2 119.5a.76.76 0 01-.4-.2l-1.1-1.6c-.1-.2-.1-.5.1-.6s.5-.1.6.1l1.1 1.6c.1.2.1.5-.1.6a.3.3 0 01-.2.1M301.9 118.5a.76.76 0 01-.4-.2l-1.1-1.5c-.1-.2-.1-.5.1-.6s.5-.1.6.1l1.1 1.5c.1.2.1.5-.1.6s-.1.1-.2.1M303.8 115.9c-.1 0-.2 0-.2-.1l-1.5-1.1c-.2-.1-.2-.4-.1-.6a.37.37 0 01.51-.16.47.47 0 01.09.06l1.5 1.1c.2.1.2.4.1.6s-.2.2-.4.2"
            />
            <path
                className="waiting-room-illustration_svg__Waiting-room-illustration-cls-8"
                d="M318.1 117.9a44.69 44.69 0 01-1.2 9.5 35.55 35.55 0 01-2.8 4.4 66.88 66.88 0 0010.4.3 39.76 39.76 0 008.4-1.2s-5.2-10.9-3.4-14z"
            />
            <path
                className="waiting-room-illustration_svg__Waiting-room-illustration-cls-7"
                d="M313.1 133.9s17.1.9 19.4-.6a1.41 1.41 0 00.4-2.3s-17.1 1.3-18.4.8-1.4 2.1-1.4 2.1M321.1 119a23.54 23.54 0 003.1-.7 2 2 0 00-1.4-.7 2 2 0 00-1.5.9c-.1.3-.2.4-.2.5m0 .9a1 1 0 01-.9-.4c-.3-.5 0-1 .4-1.5a2.88 2.88 0 012.1-1.3 3.19 3.19 0 012.2 1.1c0 .1.1.1.1.2a27.65 27.65 0 013.1-.8.41.41 0 11.2.8q-1.95.45-3.6.9a10.29 10.29 0 01-3.6 1M323 122.2a.43.43 0 01-.4-.4.68.68 0 01.4-.5l3.8-.1a.45.45 0 11.1.9l-3.9.1M321.9 124.9a.43.43 0 01-.4-.4.68.68 0 01.4-.5l4.6-.2a.45.45 0 01.1.9l-4.7.2M322.6 127.8a.43.43 0 01-.4-.4.68.68 0 01.4-.5l4.1-.2a.43.43 0 01.4.4.68.68 0 01-.4.5l-4.1.2"
            />
            <path
                className="waiting-room-illustration_svg__Waiting-room-illustration-cls-8"
                d="M216.4 130.9v-1.8h19.7v1.8h-19.7z"
            />
            <path
                className="waiting-room-illustration_svg__Waiting-room-illustration-cls-7"
                d="M216.4 129.1s-.3-8.5 5.2-8.5 10.2-1 11 1.3a76.35 76.35 0 003.5 7.3h-19.7z"
            />
            <path
                className="waiting-room-illustration_svg__Waiting-room-illustration-cls-8"
                d="M216.4 129.4h19.7v-.6h-19.7v.6zM212.6 1.1s-6.4 53.3-5.2 61.5-1.1 10.4-.2 12.8 9.5 47.7 9.5 47.7l18.8-1.5v-4.9s-2.7-59.6-2.7-61.5 1.5-30.6 1.5-30.6l2.7-.3s7.4 29.8 9.6 33.1a37 37 0 013.5 6.8l-2.5.8s-31.9 1.4-39 32.8l8.2 12s11.6-14.2 28.4-20.2 23.7-4.1 24.7-5.2 3.5-5.7 2.7-7.4-3.2-60.4-8.6-76h-51.4z"
            />
            <path
                className="waiting-room-illustration_svg__Waiting-room-illustration-cls-3"
                d="M210.8 26.8a2 2 0 01-1-.3l.2-.5a1.53 1.53 0 001.6 0c3.8-2.1 5.7-17.5 5.7-17.7l.6.1c-.1.6-1.9 15.9-6 18.1a2.66 2.66 0 01-1.1.3M266 23.1a7.49 7.49 0 01-3.8-1.1c-3.7-2-3.6-16.9-3.6-17.5h.6c0 4.2.5 15.5 3.3 17 3.4 1.8 5.2.6 5.3.6l.3.5a3.23 3.23 0 01-2.1.5M234.1 24.6l-1.9-19h.5l2 19h-.6zM237.4 24.4l-.6-.1c1.2-6.5 3-17.1 2.9-17.8l.5-.3c.4.6-1.6 11.6-2.8 18.2"
            />
            <path
                className="waiting-room-illustration_svg__Waiting-room-illustration-cls-3"
                d="M225.7 26.1l-.1-.6c.1 0 10.4-2.2 17.4-1.5l-.1.6c-6.9-.7-17.1 1.4-17.2 1.5M224.4 31.8l-.3-.5c.1-.1 3.6-2.1 4.5-2.5l.2.5a46.54 46.54 0 00-4.4 2.5M255.4 69.5c-4.6-1.4-5.5-5-5.5-5.2l-.1-.5.5.1c.6.2 2.2.5 2.9 0l.3.5a4.87 4.87 0 01-2.9.2 7.1 7.1 0 004.9 4.3zM251.5 72.4l-3.2-7.5.5-.2 3.2 7.5-.5.2zM266.1 85.2l-2.3-1.9.4-.5 2.2 2-.3.4z"
            />
            <path
                className="waiting-room-illustration_svg__Waiting-room-illustration-cls-7"
                d="M209.9 99.6l-5.1 2h-6V115l4.1 1.8v13s11 .6 11.6 0-.4-4.9-2.5-5.2-4.9-.4-4.9-.4l4.1-12.4 3.5-6.2z"
            />
            <path
                className="waiting-room-illustration_svg__Waiting-room-illustration-cls-8"
                d="M212.8 126.9h-8.7v-12l-2.7-1.8.8-10.8.6.1-.8 10.4 2.7 1.8v11.7h8.1v.6z"
            />
            <path
                className="waiting-room-illustration_svg__Waiting-room-illustration-cls-8"
                d="M209.5 119.4l-3.5-2h3.6l-3.3-3.1 4.1.8-2.7-1.5.3-.5 5.8 3.3-5.6-1.1 2.9 2.6h-3l1.6 1-.2.5zM222.8 126.3h-4.5a.3.3 0 110-.6h3.3c-3.5-1.7-3.5-1.8-3.4-2s.4-.1.5-.1c.5.2 3 .5 5 .8l-4.8-1.7c-.1-.1-.2-.2-.2-.3s.2-.2.3-.2l5.2.9c.2 0 .3.2.2.3s-.2.3-.3.2l-1.5-.3 3.6 1.3c.1 0 .2.2.2.3s-.2.2-.3.2-3.5-.4-5.8-.7c.8.4 1.8.9 2.5 1.3.1.1.2.2.2.3s-.1.3-.2.3"
            />
            <path
                className="waiting-room-illustration_svg__Waiting-room-illustration-cls-3"
                d="M211.5 90.4l-.6-.1c2.9-14.5 22.1-22.4 22.3-22.4l.2.5c-.1 0-19 7.8-21.9 22"
            />
            <path
                className="waiting-room-illustration_svg__Waiting-room-illustration-cls-5"
                d="M223.1-75.9a25.8 25.8 0 01-3.5 1.4c-4.6 1.1-15.9 4.6-17.3 5.9-1.8 1.7-5.1 5.2-2.1 13.8s5.9 9.2 5.1 14.5a34.42 34.42 0 01-1.7 7.3l.8 2.2-.7 3.2 1.8 1.2-.8 2.8s3.7 3.5 5.6 3.5 2.2.4 2.2.4L210.1-.8a11.7 11.7 0 00-1.2 2.3 9.51 9.51 0 001.2 2.1l57.5.6S269 2 269 1.1s-1.9-3.7-1.9-3.7l-4.4-25.7 10.1-5.1a11.58 11.58 0 00-4.1-6.8c-3.4-2.5.4-6.3.4-6.3s-1.9-17.2-3-18.5c-4.5-5.3-16.8-7.6-16.8-7.6l-5.8-2.3z"
            />
            <path
                className="waiting-room-illustration_svg__Waiting-room-illustration-cls-8"
                d="M212.3 3.6l52.6.5.7 3.6s-3.9-2.6-13.4-1.4c-9.1 1.1-11.5-1.6-18.7-1.2a110.29 110.29 0 01-15.1.1c-4.7-.3-6.1-1.6-6.1-1.6"
            />
            <path
                className="waiting-room-illustration_svg__Waiting-room-illustration-cls-5"
                d="M46.5 61.1S26.8 102 25.6 103.4s-6.2 3.4-5.9 4.8 5.4 9.4 5.7 10.8 1 4.8 2.4 5.2 9.3 6 10.7 5.3 4-1.7 4-2.6-3.7-.5-5.4-2.7-5.9-9.7-4-13 28.2-37 28.2-37z"
            />
            <path
                className="waiting-room-illustration_svg__Waiting-room-illustration-cls-5"
                d="M79.5 79s-13.3 39.3-12.9 39.8c5.7 7.7 15.4 9.7 15.4 9.7s-12.2 2.2-21.6-.6c-3.7-1.1-3.3-5.4-3.3-5.4s-1.3-30.6 1.1-43.4 21.3-.1 21.3-.1"
            />
            <path
                className="waiting-room-illustration_svg__Waiting-room-illustration-cls-8"
                d="M88.6 129.8c.6.6-1.2 3-10.9 2.6a49.79 49.79 0 01-11.7-1.7l-.9.8-7-.2s-2.2-2.5-1.1-7.5c0 0 11.6 5.1 17.7 5.4 1.5.1 2.6-1 3.5-1.4.3-.2-5.2-2.7-5.2-2.7a47.39 47.39 0 009.6 3.7c1.5.3 5.4.3 6 1"
            />
            <path
                d="M85.1 82s-9.3 10.1-32 3.1L57 70.4S81.7 71 85.1 82"
                fill="#45494f"
            />
            <path
                className="waiting-room-illustration_svg__Waiting-room-illustration-cls-8"
                d="M24 104.3s-1.1 3.9 0 5.9 5 11.5 6.7 12.1 6.4.7 6.4.7.3 1.9 1.5 2.4 3.7.2 4.1 1.6-2.3 2.9-4 2.9c-4.9 0-9.2-3.9-10.1-4.7-1.5-1.3-2.7-2.9-3.5-6.3a12.08 12.08 0 00-2.8-5.4l-.9.2-2.8-5.4c-.2-.1.9-3.7 5.4-4"
            />
            <path
                className="waiting-room-illustration_svg__Waiting-room-illustration-cls-10"
                d="M75.9 89.8s-18.9 1.1-18.8-.9l.7-8.8 20 4.1zM50.3 88.5s-2.4-4.2-15.9-2.4l2.9-5.9L53.1 85z"
            />
            <path
                className="waiting-room-illustration_svg__Waiting-room-illustration-cls-8"
                d="M79.5 0c.6.4 5.6 27.1 9.7 45.3 2.2 9.5-3.5 32-3.8 35.6s-13.1 9.4-30.3 4.6-24-3.1-23-4.9 6-16.5 6.2-19.5c1.9-32.3-9.1-38.5-3.6-65.8"
            />
            <path
                className="waiting-room-illustration_svg__Waiting-room-illustration-cls-5"
                d="M158.7-42.7s1.2 63 .8 64.1-2 7.1-3.5 8.1a11.32 11.32 0 01-4.4 1.2v-.9s-1.6-2.3-1.1-3.3A31.63 31.63 0 00153 20l.4-3.2s-3 4-3.8 3.1c-1.3-1.5 3.8-5.7 4.2-10.4.1-.7.2-2.6.2-2.6s-4.4-17.5-6-24.1c-.2-.7-.7-.6-.9-1.6-1.5-7.7-2.2-22.2-2.2-22.2s11.9-3.9 13.8-1.7M159.3 96.5l9.2 21.4-7 3.4-10.9-13.1 8.7-11.7z"
            />
            <path
                className="waiting-room-illustration_svg__Waiting-room-illustration-cls-10"
                d="M164 107.5s-2.1-2.8-5.6-.6a60.07 60.07 0 01-6.1 3.4l-1.7-2 10.1-9.3z"
            />
            <path
                className="waiting-room-illustration_svg__Waiting-room-illustration-cls-5"
                d="M142.2 98.1l-6.7 22.8-10.3-.1 4.1-22.6 12.9-.1z"
            />
            <path
                className="waiting-room-illustration_svg__Waiting-room-illustration-cls-10"
                d="M141.7 99.7l-12.5-1.5-1.3 7.4s3.9-4 7.6-2.8 5.1.7 5.1.7z"
            />
            <path
                fill="#263f77"
                d="M150.3 62.4l11.4 34.1h1.4l-12.5 12.6-9.2-13.7 8.9-33z"
            />
            <path
                className="waiting-room-illustration_svg__Waiting-room-illustration-cls-7"
                d="M128.4 2.8L151.6 52l1.8 2.7-8.9 45-18.9-1.9 7.5-41 2.5-.9-3.6-.2S96.2 21 95.8 12.8s8-34.8 8-34.8a151.61 151.61 0 0127.1 9.3z"
            />
            <path
                className="waiting-room-illustration_svg__Waiting-room-illustration-cls-8"
                d="M160.7 121.2l3.7 6.6a57.85 57.85 0 005.7-2.8c2.6-1.5 10.3-3.7 13.7-5.5s5.3-3.4 5.1-3.7-3.5-.7-6.7.7-9.2 1.6-9.2 1.6-2.4 0-3.1-1.1-9.2 4.2-9.2 4.2M136.1 120.5s-.9 2.7 0 3.4a46.58 46.58 0 008.7 4c1.9.3 5.2 1.4 5.3 2.3s-1.5.9-4.1.7-15-2.1-18.3-2.3-6.8-.3-6.9-.7 2.6-7.4 2.6-7.4zM128.4 3.1h.1a.37.37 0 00.1-.3l-3.1-5.1a.37.37 0 00-.3-.1.37.37 0 00-.1.3l3.1 5.1a.35.35 0 01.2.1M140.5 91.1v-.4l-13.4-1.1v.4l13.4 1.1zM126.7 92.4h8.8V92h-8.8v.4z"
            />
            <path
                className="waiting-room-illustration_svg__Waiting-room-illustration-cls-7"
                d="M128.9 52.6l7.1 7.5 17.1-5L126 1.5l2.9 51.1z"
            />
            <path
                className="waiting-room-illustration_svg__Waiting-room-illustration-cls-8"
                d="M209.7 96.5a40.31 40.31 0 011-5.1 30.16 30.16 0 011.3-2.9c1.5-4 2.6-4.9 3.8-6.8A32.55 32.55 0 01232.1 69a4.76 4.76 0 01.7-.3 1.42 1.42 0 01.6-.2h.3c.7.1.8.5 1.5.5 13.4 0 15.6 6.2 6.4 18.7l-7.9 7.7-17 14.8-2.7-3.3V105a11.4 11.4 0 01-1.5-1.9c-.1-.4-1-2.6-2.8-6.6z"
            />
        </g>
    </svg>
);

WaitingRoomIllustrationSvg.displayName = 'WaitingRoomIllustrationSvg';
export default WaitingRoomIllustrationSvg;
