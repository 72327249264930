import React, { PureComponent } from 'react';
import classNames from 'classnames';

import MultiselectOption from './MultiselectOption';
import MultiselectFooter from './MultiselectFooter';
import SelectAllOption from './SelectAllOption';

export default class ListContainer extends PureComponent {
    /** Build up the list of options with the checkbox that will be visible
     * and styled according to our needs. A change in a checkbox will be
     * linked to the actual component.
     */
    _getOptionsList(values, selectedValues, onItemChange, parentId) {
        return values.map(({ value, display }) => {
            const isSelected = selectedValues.includes(value);

            return (
                <MultiselectOption
                    key={`${parentId}-${value}`}
                    parentId={parentId}
                    value={value}
                    isSelected={isSelected}
                    onItemChange={onItemChange.bind(null, value)}
                    display={display}
                />
            );
        });
    }

    render() {
        const {
            parentId,
            label,
            values,
            selectedValues,
            shouldShowSelectAll,
            shouldFullscreen,
            shouldShowApplyCancelFooter = true,
            onSelectAllChange,
            onItemChange,
            onApplyClick,
            onCancelClick,
            isActive,
            footerContent = null,
        } = this.props;

        let component = null;
        let labelComponent = null;
        let footerComponent = footerContent;

        if (label) {
            const labelClassNames = classNames(
                'eds-field-multiselect__list-title eds-text-hs',
                'eds-l-pad-hor-4',
                {
                    'eds-show-down-sm': !shouldFullscreen,
                },
            );

            labelComponent = (
                <div
                    className={labelClassNames}
                    aria-hidden="true"
                    data-spec="list-container-label"
                >
                    {label}
                </div>
            );
        }

        if (!footerComponent && shouldShowApplyCancelFooter) {
            footerComponent = (
                <MultiselectFooter
                    onCancelClick={onCancelClick}
                    onApplyClick={onApplyClick}
                />
            );
        }

        if (isActive) {
            component = (
                <div className="eds-field-multiselect__list-container">
                    {labelComponent}

                    <div className="eds-field-multiselect__list-wrapper">
                        <SelectAllOption
                            values={values}
                            selectedValues={selectedValues}
                            shouldShowSelectAll={shouldShowSelectAll}
                            onSelectAllChange={onSelectAllChange}
                        />

                        <ul
                            className="eds-field-multiselect__list eds-l-mar-vert-2"
                            id={`listbox-${parentId}-1`}
                        >
                            {this._getOptionsList(
                                values,
                                selectedValues,
                                onItemChange,
                                parentId,
                            )}
                        </ul>
                    </div>

                    {footerComponent}
                </div>
            );
        }

        return component;
    }
}
