import * as React from 'react';

const TrashSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 24 24" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5 6h1v15H5V6zm13 0h1v15h-1V6zM5 21h14v1H5v-1zM15 4V2H9v2H4v1h16V4h-5zm-1 0h-4V3h4v1z"
        />
        <g fillRule="evenodd" clipRule="evenodd">
            <path d="M9 8h1v11H9zM14 8h1v11h-1z" />
        </g>
    </svg>
);

TrashSvg.displayName = 'TrashSvg';
export default TrashSvg;
