import { formatUrl } from 'url-lib';
import { setWindowLocation } from '@eb/http';
import { saveSession } from '../session';
import { getCheckoutWidgetLanguageUrl } from '../api/constants';
import { trackLanguageChange } from './eventAnalytics';

export const CHANGE_LANGUAGE = 'changeLanguage';

export const createUrlWithLanguage = ({
    eventId,
    locale,
    widgetParentUrl,
    isModal,
    affiliateCode,
    waitlistCode,
    useIosProfile,
    useAndroidProfile,
}) => {
    const url = getCheckoutWidgetLanguageUrl(eventId, locale);
    const params = {};

    if (widgetParentUrl) {
        params.parent = widgetParentUrl;
    }
    if (isModal) {
        params.modal = isModal;
    }
    if (affiliateCode) {
        params.aff = affiliateCode;
    }
    if (waitlistCode) {
        params.w = waitlistCode;
    }

    if (useIosProfile) {
        params.profile = 'mobile_ios';
    }

    if (useAndroidProfile) {
        params.profile = 'mobile_android';
    }

    return formatUrl(url, params);
};

export const changeLanguage = (locale, checkoutStep) => (
    dispatch,
    getState,
) => {
    const state = getState();

    const {
        event: { id: eventId },
        app: {
            affiliateCode,
            isModal,
            waitlistCode,
            widgetParentUrl,
            useIosProfile,
            useAndroidProfile,
        },
    } = state;

    dispatch(trackLanguageChange(locale, checkoutStep));

    saveSession(state);

    // Allow all the instructions above to finish running before reload
    setTimeout(() => {
        const urlWithSelectedLanguage = createUrlWithLanguage({
            eventId,
            locale,
            widgetParentUrl,
            isModal,
            affiliateCode,
            waitlistCode,
            useIosProfile,
            useAndroidProfile,
        });

        // Reload the page with the selected language
        setWindowLocation(urlWithSelectedLanguage);
    }, 300);
};
