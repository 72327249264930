import * as React from 'react';

const BancontactPaymentSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 48 32" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                d="M3 31.5C1.6 31.5.5 30.4.5 29V3C.5 1.6 1.6.5 3 .5h42c1.4 0 2.5 1.1 2.5 2.5v26c0 1.4-1.1 2.5-2.5 2.5H3z"
                fill="#FFF"
            />
            <path
                d="M45 1c1.1 0 2 .9 2 2v26c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h42zm0-1H3C1.4 0 0 1.4 0 3v26c0 1.6 1.4 3 3 3h42c1.6 0 3-1.4 3-3V3c0-1.6-1.4-3-3-3z"
                fill="#D2D6DF"
                fillRule="nonzero"
            />
            <path
                d="M37.281 12.77H26.757l-1.075 1.237-3.467 4.001-1.076 1.238H10.763l1.049-1.251.497-.592 1.048-1.251H8.586c-.874 0-1.586.756-1.586 1.664v3.52C7 22.257 7.712 23 8.586 23h18.306c.873 0 2.07-.55 2.648-1.238l2.768-3.217 4.973-5.776z"
                fill="#005498"
                fillRule="nonzero"
            />
            <path
                d="M39.413 9.028c.874 0 1.587.756 1.587 1.663v3.507c0 .921-.713 1.664-1.587 1.664h-4.76l1.063-1.238.524-.619 1.062-1.237H26.774l-5.607 6.463H10.719l7.49-8.663.282-.33C19.082 9.564 20.279 9 21.153 9H39.413v.028z"
                fill="#FFD800"
                fillRule="nonzero"
            />
        </g>
    </svg>
);

BancontactPaymentSvg.displayName = 'BancontactPaymentSvg';
export default BancontactPaymentSvg;
