import grylls from '@eb/grylls';
import { getFormattedDateTime } from '@eb/datetime';
import isEmpty from 'lodash/isEmpty';
import { parseUrl } from 'url-lib';

// Trigger Actions from /myevent/<EID>/tracking
export const VIEW_CONTENT = 'ViewContent';
export const ADD_TO_CART = 'AddToCart';
export const INITIATE_CHECKOUT = 'InitiateCheckout';
export const ADD_PAYMENT_INFO = 'AddPaymentInfo';
export const COMPLETE_REGISTRATION = 'CompleteRegistration';

//triggered when embed loads on external website
export const EVENT_LISTING = 'event_listing';

//triggered on submit of selected tickets
export const EVENT_REGISTER = 'event_register';

//triggered at order confirmation page load
export const EVENT_ORDER_CONFIRMATION = 'event_order_confirmation';

// grylls-specific action
export const COMPLETED_ORDER = 'Completed Order';

export const MAP_TRIGGER_ACTION_TO_EB_EVENT = {
    [VIEW_CONTENT]: EVENT_LISTING,
    [INITIATE_CHECKOUT]: EVENT_REGISTER,
    [COMPLETE_REGISTRATION]: EVENT_ORDER_CONFIRMATION,
};

// Pixel Tracker type
export const FACEBOOK_PIXEL = 'Facebook Pixel';
export const GOOGLE_ANALAYTICS_PIXEL = 'Google Analytics';

// Spoof page title and pathname to match those in old register flow
const _getPageTitleAndPath = (event, gryllsAction) => {
    const {
        name: { text: eventName },
        start: { timezone, utc },
        url,
    } = event;

    const pageTitleAndPath = {
        [EVENT_LISTING]: {
            title: `${eventName} Tickets, ${getFormattedDateTime(
                utc,
                true,
                timezone,
            )} | Eventbrite`,
            path: parseUrl(url).pathname,
        },
        [EVENT_REGISTER]: {
            title: `Eventbrite - ${eventName}`,
            path: '/register',
        },
        [EVENT_ORDER_CONFIRMATION]: {
            title: `You're going to ${eventName}`,
            path: '/orderconfirmation',
        },
    };

    return pageTitleAndPath[gryllsAction];
};
// derive purchased quantities of each individual ticketClassId
const _getTicketQuantity = (attendees, ticketClassId) => {
    const ticketQuantity = 0;

    const attendeesByTicketClassId = attendees.filter(
        (attendee) => attendee.ticketClassId === ticketClassId,
    );

    return attendeesByTicketClassId.reduce(
        (accumulator, currentValue) => accumulator + currentValue.quantity,
        ticketQuantity,
    );
};

const _attendeeClassIdMap = (attendees) =>
    attendees.map((mapObj) => mapObj.ticketClassId);

// create ticket groupings for individual ticketClassIds
const _createTicketGroups = (attendees) => {
    const attendeeClassIdMap = _attendeeClassIdMap(attendees);

    return attendees.filter(
        (attendee, pos) =>
            attendeeClassIdMap.indexOf(attendee.ticketClassId) === pos,
    );
};

/**
 * _getTrackPropertiesForOrder is an internal helper called by
 * _getTrackProperties. It returns data derived from the user's
 * current order suitable for input to calls to grylls.track.
 *
 * @param {object} order is our internal representation of a user's
 *   order from the state store.
 */
const _getTrackPropertiesForOrder = (order, eventName) => {
    if (isEmpty(order)) {
        return {};
    }

    const {
        costs: {
            gross: { majorValue, currency },
        },
        attendees = [],
    } = order;

    const products = _createTicketGroups(attendees).map((attendee) => {
        const {
            costs: {
                gross: { majorValue: price, currency: ticketCurrency },
            },
            ticketClassId: id,
            ticketClassId: sku,
        } = attendee;

        return {
            name: eventName,
            id,
            sku,
            quantity: _getTicketQuantity(attendees, sku),
            price,
            currency: ticketCurrency,
        };
    });

    return {
        products,
        revenue: majorValue,
        currency,
    };
};

/**
 * _getTrackProperties returns an object formatted as the desired
 * input to calls to grylls.track.
 *
 * @param {object} event is our internal representation of an event
 *   from the state store.
 * @param {object} order is our internal representation of a user's
 *   order from the state store.
 */
const _getTrackProperties = (event, order, gryllsAction) => {
    const {
        id: eventId,
        name: { text: eventName },
    } = event;
    const productProperties = _getTrackPropertiesForOrder(order, eventName);

    const trackProperties = {
        [EVENT_LISTING]: {
            eventId,
        },
        [EVENT_REGISTER]: productProperties,
        [EVENT_ORDER_CONFIRMATION]: productProperties,
    };

    return trackProperties[gryllsAction];
};

export const triggerTrackingPixelAction = (triggerAction) => (
    dispatch,
    getState,
) => {
    const { order, event } = getState();

    // Build the properties to pass along for tracking from the order data
    if (triggerAction === COMPLETE_REGISTRATION) {
        const {
            id: orderId,
            costs: {
                gross: { majorValue, currency },
            },
            attendees,
        } = order;
        const {
            name: { text: eventName },
        } = event;
        const products = _createTicketGroups(attendees).map((attendee) => {
            const {
                costs: {
                    gross: { majorValue: price, currency: ticketCurrency },
                },
                /* GA expects a sku (Stock Keeping Unit), a unit often associated with retail items
                 * In our case, ticketClassId most closely corresponds with the definition of a sku
                 */
                ticketClassId: sku,
            } = attendee;

            return {
                name: eventName,
                sku,
                quantity: _getTicketQuantity(attendees, sku),
                price,
                currency: ticketCurrency,
            };
        });

        const trackingData = {
            orderId,
            affiliation: eventName,
            total: majorValue,
            revenue: majorValue,
            currency,
            products,
        };

        // Track `Completed Order` no matter what is custom configured
        // Completed Order is a specific grylls e-commerce named event
        // For Facebook, it maps to 'Purchase' in grylls library
        grylls.track(COMPLETED_ORDER, trackingData);

        /* eslint-disable */
        // EB-113842 AdRoll collect dynamic conversion values
        window.adroll_conversion_value = majorValue;
        window.adroll_currency = currency;
        /* eslint-enable */

        // Track via EB GA account
        if (typeof window.ga === 'function') {
            const ga = window.ga;

            ga('require', 'ecommerce');

            let gaTxn = {
                id: orderId.toString(),
                affiliation: eventName,
                revenue: majorValue,
                currency,
            };

            // Check wether we need to track this purchase on GA for Youtube integration
            const localStorageKey = 'eb_youtube_events';
            const gaEventId = event.id;
            let eventsToLogString;
            let eventsToLog = [];
            let youtubeParams = {};

            try {
                eventsToLogString = localStorage.getItem(localStorageKey);
            } catch (err) {
                // No access to local storage, do nothing.
            }

            if (eventsToLogString) {
                eventsToLog = JSON.parse(eventsToLogString);
            }
            // If this event was previously accessed through Youtube, track it and delete it for future purchases
            const gaEventIdIndex = eventsToLog.indexOf(gaEventId);

            if (gaEventIdIndex > -1) {
                youtubeParams = {
                    dimension36: 'true',
                    dimension37: gaEventId,
                };
                eventsToLog.splice(gaEventIdIndex, 1);

                if (eventsToLog.length) {
                    localStorage.setItem(
                        localStorageKey,
                        JSON.stringify(eventsToLog),
                    );
                } else {
                    localStorage.removeItem(localStorageKey);
                }
                gaTxn = {
                    ...gaTxn,
                    ...youtubeParams,
                };
            }

            ga('ecommerce:addTransaction', gaTxn);
            products.forEach((product) => {
                const { sku, name, quantity, price } = product;

                const eventTerminology = event.terminology || {};

                const gaItem = {
                    id: orderId.toString(),
                    sku,
                    name,
                    category: eventTerminology.ticket || 'Ticket',
                    price: price.toString(),
                    quantity: quantity.toString(),
                    currency,
                    ...youtubeParams,
                };

                ga('ecommerce:addItem', gaItem);
            });

            ga('ecommerce:send');
        }
    }

    // For triggers specific to checkout widget flow, track the corresponding event
    // NOTE: also disregards any custom configuration in favor of standardization
    if (Object.keys(MAP_TRIGGER_ACTION_TO_EB_EVENT).includes(triggerAction)) {
        const gryllsAction = MAP_TRIGGER_ACTION_TO_EB_EVENT[triggerAction];
        const pageProperties = _getPageTitleAndPath(event, gryllsAction);
        const trackProperties = _getTrackProperties(event, order, gryllsAction);

        grylls.page(pageProperties);
        grylls.track(gryllsAction, trackProperties);
    }
};

const _gryllsInitialized = () =>
    grylls.analyticsjs && grylls.analyticsjs.initialized;

export const initializeGrylls = () => (dispatch, getState) => {
    const {
        app: {
            gryllsTrackingData,
            gryllsOptions,
            isEventbriteTLD,
            featureFlags: { launchSupportMultipleFacebookPixels },
        },
    } = getState();

    const gryllsInitialized = _gryllsInitialized();

    gryllsOptions.launchSupportMultipleFacebookPixels = launchSupportMultipleFacebookPixels;

    if (!gryllsInitialized) {
        grylls.load(gryllsTrackingData, gryllsOptions);

        // Don't fire the pixels if the embed is loading on an Eventbrite
        // domain, this will cause double firing of the pixel.
        if (!isEventbriteTLD) {
            dispatch(triggerTrackingPixelAction(VIEW_CONTENT));
        }
    }
};
