import React, { Component, PureComponent } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';

export default class Debounced extends PureComponent {
    static propTypes = {
        callbacksToDebounce: PropTypes.arrayOf(PropTypes.string),
        debounceTime: PropTypes.number,
        wrappedComponent: PropTypes.oneOfType([
            PropTypes.shape(Component),
            PropTypes.func,
        ]).isRequired,
    };

    static defaultProps = {
        callbacksToDebounce: [],
        debounceTime: 500,
    };

    render() {
        const {
            callbacksToDebounce,
            debounceTime,
            wrappedComponent,
            ...restProps
        } = this.props;

        const debouncedCallbacks = callbacksToDebounce.reduce(
            (obj, key) => ({
                ...obj,
                [key]: debounce(this.props[key], debounceTime),
            }),
            {},
        );

        return React.createElement(this.props.wrappedComponent, {
            ...restProps,
            ...debouncedCallbacks,
        });
    }
}
