import * as React from 'react';

const MoneySvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 24 24" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#231F20"
            d="M3 18h18V6H3v12zm-1 1h20V5H2v14z"
        />
        <g fillRule="evenodd" clipRule="evenodd" fill="#231F20">
            <path d="M17 8v1h1v6h-1v1h2V8zM5 9v7h2v-1H6V9h1V8H5z" />
        </g>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#231F20"
            d="M12 8c-2.2 0-4 1.8-4 4s1.8 4 4 4 4-1.8 4-4-1.8-4-4-4zm0 7c-1.7 0-3-1.3-3-3s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3z"
        />
    </svg>
);

MoneySvg.displayName = 'MoneySvg';
export default MoneySvg;
