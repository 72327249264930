import * as React from 'react';

const VisaPaymentSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 48 32" {...props}>
        <path
            fill="#FFF"
            d="M0 3c0-1.6 1.4-3 3-3h42c1.7 0 3 1.4 3 3v26c0 1.6-1.3 3-3 3H3c-1.6 0-3-1.4-3-3V3z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#26337A"
            d="M38.4 20.9h2.5l-2.1-10.3h-2.2c-1 0-1.2.8-1.2.8l-4 9.5h2.8l.6-1.5h3.4l.2 1.5zm-3-3.6l1.4-3.9.8 3.9h-2.2zm-3.9-4.2c-1.1-.6-3.8-.8-3.8.4 0 1.2 3.7 1.4 3.7 4.1 0 2.6-2.8 3.5-4.6 3.5-1.8 0-3-.6-3-.6l.4-2.3c1.1.9 4.4 1.1 4.4-.3 0-1.4-3.7-1.4-3.7-4.1 0-2.8 3.2-3.4 4.5-3.4 1.2 0 2.4.4 2.4.4l-.3 2.3m-9.3 7.8h-2.7l1.7-10.3h2.7l-1.7 10.3m-4.9-10.3h2.9l-4.3 10.3h-2.8l-2.3-9C9.3 11.1 8 10.8 8 10.8v-.2h4.2c1.2 0 1.3.9 1.3.9l.9 4.6.3 1.5 2.6-7"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#26337A"
            d="M14.4 16.2l-.9-4.6s-.1-.9-1.3-.9H8v.2s2 .4 4 2c1.8 1.4 2.4 3.3 2.4 3.3"
        />
    </svg>
);

VisaPaymentSvg.displayName = 'VisaPaymentSvg';
export default VisaPaymentSvg;
