import React from 'react';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';

import gettext from '@eb/gettext';
import { getSelectPropValues } from '@eb/eds-select';
import { SelectField } from '@eb/eds-input-field';
import { STYLE_BASIC, BORDER_HIDDEN } from '@eb/eds-input-field';

const getLanguages = (locales) => {
    const unsupportedLocales = [
        'en_IE',
        'en_NZ',
        'en_HK',
        'en_SG',
        'de_AT',
        'fr_BE',
        'nl_BE',
        'es_PE',
        'es_CL',
        'es_CO',
    ];

    return sortBy(
        // Don't display locales that aren't supported on Event Type/Language
        filter(locales, ({ locale }) => !unsupportedLocales.includes(locale)),
        'localized_language_name',
    );
};

export const LanguageSelector = ({ defaultLocale, locales, onChange }) => (
    <SelectField
        id="language-select-field"
        borderType={BORDER_HIDDEN}
        values={getSelectPropValues(
            getLanguages(locales),
            'localized_language_name',
            'locale',
        )}
        label={gettext('Select your language')}
        defaultValue={defaultLocale}
        onChange={onChange}
        data-automation="language-dropdown"
        name="language-select-field"
        style={STYLE_BASIC}
    />
);
