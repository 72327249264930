import countBy from 'lodash/countBy';
import groupBy from 'lodash/groupBy';
import { createSelector } from './utils/selector';
import { getAttendees, isTransferredOrder } from './order';
import { TICKET_CLASS_CATEGORIES_BY_ORDER } from '../constants';

/**
 * Returns an object with selected delivery methods as keys and the total quantity of
 * tickets for each of those delivery methods as values
 * Example object returned: {'electronic': 1, 'will_call': 5}
 *
 * @param state state object includes {order: {attendees}}
 * @returns {Object}
 */
export const getAttendeeCountGroupBySelectedDeliveryMethod = createSelector(
    getAttendees,
    (attendees) => countBy(attendees, 'deliveryMethod'),
);

export const getAttendeeDeliveryMethodsPayloadForPlacingOrder = createSelector(
    isTransferredOrder,
    getAttendees,
    (isTransferredOrder, attendees) =>
        isTransferredOrder
            ? null
            : attendees.map(({ id, deliveryMethod }) => ({
                  attendee_id: id,
                  delivery_method: deliveryMethod,
              })),
);

export const getAttendeesSortedByTicketCategory = createSelector(
    getAttendees,
    (attendees) => {
        const groupAttendeesByTicketClassCategory = groupBy(
            attendees,
            (attendee) => attendee.ticketClass.category,
        );

        return TICKET_CLASS_CATEGORIES_BY_ORDER.reduce((acc, category) => {
            if (groupAttendeesByTicketClassCategory[category]) {
                acc.push(...groupAttendeesByTicketClassCategory[category]);
            }

            return acc;
        }, []);
    },
);
