import { splitByCommaOrSpace } from '@eb/string-utils';
import { makeMatchesRegex } from './general';
import { SingleValueValidator } from './types';

// eslint-disable-next-line no-useless-escape, no-control-regex
const EMAIL_USER_RE = /^[-!#$%&'*+/=?^`{}|~\w]+(\.[-!#$%&'*+/=?^`{}|~\w]+)*|^"([\u0001-\u0008\u000B\u000C\u000E-\u001F!#-\[\]-\u007F ]|\\[\u0001-\u0009\u000B\u000C\u000E-\u007F])*"/i;

// eslint-disable-next-line no-useless-escape
const EMAIL_DOMAIN_RE = /(?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\.)+(?:[A-Z]{2,6}|[A-Z0-9-]{2,})$|\[(25[0-5]|2[0-4]\d|[0-1]?\d?\d)(\.(25[0-5]|2[0-4]\d|[0-1]?\d?\d)){3}\]$/i;
const EMAIL_RE = new RegExp(
    `(${EMAIL_USER_RE.source})@(${EMAIL_DOMAIN_RE.source})`,
    'i',
);

/**
 * Validator: Test that a value is a valid email address.
 */
export const isAnEmail = makeMatchesRegex<string>(EMAIL_RE);

/**
 * Validator: Test that a value is a comma or space separated list of email addresses.
 *
 * @returns is str a valid list of emails
 */
export const isStringListOfEmails: SingleValueValidator<string> = (str) => {
    const emails = splitByCommaOrSpace(str ?? '');

    return emails.every(isAnEmail);
};
