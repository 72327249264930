import {
    SAVE_GROUP_REGISTRATION_INDIVIDUAL_SETTINGS,
    SAVE_GROUP_REGISTRATION_TEAM_SETTINGS,
    RESET_GROUP_REGISTRATION,
    RESET_IS_NEWLY_CREATED_GROUP,
} from '../actions/groupRegistration';

export const groupRegistrationReducer = (state = {}, action) => {
    switch (action.type) {
        case SAVE_GROUP_REGISTRATION_INDIVIDUAL_SETTINGS:
            return {
                ...state,
                isIndividualSelected: action.isIndividualSelected,
            };

        case SAVE_GROUP_REGISTRATION_TEAM_SETTINGS:
            return {
                ...state,
                teamSettings: {
                    ...action.payload,
                },
            };

        case RESET_GROUP_REGISTRATION:
            return {};

        case RESET_IS_NEWLY_CREATED_GROUP:
            return {
                ...state,
                teamSettings: {
                    ...state.teamSettings,
                    isNewlyCreatedGroup: false,
                },
            };

        default:
            return state;
    }
};
