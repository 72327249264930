import flowRight from 'lodash/flowRight';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PoweredByEventbriteText from '../components/poweredByEventbriteText/PoweredByEventbriteText';
import { trackBrandClick } from '../actions/eventAnalytics';
import { getCheckoutStepFromPath } from '../utils/analytics';

export default flowRight(
    withRouter,
    connect(
        (state) => ({
            locale: state.app.locale,
        }),
        (dispatch, { router }) => ({
            onClick: () =>
                dispatch(
                    trackBrandClick(
                        getCheckoutStepFromPath(
                            router.getCurrentLocation().pathname,
                        ),
                    ),
                ),
        }),
    ),
)(PoweredByEventbriteText);
