import momentTimezone from 'moment-timezone';

import { moment } from '@eb/date';
import gettext from '@eb/gettext';
import { lazyGettext } from '@eb/lazy-gettext';

import {
    DEFAULT_DATETIME_FORMAT,
    DEFAULT_LANGUAGE,
    DEFAULT_TIMEZONE,
    ISO_8601_FORMAT,
    ISO_8601_DATE_FORMAT,
    NUMERAL_DATE_FORMAT,
    FULL_TIME_FORMAT,
    DEFAULT_TIME_FORMAT,
} from './constants';

/**
 * formatDate
 *
 * A transformation a date string or object into the string format expected by DatePicker.
 * NOTE: This will strip any time information.
 *
 * @param {Date} date
 * @return {string}
 */
export const formatDate = (date) =>
    moment(date, ISO_8601_DATE_FORMAT).format(ISO_8601_DATE_FORMAT);

/**
 * getFormattedTimezone
 *
 * Returns a string representing a formatted timezone.
 * If no dateTime is passed, moment.js will use execution time
 *
 * @param {String} timezone -- required
 * @param {String} dateTime
 **/
export const getFormattedTimezone = (timezone, dateTime) =>
    momentTimezone.tz(dateTime, timezone).format('z');

/**
 * getFormattedDateTime
 *
 * Returns a string representing a singular datetime formatted for a given
 * timezone. If no timezone is provided, use the default timezone. If showTimezone
 * is false, do not display the timezone as part of the datetime.
 *
 * Note: In the future, instead of a default timezone it may be better to use
 * the browser's timezone. Moment Timezone 0.5.0 has a function momentTimezone.tz.guess(),
 * which we could use if we upgrade the library. (EB-43817)
 *
 * @param  {String} dateTime
 * @param  {Boolean} showTimezone
 * @param  {String} timezone
 * @param  {String} locale
 * @param  {String} dateTimeFormat
 *
 */

export const getFormattedDateTime = (
    dateTime,
    showTimezone,
    timezone = DEFAULT_TIMEZONE,
    locale = DEFAULT_LANGUAGE,
    dateTimeFormat = DEFAULT_DATETIME_FORMAT,
) => {
    const dateTimeAsMoment = momentTimezone
        .tz(dateTime, timezone)
        .locale(locale);

    let formattedDateTime = '';

    // Example return value: 'Tue, Feb 28, 7:00 PM EST' or 'Tue, Feb 28, 7:00 PM'
    if (!showTimezone) {
        formattedDateTime = gettext('%(formattedDateTime)s', {
            formattedDateTime: dateTimeAsMoment.format(dateTimeFormat),
        });
    } else {
        formattedDateTime = gettext('%(formattedDateTime)s %(timezone)s', {
            formattedDateTime: dateTimeAsMoment.format(dateTimeFormat),
            timezone: getFormattedTimezone(timezone, dateTime),
        });
    }

    return formattedDateTime;
};

/**
 * Takes a date string like "YYYY-MM-DD" and returns an object with moment formatted dates as such:
 * @param  {String} date        date string of YYYY-MM-DD
 * @param  {String} dayFormat   optional parameter which allows for customization of the returned format
 * @param  {String} monthFormat optional parameter which allows for customization of the returned format
 * @param  {String} yearFormat  optional parameter which allows for customization of the returned format
 * @return {Object}             Object with day, month and year as keys. Default params {month: 'Apr', day: '10', year: '1999'}
 */
export const parseDate = (
    date,
    dayFormat = 'D',
    monthFormat = 'MMM',
    yearFormat = 'YYYY',
) => {
    const momentDate = moment(date);
    const day = momentDate.format(dayFormat);
    const month = momentDate.format(monthFormat);
    const year = momentDate.format(yearFormat);

    return { day, month, year };
};

/**
 * Combine a date and time, and return a Moment object that
 * represents the two.
 *
 * @props {Date[object] || Date[Moment] || String}, date - date as
 * Date object or Moment object or date-like string
 * @props {Date[object] || Date[Moment] || String}, time - time as
 * Date object or Moment object or date-like string
 * @return {import('moment').Moment}, dateTime Moment object or null if either param is invalid
 */
export const getDateTimeAsMoment = (date, time) => {
    if (!date || !time) {
        return null;
    }

    let dateMoment;

    let timeMoment;

    if (date instanceof Date) {
        dateMoment = date.toISOString();
        dateMoment = dateMoment.substr(0, dateMoment.indexOf('T'));
        dateMoment = moment(dateMoment, ISO_8601_DATE_FORMAT);
    } else {
        dateMoment = moment(date, ISO_8601_DATE_FORMAT);
    }

    if (time instanceof Date) {
        timeMoment = moment(time);
    } else {
        timeMoment = moment(time, FULL_TIME_FORMAT);
    }

    if (!dateMoment.isValid() || !timeMoment.isValid()) {
        return null;
    }

    const dateString = dateMoment.format(ISO_8601_DATE_FORMAT);
    const timeString = timeMoment.format(FULL_TIME_FORMAT);

    return moment(
        `${dateString}T${timeString}`,
        `${ISO_8601_DATE_FORMAT}T${FULL_TIME_FORMAT}`,
    );
};

/**
 * formatForAPI
 *
 * A transformation for date & time into the string format that the V3 API expects for dates.
 *
 * @param {Date|import('moment').Moment} date
 * @param {String} time
 * @return {string}
 */
export const formatForAPI = (date, time, timeZone) => {
    const dateTimeMoment = getDateTimeAsMoment(date, time);

    if (!dateTimeMoment) {
        return '';
    }

    const dateTimeString = dateTimeMoment.format(ISO_8601_FORMAT);

    return momentTimezone
        .tz(dateTimeString, ISO_8601_FORMAT, timeZone)
        .toISOString()
        .replace(/\.\d+/, '');
};

/**
 * formatFromAPI
 *
 * A transformation that takes a UTC dateTime string from the API, and converts
 * it to the specified time zone.
 *
 * @param {String} dateTimeString
 * @param {String} timeZone
 * @return {String}
 */
export const formatFromAPI = (dateTimeString, timeZone) =>
    momentTimezone
        .utc(dateTimeString, ISO_8601_FORMAT)
        .tz(timeZone)
        .format(ISO_8601_FORMAT);

// Translators: This is showing a formatted date. For example "Oct 21"
const SHORT_DATE_FORMAT = lazyGettext('%(month)s %(dayOfMonth)s', {
    month: 'MMM',
    dayOfMonth: 'D',
});

// Translators: This is showing a fully formatted date. For example "August 27, 2019"
const FULL_DATE_FORMAT = lazyGettext('%(month)s %(dayOfMonth)s, %(year)s', {
    month: 'MMMM',
    dayOfMonth: 'D',
    year: 'YYYY',
});

// Translators: This is showing a fully formatted date. For example "Wednesday, August 27, 2019"
const FULL_DATE_WEEKDAY_FORMAT = lazyGettext(
    '%(dayOfWeek)s, %(month)s %(dayOfMonth)s, %(year)s',
    {
        dayOfWeek: 'dddd',
        month: 'MMMM',
        dayOfMonth: 'D',
        year: 'YYYY',
    },
);

/**
 * ISO_8601ToDateTime
 *
 * Separates an ISO_8601 string to its individual date and time parts
 *
 * @param {string} isoString
 * @return {Object}
 */
export const iso8601ToDateTime = (isoString) => ({
    date: moment(isoString, ISO_8601_FORMAT),
    time: moment(isoString, ISO_8601_FORMAT).format(DEFAULT_TIME_FORMAT),
});

/**
 * @typedef {Object} FormattedDateTime
 * @property {string} fullDate -  The date formatted in full form (e.g. August 27, 2019)
 * @property {string} fullDateWeekday -  The date formatted in full form with the day of the week (e.g. Wednesday, August 27, 2019)
 * @property {string} numeralDate - The date formatted in numeral form (e.g. 10/21/19)
 * @property {string} shortDate -  The date formatted in short form (e.g. Sat. Oct 21)
 * @property {string} time - The formatted time (e.g. 8:00 PM PST)
 * @property {string} shortTime - The time formatted in short form w/o timezone (e.g. 7:00 PM)
 * @property {string} shortDateTime - The date time formatted in short form (e.g. Sat. Oct 21, 8:00 PM PST)
 */
/**
 * Converts a date object returned in an API response into several useful date and time formats by parsing the datetime string into the current locale
 *
 * @param {Object} apiDate - Date object returned in an API response
 * @param {string} apiDate.utc - The datetime in UTC
 * @param {string} apiDate.timezone - The timezone of the local datetime to format into
 * @param {string} [apiDate.local] - The datetime in the local timezone
 * @returns {FormattedDateTime} An object containing several useful date and time formats
 */
export const getAPIDateTimeFormats = ({ utc, timezone }) => {
    const { date, time } = iso8601ToDateTime(formatFromAPI(utc, timezone));
    const momentDate = moment(date);
    const shortDate = momentDate.format(SHORT_DATE_FORMAT.toString());
    const fullDate = momentDate.format(FULL_DATE_FORMAT.toString());
    const fullDateWeekday = momentDate.format(
        FULL_DATE_WEEKDAY_FORMAT.toString(),
    );
    const numeralDate = momentDate.format(NUMERAL_DATE_FORMAT);
    const formattedTimezone = momentDate.tz(timezone).format('z');

    // Translators: This shows a time with its timezone. For example "7:00 PM PDT"
    const timeWithTimezone = gettext('%(time)s %(timezone)s', {
        time,
        timezone: formattedTimezone,
    });

    // Translators: This is a date and time together. For example: "Oct 21, 8:00 PM PST"
    const shortDateTime = gettext('%(date)s, %(time)s', {
        time: timeWithTimezone,
        date: shortDate,
    });

    return {
        fullDate,
        fullDateWeekday,
        numeralDate,
        shortDate,
        shortDateTime,
        shortTime: time,
        time: timeWithTimezone,
    };
};

/**
 * calculateDuration
 *
 * Calculate event duration in the specified unit, defaults as minutes
 *
 * @param {Object} options
 * @param {import('moment').Moment} options.startDate
 * @param {String} options.startTime
 * @param {import('moment').Moment} options.endDate
 * @param {String} options.endTime
 * @param {import('moment').unitOfTime.Diff} options.unit
 * @return {Number}, an event's duration as minutes
 */
export const calculateDuration = ({
    startDate,
    startTime,
    endDate,
    endTime,
    unit = 'minutes',
}) => {
    const startDateTimeMoment = getDateTimeAsMoment(startDate, startTime);
    const endDateTimeMoment = getDateTimeAsMoment(endDate, endTime);

    if (!startDateTimeMoment || !endDateTimeMoment) {
        return 0;
    }

    return endDateTimeMoment.diff(startDateTimeMoment, unit);
};

/**
 * createMomentWithTz
 *
 * Returns a moment object formatted with the passed in timezone. If no specific
 * time is passed in then it creates a moment object of the current time at the
 * designated timezone.
 *
 * @param {String} timezone -- required
 * @param {String} datetime
 **/
export const createMomentWithTz = (timezone, datetime) => {
    let momentObject = momentTimezone().tz(timezone);

    if (datetime) {
        momentObject = momentTimezone.tz(datetime, timezone);
    }

    return momentObject;
};

/**
 * formatTimeForLocales
 *
 * A transformation a time string into the string format expected by TimePicker.
 * The parameter is expected to be in 24 hour formatted.
 *
 * @param {String} time
 * @return {string}
 */
export const formatTimeForLocales = (time) =>
    moment(time, DEFAULT_TIME_FORMAT).format(DEFAULT_TIME_FORMAT);
