import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { translationPropType } from '@eb/i18n';
import classNames from 'classnames';
import gettext from '@eb/gettext';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router';

import { Icon } from '@eb/eds-icon';
import { MoreActions } from '@eb/eds-more-actions';

import {
    TICKET_STATUS_MAP,
    STATUSKEY_PROP_TYPE,
    SALES_ENDED_STATUS,
} from './ticketStatusConstants';
import { TEXT_ITEMS_PROP_TYPE } from '@eb/eds-text-list';

import { isSalesEndPropKey } from './utils';
import './ticketCardContent.scss';

import { EyeOffChunky } from '@eb/eds-iconography';

const HiddenTicketStatus = ({ isHiddenType }) => {
    if (!isHiddenType) {
        return null;
    }

    return (
        <div className="eds-g-cell">
            <span className="eds-ticket-card-content__spacer eds-show-down-sw" />
            <span className="eds-l-mar-left-2 eds-text-color--grey-600 eds-l-mar-right-1 eds-show-up-mn">
                <Icon color="grey-600" type={<EyeOffChunky />} size="xsmall" />
            </span>
            <p className="eds-g-cell">{gettext('Hidden')}</p>
        </div>
    );
};

const TicketCardStatusIcon = ({ statusKey }) => {
    const statusInfo = TICKET_STATUS_MAP[statusKey];
    const ticketStatusIcon = (
        <span
            className={`eds-show-up-mn eds-ticket-card-status-icon eds-text-color--${statusInfo.iconColor} eds-l-mar-right-2`}
        />
    );

    return ticketStatusIcon;
};

const TicketCardStatus = ({
    statusKey,
    salesEnd,
    salesStart,
    quantity,
    isHiddenType,
}) => {
    if (!statusKey) {
        return null;
    }

    const statusInfo = TICKET_STATUS_MAP[statusKey];
    const displayStatus = statusInfo.displayStatus;
    const datePropKey = statusInfo.datePropKey;
    const salesEndPropKey = isSalesEndPropKey(statusInfo.datePropKey);
    let ticketStatusComponent = null;
    let displayStatusContent;
    let displayText;

    const ticketStatusClasses = classNames(
        'eds-g-cell',
        'eds-ticket-edit-card-content__status',
    );

    if (datePropKey) {
        displayText = salesEndPropKey ? salesEnd : salesStart;
    }

    if (displayText) {
        if (datePropKey === SALES_ENDED_STATUS) {
            displayStatusContent = (
                <p className={ticketStatusClasses}>
                    <span className="eds-show-up-mn">{displayText}</span>
                </p>
            );
        } else {
            displayStatusContent = (
                <p className={ticketStatusClasses}>
                    {displayStatus}
                    <span className="eds-show-up-mn">
                        <Icon
                            type={statusInfo.iconType}
                            color="grey-600"
                            size="xsmall"
                        />
                        {displayText}
                    </span>
                </p>
            );
        }
    } else {
        displayStatusContent = (
            <p className={ticketStatusClasses}>{displayStatus}</p>
        );
    }

    ticketStatusComponent = (
        <div
            className="eds-g-cell eds-text-bm--fixed eds-text-color--grey-600"
            data-spec="eds-ticket-card-status"
        >
            <TicketCardStatusIcon statusKey={statusKey} />
            {displayStatusContent}
            <HiddenTicketStatus isHiddenType={isHiddenType} />
            <p className="eds-show-down-sw">{quantity}</p>
        </div>
    );

    return ticketStatusComponent;
};

const BottomContent = ({ content }) => {
    if (!content) {
        return null;
    }

    return <span data-spec="eds-ticket-card-bottom-content">{content}</span>;
};

const _getQuantity = (total, sold, separator = '/') => {
    if (sold || sold === 0) {
        return `${sold} ${separator} ${total}`;
    }

    return `${total}`;
};

const _getMoreActions = (
    moreActionItems,
    onAction,
    onActionsVisibilityToggle,
) => {
    if (isEmpty(moreActionItems)) {
        return null;
    }

    return (
        <div className="eds-g-cell eds-g-cell-1-12 eds-l-mar-top-1 eds-g-cell--has-overflow">
            <div className="eds-text--right">
                <MoreActions
                    items={moreActionItems}
                    onAction={onAction}
                    align="right"
                    onToggleVisibility={onActionsVisibilityToggle}
                />
            </div>
        </div>
    );
};

const TicketCardContent = ({
    name,
    statusKey,
    salesStart,
    salesEnd,
    isHiddenType,
    quantity,
    displayPrice,
    url,
    bottomContent,
    isBottomContent,
    isCompact,
}) => {
    const ticketCardContentBaseClasses = classNames(
        'eds-g-cell',
        'eds-g-cell-1-1',
        {
            'eds-align--center': !isBottomContent,
        },
    );
    const ticketNumberSection = classNames({
        'eds-l-mar-top-2 eds-l-sn-mar-top-4 eds-l-sm-mar-top-4 eds-l-sw-mar-top-4': isBottomContent,
    });

    const titleClassNames = classNames('eds-text-color--grey-700', {
        'eds-text-bl--fixed': !isCompact,
        'eds-text-bm--fixed': isCompact,
        'eds-text-weight--heavy': isCompact,
    });

    const ticketCardContentClasses = `${ticketCardContentBaseClasses}  eds-ticket-card-content__details-wrapper`;
    const ticketCardContentDetailsClasses = `${ticketCardContentBaseClasses} eds-ticket-card-content__details`;
    const content = (
        <div className={ticketCardContentDetailsClasses}>
            <div className="eds-l-pad-right-4 eds-l-pad-left-3 eds-g-cell eds-g-cell-8-12">
                <h3
                    className={titleClassNames}
                    data-spec="eds-ticket-card-name"
                >
                    {name}
                </h3>
                <TicketCardStatus
                    statusKey={statusKey}
                    salesStart={salesStart}
                    salesEnd={salesEnd}
                    isHiddenType={isHiddenType}
                    quantity={quantity}
                />
                <BottomContent content={bottomContent} />
            </div>
            <div className="eds-g-cell eds-g-cell-2-12 eds-show-up-mn">
                <div className={ticketNumberSection}>
                    <p
                        className="eds-text-bm--fixed eds-text-color--grey-700 eds-text--right"
                        data-spec="eds-ticket-card-quantity"
                    >
                        {quantity}
                    </p>
                </div>
            </div>
            <div className="eds-g-cell eds-g-cell-lw-2-12 eds-g-cell-lg-2-12 eds-g-cell-ln-2-12 eds-g-cell-mw-2-12 eds-g-cell-md-2-12 eds-g-cell-mn-2-12 eds-g-cell-4-12">
                <div className={ticketNumberSection}>
                    <p
                        className="eds-text-bm--fixed eds-text-color--grey-700 eds-text--right"
                        data-spec="eds-ticket-card-display-price"
                    >
                        {displayPrice}
                    </p>
                </div>
            </div>
        </div>
    );

    if (url) {
        return (
            <Link className={ticketCardContentClasses} to={url}>
                {content}
            </Link>
        );
    }

    return <div className={ticketCardContentClasses}>{content}</div>;
};

export default class TicketEditCardContent extends PureComponent {
    static propTypes = {
        /**
         * Example prop: ID of select component
         */
        id: PropTypes.string.isRequired,
        /**
         * The name of the ticket to be displayed
         **/
        name: PropTypes.string.isRequired,
        /**
         * A key tied to the ticketStatus map located in ticketStatusConstants.js. If nothing is passed in, then
         * no status will be displayed. Otherwise will render the associated status and also hide the
         * appropriate components as well.
         **/
        statusKey: STATUSKEY_PROP_TYPE,
        /**
         * A string representing the price of the ticket. Should include any necessary currency
         * information as well.
         **/
        displayPrice: PropTypes.oneOfType([
            PropTypes.string,
            translationPropType,
        ]).isRequired,
        /**
         * Total ticket quantity available
         **/
        totalQuantity: PropTypes.oneOfType([
            PropTypes.number,
            translationPropType,
        ]).isRequired,
        /**
         * Total sold ticket quantity
         **/
        soldQuantity: PropTypes.number,
        /**
         * Ticket visibility status e.g. hidden
         **/
        isHiddenType: PropTypes.bool,
        /**
         * The end datetime for ticket sales to be shown. Only required when certain statuses need
         * to be rendered.
         **/
        salesEnd: translationPropType,
        /**
         * The start datetime for ticket sales. Only required when rendering a status that needs the
         * date.
         **/
        salesStart: translationPropType,
        /**
         * URL linking to a ticket edit view
         **/
        url: PropTypes.string,
        /**
         * State of ticket being selected
         **/
        isSelected: PropTypes.bool,
        /**
         * Ticket action items (Array of items) e.g. delete, copy
         **/
        actionItems: TEXT_ITEMS_PROP_TYPE,
        /**
         * Callback function that will be called when a action item is selected
         * (value, index) => {}
         */
        onAction: PropTypes.func,
        /**
         * Custom content to be added at the beginning of the component
         **/
        prependContent: PropTypes.node,
        /**
         * Custom content to be added at the bottom of the component
         **/
        bottomContent: PropTypes.node,
        /**
         * Background color
         **/
        backgroundColor: PropTypes.string,
        /**
         * Use compact card styles
         **/
        isCompact: PropTypes.bool,
    };

    static defaultProps = {
        isSelected: false,
    };

    state = {
        actionsVisible: false,
    };

    _getClassNames() {
        const { isSelected, bottomContent, backgroundColor } = this.props;
        const { actionsVisible } = this.state;
        const isBottomContent = Boolean(bottomContent);
        const backgroundColorPrefix = backgroundColor
            ? `eds-bg-color--${backgroundColor}`
            : null;

        return classNames(
            'eds-ticket-card-content',
            'eds-ticket-edit-card-content',
            backgroundColorPrefix,
            {
                'eds-ticket-card-content--selected': isSelected,
                'eds-align--center': !isBottomContent,
                'eds-ticket-edit-card-actions-visible': actionsVisible,
            },
        );
    }

    _onActionsVisibilityToggle = (isVisible) => {
        this.setState({
            actionsVisible: isVisible,
        });
    };

    render() {
        const {
            name,
            statusKey,
            salesStart,
            salesEnd,
            isHiddenType,
            displayPrice,
            soldQuantity,
            totalQuantity,
            actionItems,
            onAction,
            url,
            prependContent,
            bottomContent,
            isCompact,
        } = this.props;
        const isBottomContent = Boolean(bottomContent);
        const quantity = _getQuantity(totalQuantity, soldQuantity);
        const ticketCardClassNames = this._getClassNames();
        const prependContentClasses = classNames({
            'eds-l-mar-top-8': isBottomContent,
        });
        const actionItemsClasses = classNames({
            'eds-l-mar-top-5': isBottomContent,
        });

        return (
            <div
                className={ticketCardClassNames}
                data-spec="eds-ticket-card-content"
            >
                <div
                    className={prependContentClasses}
                    data-spec="eds-ticket-card-prepend-content"
                >
                    {prependContent}
                </div>
                <TicketCardContent
                    name={name}
                    statusKey={statusKey}
                    salesStart={salesStart}
                    salesEnd={salesEnd}
                    isHiddenType={isHiddenType}
                    quantity={quantity}
                    displayPrice={displayPrice}
                    url={url}
                    bottomContent={bottomContent}
                    isBottomContent={isBottomContent}
                    isCompact={isCompact}
                />
                <div
                    className={actionItemsClasses}
                    data-spec="eds-ticket-card-action-items"
                >
                    {_getMoreActions(
                        actionItems,
                        onAction,
                        this._onActionsVisibilityToggle,
                    )}
                </div>
            </div>
        );
    }
}
