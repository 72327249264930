import React from 'react';
import PropTypes from 'prop-types';
import { CardList } from '@eb/eds-card-list';
import { CARD_STYLE_SIMPLE } from '@eb/eds-card';
import TicketDisplayCardContent from './TicketDisplayCardContent';
import DisplayContentWithMultipleInputVariants from './DisplayContentWithMultipleInputVariants';
import { DATA_PROP_TYPES_SHAPE, INVENTORY_TIER_TYPE_SHAPE } from './constants';
import { isMultipleVariantType } from '../../containers/ticketSelection/utils/ticketUtils';

export default class TicketsSelectionList extends React.PureComponent {
    static propTypes = {
        /**
         * Function to call when ticket quantity changes.
         */
        onQuantityChange: PropTypes.func.isRequired,
        /**
         * Function to call when donation changes.
         */
        onDonationChange: PropTypes.func,
        /**
         * Array of ticket data.
         */
        tickets: PropTypes.arrayOf(
            PropTypes.oneOfType([
                DATA_PROP_TYPES_SHAPE,
                INVENTORY_TIER_TYPE_SHAPE,
            ]),
        ).isRequired,
        /**
         * If true, the status on each TicketDisplayCardContent component will be hidden.
         */
        shouldHideStatusOnTickets: PropTypes.bool,
        /**
         * Function to call when ticket with variants is toggled
         */
        onTicketToggle: PropTypes.func,
        /**
         * Function to call when 'Join Waitlist' is clicked for a waitlisted ticket type
         */
        onJoinWaitlist: PropTypes.func,
        onVariantQuantityChange: PropTypes.func,
    };

    render() {
        const {
            tickets,
            onTicketToggle,
            onQuantityChange,
            onDonationChange,
            shouldHideStatusOnTickets,
            onJoinWaitlist,
            ticketReseller,
            featureFlags,
            isFormSubmitting,
        } = this.props;

        const items = tickets.map((ticket) => {
            const hasMultipleInputVariants = isMultipleVariantType(ticket);
            let content;

            if (hasMultipleInputVariants) {
                content = (
                    <DisplayContentWithMultipleInputVariants
                        key={ticket.id}
                        {...ticket}
                        onQuantityChange={onQuantityChange}
                        onDonationChange={onDonationChange}
                        onJoinWaitlist={onJoinWaitlist}
                        featureFlags={featureFlags}
                        isFormSubmitting={isFormSubmitting}
                    />
                );
            } else {
                content = (
                    <TicketDisplayCardContent
                        key={ticket.id}
                        {...ticket}
                        onTicketToggle={onTicketToggle}
                        onQuantityChange={onQuantityChange}
                        onDonationChange={onDonationChange}
                        shouldHideStatusOnTickets={shouldHideStatusOnTickets}
                        onJoinWaitlist={onJoinWaitlist}
                        ticketReseller={ticketReseller}
                        featureFlags={featureFlags}
                        isFormSubmitting={isFormSubmitting}
                    />
                );
            }

            return {
                id: ticket.id,
                content,
                style: !hasMultipleInputVariants ? CARD_STYLE_SIMPLE : null,
            };
        });

        return (
            <div
                className="tickets-container"
                data-automation="tickets-container"
                data-spec="tickets-selection-list-container"
            >
                <CardList items={items} />
            </div>
        );
    }
}
