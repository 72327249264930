import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
    MAX_COUNT,
    COLOR_PROP_TYPE,
    ICON_PROP_TYPE,
    POSITION_RIGHT,
    POSITION_PROP_TYPE,
} from './constants';
import { getCount } from './utils';
import { Icon } from '@eb/eds-icon';

import './badge.scss';

const getContent = ({ count, contentColor, iconType, text }) => {
    let content = null;

    if (count) {
        content = getCount(count, MAX_COUNT);
    }
    if (iconType) {
        const iconColor = contentColor || 'white';

        content = <Icon type={iconType} size="xsmall" color={iconColor} />;
    }
    // Text takes precedence over icon or count
    if (text) {
        content = text;
    }

    return content;
};

/**
 * Helper component that conditionally renders the badge with a number string
 */
const BadgeContent = ({
    bgColor,
    children,
    contentColor,
    count,
    iconType,
    text,
    position,
}) => {
    let component = null;
    const className = classNames('eds-badge__content', 'eds-fx--pop', {
        'eds-badge__content--round':
            (count >= 0 && count < 10) ||
            iconType ||
            (text && text.length === 1),
        'eds-badge__content--plus': count > MAX_COUNT,
        'eds-badge__content--count': count,
        'eds-badge__content--text': text,
        'eds-badge__content--icon': iconType,
        'eds-badge__content--standalone': !iconType && !text && !count,
        'eds-badge__content--over-children': children,
        [`eds-badge__content--over-children-${position}`]: position && children,
        [`eds-bg-color--${bgColor}`]: bgColor,
        [`eds-text-color--${contentColor}`]: contentColor,
    });

    // Don't show the badge if count is set to 0
    if (count === 0) {
        return component;
    }

    const content = getContent({ count, contentColor, iconType, text });

    component = (
        <div className={className} data-spec="eds-badge__content">
            {content}
        </div>
    );

    return component;
};

export default class Badge extends PureComponent {
    static propTypes = {
        /**
         * The background color of the Badge
         */
        bgColor: COLOR_PROP_TYPE,
        /**
         * The object over which the Badge is placed (e.g. Icon or Text)
         */
        children: PropTypes.node,
        /**
         * The fill color of the text or icon in the Badge
         */
        contentColor: COLOR_PROP_TYPE,
        /**
         * Numerical content of the Badge.
         * Differs from text as it will disappear when count is 0
         * and it will truncate to 99+ when count is > 100
         */
        count: PropTypes.number,
        /**
         * Icon content of the Badge
         */
        iconType: ICON_PROP_TYPE,
        /**
         * Textual content of the Badge
         */
        text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        /**
         * Position in relation to child node
         */
        position: POSITION_PROP_TYPE,
    };

    static defaultProps = {
        position: POSITION_RIGHT,
    };

    render() {
        const { children } = this.props;

        return (
            <div className="eds-badge" data-spec="eds-badge">
                {children}
                <BadgeContent {...this.props} />
            </div>
        );
    }
}
