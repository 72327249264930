import { AppAction, AppActionType } from '../actions/app';
import { AppNamespace } from '../state/app';

export const appReducer = (
    state: AppNamespace = {
        featureFlags: {},
        defaultUserCountry: '',
        locale: '',
    },
    action: AppAction,
) => {
    switch (action.type) {
        case AppActionType.initializeApp:
            return {
                ...state,
                ...action.payload,
            };

        case AppActionType.setReloadAfterCheckout: {
            return {
                ...state,
                reloadAfterCheckout: action.payload,
            };
        }

        case AppActionType.setOrganizerMarketingOptIn: {
            return {
                ...state,
                enableOrganizerMarketingOptIn: action.payload,
            };
        }

        case AppActionType.retrieveOrderFailure: {
            return {
                ...state,
                errors: [...state.errors, action.payload],
            };
        }

        case AppActionType.removeAppError: {
            return {
                ...state,
                errors: state.errors?.filter(
                    (error) => error.error !== action.payload,
                ),
            };
        }

        case AppActionType.updatePayinLockStatus:
            return {
                ...state,
                payins: {
                    ...(state.payins || {}),
                    amountLocked: action.payload,
                },
            };

        default:
            return state;
    }
};

const getWidgetParentUrl = (state: AppNamespace) => state.widgetParentUrl;

export const selectors = {
    getWidgetParentUrl,
};
