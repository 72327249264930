export interface QuestionChoice {
    answer: {
        text: string;
        html: string;
    };
    id: string;
    quantityAvailable: null | number;
}

export interface Question {
    question: {
        text: string;
        html: string;
    };
    type: QuestionType;
    choices: Array<QuestionChoice>;
    waiver?: string;
}

export type QuestionId = string | CannedQuestionName;
export type QuestionResponse = string;
export type QuestionsCollection = Record<QuestionId, Question>;
export type QuestionsResponses = Record<QuestionId, QuestionResponse>;

export interface QuestionTrigger {
    choiceId: string;
    parentQuestionId: string;
}

export interface InteractiveQuestion {
    answer: null; // TODO: Find proper type
    questionId: QuestionId;
    required: boolean;
    trigger: null | QuestionTrigger;
}

export enum CannedQuestionName {
    prefix = 'N-prefix',
    firstName = 'N-first_name',
    lastName = 'N-last_name',
    suffix = 'N-suffix',
    email = 'N-email',
    homePhone = 'N-home_phone',
    cellPhone = 'N-cell_phone',
    company = 'N-company',
    nameTaxReceipt = 'N-name_tax_receipt',
    individualTaxAddress = 'N-individual_tax_address',
    businessTaxAddress = 'N-business_tax_address',
    companyTax = 'N-company_tax',
    work = 'N-work',
    home = 'N-home',
    ship = 'N-ship',
    jobTitle = 'N-job_title',
    workPhone = 'N-work_phone',
    website = 'N-website',
    blog = 'N-blog',
    sex = 'N-sex',
    birthDate = 'N-birth_date',
    age = 'N-age',
    cc = 'N-cc',
    bill = 'N-bill',
    taxRegistrationId = 'N-tax_registration_id',
    isBusiness = 'N-is_business',
    confirmEmailAddress = 'confirmEmailAddress',
}

export enum QuestionType {
    address = 'address',
    checkbox = 'checkbox',
    date = 'date',
    dropdown = 'dropdown',
    email = 'email',
    number = 'number',
    paragraph = 'paragraph',
    phone = 'phone',
    radio = 'radio',
    text = 'text',
    url = 'url',
    waiver = 'waiver',
}
