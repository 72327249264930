import { Schema } from 'normalizr';
import { transformUtil } from '@eb/transformation-utils';

import { SELECTED_QUESTION_GROUP_BUYER } from '../../models/survey';
import { OrderResponse, OrderResponseFormatted } from '../models/order';
import { InteractiveQuestion, QuestionType } from '../models/survey';

const orderSchema = new Schema('order');

const getTransformedOrderFromOrderResponse = (data: OrderResponse) => {
    const transformedOrder = transformUtil({
        response: data,
        schema: orderSchema,
    });

    if (!transformedOrder) {
        return null;
    }

    return transformedOrder.entities.order[
        transformedOrder.result
    ] as OrderResponseFormatted;
};

export const getInteractiveQuestionsByAttendeeIdFromOrderResponse = (
    data: OrderResponse,
) => {
    const order = getTransformedOrderFromOrderResponse(data);

    if (!order) {
        return {};
    }

    const baseQuestions: Record<string, Array<InteractiveQuestion>> = {
        [SELECTED_QUESTION_GROUP_BUYER]: order.survey,
    };

    const attendees = order.attendees ?? [];

    return attendees.reduce(
        (otherAttendees, attendee) => ({
            ...otherAttendees,
            [attendee.id]: attendee.survey,
        }),
        baseQuestions,
    );
};

export const getQuestionsCollectionFromOrderResponse = (
    data: OrderResponse,
) => {
    const order = getTransformedOrderFromOrderResponse(data);

    return order ? order.questions : null;
};

type InputFieldValue = string;
type DateFieldValue = string;
type AddressFieldValue = Record<string, string>;
type WaiverFieldValue = string;
type ChoiceFieldValue = Array<{ id: string }>;

const getDateFieldPrefilledValue = (
    surveyFieldValue: DateFieldValue,
): {
    day: string;
    month: string;
    year: string;
} => {
    const [month, day, year] = surveyFieldValue.split('/');
    return {
        day: parseInt(day).toString(),
        month: parseInt(month).toString(),
        year: parseInt(year).toString(),
    };
};

const getAddressFieldPrefilledValue = (
    surveyFieldValue: AddressFieldValue,
): Record<string, string> =>
    Object.keys(surveyFieldValue).reduce(
        (accum: Record<string, string>, key: string) => ({
            ...accum,
            [key
                .replace(/^address_/, 'address')
                .replace(/^postal_code/, 'postal')]: surveyFieldValue[key],
        }),
        {},
    );

const getWaiverFieldPrefilledValue = (
    surveyFieldValue: WaiverFieldValue,
): boolean => surveyFieldValue === 'accepted';

const getSingleChoiceFieldPrefilledValue = (
    surveyFieldValue: ChoiceFieldValue,
): string => surveyFieldValue[0].id;

const getMultipleChoiceFieldPrefilledValue = (
    surveyFieldValue: ChoiceFieldValue,
): Array<string> =>
    surveyFieldValue.map((surveyFieldValueItem) => surveyFieldValueItem.id);

type GetFieldPrefilledValue =
    | typeof getDateFieldPrefilledValue
    | typeof getAddressFieldPrefilledValue
    | typeof getWaiverFieldPrefilledValue
    | typeof getSingleChoiceFieldPrefilledValue
    | typeof getMultipleChoiceFieldPrefilledValue;

export const getFieldPrefilledValue = (
    params:
        | {
              value: DateFieldValue;
              type: QuestionType.date;
          }
        | {
              value: AddressFieldValue;
              type: QuestionType.address;
          }
        | {
              value: WaiverFieldValue;
              type: QuestionType.waiver;
          }
        | {
              value: ChoiceFieldValue;
              type:
                  | QuestionType.radio
                  | QuestionType.dropdown
                  | QuestionType.checkbox;
          }
        | {
              value: InputFieldValue;
              type:
                  | QuestionType.email
                  | QuestionType.number
                  | QuestionType.paragraph
                  | QuestionType.phone
                  | QuestionType.text
                  | QuestionType.url;
          },
) => {
    switch (params.type) {
        case QuestionType.date:
            return getDateFieldPrefilledValue(params.value);
        case QuestionType.address:
            return getAddressFieldPrefilledValue(params.value);
        case QuestionType.waiver:
            return getWaiverFieldPrefilledValue(params.value);
        case QuestionType.radio:
            return getSingleChoiceFieldPrefilledValue(params.value);
        case QuestionType.dropdown:
            return getSingleChoiceFieldPrefilledValue(params.value);
        case QuestionType.checkbox:
            return getMultipleChoiceFieldPrefilledValue(params.value);
        case QuestionType.email:
        case QuestionType.number:
        case QuestionType.paragraph:
        case QuestionType.phone:
        case QuestionType.text:
        case QuestionType.url:
            return params.value;
    }
};
