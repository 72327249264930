import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@eb/eds-button';
import { Modal } from '@eb/eds-modal';
import { Icon } from '@eb/eds-icon';
import { MagnifyingGlass } from '@eb/eds-iconography';
import { TYPE_APERTURE } from '@eb/eds-modal';

import { translationPropType } from '@eb/i18n';
import gettext from '@eb/gettext';
import ImagePreviewModalBody from '../imagePreviewModalBody/ImagePreviewModalBody';

import './TicketImageThumbnail.scss';

export default class TicketImageThumbnail extends Component {
    static propTypes = {
        thumbnailUrl: PropTypes.string.isRequired,
        fullImageUrl: PropTypes.string.isRequired,
        title: translationPropType.isRequired,
    };

    state = {
        isModalShown: false,
    };

    _handleToggleIsModalShownState = () => {
        this.setState((state) => ({
            isModalShown: !state.isModalShown,
        }));
    };

    render() {
        const { thumbnailUrl, fullImageUrl, title } = this.props;

        return (
            <div className="ticket__image-thumbnail">
                <Button
                    style="none"
                    onClick={this._handleToggleIsModalShownState}
                    data-spec="ticket-image-button"
                >
                    <div className="ticket__image-overlay">
                        <div className="ticket__image-zoom-icon">
                            <Icon type={<MagnifyingGlass />} />
                        </div>
                    </div>
                </Button>
                <img width="120" height="120" src={thumbnailUrl} alt="" />
                <Modal
                    title={title}
                    type={TYPE_APERTURE}
                    primaryText={gettext('Return to Checkout')}
                    onClose={this._handleToggleIsModalShownState}
                    onPrimaryAction={this._handleToggleIsModalShownState}
                    isShown={this.state.isModalShown}
                >
                    <ImagePreviewModalBody
                        imageUrl={fullImageUrl}
                        title={title}
                    />
                </Modal>
            </div>
        );
    }
}
