import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import has from 'lodash/has';

import { SELECTED_QUESTION_GROUP_BUYER } from '../../models/survey';
import {
    CANNED_QUESTION_WORK_ADDRESS,
    CANNED_QUESTION_TAX_INVOICE,
    PARENT_FIELD_MULTIPLIER,
    QUESTION_GROUP_BUYER,
    QUESTION_GROUP_ATTENDEES,
    CANNED_QUESTION_COMPANY,
    CHECKOUT_NATIVE_IOS,
    CHECKOUT_NATIVE_ANDROID,
    CHECKOUT_NATIVE_WEB,
} from './constants';

export const cleanUpDuplicateTaxesQuestions = (questionsList) => {
    const duplicateQuestionList = [
        CANNED_QUESTION_WORK_ADDRESS,
        CANNED_QUESTION_COMPANY,
    ];

    return questionsList.filter(
        (element) => duplicateQuestionList.includes(element.name) === false,
    );
};

/**
 * Reorder subquestions (choice questions) just after the parent ones (they render according to the list order)
 */
export const orderQuestions = (questionsList) => {
    // To properly assign incrementing indexes to sub-questions
    let lastParentQuestionName = null;
    let subQuestionIndex = 0;
    let orderedQuestionsList = [...questionsList];

    // This hack moves the N-is_business question to the first position
    const taxInvoiceQuestion = orderedQuestionsList.find(
        (question) => question.name === CANNED_QUESTION_TAX_INVOICE,
    );

    if (taxInvoiceQuestion) {
        orderedQuestionsList = [
            taxInvoiceQuestion,
            ...orderedQuestionsList.filter(
                (question) => question !== taxInvoiceQuestion,
            ),
        ];
    }

    // Transform the ordered list into an object list, and add index for all parent questions
    // (leaving 99 slots for children questions)
    const partiallyIndexedQuestions = orderedQuestionsList.map(
        (question, originalIndex) => ({
            index: question.renderConditionTrigger
                ? 0
                : (originalIndex + 1) * PARENT_FIELD_MULTIPLIER,
            value: question,
        }),
    );

    // Add index to subquestions by taking parent question index and incrementing it
    const fullyIndexedQuestions = partiallyIndexedQuestions.map((element) => {
        const elementCopy = { ...element };

        if (element.index === 0) {
            if (
                element.value.renderConditionTrigger.parentQuestionId !==
                lastParentQuestionName
            ) {
                subQuestionIndex = 0;
                lastParentQuestionName =
                    element.value.renderConditionTrigger.parentQuestionId;
            }
            const parentQuestion = partiallyIndexedQuestions.find(
                (needle) =>
                    needle.value.name ===
                    element.value.renderConditionTrigger.parentQuestionId,
            );

            if (parentQuestion) {
                elementCopy.index = parentQuestion.index + ++subQuestionIndex;
            }
        }
        return elementCopy;
    });

    // Sort by index...
    const orderedIndexedQuestions = fullyIndexedQuestions.sort(
        (first, second) => first.index - second.index,
    );

    // And go back to a simple ordered list, now with subquestions located just after their parent question
    return orderedIndexedQuestions.map((element) => element.value);
};

/**
 * Actual function to remove the wrongly selected values
 */
const filterOutAnswerIfUnregistered = (
    questionsGroup,
    answers,
    answersGroupId,
    answersGroup,
    answerId,
) => {
    const cleanedAnswers = { ...answers };
    const question =
        questionsGroup.find(
            (surveyQuestion) => surveyQuestion.name === answerId,
        ) || {};

    // If question has `renderConditionTrigger` means that has a parent trigger
    if (question.renderConditionTrigger) {
        let hasParentQuestionSelected;
        const selectedParentChoice =
            answersGroup[question.renderConditionTrigger.parentQuestionId];
        const questionParentChoice = question.renderConditionTrigger.choiceId;

        // A question might be an array of values or a string
        if (isArray(selectedParentChoice) && !isEmpty(selectedParentChoice)) {
            hasParentQuestionSelected =
                selectedParentChoice.find(
                    (questionId) => questionId === questionParentChoice,
                ) || false;
        } else {
            hasParentQuestionSelected =
                selectedParentChoice === questionParentChoice;
        }

        // If selected value doesn't have its parent selected, it's wrong, so delete it.
        if (!hasParentQuestionSelected) {
            delete cleanedAnswers[answersGroupId][answerId];
        }
    }
    return cleanedAnswers;
};

/**
 * Apply the filtering to each applicable survey question
 */
const cleanEachSurveyAnswer = (
    questions,
    answers,
    answersGroupId,
    answersGroup,
    answerId,
) => {
    let cleanedAnswers = { ...answers };

    for (const questionsGroupName of Object.keys(questions)) {
        const questionsGroup = questions[questionsGroupName];
        const areQuestionsForBuyer =
            questionsGroupName === QUESTION_GROUP_BUYER;
        const areQuestionsForAttendee =
            questionsGroupName === QUESTION_GROUP_ATTENDEES;

        // Check questions for buyer and attendees, because they are the only ones that can have
        // conditional questions, and pass `filterOutUnregisteredFieldValues`
        // to clean wrong selected values of subquestions which don't have their parent questions selected
        if (areQuestionsForBuyer) {
            cleanedAnswers = filterOutAnswerIfUnregistered(
                questionsGroup,
                cleanedAnswers,
                answersGroupId,
                answersGroup,
                answerId,
            );
        } else if (areQuestionsForAttendee) {
            for (const attendeeSurvey of Object.values(questionsGroup)) {
                cleanedAnswers = filterOutAnswerIfUnregistered(
                    attendeeSurvey.survey,
                    cleanedAnswers,
                    answersGroupId,
                    answersGroup,
                    answerId,
                );
            }
        }
    }
    return cleanedAnswers;
};

/**
 * Filter out to only buyer and attendee questions
 */
const cleanBuyerAndAttendeeAnswers = (answers, questions) => {
    let cleanedAnswers;

    // TODO: Cleanup
    // Janus experiment exp_eb_112578_confirm_email_embedded_checkout avoid to send this frontend property
    // as an answer to the backend, destructuring prop to without remove the field value.
    if (has(answers, 'buyer.confirmEmailAddress')) {
        // eslint-disable-next-line no-unused-vars
        const { confirmEmailAddress, ...buyer } = answers.buyer;

        cleanedAnswers = {
            ...answers,
            buyer,
        };
    } else {
        cleanedAnswers = { ...answers };
    }

    for (const answersGroupId of Object.keys(cleanedAnswers)) {
        const answersGroup = cleanedAnswers[answersGroupId];
        const isSelectedBuyerQuestion =
            answersGroupId === SELECTED_QUESTION_GROUP_BUYER;
        const isSelectedAttendeeQuestion = answersGroupId.startsWith('A-');

        // Only Buyer and Attendee surveys have Conditional questions
        if (isSelectedBuyerQuestion || isSelectedAttendeeQuestion) {
            // Loop over each selected values type to filter whether are wrong, and get them out, or not
            for (const answerId of Object.keys(answersGroup)) {
                // Get survey questions to check them with selected values
                cleanedAnswers = cleanEachSurveyAnswer(
                    questions,
                    cleanedAnswers,
                    answersGroupId,
                    answersGroup,
                    answerId,
                );
            }
        }
    }
    return cleanedAnswers;
};

/**
 * Remove wrong selected values of Conditional Sub-questions when their parent questions (their triggers) are not
 * selected. This is due to redux-form doesn't remove them when action UNREGISTER_FIELD is triggered on these
 * Sub-questions, and if they were required, it would block user registration.
 */
export const cleanUnregisteredQuestionAnswers = (formData, surveyQuestions) =>
    cleanBuyerAndAttendeeAnswers(formData, surveyQuestions);

export const canUseIOSmessage = () =>
    window.webkit !== undefined &&
    window.webkit.messageHandlers !== undefined &&
    window.webkit.messageHandlers.ffi !== undefined;

export const canUseAndroidMessage = () => window.android !== undefined;

export const getNativePlatform = () => {
    if (canUseIOSmessage()) {
        return CHECKOUT_NATIVE_IOS;
    }
    if (canUseAndroidMessage()) {
        return CHECKOUT_NATIVE_ANDROID;
    }
    return CHECKOUT_NATIVE_WEB;
};

export const sendNativeCheckoutPurchaseAction = (message, webCallback) => {
    switch (getNativePlatform()) {
        case CHECKOUT_NATIVE_IOS:
            return window.webkit.messageHandlers.ffi.postMessage(message);
        case CHECKOUT_NATIVE_ANDROID:
            return window.android.postMessage(message);
        default:
            // CHECKOUT_NATIVE_WEB or others
            return webCallback();
    }
};
