import filter from 'lodash/filter';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import keyBy from 'lodash/keyBy';
import map from 'lodash/map';
import mergeWith from 'lodash/mergeWith';
import values from 'lodash/values';

import { moment } from '@eb/date';

import { EVENT_SERIES_FORM_NAME } from '../constants';
import { createSelector } from './utils/selector';

export const getSeriesIsLoading = (state) =>
    get(state, 'series.isLoading', false);

export const getSeriesEvents = (state) => get(state, 'series.seriesEvents');

export const getPreloadedSeriesEvents = (state) =>
    get(state, 'app.event.series_dates');

export const getSelectedDate = (state) =>
    get(state, ['form', EVENT_SERIES_FORM_NAME, 'values', 'filterDate']);

/**
 * Gets all the preloaded event dates from the app context and
 * transforms them into a event id => event map to match the shape
 * under state.series.seriesEvents so it's easier to merge both.
 */
export const getPreloadedSeriesEventsById = createSelector(
    getPreloadedSeriesEvents,
    (preloadedSeriesEvents) => keyBy(preloadedSeriesEvents, 'id'),
);

const seriesEventsMerge = (current, payload) => ({
    ...current,
    ...payload,
});

/**
 * Utility to deep merge two id=>event maps.
 */
export const mergeSeriesEvents = (...events) =>
    mergeWith({}, ...events, seriesEventsMerge);

/**
 * Gets preloaded and api series events into a unified map.
 */
export const getSeriesEventsWithPreloaded = createSelector(
    getPreloadedSeriesEventsById,
    getSeriesEvents,
    mergeSeriesEvents,
);

/**
 * Generates a calendar structure from the event dates list
 * ```js
 * {
 *    'YYYY-MM-DD': [
 *        { date: 'YYYY-MM-DD', soldOut: Boolean },
 *        { date: 'YYYY-MM-DD', soldOut: Boolean },
 *     ]
 * }
 * ```
 */
export const getSeriesCalendar = createSelector(
    getSeriesEventsWithPreloaded,
    (seriesEvents) =>
        groupBy(
            map(seriesEvents, (event) => ({
                status: event.status,
                date: event.start.local.substring(0, 10),
                soldOut: get(event, 'ticketAvailability.isSoldOut', false),
                hasWaitlistAvailable: get(
                    event,
                    'ticketAvailability.waitlistAvailable',
                    false,
                ),
            })),
            'date',
        ),
);

const getEventsOnDate = (date, events) =>
    filter(events, (event) => moment(event.start.local).isSame(date, 'd'));

/**
 * Generates an array of events and filters by the current selected date in redux-form.
 * If there's no selected date, returns all currently loaded events.
 */
export const getSeriesEventsFiltered = createSelector(
    getSelectedDate,
    getSeriesEvents,
    (selectedDate, seriesEvents = []) =>
        selectedDate
            ? getEventsOnDate(selectedDate, seriesEvents)
            : values(seriesEvents),
);

/**
 * Gets filtered events and sorts them by startDate.
 * Events with the same startDate are then sorted by endDate.
 */
export const getSeriesEventsFilteredAndSortedByStartDate = createSelector(
    getSeriesEventsFiltered,
    (seriesEvents = []) =>
        seriesEvents.concat().sort((eventA, eventB) => {
            const startA = eventA.start.local;
            const startB = eventB.start.local;

            return moment(startA).isAfter(startB, 'm') ||
                (moment(startA).isSame(startB, 'm') &&
                    moment(eventA.end.local).isAfter(eventB.end.local, 'm'))
                ? 1
                : -1;
        }),
);

const getCalendarDatesBetween = (seriesEvents, from, to) =>
    filter(
        seriesEvents,
        (calendarDay) =>
            moment(calendarDay.start.local).isSameOrAfter(from, 'day') &&
            moment(calendarDay.start.local).isSameOrBefore(to, 'day'),
    );

/**
 * Returns Boolean indicating if all events happening in between a given start
 * date range currently have availability data loaded (event.ticketAvailability prop is defined).
 *
 * If there are no events happening in between the two provided dates, returns true.
 */
export const getIsEventDateAvailabilityLoaded = createSelector(
    (_, props) => moment(props.startDateFrom).format('YYYY-MM-DD'),
    (_, props) => moment(props.startDateTo).format('YYYY-MM-DD'),
    getSeriesEventsWithPreloaded,
    (startDateFrom, startDateTo, seriesEvents) => {
        const eventsOnDates = getCalendarDatesBetween(
            seriesEvents,
            startDateFrom,
            startDateTo,
        );

        // NOTE: .every returns true for an empty array.
        return eventsOnDates.every(
            (event) => event && event.ticketAvailability,
        );
    },
);

export const getIsAnyAvailabilityLoaded = createSelector(
    getSeriesEvents,
    (seriesEvents) =>
        Boolean(seriesEvents) && Object.keys(seriesEvents).length > 0,
);
