import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as constants from './constants';

import './card.scss';

export default class Card extends React.PureComponent {
    static propTypes = {
        /**
         * Content of card
         */
        children: PropTypes.node.isRequired,
        /**
         * Changes the style of the card.
         * Available styles: simple, neutral
         */
        style: PropTypes.oneOf(constants.CARD_STYLES),
        /**
         * Changes the style of a selected card
         */
        isSelected: PropTypes.bool,
    };

    state = {
        focused: false,
    };

    _onFocus() {
        this.setState({ focused: true });
    }

    _onBlur() {
        this.setState({ focused: false });
    }

    render() {
        const { children, style, isSelected } = this.props;
        const { focused } = this.state;

        const className = classNames('eds-card', {
            [`eds-card--${style}`]: style,
            'eds-card--focused': focused,
            'eds-card--selected': isSelected,
        });

        return (
            <div
                className={className}
                onFocus={this._onFocus.bind(this)}
                onBlur={this._onBlur.bind(this)}
                role="presentation"
            >
                {children}
            </div>
        );
    }
}
