import React from 'react';
import PropTypes from 'prop-types';
import gettext from '@eb/gettext';

export default class RemainingTickets extends React.PureComponent {
    static propTypes = {
        quantityRemaining: PropTypes.number,
    };

    render() {
        const { quantityRemaining } = this.props;

        return (
            <span
                className="ticket-status eds-text-bm eds-text-color--ui-600 eds-l-pad-right-2"
                data-spec="remaining-tickets"
            >
                {gettext('%(quantityRemaining)s REMAINING', {
                    quantityRemaining,
                })}
            </span>
        );
    }
}
