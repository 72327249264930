import React from 'react';

import PropTypes from 'prop-types';

import { getSalesStatusContent } from '../../utils/status';

const Header = ({ children }) => (
    <div
        className="eds-text-hs eds-text-color--grey-800 eds-l-pad-top-2 eds-text--center"
        data-automation="checkout-widget-status-page-header"
    >
        {children}
    </div>
);

export default class Status extends React.PureComponent {
    static propTypes = {
        statusType: PropTypes.string.isRequired,
        toCheckout: PropTypes.func.isRequired,
        toPickASeat: PropTypes.func,
        toReservedTicketConfirmation: PropTypes.func,
        toTicketSelection: PropTypes.func.isRequired,
        toDeliveryMethod: PropTypes.func,
        toPaymentSelection: PropTypes.func,
        toLogin: PropTypes.func,
        closeModal: PropTypes.func,
        isNativeApp: PropTypes.bool,
    };

    render() {
        const {
            closeModalActions,
            closeModalOnNativeActions,
            eventSalesStatus,
            isNativeApp,
            statusType,
            toCheckout,
            toDeliveryMethod,
            toLogin,
            toPickASeat,
            toReservedTicketConfirmation,
            toTicketSelection,
            toPaymentSelection,
        } = this.props;

        const closeModal = isNativeApp
            ? closeModalOnNativeActions
            : closeModalActions;

        const { headerText, children } = getSalesStatusContent(
            statusType,
            eventSalesStatus,
            {
                toCheckout,
                toPickASeat,
                toReservedTicketConfirmation,
                toDeliveryMethod,
                toLogin,
                toTicketSelection,
                toPaymentSelection,
                closeModal,
            },
        );

        return (
            <div
                className="eds-g-cell eds-g-cell-1-1"
                data-spec={`checkout-widget-status-page-${statusType}`}
            >
                <Header>{headerText}</Header>
                <div className="eds-text-bm eds-l-pad-vert-3 eds-text--center eds-text-color--grey-600">
                    {children}
                </div>
            </div>
        );
    }
}
