import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import reduce from 'lodash/reduce';
import { translationPropType } from '@eb/i18n';

import { mergeEventHandlers } from './reduxFormUtils';

export default class ValidatedElement extends PureComponent {
    static propTypes = {
        children: PropTypes.node,
        // input state provided by redux-form
        input: PropTypes.shape({
            checked: PropTypes.bool,
            name: PropTypes.string,
            onBlur: PropTypes.func,
            onChange: PropTypes.func,
            onDragStart: PropTypes.func,
            onDrop: PropTypes.func,
            onFocus: PropTypes.func,
            value: PropTypes.any,
        }),
        // meta information provided by redux-form
        meta: PropTypes.shape({
            active: PropTypes.bool,
            asyncValidating: PropTypes.bool,
            dirty: PropTypes.bool,
            error: translationPropType,
            invalid: PropTypes.bool,
            pristine: PropTypes.bool,
            touched: PropTypes.bool,
            valid: PropTypes.bool,
            visited: PropTypes.bool,
        }),
        // additional parameters passed through ValidationFormField
        shouldDisplayError: PropTypes.func,
        submitFailed: PropTypes.bool,
    };

    static contextTypes = {
        _reduxForm: PropTypes.object,
    };

    componentWillMount() {
        this._callOnChangeIfNeeded(this.props);
    }

    componentWillReceiveProps(props) {
        this._callOnChangeIfNeeded(props);
    }

    /**
     * Returns whether the current value in the form's state differs from the
     * value passed to this component.
     *
     * @param value
     * @returns {boolean}
     * @private
     */
    _valueDiffersFromState(value) {
        const path = this.props.input.name.split('.');

        const stateValue = reduce(
            path,
            (memo = {}, pathKey) => memo[pathKey],
            this.context._reduxForm.getValues(),
        );

        return stateValue !== value;
    }

    /**
     * This method raises a change event if the value passed to the input differs
     * from the value in the redux store. Ideally, this shouldn't happen, but due
     * to some eccentricities with redux-form it unfortunately seems to be
     * unavoidable at times.
     * @private
     */
    _callOnChangeIfNeeded({ input: { value, onChange }, children }) {
        if (
            children.props.value !== undefined &&
            children.props.value !== value
        ) {
            onChange(children.props.value);
        } else if (value && this._valueDiffersFromState(value)) {
            onChange(value);
        }
    }

    render() {
        const {
            children,
            input,
            shouldDisplayError,
            submitFailed,
            meta: { error, dirty, active, touched },
            ...additionalProps
        } = this.props;
        const childrenProps = {
            ...additionalProps,
            ...mergeEventHandlers(children, input),
            hasError: false,
            value: input.value,
        };
        const validationProps = {
            active,
            dirty,
            error,
            touched,
            submitFailed,
            value: input.value,
        };

        if (error && shouldDisplayError(validationProps)) {
            if (children && children.props && children.props.annotationNote) {
                childrenProps.annotationNote = children.props.annotationNote;
            } else {
                childrenProps.annotationNote = error;
            }
            childrenProps.hasError = true;
        }

        return React.cloneElement(children, childrenProps);
    }
}
