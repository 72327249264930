import PropTypes from 'prop-types';
import { translationPropType } from '@eb/i18n';

export const ITEMS_PROP_TYPE = PropTypes.arrayOf(
    PropTypes.shape({
        content: translationPropType.isRequired,
        subcontent: PropTypes.node,
        value: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        quantity: PropTypes.string,
        color: PropTypes.string,
    }),
);

export const BREAKDOWN_ITEMS_PROP_TYPE = PropTypes.arrayOf(
    PropTypes.shape({
        content: translationPropType.isRequired,
        subcontent: PropTypes.node,
        value: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        color: PropTypes.string,
        expansionContent: PropTypes.arrayOf(
            PropTypes.shape({
                content: PropTypes.string.isRequired,
                value: PropTypes.string.isRequired,
                id: PropTypes.string.isRequired,
            }),
        ),
        infoTooltipContent: translationPropType,
        valueAdditionalClassNames: PropTypes.string,
    }),
);
