import has from 'lodash/has';
import get from 'lodash/get';
import { change } from 'redux-form';

import { formatQuery as encodeGetParams } from 'url-lib';
import gettext from '@eb/gettext';

import { trackOrderFailure } from '../eventAnalytics';

import { getUserAccountDetails as getUserAccountDetailsApi } from '../../api/users';
import {
    INTERNAL_SERVER_ERROR,
    PAYMENT_PAY_WITH_VIRTUAL_INCENTIVES,
} from '../../containers/checkout/constants';

import { selectors as appSelectors } from '../../reducers/app';
import { saveSession } from '../../session';
import logger from '@eb/logger-bugsnag';

import { selectors as orderSelectors } from '../../reducers/order';
import { getEventId } from '../../selectors/event';

import {
    shouldPerformTablessCheckout,
    isNativeApp,
    redirectTo,
} from '../../utils/browser';
import {
    PAYMENT_METHODS,
    VIRTUAL_INCENTIVES_ERRORS,
    CHECKOUT_FORM_NAME,
} from '../../constants';
import { setReloadAfterCheckoutAction } from '../app';
import {
    setCanRetrySubmitAction,
    setHasVirtualIncentivesErrorsAction,
} from './';

export const checkIfUserWasLoggedIn = () => async (dispatch, getState) => {
    const {
        order: { email = null, userId = null },
    } = getState();

    if (userId && email && email.length) {
        const { response } = await getUserAccountDetailsApi(email);

        if (
            response &&
            has(response, 'can_login') &&
            response.can_login === false
        ) {
            return dispatch(setReloadAfterCheckoutAction(true));
        }
    }

    return null;
};

export const userIsStoringNewCreditCard = (formData) =>
    formData.paymentMethod === PAYMENT_METHODS.CREDIT &&
    formData.payment.vaultId === PAYMENT_METHODS.CREDIT &&
    formData.payment.saveCreditCard;

export const getPostMessageActionParamsFromState = (state) => ({
    eventId: getEventId(state),
    widgetParentUrl: appSelectors.getWidgetParentUrl(state.app),
});

export const handleOrderError = (dispatch, error) => {
    if (error && error.errors) {
        /** Both JavaScript errors and server errors from the API will end up being caught here.
         *  However, this block is intended specifically for gracefully handling errors from the
         *  API and passing them to redux-form to be translated into user-friendly notifications.
         *  Any JavaScript errors that show up here should be fixed and should not be passed on.
         */

        dispatch(trackOrderFailure(error.errors._error));

        const errorType = get(error, 'extraData.parsedError.error');
        const hasVirtualIncentiveErrors = VIRTUAL_INCENTIVES_ERRORS.includes(
            errorType,
        );

        if (hasVirtualIncentiveErrors) {
            // After VI fails, we don't want to surface them again in the flow
            // setHasVirtualIncentiveErrors will be erased on resetOrder
            dispatch(setHasVirtualIncentivesErrorsAction(true));

            // Remove virtual incentive from form
            dispatch(
                change(
                    CHECKOUT_FORM_NAME,
                    PAYMENT_PAY_WITH_VIRTUAL_INCENTIVES,
                    false,
                ),
            );
        }

        if (INTERNAL_SERVER_ERROR === errorType || hasVirtualIncentiveErrors) {
            // allow the user to retry if they got a 500 error or their error is virtual incentive related
            dispatch(setCanRetrySubmitAction(true));
        } else {
            dispatch(setCanRetrySubmitAction(false));
        }
        throw error;
    }
};

/**
 * Returns true if the user has chosen to use CREDIT payment method,
 * then chose a card stored in their account.
 *
 * Note: payment.vaultId === CREDIT if the user chose to add a new card.
 */
export const userSelectedStoredPaymentMethod = (formData) =>
    formData.paymentMethod === PAYMENT_METHODS.CREDIT &&
    formData.payment.vaultId &&
    formData.payment.vaultId !== PAYMENT_METHODS.CREDIT;

const _getBankName = (availablePaymentMethods, bankId) => {
    if (!availablePaymentMethods || !bankId) {
        return null;
    }

    const idealInstrument = availablePaymentMethods
        .find((method) => method.type === 'eventbrite')
        .instrumentTypes.find(
            (instrument) => instrument.type === PAYMENT_METHODS.IDEAL,
        );

    if (!idealInstrument) {
        return null;
    }

    const bankIdentifiers = idealInstrument.idealBanks;

    const bankIdentifier = bankIdentifiers.filter(
        (identifier) => identifier.bankId === bankId,
    );

    if (!bankIdentifier[0]) {
        return null;
    }

    return bankIdentifier[0].bankName;
};

export const redirectToExternalPayment = async (
    paymentMethod,
    promise,
    availablePaymentMethods,
    bankId,
    getState,
) => {
    let url = `//${window.location.host}/checkout-widget-external-payment-redirect`;

    const appData = {};
    const performTabless =
        shouldPerformTablessCheckout() || isNativeApp(getState());

    appData.paymentMethod = paymentMethod;
    appData.bankName = _getBankName(availablePaymentMethods, bankId);

    const openExternalPaymentWindow = () => {
        const externalPaymentWindow = window.open(url, '_blank');
        if (externalPaymentWindow) {
            window.externalPayment = externalPaymentWindow;
        } else {
            // Popup blocked
            const errorMessage = gettext(
                'Your browser blocked the payment pop-up. Please disable your pop-up blocker for this site and try again.',
            );
            throw errorMessage;
        }
    };

    // EB-95856 Some users cannot progress to order form on Embedded Checkout on some devices
    // iosWebView are removing window.opener data, so pass through GET parameters
    if (performTabless) {
        appData.redirectInstructions = await promise();
        url += `?${encodeGetParams({ appData: JSON.stringify(appData) })}`;
        const state = getState();
        saveSession(state);

        try {
            await redirectTo(url);
        } catch (error) {
            const orderId = orderSelectors.getOrderId(state.order);
            const eventId = getEventId(state);
            logger.error(
                'Redirect to URL has failed',
                {
                    orderId,
                    eventId,
                    url,
                    error,
                },
                {
                    beforeSend: (report) => {
                        // eslint-disable-next-line no-param-reassign
                        report.groupingHash =
                            'redirecting-to-third-party-payment-on-tabless';
                    },
                },
            );
        }
    } else {
        appData._getRedirectedPaymentOrderPromise = promise;
        window.app = appData;

        // EB-95749 We need to prevent that user can open multiple tabs for the same external payment
        // In firefox, closing and reopening a new tab was causing that the brand tab aren't getting opened
        // We decide to include setTimeout in order to give some time to close the tab and after this attempt to reopen it.
        if (window.externalPayment) {
            window.externalPayment.close();
            setTimeout(() => {
                openExternalPaymentWindow();
            }, 100);
        } else {
            openExternalPaymentWindow();
        }
    }
};
