import { arrayOf, Schema } from 'normalizr';
import { transformUtil } from '@eb/transformation-utils';
import { EventbriteBackendEvent, EventbriteFrontendEvent } from './event.types';
import { PaginatedResponse } from './pagination.types';

const SERIES_EVENT_SCHEMA = new Schema('seriesEvents');

const SERIES_EVENTS_SCHEMA = {
    events: arrayOf(SERIES_EVENT_SCHEMA),
};

export interface SeriesEventsResponse extends PaginatedResponse {
    events: Array<
        EventbriteBackendEvent & {
            series_id: string;
            ticket_availability: {
                has_available_tickets: boolean;
                minimum_ticket_price: {
                    currency: string;
                    value: number;
                    major_value: string;
                    display: string;
                };
                is_sold_out: boolean;
                start_sales_date: any | null; // TODO: Find proper type for this
                waitlist_available: boolean;
            };
        }
    >;
}

export interface SeriesEvents
    extends Pick<
        EventbriteFrontendEvent,
        | 'id'
        | 'name'
        | 'start'
        | 'end'
        | 'hideEndDate'
        | 'currency'
        | 'status'
        | 'hasAvailableTickets'
        | 'isSoldOut'
    > {
    seriesId: string;
}

const SERIES_EVENT_RESPONSE_FIELDS = [
    'id',
    'name',
    'text',
    'start',
    'end',
    'local',
    'timezone',
    'hide_end_date',
    'currency',
    'status',
    // series parent id
    'series_id',
    'ticket_availability',
    'has_available_tickets',
    'is_sold_out',
    'waitlist_available',
    'event_sales_status',
    'sales_status',
    // ticket price
    'minimum_ticket_price',
    'value',
    // venue
    'venue',
    'address',
    'localized_area_display',
];

export const transformSeriesEventsData = (data: SeriesEventsResponse) => {
    const transformedSeriesEvents = transformUtil({
        response: data,
        schema: SERIES_EVENTS_SCHEMA,
        fields: SERIES_EVENT_RESPONSE_FIELDS,
        isWhiteList: true,
    });

    if (!transformedSeriesEvents) {
        return null;
    }

    const seriesEvents: SeriesEvents =
        transformedSeriesEvents.entities.seriesEvents;

    return {
        seriesEvents,
    };
};

export const transformSeriesPaginationData = (data: PaginatedResponse) => ({
    continuationToken: data.pagination.continuation,
    hasMoreItems: data.pagination['has_more_items'],
});
