import gettext from '@eb/gettext';
import {
    GROUP_NAME_ID,
    GROUP_PASSWORD_ID,
    GROUP_START_TIME_ID,
    GROUP_TYPE_ASSOCIATION,
    GROUP_TYPE_COMPANY,
    GROUP_TYPE_GROUP,
    GROUP_TYPE_ORGANIZATION,
    GROUP_TYPE_TEAM,
    REGISTRATION_TYPE_INDIVIDUAL,
    GROUP_FULL_ERROR,
    GROUP_DOES_NOT_EXIST_ERROR,
} from './constants';

export const getGroupRegistrationSelectionTerminology = (isRegEvent) => ({
    groupTypes: {
        [GROUP_TYPE_GROUP]: gettext('As a group member'),
        [GROUP_TYPE_TEAM]: gettext('As a team member'),
        [GROUP_TYPE_ORGANIZATION]: gettext('As an organization member'),
        [GROUP_TYPE_COMPANY]: gettext('As a company member'),
        [GROUP_TYPE_ASSOCIATION]: gettext('As an association member'),
        [REGISTRATION_TYPE_INDIVIDUAL]: gettext('As an individual'),
    },
    header: isRegEvent
        ? gettext('I want to register...')
        : gettext('I want to get tickets...'),
    ctaText: isRegEvent ? gettext('Register') : gettext('Get Tickets'),
});

export const GROUP_REG_JOIN_TERMINOLOGY = {
    [GROUP_TYPE_ASSOCIATION]: {
        headerText: gettext('Join the association'),
        headerActionTitle: gettext(
            'Back to individual or association selection',
        ),
        selectGroupPlaceholder: gettext('Enter your association name*'),
        secondaryCtaText: gettext('Create your association'),
    },
    [GROUP_TYPE_COMPANY]: {
        headerText: gettext('Join the company'),
        headerActionTitle: gettext('Back to individual or company selection'),
        selectGroupPlaceholder: gettext('Enter your company name*'),
        secondaryCtaText: gettext('Create your company'),
    },
    [GROUP_TYPE_GROUP]: {
        headerText: gettext('Join the group'),
        headerActionTitle: gettext('Back to individual or group selection'),
        selectGroupPlaceholder: gettext('Enter your group name*'),
        secondaryCtaText: gettext('Create your group'),
    },
    [GROUP_TYPE_ORGANIZATION]: {
        headerText: gettext('Join the organization'),
        headerActionTitle: gettext(
            'Back to individual or organization selection',
        ),
        selectGroupPlaceholder: gettext('Enter your organization name*'),
        secondaryCtaText: gettext('Create your organization'),
    },
    [GROUP_TYPE_TEAM]: {
        headerText: gettext('Join the team'),
        headerActionTitle: gettext('Back to individual or team selection'),
        selectGroupPlaceholder: gettext('Enter your team name*'),
        secondaryCtaText: gettext('Create your team'),
    },
};

export const getGroupRegPurchaseConfirmationTerminology = ({
    teamUrl,
    teamName,
}) => ({
    [GROUP_TYPE_GROUP]: gettext(
        'Your group: <a href="%(teamUrl)s" target="_blank">%(teamName)s</a>',
        {
            teamUrl,
            teamName,
        },
    ),
    [GROUP_TYPE_TEAM]: gettext(
        'Your team: <a href="%(teamUrl)s" target="_blank">%(teamName)s</a>',
        {
            teamUrl,
            teamName,
        },
    ),
    [GROUP_TYPE_ORGANIZATION]: gettext(
        'Your organization: <a href="%(teamUrl)s" target="_blank">%(teamName)s</a>',
        { teamUrl, teamName },
    ),
    [GROUP_TYPE_COMPANY]: gettext(
        'Your company: <a href="%(teamUrl)s" target="_blank">%(teamName)s</a>',
        {
            teamUrl,
            teamName,
        },
    ),
    [GROUP_TYPE_ASSOCIATION]: gettext(
        'Your association: <a href="%(teamUrl)s" target="_blank">%(teamName)s</a>',
        {
            teamUrl,
            teamName,
        },
    ),
});

export const getDefaultGroupRegPurchaseConfirmation = ({ teamUrl, teamName }) =>
    gettext(
        'You have signed up with: <a href="%(teamUrl)s" target="_blank">%(teamName)s</a>',
        {
            teamUrl,
            teamName,
        },
    );

export const GROUP_REG_CREATE_TERMINOLOGY = {
    [GROUP_TYPE_ASSOCIATION]: {
        headerText: gettext('Create your association'),
        headerActionTitle: gettext('Back to join the association'),
        groupNameLabel: gettext('Association name'),
        groupPasswordLabel: gettext('Association password'),
        primaryCtaText: gettext('Create an association'),
    },
    [GROUP_TYPE_COMPANY]: {
        headerText: gettext('Create your company'),
        headerActionTitle: gettext('Back to join the company'),
        groupNameLabel: gettext('Company name'),
        groupPasswordLabel: gettext('Company password'),
        primaryCtaText: gettext('Create a company'),
    },
    [GROUP_TYPE_GROUP]: {
        headerText: gettext('Create your group'),
        headerActionTitle: gettext('Back to join the group'),
        groupNameLabel: gettext('Group name'),
        groupPasswordLabel: gettext('Group password'),
        primaryCtaText: gettext('Create a group'),
    },
    [GROUP_TYPE_ORGANIZATION]: {
        headerText: gettext('Create your organization'),
        headerActionTitle: gettext('Back to join the organization'),
        groupNameLabel: gettext('Organization name'),
        groupPasswordLabel: gettext('Organization password'),
        primaryCtaText: gettext('Create an organization'),
    },
    [GROUP_TYPE_TEAM]: {
        headerText: gettext('Create your team'),
        headerActionTitle: gettext('Back to join the team'),
        groupNameLabel: gettext('Team name'),
        groupPasswordLabel: gettext('Team password'),
        primaryCtaText: gettext('Create a team'),
    },
};

export const GROUP_REG_PREFERRED_START_TIME_LABEL = gettext(
    'Preferred start time',
);

export const GROUP_REG_ERROR_TERMINOLOGY = {
    [GROUP_FULL_ERROR]: gettext('This group is full'),
    [GROUP_DOES_NOT_EXIST_ERROR]: gettext("This group doesn't exist"),
};

export const GROUP_REG_INVALID_PASSWORD_ERROR = gettext('Password is invalid');
export const GROUP_JOIN_PASSWORD_PLACEHOLDER = gettext('Enter password');

const GROUP_NAME_IS_EMPTY_ERROR = {
    [GROUP_TYPE_ASSOCIATION]: gettext('Association name is required.'),
    [GROUP_TYPE_COMPANY]: gettext('Company name is required.'),
    [GROUP_TYPE_GROUP]: gettext('Group name is required.'),
    [GROUP_TYPE_ORGANIZATION]: gettext('Organization name is required.'),
    [GROUP_TYPE_TEAM]: gettext('Team name is required.'),
};

export const getCreateGroupErrorMessages = (groupType) => ({
    name: {
        isProvided: GROUP_NAME_IS_EMPTY_ERROR[groupType],
        hasRangeLength: gettext(
            'Please enter a name consisting of 3 to 60 characters.',
        ),
    },
    password: {
        isProvided: gettext('Password is required.'),
    },
    preferredStartTime: {
        isProvided: gettext('Preferred start time is required.'),
    },
});

export const NEWLY_GROUP_CREATED_TEXT = {
    [GROUP_TYPE_ASSOCIATION]: gettext(
        'Your association is set! Now you can get your tickets.',
    ),
    [GROUP_TYPE_COMPANY]: gettext(
        'Your company is set! Now you can get your tickets.',
    ),
    [GROUP_TYPE_GROUP]: gettext(
        'Your group is set! Now you can get your tickets.',
    ),
    [GROUP_TYPE_ORGANIZATION]: gettext(
        'Your organization is set! Now you can get your tickets.',
    ),
    [GROUP_TYPE_TEAM]: gettext(
        'Your team is set! Now you can get your tickets.',
    ),
};

export const TEAM_SETTINGS_SERVER_ERRORS = {
    [GROUP_NAME_ID]: gettext('There was a problem with the name you chose'),
    [GROUP_PASSWORD_ID]: gettext('There was a problem with the password'),
    [GROUP_START_TIME_ID]: gettext(
        'There was a problem with the preferred start time',
    ),
};
