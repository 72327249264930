import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@eb/eds-button';
import { TYPE_LINK } from '@eb/eds-button';
import { isDonationTicket } from '../../utils/tickets';
import { shouldShowTicketReseller } from './utils/ticketUtils';

import { CTA_JOIN_WAITLIST } from '../../constants';

import TicketCardStatus from '../../components/ticketsSelectionList/TicketCardStatus';
import TicketDonationField from '../../components/ticketsSelectionList/TicketDonationField';
import TicketQuantitySelector from '../../components/ticketsSelectionList/TicketQuantitySelector';
import { DATA_PROP_TYPES } from '../../components/ticketsSelectionList/constants';

const DonationField = ({
    id,
    onDonationChange,
    donationAmount,
    currencyFormat,
    currencySymbol,
    isCurrencySuffix,
    isDisabled,
}) => (
    <div className="eds-ticket-card-content__donation-field">
        <TicketDonationField
            id={id}
            onChange={onDonationChange}
            isDisabled={isDisabled}
            amount={donationAmount}
            currencySymbol={currencySymbol}
            currencyFormat={currencyFormat}
            isCurrencySuffix={isCurrencySuffix}
        />
    </div>
);

export default class TicketAvailabilityField extends React.PureComponent {
    static propTypes = {
        ...DATA_PROP_TYPES,

        /**Boolean indicate if the a ticket is available for select, or entering amount (donation) */
        canBeSelected: PropTypes.bool,
    };

    render() {
        const {
            canBeSelected,
            id,
            statusKey,
            showJoinWaitlist,
            onJoinWaitlist,
            onQuantityChange,
            variantId,
            hideSaleDates,
            ticketReseller = {},
            isFormSubmitting,
        } = this.props;

        // Only display individual ticket reseller if other tickets
        // have remaining quantity and resellers are available.
        const showTicketReseller = shouldShowTicketReseller(
            statusKey,
            ticketReseller,
        );
        let content;

        if (canBeSelected) {
            if (isDonationTicket(this.props)) {
                content = (
                    <DonationField
                        {...this.props}
                        isDisabled={isFormSubmitting}
                    />
                );
            } else {
                content = (
                    <TicketQuantitySelector
                        {...this.props}
                        isDisabled={isFormSubmitting}
                        onQuantityChange={onQuantityChange.bind(
                            null,
                            variantId,
                        )}
                    />
                );
            }
        } else if (showJoinWaitlist) {
            content = (
                <div
                    className="eds-ticket-card-content__join-waitlist-button"
                    data-spec="join-waitlist-button"
                    data-automation={`join-waitlist-ticket-${id}-btn`}
                >
                    <Button onClick={onJoinWaitlist}>
                        {CTA_JOIN_WAITLIST}
                    </Button>
                </div>
            );
        } else if (showTicketReseller) {
            content = (
                <div data-spec="show-ticket-reseller">
                    <Button
                        type={TYPE_LINK}
                        href={ticketReseller.url}
                        target="_blank"
                        data-spec="ticket-reseller-button"
                    >
                        {ticketReseller.text}
                    </Button>
                </div>
            );
        } else {
            content = (
                <TicketCardStatus
                    {...this.props}
                    shouldHideStatus={hideSaleDates}
                    customClassNames="eds-text--right"
                />
            );
        }

        return content;
    }
}
