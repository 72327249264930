import { FOCUSABLE_ELEMENTS } from '../constants/focusableElements';

/**
 * This method sets the focus to the first focusable element inside the root element.
 *
 * Our application continuously modifes the HTML DOM during runtime, sometimes leading to keyboard focus being lost
 * or set to an unexpected element. In order to repair this, we need to programmatically nudge the keyboard focus
 * in the right direction.
 *
 * @param focusableEls the list of DOM selectors.
 * @param rootEL the element used as a reference to search the focusable elements inside it
 */

export const resetFocusToInitialElement = ({
    focusableEls = FOCUSABLE_ELEMENTS,
    rootEl,
}: {
    focusableEls?: Array<String>;
    rootEl: HTMLElement;
}) => {
    const cleanedFocusableEls = _cleanFocusableEls(focusableEls, rootEl);
    const firstFocusableEl = cleanedFocusableEls[0];

    (firstFocusableEl as HTMLElement)?.focus();
};

const _cleanFocusableEls = (
    focusableEls: Array<String>,
    rootEl: HTMLElement,
): Array<Element> => {
    const selector = focusableEls.join();
    const cleanedFocusableEls = [
        ...Array.from(rootEl.querySelectorAll(selector)),
    ].filter(
        (focusableEl) =>
            window.getComputedStyle(focusableEl).display !== 'none' &&
            focusableEl.getAttribute('tabindex') !== '-1',
    );

    return cleanedFocusableEls;
};
