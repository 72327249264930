import * as React from 'react';

const EyeOnChunkySvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 24 24" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 16.2c-2.3 0-4.2-1.9-4.2-4.2 0-2.3 1.9-4.2 4.2-4.2 2.3 0 4.2 1.9 4.2 4.2 0 2.3-1.9 4.2-4.2 4.2zm0-10.5c-4.2 0-7.8 2.6-9.2 6.3 1.5 3.7 5 6.3 9.2 6.3s7.8-2.6 9.2-6.3c-1.4-3.7-5-6.3-9.2-6.3z"
        />
        <circle fillRule="evenodd" clipRule="evenodd" cx={12} cy={12} r={2.5} />
    </svg>
);

EyeOnChunkySvg.displayName = 'EyeOnChunkySvg';
export default EyeOnChunkySvg;
