import { SingleValueValidator } from './types';

const CNPJ_LENGTH = 14;
const CPF_LENGTH = 11;
const CHECK_SUM_LENGTH = 2;
const CHECK_SUM_MOD = 11;
const CHECK_SUM_COEFFICIENT_MIN = 2;
const CNPJ_COEFFICIENT_LIMIT = 9;

const checkSum = (str: string, len: number, CNPJ: boolean): number => {
    let j = CHECK_SUM_COEFFICIENT_MIN;

    let sum = 0;

    for (let i = len; i >= 0; i--) {
        sum += parseInt(str.charAt(i), 10) * j;
        j++;
        if (CNPJ && j > CNPJ_COEFFICIENT_LIMIT) {
            j = CHECK_SUM_COEFFICIENT_MIN;
        }
    }
    return sum % CHECK_SUM_MOD;
};

/**
 * Validator: Test that a value is a valid CNPJ number.
 *
 * @returns is str a valid CNPJ number.
 */
export const isCNPJ: SingleValueValidator<string> = (str) => {
    if (!str || str.length !== CNPJ_LENGTH) {
        return false;
    }

    // check first checkSum
    let r1 = checkSum(str, CNPJ_LENGTH - CHECK_SUM_LENGTH - 1, true);

    r1 = r1 < 2 ? 0 : CHECK_SUM_MOD - r1;
    if (parseInt(str.charAt(CNPJ_LENGTH - CHECK_SUM_LENGTH), 10) !== r1) {
        return false;
    }

    // check second checkSum
    let r2 = checkSum(str, CNPJ_LENGTH - CHECK_SUM_LENGTH, true);

    r2 = r2 < 2 ? 0 : CHECK_SUM_MOD - r2;
    return parseInt(str.charAt(CNPJ_LENGTH - 1), 10) === r2;
};

/**
 * Validator: Test that a value is a valid CPF number.
 *
 * @returns is str a valid CPF number.
 */
export const isCPF: SingleValueValidator<string> = (str) => {
    if (!str || str.length !== CPF_LENGTH) {
        return false;
    }
    // check first checkSum
    let r1 = checkSum(str, CPF_LENGTH - CHECK_SUM_LENGTH - 1, false);

    r1 = r1 < 2 ? 0 : CHECK_SUM_MOD - r1;
    if (parseInt(str.charAt(CPF_LENGTH - CHECK_SUM_LENGTH), 10) !== r1) {
        return false;
    }

    // check second checkSum
    let r2 = checkSum(str, CPF_LENGTH - CHECK_SUM_LENGTH, false);

    r2 = r2 < 2 ? 0 : CHECK_SUM_MOD - r2;
    return parseInt(str.charAt(CPF_LENGTH - 1), 10) === r2;
};
