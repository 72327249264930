import * as React from 'react';

const OxxoPaymentSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 35 28" {...props}>
        <g fill="none" fillRule="evenodd">
            <rect fill="#F0A929" width={35} height={28} rx={1} />
            <path
                d="M.046 7.129a1.09 1.09 0 011.086-1.087h32.722A1.09 1.09 0 0134.94 7.13v13.91a1.09 1.09 0 01-1.086 1.086H1.132A1.09 1.09 0 01.046 21.04V7.129z"
                fill="#F0A929"
            />
            <path fill="#FFF" d="M.046 7.622H34.94v12.924H.046z" />
            <path
                d="M29.618 10.978a2.903 2.903 0 00-2.898 2.898 2.903 2.903 0 002.898 2.898 2.903 2.903 0 002.898-2.898 2.903 2.903 0 00-2.898-2.898zM5.732 10.978a2.903 2.903 0 00-2.898 2.898 2.903 2.903 0 002.898 2.898 2.903 2.903 0 002.898-2.898 2.903 2.903 0 00-2.898-2.898z"
                fill="#E21C2A"
            />
            <path
                d="M29.39 8.428c-2.682-.062-3.943 1.658-5.774 3.78L21.89 14.21l2.734 3.274c.651 1.005-.592 2.004-1.363 1.083l-2.528-3.015-2.464 2.855c-.784.91-2.014-.106-1.348-1.103l2.674-3.11-1.752-2.09 1.127-1.395 1.782 2.14 1.536-1.786c.753-.876 1.522-2.037 2.518-2.634H.046V19.74h5.789c2.683 0 3.9-1.604 5.682-3.767l1.68-2.041-2.81-3.21c-.674-.99.546-2.016 1.339-1.114l2.596 2.957 2.398-2.912c.763-.927 2.015.06 1.373 1.071l-2.602 3.171 1.8 2.05-1.134 1.343-1.792-2.021-1.494 1.821c-.733.893-1.476 2.032-2.458 2.652h24.528V8.428h-5.55zm-19.08 5.448a4.587 4.587 0 01-4.578 4.579 4.587 4.587 0 01-4.579-4.579 4.587 4.587 0 014.58-4.578 4.587 4.587 0 014.577 4.578zm19.308 4.579a4.587 4.587 0 01-4.579-4.579 4.587 4.587 0 014.58-4.578 4.587 4.587 0 014.577 4.578 4.587 4.587 0 01-4.578 4.579z"
                fill="#E21C2A"
            />
        </g>
    </svg>
);

OxxoPaymentSvg.displayName = 'OxxoPaymentSvg';
export default OxxoPaymentSvg;
