// External libraries
import React from 'react';
import PropTypes from 'prop-types';
import pick from 'lodash/pick';
import { translationPropType } from '@eb/i18n';

// Local imports
import gettext from '@eb/gettext';
import { PROMO_CODE_TYPES } from '../../constants';
import { OPTIONS_PROP_TYPE } from '../promoCode/constants';
import TicketsSelectionList from './TicketsSelectionList';

const ticketSelectionListPropKeys = Object.keys(TicketsSelectionList.propTypes);

const SelectionListWithCode = function (props) {
    if (!props.tickets.length) {
        return null;
    }

    const headerText =
        props.promoCodeFormOptions.promoCodeType === PROMO_CODE_TYPES.ACCESS
            ? gettext('Unlocked with code')
            : gettext('Discounted %(header)s', { header: props.header });
    const header = (
        <h3
            className="eds-l-mar-top-6 eds-text-bm eds-text-color--grey-900 eds-text-weight--heavy"
            data-spec="promo-code-tickets-header"
        >
            {headerText}
        </h3>
    );
    const listProps = {
        ...pick(props, ...ticketSelectionListPropKeys),
        tickets: props.tickets,
        shouldHideStatusOnTickets: props.shouldHideStatusOnTickets,
        ticketReseller: props.ticketReseller,
        featureFlags: props.featureFlags,
        isFormSubmitting: props.isFormSubmitting,
    };

    return (
        <div>
            {header}
            <TicketsSelectionList {...listProps} />
        </div>
    );
};

const SelectionList = function (props) {
    if (!props.tickets.length) {
        return null;
    }

    const listProps = {
        ...pick(props, ...ticketSelectionListPropKeys),
        tickets: props.tickets,
        shouldHideStatusOnTickets: props.shouldHideStatusOnTickets,
        ticketReseller: props.ticketReseller,
        featureFlags: props.featureFlags,
        isFormSubmitting: props.isFormSubmitting,
    };

    return <TicketsSelectionList {...listProps} />;
};

export default class TicketCategoryGroup extends React.PureComponent {
    static propTypes = {
        ticketClassGroupProps: PropTypes.shape({
            tickets: PropTypes.array,
            ticketsWithAppliedCode: PropTypes.array,
            title: translationPropType,
        }),
        promoCodeFormOptions: OPTIONS_PROP_TYPE,
        shouldHideStatusOnTickets: PropTypes.bool,
        shouldShowTicketCategoryGroupHeaders: PropTypes.bool,
    };

    render() {
        const {
            ticketClassGroupProps,
            shouldShowTicketCategoryGroupHeaders,
        } = this.props;

        const formattedListProps = {
            ...this.props,
            tickets: ticketClassGroupProps.tickets,
            header: ticketClassGroupProps.title,
        };

        const formattedListWithCodeProps = {
            ...formattedListProps,
            tickets: ticketClassGroupProps.ticketsWithAppliedCode,
        };

        const header = (
            <h2
                className="eds-l-mar-top-8 eds-text-hs"
                data-spec="ticket-selection-list-header"
            >
                {formattedListProps.header}
            </h2>
        );

        return (
            <div>
                {shouldShowTicketCategoryGroupHeaders && header}
                {ticketClassGroupProps.ticketsWithAppliedCode.length ? (
                    <div data-spec="ticket-selection-list">
                        <SelectionListWithCode
                            {...formattedListWithCodeProps}
                        />
                        <SelectionList {...formattedListProps} />
                    </div>
                ) : (
                    <div data-spec="ticket-selection-list">
                        <SelectionList {...formattedListProps} />
                    </div>
                )}
            </div>
        );
    }
}
