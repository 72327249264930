import { combineReducers } from 'redux';
import {
    UPDATE_WAITING_ROOM_TOKEN,
    UPDATE_GUEST_TOKEN,
    UPDATE_ORDER_TOKEN,
    RESET_GUEST_TOKEN,
    RESET_ORDER_TOKEN,
    RESET_WAITING_ROOM_TOKEN,
    UPDATE_SQUARE_ANALYTICS_TOKEN,
} from '../actions/tokens';

const guestToken = (state = '', action) => {
    switch (action.type) {
        case UPDATE_GUEST_TOKEN:
            return action.payload.guestToken;

        case RESET_GUEST_TOKEN:
            return '';

        default:
            return state;
    }
};

const orderToken = (state = '', action) => {
    switch (action.type) {
        case UPDATE_ORDER_TOKEN:
            return action.payload.orderToken;

        case RESET_ORDER_TOKEN:
            return '';

        default:
            return state;
    }
};

const waitingRoomToken = (state = '', action) => {
    switch (action.type) {
        case UPDATE_WAITING_ROOM_TOKEN:
            return action.payload.waitingRoomToken;

        case RESET_WAITING_ROOM_TOKEN:
            return '';

        default:
            return state;
    }
};

const sqAnalyticsToken = (state = null, action) => {
    switch (action.type) {
        case UPDATE_SQUARE_ANALYTICS_TOKEN:
            return action.payload.sqAnalyticsToken;

        default:
            return state;
    }
};

export default combineReducers({
    guestToken,
    orderToken,
    waitingRoomToken,
    sqAnalyticsToken,
});
