import * as React from 'react';

const CreditCardFrontSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 24 24" {...props}>
        <path d="M3 6h18v12H3V6zM2 19h20V5H2v14z" />
        <path d="M5 15h2v1H5zM9 15h2v1H9zM13 15h2v1h-2zM17 15h2v1h-2zM5 12v-2h2v2z" />
    </svg>
);

CreditCardFrontSvg.displayName = 'CreditCardFrontSvg';
export default CreditCardFrontSvg;
