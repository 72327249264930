import find from 'lodash/find';

/**
 * Currently if there is tax on the event, all tickets will have the same eventTaxName regardless of whether
 * tax is applied to that ticket or not.
 *
 * We may have different taxes and tax names per ticket in the future.
 *
 * @param tickets the flattened list of Tickets.
 */
export const getEventTaxName = <Ticket extends { eventTaxName?: string }>(
    tickets: Array<Ticket>,
) =>
    find(
        tickets.map((ticket) => ticket.eventTaxName),
        (tax) => tax,
    );
