import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
    calculateRemaining,
    calculateApproachingLimit,
    calculateReachedLimit,
} from '@eb/eds-utils';

import './characterCounter.scss';

export default class CharacterCounter extends React.PureComponent {
    static propTypes = {
        /**
         * Value of the string that you want to counter
         */
        value: PropTypes.string,
        /**
         * Maximum length of your counter
         */
        maxLength: PropTypes.number,
        /**
         * Show / hide counter
         */
        showCounter: PropTypes.bool,
    };

    static defaultProps = {
        value: '',
        maxLength: 100,
    };

    render() {
        const { value, maxLength, showCounter } = this.props;

        if (!showCounter) {
            return null;
        }

        const remainingChars = calculateRemaining(maxLength, value);
        const approachingLimit = calculateApproachingLimit(
            maxLength,
            remainingChars,
        );
        const reachedLimit = calculateReachedLimit(remainingChars);

        const className = classNames(
            'eds-character-counter',
            'eds-fx--fade-in',
            {
                'eds-character-counter--approaching-limit': approachingLimit,
                'eds-character-counter--reached-limit': reachedLimit,
            },
        );

        return (
            <div className={className} data-spec="character-counter">
                {`${remainingChars}`}
            </div>
        );
    }
}
