import gettext from '@eb/gettext';
import ngettext from '@eb/ngettext';

import { REGISTRATION, TICKETING } from '../constants';
import { DeliveryMethod } from '../api/models/deliveryMethod';

/**
 * Used to provide the correct strings for both ticketing and registration events.
 * When adding a new string that uses ticketing terminology, you must add a
 * variation for both ticketing and registration.
 * @type {Object}
 */
export const TERMINOLOGY = {
    [TICKETING]: {
        tickets: gettext('Tickets'),
        attendeeSectionTitle: (ticketNumber) =>
            //Translators: For orders with multiple tickets, this is the ticket number. Example: Ticket 2
            gettext('Ticket %(ticketNumber)s', { ticketNumber }),
        viewTickets: gettext('View Tickets'),
        deliveryMethodDisplays: {
            [DeliveryMethod.eTicket]: gettext('eTicket'),
            [DeliveryMethod.willCall]: gettext('Will Call'),
            [DeliveryMethod.standardShipping]: gettext('Standard Shipping'),
        },
        deliveryMethodInstruction: {
            [DeliveryMethod.eTicket]: (eTicketQuantity) =>
                ngettext(
                    'Your ticket will be attached to your confirmation email. You can also access it from your Eventbrite account or mobile app.',
                    'Your tickets will be attached to your confirmation email. You can also access them from your Eventbrite account or mobile app',
                    eTicketQuantity,
                ),
            [DeliveryMethod.willCall]: (eTicketQuantity) =>
                ngettext(
                    "Your ticket will be waiting for you at the venue's pick-up location on the day of the event. Please bring photo ID and the card used for purchase.",
                    "Your tickets will be waiting for you at the venue's pick-up location on the day of the event. Please bring photo ID and the card used for purchase.",
                    eTicketQuantity,
                ),
            [DeliveryMethod.standardShipping]: (eTicketQuantity) =>
                ngettext(
                    'It should arrive no later than 48 hours before the event.',
                    'They should arrive no later than 48 hours before the event.',
                    eTicketQuantity,
                ),
        },
        deliveryMethodInstructions: {
            [DeliveryMethod.eTicket]: gettext(
                'Your tickets will be attached to your confirmation email. You can also access them from your Eventbrite account or mobile app',
            ),
            [DeliveryMethod.willCall]: gettext(
                "Your tickets will be waiting for you at the venue's pick-up location on the day of the event. Please bring photo ID and the card used for purchase.",
            ),
            [DeliveryMethod.standardShipping]: gettext(
                'They should arrive no later than 48 hours before the event.',
            ),
        },
        deliveryMethodConfirmationHeaders: {
            ticketSentTo: (eTicketQuantity) =>
                // Translators: This shows how many electronic tickets were purchased.
                // For example: "1 Ticket sent to buyer123@eventbrite.com"
                // or plural: "5 Tickets sent to buyer123@eventbrite.com"
                ngettext(
                    '%(eTicketQuantity)s Ticket sent to',
                    '%(eTicketQuantity)s Tickets sent to',
                    eTicketQuantity,
                    {
                        eTicketQuantity,
                    },
                ),
            ticketAtWillCall: (eTicketQuantity) =>
                // Translators: This shows how many tickets were purchased using "Will Call" method of delivery.
                // For example: "1 Ticket at Will Call"
                // or plural: "5 Tickets at Will Call"
                ngettext(
                    '%(eTicketQuantity)s Ticket at Will Call',
                    '%(eTicketQuantity)s Tickets at Will Call',
                    eTicketQuantity,
                    {
                        eTicketQuantity,
                    },
                ),
            ticketShipped: (eTicketQuantity) =>
                // Translators: This shows how many tickets were purchased using shipping as method of delivery.
                // For example: "1 Ticket shipping to 155 5th Street, 7th Floor, San Francisco, CA, 94103, United States"
                // or plural: "5 Tickets shipping to 155 5th Street, 7th Floor, San Francisco, CA, 94103, United States"
                ngettext(
                    '%(eTicketQuantity)s Ticket shipping to',
                    '%(eTicketQuantity)s Tickets shipping to',
                    eTicketQuantity,
                    {
                        eTicketQuantity,
                    },
                ),
        },
        accessCodeSuccessMessages: (numPromoCodeTickets) =>
            // Translators: This code gives access to hidden tickets, not a discount on tickets.
            // Translators: ACCESS_CODE applied. New ticket unlocked below.
            // Translators: ACCESS_CODE applied. New tickets unlocked below.
            ngettext(
                '%(promoCodeHtml)s applied. New ticket unlocked below.',
                '%(promoCodeHtml)s applied. New tickets unlocked below.',
                numPromoCodeTickets,
            ),
        waitlistInstructions: gettext(
            'Ticket availability is determined by the event organizer. Being added to the waitlist does not guarantee that you will be able to purchase a ticket in the future. Each waitlist entry only allows you to purchase an individual ticket when the event organizer releases the ticket.',
        ),
        ticketCategoryTitle: {
            admission: gettext('Tickets'),
            donation: gettext('Donations'),
            addon: gettext('Addons'),
        },
        seriesTickets: gettext('Tickets'),
    },
    [REGISTRATION]: {
        tickets: gettext('Registrations'),
        attendeeSectionTitle: (ticketNumber) =>
            //Translators: For orders with multiple registrations, this is the registration number. Example: Registration 2
            gettext('Registration %(ticketNumber)s', { ticketNumber }),
        viewTickets: gettext('View Registrations'),
        deliveryMethodDisplays: {
            [DeliveryMethod.eTicket]: gettext('eRegistration'),
            [DeliveryMethod.willCall]: gettext('Will Call'),
            [DeliveryMethod.standardShipping]: gettext('Standard Shipping'),
        },
        deliveryMethodInstruction: {
            [DeliveryMethod.eTicket]: (eTicketQuantity) =>
                ngettext(
                    'Your registration will be attached to your confirmation email. You can also access it from your Eventbrite account or mobile app.',
                    'Your registrations will be attached to your confirmation email. You can also access them from your Eventbrite account or mobile app.',
                    eTicketQuantity,
                ),
            [DeliveryMethod.willCall]: (eTicketQuantity) =>
                ngettext(
                    "Your registration will be waiting for you at the venue's pick-up location on the day of the event. Please bring photo ID and the card used for purchase.",
                    "Your registrations will be waiting for you at the venue's pick-up location on the day of the event. Please bring photo ID and the card used for purchase.",
                    eTicketQuantity,
                ),
            [DeliveryMethod.standardShipping]: (eTicketQuantity) =>
                ngettext(
                    'It should arrive no later than 48 hours before the event.',
                    'They should arrive no later than 48 hours before the event.',
                    eTicketQuantity,
                ),
        },
        deliveryMethodInstructions: {
            [DeliveryMethod.eTicket]: gettext(
                'Your registrations will be attached to your confirmation email. You can also access them from your Eventbrite account or mobile app.',
            ),
            [DeliveryMethod.willCall]: gettext(
                "Your registrations will be waiting for you at the venue's pick-up location on the day of the event. Please bring photo ID and the card used for purchase.",
            ),
            [DeliveryMethod.standardShipping]: gettext(
                'They should arrive no later than 48 hours before the event.',
            ),
        },
        deliveryMethodConfirmationHeaders: {
            ticketSentTo: (eTicketQuantity) =>
                // Translators: This shows how many electronic registrations were purchased.
                // For example: "1 Registration sent to buyer123@eventbrite.com"
                // or plural: "5 Registrations sent to buyer123@eventbrite.com"
                ngettext(
                    '%(eTicketQuantity)s Registration sent to',
                    '%(eTicketQuantity)s Registrations sent to',
                    eTicketQuantity,
                    {
                        eTicketQuantity,
                    },
                ),
            ticketAtWillCall: (eTicketQuantity) =>
                // Translators: This shows how many registrations were purchased using "Will Call" method of delivery.
                // For example: "1 Registration at Will Call"
                // or plural: "5 Registration at Will Call"
                ngettext(
                    '%(eTicketQuantity)s Registration at Will Call',
                    '%(eTicketQuantity)s Registrations at Will Call',
                    eTicketQuantity,
                    {
                        eTicketQuantity,
                    },
                ),
            ticketShipped: (eTicketQuantity) =>
                // Translators: This shows how many registrations were purchased using shipping as method of delivery.
                // For example: "1 Ticket shipping to 155 5th Street, 7th Floor, San Francisco, CA, 94103, United States"
                // or plural: "5 Tickets shipping to 155 5th Street, 7th Floor, San Francisco, CA, 94103, United States"
                ngettext(
                    '%(eTicketQuantity)s Registration shipping to',
                    '%(eTicketQuantity)s Registrations shipping to',
                    eTicketQuantity,
                    {
                        eTicketQuantity,
                    },
                ),
        },
        accessCodeSuccessMessages: (numPromoCodeTickets) =>
            // Translators: This code gives access to hidden registrations, not a discount on registration.
            // Translators: ACCESS_CODE applied. New registration unlocked below.
            // Translators: ACCESS_CODE applied. New registrations unlocked below.
            ngettext(
                '%(promoCodeHtml)s applied. New registration unlocked below.',
                '%(promoCodeHtml)s applied. New registrations unlocked below.',
                numPromoCodeTickets,
            ),
        waitlistInstructions: gettext(
            'Registration availability is determined by the event organizer. Being added to the waitlist does not guarantee that you will be able to purchase a registration in the future. Each waitlist entry only allows you to purchase an individual registration when the event organizer releases the registration.',
        ),
        ticketCategoryTitle: {
            admission: gettext('Registration'),
            donation: gettext('Donations'),
            addon: gettext('Addons'),
        },
        seriesTickets: gettext('Register'),
    },
};

export const PRIVACY_MESSAGE_ADDING_ATTENDEES = gettext(
    'As a reminder, the creator is responsible for compliance with privacy and marketing regulation when using this feature to upload email addresses for marketing communications',
);
