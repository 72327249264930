import { TimerAction, TimerActionType } from '../actions/timer';
import { TimerNamespace } from '../state/timer';

export const timerReducer = (
    state: TimerNamespace = {
        timerStartedAt: 0,
        remainingDuration: 0,
        isTimerExpired: false,
    },
    action: TimerAction,
) => {
    switch (action.type) {
        case TimerActionType.startBackgroundTimer:
            return {
                ...state,
                timerStartedAt: action.payload.timerStartedAt,
                remainingDuration: action.payload.remainingDuration,
                isTimerExpired: false,
            };

        case TimerActionType.updateBackgroundTimer:
            return {
                ...state,
                remainingDuration: action.payload.remainingDuration,
                isTimerExpired: action.payload.isTimerExpired,
            };

        default:
            return state;
    }
};
