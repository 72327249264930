import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { routerReducer } from 'react-router-redux';
import { addressReducer } from './address';
import { appReducer } from './app';
import { eventReducer } from './event';
import { groupRegistrationReducer } from './groupRegistration';
import { orderReducer } from './order';
import { paymentHistoryReducer } from './paymentHistory';
import { paypalReducer } from './paypal';
import pickASeatReducer from './pickASeat';
import { seriesReducer } from './series';
import { surveyReducer } from './survey';
import tokensReducer from './tokens';
import { ticketsReducer } from './tickets';
import { timerReducer } from './timer';
import { userReducer } from './user';
import { waitingRoomReducer } from './waitingRoom';

const gaSettings = (state = {}) => state;

export default combineReducers({
    address: addressReducer,
    app: appReducer,
    event: eventReducer,
    form: formReducer,
    groupRegistration: groupRegistrationReducer,
    order: orderReducer,
    paymentHistory: paymentHistoryReducer,
    paypal: paypalReducer,
    pickASeat: pickASeatReducer,
    routing: routerReducer,
    series: seriesReducer,
    tickets: ticketsReducer,
    timer: timerReducer,
    tokens: tokensReducer,
    user: userReducer,
    survey: surveyReducer,
    waitingRoom: waitingRoomReducer,
    gaSettings,
});
