import { connect } from 'react-redux';
import get from 'lodash/get';
import { getEventTitle, getFormattedEventDate } from '../../selectors/event';
import { shouldShowBackToSeriesLink } from '../series/utils';
import { navigateToSeriesPage as navigateToSeriesPageAction } from '../../actions/series';
import { setIsWaitingRoomShown as setIsWaitingRoomShownAction } from '../../actions/waitingRoom';

const _mapStateToProps = (state) => ({
    eventTitle: getEventTitle(state),
    eventDate: getFormattedEventDate(state),
    checkoutStep: get(state, 'waitingRoom.step', 'visit'),
    shouldShowBackToSeriesLink: shouldShowBackToSeriesLink(state),
});

const _mapDispatchToProps = (dispatch) => ({
    onPageDidMount: () => dispatch(setIsWaitingRoomShownAction()),
    onBackToSeries: () => dispatch(navigateToSeriesPageAction()),
});

export const connectWaitingRoomPage = (ComponentClass) =>
    connect(_mapStateToProps, _mapDispatchToProps)(ComponentClass);
