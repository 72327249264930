import PropTypes from 'prop-types';

export const VERTICAL_MARGIN_TOP = 'top';
export const VERTICAL_MARGIN_BOTTOM = 'bottom';
export const VERTICAL_MARGIN_BOTH = 'both';

export const STYLE_CONTAINER_DIVIDER = 'container-divider';
export const STYLE_CONTAINER = 'container';
export const STYLE_PROTOTYPE = 'default';
export const STYLE_ORDER_SUMMARY = 'order-summary';

export const STYLES_PROP_TYPE = PropTypes.oneOf([
    STYLE_CONTAINER,
    STYLE_PROTOTYPE,
    STYLE_CONTAINER_DIVIDER,
    STYLE_ORDER_SUMMARY,
]);
export const VERTICAL_MARGIN_TYPES = PropTypes.oneOf([
    VERTICAL_MARGIN_TOP,
    VERTICAL_MARGIN_BOTTOM,
    VERTICAL_MARGIN_BOTH,
]);
