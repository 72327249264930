import isObject from 'lodash/isObject';
import gettext from '@eb/gettext';
import { fetchEB, translateServerErrors } from '@eb/http';
import logger from '@eb/logger-bugsnag';

export const ORDER_TOKEN_HEADER = 'X-Order-Token';
export const GUEST_TOKEN_HEADER = 'X-Guest-Token';
export const WAITING_ROOM_TOKEN_HEADER = 'X-WR-Token';
export const WAITING_ROOM_TOKEN_DELIMITER = ':';
const DEFAULT_ERROR_SPEC = {
    default: () => gettext('Something went wrong'),
};

const _handleFetchSuccess = (response) => {
    const responseHeaders = response.headers;

    if (response.status === 204) {
        return Promise.resolve();
    }

    return response
        .json()
        .then((responseData) =>
            Promise.resolve({
                response: responseData,
                headers: responseHeaders,
            }),
        )
        .catch(async (error) => {
            let responseNotSerializable;

            try {
                responseNotSerializable = await response.text();
            } catch (e) {
                responseNotSerializable = 'failed to get response as text.';
            }

            logger.error('response cannot be serialized as json', {
                error,
                response: responseNotSerializable,
                url: response.url,
            });
        });
};

const _hasArgumentsError = (responseData) =>
    isObject(responseData['error_detail']) &&
    isObject(responseData['error_detail']['ARGUMENTS_ERROR']);

/**
 * Parse v3 errors into an array of objects representing the errors returned by
 * the API. The format of the parsed errors looks like yea:
 *
 *  {
 *      error: 'ERROR_CODE',
 *      description: 'Description of the error
 *  }
 *
 * An ARGUMENTS_ERROR looks like:
 *
 *  {
 *      error: 'ARGUMENTS_ERROR',
 *      description: 'Some of the fields were invalid or something',
 *      argumentErrors: {
 *          attr1: ['INVALID'],
 *          attr2: ['This field is required']
 *      }
 *  }
 *
 * @param responseData json parsed data from the response
 * @returns object error
 */
const parseError = (responseData) => {
    if (!responseData.error) {
        // Weird error format, return null
        return null;
    }

    const error = {
        error: responseData.error,
        description: responseData['error_description'],
    };

    if (_hasArgumentsError(responseData)) {
        error.argumentErrors = responseData['error_detail']['ARGUMENTS_ERROR'];
    }

    return error;
};

/**
 * Designed to work with `checkStatus` in utils/http, or any function that
 * raises an error on an invalid status. The error raised should have a `response`
 * property with the original response object.
 *
 * catchStatusError will parse the error response, then raise a `V3APIError` with
 * the result.
 *
 * Example usage:
 *
 * fetchJSON('/api/v3/test/path', {'body': someData})
 *     .catch(catchStatusError)
 *     .catch(({response, parsedError}) => doSomethingOnError())
 *     .then(doSomethingOnSuccess);
 *
 *
 * @param response Response object a la Fetch API
 */
export const catchStatusError = (response) =>
    new Promise((resolve, reject) => {
        response
            .json()
            .then((responseData) => Promise.resolve(parseError(responseData)))
            .then((parsedError) => reject({ response, parsedError }))
            .catch(() => reject({ response }));
    });

/**
 * Return headers object populated with tokens needed to make order
 * flow api calls. If any tokens are absent from the input object,
 * they will be omitted from the result.
 *
 * @param {object} tokens object optionally containing guestToken, orderToken, and waitingRoomToken.
 * Any entries which are present will be included in headers.
 */
export const getOrderFlowHeaders = ({
    guestToken,
    orderToken,
    waitingRoomToken,
}) => {
    const headers = {};

    if (guestToken) {
        headers[GUEST_TOKEN_HEADER] = guestToken;
    }

    if (orderToken) {
        headers[ORDER_TOKEN_HEADER] = orderToken;
    }

    if (waitingRoomToken) {
        headers[WAITING_ROOM_TOKEN_HEADER] = waitingRoomToken;
    }

    return headers;
};

/**
 * Wraps fetchV3 requests with specially formatted error handling for redux-form in accordance with
 * our validation library.
 *
 * Error spec example:
 *
 * EXAMPLE_ERROR_SPEC = {
 *   default: () => 'default',
 *   ERROR_MESSAGE: () => 'Undefined is not a function.',
 * };
 *
 * @param {object} errorSpec Object of key/value pairs where key is error name and value is
 * localized error message string
 * @param {string} url
 * @param {object} options
 * @returns {Promise}
 */
export const fetchV3WithTranslateServerErrors = (
    errorSpec = {},
    url,
    { headers, method, ...options } = {},
) => {
    let fetchHeaders = headers;

    if (method && method !== 'GET') {
        fetchHeaders = {
            'Content-Type': 'application/json',
            ...headers,
        };
    }

    const fetchOptions = {
        headers: fetchHeaders,
        method: method || 'GET',
        ...options,
    };

    return fetchEB(url, fetchOptions)
        .catch(catchStatusError)
        .catch(translateServerErrors({ ...DEFAULT_ERROR_SPEC, ...errorSpec }))
        .then(_handleFetchSuccess);
};
