export const addErrorReportingTag = ({
    name,
    value,
}: {
    name: string;
    value: string;
}) => {
    if (window.bugsnagClient) {
        window.bugsnagClient.metaData = window.bugsnagClient.metaData || {};
        window.bugsnagClient.metaData.tags =
            window.bugsnagClient.metaData.tags || {};
        window.bugsnagClient.metaData.tags[name] = value;
    }
};
