import { HAS_WINDOW } from '@eb/eds-utils';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import React from 'react';
import groupBy from 'lodash/groupBy';
import gettext from '@eb/gettext';
import { formatMajorMoney } from '@eb/intl';
import { TierColorCircle } from '../tierColorCircle/TierColorCircle';
import { TINY_COLOR_CIRCLE_SIZE } from '../tierColorCircle/constants';
import {
    ATTENDEE_PAYER,
    FREE_TEXT,
    SERVICE_FEE_COMPONENT_NAME,
    TAX_COMPONENT_BASE_FEES_TAX_INCLUDABLE,
    TAX_PROCESSING_FEE_COMPONENT_NAME,
    TAX_SERVICE_FEE_COMPONENT_NAME,
    TICKET_CLASS_CATEGORIES,
} from '../../constants';
import { getBreakdownItems } from '../../containers/pane/utils';
import { getQuantityBySelectedDeliveryMethod } from '../../utils/deliveryMethod';
import { getTotalDiscountingVirtualIncentives } from '../../utils/virtualIncentives';

export const getTaxOnFeeComponents = (feeComponents = [], taxComponents) => {
    const taxFeeComponents = feeComponents;
    const filteredTaxComponents = taxComponents.filter(
        (e) =>
            e.base === TAX_COMPONENT_BASE_FEES_TAX_INCLUDABLE ||
            ([
                TAX_SERVICE_FEE_COMPONENT_NAME,
                TAX_PROCESSING_FEE_COMPONENT_NAME,
            ].includes(e.groupName) &&
                e.payer == ATTENDEE_PAYER),
    );
    const feeTax = filteredTaxComponents.reduce(
        (total, component) => total + component.value,
        0,
    );
    const esfComponent = taxFeeComponents.find(
        (e) => e.name === SERVICE_FEE_COMPONENT_NAME,
    );
    const index = taxFeeComponents.findIndex(
        (e) => e.name === SERVICE_FEE_COMPONENT_NAME,
    );

    // Only add tax to ESF component if not already included
    if (!isEmpty(esfComponent) && !esfComponent.includesTax) {
        esfComponent.value += feeTax;
        esfComponent.includesTax = true;
        taxFeeComponents.splice(index, 1);
        taxFeeComponents.push(esfComponent);
    }

    return taxFeeComponents;
};

const getVariants = (tickets) =>
    tickets.reduce((acc, { variants = [] }) => [...acc, ...variants], []);

export const getShippingFees = (tickets = []) =>
    getVariants(tickets)
        .filter(({ displayShippingFee }) => displayShippingFee?.value)
        .map((variant) => variant.displayShippingFee);

const addUpFees = (acc, fee) => ({
    ...acc,
    [fee.name]: {
        ...fee,
        value: (acc[fee.name]?.value || 0) + fee.value,
    },
});

const getComputedValueFees = (
    acc,
    { selectedQuantity, feeComponents = [] },
) => [
    ...acc,
    ...feeComponents.map(({ name, groupName, payer, value }) => ({
        name: groupName || name,
        payer,
        value: value.value * selectedQuantity,
    })),
];

export const getFeeComponents = (tickets = []) =>
    Object.values(
        getVariants(tickets)
            .reduce(getComputedValueFees, [])
            .reduce(addUpFees, {}),
    );

const getAttendeeFaceValueCost = (attendee) => attendee.costs.gross;

export const getOrderSummarySubContent = (
    attendeeTicketClass,
    ticketDisplayCost,
) => {
    const { category, reservedSeating } = attendeeTicketClass;

    let content;
    let colorCircle = null;

    if (ticketDisplayCost === FREE_TEXT) {
        content = gettext('%(ticketDisplayCost)s', { ticketDisplayCost });
    } else {
        content = gettext('%(ticketDisplayCost)s each', { ticketDisplayCost });
    }

    if (category === TICKET_CLASS_CATEGORIES.ADMISSION && reservedSeating) {
        colorCircle = (
            <span className="eds-l-mar-right-2">
                <TierColorCircle
                    tierColor={reservedSeating.color}
                    colorCircleSize={TINY_COLOR_CIRCLE_SIZE}
                />
            </span>
        );
    }

    return (
        <span className="eds-align--center-vertical">
            {colorCircle}
            {content}
        </span>
    );
};

const getOrderSummaryItems = (
    attendeesGroupByTicketClass,
    priceWithTaxesAndFees,
    currency,
    currencyFormat,
    shouldHandleFreeTickets,
) =>
    Object.keys(attendeesGroupByTicketClass).map((variantId) => {
        const ticketTypeAttendees =
            attendeesGroupByTicketClass[variantId] || [];
        const attendee = ticketTypeAttendees.length && ticketTypeAttendees[0];

        if (
            !attendee ||
            (!attendee.costs && attendee.cost === undefined) ||
            !attendee.ticketClass
        ) {
            return {};
        }
        const isFree = attendee.ticketClass.free;
        const quantity = ticketTypeAttendees.length;
        const attendeeFaceValueCost = getAttendeeFaceValueCost(attendee);

        let subcontentDisplayPrice = FREE_TEXT;
        let itemSubTotal = FREE_TEXT;

        if (
            !isFree ||
            (shouldHandleFreeTickets && attendeeFaceValueCost.value > 0)
        ) {
            subcontentDisplayPrice = attendeeFaceValueCost.display;
            itemSubTotal = formatMajorMoney(
                attendeeFaceValueCost.value * quantity,
                currency,
                currencyFormat,
            );
        }

        return {
            content: attendee.ticketClassName,
            subcontent: getOrderSummarySubContent(
                attendee.ticketClass,
                subcontentDisplayPrice,
            ),
            value: itemSubTotal,
            id: variantId,
            quantity: quantity.toString(),
            color: get(attendee, 'ticketClass.reservedSeating.color', null),
        };
    });

const getDisplayFeeValue = (orderCosts) => orderCosts.displayFee?.value ?? 0;

const getTotalFeeComponentsCost = (feeComponents) =>
    feeComponents.reduce((total, fee) => total + fee.value, 0);

const getTotalFees = (shippingFees, orderCosts, feeComponents = []) =>
    isEmpty(shippingFees)
        ? getDisplayFeeValue(orderCosts)
        : getTotalFeeComponentsCost(feeComponents);

const getTotalCostWithShipping = ({
    shippingRates = [],
    selectedDeliveryMethods = [],
    currency,
    currencyFormat,
    orderCosts,
    selectedVirtualIncentives,
}) => {
    let totalWithShippingFee = shippingRates.reduce(
        (acc, { name, price: { value } }) =>
            // eslint-disable-next-line no-param-reassign
            (acc += selectedDeliveryMethods.includes(name) ? value : 0),
        orderCosts.gross.value,
    );

    if (!isEmpty(selectedVirtualIncentives)) {
        totalWithShippingFee = getTotalDiscountingVirtualIncentives(
            totalWithShippingFee,
            selectedVirtualIncentives,
        );
    }

    return formatMajorMoney(totalWithShippingFee, currency, currencyFormat);
};

/**
 * @param {Object}
 * - attendees: {Array} The attendees are fetched with `attendees.ticket_class.reserved_seating` expansion from `order` v3 API.
 * - orderCosts: {Object} Object from state.order.costs include: {displayPrice, displayFee, tax, gross}
 * - currencyFormat: {String} currencyFormat
 * - selectedDeliveryMethods: {Array} `selectedDeliveryMethods` from `ticket`, default to be []
 * - priceWithTaxesAndFees: {Boolean} Indicating if the ticket price includes fees and taxes (set it to true if selling at the door through organizer app), default to be false
 * - shouldHandleFreeTickets {Boolean} Indicating if we need to take into account the fact that free tickets can have an amount > 0 (this can happen with Manual orders)
 */

export const getReservedSeatingOrderSummary = ({
    attendees = [],
    currencyFormat,
    orderCosts,
    priceWithTaxesAndFees = false,
    selectedDeliveryMethods = [],
    shippingRates,
    shippingFees,
    shouldHandleFreeTickets = false,
    ticketGroups,
    selectedVirtualIncentives,
    unformattedOrderCost,
}) => {
    const title = gettext('Order summary');
    const totalContent = gettext('Total');
    const currency = orderCosts.gross.currency;

    const shouldDisplayTax =
        orderCosts.displayTax || (orderCosts.tax || {}).displayTax;
    const taxBeforeExemption = (orderCosts.tax || {}).taxBeforeExemption;

    const hasGtsTax = orderCosts.hasGtsTax;
    let taxOnFee;
    const feeComponents = (orderCosts.feeComponents || []).filter(
        ({ payer }) => payer === 'attendee',
    );

    if (orderCosts.taxComponents) {
        taxOnFee = orderCosts.taxComponents.find(
            (component) =>
                component.base === TAX_COMPONENT_BASE_FEES_TAX_INCLUDABLE,
        );
    }

    const getEventTaxName = () => {
        let eventTaxName = shouldDisplayTax.name;

        if (hasGtsTax) {
            eventTaxName = gettext('Sales tax');
        }

        return eventTaxName;
    };

    // TODO: Resolve duplicate logic in `containers/Pane.js:_getTotalCostInfo`
    // This can be refactored into a selector in `selectors/tickets.js`
    const getTotalCostInfo = () => {
        let totalCostInfo = null;

        if (taxOnFee) {
            totalCostInfo = gettext('Price includes tax');
        } else if (hasGtsTax && !shouldDisplayTax) {
            totalCostInfo = gettext('Incl. sales tax');
        }

        return totalCostInfo;
    };

    // Need to obtain how many tickets were selected by every delivery method
    const quantitySelectedDeliveryMethods = getQuantityBySelectedDeliveryMethod(
        ticketGroups,
        selectedDeliveryMethods,
    );

    const breakdownItems = getBreakdownItems({
        currency,
        currencyFormat,
        eventTaxName: shouldDisplayTax ? getEventTaxName() : null,
        feeComponents: taxOnFee
            ? getTaxOnFeeComponents(feeComponents, orderCosts.taxComponents)
            : feeComponents,
        //when free ticket, backend doesn't provide `display_fee`
        fees: getTotalFees(shippingFees, orderCosts, feeComponents),
        //when free ticket, backend doesn't provide `display_price`
        fullPrice: orderCosts.displayPrice ? orderCosts.displayPrice.value : 0,
        quantitySelectedDeliveryMethods,
        selectedDeliveryMethods,
        shippingRates,
        shippingFees,
        taxComponents: orderCosts.taxComponents,
        //when order doesn't have tax, backend doesn't provide `display_tax`
        taxes: shouldDisplayTax ? (shouldDisplayTax.tax || {}).value : 0,
        taxBeforeExemption,
        selectedVirtualIncentives,
        unformattedOrderCost,
    });

    return {
        currency: orderCosts.gross.currency,
        breakdownItems,
        items:
            attendees.length > 0
                ? getOrderSummaryItems(
                      groupBy(attendees, 'variantId'),
                      priceWithTaxesAndFees,
                      currency,
                      currencyFormat,
                      shouldHandleFreeTickets,
                  )
                : [],
        title,
        totalContent,
        totalCostInfo: getTotalCostInfo(),
        totalValue: getTotalCostWithShipping({
            shippingRates,
            selectedDeliveryMethods,
            currency,
            currencyFormat,
            orderCosts,
            selectedVirtualIncentives,
        }),
    };
};

export const doesElementHaveTooMuchContent = (element) => {
    if (!element || !HAS_WINDOW) {
        return false;
    }

    const offsetHeight = element.offsetHeight;
    const scrollHeight = element.scrollHeight;
    // EB-85619 content inside an iframe in iOS has a 1 pixel deviation
    const sameHeight =
        Math.max(offsetHeight, scrollHeight) -
            Math.min(offsetHeight, scrollHeight) <
        2;

    return !sameHeight;
};

/**
 * Get a list of `assignedUnit` with ticket class id included
 *
 * @param {Array} attendees props retrieved from `checkoutWidget/containers/ticketSelection/utils/ticketUtils.js:getReservedSeatingAttendees`
 *
 * @return {Array} assignedUnits with `ticketClassId`
 */
export const getAssignedUnitsWithTicketId = (attendees = []) =>
    attendees.map(({ assignedUnit, ticketClassId }) => ({
        ...assignedUnit,
        ticketClassId,
    }));
