import map from 'lodash/map';
import { Schema, arrayOf } from 'normalizr';
import { transformUtil } from '@eb/transformation-utils';

const TICKET_SCHEMA = new Schema('tickets');

const FEE_COMPONENT_SCHEMA = new Schema('fee_components');

const PAYMENT_CONSTRAINT_SCHEMA = new Schema('payment_constraints');

TICKET_SCHEMA.define({
    feeComponent: arrayOf(FEE_COMPONENT_SCHEMA),
    paymentConstraints: arrayOf(PAYMENT_CONSTRAINT_SCHEMA),
});

const TICKETS_SCHEMA = {
    tickets: arrayOf(TICKET_SCHEMA),
};

const FEE_COMPONENT_FIELDS = ['name', 'value', 'group_name', 'payer'];

const PAYMENT_CONSTRAINT_FIELDS = ['payment_method', 'instrument_type'];

const TAX_COMPONENTS_FIELDS = ['base'];

export interface TicketsResponseOnsaleStatus {
    end_sales: string;
    end_sales_with_tz: {
        timezone: string;
        utc: string;
    };
    ended: boolean;
    is_ticket_tiered: boolean;
    near_sales_end: boolean;
    not_on_sale: boolean;
    not_yet_started: boolean;
    sold_out_with_waitlist: boolean;
    start_sales: string;
    start_sales_with_tz: {
        timezone: string;
        utc: string;
    };
    unavailable: boolean;
}

export interface TicketsResponseTicket {
    characteristics: {
        default_quantity: number;
    };
    currency: string | null;
    variant_input_type: string;
    id: string;
    maximum_quantity_per_order: number;
    total_cost: {
        currency: string;
        major_value: string;
        value: number;
    };
    total_cost_without_shipping: {
        currency: string;
        major_value: string;
        value: number;
    };
    delivery_methods: Array<string>;
    description: any | null; // TODO: Find proper type for this
    onsale_status: TicketsResponseOnsaleStatus;
    free: boolean;
    fee_components: Array<{
        name: string;
        payer: string;
        value: {
            currency: string;
            value: number;
            display: string;
        };
        base: string;
        group_name: string;
    }>;
    discount_id: any | null; // TODO: Find proper type for this
    display_flags: {
        show_sales_end_date: boolean;
        show_remaining: boolean;
        show_waitlist: boolean;
    };
    display_shipping_fee: {
        currency: string;
        major_value: string;
        value: number;
        display: string;
    };
    tax: {
        currency: string;
        major_value: string;
        value: number;
        display: string;
    };
    tax_without_shipping: {
        currency: string;
        major_value: string;
        value: number;
        display: string;
    };
    variants: Array<{
        maximum_quantity_per_order: number;
        ended: boolean;
        sold_out_with_waitlist: boolean;
        is_ticket_tiered: boolean;
        primary: boolean;
        ticket_options_range: Array<number>;
        minimum_quantity: number;
        not_yet_started: boolean;
        start_sales_with_tz: {
            timezone: string;
            utc: string;
        };
        unavailable: boolean;
        checkout_group_id: string;
        onsale_status: TicketsResponseOnsaleStatus;
        id: string;
    }>;
    include_fee: boolean;
    name: string;
    ticket_options_range: Array<number>;
    on_sale_status: string;
    minimum_quantity: number;
    category: string;
}

export interface TicketsResponseEvent {
    show_remaining: boolean;
    currency: string;
    id: string;
    url: string;
}

export interface TicketsResponse {
    tickets: Array<TicketsResponseTicket>;
    event: TicketsResponseEvent;
}

export interface TicketOnsaleStatus {
    endSales: string;
    endSalesWithTz: {
        timezone: string;
        utc: string;
    };
    ended: boolean;
    isTicketTiered: boolean;
    nearSalesEnd: boolean;
    notOnSale: boolean;
    notYetStarted: boolean;
    soldOutWithWaitlist: boolean;
    startSales: string;
    startSalesWithTz: {
        timezone: string;
        utc: string;
    };
    unavailable: boolean;
}

export interface Ticket {
    characteristics: {
        defaultQuantity: number;
    };
    currency: string | null;
    variantInputType: string;
    id: string;
    maximumQuantityPerOrder: number;
    totalCost: any | null; // TODO: Find proper type for this
    deliveryMethods: Array<string>;
    description: any | null; // TODO: Find proper type for this
    onsaleStatus: TicketsResponseOnsaleStatus;
    free: boolean;
    feeComponents: Array<{
        name: string;
        payer: string;
        value: {
            currency: string;
            value: number;
            display: string;
        };
        base: string;
        groupName: string;
    }>;
    discountId: any | null; // TODO: Find proper type for this
    displayFlags: {
        showSalesEndDate: boolean;
        showRemaining: boolean;
        showWaitlist: boolean;
    };
    displayShippingFee: {
        currency: string;
        majorValue: string;
        value: number;
        display: string;
    };
    variants: Array<{
        maximumQuantityPerOrder: number;
        ended: boolean;
        soldOutWithWaitlist: boolean;
        isTicketTiered: boolean;
        primary: boolean;
        ticketOptionsRange: Array<number>;
        minimumQuantity: number;
        notYetStarted: boolean;
        startSalesWithTz: {
            timezone: string;
            utc: string;
        };
        unavailable: boolean;
        checkoutGroupId: string;
        onsaleStatus: TicketsResponseOnsaleStatus;
        id: string;
    }>;
    includeFee: boolean;
    name: string;
    ticketOptionsRange: Array<number>;
    onSaleInfo: string;
    minimumQuantity: number;
    category: string;
}

const TICKETS_RESPONSE_FIELDS = [
    'id',
    'name',
    'maximum_quantity_per_order',
    'currency',
    'color',
    'cost',
    'minimum_quantity',
    'characteristics',
    'default_quantity',
    'quantity_remaining',
    'display_flags',
    'display_shipping_fee',
    'show_remaining',
    'free',
    'primary',
    'description',
    'include_fee',
    'include_tax',
    'has_gts_tax',
    'show_sales_end_date',
    'show_waitlist',
    // string text that represents the ticket on sale status
    'on_sale_status',
    // object containing useful information about ticket on sale status
    'onsale_status',
    'is_ticket_tiered',
    'end_sales_with_tz',
    'utc',
    'timezone',
    'sold_out_with_waitlist',
    'start_sales_with_tz',
    'unavailable',
    'total_cost',
    'total_cost_without_shipping',
    'cost',
    'display',
    'value',
    'fee',
    'fee_components',
    'tax',
    'tax_without_shipping',
    'tax_components',
    'event_tax_name',
    'tickets',
    'variants',
    'variant_input_type',
    'ticket_options_range',
    'use_all_in_price',
    'delivery_methods',
    'donation',
    'category',
    'checkout_group_id',
    'image',
    'url',
    'original',
    'end_sales',
    'start_sales',
    'ended',
    'not_on_sale',
    'not_yet_started',
    'sales_start_after',
    'near_sales_end',
    'display_name',
    // Discount fields
    'amount_off',
    'code',
    'discount_id',
    'discount_type',
    'original_cost',
    'percent_off',
    // Group settings keys
    'group_settings',
    'default_hidden',
    'required',
    'hide_sale_dates',
    'payment_constraints',
];

const TICKETS_FIELDS_NAME_MAP = {
    onsale_status: 'on_sale_info',
};

/**
 * Transforms the service response into a map by ID, performs a whiteList to remove unwanted fields,
 * and finally applies camelcase to each key.
 **/
export const transformTicketsData = (data: TicketsResponse) => {
    const transformedTickets = transformUtil({
        response: data,
        schema: TICKETS_SCHEMA,
        fields: [
            ...TICKETS_RESPONSE_FIELDS,
            ...FEE_COMPONENT_FIELDS,
            ...TAX_COMPONENTS_FIELDS,
            ...PAYMENT_CONSTRAINT_FIELDS,
        ],
        fieldsNameMap: TICKETS_FIELDS_NAME_MAP,
        isWhiteList: true,
    });

    if (!transformedTickets) {
        return null;
    }

    return {
        tickets: transformedTickets.entities.tickets as Record<string, Ticket>,
        ticketIds: map(data.tickets, (ticket) => ticket.id),
    };
};
