import PropTypes from 'prop-types';
import { translationPropType } from '@eb/i18n';
import gettext from '@eb/gettext';
export const SOLD_OUT_STATUS = 'soldOut';
export const UNAVAILABLE_STATUS = 'unavailable';
export const SALES_ENDED_STATUS = 'salesEnded';
export const SALES_NOT_STARTED_STATUS = 'salesNotStarted';
export const SALES_START_SOON_STATUS = 'salesStartSoon';
export const SALES_START_AFTER_STATUS = 'salesStartAfter';
export const ON_SALE_STATUS = 'onSale';
export const SALES_END_SOON_STATUS = 'salesEndSoon';

const SALES_START_PROP_KEY = 'salesStart';
const SALES_END_PROP_KEY = 'salesEnd';
const SALES_ENDED_PROP_KEY = 'salesEnded';
const SOLD_OUT = {
    displayStatus: gettext('Sold Out'),
    displayText: gettext('Sold out'),
};
const UNAVAILABLE = {
    displayStatus: gettext('On Sale'),
    displayText: gettext('Unavailable'),
};
const SALES_ENDED = {
    displayStatus: gettext('Ended'),
    displayText: gettext('Sales ended'),
    datePropKey: SALES_ENDED_PROP_KEY,
};
const SALES_NOT_STARTED = {
    displayStatus: gettext('Scheduled'),
    displayText: gettext('Starts on %(date)s'),
    datePropKey: SALES_START_PROP_KEY,
};
const SALES_START_SOON = {
    displayStatus: gettext('Scheduled'),
    displayText: gettext('Starts in %(date)s'),
    datePropKey: SALES_START_PROP_KEY,
};
const SALES_START_AFTER = {
    displayStatus: gettext('Scheduled'),
    displayText: gettext('Sales not started'),
    datePropKey: SALES_START_PROP_KEY,
};
const ON_SALE = {
    displayStatus: gettext('On Sale'),
    displayText: gettext('Ends on %(date)s'),
    datePropKey: SALES_END_PROP_KEY,
};
const SALES_END_SOON = {
    displayStatus: gettext('On Sale'),
    displayText: gettext('Ends in %(date)s'),
    datePropKey: SALES_END_PROP_KEY,
};

export const TICKET_STATUS_MAP = {
    [SOLD_OUT_STATUS]: SOLD_OUT,
    [UNAVAILABLE_STATUS]: UNAVAILABLE,
    [SALES_ENDED_STATUS]: SALES_ENDED,
    [SALES_NOT_STARTED_STATUS]: SALES_NOT_STARTED,
    [SALES_START_SOON_STATUS]: SALES_START_SOON,
    [SALES_START_AFTER_STATUS]: SALES_START_AFTER,
    [ON_SALE_STATUS]: ON_SALE,
    [SALES_END_SOON_STATUS]: SALES_END_SOON,
};

export const DISABLE_STATUSES = [
    UNAVAILABLE_STATUS,
    SOLD_OUT_STATUS,
    SALES_ENDED_STATUS,
];

export const NOT_AVAILABLE_FOR_SALE_STATUSES = [
    SALES_ENDED_STATUS,
    SALES_NOT_STARTED_STATUS,
    SALES_START_SOON_STATUS,
    SALES_START_AFTER_STATUS,
    SOLD_OUT_STATUS,
    UNAVAILABLE_STATUS,
];

export const STATUSES = [
    SOLD_OUT_STATUS,
    UNAVAILABLE_STATUS,
    SALES_ENDED_STATUS,
    SALES_NOT_STARTED_STATUS,
    SALES_START_SOON_STATUS,
    SALES_START_AFTER_STATUS,
    ON_SALE_STATUS,
    SALES_END_SOON_STATUS,
];

export const STATUSKEY_PROP_TYPE = PropTypes.oneOf(STATUSES);

export const DATA_PROP_TYPES = {
    /**
     * ID of the ticket to be passed up along with the selected value in the callback
     */
    id: PropTypes.string.isRequired,
    /**
     * The name of the ticket to be displayed
     */
    name: PropTypes.oneOfType([translationPropType, PropTypes.node]).isRequired,
    /**
     * The currently selected quantity of the ticket. Should represent current state of the
     * select box.
     */
    selectedQuantity: PropTypes.number.isRequired,
    /**
     * A string representing the price of the ticket. Should include any necessary currency
     * information as well.
     */
    displayPrice: translationPropType,
    /**
     * Max allowed ticket quantity that can be selected in a single order
     */
    maximumQuantity: PropTypes.number,
    /**
     * Min allowed ticket quantity that can be selected in a single order
     */
    minimumQuantity: PropTypes.number,
    /**
     * Whether ticket select dropdown should be disabled or not. Dropdown is enabled by default
     */
    isDisabled: PropTypes.bool,
    /**
     * The number of tickets remaining for this specific ticket type. If passed in, it will be displayed.
     */
    quantityRemaining: PropTypes.number,
    /**
     * The string representing the associated fees and taxes. Should include the appropriate
     * copy. If not included, the section will not be rendered.
     */
    feeAndTax: PropTypes.array,

    /* Props going to ExpansionPanel */
    /**
     * Description for a the given ticket. If not included, no description will be rendered.
     */
    description: PropTypes.node,
    /**
     * Price range of ticket (Ex. with multiple public discounts, $5.00 - $10.00)
     */
    priceRange: translationPropType,

    /**
     * Represents the original cost of the ticket prior to discounts applied
     */
    primaryPrice: PropTypes.string,

    /* Props related to status */

    /**
     * A key tied to the ticketStatus map located in ticketStatusConstants.js. If nothing is passed in, then
     * no status will be displayed. Otherwise will render the associated status and also hide the
     * appropriate components as well.
     */
    statusKey: STATUSKEY_PROP_TYPE,
    /**
     * The end datetime for ticket sales to be shown. Only required when certain statuses need
     * to be rendered.
     */
    salesEnd: translationPropType,
    /**
     * The start datetime for ticket sales. Only required when rendering a status that needs the
     * date.
     */
    salesStart: translationPropType,
    /**
     * This is a necessary flag for cases where the tickets may have a status causing elements to appear
     * or disappear but the status itself should not be displayed. An example of this is event-wide
     * sales not started or sales ended. Each ticket should not display it individually, but no select
     * should appear.
     */
    shouldHideStatus: PropTypes.bool,
    /**
      If the ticket is a donation ticket
     */
    donation: PropTypes.bool,
    /**
      Amount on the donation ticket
     */
    donationAmount: PropTypes.number,
    /**
     * Currency type
     */
    currency: PropTypes.string,
    /**
     * How the currency is formatted
     */
    currencyFormat: PropTypes.string,
    /**
     * Symbol for the currency prefix or suffix
     */
    currencySymbol: PropTypes.string,
    /**
     * Whether the currency symbol is prefix or suffix
     */
    isCurrencySuffix: PropTypes.bool,
    /**
     * if fees are included in a ticket price
     */
    includeFee: PropTypes.bool,
    /**
     * if taxes are included in a ticket price
     */
    includeTax: PropTypes.bool,
    /**
     * if Join Waitlist button show be displayed
     */
    showJoinWaitlist: PropTypes.bool,
    /**
     * if it is a tiered, dependent ticket
     */
    isTicketTiered: PropTypes.bool,
    /**
     * Image attributes
     */
    image: PropTypes.shape({
        id: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
    }),
    /**
     * Category type. Current support value 'admission', 'add_on' and 'donation'
     */
    category: PropTypes.string,
    /**
     * The range to be converted to selection options. This array is computed by the backend
     * which incorporates mininum and maximum values set on the ticket level by the creator,
     * as well as other inputs like waitlist data. If additional behavior is required make sure
     * to add to the appropriate methods in Ticket Availability Service, eg.
     * get_ticket_options_range()
     */
    ticketOptionsRange: PropTypes.arrayOf(PropTypes.number),
};

export const DATA_PROP_TYPES_SHAPE = PropTypes.shape(DATA_PROP_TYPES);

export const INVENTORY_TIER_TYPE_SHAPE = PropTypes.shape({
    category: PropTypes.string,
    checkoutGroupId: PropTypes.string,
    currency: PropTypes.string,
    deliveryMethods: PropTypes.object,
    description: PropTypes.string,
    discountId: PropTypes.string,
    displayFlags: PropTypes.object,
    id: PropTypes.string,
    isDisabled: PropTypes.bool,
    minimumQuantity: PropTypes.number,
    name: PropTypes.string,
    onSaleInfo: PropTypes.object,
    selectedQuantity: PropTypes.number,
    ticketOptionsRange: PropTypes.array,
    totalCost: PropTypes.object,
    variantInputType: PropTypes.string,
    variants: PropTypes.array,
});

export const DONATION_FEE_TAX_COPIES = {
    feePassedOn: gettext(
        'Fees will be calculated before you place your order.',
    ),
    feeAbsorbed: gettext('Fees will be deducted from your donation amount.'),
    feeAndTaxPassedOn: gettext(
        'Fees and taxes will be calculated before you place your order.',
    ),
    feeAndTaxAbsorbed: gettext(
        'Fees and taxes will be deducted from your donation amount.',
    ),
    feeAbsorbedTaxPassedOn: gettext(
        'Fees will be deducted from your donation amount but taxes will be calculated before you place your order.',
    ),
    feePassedOnTaxAbsorbed: gettext(
        'Taxes will be deducted from your donation amount but fees will be calculated before you place your order.',
    ),
};
