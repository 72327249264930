import React from 'react';
import { translationPropType } from '@eb/i18n';

import { TYPE_BUTTON } from '@eb/eds-button';
import { Button } from '@eb/eds-button';

import PropTypes from 'prop-types';

import './ConfirmDialog.scss';

export default class ConfirmDialog extends React.PureComponent {
    static propTypes = {
        infoMessage: translationPropType,
        confirmButtonText: translationPropType.isRequired,
        cancelButtonText: translationPropType.isRequired,
        onCancelButtonClick: PropTypes.func.isRequired,
        onConfirmButtonClick: PropTypes.func.isRequired,
    };

    render() {
        const {
            infoMessage,
            confirmButtonText,
            cancelButtonText,
            onCancelButtonClick,
            onConfirmButtonClick,
        } = this.props;

        return (
            <div className="confirm-widget">
                <div
                    data-spec="confirm-widget-info-message"
                    className="eds-l-pad-bot-6"
                >
                    {infoMessage}
                </div>
                <div className="eds-g-grid eds-l-pad-bot-20">
                    <div className="eds-g-cell eds-g-cell-6-12 eds-l-pad-hor-2">
                        <Button
                            data-spec="confirm-widget-cancel-button"
                            type={TYPE_BUTTON}
                            onClick={onCancelButtonClick}
                            size="block"
                            data-automation="checkout-widget-return-to-checkout-button"
                        >
                            {cancelButtonText}
                        </Button>
                    </div>
                    <div className="eds-g-cell eds-g-cell-6-12 eds-l-pad-hor-2">
                        <Button
                            data-spec="confirm-widget-confirm-button"
                            type={TYPE_BUTTON}
                            style="fill"
                            size="block"
                            onClick={onConfirmButtonClick}
                            data-automation="checkout-widget-leave-checkout-button"
                        >
                            {confirmButtonText}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}
