import React from 'react';
import gettext from '@eb/gettext';

import { TYPE_BUTTON } from '@eb/eds-button';
import { Button } from '@eb/eds-button';
import { Icon } from '@eb/eds-icon';
import ConfirmDialog from './ConfirmDialog';

import {
    WAITLIST_DISABLED,
    WAITLIST_LINK_EXPIRED,
    WAITLIST_LINK_INVALID,
    WAITLIST_LINK_CANCELED,
    WAITLIST_LINK_USED,
} from '../../constants';

import { Ticket } from '@eb/eds-iconography';

export const CONFIRM_BACK_STATUS = 'confirmBack';
export const CONFIRM_BACK_TO_DELIVERY_METHOD_STATUS =
    'confirmBackToDeliveryMethod';
export const CONFIRM_BACK_TO_PICK_A_SEAT_STATUS = 'confirmBackToPickASeat';
export const CONFIRM_BACK_TO_RESERVED_TICKET_CONFIRMATION_STATUS =
    'confirmBackToReservedTicketConfirmation';
export const CONFIRM_CLOSE_MODAL = 'closeModal';
export const CONFIRM_CLOSE_STATUS = 'confirmClose';
export const CONFIRM_CLOSE_TO_DELIVERY_METHOD_STATUS =
    'confirmCloseToDeliveryMethod';
export const CONFIRM_CLOSE_TO_LOGIN_STATUS = 'confirmCloseForLogin';
export const CONFIRM_CLOSE_TO_PAYMENTS_STATUS = 'confirmCloseForPayments';
export const CONFIRM_CLOSE_TO_PICK_A_SEAT_STATUS = 'confirmCloseForPickASeat';
export const CONFIRM_CLOSE_TO_RESERVED_TICKET_CONFIRMATION_STATUS =
    'confirmCloseForReservedTicketConfirmation';
export const CONFIRM_TO_CHECKOUT_STATUS = 'confirmToCheckout';
export const CONFIRM_TO_TICKET_SELECTION = 'toTicketSelection';
export const EVENT_ALL_TICKETS_REQUIRE_UNLOCK_STATUS =
    'allTicketsRequireUnlock';
export const EVENT_LIVE_STATUS = 'live';
export const EVENT_STARTED_STATUS = 'started';
export const EVENT_DRAFT_STATUS = 'draft';
export const EVENT_CANCELED_STATUS = 'canceled';
export const EVENT_ENDED_STATUS = 'ended';
export const EVENT_SOLD_OUT_STATUS = 'soldOut';
export const EVENT_SOLD_OUT_IN_TICKET_SELECTION_STATUS =
    'soldOutInTicketSelection';
export const EVENT_SOLD_OUT_WITH_CAPACITY_WAITLIST_STATUS =
    'soldOutWithCapacityWaitlist';
export const EVENT_SOLD_OUT_WITH_TICKET_RESELLER_STATUS =
    'soldOutWithTicketReseller';
export const EVENT_SALES_ENDED_STATUS = 'salesEnded';
export const EVENT_TICKETS_UNAVAILABLE_STATUS = 'unavailable';
export const SERVER_ERROR_STATUS = 'serverError';
export const TIME_EXPIRED_STATUS = 'timeExpired';
export const TIME_EXPIRED_STATUS_NO_BACK_BUTTON = 'timeExpiredNoBackButton';
export const WAITLIST_ERROR_STATUS = 'waitlistError';
export const WAITLIST_DISABLED_STATUS = 'waitlistDisabled';

export const EVENT_SALES_SOLD_OUT_STATUS = 'sold_out';
export const EVENT_SALES_SALES_ENDED_STATUS = 'sales_ended';

export const PAYMENT_STATUS_CANCELLED = 'CANCELLED';
export const PAYMENT_STATUS_COMPLETED = 'COMPLETED';
export const PAYMENT_STATUS_DECLINED = 'DECLINED';
export const PAYMENT_STATUS_EXPIRED = 'EXPIRED';
export const PAYMENT_STATUS_FAILED = 'FAILED';
export const PAYMENT_STATUS_FINISH_PAYMENT = 'FINISH_PAYMENT';
export const PAYMENT_STATUS_SETTLED = 'SETTLED';

export const ORDER_STATUS_PLACED = 'placed';
export const ORDER_STATUS_PROCESSING = 'processing';
export const ORDER_STATUS_PENDING = 'pending';

export const FINAL_PAYMENT_STATUSES = [
    PAYMENT_STATUS_CANCELLED,
    PAYMENT_STATUS_COMPLETED,
    PAYMENT_STATUS_DECLINED,
    PAYMENT_STATUS_EXPIRED,
    PAYMENT_STATUS_FAILED,
    PAYMENT_STATUS_SETTLED,
];

export const SUCCESSFUL_PAYMENT_STATUSES = [
    PAYMENT_STATUS_COMPLETED,
    PAYMENT_STATUS_SETTLED,
];

export const STATUSES_TO_SHOW_ARROW_IN_MOBILE = [
    EVENT_SOLD_OUT_STATUS,
    EVENT_SOLD_OUT_IN_TICKET_SELECTION_STATUS,
    EVENT_SOLD_OUT_WITH_CAPACITY_WAITLIST_STATUS,
    EVENT_SOLD_OUT_WITH_TICKET_RESELLER_STATUS,
    EVENT_SALES_ENDED_STATUS,
    EVENT_ENDED_STATUS,
    EVENT_CANCELED_STATUS,
    EVENT_TICKETS_UNAVAILABLE_STATUS,
    SERVER_ERROR_STATUS,
    WAITLIST_ERROR_STATUS,
    WAITLIST_DISABLED_STATUS,
];

export const STATUS_CONTROL_ORDER_TIME_EXPIRED = [
    ORDER_STATUS_PROCESSING,
    ORDER_STATUS_PLACED,
];

export const ABANDON_ORDER_ATTEMPT_STATUSES = [
    CONFIRM_BACK_STATUS,
    CONFIRM_BACK_TO_DELIVERY_METHOD_STATUS,
    CONFIRM_CLOSE_TO_LOGIN_STATUS,
    CONFIRM_BACK_TO_PICK_A_SEAT_STATUS,
    CONFIRM_BACK_TO_RESERVED_TICKET_CONFIRMATION_STATUS,
    CONFIRM_CLOSE_STATUS,
    CONFIRM_CLOSE_TO_DELIVERY_METHOD_STATUS,
    CONFIRM_CLOSE_TO_PICK_A_SEAT_STATUS,
    CONFIRM_CLOSE_TO_RESERVED_TICKET_CONFIRMATION_STATUS,
    CONFIRM_CLOSE_TO_PAYMENTS_STATUS,
    CONFIRM_TO_CHECKOUT_STATUS,
];

export const ERROR_TO_STATUS_PATH_MAPPING = {
    [WAITLIST_LINK_INVALID]: WAITLIST_ERROR_STATUS,
    [WAITLIST_LINK_EXPIRED]: WAITLIST_ERROR_STATUS,
    [WAITLIST_LINK_USED]: WAITLIST_ERROR_STATUS,
    [WAITLIST_LINK_CANCELED]: WAITLIST_ERROR_STATUS,
    [WAITLIST_DISABLED]: WAITLIST_DISABLED_STATUS,
};

export const ABANDON_ORDER_STATUSES = [
    ...ABANDON_ORDER_ATTEMPT_STATUSES,
    TIME_EXPIRED_STATUS,
];

export const DEFAULT_MESSAGE_TYPE = 'default';

export const DONATIONS_MAX_DAYS_AFTER_EVENT_END = 45;

const SOLD_OUT_CONTENT = {
    headerText: gettext('This event has sold out online'),
    children: gettext('This event is no longer available online.'),
};

const SOLD_OUT_IN_TICKET_SELECTION_CONTENT = {
    headerText: (
        <div>
            <div className="eds-l-mar-top-1 eds-l-mar-bot-2 eds-align--center">
                <Icon type={<Ticket />} size="medium" hasHalo={true} />
            </div>
            {gettext('This event has sold out')}
        </div>
    ),

    children: gettext(
        'Sadly, the event sold out while you were making your selection.',
    ),
};

const SALES_ENDED_CONTENT = {
    ...SOLD_OUT_CONTENT,
    headerText: gettext('Sales have ended online'),
};

const CANCELED_CONTENT = {
    headerText: gettext('This event has been canceled'),
    children: gettext('This event is no longer available.'),
};

const ENDED_CONTENT = {
    headerText: gettext('This event has ended'),
    children: gettext('This event is no longer available.'),
};

const EVENT_TICKETS_UNAVAILABLE_CONTENT = {
    headerText: gettext('Event currently unavailable'),
    children: gettext(
        'Registration is currently unavailable. Try waiting a minute and refresh the page.',
    ),
};

const SERVER_ERROR_CONTENT = {
    headerText: gettext('Something went wrong...'),
    children: gettext('Try refreshing the page and submitting the form again.'),
};

const WAITLIST_ERROR_CONTENT = {
    headerText: gettext('Your waitlist entry is not valid'),
    children: gettext('This event has sold out.'),
};

const WAITLIST_DISABLED_CONTENT = {
    ...WAITLIST_ERROR_CONTENT,
    headerText: gettext('Your waitlist entry is no longer available'),
};

const CALLBACKS_MAP = {
    [CONFIRM_BACK_TO_DELIVERY_METHOD_STATUS]: 'toDeliveryMethod',
    [CONFIRM_BACK_TO_PICK_A_SEAT_STATUS]: 'toPickASeat',
    [CONFIRM_BACK_TO_RESERVED_TICKET_CONFIRMATION_STATUS]:
        'toReservedTicketConfirmation',
    [CONFIRM_CLOSE_TO_DELIVERY_METHOD_STATUS]: 'toDeliveryMethod',
    [CONFIRM_CLOSE_TO_LOGIN_STATUS]: 'toLogin',
    [CONFIRM_CLOSE_TO_PICK_A_SEAT_STATUS]: 'toPickASeat',
    [CONFIRM_CLOSE_TO_RESERVED_TICKET_CONFIRMATION_STATUS]:
        'toReservedTicketConfirmation',
    [CONFIRM_CLOSE_TO_PAYMENTS_STATUS]: 'toPaymentSelection',
    [CONFIRM_CLOSE_STATUS]: 'toCheckout',
    [CONFIRM_CLOSE_MODAL]: 'closeModal',
    [CONFIRM_TO_CHECKOUT_STATUS]: 'toCheckout',
    [CONFIRM_TO_TICKET_SELECTION]: 'toTicketSelection',
};

const confirmPage = (
    availableCallbacks,
    confirmCallback = CONFIRM_TO_TICKET_SELECTION,
    cancelCallback = CONFIRM_TO_CHECKOUT_STATUS,
) => {
    const defaultCallback = () => undefined;
    const returnCancelCallbackName = CALLBACKS_MAP[cancelCallback];
    const returnConfirmCallbackName = CALLBACKS_MAP[confirmCallback];
    const returnCancelCallback =
        availableCallbacks[returnCancelCallbackName] || defaultCallback;
    const returnConfirmCallback =
        availableCallbacks[returnConfirmCallbackName] || defaultCallback;

    return {
        headerText: gettext('Leave Checkout?'),

        children: (
            <ConfirmDialog
                infoMessage={gettext(
                    "Are you sure you want to leave checkout? The items you've selected may not be available later.",
                )}
                cancelButtonText={gettext('Stay')}
                onCancelButtonClick={returnCancelCallback}
                confirmButtonText={gettext('Leave')}
                onConfirmButtonClick={returnConfirmCallback}
            />
        ),
    };
};

export const timeExpiredContent = ({ toTicketSelection }) => ({
    headerText: gettext('Time Limit Reached'),

    children: (
        <div>
            <div className="eds-l-pad-bot-2">
                {gettext(
                    'Your reservation has been released. Please re-start your purchase.',
                )}
            </div>
            <Button type={TYPE_BUTTON} style="link" onClick={toTicketSelection}>
                {gettext('Back to Tickets')}
            </Button>
        </div>
    ),

    alertText: gettext(
        'Your cart timer has expired. Please re-start your purchase.',
    ),
});

export const timeExpiredContentNoBackButton = () => ({
    headerText: gettext('Time Limit Reached'),

    children: (
        <div>
            <div className="eds-l-pad-bot-2">
                {gettext(
                    'Your reservation has been released. Please re-start your purchase.',
                )}
            </div>
        </div>
    ),

    alertText: gettext(
        'Your cart timer has expired. Please re-start your purchase.',
    ),
});

export const STATUS_CONTENT_MAP = {
    [CONFIRM_BACK_STATUS]: confirmPage,
    [CONFIRM_BACK_TO_DELIVERY_METHOD_STATUS]: (
        availableCallbacks,
        confirmCallback,
    ) =>
        confirmPage(
            availableCallbacks,
            confirmCallback,
            CONFIRM_BACK_TO_DELIVERY_METHOD_STATUS,
        ),
    [CONFIRM_BACK_TO_PICK_A_SEAT_STATUS]: (
        availableCallbacks,
        confirmCallback,
    ) =>
        confirmPage(
            availableCallbacks,
            confirmCallback,
            CONFIRM_BACK_TO_PICK_A_SEAT_STATUS,
        ),
    [CONFIRM_BACK_TO_RESERVED_TICKET_CONFIRMATION_STATUS]: (
        availableCallbacks,
        confirmCallback,
    ) =>
        confirmPage(
            availableCallbacks,
            confirmCallback,
            CONFIRM_BACK_TO_RESERVED_TICKET_CONFIRMATION_STATUS,
        ),
    [CONFIRM_CLOSE_TO_DELIVERY_METHOD_STATUS]: (availableCallbacks) =>
        confirmPage(
            availableCallbacks,
            CONFIRM_CLOSE_MODAL,
            CONFIRM_CLOSE_TO_DELIVERY_METHOD_STATUS,
        ),
    [CONFIRM_CLOSE_TO_LOGIN_STATUS]: (availableCallbacks) =>
        confirmPage(
            availableCallbacks,
            CONFIRM_CLOSE_MODAL,
            CONFIRM_CLOSE_TO_LOGIN_STATUS,
        ),
    [CONFIRM_CLOSE_TO_PICK_A_SEAT_STATUS]: (availableCallbacks) =>
        confirmPage(
            availableCallbacks,
            CONFIRM_CLOSE_MODAL,
            CONFIRM_BACK_TO_PICK_A_SEAT_STATUS,
        ),
    [CONFIRM_CLOSE_TO_RESERVED_TICKET_CONFIRMATION_STATUS]: (
        availableCallbacks,
    ) =>
        confirmPage(
            availableCallbacks,
            CONFIRM_CLOSE_MODAL,
            CONFIRM_BACK_TO_RESERVED_TICKET_CONFIRMATION_STATUS,
        ),
    [CONFIRM_CLOSE_STATUS]: (availableCallbacks) =>
        confirmPage(availableCallbacks, CONFIRM_CLOSE_MODAL),
    [CONFIRM_CLOSE_TO_PAYMENTS_STATUS]: (availableCallbacks, confirmCallback) =>
        confirmPage(
            availableCallbacks,
            confirmCallback,
            CONFIRM_CLOSE_TO_PAYMENTS_STATUS,
        ),
    [CONFIRM_TO_CHECKOUT_STATUS]: (availableCallbacks, confirmCallback) =>
        confirmPage(
            availableCallbacks,
            confirmCallback,
            CONFIRM_TO_CHECKOUT_STATUS,
        ),
    [EVENT_CANCELED_STATUS]: () => CANCELED_CONTENT,
    [EVENT_ENDED_STATUS]: () => ENDED_CONTENT,
    [EVENT_SOLD_OUT_STATUS]: () => SOLD_OUT_CONTENT,
    [EVENT_SOLD_OUT_IN_TICKET_SELECTION_STATUS]: () =>
        SOLD_OUT_IN_TICKET_SELECTION_CONTENT,
    [EVENT_SALES_ENDED_STATUS]: () => SALES_ENDED_CONTENT,
    [EVENT_TICKETS_UNAVAILABLE_STATUS]: () => EVENT_TICKETS_UNAVAILABLE_CONTENT,
    [SERVER_ERROR_STATUS]: () => SERVER_ERROR_CONTENT,
    [TIME_EXPIRED_STATUS]: timeExpiredContent,
    [TIME_EXPIRED_STATUS_NO_BACK_BUTTON]: timeExpiredContentNoBackButton,
    [WAITLIST_ERROR_STATUS]: () => WAITLIST_ERROR_CONTENT,
    [WAITLIST_DISABLED_STATUS]: () => WAITLIST_DISABLED_CONTENT,
};

export const SALES_STATUS_CONTENT_MAP = {
    [EVENT_SOLD_OUT_STATUS]: 'ticketsSoldOut',
    [EVENT_SALES_ENDED_STATUS]: 'ticketsWithSalesEnded',
};
