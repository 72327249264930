import { connect } from 'react-redux';
import { changeLanguage as changeLanguageAction } from '../actions/languages';
import LanguageAndBrand from '../components/languageAndBrand/LanguageAndBrand';
import { getLocaleProps } from './template';

export default connect(
    (state) => getLocaleProps(state),
    (dispatch) => ({
        onSelectLanguage: (data) => dispatch(changeLanguageAction(data)),
    }),
)(LanguageAndBrand);
