import { Dispatch } from 'redux';
import {
    fetchCountries,
    fetchRegionsByCountry,
    CountryWithDeinflatedMobileData,
} from '../api/address';
import { Region } from '../api/models/region';
import { CheckoutState } from '../state';

export enum AddressActionType {
    startLoadingRegions = 'startLoadingRegions',
    finishLoadingRegionsSuccessfully = 'finishLoadingRegionsSuccessfully',
    finishLoadingRegionsWithError = 'finishLoadingRegionsWithError',
    startLoadingCountries = 'startLoadingCountries',
    finishLoadingCountriesSuccessfully = 'finishLoadingCountriesSuccessfully',
    finishLoadingCountriesWithError = 'finishLoadingCountriesWithError',
}

export interface StartLoadingRegionsAction {
    type: AddressActionType.startLoadingRegions;
}

const startLoadingRegionsAction = (): StartLoadingRegionsAction => ({
    type: AddressActionType.startLoadingRegions,
});

export interface FinishLoadingRegionsSuccessfullyAction {
    type: AddressActionType.finishLoadingRegionsSuccessfully;
    payload: {
        regionsByCountry: Record<string, Array<Region>>;
    };
}

const finishLoadingRegionsSuccessfullyAction = ({
    regionsByCountry,
}: {
    regionsByCountry: Record<string, Array<Region>>;
}): FinishLoadingRegionsSuccessfullyAction => ({
    type: AddressActionType.finishLoadingRegionsSuccessfully,
    payload: {
        regionsByCountry,
    },
});

export interface FinishLoadingRegionsWithErrorAction {
    type: AddressActionType.finishLoadingRegionsWithError;
    payload: {
        error: Error;
    };
}

const finishLoadingRegionsWithErrorAction = (
    error: Error,
): FinishLoadingRegionsWithErrorAction => ({
    type: AddressActionType.finishLoadingRegionsWithError,
    payload: {
        error,
    },
});

export interface StartLoadingCountriesAction {
    type: AddressActionType.startLoadingCountries;
}

const startLoadingCountriesAction = (): StartLoadingCountriesAction => ({
    type: AddressActionType.startLoadingCountries,
});

export interface FinishLoadingCountriesSuccessfullyAction<
    MobileData extends Record<string, unknown>
> {
    type: AddressActionType.finishLoadingCountriesSuccessfully;
    payload: {
        countries: Array<CountryWithDeinflatedMobileData<MobileData>>;
    };
}

const finishLoadingCountriesSuccessfullyAction = <
    MobileData extends Record<string, unknown>
>({
    countries,
}: {
    countries: Array<CountryWithDeinflatedMobileData<MobileData>>;
}): FinishLoadingCountriesSuccessfullyAction<MobileData> => ({
    type: AddressActionType.finishLoadingCountriesSuccessfully,
    payload: {
        countries,
    },
});

export interface FinishLoadingCountriesWithErrorAction {
    type: AddressActionType.finishLoadingCountriesWithError;
    payload: {
        error: Error;
    };
}

const finishLoadingCountriesWithErrorAction = (
    error: Error,
): FinishLoadingCountriesWithErrorAction => ({
    type: AddressActionType.finishLoadingCountriesWithError,
    payload: {
        error,
    },
});

export type AddressAction<
    MobileData extends Record<string, unknown> = Record<string, unknown>
> =
    | StartLoadingRegionsAction
    | FinishLoadingRegionsSuccessfullyAction
    | FinishLoadingRegionsWithErrorAction
    | StartLoadingCountriesAction
    | FinishLoadingCountriesSuccessfullyAction<MobileData>
    | FinishLoadingCountriesWithErrorAction;

/**
 * Wraps the `fetchCountries` method with action dispatching for loading, success,
 * and error states.
 */
export const dispatchLoadCountries = () => async (
    dispatch: Dispatch<any>,
    getState: () => Pick<CheckoutState, 'address'>,
) => {
    const {
        address: { countries },
    } = getState();

    if (countries.all || countries.isLoading) {
        return;
    }

    dispatch(startLoadingCountriesAction());

    try {
        const { countries } = await fetchCountries();

        return dispatch(
            finishLoadingCountriesSuccessfullyAction({ countries }),
        );
    } catch (error) {
        return dispatch(finishLoadingCountriesWithErrorAction(error));
    }
};

/**
 * Wraps the `fetchRegions` endpoint with action dispatching for loading, success,
 * and error states.
 */
export const dispatchLoadRegions = () => async (
    dispatch: Dispatch<any>,
    getState: () => Pick<CheckoutState, 'address'>,
) => {
    const {
        address: { regions },
    } = getState();

    if (regions.byCountry || regions.isLoading) {
        return;
    }

    dispatch(startLoadingRegionsAction());

    try {
        const { regionsByCountry } = await fetchRegionsByCountry();

        return dispatch(
            finishLoadingRegionsSuccessfullyAction({
                regionsByCountry,
            }),
        );
    } catch (error) {
        return dispatch(finishLoadingRegionsWithErrorAction(error));
    }
};
