import * as React from 'react';

const EloPaymentSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 48 32" {...props}>
        <path d="M3 0h42c1.7 0 3 1.3 3 3v26c0 1.7-1.3 3-3 3H3c-1.7 0-3-1.3-3-3V3c0-1.7 1.3-3 3-3z" />
        <path
            d="M12.544 12.423c.386-.13.797-.199 1.224-.199 1.87 0 3.431 1.338 3.788 3.117l2.65-.545C19.6 11.775 16.947 9.5 13.768 9.5c-.729 0-1.427.12-2.082.34l.858 2.583z"
            fill="#FFCB05"
        />
        <path
            d="M9.416 21.076l1.793-2.041a3.9 3.9 0 01-1.304-2.918c0-1.16.505-2.202 1.304-2.914l-1.793-2.044A6.63 6.63 0 007.2 16.117c0 1.974.858 3.746 2.216 4.96"
            fill="#00A4E0"
        />
        <path
            d="M17.556 16.898c-.358 1.777-1.918 3.113-3.786 3.113-.429 0-.842-.069-1.226-.2l-.858 2.586c.655.22 1.355.339 2.084.339 3.175 0 5.826-2.272 6.436-5.29l-2.65-.548z"
            fill="#EF4123"
        />
        <path
            d="M22.6 19.233a4.776 4.776 0 01-.28-.542 3.987 3.987 0 01-.086-3.002c.394-1.08 1.149-1.906 2.123-2.329 1.225-.529 2.58-.426 3.754.274.745.43 1.274 1.093 1.675 2.032.05.12.096.248.14.358l-7.325 3.209zm2.447-4.279c-.87.375-1.319 1.196-1.225 2.156l3.684-1.594c-.634-.747-1.458-.995-2.459-.562zm2.918 3.467c-.002 0-.002.002-.002.002l-.076-.053a2.17 2.17 0 01-.996.838c-.824.36-1.587.267-2.136-.216l-.05.076-.002-.001-.935 1.405c.233.162.482.298.742.408 1.033.432 2.089.41 3.13-.043.752-.328 1.343-.828 1.746-1.461l-1.421-.955zM32.275 11.26v7.651l1.183.483-.671 1.576-1.305-.547a1.478 1.478 0 01-.642-.544c-.144-.224-.251-.533-.251-.947V11.26h1.686zM35.52 17.108c0-.663.29-1.258.745-1.662l-1.222-1.386a4.083 4.083 0 00-1.351 3.045 4.085 4.085 0 001.347 3.048l1.221-1.386a2.217 2.217 0 01-.74-1.659zM37.692 19.318c-.24 0-.473-.042-.689-.115l-.584 1.763c.4.136.826.211 1.27.211 1.936.002 3.55-1.395 3.925-3.249l-1.794-.372c-.204 1.008-1.079 1.763-2.128 1.762zM37.697 13.04c-.444 0-.87.073-1.269.207l.579 1.765c.217-.073.448-.113.688-.113a2.18 2.18 0 012.127 1.77l1.794-.369c-.368-1.857-1.983-3.259-3.92-3.26z"
            fill="#FFF"
        />
    </svg>
);

EloPaymentSvg.displayName = 'EloPaymentSvg';
export default EloPaymentSvg;
