import every from 'lodash/every';
import filter from 'lodash/filter';
import find from 'lodash/find';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import memoize from 'lodash/memoize';

import { formatMajorMoney } from '@eb/intl';

import {
    BUY_ON_MAP_FORM_NAME,
    TICKET_CLASS_CATEGORIES_BY_ORDER,
    TICKETS_SELECTION_PAGE_FORM_NAME,
} from '../constants';
import {
    getTotalCost as getTicketsTotalCost,
    getTotalDisplayCost,
} from '../containers/ticketSelection/utils/ticketPriceUtils';
import {
    getSelectedTicketsFromOrderAttendees,
    getTotalSelectedTickets,
    ticketClassesByCategory,
} from '../containers/ticketSelection/utils/ticketUtils';
import { createSelector } from './utils/selector';
import getTerminology from '../utils/terminology';
import {
    filterAdvancedTeamTicketsAndVariants,
    filterIndividualTickets,
    filterGroupTickets,
} from '../utils/tickets';
import { getSelectedDeliveryMethodsForDisplay } from './deliveryMethod';
import { getCheckoutGroups, getIsReservedSeating } from './event';
import {
    getAttendees,
    getTotalCostsFromGrossValue,
    hasExistingOrder,
} from './order';

const getTicketsById = (state) => get(state, 'tickets.ticketsById', {});

const getSelectedTicketsFromTicketsById = createSelector(
    getTicketsById,
    (tickets) =>
        filter(
            tickets,
            ({ selectedQuantity, variants = [] }) =>
                selectedQuantity > 0 ||
                variants.some((variant) => variant.selectedQuantity > 0),
        ),
);

/**
 * Reserved seating was initially built to get selectedTickets from the list of attendees, since
 * the selected ticket quantity is not always updated in redux state in the Reserved flow - at least
 * one example is when users select seats through Pick-a-seat.
 * This means that in the reserved flow, only if we have an order *and* the order has atttendees do
 * we get selected tickets from order.attendees rather than ticketsById.
 *
 * TODO: Consider properly updating selected ticket quantity via Redux in all reserved flows. There
 * should be a consistent way to calculate selectedTickets no matter which flow we are in.
 *
 * In the case of an Existing Order, the order has already been created externally.
 * The usual ticket selection step has therefore been skipped, and the redux state could not be updated properly.
 * The ONLY way to retrieve the selected tickets is through `order.attendees`
 * The logic to do so is the same as for reserved seating.
 */
const _shouldGetTicketsFromOrder = (state) =>
    (getIsReservedSeating(state) || hasExistingOrder(state)) &&
    getAttendees(state).length > 0;

export const getSelectedTickets = (state) => {
    const shouldGetTicketsFromOrder = _shouldGetTicketsFromOrder(state);

    if (shouldGetTicketsFromOrder) {
        return getSelectedTicketsFromOrderAttendees(state);
    }

    return getSelectedTicketsFromTicketsById(state);
};

/**
 * Composable function who's inner returned function with specific formatting logic for ticket class
 * category
 *
 * @param {String} key -- Ticket class category, one of "admission", "add_on", or "donation"
 * @returns {Function} getTicketClassFormatter -- The returned function
 */
export const getTicketClassFormatter = (key) =>
    memoize((state) => ticketClassesByCategory(key)(state));

/**
 * @param {Array} ticketClasses
 * @returns {Object}
 */
export const getTicketClassCheckoutGroupId = (ticketClasses) =>
    ticketClasses[0].checkoutGroupId;

/**
 * Partitions ticket classes for display in ticket selection page components  to differentiate
 * tickets with an applied code.
 *
 * @param {Array} ticketClasses
 * @returns {Object} Returns an object with keys "tickets" and "ticketsWithAppliedCode" whose values
 * contain arrays of ticket class data.
 */
export const partitionTicketClassesByDiscountId = (ticketClasses) => {
    const result = {
        tickets: [],
        ticketsWithAppliedCode: [],
    };

    for (const ticketClass of ticketClasses) {
        if (ticketClass.discountId) {
            result.ticketsWithAppliedCode.push(ticketClass);
        } else {
            result.tickets.push(ticketClass);
        }
    }

    return result;
};

/**
 * Returns Array with all tickets available for group registration
 *
 * @param {*} state
 * @returns {Array}
 */
export const getGroupEligibleTypeTickets = createSelector(
    (state) => get(state, 'tickets.ticketsById', {}),
    (tickets) =>
        filterAdvancedTeamTicketsAndVariants(tickets, filterGroupTickets),
);

/**
 * Returns Array with all tickets available for individual registration
 *
 * @param {*} state
 * @returns {Array}
 */
export const getIndividualEligibleTypeTickets = createSelector(
    (state) => get(state, 'tickets.ticketsById', {}),
    (tickets) =>
        filterAdvancedTeamTicketsAndVariants(tickets, filterIndividualTickets),
);

/**
 * Returns Boolean that checks whether the event has group type tickets
 *
 * @param {*} state
 * @returns {Boolean}
 */
export const getGroupRegistrationTickets = (state) => {
    const isIndividualSelected = get(
        state,
        'groupRegistration.isIndividualSelected',
    );

    return isIndividualSelected
        ? getIndividualEligibleTypeTickets(state)
        : getGroupEligibleTypeTickets(state);
};

/**
 * Returns an array of tickets available based on the users registration type
 * This method takes in a partial group of tickets which are prefiltered by getTicketClassFormatter
 *
 * @param {Array} ticketClasses
 * @param {Boolean} isIndividualSelected
 * @returns {Array}
 */
export const getEligibleAdvancedTeamTickets = (
    ticketClasses,
    isIndividualSelected,
) => {
    if (isIndividualSelected) {
        return getIndividualEligibleTypeTickets({
            tickets: { ticketsById: ticketClasses },
        });
    }

    return getGroupEligibleTypeTickets({
        tickets: { ticketsById: ticketClasses },
    });
};

/**
 * @typedef {object} TicketClassesFormattedByCategoryForDisplay
 * @type {Object}
 *
 * Example:
 * {
 *  tickets: [{}],
 *  ticketsWithAppliedCode: [{}],
 *  title: 'Tickets'
 * }
 */

/**
 * Formats ticket class data for display in ticket selection page components
 *
 * @param {Object} state
 * @returns {Object} Object TicketClassesFormattedByCategoryForDisplay
 */
export const getFormattedTicketClassesByCategory = memoize((state) => {
    const result = {};

    for (const category of TICKET_CLASS_CATEGORIES_BY_ORDER) {
        let ticketClasses;

        ticketClasses = getTicketClassFormatter(category)(state);

        if (get(state, 'event.hasAdvancedTeamsEnabled')) {
            const isIndividualSelected = get(
                state,
                'groupRegistration.isIndividualSelected',
            );

            ticketClasses = getEligibleAdvancedTeamTickets(
                ticketClasses,
                isIndividualSelected,
            );
        }

        if (!ticketClasses.length) {
            continue;
        }

        // Note: Default titles are supported by our v3 API and could be retrieved that way.
        // For now I've hard-coded these as constants since they are very unlikely to change and it
        // saves an extra remote trip. See:
        // https://eventbriteapiv3.docs.apiary.io/#reference/event-texts/retrieve/retrieve-event-texts

        const defaultTitle = getTerminology(state.app.isRegEvent)
            .ticketCategoryTitle[category];
        const checkoutGroupId = getTicketClassCheckoutGroupId(ticketClasses);
        const group = getCheckoutGroups(state).find(
            (currentGroup) => currentGroup.id === checkoutGroupId,
        );

        result[category] = {
            ...partitionTicketClassesByDiscountId(ticketClasses),
            title: group ? group.title : defaultTitle,
        };
    }

    return result;
});

// TODO: Continue grouping / alpha sorting.
// Everything below this line is sorted alphabetical ASC

/**
 * Returns object for easy variant lookup
 *
 * @param {*} state
 * @returns {Object}
 */
export const getAllVariants = createSelector(
    (state) => get(state, 'ticketsById', {}),
    (ticketsById) =>
        Object.keys(ticketsById).reduce((acc, ticketId) => {
            ticketsById[ticketId].variants.forEach((variant) => {
                // eslint-disable-next-line no-param-reassign
                acc[variant.id] = variant;
            });

            return acc;
        }, {}),
);

/**
 * @param {object} state
 * @returns {string}
 */
export const getConstrainedInstrumentType = (state) =>
    get(state, 'tickets.constrainedInstrumentType', null);

/**
 * @param {object} state
 * @returns {array}
 */
export const getConstrainedPaymentMethods = (state) =>
    get(state, 'tickets.constrainedPaymentMethods', []);

/**
 * Get total cost and shipping fees of the selected tickets or order
 *
 * @param {object} state The entire redux state
 * @return {{ totalCost: number, shippingFee: number }}
 */
export const getTicketsCostDetails = (state) => {
    const {
        tickets: { ticketsById },
        event: { shippingRates },
    } = state;

    const baseCostInCents =
        hasExistingOrder(state) || getTotalCostsFromGrossValue(state) > 0
            ? getTotalCostsFromGrossValue(state)
            : getTicketsTotalCost(ticketsById);

    const selectedDeliveryMethods = getSelectedDeliveryMethodsForDisplay(state);

    const shippingFeeInCents = selectedDeliveryMethods.reduce(
        (previousShippingFee, deliveryMethod) => {
            const singleShippingRate = find(
                shippingRates,
                (rate) => rate.name === deliveryMethod,
            );

            return singleShippingRate
                ? previousShippingFee + singleShippingRate.price.value
                : previousShippingFee;
        },
        0,
    );

    const totalCostInCents = baseCostInCents + shippingFeeInCents;

    return { totalCostInCents, shippingFeeInCents };
};

/**
 * Get common display props used by OrderSummary and Footer from the
 * selected tickets, using event currency and app currency format
 *
 * @param {object} state The entire redux state
 * @return {object} props with currency and totalCost for display
 */

export const getDisplayCostProps = (state) => {
    const {
        tickets: { ticketsById },
        event: { currency },
        app: { currencyFormat },
    } = state;

    const { totalCostInCents, shippingFeeInCents } = getTicketsCostDetails(
        state,
    );

    // TODO: Document the differences b/w totalCost and totalCostDisplay via unit tests
    return {
        currency,
        totalCostUnformatted: totalCostInCents,
        totalCost: formatMajorMoney(totalCostInCents, currency, currencyFormat),
        totalCostDisplay: getTotalDisplayCost(
            ticketsById,
            currency,
            currencyFormat,
            shippingFeeInCents,
        ),
    };
};

/**
 * @param {object} ticket Can be one of tier or ticket class
 */
export const getFullImageUrl = (ticket = {}) =>
    get(ticket, 'image.original.url', '');

/**
 * @param {object} ticket Can be one of tier or ticket class
 */
export const getImageThumbnailUrl = (ticket = {}) =>
    get(ticket, 'image.url', '');

export const getReservedTicketSelectionSubmitErrors = createSelector(
    (state) => get(state, `form[${TICKETS_SELECTION_PAGE_FORM_NAME}].error`),
    (state) => get(state, `form[${BUY_ON_MAP_FORM_NAME}].error`),

    (ticketSelectionFormSubmitErrors, buyOnMapSubmitErrors) => {
        const errors = [];

        if (ticketSelectionFormSubmitErrors) {
            errors.push(ticketSelectionFormSubmitErrors);
        }

        if (buyOnMapSubmitErrors) {
            errors.push(buyOnMapSubmitErrors);
        }

        return errors;
    },
);

/**
 * Returns a boolean describing whether or not there are more than
 * @param {Object} state
 * @returns {Boolean}
 */
export const getShouldShowTicketCategoryGroupHeaders = (state) =>
    Object.keys(getFormattedTicketClassesByCategory(state)).length >= 2;

/**
 * Get tickets with associated assigned unit data
 *
 * Underscore prefix used because this is not a true selector, rather it's used
 * as a helper for getTicketsProps
 *
 * @param  {array} tickets    The tickets state
 * @param  {array} attendees  The attendees state
 * @return {array} Tickets with assigned unit prop attached
 * @was `utils/forms.js: getTicketsWithAssignedUnit
 */
export const _getTicketsWithAssignedUnit = (tickets, attendees = []) => {
    const groupedAttendeesByTicketClass = groupBy(attendees, 'ticketClassId');

    return tickets.map((ticket) => {
        const attendeesData = groupedAttendeesByTicketClass[ticket.id];
        let assignedUnits;

        if (attendeesData) {
            assignedUnits = map(attendeesData, 'assignedUnit');
        }

        return attendeesData && every(assignedUnits)
            ? {
                  ...ticket,
                  assignedUnits,
              }
            : ticket;
    });
};

export const getTicketsProps = (state) => {
    const selectedTickets = getSelectedTickets(state);
    // This sets a default for order.attendees when order doesn't exist in the app state yet
    // (e.g. when the user is carting tickets on the TicketSelectionPage before creatOrder is called)
    const attendees = state.order ? state.order.attendees : [];

    return {
        selectedTickets: _getTicketsWithAssignedUnit(
            selectedTickets,
            attendees,
        ),
        totalTicketQuantity: getTotalSelectedTickets(selectedTickets),
    };
};
