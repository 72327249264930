import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import nth from 'lodash/nth';
import startsWith from 'lodash/startsWith';
import upperFirst from 'lodash/upperFirst';
import { snakeToCamel } from '@eb/string-utils';

import { getWaitlistLabel } from '../utils/analytics';
import {
    ABANDON_PATH,
    CHECKOUT_FORM_NAME,
    CHECKOUT_PATH,
    PAY_IN_PATH,
    DELIVERY_METHODS_PATH,
    EXTERNAL_PAYMENT_PATH,
    FORM_TO_PATH_MAPPING,
    INVITATION_REQUIRED,
    PASSWORD_EXPIRED,
    PASSWORD_REQUIRED,
    PROTECTED_PATH,
    PURCHASE_CONFIRMATION_PATH,
    RESEND_EMAIL_PATH,
    SERIES_PATH,
    SOCIAL_SHARE_TRACKINGS,
    STATUS_PATH,
    TICKETS_PATH,
    TICKETS_WITH_CAPACITY_WAITLIST_PATH,
    UNTRACKED_QUESTION_TYPES,
    WAITLIST_PATH,
    WIDGET_TRACKING,
} from '../constants';
import { LOGIN_PATH } from '../constants';

export const _getQuestionNameParts = (questionName) => {
    const nameParts = questionName.split('.');
    const surveyType = nameParts[0];
    const fieldName = nth(nameParts, 1) ? snakeToCamel(nth(nameParts, 1)) : '';
    const subField = nth(nameParts, 2);

    return { surveyType, fieldName, subField };
};

export const _getQuestionTypeFromFieldName = ({
    questionType,
    getState,
    fieldName,
}) =>
    questionType
        ? questionType
        : get(getState(), ['order', 'questions', fieldName, 'type']);

export const _getActionNameFromQuestionType = (questionType) =>
    `${upperFirst(questionType)}QuestionEnter`;

const _getLabelFromPrivacySettingType = (privacySetting) => {
    switch (privacySetting) {
        case INVITATION_REQUIRED:
            return 'invite';
        case PASSWORD_REQUIRED:
            return 'password';
        case PASSWORD_EXPIRED:
            return 'passwordExpired';
        default:
            return null;
    }
};

export const _getQuestionInfoFromQuestionName = (getState, questionName) => {
    const { surveyType, fieldName, subField } = _getQuestionNameParts(
        questionName,
    );
    const questionType = _getQuestionTypeFromFieldName({
        questionType: surveyType === 'payment' ? 'payment' : null,
        getState,
        fieldName,
    });

    let label;

    if (UNTRACKED_QUESTION_TYPES.includes(questionType)) {
        return {};
    }

    if (startsWith(fieldName, 'N-')) {
        if (subField) {
            label = `${fieldName}.${subField}`;
        } else {
            label = fieldName;
        }
    } else if (startsWith(fieldName, 'U-')) {
        label = 'CustomQuestion';
    } else if (surveyType === 'payment') {
        label = fieldName;
    } else {
        // Delivery Method Form is purposefully omitted. Since we collect the payload on that form,
        // we track that in a separate action.
        return {};
    }

    const action = _getActionNameFromQuestionType(questionType);

    return {
        action,
        label,
    };
};

export const trackUserInput = ({ formName, questionName = '' }) => (
    dispatch,
    getState,
) => {
    const questionInfo = _getQuestionInfoFromQuestionName(
        getState,
        questionName,
    );

    if (!isEmpty(questionInfo)) {
        dispatch({
            type: 'TRACK_USER_INPUT',
            meta: {
                analytics: {
                    action: questionInfo.action,
                    dimensions: {
                        checkoutStep: FORM_TO_PATH_MAPPING[formName],
                    },
                    label: questionInfo.label,
                },
            },
        });
    }
};

export const trackLoginClicked = () => (dispatch, getState) => {
    const state = getState();
    const isFirstPartyLogin = state.app.isEventbriteTLD;
    const label = isFirstPartyLogin ? 'ebHost' : 'thirdPartyHost';

    dispatch({
        type: 'TRACK_LOGIN_BUTTON_CLICKED',
        meta: {
            analytics: {
                ...WIDGET_TRACKING.RF_LOGIN_LINK_CLICKED,
                dimensions: {
                    checkoutStep: TICKETS_PATH,
                },
                label,
            },
        },
    });
};

export const trackLogoutClicked = () => ({
    type: 'TRACK_LOGOUT_BUTTON_CLICKED',
    meta: {
        analytics: {
            ...WIDGET_TRACKING.RF_LOGOUT_LINK_CLICKED,
            dimensions: {
                checkoutStep: TICKETS_PATH,
            },
        },
    },
});

export const trackLoginContinueAsGuestClicked = () => ({
    type: 'TRACK_LOGIN_CONTINUE_AS_GUEST_CLICKED',
    meta: {
        analytics: {
            ...WIDGET_TRACKING.RF_LOGIN_CONTINUE_AS_GUEST_CLICKED,
            dimensions: {
                checkoutStep: LOGIN_PATH,
            },
        },
    },
});

export const trackEmailEditAndLogoutClick = () => ({
    type: 'TRACK_EMAIL_EDIT_AND_LOGOUT_CLICK',
    meta: {
        analytics: {
            ...WIDGET_TRACKING.RF_EMAIL_EDIT_AND_LOGOUT_CLICK,
            dimensions: {
                checkoutStep: TICKETS_PATH,
            },
        },
    },
});

export const trackPaymentMethodSelect = (paymentMethod) => (dispatch) => {
    let label;

    if (isEmpty(paymentMethod)) {
        label = 'change';
    } else {
        label = paymentMethod;
    }

    dispatch({
        type: 'TRACK_PAYMENT_METHOD_SELECT',
        meta: {
            analytics: {
                ...WIDGET_TRACKING.RF_PAYMENT_METHOD_SELECT,
                dimensions: {
                    checkoutStep: CHECKOUT_PATH,
                },
                label,
            },
        },
    });
};

export const trackSaveNewStoredPaymentChecked = () => ({
    type: 'TRACK_SAVE_NEW_STORED_PAYMENT_CHECKED',
    meta: {
        analytics: {
            ...WIDGET_TRACKING.RF_SAVE_NEW_STORED_PAYMENT_METHOD_CHECKED,
            dimensions: {
                checkoutStep: TICKETS_PATH,
            },
        },
    },
});

export const trackStoredPaymentSelectionChange = (paymentMethod) => (
    dispatch,
) => {
    let label;

    if (paymentMethod === 'CREDIT') {
        label = 'Add new card';
    } else {
        label = 'Stored payment';
    }

    dispatch({
        type: 'TRACK_STORED_PAYMENT_SELECTION_CHANGE',
        meta: {
            analytics: {
                ...WIDGET_TRACKING.RF_STORED_PAYMENT_SELECT,
                dimensions: {
                    checkoutStep: TICKETS_PATH,
                },
                label,
            },
        },
    });
};

export const trackPromoCodeFormOpen = (checkoutStep) => ({
    type: 'TRACK_PROMO_CODE_FORM_OPEN',
    meta: {
        analytics: {
            ...WIDGET_TRACKING.TS_PROMO_CLICK,
            dimensions: {
                checkoutStep,
            },
        },
    },
});

export const trackPromoCodeFormShown = (checkoutStep) => ({
    type: 'TRACK_PROMO_CODE_FORM_SHOWN',
    meta: {
        analytics: {
            ...WIDGET_TRACKING.TS_PROMO_LOAD,
            dimensions: {
                checkoutStep,
            },
        },
    },
});

export const trackTicketsPageSubmit = (checkoutStep) => ({
    type: 'TRACK_TICKETS_PAGE_SUBMIT',
    meta: {
        analytics: {
            ...WIDGET_TRACKING.TS_PAGE_SUBMIT,
            dimensions: {
                checkoutStep,
            },
        },
    },
});

export const trackDeliveryMethodSelect = (selectedDeliveryMethod) => ({
    type: 'TRACK_DELIVERY_METHODS_SELECT',
    meta: {
        analytics: {
            ...WIDGET_TRACKING.DM_SELECTION_CHANGE,
            dimensions: {
                checkoutStep: DELIVERY_METHODS_PATH,
            },
            label: selectedDeliveryMethod,
        },
    },
});

export const trackDeliveryMethodsPageSubmit = () => ({
    type: 'TRACK_DELIVERY_METHODS_PAGE_SUBMIT',
    meta: {
        analytics: {
            ...WIDGET_TRACKING.DM_PAGE_SUBMIT,
            dimensions: {
                checkoutStep: DELIVERY_METHODS_PATH,
            },
        },
    },
});

export const trackLanguageChange = (selectedLanguage, checkoutStep) => ({
    type: 'TRACK_LANGUAGE_CHANGE',
    meta: {
        analytics: {
            ...WIDGET_TRACKING.TS_LANGUAGE_SELECT,
            label: selectedLanguage,
            dimensions: {
                checkoutStep,
            },
        },
    },
});

export const trackBrandClick = (checkoutStep) => ({
    type: 'TRACK_LOGO_CLICK',
    meta: {
        analytics: {
            ...WIDGET_TRACKING.TS_LOGO_CLICK,
            dimensions: {
                checkoutStep,
            },
        },
    },
});

export const trackCartIconClick = (isPaneOpen, checkoutStep) => ({
    type: 'TRACK_CART_ICON_CLICK',
    meta: {
        analytics: {
            ...WIDGET_TRACKING.CART_ICON_CLICK,
            dimensions: {
                checkoutStep,
            },
            label: isPaneOpen ? 'close' : 'open',
        },
    },
});

export const trackPromoCodeSubmitError = (
    promoCode,
    errorMessage,
    checkoutStep,
) => ({
    type: 'TRACK_PROMO_ERROR',
    meta: {
        analytics: {
            ...WIDGET_TRACKING.TS_PROMO_ERROR,
            label: errorMessage,
            dimensions: {
                checkoutStep,
            },
            value: promoCode,
        },
    },
});

export const trackMyTicketsClick = () => ({
    type: 'TRACK_MY_TICKETS_CLICK',
    meta: {
        analytics: {
            ...WIDGET_TRACKING.OC_MY_TICKETS_CLICK,
            dimensions: {
                checkoutStep: PURCHASE_CONFIRMATION_PATH,
            },
        },
    },
});

export const trackSocialShareClick = (shareType) => ({
    type: 'TRACK_SHARE_CLICK',
    meta: {
        analytics: {
            ...SOCIAL_SHARE_TRACKINGS[shareType],
            dimensions: {
                checkoutStep: PURCHASE_CONFIRMATION_PATH,
            },
        },
    },
});

export const trackTimerExpired = (checkoutStep) => (dispatch, getState) =>
    dispatch({
        type: 'TRACK_TIMER_EXPIRED',
        meta: {
            analytics: {
                ...WIDGET_TRACKING.TIMER_EXPIRED,
                label: get(getState(), 'event.txTimeLimit', ''),
                dimensions: {
                    checkoutStep,
                },
            },
        },
    });

export const trackAbandonOrder = (checkoutStep) => ({
    type: 'TRACK_ABANDON_ORDER',
    meta: {
        analytics: {
            ...WIDGET_TRACKING.ABANDON_ORDER_CLICK,
            dimensions: {
                checkoutStep,
            },
        },
    },
});

export const trackAbandonOrderConfirm = () => ({
    type: 'TRACK_ABANDON_ORDER_CONFIRM',
    meta: {
        analytics: {
            ...WIDGET_TRACKING.ABANDON_CHECKOUT_CONFIRM,
            dimensions: {
                checkoutStep: ABANDON_PATH,
            },
        },
    },
});

export const trackAbandonOrderCancel = () => ({
    type: 'TRACK_ABANDON_ORDER_CANCEL',
    meta: {
        analytics: {
            ...WIDGET_TRACKING.ABANDON_CHECKOUT_CANCEL,
            dimensions: {
                checkoutStep: ABANDON_PATH,
            },
        },
    },
});

export const trackBackClick = (checkoutStep) => ({
    type: 'TRACK_BACK_CLICK',
    meta: {
        analytics: {
            ...WIDGET_TRACKING.BACK_CLICK,
            dimensions: {
                checkoutStep,
            },
        },
    },
});

/**
 *  Track checkout events
 */

export const trackOrderSubmit = (label) => ({
    type: 'TRACK_ORDER_SUBMIT',
    meta: {
        analytics: {
            ...WIDGET_TRACKING.RF_ORDER_SUBMITTED,
            label,
            dimensions: {
                checkoutStep: CHECKOUT_PATH,
            },
        },
    },
});

export const trackOrderSuccess = () => ({
    type: 'TRACK_ORDER_SUCCESS',
    meta: {
        analytics: {
            ...WIDGET_TRACKING.OC_ORDER_SUCCESS,
            dimensions: {
                checkoutStep: CHECKOUT_PATH,
            },
        },
    },
});

export const trackOrderFailure = (label) => ({
    type: 'TRACK_ORDER_FAILURE',
    meta: {
        analytics: {
            ...WIDGET_TRACKING.OC_ORDER_FAILURE,
            label,
            dimensions: {
                checkoutStep: CHECKOUT_PATH,
            },
        },
    },
});

export const trackPayInSubmit = (label) => ({
    type: 'TRACK_PAY_IN_SUBMIT',
    meta: {
        analytics: {
            ...WIDGET_TRACKING.OC_PAY_IN_SUBMITTED,
            label,
            dimensions: {
                checkoutStep: PAY_IN_PATH,
            },
        },
    },
});

export const trackPayInLoad = () => ({
    type: 'TRACK_PAY_IN_LOAD',
    meta: {
        analytics: {
            ...WIDGET_TRACKING.OC_PAY_IN_LOAD,
            dimensions: {
                checkoutStep: PAY_IN_PATH,
            },
        },
    },
});

export const trackPayInSuccess = () => ({
    type: 'TRACK_PAY_IN_SUCCESS',
    meta: {
        analytics: {
            ...WIDGET_TRACKING.OC_PAY_IN_SUCCESS,
            dimensions: {
                checkoutStep: PAY_IN_PATH,
            },
        },
    },
});

export const trackPayInFailure = (label) => ({
    type: 'TRACK_PAY_IN_FAILURE',
    meta: {
        analytics: {
            ...WIDGET_TRACKING.OC_PAY_IN_FAILURE,
            label,
            dimensions: {
                checkoutStep: PAY_IN_PATH,
            },
        },
    },
});

export const _getMarketingValue = (getState, marketingType) => {
    const optInType =
        marketingType === 'RF_EB_EMAIL_OPT_IN_CLICK'
            ? 'ebMarketingOptIn'
            : 'organizationMarketingOptIn';

    // The state reflects the value when this action was fired, but we are tracking what the user changes it to
    // e.g. the box is checked (value: true) and the user clicks to uncheck it so we return 'unchecked'
    const isOptedIn = get(getState(), [
        'form',
        CHECKOUT_FORM_NAME,
        'values',
        optInType,
    ]);

    return isOptedIn ? 'unchecked' : 'checked';
};

export const trackMarketingClick = (marketingType) => (dispatch, getState) =>
    dispatch({
        type: 'TRACK_MARKETING_CLICK',
        meta: {
            analytics: {
                ...WIDGET_TRACKING[marketingType],
                label: _getMarketingValue(getState, marketingType),
                dimensions: {
                    checkoutStep: CHECKOUT_PATH,
                },
            },
        },
    });

export const trackEmailPreferences = (marketingType, optedIn) => ({
    type: 'TRACK_EMAIL_PREFERENCES',
    meta: {
        analytics: {
            ...WIDGET_TRACKING[marketingType],
            label: optedIn ? 'checked' : 'unchecked',
            dimensions: {
                checkoutStep: CHECKOUT_PATH,
            },
        },
    },
});

export const trackEmailPreferencesError = (label) => ({
    type: 'TRACK_EMAIL_PREFERENCES_FAILURE',
    meta: {
        analytics: {
            ...WIDGET_TRACKING.RF_EMAIL_OPT_IN_ERROR,
            label,
            dimensions: {
                checkoutStep: CHECKOUT_PATH,
            },
        },
    },
});

export const trackTaxInvoiceSelectionChange = ({ checkoutStep, label }) => (
    dispatch,
) =>
    dispatch({
        type: 'TRACK_TAX_INVOICE_SELECTION_CHANGE',
        meta: {
            analytics: {
                ...WIDGET_TRACKING.TAX_INVOICE_SELECTION_CHANGE,
                label,
                dimensions: {
                    checkoutStep,
                },
            },
        },
    });

export const trackTaxInvoiceInputFieldEnter = ({ checkoutStep, label }) => (
    dispatch,
) =>
    dispatch({
        type: 'TRACK_TAX_INVOICE_INPUT_FIELD_ENTER',
        meta: {
            analytics: {
                ...WIDGET_TRACKING.TAX_INVOICE_INPUT_FIELD_ENTER,
                label,
                dimensions: {
                    checkoutStep,
                },
            },
        },
    });

export const trackTaxInvoiceAddressDropdownChange = ({
    checkoutStep,
    label,
}) => (dispatch) =>
    dispatch({
        type: 'TRACK_TAX_INVOICE_ADDRESS_DROPDOWN_CHANGE',
        meta: {
            analytics: {
                ...WIDGET_TRACKING.TAX_INVOICE_ADDRESS_DROPDOWN_CHANGE,
                label,
                dimensions: {
                    checkoutStep,
                },
            },
        },
    });

/**
 * Track page loads
 */

export const _getActionNameFromPath = (checkoutStep) => {
    let pathName = checkoutStep;

    if (checkoutStep === TICKETS_WITH_CAPACITY_WAITLIST_PATH) {
        pathName = TICKETS_PATH;
    } else if (startsWith(checkoutStep, STATUS_PATH)) {
        pathName = STATUS_PATH;
    }

    return `${upperFirst(pathName)}Load`;
};

export const trackPageLoad = ({ checkoutStep, label }) => ({
    type: 'TRACK_PAGE_LOAD',
    meta: {
        analytics: {
            action: _getActionNameFromPath(checkoutStep),
            label,
            dimensions: {
                checkoutStep,
            },
        },
    },
});

/**
 *  Track waitlist events
 */

export const trackJoinWaitlistClick = (checkoutStep, label) => ({
    type: 'TRACK_JOIN_WAITLIST_CLICK',
    meta: {
        analytics: {
            ...WIDGET_TRACKING.TS_JOIN_WAITLIST_CLICK,
            label,
            dimensions: {
                checkoutStep,
            },
        },
    },
});

export const trackWaitlistEntrySubmit = () => (dispatch, getState) =>
    dispatch({
        type: 'TRACK_WAITLIST_ENTRY_SUBMIT',
        meta: {
            analytics: {
                ...WIDGET_TRACKING.WL_SUBMIT_CLICK,
                label: getWaitlistLabel(getState),
                dimensions: {
                    checkoutStep: WAITLIST_PATH,
                },
            },
        },
    });

/**
 *  Track external payment events
 */

export const trackXPBackToCheckout = () => ({
    type: 'TRACK_XP_BACK_TO_CHECKOUT',
    meta: {
        analytics: {
            ...WIDGET_TRACKING.XP_BACK_TO_CHECKOUT,
            label: 'back',
            dimensions: {
                checkoutStep: EXTERNAL_PAYMENT_PATH,
            },
        },
    },
});

export const trackReopenXPTab = () => ({
    type: 'TRACK_REOPEN_EXTERNAL_PAYMENT_TAB',
    meta: {
        analytics: {
            ...WIDGET_TRACKING.XP_REOPEN_PAYMENT_TAB,
            label: 'back',
            dimensions: {
                checkoutStep: EXTERNAL_PAYMENT_PATH,
            },
        },
    },
});

/**
 *  Track resend email events
 */

export const trackResendEmailClick = () => ({
    type: 'TRACK_RESEND_EMAIL_CLICK',
    meta: {
        analytics: {
            ...WIDGET_TRACKING.OC_EDIT_EMAIL,
            dimensions: {
                checkoutStep: PURCHASE_CONFIRMATION_PATH,
            },
        },
    },
});

export const trackResendEmailSubmit = () => ({
    type: 'TRACK_RESEND_EMAIL_SUBMIT',
    meta: {
        analytics: {
            ...WIDGET_TRACKING.OC_EDIT_EMAIL_UPDATE,
            dimensions: {
                checkoutStep: RESEND_EMAIL_PATH,
            },
        },
    },
});

export const trackResendEmailCancel = () => ({
    type: 'TRACK_RESEND_EMAIL_CANCEL',
    meta: {
        analytics: {
            ...WIDGET_TRACKING.OC_EDIT_EMAIL_CANCEL,
            dimensions: {
                checkoutStep: RESEND_EMAIL_PATH,
            },
        },
    },
});

/**
 * Track password / invite Protected Events
 */
export const trackProtectedEventsCTAClick = (privacySettingType, eventId) => (
    dispatch,
) =>
    dispatch({
        type: 'TRACK_PROTECTED_CTA_CLICK',
        meta: {
            analytics: {
                ...WIDGET_TRACKING.TRACK_PROTECTED_CTA_CLICK,
                label: _getLabelFromPrivacySettingType(privacySettingType),
                dimensions: {
                    checkoutStep: PROTECTED_PATH,
                    eventId,
                },
            },
        },
    });

/**
 * Track repeating events
 */

export const trackSeriesTicketsClick = (label) => ({
    type: 'TRACK_SERIES_PARENT_BUTTON_CLICK',
    meta: {
        analytics: {
            ...WIDGET_TRACKING.SP_TICKETS_CLICK,
            label,
            dimensions: {
                checkoutStep: SERIES_PATH,
            },
        },
    },
});

export const trackDatePickerClick = () => ({
    type: 'TRACK_DATE_PICKER_CLICK',
    meta: {
        analytics: {
            ...WIDGET_TRACKING.SP_DATE_PICKER_CLICK,
            dimensions: {
                checkoutStep: SERIES_PATH,
            },
        },
    },
});

export const trackDatePickerSelection = () => ({
    type: 'TRACK_DATE_PICKER_SELECTION',
    meta: {
        analytics: {
            ...WIDGET_TRACKING.SP_DATE_SELECTION,
            dimensions: {
                checkoutStep: SERIES_PATH,
            },
        },
    },
});

export const trackDatePickerSelectionClear = () => ({
    type: 'TRACK_DATE_PICKER_SELECTION_CLEAR',
    meta: {
        analytics: {
            ...WIDGET_TRACKING.SP_DATE_SELECTION_CLEAR,
            dimensions: {
                checkoutStep: SERIES_PATH,
            },
        },
    },
});

export const trackGroupRegistrationCTAClick = (label, checkoutStep) => (
    dispatch,
) =>
    dispatch({
        type: 'TRACK_GROUP_REGISTRATION_CTA_CLICK',
        meta: {
            analytics: {
                ...WIDGET_TRACKING[checkoutStep],
                label,
                dimensions: {
                    checkoutStep,
                },
            },
        },
    });

export const trackPaypalCTAClick = (label) => ({
    type: 'TRACK_PAYPAL_CTA_CLICK',
    meta: {
        analytics: {
            ...WIDGET_TRACKING.PAYPAL_CTA_CLICK,
            label,
        },
    },
});
