import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import { isPostalRequired, isRegionRequired } from '@eb/intl';
import { BaseAddressFields } from '@eb/eds-base-address-fields';
import {
    ADDRESS_VALUES_PROP,
    DISABLED_FIELDS_PROP,
} from '@eb/eds-base-address-fields';
import { REGIONS_BY_COUNTRY_PROP, COUNTRIES_PROP } from './constants';
import { getAdditionalProps } from '@eb/eds-utils';
import {
    getRegionLabel,
    getPostalLabel,
    formatCountriesForDropdown,
    formatRegionsForDropdown,
} from './lib';

const getCountryFromLocale = (locale) => locale.split('_')[1];

export default class AddressFields extends Component {
    static propTypes = {
        ...omit(BaseAddressFields.propTypes, 'countryValues'),
        /**
         * An object with address fields to be set as default
         * ex. {address1: '123 Fifth St.', address2: 'Unit 1', city: 'San Francisco', region: 'CA', postal: '94103', country: 'United States'}
         **/
        defaultValues: ADDRESS_VALUES_PROP,
        /**
         * An object with address fields to be disabled
         * ex. {country: true}
         */
        disabledFields: DISABLED_FIELDS_PROP,
        /**
         * An object with address fields that overrides defaultValues with fixed values
         * ex. {address1: '123 Fifth St.', address2: 'Unit 1', city: 'San Francisco', region: 'CA', postal: '94103', country: 'United States'}
         **/
        values: ADDRESS_VALUES_PROP,
        /**
         * a `regionsByCountry` object with the following format:
         * {isLoading: false, content: {US: [{code: 'CA', label: 'California'}, ...], ...}
         * Used to populate the country specific regions.
         **/
        regionsByCountry: REGIONS_BY_COUNTRY_PROP,
        /**
         * Callback to handle asnychronous loading of the regions if no regionsByCountry
         * prop is passed in.
         **/
        onRegionsUnknown: PropTypes.func,
        /**
         * The component desires to receive a `countries` object with the following format:
         * {isLoading: false, content: [{code: 'AF', label: 'Afghanistan', zip_required: false}, ...]}
         **/
        countries: COUNTRIES_PROP,
        /**
         * Callback to handle asynchronous loading of available Countries if no prop is passed in.
         **/
        onCountriesUnknown: PropTypes.func,
        /**
         * A callback triggered when the value of country changes, passing in the value of country as a parameter
         * ex. (value) => { console.log(value) // 'US' }
         */
        onCountryChange: PropTypes.func,
        /**
         * A string specifying the default country for AddressFields
         * In the form of country code: 'US', 'CA', etc.
         **/
        locale: PropTypes.string,
        /**
         * Text to render label-like above the fields
         */
        label: PropTypes.node,
        /**
         * Hides label above the fields
         */
        hideLabels: PropTypes.bool,
        /**
         * Determines whether it should use or not a FormField wrapper.
         */
        v2: PropTypes.bool,
        /**
         * Function to determine whether we should
         * display an error given the input state. Has a sensible default.
         */
        shouldDisplayError: PropTypes.func,
    };

    static defaultProps = {
        regionsByCountry: {},
        countries: {},
        defaultValues: {},
        disabledFields: {},
        values: {},
        hideLabels: false,
        v2: false,
    };

    static contextTypes = {
        locale: PropTypes.string,
    };

    constructor(props, context) {
        super(props, context);

        let country = props.values.country || props.defaultValues.country;
        const locale = props.locale || context.locale;

        if (!country && locale) {
            country = getCountryFromLocale(locale);
        }
        this.state = { country };
    }

    componentDidMount() {
        const {
            countries,
            regionsByCountry,
            onCountriesUnknown,
            onRegionsUnknown,
        } = this.props;

        if (
            !countries.isLoading &&
            isEmpty(countries.content) &&
            onCountriesUnknown
        ) {
            onCountriesUnknown();
        }

        if (
            !regionsByCountry.isLoading &&
            !regionsByCountry.content &&
            onRegionsUnknown
        ) {
            onRegionsUnknown();
        }
    }

    componentWillReceiveProps({ values: { country } }) {
        // allows setting an existing `input` element's value via props from a parent
        if (country !== undefined) {
            this.setState({ country });
        }
    }

    _handleCountryChange(country) {
        this.setState({ country });
        if (this.props.onCountryChange) {
            this.props.onCountryChange(country);
        }
    }

    render() {
        const {
            regionsByCountry,
            countries,
            defaultValues,
            disabledFields,
            label,
            hideLabels,
            error,
            idPrefix,
            namePrefix,
            onChange,
            onBlur,
            required,
            v2,
            shouldDisplayError,
        } = this.props;
        const { country } = this.state;
        const regions =
            regionsByCountry.content && regionsByCountry.content[country];
        const regionValues = regions && formatRegionsForDropdown(regions);
        const postalRequired = isPostalRequired(countries.content, country);
        const values = {
            ...this.props.value,
            country,
        };

        const extraAttrs = getAdditionalProps(this);

        return (
            <BaseAddressFields
                {...extraAttrs}
                defaultValues={defaultValues}
                values={values}
                countryValues={formatCountriesForDropdown(countries.content)}
                regionLabel={getRegionLabel(country)}
                regionRequired={isRegionRequired(country)}
                regionValues={regionValues}
                postalLabel={getPostalLabel(country)}
                postalRequired={postalRequired}
                postalShown={postalRequired}
                onCountryChange={this._handleCountryChange.bind(this)}
                error={error}
                idPrefix={idPrefix}
                namePrefix={namePrefix}
                onChange={onChange}
                onBlur={onBlur}
                required={required}
                disabledFields={disabledFields}
                label={label}
                hideLabels={hideLabels}
                v2={v2}
                shouldDisplayError={shouldDisplayError}
            />
        );
    }
}
