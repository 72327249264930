import * as React from 'react';

const RapipagoPaymentSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 48 32" {...props}>
        <path
            fill="#FFF"
            d="M3 31.5C1.6 31.5.5 30.4.5 29V3C.5 1.6 1.6.5 3 .5h42c1.4 0 2.5 1.1 2.5 2.5v26c0 1.4-1.1 2.5-2.5 2.5H3z"
        />
        <path
            fill="#373379"
            d="M45 1c1.1 0 2 .9 2 2v26c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h42m0-1H3C1.3 0 0 1.3 0 3v26c0 1.7 1.3 3 3 3h42c1.7 0 3-1.3 3-3V3c0-1.7-1.3-3-3-3z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#373379"
            d="M27.4 9.3s-2.5.9-3.4 1.9c0 0-1.5 1.3-1.9 3.4 0 0-.2 2 .7 2.9 0 0 .9 1.1 1.7 1.4 0 0 1.2.5 3.1.3 0 0 3.1-.4 4.7-2.2 0 0-.8 1.7-3.2 3.4 0 0-1.7 1.2-4.4 1.9 0 0-2.5.7-4.6.1 0 0-2.2-.4-3.3-1.8 0 0-1.1-1.1-1.1-2.7 0 0-.1-1.8 1.3-3.8 0 0 1.5-1.9 3-2.7 0 0 2-1.1 3.4-1.6-.1 0 1.9-.6 4-.5"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#E83F2E"
            d="M32.7 12.3c.7 1.8-.7 4.1-3.1 5.1-2.5 1.1-5.1.5-5.8-1.2-.7-1.8.7-4.1 3.1-5.1 2.4-1.2 5-.6 5.8 1.2"
        />
    </svg>
);

RapipagoPaymentSvg.displayName = 'RapipagoPaymentSvg';
export default RapipagoPaymentSvg;
