import React from 'react';
import find from 'lodash/find';

/**
 * Searches the specified children for the first component that has an id prop
 * @returns {string} Found ID
 */
export const getFirstAvailableChildId = (children) => {
    let childrenNormalized = children;

    childrenNormalized = React.Children.toArray(childrenNormalized);

    // no traversal into descendants for now, just check flat list (happy path)
    const childWithId = find(
        childrenNormalized,
        (child) => child.props && child.props.id,
    );

    return childWithId && childWithId.props.id;
};
