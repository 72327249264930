import { keysCamelToSnake } from '@eb/transformation-utils';

import { fetchV3WithTranslateServerErrors } from './base';

import {
    _getGroupSearchUrl,
    _validateGroupPasswordUrl,
    _getGroupCreateUrl,
} from './constants';

import { EVENT_DEFAULT_ERROR_MESSAGE } from '../constants';

/**
 * How to handle errors on group registration api
 */
const ERROR_SPEC = {
    default: () => EVENT_DEFAULT_ERROR_MESSAGE,
};

/**
 * Returns a `fetch` Promise to retrieve a list of team names that match the query
 *
 * @param {string} eventId the current event's ID
 * @param {string} querySearch the search query to lookup
 */
export const getGroupSuggestions = (eventId, querySearch) =>
    fetchV3WithTranslateServerErrors(
        ERROR_SPEC,
        _getGroupSearchUrl(eventId, querySearch),
    );

/**
 * Returns a Promise to validate the user's password
 *
 * @param {string} eventId the current event's ID
 * @param {string} teamId the team ID
 * @param {string} password the user input password to validate
 */
export const validateGroupPassword = (eventId, teamId, password) => {
    const postBody = {
        password,
    };

    return fetchV3WithTranslateServerErrors(
        ERROR_SPEC,
        _validateGroupPasswordUrl(eventId, teamId),
        {
            method: 'POST',
            body: JSON.stringify(postBody),
        },
    );
};

/**
 * Returns a `fetch` Promise to create a new group
 *
 * @param {string} eventId the current event's ID
 * @param {object} groupData Group settings selected by the group creator
 */
export const createGroup = (eventId, groupData) => {
    const groupDataKeysToSnake = keysCamelToSnake(groupData);

    return fetchV3WithTranslateServerErrors(
        ERROR_SPEC,
        _getGroupCreateUrl(eventId),
        {
            method: 'POST',
            body: JSON.stringify({
                public_event_id: eventId,
                ...groupDataKeysToSnake,
            }),
        },
    );
};
