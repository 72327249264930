import { keysCamelToSnake } from '@eb/transformation-utils';
import { fetchV3WithTranslateServerErrors, getOrderFlowHeaders } from './base';
import { EVENT_DEFAULT_ERROR_MESSAGE, PAYMENT_METHODS } from '../constants';
import { getTokens } from '../actions/tokens';
import {
    _getBraintreeClientTokenUrl,
    _getBraintreeNonceUrl,
} from './constants';
import { formatCreditCardInstrument } from './order';
import logger from '@eb/logger-bugsnag';
/**
 * How to handle errors on the event lifecycle
 */
const ERROR_SPEC = {
    default: () => EVENT_DEFAULT_ERROR_MESSAGE,
};

/**
 * Return a `fetch` Promise to retrieve a client token to authorize communication with braintree
 *
 * @param  {string} currency  the currency that we'll be transacting in
 */
export const getBraintreeClientToken = (currency) => {
    const errorSpec = ERROR_SPEC;
    const postBody = {
        currency,
    };

    return fetchV3WithTranslateServerErrors(
        errorSpec,
        _getBraintreeClientTokenUrl(),
        {
            method: 'POST',
            headers: getOrderFlowHeaders(getTokens()),
            body: JSON.stringify(postBody),
        },
    );
};

/**
 * Return a `fetch` Promise to retrieve a nonce to verify a card with braintree
 *
 * @param {object} params
 * @param {object} params.paymentData
 * @param {string} params.currency
 * @param {string} params.countryCode
 * @param {number} params.amount
 * @param {string} params.orderId
 * @param {string} params.eventId
 * @param {string} params.userCountry
 */
export const getBraintreeNonce = ({
    paymentData,
    currency,
    countryCode,
    amount,
    orderId,
    eventId,
    userCountry,
}) => {
    const errorSpec = ERROR_SPEC;
    let postBody = {};

    if (paymentData.paymentMethod === PAYMENT_METHODS.CREDIT) {
        const { instrument } = formatCreditCardInstrument({
            countryCode,
            payment: paymentData.payment,
            currency,
            instrumentType: PAYMENT_METHODS.CREDIT,
        });

        postBody = { instrument };
    } else if (paymentData.paymentMethod === PAYMENT_METHODS.USER_INSTRUMENT) {
        postBody = {
            instrument: {
                instrument_type: PAYMENT_METHODS.USER_INSTRUMENT,
                ...keysCamelToSnake(paymentData.payment),
            },
        };
    }
    try {
        postBody = {
            ...postBody,
            log_fields: {
                amount: amount,
                currency: currency,
                order_id: orderId,
                event_id: eventId,
                user_country: userCountry,
                flow: 'embedded_checkout',
            },
        };
    } catch (err) {
        logger.error(
            '[EB-123883] exception caught while logging more data for getBraintreeNonce',
            {
                err: err.message,
            },
        );
    }

    return fetchV3WithTranslateServerErrors(
        errorSpec,
        _getBraintreeNonceUrl(),
        {
            method: 'POST',
            headers: getOrderFlowHeaders(getTokens()),
            body: JSON.stringify(postBody),
        },
    );
};
