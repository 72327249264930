import isEmpty from 'lodash/isEmpty';
import {
    trackUserInput,
    trackDatePickerSelection,
    trackDatePickerClick,
    trackTaxInvoiceSelectionChange,
} from '../actions/eventAnalytics';
import {
    CHECKOUT_FORM_NAME,
    CHECKOUT_STEP_CHECKOUT,
    EVENT_SERIES_FORM_NAME,
} from '../constants';
import { CANNED_QUESTION_TAX_INVOICE } from '../containers/checkout/constants';
import {
    TAX_INVOICE_REGISTRATION_TYPES,
    TAX_INVOICE_FORM_FIELDS,
    trackedTaxFields,
} from './constants';
import { actionTypes } from 'redux-form';

let trackedInputs = {};

export const formAnalytics = (store) => (next) => (action) => {
    // eslint-disable-line complexity
    // We only track the first key enter on a field when the user is typing
    // If the user exits the field then returns to it, we track that again
    const questionName = action.meta ? action.meta.field : null;
    const formName = action.meta ? action.meta.form : null;
    const checkoutStep =
        formName === CHECKOUT_FORM_NAME ? CHECKOUT_STEP_CHECKOUT : null;
    const excludedQuestions = new Set(TAX_INVOICE_FORM_FIELDS);

    if (action.type === actionTypes.CHANGE) {
        if (
            !trackedInputs[questionName] &&
            !excludedQuestions.has(questionName)
        ) {
            store.dispatch(trackUserInput({ formName, questionName }));

            trackedInputs[questionName] = true;
        }

        if (questionName.includes(CANNED_QUESTION_TAX_INVOICE)) {
            const label =
                TAX_INVOICE_REGISTRATION_TYPES[action.payload] || null;

            store.dispatch(
                trackTaxInvoiceSelectionChange({ checkoutStep, label }),
            );
        }
    }

    if (action.type === actionTypes.BLUR) {
        trackedInputs = {};
        const [trackedGroup] = trackedTaxFields.filter((trackingGroup) =>
            trackingGroup.fieldList.includes(questionName),
        );
        const trackedAction = trackedGroup ? trackedGroup.action : null;

        if (trackedAction) {
            store.dispatch(
                trackedAction({ checkoutStep, label: questionName }),
            );
        }

        if (formName === EVENT_SERIES_FORM_NAME && !isEmpty(action.payload)) {
            store.dispatch(trackDatePickerSelection());
        }
    }

    if (
        action.type === actionTypes.FOCUS &&
        formName === EVENT_SERIES_FORM_NAME
    ) {
        store.dispatch(trackDatePickerClick());
    }

    return next(action);
};

export const _resetTrackedInputsForTesting = () => {
    trackedInputs = {};
};
