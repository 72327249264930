import { combineReducers } from 'redux';
import {
    CLEAR_PICK_A_SEAT_BEST_AVAILABLE_WARNING,
    SET_PICK_A_SEAT_BEST_AVAILABLE_WARNING,
} from '../actions/order/constants';

const bestAvailableWarning = (state = null, action) => {
    switch (action.type) {
        case SET_PICK_A_SEAT_BEST_AVAILABLE_WARNING:
            return action.payload;
        case CLEAR_PICK_A_SEAT_BEST_AVAILABLE_WARNING:
            return null;
        default:
            return state;
    }
};

export default combineReducers({
    bestAvailableWarning,
});
