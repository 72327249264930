import keyBy from 'lodash/keyBy';
import { lazyGettext } from '@eb/lazy-gettext';
import {
    AT_LOCALE,
    DE_LOCALE,
    ES_LOCALE,
    FR_BE_LOCALE,
    FR_CA_LOCALE,
    FR_LOCALE,
    IT_LOCALE,
} from './locales';

// ported from https://github.com/eventbrite/currint/blob/7a38b1f4ef5bef984e79cab343211d950a20f730/currint/currency.py#L117-L285
const RAW_CURRENCY_INFO = [
    ['AED', '', '', '784', 100, false, lazyGettext('UAE Dirham')],
    ['AFN', '\u060b', '', '971', 100, false, lazyGettext('Afghani')],
    ['ALL', 'Lek', '', '008', 100, false, lazyGettext('Lek')],
    ['AMD', '', '', '051', 100, false, lazyGettext('Armenian Dram')],
    [
        'ANG',
        'ƒ',
        '',
        '532',
        100,
        false,
        lazyGettext('Netherlands Antillean Guilder'),
    ],
    ['AOA', '', '', '973', 100, false, lazyGettext('Kwanza')],
    ['ARS', '$', '$', '032', 100, false, lazyGettext('Argentine Peso')],
    ['AUD', 'A$', '$', '036', 100, false, lazyGettext('Australian Dollar')],
    ['AWG', 'ƒ', '', '533', 100, false, lazyGettext('Aruban Florin')],
    ['AZN', 'ман', '', '944', 100, false, lazyGettext('Azerbaijanian Manat')],
    ['BAM', 'KM', '', '977', 100, false, lazyGettext('Convertible Mark')],
    ['BBD', '$', '', '052', 100, false, lazyGettext('Barbados Dollar')],
    ['BDT', '', '', '050', 100, false, lazyGettext('Taka')],
    ['BGN', 'лв', '', '975', 100, false, lazyGettext('Bulgarian Lev')],
    ['BHD', '', '', '048', 1000, false, lazyGettext('Bahraini Dinar')],
    ['BIF', '', '', '108', 1, false, lazyGettext('Burundi Franc')],
    ['BMD', '$', '', '060', 100, false, lazyGettext('Bermudian Dollar')],
    ['BND', '$', '', '096', 100, false, lazyGettext('Brunei Dollar')],
    ['BOB', '$b', '', '068', 100, false, lazyGettext('Boliviano')],
    ['BOV', '', '', '984', 100, false, lazyGettext('Mvdol')],
    ['BRL', 'R$', 'R$', '986', 100, false, lazyGettext('Brazilian Real')],
    ['BSD', '$', '', '044', 100, false, lazyGettext('Bahamian Dollar')],
    ['BTN', '', '', '064', 100, false, lazyGettext('Ngultrum')],
    ['BWP', 'P', '', '072', 100, false, lazyGettext('Pula')],
    ['BYR', 'p.', '', '974', 1, false, lazyGettext('Belarussian Ruble')],
    ['BZD', 'BZ$', '$', '084', 100, false, lazyGettext('Belize Dollar')],
    ['CAD', 'CA$', '$', '124', 100, false, lazyGettext('Canadian Dollar')],
    ['CDF', '', '', '976', 100, false, lazyGettext('Congolese Franc')],
    ['CHE', '', '', '947', 100, false, lazyGettext('WIR Euro')],
    ['CHF', 'Fr', 'Fr', '756', 100, true, lazyGettext('Swiss Franc')],
    ['CHW', '', '', '948', 100, false, lazyGettext('WIR Franc')],
    ['CLF', '', '', '990', 1, false, lazyGettext('Unidad de Fomento')],
    ['CLP', '$', '', '152', 1, false, lazyGettext('Chilean Peso')],
    ['CNY', '¥', '', '156', 100, false, lazyGettext('Yuan Renminbi')],
    ['COP', '$', '', '170', 100, false, lazyGettext('Colombian Peso')],
    ['COU', '', '', '970', 100, false, lazyGettext('Unidad de Valor Real')],
    ['CRC', '₡', '', '188', 100, false, lazyGettext('Costa Rican Colon')],
    ['CUC', '', '', '931', 100, false, lazyGettext('Peso Convertible')],
    ['CUP', '₱', '', '192', 100, false, lazyGettext('Cuban Peso')],
    ['CVE', '', '', '132', 100, false, lazyGettext('Cape Verde Escudo')],
    ['CZK', 'Kč', 'Kč', '203', 100, false, lazyGettext('Czech Koruna')],
    ['DJF', '', '', '262', 1, false, lazyGettext('Djibouti Franc')],
    ['DKK', 'Dkr', 'kr', '208', 100, true, lazyGettext('Danish Krone')],
    ['DOP', 'RD$', '', '214', 100, false, lazyGettext('Dominican Peso')],
    ['DZD', '', '', '012', 100, false, lazyGettext('Algerian Dinar')],
    ['EGP', '', '', '818', 100, false, lazyGettext('Egyptian Pound')],
    ['ERN', '', '', '232', 100, false, lazyGettext('Nakfa')],
    ['ETB', '', '', '230', 100, false, lazyGettext('Ethiopian Birr')],
    ['EUR', '€', '€', '978', 100, false, lazyGettext('Euro')],
    ['FJD', '$', '', '242', 100, false, lazyGettext('Fiji Dollar')],
    ['FKP', '£', '', '238', 100, false, lazyGettext('Falkland Islands Pound')],
    ['GBP', '£', '£', '826', 100, false, lazyGettext('Pound Sterling')],
    ['GEL', '', '', '981', 100, false, lazyGettext('Lari')],
    ['GHS', '', '', '936', 100, false, lazyGettext('Ghana Cedi')],
    ['GIP', '£', '', '292', 100, false, lazyGettext('Gibraltar Pound')],
    ['GMD', '', '', '270', 100, false, lazyGettext('Dalasi')],
    ['GNF', '', '', '324', 1, false, lazyGettext('Guinea Franc')],
    ['GTQ', 'Q', '', '320', 100, false, lazyGettext('Quetzal')],
    ['GYD', '$', '', '328', 100, false, lazyGettext('Guyana Dollar')],
    ['HKD', 'HK$', '$', '344', 100, false, lazyGettext('Hong Kong Dollar')],
    ['HNL', 'L', '', '340', 100, false, lazyGettext('Lempira')],
    ['HRK', 'kn', '', '191', 100, false, lazyGettext('Croatian Kuna')],
    ['HTG', '', '', '332', 100, false, lazyGettext('Gourde')],
    ['HUF', 'Ft', 'FT', '348', 100, false, lazyGettext('Forint')],
    ['IDR', 'Rp', '', '360', 100, false, lazyGettext('Rupiah')],
    ['ILS', '₪', '₪', '376', 100, false, lazyGettext('New Israeli Sheqel')],
    ['INR', '₹', '', '356', 100, false, lazyGettext('Indian Rupee')],
    ['IQD', '', '', '368', 1000, false, lazyGettext('Iraqi Dinar')],
    ['IRR', '\ufdfc', '', '364', 100, false, lazyGettext('Iranian Rial')],
    ['ISK', 'kr', '', '352', 1, false, lazyGettext('Iceland Krona')],
    ['JMD', 'J$', '', '388', 100, false, lazyGettext('Jamaican Dollar')],
    ['JOD', '', '', '400', 1000, false, lazyGettext('Jordanian Dinar')],
    ['JPY', '\u00a5', '\u00a5', '392', 1, false, lazyGettext('Yen')],
    ['KES', '', '', '404', 100, false, lazyGettext('Kenyan Shilling')],
    ['KGS', 'лв', '', '417', 100, false, lazyGettext('Som')],
    ['KHR', '៛', '', '116', 100, false, lazyGettext('Riel')],
    ['KMF', '', '', '174', 1, false, lazyGettext('Comoro Franc')],
    ['KPW', '₩', '', '408', 100, false, lazyGettext('North Korean Won')],
    ['KRW', '', '', '410', 1, false, lazyGettext('Won')],
    ['KWD', '', '', '414', 1000, false, lazyGettext('Kuwaiti Dinar')],
    ['KYD', '$', '', '136', 100, false, lazyGettext('Cayman Islands Dollar')],
    ['KZT', 'лв', '', '398', 100, false, lazyGettext('Tenge')],
    ['LAK', '₭', '', '418', 100, false, lazyGettext('Kip')],
    ['LBP', '£', '', '422', 100, false, lazyGettext('Lebanese Pound')],
    ['LKR', '₨', '', '144', 100, false, lazyGettext('Sri Lanka Rupee')],
    ['LRD', '$', '', '430', 100, false, lazyGettext('Liberian Dollar')],
    ['LSL', '', '', '426', 100, false, lazyGettext('Loti')],
    ['LTL', 'Lt', '', '440', 100, false, lazyGettext('Lithuanian Litas')],
    ['LYD', '', '', '434', 1000, false, lazyGettext('Libyan Dinar')],
    ['MAD', '', '', '504', 100, false, lazyGettext('Moroccan Dirham')],
    ['MDL', '', '', '498', 100, false, lazyGettext('Moldovan Leu')],
    ['MGA', '', '', '969', 5, false, lazyGettext('Malagasy Ariary')],
    ['MKD', 'ден', '', '807', 100, false, lazyGettext('Denar')],
    ['MMK', '', '', '104', 100, false, lazyGettext('Kyat')],
    ['MNT', '$', '', '496', 100, false, lazyGettext('Tugrik')],
    ['MOP', '', '', '446', 100, false, lazyGettext('Pataca')],
    ['MRO', '', '', '478', 5, false, lazyGettext('Ouguiya')],
    ['MUR', '₨', '', '480', 100, false, lazyGettext('Mauritius Rupee')],
    ['MVR', '', '', '462', 100, false, lazyGettext('Rufiyaa')],
    ['MWK', '', '', '454', 100, false, lazyGettext('Kwacha')],
    ['MXN', 'Mex$', '$', '484', 100, false, lazyGettext('Mexican Peso')],
    [
        'MXV',
        '',
        '',
        '979',
        100,
        false,
        lazyGettext('Mexican Unidad de Inversion (UDI]'),
    ],
    ['MYR', 'RM', 'RM', '458', 100, false, lazyGettext('Malaysian Ringgit')],
    ['MZN', 'MT', '', '943', 100, false, lazyGettext('Mozambique Metical')],
    ['NAD', '$', '', '516', 100, false, lazyGettext('Namibia Dollar')],
    ['NGN', '₦', '', '566', 100, false, lazyGettext('Naira')],
    ['NIO', 'C$', '', '558', 100, false, lazyGettext('Cordoba Oro')],
    ['NOK', 'Nkr', 'kr', '578', 100, true, lazyGettext('Norwegian Krone')],
    ['NPR', '₨', '', '524', 100, false, lazyGettext('Nepalese Rupee')],
    ['NZD', 'NZ$', '$', '554', 100, false, lazyGettext('New Zealand Dollar')],
    ['OMR', '﷼', '', '512', 1000, false, lazyGettext('Rial Omani')],
    ['PAB', 'B/.', '', '590', 100, false, lazyGettext('Balboa')],
    ['PEN', 'S/.', '', '604', 100, false, lazyGettext('Nuevo Sol')],
    ['PGK', '', '', '598', 100, false, lazyGettext('Kina')],
    ['PHP', '₱', '₱', '608', 100, false, lazyGettext('Philippine Peso')],
    ['PKR', '₨', '', '586', 100, false, lazyGettext('Pakistan Rupee')],
    ['PLN', 'zł', 'zł', '985', 100, true, lazyGettext('Zloty')],
    ['PYG', 'Gs', '', '600', 1, false, lazyGettext('Guarani')],
    ['QAR', '﷼', '', '634', 100, false, lazyGettext('Qatari Rial')],
    ['RON', 'lei', '', '946', 100, false, lazyGettext('New Romanian Leu')],
    ['RSD', 'Дин.', '', '941', 100, false, lazyGettext('Serbian Dinar')],
    ['RUB', 'руб', '', '643', 100, false, lazyGettext('Russian Ruble')],
    ['RWF', '', '', '646', 1, false, lazyGettext('Rwanda Franc')],
    ['SAR', '﷼', '', '682', 100, false, lazyGettext('Saudi Riyal')],
    ['SBD', '$', '', '090', 100, false, lazyGettext('Solomon Islands Dollar')],
    ['SCR', '₨', '', '690', 100, false, lazyGettext('Seychelles Rupee')],
    ['SDG', '', '', '938', 100, false, lazyGettext('Sudanese Pound')],
    ['SEK', 'kr', 'kr', '752', 100, true, lazyGettext('Swedish Krona')],
    ['SGD', 'SGD$', 'SGD$', '702', 100, false, lazyGettext('Singapore Dollar')],
    ['SHP', '£', '', '654', 100, false, lazyGettext('Saint Helena Pound')],
    ['SLL', '', '', '694', 100, false, lazyGettext('Leone')],
    ['SOS', 'S', '', '706', 100, false, lazyGettext('Somali Shilling')],
    ['SRD', '$', '', '968', 100, false, lazyGettext('Surinam Dollar')],
    ['SSP', '', '', '728', 100, false, lazyGettext('South Sudanese Pound')],
    ['STD', '', '', '678', 100, false, lazyGettext('Dobra')],
    ['SVC', '', '', '222', 100, false, lazyGettext('El Salvador Colon')],
    ['SYP', '£', '', '760', 100, false, lazyGettext('Syrian Pound')],
    ['SZL', '', '', '748', 100, false, lazyGettext('Lilangeni')],
    ['THB', '฿', '฿', '764', 100, false, lazyGettext('Baht')],
    ['TJS', '', '', '972', 100, false, lazyGettext('Somoni')],
    ['TMT', '', '', '934', 100, false, lazyGettext('Turkmenistan New Manat')],
    ['TND', '', '', '788', 1000, false, lazyGettext('Tunisian Dinar')],
    ['TOP', '', '', '776', 100, false, lazyGettext("Pa'anga")],
    ['TRY', '₺', '', '949', 100, false, lazyGettext('Turkish Lira')],
    [
        'TTD',
        'TT$',
        '',
        '780',
        100,
        false,
        lazyGettext('Trinidad and Tobago Dollar'),
    ],
    ['TWD', 'NT$', '$', '901', 100, false, lazyGettext('New Taiwan Dollar')],
    ['TZS', '', '', '834', 100, false, lazyGettext('Tanzanian Shilling')],
    ['UAH', '₴', '', '980', 100, false, lazyGettext('Hryvnia')],
    ['UGX', '', '', '800', 1, false, lazyGettext('Uganda Shilling')],
    ['USD', '$', '$', '840', 100, false, lazyGettext('US Dollar')],
    [
        'UYI',
        '',
        '',
        '940',
        1,
        false,
        lazyGettext('Uruguay Peso en Unidades Indexadas (URUIURUI]'),
    ],
    ['UYU', '$U', '', '858', 100, false, lazyGettext('Peso Uruguayo')],
    ['UZS', 'лв', '', '860', 100, false, lazyGettext('Uzbekistan Sum')],
    ['VEF', 'Bs', '', '937', 100, false, lazyGettext('Bolivar')],
    ['VND', '₫', '', '704', 1, false, lazyGettext('Dong')],
    ['VUV', '', '', '548', 1, false, lazyGettext('Vatu')],
    ['WST', '', '', '882', 100, false, lazyGettext('Tala')],
    ['XAF', '', '', '950', 1, false, lazyGettext('CFA Franc BEAC')],
    ['XCD', '$', '', '951', 100, false, lazyGettext('East Caribbean Dollar')],
    ['XOF', '', '', '952', 1, false, lazyGettext('CFA Franc BCEAO')],
    ['XPF', '', '', '953', 1, false, lazyGettext('CFP Franc')],
    ['YER', '﷼', '', '886', 100, false, lazyGettext('Yemeni Rial')],
    ['ZAR', 'R', '', '710', 100, false, lazyGettext('Rand')],
    ['ZMW', '', '', '967', 100, false, lazyGettext('Zambian Kwacha')],
    ['ZWL', '', '', '932', 100, false, lazyGettext('Zimbabwe Dollar')],
    ['XBT', '', '', '', 100000000, false, lazyGettext('Bitcoin')],
];

/**
 * @typedef {{code: string, symbol: string, shortSymbol: string, name: string, numericCode: string, divisor: number, isSuffix: boolean }} CurrencyInfo
 */
/**
 * @type Object.<string, CurrencyInfo>
 */
export const CURRENCY_MAP = keyBy(
    RAW_CURRENCY_INFO.map((currencyTuple) => {
        var [
            code,
            symbol,
            shortSymbol,
            numericCode,
            divisor,
            isSuffix,
            name,
        ] = currencyTuple;

        return {
            code,
            symbol,
            shortSymbol,
            name,
            numericCode,
            divisor,
            isSuffix,
        };
    }),
    'code',
);

export const SYMBOL_PLACEHOLDER = '\u00A4';

export const PRECEDING_SPACE_PLACEHOLDER = '\u00A0\u00A4';
export const FOLLOWING_SPACE_PLACEHOLDER = '\u00A4\u00A0';
export const DECIMAL_REGEX = /0.00/;
export const THOUSANDS_REGEX = /#.##/;
export const THOUSANDS_GLOBAL_REGEX = /#/g;
export const THOUSANDS_GROUP_GLOBAL_REGEX = /(\d{3})(?=\d)/g;
export const ZERO_GLOBAL_REGEX = /0/g;
export const BRACKET_REGEX = /\((.*)\)/;

export const FORMAT_NUMBER_OPTIONS = ['decimal', 'thousand', 'precision'];

export const DEFAULT_THOUSANDS_NOTATION = ',';
export const DEFAULT_DECIMAL_NOTATION = '.';
export const DEFAULT_CURRENCY = 'USD';
export const DEFAULT_NUMBER_SETTINGS = {
    precision: 0,
    grouping: 3,
    thousand: ',',
    decimal: '.',
};
export const DEFAULT_CURRENCY_SETTINGS = {
    symbol: '$',
    format: '%s%v',
    decimal: '.',
    thousand: ',',
    precision: 2,
    grouping: 3,
};

export const DEFAULT_CURRENCY_FORMAT = '#,##0.00';
export const US_CURRENCY_FORMAT = '¤#,##0.00';
export const DE_CURRENCY_FORMAT = '#.##0,00 ¤';

export const CURRENCY_SYMBOL_IS_SUFFIX_FOR_LOCALE = {
    [AT_LOCALE]: true,
    [DE_LOCALE]: true,
    [ES_LOCALE]: true,
    [FR_BE_LOCALE]: true,
    [FR_CA_LOCALE]: true,
    [FR_LOCALE]: true,
    [IT_LOCALE]: true,
};
