import { Schema } from 'normalizr';
import { transformUtil } from '@eb/transformation-utils';
import {
    PaginatedResponse,
    PaginatedResponseFormatted,
} from './pagination.types';
import { SingleUserInstrumentResponse } from './userInstruments';
import { UserInstrument } from '../../models/user';
import {
    InstrumentType,
    PaymentMethodType,
    PAYMENT_METHODS_INFO,
    PaymentOptionType,
    PaymentConstraintName,
    PaymentMethodInfo,
} from '../../models/paymentMethod';

const paymentOptionsSchema = new Schema('payment_options');

export interface PaymentOptionsResponse extends PaginatedResponse {
    payment_options: Array<{
        type: PaymentOptionType;
        instrument_types: Array<{
            variants: Array<PaymentConstraintName>;
            type: InstrumentType;
        }>;
    }>;
    user_instruments?: Array<SingleUserInstrumentResponse>;
}

export interface PaymentOptionsResponseFormatted
    extends PaginatedResponseFormatted {
    paymentOptions: Array<{
        type: PaymentOptionType;
        instrumentTypes: Array<{
            variants: Array<PaymentConstraintName>;
            type: InstrumentType;
        }>;
    }>;
    userInstruments?: Array<UserInstrument>;
}

const getInstrumentType = (
    instrument: { type: InstrumentType },
    paymentOption: { type: PaymentOptionType },
) => {
    const instrumentTypeForCreditCard =
        paymentOption.type === PaymentOptionType.AUTHNET
            ? PaymentMethodType.AUTHNET
            : PaymentMethodType.CREDIT;

    return {
        ...instrument,
        type:
            instrument.type === InstrumentType.CREDIT_CARD
                ? instrumentTypeForCreditCard
                : instrument.type,
    };
};

export const transformPaymentOptions = (data: PaymentOptionsResponse) => {
    const transformedPaymentOptions = transformUtil({
        response: data,
        schema: paymentOptionsSchema,
    });
    const paymentOptions = transformedPaymentOptions.entities.payment_options[
        transformedPaymentOptions.result
    ] as PaymentOptionsResponseFormatted;

    const paymentMethodsInfo = (PAYMENT_METHODS_INFO as unknown) as Record<
        string,
        PaymentMethodInfo
    >;

    // NOTE: The response from payment_options uses 'CREDIT_CARD' to identify credit cards.
    // The payment_methods endpoint expects 'CREDIT' when we set it. Since payment_options
    // is deprecated, we'll go with the payment_methods version.

    // Replace 'CREDIT_CARD' with 'CREDIT' (when using EPP) or 'AUTHNET' (when using Authnet) in instrument.type.
    const supportedPaymentOptions = paymentOptions.paymentOptions.map(
        (singlePaymentOption) => ({
            ...singlePaymentOption,
            instrumentTypes: singlePaymentOption.instrumentTypes
                .map((instrument) =>
                    getInstrumentType(instrument, singlePaymentOption),
                )
                // Filter out payment methods we do not support (those we don't have in PAYMENTS_METHODS_INFO)
                .filter((instrument) => paymentMethodsInfo[instrument.type]),
        }),
    );

    return {
        ...paymentOptions,
        paymentOptions: supportedPaymentOptions,
    };
};
