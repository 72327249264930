export * as statusConstants from './ticketStatusConstants';
export {
    TICKET_STATUS_MAP,
    SOLD_OUT_STATUS,
    SALES_ENDED_STATUS,
    UNAVAILABLE_STATUS,
    SALES_NOT_STARTED_STATUS,
    SALES_START_SOON_STATUS,
    ON_SALE_STATUS,
    SALES_END_SOON_STATUS,
} from './ticketStatusConstants';
export { default as TicketEditCardContent } from './TicketEditCardContent';

export const EXAMPLE_ACTION_ITEMS = [
    { content: 'Copy Ticket', value: 'copy' },
    { content: 'Delete Ticket', value: 'delete' },
];
