import { fetchV3WithTranslateServerErrors, getOrderFlowHeaders } from './base';
import { EVENT_DEFAULT_ERROR_MESSAGE } from '../constants';
import { getTokens } from '../actions/tokens';
import {
    _getUserAccountUrl,
    _getVaultInstrumentsUrl,
    _getListVaultInstrumentsUrl,
    _getUserAccountDetails,
    _getCurrentUserAccountDetailsUrl,
} from './constants';

/**
 * How to handle errors on the event lifecycle
 */
const ERROR_SPEC = {
    default: () => EVENT_DEFAULT_ERROR_MESSAGE,
};

/**
 * Returns a `fetch` Promise to retrieve a list of the user's stored payment instruments
 *
 * @param {string} userId The logged in user's public ID
 */
export const getVaultInstruments = (userId, usableOn) => {
    let url = _getListVaultInstrumentsUrl(userId);

    if (usableOn) {
        // We split by '.' to remove the milliseconds, which are not compatible with our endpoint
        url = `${url}&usable_on=${usableOn.toISOString().split('.')[0]}Z`;
    }
    return fetchV3WithTranslateServerErrors(ERROR_SPEC, url, {
        method: 'GET',
        headers: getOrderFlowHeaders(getTokens()),
    });
};

/**
 * Returns a `fetch` Promise to store a new vault instrument in the user's "wallet"
 *
 * @param {string} userId The logged in user's public ID
 */
export const createNewVaultInstrument = (
    userId,
    instrument,
    billingInformation,
) =>
    fetchV3WithTranslateServerErrors(
        ERROR_SPEC,
        _getVaultInstrumentsUrl(userId),
        {
            method: 'POST',
            headers: getOrderFlowHeaders(getTokens()),
            body: JSON.stringify({
                instrument,
                billing_information: billingInformation,
            }),
        },
    );

/**
 * Returns a `fetch` Promise to retrieve a user's account type
 *
 * @param {string} email The logged in user's email
 */
export const getUserAccountDetails = (email) =>
    fetchV3WithTranslateServerErrors(ERROR_SPEC, _getUserAccountDetails(), {
        method: 'POST',
        headers: getOrderFlowHeaders(getTokens()),
        body: JSON.stringify({ email }),
    });

/**
 * Returns a `fetch` Promise to retrieve the current user's account data
 */
export const getCurrentUserAccountDetails = () =>
    fetchV3WithTranslateServerErrors(
        ERROR_SPEC,
        _getCurrentUserAccountDetailsUrl(),
    );
