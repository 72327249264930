import * as React from 'react';

const BoletoPaymentSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 48 32" {...props}>
        <path
            fill="#FFF"
            d="M3 31.5C1.6 31.5.5 30.4.5 29V3C.5 1.6 1.6.5 3 .5h42c1.4 0 2.5 1.1 2.5 2.5v26c0 1.4-1.1 2.5-2.5 2.5H3z"
        />
        <path d="M45 1c1.1 0 2 .9 2 2v26c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h42m0-1H3C1.3 0 0 1.3 0 3v26c0 1.7 1.3 3 3 3h42c1.7 0 3-1.3 3-3V3c0-1.7-1.3-3-3-3z" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#231F20"
            d="M9.2 8.3h1.6v15.3H9.2V8.3zm2.2 0v15.3h.5V8.3h-.5zm.9 0v15.3h.9V8.3h-.9zm1.8 0v15.3h.5V8.3h-.5zm.7 0v15.3h.4V8.3h-.4zM8 8.3v15.3h.5V8.3H8zm-1.1 0v15.3h.9V8.3h-.9zm11 0v15.3h1.6V8.3h-1.6zm2.2 0v15.3h.5V8.3h-.5zm.9 0v15.3h.9V8.3H21zm1.8 0v15.3h.5V8.3h-.5zm.7 0v15.3h.4V8.3h-.4zm-6.8 0v15.3h.5V8.3h-.5zm-1.1 0v15.3h.9V8.3h-.9zm8.9 0v15.3h.9V8.3h-.9zm1.8 0v15.3h.5V8.3h-.5zm.7 0v15.3h.4V8.3H27zm1.9 0v15.3h.5V8.3h-.5zm-1.1 0v15.3h.9V8.3h-.9zm2.3 0v15.3h.5V8.3h-.5zm.7 0v15.3h.4V8.3h-.4zm1.9 0v15.3h.5V8.3h-.5zm-1.2 0v15.3h.9V8.3h-.9zm2.1 0v15.3h1.6V8.3h-1.6zm2.2 0v15.3h.5V8.3h-.5zm.9 0v15.3h.9V8.3h-.9zm1.8 0v15.3h.5V8.3h-.5zm.7 0v15.3h.4V8.3h-.4zm1 0v15.3h.9V8.3h-.9z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFF"
            d="M8.7 23.7H40v-8.6H8.7v8.6"
        />
        <path
            fill="#231F20"
            d="M33.5 20.5c0-1 .3-1.8.9-2.3 1-.9 2.7-.8 3.7.1.9.9 1 2.7.4 3.8-.2.4-.5.7-.9 1-2.1 1.1-4.1-.5-4.1-2.6zm1 0c0 .7.2 1.3.5 1.6.6.7 1.7.7 2.4 0 .7-.8.7-2.4 0-3.2-.6-.7-1.7-.7-2.4 0-.3.4-.5.9-.5 1.6zm-1.5 2c-.2 0-.5.1-.7 0-.1 0-.1-.1-.2-.2-.2-.5-.1-1.4-.1-1.9v-1.9h1v-.7h-1v-2l-1 .6v1.4h-.7v.7h.7v2.1c0 .7-.1 1.7.1 2.3.1.2.2.3.4.4.4.3 1 .2 1.5.1v-.9zm-4.3-1l1 .1c-.2.6-.4 1-.9 1.3-1 .8-2.7.6-3.6-.3-1-1.1-1-3.2 0-4.3 1-1 2.8-1.1 3.7 0 .6.6.7 1.6.7 2.4h-4.2c0 .6.2 1.1.5 1.4.5.5 1.4.7 2.1.2.4-.1.6-.4.7-.8zM25.6 20h3.1c0-.5-.2-.8-.4-1.1-.6-.7-1.6-.7-2.3-.1-.2.3-.4.7-.4 1.2zm-3.2 3.3h1v-7.8h-1v7.8zm-6.3-2.8c0-1 .3-1.8.9-2.3 1-.9 2.7-.8 3.7.1.9.9 1 2.7.4 3.8-.2.4-.5.7-.9 1-1 .6-2.4.4-3.3-.4-.6-.5-.8-1.2-.8-2.2zm1 0c0 .7.2 1.3.5 1.6.6.7 1.7.7 2.4 0 .7-.8.7-2.4 0-3.2-.6-.7-1.7-.7-2.4 0-.4.4-.5.9-.5 1.6zm-7.8 2.8v-7.8c1.3 0 3.1-.3 4.3.2.4.2.6.4.8.7.4.6.4 1.3 0 2-.2.3-.4.5-.8.7.5.1.8.4 1.1.7.5.6.5 1.5.1 2.2-.2.3-.3.6-.6.7-.2.2-.5.3-.8.4-1.2.4-2.8.2-4.1.2zm1-4.5c.8 0 1.9.2 2.7-.1.3-.1.5-.2.6-.4.3-.4.3-1 0-1.4-.1-.2-.3-.3-.5-.4-.8-.3-1.9-.1-2.7-.1v2.4h-.1zm0 3.6c.8 0 1.8.1 2.6 0 .4-.1.8-.3 1-.6.2-.4.2-1-.1-1.4-.1-.2-.4-.4-.6-.5-.9-.3-2-.1-2.9-.1v2.6z"
        />
    </svg>
);

BoletoPaymentSvg.displayName = 'BoletoPaymentSvg';
export default BoletoPaymentSvg;
