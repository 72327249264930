import { Dispatch } from 'redux';
import {
    QuestionsCollection,
    InteractiveQuestion,
    QuestionsResponses,
} from '../api/models/survey';
import { OrderResponse } from '../api/models/order';
import {
    getQuestionsCollectionFromOrderResponse,
    getInteractiveQuestionsByAttendeeIdFromOrderResponse,
} from '../api/transformations/survey';
import { getSurveyResponsesByAttendeeIdFromOrderResponse } from '../api/transformations/order';
import { CHECKOUT_FORM_NAME } from '../constants/checkoutForm';
import { changeSurveyField } from '../utils/surveys';
import {
    BUYER_EMAIL_FIELD,
    BUYER_FIRST_NAME_FIELD,
    BUYER_LAST_NAME_FIELD,
    CANNED_QUESTION_EMAIL_ADDRESS,
    CANNED_QUESTION_FIRST_NAME,
    CANNED_QUESTION_LAST_NAME,
    getFieldName,
} from '../containers/checkout/constants';
import { PrefillSurveyFieldsInfo } from '../models/survey';
import { CheckoutState } from '../state';
import { getPrefillSurveyInfo } from '../selectors/survey';

export enum SurveyActionType {
    initSurveys = 'initSurveys',
}

export interface InitSurveysAction {
    type: SurveyActionType.initSurveys;
    payload: {
        questionsCollection: QuestionsCollection;
        interactiveQuestionsByAttendeeId: Record<
            string,
            Array<InteractiveQuestion>
        >;
        surveyResponsesByAttendeeId: Record<string, QuestionsResponses>;
    };
}
export const getInitSurveysAction = (payload: {
    questionsCollection: QuestionsCollection;
    interactiveQuestionsByAttendeeId: Record<
        string,
        Array<InteractiveQuestion>
    >;
    surveyResponsesByAttendeeId: Record<string, QuestionsResponses>;
}): InitSurveysAction => ({
    type: SurveyActionType.initSurveys,
    payload,
});

export const getInitSurveysActionFromOrderResponse = (
    orderResponse: OrderResponse,
): InitSurveysAction =>
    getInitSurveysAction({
        questionsCollection:
            getQuestionsCollectionFromOrderResponse(orderResponse) ?? {},
        interactiveQuestionsByAttendeeId:
            getInteractiveQuestionsByAttendeeIdFromOrderResponse(
                orderResponse,
            ) ?? {},
        surveyResponsesByAttendeeId: getSurveyResponsesByAttendeeIdFromOrderResponse(
            orderResponse,
        ),
    });

export type SurveyAction = InitSurveysAction;

export const prefillSurveyFields = ({
    formName = CHECKOUT_FORM_NAME,
    emailToPrefill = '',
    firstNameToPrefill = '',
    lastNameToPrefill = '',
    attendeeWithSurvey,
    isTransferredOrder,
    shouldManageUserSavedSurveyData,
    surveyResponseNameValues = {},
}: PrefillSurveyFieldsInfo) => (dispatch: Dispatch<any>) => {
    if (emailToPrefill) {
        dispatch(
            changeSurveyField(BUYER_EMAIL_FIELD, emailToPrefill, formName),
        );
    }

    if (shouldManageUserSavedSurveyData) {
        dispatch(
            changeSurveyField(
                BUYER_FIRST_NAME_FIELD,
                firstNameToPrefill,
                formName,
            ),
        );
        dispatch(
            changeSurveyField(
                BUYER_LAST_NAME_FIELD,
                lastNameToPrefill,
                formName,
            ),
        );

        if (isTransferredOrder) {
            Object.entries(
                surveyResponseNameValues,
            ).forEach(([surveyFieldName, surveyFieldValue]) =>
                dispatch(
                    changeSurveyField(
                        surveyFieldName,
                        surveyFieldValue,
                        formName,
                    ),
                ),
            );
        } else if (attendeeWithSurvey) {
            dispatch(
                changeSurveyField(
                    getFieldName(
                        attendeeWithSurvey.id,
                        CANNED_QUESTION_EMAIL_ADDRESS,
                    ),
                    emailToPrefill,
                    formName,
                ),
            );
            dispatch(
                changeSurveyField(
                    getFieldName(
                        attendeeWithSurvey.id,
                        CANNED_QUESTION_FIRST_NAME,
                    ),
                    firstNameToPrefill,
                    formName,
                ),
            );
            dispatch(
                changeSurveyField(
                    getFieldName(
                        attendeeWithSurvey.id,
                        CANNED_QUESTION_LAST_NAME,
                    ),
                    lastNameToPrefill,
                    formName,
                ),
            );
        }
    }
};

export const getSurveyInfoAndPrefillFieldsAction = () => (
    dispatch: Dispatch<any>,
    getState: () => CheckoutState,
) => {
    const state = getState();
    const prefillSurveyFieldsInfo = getPrefillSurveyInfo(state);

    dispatch(prefillSurveyFields(prefillSurveyFieldsInfo));
};
