import React from 'react';
import PropTypes from 'prop-types';
import { translationPropType } from '@eb/i18n';

const _getFeeAndTaxContent = (feeAndTax) =>
    feeAndTax.map((item) => (
        <span
            key={item}
            data-spec="fee-information"
            className="eds-text-bs eds-ticket-card-content__fee-information eds-l-pad-right-1"
        >
            {item}
        </span>
    ));

export default class PriceInformation extends React.PureComponent {
    static propTypes = {
        price: translationPropType,
        /**
         * The string representing the associated fees and taxes. Should include the appropriate
         * copy. If not included, the section will not be rendered.
         **/
        feeAndTax: PropTypes.arrayOf(translationPropType),
        /**
         * Represents the original cost of the ticket prior to discounts applied
         **/
        primaryPrice: translationPropType,
    };

    render() {
        const { price, feeAndTax, primaryPrice } = this.props;

        let feeAndTaxContent;
        let primaryPriceContent;

        if (feeAndTax) {
            feeAndTaxContent = _getFeeAndTaxContent(feeAndTax);
        }

        if (primaryPrice) {
            primaryPriceContent = (
                <span className="eds-text--strikethrough eds-l-pad-left-1">
                    {primaryPrice}
                </span>
            );
        }

        return (
            <div
                className="ticket-card__price-information"
                data-spec="price-information"
            >
                <span className="ticket-price">
                    {price}
                    {primaryPriceContent}
                </span>
                {feeAndTaxContent}
            </div>
        );
    }
}
