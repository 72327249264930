import { Analytics } from './analyticsModelSchema';

export const Event = {
    created: ({ eventId }) =>
        Analytics.event({
            object: 'Event',
            action: 'Created',
            properties: {
                eventId: {
                    type: 'dimension',
                    value: eventId,
                },
            },
        }),
    discarded: () =>
        Analytics.event({
            object: 'Event',
            action: 'Discarded',
        }),
    saved: ({ eventId }) =>
        Analytics.event({
            object: 'Event',
            action: 'Saved',
            properties: {
                eventId: {
                    type: 'dimension',
                    value: eventId,
                },
            },
        }),
    previewed: () =>
        Analytics.event({
            object: 'Event',
            action: 'Previewed',
        }),
    published: () =>
        Analytics.event({
            object: 'Event',
            action: 'Published',
        }),
    unpublished: () =>
        Analytics.event({
            object: 'Event',
            action: 'Unpublished',
        }),
    deleted: () =>
        Analytics.event({
            object: 'Event',
            action: 'Deleted',
        }),
    cancelled: () =>
        Analytics.event({
            object: 'Event',
            action: 'Cancelled',
        }),
    copied: () =>
        Analytics.event({
            object: 'Event',
            action: 'Copied',
        }),
    viewed: () =>
        Analytics.event({
            object: 'Event',
            action: 'Viewed',
        }),
    layoutToggled: (selectedView) =>
        Analytics.event({
            object: 'Event',
            action: 'LayoutToggled',
            properties: {
                selectedView: {
                    type: 'label',
                    value: selectedView,
                },
            },
        }),
    search: (searchFieldInput, isCalendarView) =>
        Analytics.event({
            object: isCalendarView ? 'Calendar' : 'Event',
            action: 'Search',
            properties: {
                searchFieldInput: {
                    type: 'label',
                    value: { searchFieldInput },
                },
            },
        }),
};

export const EventSchedule = {
    created: ({ scheduleId }) =>
        Analytics.event({
            object: 'EventSchedule',
            action: 'Created',
            properties: {
                scheduleId: {
                    type: 'dimension',
                    value: scheduleId,
                },
            },
        }),
    managed: ({ eventId, activeScopeUserId }) =>
        Analytics.event({
            object: 'EventSchedule',
            action: 'Managed',
            properties: {
                eventId: {
                    type: 'dimension',
                    value: eventId,
                },
                activeScopeUserId: {
                    type: 'dimension',
                    value: activeScopeUserId,
                },
            },
        }),
    errored: ({ eventId, activeScopeUserId }) =>
        Analytics.event({
            object: 'EventSchedule',
            action: 'Errored',
            properties: {
                eventId: {
                    type: 'dimension',
                    value: eventId,
                },
                activeScopeUserId: {
                    type: 'dimension',
                    value: activeScopeUserId,
                },
            },
        }),
};

export const EventOccurrence = {
    deleted: ({ deleteType }) =>
        Analytics.event({
            object: 'EventOccurrence',
            action: 'Deleted',
            properties: {
                deleteType: {
                    type: 'label',
                    value: deleteType,
                },
            },
        }),
    rescheduled: ({ rescheduleType }) =>
        Analytics.event({
            object: 'EventOccurrence',
            action: 'Rescheduled',
            properties: {
                rescheduleType: {
                    type: 'label',
                    value: rescheduleType,
                },
            },
        }),
};

export const TicketClass = {
    created: () =>
        Analytics.event({
            object: 'TicketClass',
            action: 'Created',
        }),
    saved: () =>
        Analytics.event({
            object: 'TicketClass',
            action: 'Saved',
        }),
    deleted: () =>
        Analytics.event({
            object: 'TicketClass',
            action: 'Deleted',
        }),
};

export const OrderForm = {
    saved: () =>
        Analytics.event({
            object: 'OrderForm',
            action: 'Saved',
        }),
};

export const Question = {
    enabled: Analytics.event({
        object: 'Question',
        action: 'Enabled',
    }),
    disabled: Analytics.event({
        object: 'Question',
        action: 'Disabled',
    }),
};

export const CustomQuestion = {
    saved: () =>
        Analytics.event({
            object: 'CustomQuestion',
            action: 'Saved',
        }),
    deleted: () =>
        Analytics.event({
            object: 'CustomQuestion',
            action: 'Deleted',
        }),
};

export const Waitlist = {
    saved: () =>
        Analytics.event({
            object: 'Waitlist',
            action: 'Saved',
        }),
};

export const Facebook = {
    clicked: () =>
        Analytics.event({
            object: 'Facebook',
            action: 'Clicked',
        }),
};

export const FacebookTicketClass = {
    added: () =>
        Analytics.event({
            object: 'FacebookTicketClass',
            action: 'Added',
        }),
    removed: () =>
        Analytics.event({
            object: 'FacebookTicketClass',
            action: 'Removed',
        }),
};

export const APO = {
    saved: () =>
        Analytics.event({
            object: 'APO',
            action: 'Saved',
        }),
};

export const EventCalendar = {
    viewed: () =>
        Analytics.event({
            object: 'Calendar',
            action: 'Viewed',
        }),
    granularityToggled: (granularity) =>
        Analytics.event({
            object: 'Calendar',
            action: 'GranularityToggled',
            properties: {
                granularity: {
                    type: 'label',
                    value: granularity,
                },
            },
        }),
    todayClicked: () =>
        Analytics.event({
            object: 'Calendar',
            action: 'TodayClicked',
        }),
    paddleClicked: () =>
        Analytics.event({
            object: 'Calendar',
            action: 'PaddleClicked',
        }),
    datePickerViewed: () =>
        Analytics.event({
            object: 'Calendar',
            action: 'DatePickerViewed',
        }),
    datePaddleClicked: () =>
        Analytics.event({
            object: 'Calendar',
            action: 'DatePaddleClicked',
        }),
    eventClicked: ({ nameOfButton }) =>
        Analytics.event({
            object: 'Calendar',
            action: 'EventClicked',
            properties: {
                nameOfButton: {
                    type: 'dimension',
                    value: nameOfButton,
                },
            },
        }),
};

// TBD
export const EmailInvitations = {};
export const DiscountAndAccessCodes = {};
export const WebsiteIntegrations = {};
export const TrackingLinks = {};
export const TrackingPixels = {};
export const AffiliatePrograms = {};
