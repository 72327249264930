interface ARSValidationRule {
    minLength: number;
    maxLength: number;
}

enum ARSIdentificationMethod {
    DNI = 'DNI',
    CI = 'CI',
    LC = 'LC',
    LE = 'LE',
    Otro = 'Otro',
}

const IdentificationMethodsARSValidationRules: Record<
    ARSIdentificationMethod,
    ARSValidationRule
> = {
    DNI: {
        minLength: 7,
        maxLength: 8,
    },
    CI: {
        minLength: 1,
        maxLength: 9,
    },
    LC: {
        minLength: 6,
        maxLength: 7,
    },
    LE: {
        minLength: 6,
        maxLength: 7,
    },
    Otro: {
        minLength: 5,
        maxLength: 20,
    },
};

const IS_NON_NEGATIVE_INTEGER_REGEX = new RegExp('^\\d+$');

/**
 * Validator: Test that a value is a valid Argentinian identification number
 * [according to Mercadopago identification types](https://www.mercadopago.com.ar/developers/es/reference/identification_types/_identification_types/get)
 * @param value the identification number to validate
 * @param identificationMethod one of ['DNI', 'CI', 'LC', 'LE', 'Otro']
 * @returns is str a valid identification number.
 */
export const isValidIdentificationMethodARS = (
    value: string,
    identificationMethod: string,
): boolean => {
    if (!IS_NON_NEGATIVE_INTEGER_REGEX.test(value)) {
        return false;
    }

    const validationRules = (IdentificationMethodsARSValidationRules as Partial<
        Record<string, ARSValidationRule>
    >)[identificationMethod];

    if (!validationRules) {
        return false;
    }

    return (
        value.length >= validationRules.minLength &&
        value.length <= validationRules.maxLength
    );
};
