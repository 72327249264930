import React from 'react';
import { lazyGettext } from '@eb/lazy-gettext';
import PropTypes from 'prop-types';

import { StatusDotChunky } from '@eb/eds-iconography';

export const SOLD_OUT_STATUS = 'soldOut';

export const UNAVAILABLE_STATUS = 'unavailable';

export const SALES_ENDED_STATUS = 'salesEnded';

export const SALES_NOT_STARTED_STATUS = 'salesNotStarted';

export const SALES_START_AFTER_STATUS = 'salesStartAfter';

export const SALES_START_SOON_STATUS = 'salesStartSoon';

export const ON_SALE_STATUS = 'onSale';

export const SALES_END_SOON_STATUS = 'salesEndSoon';

/* These constants should point to the relevant prop key to pull the
   time information from the passed-in props. */
const SALES_START_PROP_KEY = 'salesStart';
const SALES_END_PROP_KEY = 'salesEnd';
const SALES_ENDED_PROP_KEY = 'salesEnded';

const SOLD_OUT = {
    iconType: <StatusDotChunky />,
    iconColor: 'ui-red',
    displayStatus: lazyGettext('Sold Out'),
};

const UNAVAILABLE = {
    iconType: <StatusDotChunky />,
    iconColor: 'ui-red',
    displayStatus: lazyGettext('On Sale'),
    displayText: lazyGettext('Unavailable'),
};

const SALES_ENDED = {
    iconType: <StatusDotChunky />,
    iconColor: 'grey-400',
    displayStatus: lazyGettext('Ended'),
    datePropKey: SALES_ENDED_PROP_KEY,
};

const SALES_NOT_STARTED = {
    iconType: <StatusDotChunky />,
    iconColor: 'tropical-yellow-400',
    displayStatus: lazyGettext('Scheduled'),
    displayText: lazyGettext('Starts on %(date)s'),
    datePropKey: SALES_START_PROP_KEY,
};

const SALES_START_AFTER = {
    iconType: <StatusDotChunky />,
    iconColor: 'tropical-yellow-400',
    displayStatus: lazyGettext('Scheduled'),
    displayText: lazyGettext('Sales not started'),
};

const SALES_START_SOON = {
    iconType: <StatusDotChunky />,
    iconColor: 'tropical-yellow-400',
    displayStatus: lazyGettext('Scheduled'),
    displayText: lazyGettext('Starts in %(date)s'),
    datePropKey: SALES_START_PROP_KEY,
};

const ON_SALE = {
    iconType: <StatusDotChunky />,
    iconColor: 'ui-green',
    displayStatus: lazyGettext('On Sale'),
    displayText: lazyGettext('Ends on %(date)s'),
    datePropKey: SALES_END_PROP_KEY,
};

const SALES_END_SOON = {
    iconType: <StatusDotChunky />,
    iconColor: 'ui-blue',
    displayStatus: lazyGettext('On Sale'),
    displayText: lazyGettext('Ends in %(date)s'),
    datePropKey: SALES_END_PROP_KEY,
};

export const STATUSES = [
    SOLD_OUT_STATUS,
    UNAVAILABLE_STATUS,
    SALES_ENDED_STATUS,
    SALES_NOT_STARTED_STATUS,
    SALES_START_AFTER_STATUS,
    SALES_START_SOON_STATUS,
    ON_SALE_STATUS,
    SALES_END_SOON_STATUS,
];

export const STATUSKEY_PROP_TYPE = PropTypes.oneOf(STATUSES);

export const TICKET_STATUS_MAP = {
    [SOLD_OUT_STATUS]: SOLD_OUT,
    [UNAVAILABLE_STATUS]: UNAVAILABLE,
    [SALES_ENDED_STATUS]: SALES_ENDED,
    [SALES_NOT_STARTED_STATUS]: SALES_NOT_STARTED,
    [SALES_START_AFTER_STATUS]: SALES_START_AFTER,
    [SALES_START_SOON_STATUS]: SALES_START_SOON,
    [ON_SALE_STATUS]: ON_SALE,
    [SALES_END_SOON_STATUS]: SALES_END_SOON,
};
