import { CheckoutState } from '../state';
import { getVirtualIncentives } from './user';
import { VirtualIncentive } from '../models/user';
import { getIsPayingWithVirtualIncentives } from './payment';

export const getSelectedVirtualIncentives = (
    state: CheckoutState,
): Array<VirtualIncentive> => {
    const payWithVirtualIncentives = getIsPayingWithVirtualIncentives(state);
    if (!payWithVirtualIncentives) return [];

    const virtualIncentives = getVirtualIncentives(state);
    return virtualIncentives;
};
