import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import includes from 'lodash/includes';

import { Checkbox } from '@eb/eds-checkbox';

import './checkboxGroup.scss';

/**
 * Given a set of checked values filter out the ones that are not in the full
 * list of values
 */
const _getValuesCheckedStatus = (checkedList = [], allValues = []) => {
    // First create a lookup of the values so the function's complexity isn't
    // O(n^2) operation, but O(2n)
    const checkedListLookup = checkedList.reduce(
        (prevLookup, value) => ({
            ...prevLookup,
            [value]: true,
        }),
        {},
    );

    // Then go through each value and set its checked status based on its
    // presence in checkedListLookup
    const allValuesCheckedStatus = _.chain(allValues)
        // first convert the array of valueInfo objects to an array where first
        // item is the value string and the second item is whether or not the
        // value string is in the lookup
        .map(({ value }) => [value, value in checkedListLookup])

        // convert that list of [valueString, checked] pairs into an object lookup
        // where the key is the valueString and the value is the checked status
        .fromPairs()

        // get back a raw object
        .value();

    return allValuesCheckedStatus;
};

/*
 * Converts an object lookup of values checked statuses to an array of the values
 * that are checked
 */
const _getCheckedList = (valuesCheckedStatus) =>
    _.chain(valuesCheckedStatus)
        // convert the lookup object into an array of [value, isChecked] pairs
        .toPairs()

        // filter down to only the pairs that are checked
        .filter(([, isChecked]) => isChecked)

        // transform from [value, isChecked] pairs to value strings
        .map(([value]) => value)

        // get out the value string
        .value();

const CheckboxComponent = ({
    index,
    name,
    value,
    htmlValue,
    display,
    isDisabled,
    numberOfColumns,
    onChange,
    onBlur,
    onFocus,
}) => {
    const id = `checkbox-${name}-${index}`;
    const wrapperClassName =
        numberOfColumns > 1
            ? `eds-g-cell eds-g-cell-${12 / numberOfColumns}-12 eds-l-mar-bot-4`
            : 'eds-l-mar-vert-4';

    return (
        <div data-spec="checkbox-wrapper" className={wrapperClassName}>
            <Checkbox
                id={id}
                name={name}
                value={value}
                htmlValue={htmlValue}
                isDisabled={isDisabled}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                data-spec="checkbox-group"
            >
                {display}
            </Checkbox>
        </div>
    );
};

export default class CheckboxGroup extends PureComponent {
    static propTypes = {
        /**
         * Checkbox group name
         */
        name: PropTypes.string.isRequired,
        /**
         * Array of formatted checkbox group like data options
         * (value: The checkbox-group value & display: The checkbox-group displayed label)
         */
        values: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.string.isRequired,
                display: PropTypes.node.isRequired,
            }),
        ).isRequired,
        /**
         * The checked checkboxes in the checkbox group, overriding any existing state.
         * Named "value" for parity with the other input components.
         *
         * In most cases, `value` should be an array of strings, where each string
         * represents a checked checkbox id. However, we also allow `value` to be
         * specifically the empty string to prevent a console warning when this
         * component is used with redux-form, as redux-form passes an empty string
         * rather than an empty list, the very first time a checkbox group is clicked.
         * See the Phabricator diffs linked in EB-57544 and EB-61970 for more context.
         * We then convert the empty string to an empty list in the relevant helper
         * functions.
         */
        value: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.string),
            PropTypes.oneOf(['']),
        ]),
        /**
         * The initially checked checkboxes in the checkbox group.
         * It will not override the state after it's been created.
         * Named "defaultValue" for parity with the other input components.
         */
        defaultValue: PropTypes.arrayOf(PropTypes.string),
        /**
         * Display the checkbox group list inline
         */
        inline: PropTypes.bool,
        /**
         * ID of checkbox group component's wrapper
         */
        id: PropTypes.string,
        /**
         * List of values for disabled checkbox group buttons
         */
        disabledList: PropTypes.arrayOf(PropTypes.string),
        /**
         * Number of columns for the checkboxes to be displayed in
         * Test number of columns: 1, 2, 3, 4
         */
        numberOfColumns: PropTypes.oneOf([1, 2, 3, 4]),
        /**
         * Function to be invoked when the set of checked checkboxes changes.
         * Passed an array of checked values and an object with 'isChecked'
         * and 'value'
         */
        onChange: PropTypes.func,
        /**
         * Function To be invoked when a checkbox in the group is blurred.
         * Passed an array of checked values
         */
        onBlur: PropTypes.func,
        /**
         * Function to be invoked when a checkbox in the group is focused.
         * Passed an array of checked values
         */
        onFocus: PropTypes.func,
        /**
         * Class names for checkbox group component wrapper
         */
        __containerClassName: PropTypes.string,
        'data-spec': PropTypes.string,
    };

    static defaultProps = {
        defaultValue: [],
        inline: false,
        numberOfColumns: 1,
        'data-spec': 'checkbox-group-wrapper',
    };

    constructor(props) {
        super(props);

        const { values, value, defaultValue } = props;
        const checkedList = value || defaultValue;

        this.state = {
            // Maintaining the currently checked values as an object lookup where
            // the key is the checkbox value and the value is whether it's checked.
            // This makes it easier to update checked status of checkboxes as
            // they change over time
            valuesCheckedStatus: _getValuesCheckedStatus(checkedList, values),
        };
    }

    componentWillReceiveProps({ value, values }) {
        // We explicitly test for undefined for the case when the value is
        // specifically not passed
        if (value !== undefined) {
            // The first time any checkbox in the group is checked, redux-form passes
            // an empty string instead of an empty list. However, _getValuesCheckedStatus
            // always expects a list as the first param.
            const valueList = value === '' ? [] : value;

            this.setState({
                valuesCheckedStatus: _getValuesCheckedStatus(valueList, values),
            });
        }
    }

    _handleCheckboxChange(checkboxIsChecked, checkboxValue) {
        // update the checkbox's checked state in the overall values checked
        // state
        this.setState(
            ({ valuesCheckedStatus: prevValuesCheckedStatus }) => ({
                valuesCheckedStatus: {
                    ...prevValuesCheckedStatus,
                    [checkboxValue]: checkboxIsChecked,
                },
            }),
            () => {
                if (this.props.onChange) {
                    // Need to convert from lookup to list of checked values
                    const checkedList = _getCheckedList(
                        this.state.valuesCheckedStatus,
                    );

                    this.props.onChange(checkedList, {
                        isChecked: checkboxIsChecked,
                        value: checkboxValue,
                    });
                }
            },
        );
    }

    _handleCheckboxBlur() {
        if (this.props.onBlur) {
            // Need to convert from lookup to list of checked values
            const checkedList = _getCheckedList(this.state.valuesCheckedStatus);

            this.props.onBlur(checkedList);
        }
    }

    _handleCheckboxFocus() {
        if (this.props.onFocus) {
            // Need to convert from lookup to list of checked values
            const checkedList = _getCheckedList(this.state.valuesCheckedStatus);

            this.props.onFocus(checkedList);
        }
    }

    _getCheckboxes() {
        const { name, values, disabledList, numberOfColumns } = this.props;
        const { valuesCheckedStatus } = this.state;

        const checkboxComponents = values.map((valueInfo, index) => {
            const isDisabled = includes(disabledList, valueInfo.value);
            const isChecked = valuesCheckedStatus[valueInfo.value];

            return (
                <CheckboxComponent
                    key={valueInfo.value}
                    index={index}
                    name={name}
                    value={isChecked}
                    htmlValue={valueInfo.value}
                    display={valueInfo.display}
                    isDisabled={isDisabled}
                    numberOfColumns={numberOfColumns}
                    onChange={this._handleCheckboxChange.bind(this)}
                    onBlur={this._handleCheckboxBlur.bind(this)}
                    onFocus={this._handleCheckboxFocus.bind(this)}
                />
            );
        });

        return checkboxComponents;
    }

    render() {
        const {
            id,
            inline,
            'data-spec': dataSpec,
            __containerClassName,
        } = this.props;

        const wrapperClassName = classNames(
            'eds-checkbox-group',
            {
                'eds-checkbox-group--inline': inline,
            },
            __containerClassName,
        );

        return (
            <div id={id} className={wrapperClassName} data-spec={dataSpec}>
                {this._getCheckboxes()}
            </div>
        );
    }
}
