import * as React from 'react';

const SepaPaymentSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 48 32" {...props}>
        <path
            fill="#386DA4"
            d="M45 32H3c-1.7 0-3-1.3-3-3V3c0-1.7 1.3-3 3-3h42c1.7 0 3 1.3 3 3v26c0 1.7-1.3 3-3 3z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFF"
            d="M38.2 19.2h-3.4l-.5 1.6h-2.6l2.8-9.4h3.8l2.9 9.4h-2.6l-.4-1.6zm-.5-1.8l-1.2-4.1-1.2 4.1h2.4zm-13.5 3.4v-9.4h4.7c.6 0 1.1.1 1.5.2.8.2 1.3.7 1.6 1.5.1.4.2 1 .2 1.8 0 1-.1 1.6-.2 2.1-.3.8-.9 1.3-1.9 1.4h-3.3v2.4h-2.6zm2.5-4.6h1.6c.5 0 .8-.1.9-.1.1-.1.3-.2.3-.5v-.7c0-.4 0-.7-.1-.9-.1-.3-.3-.4-.7-.5h-2.1v2.7zm-12.6-1.8v-.5c0-1.3-.5-2-1.5-2.3-.4-.1-1.1-.2-2.1-.2-.6 0-1.2 0-1.7.1-.6 0-1.1.2-1.5.5-.3.2-.5.5-.6.8-.1.4-.2.8-.2 1.4 0 .8.1 1.4.3 1.7.3.5.8.7 1.5.9.3.1.8.1 1.6.2.9.1 1.5.1 1.6.1.3.1.5.3.5.8 0 .2 0 .3-.1.5s-.2.3-.4.4c-.1 0-.4.1-.8.1h-.9c-.2 0-.4 0-.6-.1-.2-.1-.4-.4-.4-.8v-.2H6.5c0 .8.1 1.3.2 1.6.2.6.6 1 1.3 1.2.5.2 1.4.2 2.8.2.8 0 1.4 0 1.8-.1.9-.2 1.5-.6 1.8-1.2.1-.4.2-.9.2-1.7v-.9c-.1-.4-.2-.8-.5-1.1-.4-.3-.9-.6-1.8-.6-.3 0-.9-.1-1.9-.1h-.5c-.2 0-.4-.1-.5-.1-.3-.2-.4-.5-.4-.8 0-.4.1-.6.3-.7.2-.1.6-.2 1.2-.2s1 .1 1.2.3c.1.1.1.4.1.8h2.3"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#F6C035"
            d="M21.6 12.6c1 0 2 .5 2.6 1.2l.5-1.2c-.9-.8-2-1.3-3.3-1.3-2 0-3.7 1.2-4.4 2.9h-1l-.6 1.3h1.4v.9H16l-.6 1.3h1.7c.7 1.7 2.4 2.8 4.4 2.8 1 0 1.9-.3 2.7-.8V18c-.6.7-1.5 1.1-2.5 1.1-1.2 0-2.2-.6-2.8-1.5h3.5l.6-1.3h-4.6v-.8h5l.6-1.3h-5.2c.5-.9 1.6-1.6 2.8-1.6"
        />
    </svg>
);

SepaPaymentSvg.displayName = 'SepaPaymentSvg';
export default SepaPaymentSvg;
