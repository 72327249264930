import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { translationPropType } from '@eb/i18n';
import gettext from '@eb/gettext';
import isEmpty from 'lodash/isEmpty';

import { ITEMS_PROP_TYPE, BREAKDOWN_ITEMS_PROP_TYPE } from './constants';

import { Divider } from '@eb/eds-divider';
import { Button } from '@eb/eds-button';
import { ExpansionPanel } from '@eb/eds-expansion-panel';
import { Icon } from '@eb/eds-icon';
import { withTooltip } from '@eb/eds-tooltip';
import { InfoChunky } from '@eb/eds-iconography';

import './orderSummary.scss';

const TitleContainer = ({ title, actionText, onClickActionText }) => {
    let titleContainer = null;
    let clickableText = null;

    if (actionText && onClickActionText) {
        clickableText = (
            <div
                className="eds-g-cell eds-g-cell-6-12 eds-text--right eds-text-bs--fixed eds-text-color--ui-blue"
                role="gridcell"
            >
                <Button style="none" onClick={onClickActionText}>
                    {actionText}
                </Button>
            </div>
        );
    }

    if (title) {
        titleContainer = (
            <div>
                <div className="eds-l-mar-bot-6">
                    <div
                        className="eds-order-summary-title eds-g-cell eds-g-cell-12-12 eds-text-bm--fixed eds-text-color--grey-900"
                        role="gridcell"
                    >
                        {title}
                    </div>
                    {clickableText}
                </div>
            </div>
        );
    }

    return titleContainer;
};

const ItemsContainer = ({ items }) => {
    let itemsContainer = null;

    if (items) {
        const rows = items.map(
            ({ content, subcontent, value, quantity, color, id, donation }) => {
                const className = classNames(
                    'eds-g-group',
                    'eds-l-mar-vert-4',
                    `eds-text-color--${color || 'grey-700'}`,
                );

                let itemText;

                if (donation) {
                    itemText = content;
                } else {
                    // Translators: This is the number of items times the item name. Example: 8 x Free Ticket or 2 x Teddy Bears
                    itemText = gettext('%(quantity)s x %(content)s', {
                        quantity,
                        content,
                    });
                }

                let subcontentContainer;

                if (subcontent) {
                    subcontentContainer = (
                        <p className="eds-text-color--grey-600 eds-text-bs--fixed eds-l-mar-top-1">
                            {subcontent}
                        </p>
                    );
                }
                return (
                    <div className={className} key={id} role="row">
                        <div
                            className="eds-g-cell eds-g-cell-6-12"
                            role="gridcell"
                        >
                            <span className="eds-text-bm--fixed eds-text-color--grey-800">
                                {itemText}
                            </span>
                            {subcontentContainer}
                        </div>
                        <div
                            className="eds-g-cell eds-g-cell-6-12 eds-text--right"
                            role="gridcell"
                        >
                            <span className="eds-text-bm--fixed eds-text-color--grey-800">
                                {value}
                            </span>
                        </div>
                    </div>
                );
            },
        );

        itemsContainer = <div>{rows}</div>;
    }

    return itemsContainer;
};

const BreakdownItemsContainer = ({ breakdownItems }) => {
    let breakdownItemsContainer = null;

    if (breakdownItems) {
        const rows = breakdownItems.map(
            (
                {
                    content,
                    subcontent,
                    value,
                    color,
                    id,
                    expansionContent,
                    infoTooltipContent,
                    valueAdditionalClassNames,
                },
                index,
            ) => {
                const isFirstItem = index === 0;
                const className = classNames(
                    'eds-g-group',
                    `eds-text-color--${color || 'grey-600'}`,
                    {
                        'eds-l-mar-top-4': isFirstItem,
                        'eds-l-mar-top-3': !isFirstItem,
                    },
                );

                let subcontentContainer;
                let infoTooltipContainer;

                if (subcontent) {
                    subcontentContainer = (
                        <p className="eds-text-color--grey-600 eds-text-bs--fixed">
                            {subcontent}
                        </p>
                    );
                }
                if (expansionContent) {
                    const expandedContent = expansionContent.map((item) => (
                        <div
                            className="eds-l-mar-bot-1"
                            key={item.id}
                            role="row"
                        >
                            <div
                                className="eds-g-cell eds-g-cell-6-12"
                                role="gridcell"
                            >
                                <span className="eds-text-bm eds-text-color--grey-600">
                                    {item.content}
                                </span>
                            </div>
                            <div
                                className="eds-g-cell eds-g-cell-6-12 eds-text--right"
                                role="gridcell"
                            >
                                <span className="eds-text-bm eds-text-color--grey-600">
                                    {item.value}
                                </span>
                            </div>
                        </div>
                    ));

                    return (
                        <div key={id}>
                            <ExpansionPanel
                                linkText={content}
                                verticalMargin="both"
                                style="order-summary"
                                suffix={value}
                            >
                                {expandedContent}
                            </ExpansionPanel>
                        </div>
                    );
                }
                if (infoTooltipContent) {
                    const IconWithTooltip = withTooltip(Icon);

                    infoTooltipContainer = (
                        <IconWithTooltip
                            type={<InfoChunky />}
                            size="xsmall"
                            tooltipId="tooltip-info"
                            tooltipStyle="basic"
                            tooltipText={infoTooltipContent}
                            tooltipArrowLocation="left"
                            wrapperComponent="span"
                        />
                    );
                }

                const valueTextClasses = classNames('eds-text-bm--fixed', {
                    [valueAdditionalClassNames]: valueAdditionalClassNames,
                });

                return (
                    <div className={className} key={id} role="row">
                        <div
                            className="eds-g-cell eds-g-cell-6-12"
                            role="gridcell"
                        >
                            <span className="eds-text-bm--fixed">
                                {content}
                                {infoTooltipContainer}
                            </span>
                            {subcontentContainer}
                        </div>
                        <div
                            className="eds-g-cell eds-g-cell-6-12 eds-text--right"
                            role="gridcell"
                        >
                            <span className={valueTextClasses}>{value}</span>
                        </div>
                    </div>
                );
            },
        );

        let breakdownDivider = null;

        if (!isEmpty(rows)) {
            breakdownDivider = (
                <div className="eds-l-mar-top-4">
                    <Divider />
                </div>
            );
        }

        breakdownItemsContainer = (
            <div>
                {rows}
                {breakdownDivider}
            </div>
        );
    }

    return breakdownItemsContainer;
};

const TotalContainer = ({ content, value, currency, footer }) => {
    let totalContainer = null;
    let footerContainer = null;

    if (footer) {
        footerContainer = (
            <div
                className="eds-g-cell eds-g-cell-12-12 eds-text--right"
                role="gridcell"
            >
                <span className="eds-text-bs--fixed eds-text-color--ui-500">
                    {footer}
                </span>
            </div>
        );
    }

    if (content && value) {
        totalContainer = (
            <div className="eds-g-group eds-l-mar-vert-4" role="row">
                <div className="eds-g-cell eds-g-cell-6-12" role="gridcell">
                    <span className="eds-text-weight--heavy eds-text-bl--fixed eds-text-color--ui-800">
                        {content}
                    </span>
                </div>
                <div
                    className="eds-g-cell eds-g-cell-6-12 eds-text--right"
                    role="gridcell"
                >
                    <span className="eds-text-bm--fixed">
                        <span className="eds-text--uppercase eds-text-color--ui-600">
                            {currency}
                        </span>
                        &nbsp;
                        <span className="eds-text-weight--heavy eds-text-bl--fixed eds-text-color--ui-800">
                            {value}
                        </span>
                    </span>
                </div>
                {footerContainer}
            </div>
        );
    }

    return totalContainer;
};

const MessageContainer = ({ messageContent }) => {
    let messageContainer = null;

    if (messageContent) {
        messageContainer = (
            <div
                className="eds-g-cell eds-l-mar-top-3 eds-g-cell-12-12 eds-text--left"
                role="gridcell"
            >
                <span className="eds-text-bs--fixed eds-text-color--grey-800">
                    {messageContent}
                </span>
            </div>
        );
    }

    return messageContainer;
};

export default class OrderSummary extends PureComponent {
    static propTypes = {
        /**
         * Array of items (such as ticket types)
         */
        items: ITEMS_PROP_TYPE.isRequired,
        /**
         * Array of items in Breakdown (such as subtotal, taxes, fees)
         */
        breakdownItems: BREAKDOWN_ITEMS_PROP_TYPE.isRequired,
        /**
         * Name of the Total
         */
        totalContent: translationPropType.isRequired,
        /**
         * Total amount or value
         */
        totalValue: PropTypes.string.isRequired,
        /**
         * Currency type
         */
        currency: PropTypes.string,
        /**
         * Title of the Order Summary
         */
        title: PropTypes.node,
        /**
         * Title of the Breakdown portion of the Order Summary
         */
        breakdownTitle: PropTypes.node,
        /**
         * Message content under Total
         */
        messageContent: PropTypes.string,
        /**
         * Text for top right clickable text
         */
        actionText: PropTypes.node,
        /**
         * Text for bottom right information of the total cost
         */
        totalCostInfo: translationPropType,
        /**
         * CTA callback for clickable actionText
         */
        onClickActionText: PropTypes.func,
    };

    render() {
        const {
            items,
            breakdownItems,
            currency,
            totalContent,
            totalValue,
            title,
            breakdownTitle,
            messageContent,
            actionText,
            totalCostInfo,
            onClickActionText,
        } = this.props;

        let breakdownTitleContainer;

        if (breakdownTitle) {
            breakdownTitleContainer = (
                <div className="eds-order-summary-breakdown-title eds-l-mar-top-6 eds-l-mar-bot-2 eds-text-bm--fixed">
                    {breakdownTitle}
                </div>
            );
        }

        return (
            <div className="eds-order-summary eds-text-bs--fixed" role="grid">
                <TitleContainer
                    title={title}
                    actionText={actionText}
                    onClickActionText={onClickActionText}
                />
                <ItemsContainer items={items} />
                {breakdownTitleContainer}
                <Divider />
                <BreakdownItemsContainer breakdownItems={breakdownItems} />
                <TotalContainer
                    content={totalContent}
                    value={totalValue}
                    currency={currency}
                    footer={totalCostInfo}
                />
                <MessageContainer messageContent={messageContent} />
            </div>
        );
    }
}
