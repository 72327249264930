import React from 'react';

import { ArrowLeftChunky } from '@eb/eds-iconography';

import { BACK_TO_SERIES_EVENTS_TITLE } from '../../constants';
import { getTotalCostDisplay } from '../../selectors/order';
import { getTicketsProps } from '../../selectors/tickets';
import {
    isFormSubmitting,
    isFormSuccessfullySubmitted,
} from '../../utils/forms';
import { GROUP_REG_JOIN_TERMINOLOGY } from '../groupRegistration/terminology';

// TODO: Move to selectors/app.js
export const getLocaleProps = ({ app }) => ({
    locales: app.locales,
    defaultLocale: app.locale,
});

// TODO: Move to selectors/event.js
export const getEventSalesStatus = ({ event }) => ({
    eventSalesStatus: event.eventSalesStatus,
});

// TODO: Move to selectors/app.js
export const getFeatureFlags = ({ app }) => ({
    featureFlags: app.featureFlags,
});

export const getTemplateProps = ({
    state,
    formName,
    disableOnSucceeded = true,
}) => {
    const { totalTicketQuantity } = getTicketsProps(state);
    const totalCostDisplay = getTotalCostDisplay(state);
    const { event } = state;
    const isSubmitting = isFormSubmitting(state, formName);
    const hasSucceeded = isFormSuccessfullySubmitted(state, formName);

    return {
        isReservedSeating: event.isReservedSeating,
        totalTicketQuantity,
        totalCostDisplay,
        isSubmitting: isSubmitting || (disableOnSucceeded && hasSucceeded),
    };
};

export const getTemplateHeaderProps = ({
    groupType,
    shouldShowBackToSeriesLink,
    onBackToSeries,
    onBackToGroupTypeSelection,
    hasAdvancedTeamsEnabled,
}) => {
    if (shouldShowBackToSeriesLink) {
        return {
            headerActionIconType: <ArrowLeftChunky />,
            headerActionTitle: BACK_TO_SERIES_EVENTS_TITLE,
            onHeaderAction: onBackToSeries,
        };
    }

    if (hasAdvancedTeamsEnabled) {
        return {
            headerActionIconType: <ArrowLeftChunky />,
            headerActionTitle:
                GROUP_REG_JOIN_TERMINOLOGY[groupType].headerActionTitle,
            onHeaderAction: onBackToGroupTypeSelection,
        };
    }

    return false;
};

export const TEMPLATE_PROPS_KEYS = [
    'isReservedSeating',
    'totalTicketQuality',
    'totalCostDisplay',
    'isSubmitting',
];
