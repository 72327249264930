import * as React from 'react';

const VerticalDotsChunkySvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 24 24" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 18c0-1.1.9-2 2-2s2 .9 2 2-.9 2-2 2-2-.9-2-2z"
        />
        <circle fillRule="evenodd" clipRule="evenodd" cx={12} cy={12} r={2} />
        <circle fillRule="evenodd" clipRule="evenodd" cx={12} cy={6} r={2} />
    </svg>
);

VerticalDotsChunkySvg.displayName = 'VerticalDotsChunkySvg';
export default VerticalDotsChunkySvg;
