import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import includes from 'lodash/includes';
import { translationPropType } from '@eb/i18n';
import { getInitialValue } from '@eb/eds-inputs';

import './radio.scss';

const getAriaLabel = (display, value) => {
    let label = null;

    if (!display) {
        label = value;
    }

    return label;
};

const getAriaLabelledBy = (title, id) => {
    let label = null;

    if (title) {
        label = id;
    }

    return label;
};

const RadioGroupTitle = ({ id, title }) => {
    let component = null;

    if (title) {
        component = (
            <p className="eds-text-bl" id={id}>
                {title}
            </p>
        );
    }

    return component;
};

const getId = (name, index) => `radio-${name}-${index}`;

const DisplayLabel = ({ display, name, index }) => {
    let component = null;

    if (display) {
        component = (
            <label
                className="eds-radio__label eds-radio__display-label"
                htmlFor={getId(name, index)}
                data-spec="radio-display-label"
                data-automation={`${getId(name, index)}-label`}
            >
                {display}
            </label>
        );
    }

    return component;
};

/**
 * Helper component for radio options
 * @prop {string} name - the name of the radio group
 * @prop {string} value - the value of the field if this option is checked
 * @prop {string} display - the text of option label displayed to user
 * @prop {boolean} inline - option to display the radio buttons inline
 * @prop {boolean} checked - radio input checked if true, false by default
 * @prop {boolean} disabled - radio input disabled if true, false by default
 * @prop {boolean} reversed - reverses display order to put the input on the right side
 * @prop {function} onChange - function to be invoked when radio button value changes
 * @example
 * // <RadioOption name="radio_group" value="option_01" display="Option 01" checked=true disabled=false />
 */
const RadioOption = ({
    index,
    name,
    value,
    display,
    checked,
    disabled,
    reversed,
    onChange,
    onBlur,
    onFocus,
}) => (
    <div
        className={classNames('eds-radio__option', {
            'eds-radio__option--reversed': reversed,
        })}
        data-spec="radio-option-container"
    >
        <input
            type="radio"
            id={getId(name, index)}
            className="eds-radio__input"
            name={name}
            value={value}
            checked={checked}
            disabled={disabled}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            data-spec="radio-button"
            data-automation={getId(name, index)}
            aria-checked={checked}
            aria-label={getAriaLabel(display, value)}
        />
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label
            className="eds-radio__label eds-radio__delegate"
            htmlFor={getId(name, index)}
            data-spec="radio-delegate"
        />
        <DisplayLabel display={display} name={name} index={index} />
    </div>
);

export default class Radio extends React.PureComponent {
    static propTypes = {
        /**
         * Radio group name of the radio component
         */
        name: PropTypes.string.isRequired,
        /**
         * Array of formatted radio like data options
         * (value: The radio value & display: The radio displayed label)
         */
        values: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.string.isRequired,
                display: PropTypes.node.isRequired,
            }),
        ).isRequired,
        /**
         * Title of the radio group (used as the radio group label)
         */
        title: translationPropType,
        /**
         * Current chosen radio button in the radio list
         */
        value: PropTypes.string,
        /**
         * Display the radio list inline
         */
        inline: PropTypes.bool,
        /**
         * Display the radio option in reverse order, this is done via flex-direction which does not change DOM order
         * for more details see: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Grid_Layout/CSS_Grid_Layout_and_Accessibility
         */
        radioOptionReversed: PropTypes.bool,
        /**
         * Render prop used to customize the style of each option
         */
        renderRadioOptionContainer: PropTypes.func,
        /**
         * ID of radio component's wrapper
         */
        id: PropTypes.string,
        /**
         * The value from values prop whose radio button should be checked by default
         */
        defaultValue: PropTypes.string,
        /**
         * List of values for disabled radio buttons
         */
        disabledList: PropTypes.arrayOf(PropTypes.string),
        /**
         * Class names for radio component wrapper
         */
        __containerClassName: PropTypes.string,
        /**
         * Function to be invoked when radio value changes
         */
        onChange: PropTypes.func,
        /**
         * Function To be invoked when radio button is blurred
         */
        onBlur: PropTypes.func,
        /**
         * Function to be invoked when radio button is blurred
         */
        onFocus: PropTypes.func,
        /**
         * Identifier for tests
         */
        'data-spec': PropTypes.string,
    };

    static defaultProps = {
        inline: false,
        radioOptionReversed: false,
        'data-spec': 'radio-wrapper',
    };

    constructor(props) {
        super(props);

        const { values, value, defaultValue } = props;

        let initialValue;

        if (value || defaultValue) {
            initialValue = getInitialValue(value || defaultValue, values);
        }
        this.state = {
            value: initialValue,
            active: false,
        };
    }

    componentWillReceiveProps({ value, values }) {
        const isValueChanged = this.props.value !== value;

        if (!isValueChanged) {
            return;
        }

        // Allows checking an existing radio button via props from a parent
        if (value) {
            this.setState({
                value: getInitialValue(value, values),
            });
        } else {
            this.setState({
                value,
            });
        }
    }

    _handleChange(e) {
        const value = e.target.value;

        this.setState({ value });

        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }

    _handleBlur() {
        this.setState({ active: false });

        if (this.props.onBlur) {
            this.props.onBlur(this.state.value);
        }
    }

    _handleFocus() {
        this.setState({ active: true });

        if (this.props.onFocus) {
            this.props.onFocus(this.state.value);
        }
    }

    /**
     * Build up the list of optionComponents and determine the checked
     * button in one iteration. We're determining this derived value here
     * instead of storing it in state.
     */
    _getOptionComponents() {
        const {
            name,
            values,
            disabledList,
            radioOptionReversed,
            renderRadioOptionContainer,
        } = this.props;
        const { value } = this.state;
        const optionComponents = values.map((valueInfo, index) => {
            const checked = valueInfo.value === value;
            const disabled = includes(disabledList, valueInfo.value);
            const radioOption = (
                <RadioOption
                    index={index}
                    name={name}
                    value={valueInfo.value}
                    display={valueInfo.display}
                    checked={checked}
                    disabled={disabled}
                    onChange={this._handleChange.bind(this)}
                    onBlur={this._handleBlur.bind(this)}
                    onFocus={this._handleFocus.bind(this)}
                    reversed={radioOptionReversed}
                    key={valueInfo.value}
                />
            );

            if (renderRadioOptionContainer) {
                return renderRadioOptionContainer(radioOption, valueInfo.value);
            }

            return radioOption;
        });

        return optionComponents;
    }

    render() {
        const {
            id,
            inline,
            'data-spec': dataSpec,
            __containerClassName,
            title,
        } = this.props;
        const { active } = this.state;
        const wrapperClassName = classNames(
            'eds-radio',
            {
                'eds-radio--active': active,
                'eds-radio--inline': inline,
            },
            __containerClassName,
        );

        return (
            <div>
                <RadioGroupTitle id={id} title={title} />
                <div
                    className={wrapperClassName}
                    data-spec={dataSpec}
                    role="radiogroup"
                    aria-labelledby={getAriaLabelledBy(title, id)}
                >
                    {this._getOptionComponents()}
                </div>
            </div>
        );
    }
}
