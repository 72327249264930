import React from 'react';
import PropTypes from 'prop-types';
import { translationPropType } from '@eb/i18n';

import { CLEAR_LABEL } from '../../constants';
import * as constants from './constants';
import { formValidate } from '@eb/eds-utils';

import {
    PromoCodeCtaButton,
    PromoCodeCtaText,
    PromoCodeInputField,
} from './helpers';
import { getCtaText, getStatus } from './utils';

import './promoCodeForm.scss';

class PromoCodeForm extends React.PureComponent {
    static propTypes = {
        ...constants.REDUX_FORM_PROP_TYPES,
        ...constants.FORM_PROP_TYPE,

        /**
         * The page rendering the PromoCodeForm; used for tracking
         */
        checkoutStep: PropTypes.string,

        /**
         * Text to display as the form label
         */
        inputLabel: translationPropType,
    };

    static defaultProps = {
        inputLabel: constants.INPUT_LABEL,
    };

    state = {
        query: '',
    };

    componentWillReceiveProps(nextProps) {
        const status = getStatus(nextProps);
        const { promoCode: query } = nextProps.options;
        const { SUCCESS } = constants.FORM_STATUS;

        if (status === SUCCESS) {
            this.setState({ query });
        }
    }

    _handleClear() {
        const { onClear } = this.props.handlers;
        const checkoutStep = this.props.checkoutStep;
        const promoCode = this.state.query;

        this.setState({ query: '' });

        onClear(promoCode, CLEAR_LABEL, checkoutStep);
    }

    _handleChange(query) {
        const { onChange } = this.props.handlers;

        this.setState({ query });

        onChange();
    }

    render() {
        const { handleSubmit, submitFailed, options, inputLabel } = this.props;
        const {
            disablePromoCodeAutoFocus,
            isLoading,
            shouldShowInternalCta,
            shouldShowExternalCta,
        } = options;
        const { query } = this.state;
        const { EMPTY, FAILURE, SUCCESS, DEFAULT } = constants.FORM_STATUS;

        const clickHandlers = {
            [EMPTY]: null,
            [SUCCESS]: null,
            [FAILURE]: this._handleClear.bind(this),
            [DEFAULT]: handleSubmit,
        };
        const status = getStatus(this.props);
        const isCodeSuccessful = status === SUCCESS;
        const isFieldEmpty = status === EMPTY;
        const _handleCtaClick = clickHandlers[status];
        let internalCta = null;
        let externalCta = null;

        if (shouldShowInternalCta) {
            internalCta = (
                <PromoCodeCtaText
                    isLoading={isLoading}
                    isDisabled={isFieldEmpty}
                    ctaText={getCtaText(status)}
                    onClick={_handleCtaClick}
                />
            );
        }

        if (shouldShowExternalCta) {
            externalCta = (
                <PromoCodeCtaButton
                    isDisabled={isFieldEmpty}
                    onClick={handleSubmit}
                />
            );
        }

        let promoCodeInputField = (
            <PromoCodeInputField
                submitFailed={submitFailed}
                handleSubmit={handleSubmit}
                query={query}
                cta={internalCta}
                onChange={this._handleChange.bind(this)}
                inputLabel={inputLabel}
                inputPlaceholder={constants.INPUT_PLACEHOLDER}
                disablePromoCodeAutoFocus={disablePromoCodeAutoFocus}
            />
        );

        // NOTE: Currently, the displays after the success application of promo
        // code is managed by the parent pages. The TicketSelectionPage shows a
        // NotificationBar with success message, while the UnlockTicketsPage
        // redirects to the TicketSelectionPage. As a result, we are hiding the PromoCodeForm
        // when the code is successfully applied.
        if (isCodeSuccessful) {
            promoCodeInputField = null;
        }

        return (
            <div>
                {promoCodeInputField}
                {externalCta}
            </div>
        );
    }
}

export default formValidate({
    touchOnBlur: false,
})(PromoCodeForm);
