import { Dispatch } from 'redux';

import { replace } from 'react-router-redux';

import { joinPath } from '@eb/path-utils';
import { moment } from '@eb/date';

import { BASE_URL, STATUS_PATH } from '../constants';
import {
    TIME_EXPIRED_STATUS,
    TIME_EXPIRED_STATUS_NO_BACK_BUTTON,
} from '../containers/status/constants';
import { hasExistingOrder } from '../selectors/order';
import { CheckoutState } from '../state';
import logger from '@eb/logger-bugsnag';

/**
 * Called when the checkout order timer is set up or reset. This happens when the order is
 * created (going to the checkout page) and when the order's timeRemaining is changed (when
 * an external payment is started, such as iDEAL). The state of the timer is stored so that
 * it will be the same between different containers.
 *
 * remainingDuration - The number of seconds until the order expires.
 * timerStartedAt    - When the timer was started (helpers later when we create a new Timer
 *                     widget on a different container).
 */

export enum TimerActionType {
    startBackgroundTimer = 'startBackgroundTimer',
    updateBackgroundTimer = 'updateBackgroundTimer',
}

export interface StartBackgroundTimerAction {
    type: TimerActionType.startBackgroundTimer;
    payload: {
        timerStartedAt: number;
        remainingDuration: number;
    };
}

export interface UpdateBackgroundTimerAction {
    type: TimerActionType.updateBackgroundTimer;
    payload: {
        isTimerExpired: boolean;
        remainingDuration: number;
    };
}

const startBackgroundTimerStatusAction = (
    remainingDuration: number,
): StartBackgroundTimerAction => ({
    type: TimerActionType.startBackgroundTimer,
    payload: {
        remainingDuration,
        timerStartedAt: Date.now(),
    },
});

export const startBackgroundTimer = () => (
    dispatch: Dispatch<any>,
    getState: () => Pick<CheckoutState, 'order'>,
) => {
    const { order } = getState();

    const remainingDuration =
        'timeRemaining' in order ? order.timeRemaining : undefined;

    if (remainingDuration === undefined) {
        logger.warn('Timer Actions: Order has no timeRemaining');
        return;
    }

    dispatch(startBackgroundTimerStatusAction(remainingDuration));
};

/**
 * Called by containers when they are mounted or become visible. This updates the value of
 * timer.remainingDuration so that the Timer widget is initialized with the correct value.
 * The Timer counts down in seconds on its own from there so long as the duration is not changed.
 *
 * remainingDuration - The number of seconds until the order expires. Calculated based on the time
 * remaining on the order model minus when the timer was started (the order time remaining is updated
 * when the order is created or placed).
 * isTimerExpired    - True if there is no more remaining time to checkout.
 */
const updateBackgroundTimerStatusAction = ({
    isTimerExpired,
    remainingDuration,
}: {
    isTimerExpired: boolean;
    remainingDuration: number;
}) => ({
    type: TimerActionType.updateBackgroundTimer,
    payload: {
        isTimerExpired,
        remainingDuration,
    },
});

export const updateBackgroundTimer = () => (
    dispatch: Dispatch<any>,
    getState: () => CheckoutState,
) => {
    const { order, timer } = getState();

    const timeRemaining =
        'timeRemaining' in order ? order.timeRemaining : undefined;

    const timerStartedAt =
        'timerStartedAt' in timer ? timer.timerStartedAt : Date.now();

    if (timeRemaining === undefined) {
        logger.warn('Timer Actions: Order has no timeRemaining at update');
        return;
    }

    const remainingDuration =
        timeRemaining - moment().diff(timerStartedAt, 'seconds');

    const isTimerExpired = remainingDuration <= 0;

    dispatch(
        updateBackgroundTimerStatusAction({
            isTimerExpired,
            remainingDuration,
        }),
    );
};

export const navigateToTimeExpiredStatusPage = () => (
    dispatch: Dispatch<any>,
    getState: () => CheckoutState,
) => {
    const path = hasExistingOrder(getState())
        ? TIME_EXPIRED_STATUS_NO_BACK_BUTTON
        : TIME_EXPIRED_STATUS;

    dispatch(replace(joinPath(BASE_URL, STATUS_PATH, path)));
};

export type TimerAction =
    | StartBackgroundTimerAction
    | UpdateBackgroundTimerAction;
