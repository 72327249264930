import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import includes from 'lodash/includes';
import classNames from 'classnames';
import { translationPropType } from '@eb/i18n';
import { Button } from '@eb/eds-button';
import { Label } from '@eb/eds-label';
import CharacterCounter from './CharacterCounter';
import * as constants from './constants';
import { getAdditionalProps } from '@eb/eds-utils';

import './textInput.scss';

// The shortest length currency symbol that qualifies for the
// "long" modifier
const CURRENCY_SYMBOL_LONG_MINLENGTH = 2;

// TextInput component can render some components inside the form input, such as icons.
const isIconComponent = ({ type }) =>
    includes(constants.CHILD_COMPONENT_WHITELIST, type);

const TextPrefix = ({ text, htmlFor }) => {
    if (!text) {
        return null;
    }

    return (
        <Label className="eds-textinput__text-prefix" htmlFor={htmlFor}>
            {text}
        </Label>
    );
};

const TextSuffix = ({ text, showTextSuffix }) => {
    if (showTextSuffix && text) {
        return <div className="eds-textinput__text-suffix">{text}</div>;
    }

    return null;
};

/**
 * @deprecated
 */
export default class TextInput extends PureComponent {
    static propTypes = {
        /**
         * TextInput type: text, password, email, url, tel, search,
         * date, number
         */
        type: PropTypes.oneOf(constants.TYPES),
        /**
         * Role of text input
         */
        role: PropTypes.string,
        /**
         * Default value of text input
         * This is the initial value
         * After the text input has been rendered the first time,
         * if the default value changes, it will *not* update the input
         */
        defaultValue: PropTypes.string,
        /**
         * Value of text input
         * The value can override the default value if specified
         * After the text input has been rendered the first time,
         * if the value changes, it *will* update the input
         */
        value: PropTypes.string,
        /**
         * ID of element
         */
        id: PropTypes.string,
        /**
         * Name of element
         */
        name: PropTypes.string,
        /**
         * Default placeholder for input field
         */
        placeholder: translationPropType,
        /**
         * Character limit
         */
        maxLength: PropTypes.number,
        /**
         * Show character limit counter
         */
        showCounter: PropTypes.bool,
        /**
         * Is disabled
         */
        disabled: PropTypes.bool,
        /**
         * Is required
         */
        required: PropTypes.bool,
        /**
         * Set error state
         */
        hasError: PropTypes.bool,
        /**
         * Container class name
         */
        __containerClassName: PropTypes.string,

        /**
         * {function} onChange
         */
        onChange: PropTypes.func,
        /**
         * {function} onBlur
         */
        onBlur: PropTypes.func,
        /**
         * {function} onFocus
         */
        onFocus: PropTypes.func,
        /**
         * {function} onMouseOver
         */
        onMouseOver: PropTypes.func,
        /**
         * Identifier for tests
         */
        'data-spec': PropTypes.string,

        /**
         * TextInput supports 1 or 2 icon children,
         * which turns the component into a Text Input w/ icons
         */
        children: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.arrayOf(PropTypes.element),
        ]),
        /**
         * Whether or not the specified icon (in children) should be right-aligned
         */
        iconAlignRight: PropTypes.bool,
        /**
         * Whether or not the specified icon (in children) should be wrapped in
         * a submit button
         */
        iconSubmits: PropTypes.bool,
        /**
         * The currency symbol to be included within the text input
         */
        hideOutline: PropTypes.bool,
        /**
         * Hide the visibility of the outline around the text input
         */
        currencySymbol: translationPropType,
        /**
         * Whether or not the specified currency symbol should be placed at the end
         */
        currencySymbolIsSuffix: PropTypes.bool,
        /**
         * Text prefix
         */
        textPrefix: translationPropType,
        /**
         * Text suffix
         */
        textSuffix: translationPropType,
        /**
         * Whether or not we should focus the input field
         */
        shouldFocus: PropTypes.bool,
        /**
         * tab-index
         */
        tabIndex: PropTypes.number,
    };

    static defaultProps = {
        type: 'text',
        role: 'textbox',
        defaultValue: '',
        'data-spec': 'text-input',
        showCounter: false,
        hideOutline: false,
    };

    constructor(props) {
        super(props);

        const { defaultValue, maxLength, textSuffix, shouldFocus } = props;

        let value = props.value || defaultValue;

        if (maxLength !== undefined) {
            value = value.substr(0, maxLength);
        }

        this.state = {
            value,
            showTextSuffix: !!textSuffix && !value,
            shouldFocus: shouldFocus === undefined ? false : shouldFocus,
        };
    }

    componentWillReceiveProps({ value, maxLength, shouldFocus, textSuffix }) {
        // allows setting an existing `input` element's value via props from a parent
        let trimmedValue = value;

        if (trimmedValue !== undefined) {
            let newState = {
                showTextSuffix: !!textSuffix && !trimmedValue,
            };

            if (maxLength !== undefined) {
                trimmedValue = trimmedValue.substr(0, maxLength);
            }

            if (shouldFocus !== undefined) {
                newState = { shouldFocus, ...newState };
            }

            this.setState({ value: trimmedValue, ...newState });
        }
    }

    componentDidUpdate() {
        const { shouldFocus } = this.state;

        if (shouldFocus) {
            this._focus();
        }
    }

    _getRef(ref) {
        this._input = ref;
        return this._input;
    }

    _blur() {
        this._input.blur();
    }

    _focus() {
        this._input.focus();
    }

    _showHideSuffix(value) {
        if (!this.props.textSuffix) {
            return;
        }

        const showTextSuffix = !value;

        this.setState({ showTextSuffix });
    }

    _handleChange(e) {
        const value = e.target.value;

        this._showHideSuffix(value);
        this.setState({ value });

        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }

    _handleBlur() {
        this.setState({ shouldFocus: false });

        if (this.props.onBlur) {
            this.props.onBlur(this.state.value);
        }
    }

    _handleFocus() {
        this.setState({ shouldFocus: true });

        if (this.props.onFocus) {
            this.props.onFocus(this.state.value);
        }
    }

    _getIconWrappers(iconComponents, iconSubmits) {
        return iconComponents.map((iconComponent, index) => {
            const iconWrapperClassName = classNames(
                'eds-textinput__icon-container',
                {
                    'eds-textinput__icon-container--right': index > 0,
                },
            );
            let contentComponent = iconComponent;

            if (iconSubmits) {
                contentComponent = (
                    <span className="eds-textinput__icon-container__button">
                        <Button type="submit" style="none">
                            {iconComponent}
                        </Button>
                    </span>
                );
            }

            // `iconCompnoents` is an array of React components and nothing
            // prevents the icons from being identical
            /* eslint-disable react/no-array-index-key */
            return (
                <div
                    className={iconWrapperClassName}
                    key={index}
                    data-spec="text-input-icon-wrapper"
                >
                    {contentComponent}
                </div>
            );
            /* eslint-enable react/no-array-index-key */
        });
    }

    _getCurrencySymbolContent(currencySymbol) {
        let content;

        if (currencySymbol) {
            content = (
                <div className="eds-textinput__curency-symbol">
                    {currencySymbol}
                </div>
            );
        }

        return content;
    }

    _getComponent({
        input,
        id,
        disabled,
        shouldFocus,
        hasError,
        value,
        maxLength,
        showCounter,
        iconComponents,
        iconAlignRight,
        iconSubmits,
        hideOutline,
        currencySymbol,
        currencySymbolIsSuffix,
        textPrefix,
        textSuffix,
        showTextSuffix,
        __containerClassName,
    }) {
        const numIcons = iconComponents.length;
        const hasIcons = numIcons > 0;
        let component = input;
        const contentWrapperClassName = classNames(
            'eds-textinput',
            {
                'eds-textinput--error': hasError,
                'eds-textinput--focus': shouldFocus,
                'eds-textinput--disabled': disabled,
                'eds-textinput--has-icon': hasIcons,
                'eds-textinput--has-icon--right': iconAlignRight,
                'eds-textinput--has-icon--double': numIcons > 1,
                'eds-textinput--has-icon--action': hasIcons && iconSubmits,
                'eds-textinput--hide-outline': hideOutline,
                'eds-textinput--currency': currencySymbol,
                'eds-textinput--has-currency-suffix':
                    currencySymbol && currencySymbolIsSuffix,
                'eds-textinput--currency--medium':
                    currencySymbol &&
                    currencySymbol.length === CURRENCY_SYMBOL_LONG_MINLENGTH,
                'eds-textinput--currency--long':
                    currencySymbol &&
                    currencySymbol.length > CURRENCY_SYMBOL_LONG_MINLENGTH,
                'eds-textinput--has-text-prefix': textPrefix,
                'eds-textinput--has-text-suffix': showTextSuffix,
            },
            __containerClassName,
        );
        const iconWrappers = this._getIconWrappers(iconComponents, iconSubmits);
        const currencySymbolContent = this._getCurrencySymbolContent(
            currencySymbol,
        );

        component = (
            <div
                className={contentWrapperClassName}
                data-spec="text-input-wrapper"
            >
                <CharacterCounter
                    value={value}
                    maxLength={maxLength}
                    showCounter={showCounter && shouldFocus}
                />
                {currencySymbolContent}
                <TextPrefix text={textPrefix} htmlFor={id} />
                {input}
                {iconWrappers}
                <TextSuffix text={textSuffix} showTextSuffix={showTextSuffix} />
            </div>
        );

        return component;
    }

    render() {
        const {
            type,
            id,
            name,
            role,
            placeholder,
            disabled,
            required,
            hasError,
            __containerClassName,

            iconAlignRight,
            iconSubmits,
            hideOutline,
            children,
            'data-spec': dataSpec,
            currencySymbol,
            currencySymbolIsSuffix,
            maxLength,
            showCounter,
            textPrefix,
            textSuffix,
            tabIndex,
            onMouseOver,
        } = this.props;
        const { shouldFocus, value, showTextSuffix } = this.state;

        const extraAttrs = getAdditionalProps(this);
        const iconComponents = React.Children.toArray(children).filter(
            isIconComponent,
        );
        const needsWrapper = iconComponents.length > 0;
        const extraInputClasses = {};

        if (__containerClassName) {
            extraInputClasses[__containerClassName] = !needsWrapper;
        }

        const inputClassName = classNames(
            'eds-textinput__input',
            extraInputClasses,
        );

        const input = (
            <input
                {...extraAttrs}
                ref={this._getRef.bind(this)}
                data-spec={dataSpec}
                type={type}
                role={role}
                className={inputClassName}
                id={id}
                name={name}
                placeholder={placeholder}
                maxLength={maxLength}
                tabIndex={tabIndex}
                disabled={disabled}
                aria-required={required}
                aria-invalid={hasError}
                value={this.state.value}
                onChange={this._handleChange.bind(this)}
                onBlur={this._handleBlur.bind(this)}
                onFocus={this._handleFocus.bind(this)}
                onMouseOver={onMouseOver}
            />
        );

        return this._getComponent({
            input,
            id,
            hasError,
            disabled,
            value,
            shouldFocus,
            maxLength,
            showCounter,
            iconComponents,
            iconAlignRight,
            iconSubmits,
            hideOutline,
            currencySymbol,
            currencySymbolIsSuffix,
            textPrefix,
            textSuffix,
            showTextSuffix,
            __containerClassName,
        });
    }
}
