import { transformUtil } from '@eb/transformation-utils';
import { Schema } from 'normalizr';

const INSTALLMENTS_RESPONSE_SCHEMA = new Schema('installments');

export interface InstallmentsResponse {
    [key: string]: {
        [key: string]: {
            installments: {
                [key: string]: {
                    installments: number;
                    installment_rate: number;
                    min_allowed_amount: number;
                    CFT: string;
                    TEA: string;
                };
            };
        };
    };
}

export interface Installments {
    [key: string]: {
        [key: string]: {
            installments: {
                [key: string]: {
                    installments: number;
                    installmentRate: number;
                    minAllowedAmount: number;
                    CFT: string;
                    TEA: string;
                };
            };
        };
    };
}

export const transformInstallmentsData = (data: InstallmentsResponse) => {
    const transformed = transformUtil({
        response: data,
        schema: INSTALLMENTS_RESPONSE_SCHEMA,
    });

    if (!transformed) {
        return null;
    }

    const installments = transformed.entities.installments as {
        [key: string]: Installments;
    };

    return Object.values(installments)[0];
};
