import { UserInstrument, VirtualIncentive } from '../models/user';
import { WithUserNamespace } from '../state/user';

export const getUserId = (state: WithUserNamespace): string =>
    state.user?.publicId ?? '';

export const isUserLoggedIn = (state: WithUserNamespace): boolean =>
    !!state.user?.publicId;

export const isLoggedInAsHardUser = (state: WithUserNamespace): boolean =>
    !!(state.user?.hasPassword || state.user?.hasSocialLogin);

export const getUserEmail = (state: WithUserNamespace) =>
    isUserLoggedIn(state) && state.user?.email;

export const getUserFirstName = (state: WithUserNamespace) =>
    isUserLoggedIn(state) && state.user?.firstName;

export const getUserLastName = (state: WithUserNamespace) =>
    isUserLoggedIn(state) && state.user?.lastName;

export const getUserInstruments = (
    state: WithUserNamespace,
): Array<UserInstrument> => state.user?.userInstruments ?? [];

export const getVirtualIncentives = (
    state: WithUserNamespace,
): Array<VirtualIncentive> =>
    state.user?.virtualIncentive ? [state.user.virtualIncentive] : [];

export const getUserHasPassword = (state: WithUserNamespace) =>
    state.user?.hasPassword ?? false;
