// TODO combine this file with checkoutWidget/containers/template/pageTemplate.scss

import classNames from 'classnames';

export const getDisplayClasses = (config: { hasTopMargin?: boolean } = {}) =>
    classNames(
        {
            'eds-l-pad-top-10': config.hasTopMargin || false,
        },
        'eds-g-cell',
        'eds-g-cell-mw-10-12 eds-g-offset-mw-1-12',
        'eds-g-cell-mn-12-12 eds-g-offset-mn-0-12',
        'eds-g-cell-sw-10-12 eds-g-offset-sw-1-12',
        'eds-g-cell-sn-12-12',
        'eds-g-cell-12-12',
        'eds-l-sn-pad-hor-4',
        'eds-l-pad-hor-6',
    );
