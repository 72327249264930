import * as React from 'react';

const DragChunkySvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 24 24" {...props}>
        <path fill="#45494E" d="M6 10V8h12v2H6zm0 6v-2h12v2H6z" />
    </svg>
);

DragChunkySvg.displayName = 'DragChunkySvg';
export default DragChunkySvg;
