import { joinPath } from '@eb/path-utils';
import { replace } from 'react-router-redux';
import { transformTicketsData } from '../api/transformations/tickets';
import { getEventAndTickets as getEventAndTicketsApi } from '../api/event';
import { stopSubmit } from 'redux-form';
import {
    BASE_URL,
    INVALID_PROMO_CODE,
    PASSWORD_EXPIRED,
    PROMO_CODE_FAILURE_MESSAGE,
    PROMO_CODE_FORM_NAME,
    PROTECTED_PATH,
    UNLOCK_TICKETS_PATH,
    WIDGET_TRACKING,
} from '../constants';
import { getIsEventProtected } from '../selectors/event';
import { getPromoCodeInformation } from '../utils/promoCodes';
import { filterEligibleAdvancedTeamTickets } from '../utils/tickets';
import { trackPromoCodeSubmitError } from './eventAnalytics';
import { removeAppErrorAction } from './app';
import {
    initializeEvent as initializeEventAction,
    updateEventPrivacySetting as updateEventPrivacySettingAction,
} from './event';
import { updateSelectedPaymentConstraints } from './tickets';

export const APPLY_PROMO_CODE = 'applyPromoCode';
export const REMOVE_PROMO_CODE = 'removePromoCode';
export const UNLOCK_TICKETS = 'unlockTickets';

export const removePromoCode = (
    transformedTickets,
    remainingCapacity,
    data = {},
) => ({
    type: REMOVE_PROMO_CODE,
    payload: {
        ...transformedTickets,
        remainingCapacity,
    },
    meta: {
        analytics: {
            ...WIDGET_TRACKING.TS_PROMO_REMOVE,
            label: data.labelType,
            dimensions: {
                checkoutStep: data.checkoutStep,
            },
            value: data.promo,
        },
    },
});

export const unlockTickets = (transformedTickets, data = {}) => ({
    type: UNLOCK_TICKETS,
    payload: transformedTickets,
    meta: {
        analytics: {
            ...WIDGET_TRACKING.TS_PROMO_APPLY,
            value: data.promo,
            dimensions: {
                checkoutStep: UNLOCK_TICKETS_PATH,
            },
        },
    },
});

export const applyPromoCode = (
    response,
    remainingCapacity,
    data = {},
    checkoutStep,
) => ({
    type: APPLY_PROMO_CODE,
    payload: {
        ...response,
        remainingCapacity,
    },
    meta: {
        analytics: {
            ...WIDGET_TRACKING.TS_PROMO_APPLY,
            value: data.promo,
            dimensions: {
                checkoutStep,
            },
        },
    },
});

export const _handleErroredPromoCode = (
    id,
    dispatch,
    errorMessage,
    data = {},
    checkoutStep,
) => (error = {}) => {
    let trackedErrorMessage;

    if (error.errors) {
        const { _error } = error.errors;

        trackedErrorMessage = _error;
        dispatch(stopSubmit(PROMO_CODE_FORM_NAME, { promo: _error }));
    }

    if (errorMessage) {
        trackedErrorMessage = errorMessage;
        dispatch(stopSubmit(PROMO_CODE_FORM_NAME, { promo: errorMessage }));
    }

    dispatch(
        trackPromoCodeSubmitError(
            data.promo,
            trackedErrorMessage,
            checkoutStep,
        ),
    );

    return Promise.reject();
};

// XXX: Currently, a user can add a discount code to a hidden ticket. In this case,
// the endpoint getEventAndTicketsApi does not return an error for the discount
// code even though nothing is discounted / unlocked. When the TAS is updated
// to return 404 for discount codes applied to hidden tickets, _evaluatePromoCodeSubmitResponse()
// can be removed. See: https://jira.evbhome.com/browse/EB-67705
export const _evaluatePromoCodeSubmitResponse = (
    response,
    data = {},
    checkoutStep,
) => async (dispatch, getState) => {
    const {
        event: { id, remainingCapacity },
    } = getState();

    const { ticketIds: updatedTicketIds = [], tickets } = transformTicketsData(
        response,
    );
    const { discountType: promoType } = getPromoCodeInformation(tickets);

    // In the case of protected events, if the password has expired, or was changed by the organizer,
    // The event will be returned as locked, we therefore should warn the user:
    if (getIsEventProtected(getState())) {
        dispatch(updateEventPrivacySettingAction(PASSWORD_EXPIRED));
        return dispatch(replace(joinPath(BASE_URL, PROTECTED_PATH)));
    }

    if (!promoType) {
        return dispatch(
            _handleErroredPromoCode(
                id,
                dispatch,
                PROMO_CODE_FAILURE_MESSAGE,
                data,
                checkoutStep,
            ),
        );
    }

    const updateAfterPromoCode = {
        ticketIds: [...updatedTicketIds],
        tickets,
    };

    return dispatch(
        applyPromoCode(
            updateAfterPromoCode,
            remainingCapacity,
            data,
            checkoutStep,
        ),
    );
};

export const _evaluatePromocodeClearResponse = (
    response,
    { promo, labelType, checkoutStep },
) => (dispatch, getState) => {
    const {
        event: { remainingCapacity, hasAdvancedTeamsEnabled },
        groupRegistration,
    } = getState();
    // In the case of protected events, if the password has expired, or was changed by the organizer,
    // The event will be returned as locked, we therefore should warn the user:

    if (getIsEventProtected(getState())) {
        dispatch(updateEventPrivacySettingAction(PASSWORD_EXPIRED));
        return dispatch(replace(joinPath(BASE_URL, PROTECTED_PATH)));
    }

    let transformedTickets = transformTicketsData(response);

    if (hasAdvancedTeamsEnabled && groupRegistration) {
        const filteredTickets = {};
        const filteredTicketIds = [];
        const filteredTicketList = filterEligibleAdvancedTeamTickets(
            transformedTickets.tickets,
            groupRegistration.isIndividualSelected,
        );

        filteredTicketList.forEach((ticket) => {
            const id = ticket.id;

            filteredTickets[id] = ticket;
            filteredTicketIds.push(id);
        });

        transformedTickets = {
            tickets: filteredTickets,
            ticketIds: filteredTicketIds,
        };
    }

    return dispatch(
        removePromoCode(transformedTickets, remainingCapacity, {
            promo,
            labelType,
            checkoutStep,
        }),
    );
};

export const promoCodeClear = (promo, labelType, checkoutStep) => (
    dispatch,
    getState,
) => {
    const {
        app: { inviteToken, campaignToken, waitlistCode },
        event: { id },
    } = getState();

    return getEventAndTicketsApi(
        id,
        null,
        waitlistCode,
        inviteToken,
        campaignToken,
    ).then(({ response }) => {
        dispatch(initializeEventAction(response));
        dispatch(
            _evaluatePromocodeClearResponse(response, {
                promo,
                labelType,
                checkoutStep,
            }),
        );
        dispatch(updateSelectedPaymentConstraints());
    });
};

export const promoCodeSubmit = (data, checkoutStep) => (dispatch, getState) => {
    const { promo } = data;
    const {
        app: { inviteToken, campaignToken, errors = [], waitlistCode },
        event: { id },
    } = getState();

    // Check for empty field submission and avoid error message
    if (!promo) {
        dispatch(stopSubmit(PROMO_CODE_FORM_NAME, {}));

        return Promise.resolve();
    }

    // Remove any previous promo code errors from initialization of the app
    if (errors.length) {
        dispatch(removeAppErrorAction(INVALID_PROMO_CODE));
    }

    return getEventAndTicketsApi(
        id,
        promo,
        waitlistCode,
        inviteToken,
        campaignToken,
    ).then(({ response }) => {
        dispatch(initializeEventAction(response));
        dispatch(
            _evaluatePromoCodeSubmitResponse(response, data, checkoutStep),
        );
        dispatch(updateSelectedPaymentConstraints());
    }, _handleErroredPromoCode(id, dispatch, null, data, checkoutStep));
};

export const _evaluateUnlockTicketsCodeSubmitResponse = (
    response,
    data,
    checkoutStep,
) => (dispatch, getState) => {
    const {
        event: { hasAdvancedTeamsEnabled, id },
        groupRegistration,
    } = getState();

    const transformedTickets = transformTicketsData(response);

    if (hasAdvancedTeamsEnabled && groupRegistration) {
        const filteredTicketList = filterEligibleAdvancedTeamTickets(
            transformedTickets.tickets,
            groupRegistration.isIndividualSelected,
        );

        if (filteredTicketList.length === 0) {
            return dispatch(
                _handleErroredPromoCode(
                    id,
                    dispatch,
                    PROMO_CODE_FAILURE_MESSAGE,
                    data,
                    checkoutStep,
                ),
            );
        }
    }

    return dispatch(unlockTickets(transformedTickets, data, checkoutStep));
};

export const unlockTicketsCodeSubmit = (data, checkoutStep) => (
    dispatch,
    getState,
) => {
    const { promo } = data;
    const {
        app: { inviteToken, campaignToken, waitlistCode },
        event: { id },
    } = getState();

    // Check for empty field submission and avoid error message
    if (!promo) {
        dispatch(stopSubmit(PROMO_CODE_FORM_NAME, {}));

        return Promise.resolve();
    }

    return getEventAndTicketsApi(
        id,
        promo,
        waitlistCode,
        inviteToken,
        campaignToken,
    )
        .then(({ response }) =>
            dispatch(
                _evaluateUnlockTicketsCodeSubmitResponse(
                    response,
                    data,
                    checkoutStep,
                ),
            ),
        )
        .catch(_handleErroredPromoCode(id, dispatch, null, data, checkoutStep));
};

export const getPromoCodeFormActionHandlers = (dispatch) => ({
    onChange: () => {
        dispatch(stopSubmit(PROMO_CODE_FORM_NAME, {}));
    },
    onClear: (promo, labelType, checkoutStep) =>
        dispatch(promoCodeClear(promo, labelType, checkoutStep)),
});
