import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import { translationPropType } from '@eb/i18n';
import gettext from '@eb/gettext';
import { LoadingOverlay } from '@eb/eds-loading-overlay';
import { CheckChunky } from '@eb/eds-iconography';
import { formValidate } from '@eb/eds-utils';
import { TYPE_ERROR, TYPE_NEUTRAL, TYPE_SUCCESS } from '@eb/eds-notification';
import {
    OPTIONS_PROP_TYPE as PROMO_CODE_OPTIONS_PROP_TYPE,
    HANDLER_PROP_TYPE as PROMO_CODE_HANDLER_PROP_TYPE,
} from '../../components/promoCode/constants';
import { DATA_PROP_TYPES as TICKET_PROP_TYPES } from '../../components/ticketsSelectionList/constants';
import TicketsSelectionList from '../../components/ticketsSelectionList/TicketsSelectionList';
import TicketCategoryGroup from '../../components/ticketsSelectionList/TicketCategoryGroup';
import ResellerFooter from '../../components/resellerFooter/ResellerFooter';
import LanguageAndBrandContainer from '../LanguageAndBrandContainer';
import { PromoCodeCtaText } from '../../components/promoCode/helpers';
import { getSuccessMessage as getPromoCodeSuccessMessage } from '../../components/promoCode/utils';

import { PageTemplate, TEMPLATE_PROP_TYPE_SHAPE } from '../template';
import { getTemplateHeaderProps } from '../template/utils';
import { shouldShowEventReseller } from './utils/ticketUtils';

import validate from './validation';
import { TICKETS_PAGE_FORM_NAME, TICKETS_PATH } from '../../constants';
import * as constants from './constants';
import { NEWLY_GROUP_CREATED_TEXT } from '../groupRegistration/terminology';
import PromoCodeBar from '../../components/promoCode/PromoCodeBar';
import WaitingRoomCTA from './WaitingRoomCTA';
import { GROUP_TYPE_PROP_TYPE } from '../groupRegistration/constants';
import { resetFocusToInitialElement } from '../../utils/accessibility';
import { FOCUSABLE_FORM_ELEMENTS } from '../../constants/focusableElements';

class TicketSelectionPage extends React.Component {
    static propTypes = {
        /**
         * All props for the tickets container
         */
        ...omit(TicketsSelectionList.propTypes, 'tickets'),
        /**
         * Props related to the checkout template
         */
        templateProps: TEMPLATE_PROP_TYPE_SHAPE.isRequired,

        /**
         * An array of tickets for this event.
         */
        tickets: PropTypes.arrayOf(PropTypes.shape(TICKET_PROP_TYPES)),

        /**
         * An array of tickets with promo code applied.
         */
        ticketsWithAppliedCode: PropTypes.arrayOf(
            PropTypes.shape(TICKET_PROP_TYPES),
        ),

        /**
         * A sales status (sales end soon, sales not started, etc.) that applies to all tickets, if it exists
         */
        statusForAllTickets: PropTypes.oneOf(
            constants.TICKET_SALES_STATUS_PROP_TYPE,
        ),

        /**
         * A common sales start or end date for all tickets, if it exists
         */
        salesDateForAllTickets: translationPropType,

        /**
         * Props passed by redux-form
         */
        handleSubmit: PropTypes.func.isRequired,
        submitFailed: PropTypes.bool,
        error: translationPropType,

        /* Used to show a success message when a promo code is applied */
        shouldShowPromoCodeNotification: PropTypes.bool,

        /**
         * PromoCodeForm-related props
         */
        shouldShowPromoCodeForm: PropTypes.bool,
        shouldShowWaitingRoomCTA: PropTypes.bool,
        promoCodeFormOptions: PROMO_CODE_OPTIONS_PROP_TYPE,
        promoCodeFormHandlers: PROMO_CODE_HANDLER_PROP_TYPE,

        onPageWillMount: PropTypes.func.isRequired,

        /*
         * Series event props
         */
        shouldShowBackToSeriesLink: PropTypes.bool,
        onBackToSeries: PropTypes.func,

        /**
         * Ticket classes sorted by category
         */
        ticketClassesByCategory: PropTypes.objectOf(PropTypes.object),

        /**
         * Whether to show the headings for ticket class category groupings
         */
        shouldShowTicketCategoryGroupHeaders: PropTypes.bool,
        /**
         * Used for Google Analytics tracking for cart summary icon in footer
         */
        onCartIconClick: PropTypes.func.isRequired,

        /**
         * Group Registration event props used to determine and
         * display back button to return to group selection page
         */
        onBackToGroupTypeSelection: PropTypes.func,
        hasAdvancedTeamsEnabled: PropTypes.bool,

        /**
         * Group type selected
         */
        groupType: GROUP_TYPE_PROP_TYPE,

        /**
         * Group Registration event props used to show a notification when a new group has just been created
         */
        showNewlyCreatedGroupNotification: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            expandedTicketCount: 0,
            isPaneOpen: false,
            isPromoCodeFormOpen:
                !!props.promoCodeFormOptions.promoCode || false,
            isLoading: false,
        };

        this.focusRef = React.createRef();
    }

    componentWillMount() {
        this.props.onPageWillMount(this.props.shouldShowPromoCodeForm);
    }

    componentDidMount() {
        const { isModal } = this.props;

        if (isModal) {
            resetFocusToInitialElement({
                rootEl: this.focusRef.current,
                focusableEls: FOCUSABLE_FORM_ELEMENTS,
            });
        }
    }

    componentWillReceiveProps({
        submitFailed,
        submitPromoCodeFormFailed,
        error,
        promoCodeFormOptions: { promoCode },
    }) {
        const prevPromoCodeType = this.props.promoCodeFormOptions.promoCode;

        if (submitFailed && error) {
            this.setState({
                isPaneOpen: false,
            });
        }
        const promoAdded = !prevPromoCodeType && promoCode;
        const promoRemoved = prevPromoCodeType && !promoCode;

        if (promoAdded || promoRemoved || submitPromoCodeFormFailed) {
            this.setState({
                isLoading: false,
            });
        }
    }

    _onPaneToggle() {
        const { onCartIconClick } = this.props;

        onCartIconClick(this.state.isPaneOpen);
        this.setState(({ isPaneOpen }) => ({ isPaneOpen: !isPaneOpen }));
    }

    _handleNotificationClose() {
        const { onClear } = this.props.promoCodeFormHandlers;
        const { promoCode } = this.props.promoCodeFormOptions;

        // Show loading spinner during removal of applied promo code
        this.setState({ isLoading: true });

        onClear(promoCode);
    }

    _handleSubmitPromoCode(e) {
        const { onSubmit } = this.props.promoCodeFormHandlers;

        this.setState({ isLoading: true });

        onSubmit(e);
    }

    _getNotificationOptions() {
        const {
            error: submitError,
            errors,
            isRegEvent,
            promoCodeFormOptions,
            shouldShowPromoCodeNotification,
            submitFailed,
            ticketsWithAppliedCode,
            isDraftEvent,
            groupType,
            showNewlyCreatedGroupNotification,
        } = this.props;
        let numPromoCodeTickets = 0;

        if (ticketsWithAppliedCode) {
            numPromoCodeTickets = ticketsWithAppliedCode.length;
        }

        const {
            promoCode,
            promoCodeType,
            percentOff,
            amountOff,
        } = promoCodeFormOptions;
        const notificationOptions = [];

        if (!isEmpty(errors)) {
            // Currently handles waitlist and promo code errors on initial load
            errors.forEach((error) => {
                const errorDescription = error.errorDescription;

                notificationOptions.push({
                    type: TYPE_ERROR,
                    children: errorDescription,
                });
            });
        }

        if (submitFailed && submitError) {
            notificationOptions.push({
                type: TYPE_ERROR,
                children: submitError,
            });
        }

        // Add notificationBar with success message
        if (shouldShowPromoCodeNotification) {
            notificationOptions.push({
                iconType: <CheckChunky />,
                'data-automation': 'promo-code-notification-bar',
                children: (
                    <span>
                        {/* eslint-disable react/no-danger */}
                        <span
                            dangerouslySetInnerHTML={getPromoCodeSuccessMessage(
                                {
                                    promoCode,
                                    promoCodeType,
                                    percentOff,
                                    amountOff,
                                    numPromoCodeTickets,
                                    isRegEvent,
                                },
                            )}
                        />{' '}
                        <PromoCodeCtaText
                            ctaText={gettext('Remove')}
                            onClick={this._handleNotificationClose.bind(this)}
                        />
                    </span>
                ),
            });
        }

        if (isDraftEvent) {
            notificationOptions.push({
                type: TYPE_NEUTRAL,
                children: constants.EVENT_IN_DRAFT_TEXT,
            });
        }

        if (showNewlyCreatedGroupNotification) {
            notificationOptions.push({
                type: TYPE_SUCCESS,
                iconType: <CheckChunky />,
                hasCloseButton: true,
                children: (
                    <span data-spec="newly-created-group-text">
                        {NEWLY_GROUP_CREATED_TEXT[groupType]}
                    </span>
                ),
            });
        }

        return notificationOptions;
    }

    render() {
        let {
            ctaText,
            eventDate,
            eventTitle,
            handleSubmit,
            hasAdvancedTeamsEnabled,
            groupType,
            isDisabled,
            numWaitingRoomVisits,
            onBackToGroupTypeSelection,
            onBackToSeries,
            shouldShowBackToSeriesLink,
            shouldShowPromoCodeForm,
            shouldShowWaitingRoomCTA,
            statusForAllTickets,
            ticketReseller,
            templateProps,
        } = this.props;

        const { isPaneOpen, isLoading } = this.state;
        const shouldHideFooter =
            statusForAllTickets === constants.SALES_NOT_STARTED ||
            statusForAllTickets === constants.SALES_START_SOON;
        const showEventReseller = shouldShowEventReseller(ticketReseller);

        const extraPaneProps = { isTicketSelectionPage: true };
        const ticketsWrapperClassNames = classNames(
            'eds-l-sn-mar-top-0 eds-l-sm-mar-top-0 ',
            'eds-l-mar-top-2 eds-l-md-mar-top-2 ',
            'eds-l-mw-mar-top-8 eds-l-ln-mar-top-8 eds-l-lg-mar-top-8 eds-l-lw-mar-top-8',
        );
        const promoCodeWrapperClassNames = classNames(
            'eds-l-sn-mar-top-6 eds-l-sm-mar-top-6 eds-l-sw-mar-top-6 eds-l-mar-top-8',
        );

        // Setting ctaText to null will make the footer not display
        if (shouldHideFooter) {
            ctaText = null;
        }

        const ticketClassCategories = Object.keys(
            this.props.ticketClassesByCategory,
        );

        const promoCodeBarProps = {
            ...pick(
                {
                    ...this.props,
                    promoCodeFormHandlers: {
                        ...this.props.promoCodeFormHandlers,
                        onSubmit: this._handleSubmitPromoCode.bind(this),
                    },
                },
                Object.keys(PromoCodeBar.propTypes),
            ),
        };

        return (
            <PageTemplate
                ctaText={ctaText}
                formName={TICKETS_PAGE_FORM_NAME}
                isPaneOpen={isPaneOpen}
                isSubmitDisabled={isDisabled}
                handleSubmit={handleSubmit}
                notificationOptions={this._getNotificationOptions()}
                onPaneToggle={this._onPaneToggle.bind(this)}
                secondaryTitle={eventDate}
                templateProps={templateProps}
                title={eventTitle}
                hasTopMargin={false}
                dataSpec="checkout-widget-tickets-page"
                extraPaneProps={extraPaneProps}
                {...getTemplateHeaderProps({
                    hasAdvancedTeamsEnabled,
                    onBackToGroupTypeSelection,
                    onBackToSeries,
                    shouldShowBackToSeriesLink,
                    groupType,
                })}
            >
                <div ref={this.focusRef}>
                    <div className={ticketsWrapperClassNames}>
                        {shouldShowPromoCodeForm && (
                            <div className={promoCodeWrapperClassNames}>
                                <PromoCodeBar
                                    {...promoCodeBarProps}
                                    checkoutStep={TICKETS_PATH}
                                />
                            </div>
                        )}
                        <form onSubmit={handleSubmit}>
                            <WaitingRoomCTA
                                shouldShowCTA={shouldShowWaitingRoomCTA}
                                numWaitingRoomVisits={numWaitingRoomVisits}
                            />
                            {ticketClassCategories.map((groupKey, i) => (
                                <TicketCategoryGroup
                                    key={i}
                                    ticketClassGroupProps={
                                        this.props.ticketClassesByCategory[
                                            groupKey
                                        ]
                                    }
                                    {...this.props}
                                />
                            ))}
                        </form>
                    </div>
                    <LanguageAndBrandContainer />
                </div>
                {showEventReseller ? (
                    <ResellerFooter
                        resellerUrl={ticketReseller.url}
                        resellerText={ticketReseller.text}
                    />
                ) : (
                    ''
                )}
                <LoadingOverlay
                    data-spec="spinner"
                    size="large-thin"
                    isShown={isLoading}
                />
            </PageTemplate>
        );
    }
}

export default formValidate({
    form: TICKETS_PAGE_FORM_NAME,
    validate,
})(TicketSelectionPage);
