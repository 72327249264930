import { getEventLevelValueForKey } from './ticketUtils';
import { TICKET_SALES_STATUS_MAP } from '../constants';

/**
 * Given a sales status that applies to all tickets, return the corresponding sales start
 * or end date for that status.
 *
 * For example, if `statusForAllTickets` is `salesEndSoon`, this method will return the
 * sales end date that applies to all tickets, if a common date exists.
 *
 * @param  {object} formattedTickets
 * @param  {string} status
 * @return {string}
 */
export const getSalesDateForAllTickets = (
    formattedTickets,
    statusForAllTickets,
) => {
    let salesDateForAllTickets;
    const statusInfo = TICKET_SALES_STATUS_MAP[statusForAllTickets];

    if (statusInfo) {
        salesDateForAllTickets = getEventLevelValueForKey(
            formattedTickets,
            statusInfo.datePropKey,
        );
    }

    return salesDateForAllTickets;
};
