import every from 'lodash/every';
import isEmpty from 'lodash/isEmpty';

import { moment } from '@eb/date';
import logger from '@eb/logger-bugsnag';

import {
    DEFAULT_MESSAGE_TYPE,
    DONATIONS_MAX_DAYS_AFTER_EVENT_END,
    EVENT_ALL_TICKETS_REQUIRE_UNLOCK_STATUS,
    EVENT_CANCELED_STATUS,
    EVENT_ENDED_STATUS,
    EVENT_SALES_ENDED_STATUS,
    EVENT_SOLD_OUT_STATUS,
    EVENT_SOLD_OUT_WITH_CAPACITY_WAITLIST_STATUS,
    EVENT_SOLD_OUT_WITH_TICKET_RESELLER_STATUS,
    EVENT_TICKETS_UNAVAILABLE_STATUS,
    STATUS_CONTENT_MAP,
} from '../containers/status/constants';
import { AVAILABLE_TICKET_STATUS } from '../containers/ticketSelection/constants';
import { TICKET_CLASS_CATEGORIES } from '../constants';
import {
    eventHasTicketLevelWaitlist,
    isWaitlistAvailable,
    shouldShowEventCapacityWaitlistPage,
} from './waitlist';

/**
 * Helper Function for connectStatusPage that handles grabbing the correct
 * content based on the param in the route.
 *
 * @param statusKey {String} -- one of statuses located in ./constants
 * @param callbacks {Object} -- callbacks to be passed to status content
 **/
export const getStatusContent = (statusKey, callbacks = {}) =>
    STATUS_CONTENT_MAP[statusKey](callbacks);

export const getSalesStatusContent = (
    statusKey,
    eventSalesStatus,
    callbacks = {},
) => {
    const salesStatusContent = getStatusContent(statusKey, callbacks);

    if (
        eventSalesStatus &&
        eventSalesStatus.message &&
        eventSalesStatus.messageType !== DEFAULT_MESSAGE_TYPE
    ) {
        salesStatusContent.headerText = eventSalesStatus.message;
    }

    return salesStatusContent;
};

/**
 * Returns true when all tickets are locked behind a code:
 * - ticketsById is empty (meaning, all tickets are hidden),
 * - there is an access code,
 * - and:
 *      - there are available hidden tickets (that are not sold out) OR
 *      - tickets ARE sold out (hasAvailableHiddenTickets is false) but there is a waitlist available (either ticket/event)
 * @param {object} event
 * @param {object} ticketsById
 */
const _areAllEventTicketsLocked = (
    { hasAccessCode, hasAvailableHiddenTickets, waitlistSettings },
    ticketsById,
) =>
    hasAccessCode &&
    isEmpty(ticketsById) &&
    (hasAvailableHiddenTickets || isWaitlistAvailable(waitlistSettings));

const _areAllEventTicketsUnavailable = (
    { hasAccessCode, hasAvailableTickets, isSoldOut, isSeriesParent },
    ticketsById,
) =>
    !hasAccessCode &&
    !hasAvailableTickets &&
    !isSoldOut &&
    !isSeriesParent &&
    every(ticketsById, ({ onSaleInfo: { unavailable } }) => unavailable);

const _areAllEventTicketsSoldOut = (
    { isSoldOut, hasAvailableHiddenTickets, hasAccessCode, waitlistSettings },
    ticketsById,
) => {
    let allTicketsSoldOut = isSoldOut;

    if (hasAccessCode && hasAvailableHiddenTickets) {
        allTicketsSoldOut = false;
    }

    if (
        eventHasTicketLevelWaitlist(waitlistSettings) &&
        !isEmpty(ticketsById)
    ) {
        // set to false when there is a ticket waitlist AND all tickets are not hidden (ticketsById is not empty)
        allTicketsSoldOut = false;
    }

    return allTicketsSoldOut;
};

const _areDonationsStillAllowed = (event) => {
    const nowDate = moment(Date.now());
    const donationAllowedUntilDate = moment(event.end.utc).add(
        DONATIONS_MAX_DAYS_AFTER_EVENT_END,
        'days',
    );

    return nowDate.isBefore(donationAllowedUntilDate);
};

const _areDonationTicketsAvailable = (ticketsById) => {
    const availableDonationTickets = Object.values(ticketsById).filter(
        (ticket) =>
            ticket.category === TICKET_CLASS_CATEGORIES.DONATION &&
            ticket.onSaleStatus === AVAILABLE_TICKET_STATUS,
    );

    return availableDonationTickets.length > 0;
};

export const getStatusForEvent = (event, { ticketsById }, waitlistCode) => {
    if (event.status === EVENT_CANCELED_STATUS) {
        return EVENT_CANCELED_STATUS;
    }

    // EB-108786: The isSeriesParent check must occur after the sales/event ended checks. If the parent event or
    // sales have ended, we want to show the status page but we should ignore the other child event checks
    if (event.properties.isEnded) {
        if (
            _areDonationTicketsAvailable(ticketsById) &&
            _areDonationsStillAllowed(event)
        ) {
            return '';
        }
        return EVENT_ENDED_STATUS;
    } else if (event.properties.isSalesEnded) {
        return EVENT_SALES_ENDED_STATUS;
    } else if (event.isSeriesParent) {
        return '';
    }

    if (_areAllEventTicketsLocked(event, ticketsById)) {
        return EVENT_ALL_TICKETS_REQUIRE_UNLOCK_STATUS;
    }

    if (_areAllEventTicketsUnavailable(event, ticketsById)) {
        return EVENT_TICKETS_UNAVAILABLE_STATUS;
    }

    if (shouldShowEventCapacityWaitlistPage(event, ticketsById, waitlistCode)) {
        if (event.waitlistSettings) {
            return EVENT_SOLD_OUT_WITH_CAPACITY_WAITLIST_STATUS;
        } else {
            logger.error(
                `event ${event.id} has missing waitlist settings. Please open a ticket similar to EB-116696`,
            );
        }
    }

    if (_areAllEventTicketsSoldOut(event, ticketsById)) {
        if (!isEmpty(event.ticketReseller)) {
            return EVENT_SOLD_OUT_WITH_TICKET_RESELLER_STATUS;
        }

        return EVENT_SOLD_OUT_STATUS;
    }

    return '';
};
