import { mergeSeriesEvents } from '../selectors/series';
import {
    UPDATE_SERIES_EVENTS,
    UPDATE_SERIES_ID,
    UPDATE_SERIES_IS_LOADING,
    UPDATE_SERIES_PAGINATION,
} from '../actions/series';

export const seriesReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_SERIES_EVENTS:
            return {
                ...state,
                seriesEvents: mergeSeriesEvents(
                    state.seriesEvents,
                    action.payload.seriesEvents,
                ),
            };
        case UPDATE_SERIES_PAGINATION:
            return {
                ...state,
                pagination: action.payload,
            };
        case UPDATE_SERIES_ID:
            return {
                ...state,
                seriesId: action.payload,
            };
        case UPDATE_SERIES_IS_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            };
        default:
            return state;
    }
};
