// This file contains validation logic for credit card numbers. The code was adapted from core:
// https://github.com/eventbrite/core/blob/master/django/media/django/js/src/eb/credit_card/credit_card.js
// https://github.com/eventbrite/core/blob/master/django/media/django/js/src/require/helpers/payment/credit_card.js

import find from 'lodash/find';
import every from 'lodash/every';
import isNumber from 'lodash/isNumber';
import {
    CARD_DEFINITIONS,
    CARD_TYPES,
    getCardDef,
} from './creditCardConstants';

/**
 * Verify that the card number is valid for the card type it has been matched to.
 * @param  {string}  cardNumber A credit card number.
 * @param  {object}  cardDef    One of the objects from CARD_DEFINITIONS.
 * @return {Boolean}
 */
const _isCardNumberOfType = (cardNumber, cardDef) => {
    if (!cardDef) {
        return false;
    }
    let isCardNumberOfType = false;

    if (cardDef.prefixes) {
        const cardPrefixes = cardDef.prefixes.join('|');
        const pattern = new RegExp(`^(${cardPrefixes})`);

        isCardNumberOfType = pattern.test(cardNumber);
    }

    if (cardDef.prefixRegex) {
        return (
            isCardNumberOfType ||
            new RegExp(`(${cardDef.prefixRegex})`).test(cardNumber)
        );
    }
    return isCardNumberOfType;
};

/**
 * @param  {string} cardNumber  A credit card number.
 * @return {Boolean}
 */
const _verifyLuhnChecksum = (cardNumber) => {
    const reversedCardNumber = cardNumber.split('').reverse();

    const sum = reversedCardNumber.reduce((memo, number, currentIndex) => {
        const i = reversedCardNumber.length - currentIndex - 1;
        let digit = parseInt(number, 10);

        if (i % 2 === reversedCardNumber.length % 2) {
            digit *= 2;
        }

        if (digit > 9) {
            digit -= 9;
        }

        return memo + digit;
    }, 0);

    return sum % 10 === 0;
};

/**
 * Given a card number, return its card type. If no card type is detected, return
 * an empty string.
 * @param  {string} cardNumber A credit card number.
 * @return {string}            A string representing a card type. '001', '002', '003', '004'
 */
export const cardTypeForNumber = (cardNumber) => {
    const cardType = find(CARD_DEFINITIONS, (cardDef) =>
        _isCardNumberOfType(cardNumber, cardDef),
    );
    let cardTypeMatch = '';

    if (cardType) {
        cardTypeMatch = CARD_TYPES[cardType.cardType];
    }

    return cardTypeMatch;
};

/**
 * @param  {string}  cardNumber A credit card number.
 * @param  {Object}  validLengths     Object of valid lengths.
 * @return {Boolean}
 */
const _isValidCardNumberLength = (cardNumber, validLengths) => {
    if (validLengths) {
        return cardNumber.length in validLengths;
    }
    return false;
};

/**
 * Check if card follows a passed by parameter regex.
 * @param {string} cardNumber A credit card number
 * @param {string} validCardFormat
 * @returns {boolean}
 */
const _isCreditCardFormatValid = (cardNumber, validCardFormat) => {
    if (validCardFormat) {
        return new RegExp(`^(${validCardFormat})`).test(cardNumber);
    }
    return false;
};

/**
 * @param  {string} cardNumber      A credit card number.
 * @param  {string} cardNameOrType  A valid card name or type:
 *                                  ('visa', 'mastercard', 'amex', 'discover', '001', '002', '003', '004')
 * @return {Boolean}
 */
export const isValidCardNumberForCardType = (cardNumber, cardNameOrType) => {
    const cardDef = getCardDef(cardNameOrType);

    if (!cardDef) {
        return false;
    }

    return every([
        !!cardDef,
        isNumber(parseInt(cardNumber, 10)),
        _verifyLuhnChecksum(cardNumber),
        _isCreditCardFormatValid(cardNumber, cardDef.regex) ||
            _isValidCardNumberLength(cardNumber, cardDef.lengths),
    ]);
};
