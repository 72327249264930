import { OrderAttendee } from '../../models/attendee';
import { ShippingSettings } from '../../models/event';
import { Installments } from '../../models/installments';
import { OrderStatus } from '../../models/order';
import { RedirectInstructions } from '../../models/redirection';
import { TaxExemptionStatus } from '../../containers/checkout/constants';

import {
    transformPaymentOptions,
    PaymentOptionsResponse,
} from '../../api/transformations/paymentOptions';
import { transformOrderData } from '../../api/transformations/order';
import { OrderResponse } from '../../api/models/order';

export enum OrderActionType {
    updateOrder = 'updateOrder',
    updateOrderAttendees = 'updateOrderAttendees',
    updateOrderEmail = 'updateOrderEmail',
    updateOrderStatus = 'updateOrderStatus',
    updateRedirectInstructions = 'updateRedirectInstructions',
    saveEventShippingSettings = 'saveEventShippingSettings',
    saveWaitlistEntry = 'saveWaitlistEntry',
    taxPoliciesLoadComplete = 'taxPoliciesLoadComplete',
    updateTaxExemptionMessage = 'updateTaxExemptionMessage',
    resetOrder = 'resetOrder',
    removeOrderInstallments = 'removeOrderInstallments',
    setOrderInstallments = 'setOrderInstallments',
    setOrderUpdating = 'setOrderUpdating',
    setCanRetrySubmit = 'setCanRetrySubmit',
    updateAvailablePaymentMethods = 'updateAvailablePaymentMethods',
    updateBraintreeClientToken = 'updateBraintreeClientToken',
    setHasVirtualIncentivesErrors = 'setHasVirtualIncentivesErrors',
}

export interface UpdateOrderAction {
    type: OrderActionType.updateOrder;
    payload: ReturnType<typeof transformOrderData>;
}

export interface UpdateOrderAttendeesAction {
    type: OrderActionType.updateOrderAttendees;
    payload: Array<OrderAttendee>;
}

export interface UpdateOrderEmailAction {
    type: OrderActionType.updateOrderEmail;
    payload: { email: string };
}

export interface UpdateOrderStatusAction {
    type: OrderActionType.updateOrderStatus;
    payload: OrderStatus;
}

export interface UpdateRedirectInstructionsAction {
    type: OrderActionType.updateRedirectInstructions;
    payload: RedirectInstructions;
}

export interface SaveEventShippingSettingsAction {
    type: OrderActionType.saveEventShippingSettings;
    payload: { shippingSettings: ShippingSettings };
}

export interface SaveWaitlistEntryAction {
    type: OrderActionType.saveWaitlistEntry;
    payload: {
        firstName: string;
        lastName: string;
        email: string;
    };
}

export interface TaxPoliciesLoadCompleteAction {
    type: OrderActionType.taxPoliciesLoadComplete;
    payload: string | null;
}

export interface UpdateTaxExemptionMessageAction {
    type: OrderActionType.updateTaxExemptionMessage;
    payload: { status: TaxExemptionStatus };
}

export interface ResetOrderAction {
    type: OrderActionType.resetOrder;
}

export interface RemoveOrderInstallmentsAction {
    type: OrderActionType.removeOrderInstallments;
}

export interface SetOrderInstallmentsAction {
    type: OrderActionType.setOrderInstallments;
    payload: Installments;
}

export interface SetOrderUpdatingAction {
    type: OrderActionType.setOrderUpdating;
    payload: boolean;
}

export interface SetCanRetrySubmitAction {
    type: OrderActionType.setCanRetrySubmit;
    payload: boolean;
}

export interface UpdateAvailablePaymentMethodsAction {
    type: OrderActionType.updateAvailablePaymentMethods;
    payload: ReturnType<typeof transformPaymentOptions>;
}

export interface UpdateBraintreeClientTokenAction {
    type: OrderActionType.updateBraintreeClientToken;
    payload?: string;
}

export interface SetHasVirtualIncentivesErrorsAction {
    type: OrderActionType.setHasVirtualIncentivesErrors;
    payload: boolean;
}

export const updateOrderAction = (
    orderResponse: OrderResponse,
): UpdateOrderAction => ({
    type: OrderActionType.updateOrder,
    payload: transformOrderData(orderResponse),
});

export const updateOrderAttendeesAction = (
    attendeesToUpdate: Array<OrderAttendee>,
): UpdateOrderAttendeesAction => ({
    type: OrderActionType.updateOrderAttendees,
    payload: attendeesToUpdate,
});

export const updateOrderEmailAction = (payload: {
    email: string;
}): UpdateOrderEmailAction => ({
    type: OrderActionType.updateOrderEmail,
    payload,
});

/**
 * @note This action is only needed for external payment methods that need to poll/query
 * the status while the payment is pending on some other site.
 */
export const updateOrderStatusAction = (
    orderStatus: OrderStatus,
): UpdateOrderStatusAction => ({
    type: OrderActionType.updateOrderStatus,
    payload: orderStatus,
});

export const updateRedirectInstructionsAction = (
    redirectInstructions: RedirectInstructions,
): UpdateRedirectInstructionsAction => ({
    type: OrderActionType.updateRedirectInstructions,
    payload: redirectInstructions,
});

export const saveEventShippingSettingsAction = (eventShippingSettings: {
    shippingSettings: ShippingSettings;
}): SaveEventShippingSettingsAction => ({
    type: OrderActionType.saveEventShippingSettings,
    payload: eventShippingSettings,
});

export const saveWaitlistEntryAction = (data: {
    firstName: string;
    lastName: string;
    email: string;
}): SaveWaitlistEntryAction => ({
    type: OrderActionType.saveWaitlistEntry,
    payload: data,
});

export const taxPoliciesLoadCompleteAction = (response: {
    tax_policies_container: Array<{ tax_id_name?: string }>;
}): TaxPoliciesLoadCompleteAction => {
    const {
        tax_policies_container: [taxPolicies],
    } = response;

    return {
        type: OrderActionType.taxPoliciesLoadComplete,
        payload: taxPolicies?.tax_id_name ?? null,
    };
};

export const updateTaxExemptionMessageAction = (
    status: TaxExemptionStatus,
): UpdateTaxExemptionMessageAction => {
    return {
        type: OrderActionType.updateTaxExemptionMessage,
        payload: {
            status,
        },
    };
};

export const resetOrderAction = (): ResetOrderAction => ({
    type: OrderActionType.resetOrder,
});

export const removeOrderInstallmentsAction = (): RemoveOrderInstallmentsAction => ({
    type: OrderActionType.removeOrderInstallments,
});

export const setOrderInstallmentsAction = (
    installments: Installments,
): SetOrderInstallmentsAction => ({
    type: OrderActionType.setOrderInstallments,
    payload: installments,
});

export const setOrderUpdatingAction = (
    isUpdating: boolean,
): SetOrderUpdatingAction => ({
    type: OrderActionType.setOrderUpdating,
    payload: isUpdating,
});

export const setCanRetrySubmitAction = (
    canRetrySubmit: boolean,
): SetCanRetrySubmitAction => ({
    type: OrderActionType.setCanRetrySubmit,
    payload: canRetrySubmit,
});

export const updateAvailablePaymentMethodsAction = (
    paymentOptionsResponse: PaymentOptionsResponse,
): UpdateAvailablePaymentMethodsAction => ({
    type: OrderActionType.updateAvailablePaymentMethods,
    payload: transformPaymentOptions(paymentOptionsResponse),
});

export const updateBraintreeClientTokenAction = (
    braintreeClientToken?: string,
): UpdateBraintreeClientTokenAction => ({
    type: OrderActionType.updateBraintreeClientToken,
    payload: braintreeClientToken,
});

export const setHasVirtualIncentivesErrorsAction = (
    hasErrors: boolean,
): SetHasVirtualIncentivesErrorsAction => ({
    type: OrderActionType.setHasVirtualIncentivesErrors,
    payload: hasErrors,
});

export type OrderAction =
    | UpdateOrderAction
    | UpdateOrderAttendeesAction
    | UpdateOrderEmailAction
    | UpdateOrderStatusAction
    | UpdateRedirectInstructionsAction
    | SaveEventShippingSettingsAction
    | SaveWaitlistEntryAction
    | TaxPoliciesLoadCompleteAction
    | UpdateTaxExemptionMessageAction
    | ResetOrderAction
    | RemoveOrderInstallmentsAction
    | SetOrderInstallmentsAction
    | SetOrderUpdatingAction
    | SetCanRetrySubmitAction
    | UpdateAvailablePaymentMethodsAction
    | UpdateBraintreeClientTokenAction
    | SetHasVirtualIncentivesErrorsAction;
