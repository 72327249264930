const _STORAGE_KEY = 'session';
import { setTokensFromState } from './actions/tokens';

const _storeInSessionStorage = (key, value) => {
    try {
        const valueSerialized = JSON.stringify(value);

        sessionStorage.setItem(key, valueSerialized);
    } catch (err) {
        /* Ignore write errors: expected to happen on server side rendering. */
    }
};

const _loadFromSessionStorage = (key) => {
    try {
        const valueSerialized = sessionStorage.getItem(key);

        if (valueSerialized === null) {
            return undefined;
        }
        return JSON.parse(valueSerialized);
    } catch (err) {
        return undefined;
    }
};

/**
 * Loads and parses any previously saved redux state from sessionStorage.
 * If no previous state is present, returns undefined instead of failing.
 */
export const _loadSession = () => _loadFromSessionStorage(_STORAGE_KEY);

/**
 * Serializes and saves to sessionStorage the passed in redux state.
 * @param {*} state the state to save.
 */
export const saveSession = (state) => {
    _storeInSessionStorage(_STORAGE_KEY, state);
};

/**
 * Clear any existing redux state from sessionStorage.
 */
export const clearSession = () => {
    try {
        sessionStorage.removeItem(_STORAGE_KEY);
    } catch (err) {
        /* Ignore write errors: expected to happen on server side rendering. */
    }
};

/**
 * Retrieve and clear any existing state from sessionStorage
 * Also refreshes the tokens to be able to call apiv3
 */
export const popSession = () => {
    const state = _loadSession();
    setTokensFromState(state);

    clearSession();
    return state;
};
