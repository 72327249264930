import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { translationPropType } from '@eb/i18n';
import { lazyGettext } from '@eb/lazy-gettext';
import classNames from 'classnames';

import FieldWrapper from './FieldWrapper';
import ListContainer from './_internals/ListContainer';
import { Icon } from '@eb/eds-icon';
import SelectSuffix from './_internals/SelectSuffix';
import { getInitialValues } from './utils';
import { getAdditionalProps } from '@eb/eds-utils';

import './inputField.scss';
import './multiSelect.scss';

import * as constants from './constants';

import { ChevronDownChunky } from '@eb/eds-iconography';

/** Build up the list of hidden optionComponents and determine the selected
 * display values in one iteration. We're determining this derived value here
 * instead of storing it in state
 */
const _getHiddenOptions = (
    placeholder,
    values,
    selectedValues,
    initialValues,
) => {
    const selectedDisplay = [];
    let selectedDisplayString = placeholder;

    const optionComponents = values.map(({ value, display }) => {
        let disabled;
        let optionValue;

        // setting this value is a side effect of the single iteration
        if (selectedValues.includes(value)) {
            selectedDisplay.push(display);
        }

        if (!value) {
            disabled = 'disabled';
        }

        if (initialValues.includes(value)) {
            optionValue = value;
        }

        return (
            <option
                disabled={disabled}
                key={value}
                value={optionValue}
                data-spec="select-option"
            >
                {display}
            </option>
        );
    });

    if (selectedDisplay.length > 0) {
        selectedDisplayString = selectedDisplay.join(', ');
    }

    return { selectedDisplayString, optionComponents };
};

const MultiselectElement = ({
    disabled,
    hasError,
    id,
    label,
    required,
    name,
    selectedValues,
    initialValues,
    values,
    placeholder,
    suffix,
    isActive,
    shouldFullscreen,
    shouldShowSelectAll,
    shouldShowApplyCancelFooter,
    footerContent,
    onArrowClick,
    onFocus,
    onBlur,
    onItemChange,
    onCancelClick,
    onApplyClick,
    onSelectAllChange,
    dataAutomation,
    ...extraAttrs
}) => {
    const { selectedDisplayString, optionComponents } = _getHiddenOptions(
        placeholder,
        values,
        selectedValues,
        initialValues,
    );
    const inputClassNames = classNames(
        'eds-field-styled__select-wrapper eds-field-multiselect',
        {
            'eds-field-multiselect--list-attached': !shouldFullscreen,
        },
    );
    let interactiveProperties = {};

    if (!disabled) {
        interactiveProperties = {
            onFocus,
            onBlur,
            tabIndex: 0,
        };
    }

    return (
        <div
            id={id}
            role="listbox"
            aria-multiselectable="true"
            aria-haspopup="true"
            aria-expanded={isActive}
            aria-labelledby={`${id}-label ${id}-values-label`}
            className={inputClassNames}
            data-spec="multiselect-field"
            {...interactiveProperties}
        >
            <div className="eds-field-styled__select-value">
                <span className="eds-field-styled__select-value-text">
                    <span
                        className="eds-is-hidden-accessible"
                        id={`${id}-values-label`}
                    >
                        {lazyGettext('Selected: %(selectedDisplayString)s', {
                            selectedDisplayString,
                        })}
                    </span>
                    {selectedDisplayString}
                </span>
                <SelectSuffix suffix={suffix} />
                <span
                    className="eds-field-styled__select-icon"
                    onClick={onArrowClick}
                    role="button"
                >
                    <Icon type={<ChevronDownChunky />} />
                </span>
            </div>

            <ListContainer
                parentId={id}
                label={label}
                values={values}
                selectedValues={selectedValues}
                shouldShowSelectAll={shouldShowSelectAll}
                shouldShowApplyCancelFooter={shouldShowApplyCancelFooter}
                footerContent={footerContent}
                shouldFullscreen={shouldFullscreen}
                onApplyClick={onApplyClick}
                onCancelClick={onCancelClick}
                onSelectAllChange={onSelectAllChange}
                onItemChange={onItemChange}
                isActive={isActive}
            />

            <select
                aria-invalid={hasError}
                aria-required={required}
                className="eds-field-multiselect__select"
                data-automation={dataAutomation}
                value={selectedValues}
                disabled={disabled}
                name={name}
                multiple={true}
                readOnly={true}
                {...extraAttrs}
            >
                {optionComponents}
            </select>
        </div>
    );
};

export default class MultiselectField extends PureComponent {
    static propTypes = {
        /**
         * Text to be displayed as the label of the select element.
         * Required for accessibility reasons but it can be hidden.
         */
        label: PropTypes.node.isRequired,

        /**
         * Array of formatted option values
         */
        values: constants.VALUES_PROP_TYPE.isRequired,

        /**
         * Text to be displayed as annotation below the select element.
         */
        annotationNote: PropTypes.node,

        /**
         * The amount of spacing for the bottom
         */
        bottomSpacing: PropTypes.number,

        /**
         * Default selected values of multiselect element.
         * This is the initial value.
         * After the select input has been rendered the first time,
         * if the default selected value changes, it will *not* update the input.
         */
        defaultSelectedValues: PropTypes.arrayOf(PropTypes.string),

        /**
         * Sets disabled attribute to the select element.
         */
        disabled: PropTypes.bool,

        /**
         * Field style chosen for the select.
         */
        style: PropTypes.oneOf(constants.SELECT_STYLES),

        /**
         * Sets error state (adds error classes and aria-invalid).
         */
        hasError: PropTypes.bool,

        /**
         * Sets the id for the select element and htmlFor attribute for the label.
         */
        id: PropTypes.string,

        /**
         * Marks the select as required (adds * and aria-required).
         */
        required: PropTypes.bool,

        /**
         * Name of select element.
         */
        name: PropTypes.string,

        /**
         * {function} onBlur
         * By default sets isActive state to false and delegates the control to
         * the onBlur function passed as prop.
         */
        onBlur: PropTypes.func,

        /**
         * {function} onChange
         * By default sets the select value to the state and delegates
         * the control to the onChange function passed as prop.
         */
        onChange: PropTypes.func,

        /**
         * {function} onFocus
         * By default sets isActive state to true and delegates the control to
         * the onFocus function passed as prop.
         */
        onFocus: PropTypes.func,

        /**
         * Text to be displayed when no option is selected.
         */
        placeholder: translationPropType,

        /**
         * Node to be displayed to the right of the select field but to the left of the chevron.
         */
        suffix: PropTypes.node,

        /**
         * Changes the list container to cover the whole page similar to the mobile version.
         * This is encouraged to be used only inside a FocusDrawer/FocusPanel
         */
        shouldFullscreen: PropTypes.bool,

        /**
         * Enables an extra option to select all the options in the multi select component.
         */
        shouldShowSelectAll: PropTypes.bool,

        /**
         * Disables Apply/Cancel footer button. NOTE: This changes the behavior of the component to no longer
         * revert changes onBlur.
         */
        shouldShowApplyCancelFooter: PropTypes.bool,

        /**
         * Override the Apply/Cancel button with a footer of yours. Usage of this will also set
         * shouldShowApplyCancelFooter to false.
         */
        footerContent: PropTypes.node,

        /**
         * {function} onClick
         * Click function of select cancel button
         */
        onCancelButtonClick: PropTypes.func,

        /**
         * {function} onClick
         * Click function of select apply button
         */
        onApplyButtonClick: PropTypes.func,

        /**
         * Identifier for tests.
         */
        'data-spec': PropTypes.string,
        /**
         * Identifier for automation tests
         */
        'data-automation': PropTypes.string,
    };

    static defaultProps = {
        bottomSpacing: 2,
        defaultSelectedValues: [],
        shouldShowApplyCancelFooter: true,
        style: constants.STYLE_STATIC,
        'data-spec': 'select-field',
    };

    constructor(props) {
        super(props);

        const { defaultSelectedValues, placeholder, values } = props;
        const initialValues = getInitialValues(
            defaultSelectedValues,
            values,
            placeholder,
        );

        this.state = {
            isActive: false,
            selectedValues: initialValues,
            initialValues,
            isFocused: false,
        };
    }

    _hasActiveApplyCancelFooter() {
        const { shouldShowApplyCancelFooter, footerContent } = this.props;

        return shouldShowApplyCancelFooter && !footerContent;
    }

    _handleFocusSelect() {
        const { disabled, onFocus } = this.props;
        const { isActive } = this.state;

        if (!disabled && !isActive) {
            this.setState({ isActive: true });

            if (onFocus) {
                onFocus(this.state.isActive);
            }
        }
    }

    _handleItemChange(value) {
        const { disabled, onChange } = this.props;

        if (!disabled) {
            this.setState(
                ({ selectedValues }) => {
                    let updatedValues;

                    if (selectedValues.includes(value)) {
                        updatedValues = selectedValues.filter(
                            (item) => item !== value,
                        );
                    } else {
                        updatedValues = [...selectedValues, value];
                    }

                    return {
                        selectedValues: updatedValues,
                    };
                },
                () => {
                    if (onChange) {
                        onChange(this.state.selectedValues);
                    }
                },
            );
        }
    }

    _handleCancel() {
        const { disabled, onCancelButtonClick } = this.props;
        const { initialValues } = this.state;

        if (!disabled) {
            const newState = { isActive: false };

            if (this._hasActiveApplyCancelFooter()) {
                newState.selectedValues = initialValues;
                if (onCancelButtonClick) {
                    onCancelButtonClick(...[initialValues]);
                }
            }
            this.setState(newState);
        }
    }

    _handleApply() {
        const { disabled, onApplyButtonClick } = this.props;
        const { selectedValues } = this.state;

        if (!disabled && this._hasActiveApplyCancelFooter()) {
            this.setState({
                isActive: false,
                initialValues: selectedValues,
            });
            if (onApplyButtonClick) {
                onApplyButtonClick(...[selectedValues]);
            }
        }
    }

    _handleSelectAllChange(values) {
        const { disabled, onChange } = this.props;

        if (!disabled) {
            this.setState(
                ({ selectedValues }) => {
                    const _values = values.map(({ value }) => value);

                    return {
                        selectedValues:
                            _values.length === selectedValues.length
                                ? []
                                : _values,
                    };
                },
                () => {
                    if (onChange) {
                        onChange(this.state.selectedValues);
                    }
                },
            );
        }
    }

    _handleBlurSelect(event) {
        const { disabled, onBlur } = this.props;

        if (!disabled) {
            // We want to hide the ListContainer only when the focus is outside the whole component.
            // Idea extracted from https://gist.github.com/pstoica/4323d3e6e37e8a23dd59
            const currentTarget = event.currentTarget;
            const blurList = () => {
                if (!currentTarget.contains(document.activeElement)) {
                    this._handleCancel();
                }
            };

            setTimeout(blurList.bind(this), 0);

            if (onBlur) {
                onBlur(this.state.selectedValues);
            }

            this.setState({ isFocused: false });
        }
    }

    _handleSelectArrowClick() {
        const { isActive, isFocused } = this.state;
        if (isFocused) {
            this.setState({ isActive: !isActive });
        }
        this.setState({ isFocused: true });
    }

    render() {
        const {
            annotationNote,
            bottomSpacing,
            disabled,
            hasError,
            id,
            label,
            required,
            name,
            placeholder,
            suffix,
            style,
            values,
            shouldFullscreen,
            shouldShowSelectAll,
            shouldShowApplyCancelFooter,
            footerContent,
            'data-spec': dataSpec,
            'data-automation': dataAutomation,
        } = this.props;
        const { isActive, selectedValues, initialValues } = this.state;
        const extraAttrs = getAdditionalProps(this);
        const wrapperDataAutomation = dataAutomation
            ? `${dataAutomation}-wrapper`
            : 'select-field-wrapper';
        let activeAttrs;

        if (isActive) {
            activeAttrs = {
                onBlur: this._handleBlurSelect.bind(this),
                onItemChange: this._handleItemChange.bind(this),
                onCancelClick: this._handleCancel.bind(this),
                onApplyClick: this._handleApply.bind(this),
                onSelectAllChange: this._handleSelectAllChange.bind(
                    this,
                    values,
                ),
            };
        }

        return (
            <FieldWrapper
                annotationNote={annotationNote}
                bottomSpacing={bottomSpacing}
                disabled={disabled}
                hasError={hasError}
                id={id}
                isActive={isActive}
                label={label}
                required={required}
                style={style}
                selectedValues={selectedValues}
                dataSpec={dataSpec}
                dataAutomation={wrapperDataAutomation}
            >
                <MultiselectElement
                    {...extraAttrs}
                    dataAutomation={dataAutomation}
                    isActive={isActive}
                    disabled={disabled}
                    hasError={hasError}
                    id={id}
                    label={label}
                    name={name}
                    required={required}
                    selectedValues={selectedValues}
                    initialValues={initialValues}
                    values={values}
                    placeholder={placeholder}
                    shouldShowSelectAll={shouldShowSelectAll}
                    shouldFullscreen={shouldFullscreen}
                    shouldShowApplyCancelFooter={shouldShowApplyCancelFooter}
                    footerContent={footerContent}
                    onFocus={this._handleFocusSelect.bind(this)}
                    onArrowClick={this._handleSelectArrowClick.bind(this)}
                    suffix={suffix}
                    {...activeAttrs}
                />
            </FieldWrapper>
        );
    }
}
