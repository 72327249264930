import { makeValidator } from '@eb/eds-utils';

export default makeValidator({
    // XXX: Yeah this seems crazy. In order to use redux-form's form.submitting and form.SubmitSucceeded
    // properties to disable the checkout button on submit, we need to pass a validate function to
    // formValidate(TICKETS_PAGE_FORM_NAME). But we don't have proper validation set up for the ticket selects yet.
    // So just passing it a nonexistent field for now.
    //
    // There is a Major Priority bug open for this: EB-47911
    dummyValidationField: {
        validator: () => true,
        errorMessage: '',
    },
});
