import * as React from 'react';

const IdealPaymentSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 48 32" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFF"
            d="M3 31.5C1.6 31.5.5 30.4.5 29V3C.5 1.6 1.6.5 3 .5h42c1.4 0 2.5 1.1 2.5 2.5v26c0 1.4-1.1 2.5-2.5 2.5H3z"
        />
        <path
            fill="#CB0F68"
            d="M45 1c1.1 0 2 .9 2 2v26c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h42m0-1H3C1.3 0 0 1.3 0 3v26c0 1.7 1.3 3 3 3h42c1.7 0 3-1.3 3-3V3c0-1.7-1.3-3-3-3z"
        />
        <path d="M25.9 5H11.4v22h14.5c3.2 0 5.7-.8 7.5-2.3 2.2-1.8 3.3-4.8 3.3-8.6 0-1.9-.3-3.5-.9-5-.5-1.4-1.4-2.5-2.4-3.5-1.9-1.7-4.5-2.5-7.5-2.6zm-.3 1.5c2.8 0 5.2.8 6.8 2.2 1.8 1.6 2.8 4.1 2.8 7.3 0 6.4-3.1 9.5-9.5 9.5H12.9V6.4c1.2.1 12.2.1 12.7.1z" />
        <path
            fill="#CD0067"
            d="M25.2 24.1h-5.1V8.2h5.1-.2c4.3 0 8.8 1.7 8.8 8 0 6.6-4.6 7.9-8.8 7.9h.2z"
        />
        <path
            fill="#FFF"
            d="M31.1 16.2v-3.8h.8v3.1h1.9v.6h-2.7zm-.6 0h-.8l-.3-.9h-1.5l-.3.9h-.8l1.5-3.8h.7l1.5 3.8zm-1.4-1.6l-.5-1.4-.6 1.4h1.1zm-5.8 1.6v-3.8h2.8v.6H24v.8h2v.6h-2v1h2.1v.6h-2.8zm-4.1-3.9h1.4c.3 0 .6 0 .7.1.2.1.4.2.6.4.2.2.3.4.4.6.1.2.1.5.1.9 0 .3 0 .6-.1.8-.1.3-.2.5-.4.7-.1.1-.3.2-.5.3-.2.1-.4.1-.7.1h-1.4v-3.9zm.8.7v2.5h1.1c.1 0 .2-.1.3-.2.1-.1.2-.2.2-.4.1-.2.1-.4.1-.7 0-.3 0-.5-.1-.7-.1-.2-.1-.3-.2-.4-.2 0-.3-.1-.4-.1h-1z"
        />
        <path d="M18.2 24.1h-4v-6.7h4v6.7zm-2-12.4c-1.3 0-2.3 1-2.3 2.3 0 1.3 1 2.3 2.3 2.3 1.3 0 2.3-1 2.3-2.3 0-1.2-1-2.3-2.3-2.3z" />
    </svg>
);

IdealPaymentSvg.displayName = 'IdealPaymentSvg';
export default IdealPaymentSvg;
