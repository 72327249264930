import * as React from 'react';

const CreditCardBackSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 24 24" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 11h18v7H3v-7zm0-5h18v3H3V6zM2 19h20V5H2v14z"
        />
        <g fillRule="evenodd" clipRule="evenodd">
            <path d="M4 15h2v1H4zM8 15h6v1H8z" />
        </g>
    </svg>
);

CreditCardBackSvg.displayName = 'CreditCardBackSvg';
export default CreditCardBackSvg;
