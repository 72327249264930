import has from 'lodash/has';
import some from 'lodash/some';

import { Schema } from 'normalizr';
import { transformUtil } from '@eb/transformation-utils';

const WAITING_ROOM_SCHEMA = new Schema('waitingRoom');

export interface WaitingRoomTokenResponse {
    allocation_time: string;
    client_id: string;
    hash_code: string;
    is_live: boolean;
    queue_id: string;
}

export interface WaitingRoomResponse extends WaitingRoomTokenResponse {
    event_id: string;
    is_activated_for_event: boolean;
    queue_server: string;
    register_server: string;
    step: string;
}

export interface WaitingRoom {
    allocationTime: string;
    clientId: string;
    eventId: string;
    hashCode: string;
    isActivatedForEvent: boolean;
    isLive: boolean;
    queueId: string;
    queueServer: string;
    registerServer: string;
    step: string;
}

const WAITING_ROOM_RESPONSE_FIELDS = [
    'allocation_time',
    'client_id',
    'event_id',
    'hash_code',
    'is_live',
    'queue_id',
    'queue_server',
    'register_server',
    'is_activated_for_event',
    'step',
];

const WAITING_ROOM_TOKEN_FIELDS = [
    'client_id',
    'queue_id',
    'is_live',
    'allocation_time',
    'hash_code',
];

/**
 * Transforms the service response into a map by ID, performs a whiteList to remove unwanted fields,
 * and finally applies camelcase to each key.
 **/
export const transformWaitingRoomData = (data: WaitingRoomResponse) => {
    const transformedWaitingRoom = transformUtil({
        response: data,
        schema: WAITING_ROOM_SCHEMA,
        fields: WAITING_ROOM_RESPONSE_FIELDS,
        isWhiteList: true,
    });

    if (!transformedWaitingRoom) {
        return null;
    }

    const waitingRoom = transformedWaitingRoom.entities.waitingRoom[
        transformedWaitingRoom.result
    ] as WaitingRoom;

    return {
        waitingRoom,
    };
};

/**
 * During the visit step, transform the waiting room api response to a token,
 * which will be set in the token header and be used for create order api call.
 **/
export const transformWaitingRoomDataToToken = (
    data: WaitingRoomTokenResponse,
): string => {
    // Make sure only generate the token string if all required field exist.
    const hasMissingField = some(
        WAITING_ROOM_TOKEN_FIELDS,
        (field) => !has(data, field),
    );

    if (hasMissingField) {
        return '';
    }

    const tokenFields = [
        data.client_id,
        data.queue_id,
        data.is_live ? 'true' : 'false',
        data.allocation_time,
        data.hash_code,
    ];

    return tokenFields.join(':');
};
