import packageJson from '../../package.json';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import omit from 'lodash/omit';
import set from 'lodash/set';
import times from 'lodash/times';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import { retry } from '@eb/retry';
import gettext from '@eb/gettext';
import { fetchV3WithTranslateServerErrors, getOrderFlowHeaders } from './base';
import { getTokens } from '../actions/tokens';
import { getCSRFToken, translateArgumentErrors } from '@eb/http';
import logger from '@eb/logger-bugsnag';
import { keysCamelToSnake, deepKeysToSnake } from '@eb/transformation-utils';
import { PaymentMethodType, InstrumentType } from '../models/paymentMethod';
import {
    formatTicketsForOrderSummary,
    isDonationTicket,
    isAddonTicket,
} from '../utils/tickets';
import {
    getSurveyAnswers,
    SURVEY_ERROR_SPEC,
    translateDynamicSurveyErrors,
    transformAddressForApi,
} from '../utils/surveys';
import { isShippingInDeliveryMethodsPayload } from '../utils/deliveryMethod';
import { getSelectedVirtualIncentivesAggregatedTotal } from '../utils/virtualIncentives';
import {
    FULFILLMENT_ADDRESS,
    PAYMENT_METHODS,
    SQ_ANALYTICS_TOKEN,
} from '../constants';
import {
    CREDIT_CARD_FIELD_NAME,
    CARD_EXPIRATION_DATE_FIELD_NAME,
    CARD_SECURITY_CODE_FIELD_NAME,
    CARD_POSTAL_CODE_FIELD_NAME,
} from '@eb/eds-payment-info-fields';
import {
    BEST_AVAILABLE_NOT_FOUND_ERROR,
    _getAbandonOrderUrl,
    _getAvailablePaymentMethodsUrl,
    _getCreateOrderUrl,
    _getGenerateGuestTokenUrl,
    _getOrderUrl,
    _getOrderAttendeesUrl,
    _getOrderEligibilityUrl,
    _getPlaceOrderUrl,
    _getResendEmailUrl,
    _getSavePaymentMethodUrl,
    _getClearPaymentMethodUrl,
    _getUpdateOrderUrl,
    getPaymentHistoryUrl,
    getLastPaymentStatusUrl,
    _updateEmailPreferencesUrl,
} from './constants';
import {
    PAYMENT_IDENTIFICATION_METHOD,
    PAYMENT_IDENTIFICATION_NUMBER,
} from '../components/paymentMethodForms/constants';
import { INTERNAL_SERVER_ERROR } from '../containers/checkout/constants';
import { isIdentificationRequiredForCurrency } from '../models/currency';

/**
 * How to handle errors on the order lifecycle
 */
const virtualIncentivesErrorText = gettext(
    'We cannot use your credits at this time. Please use a different payment method to complete your order.',
);
const ORDER_ERROR_SPEC = {
    default: () =>
        gettext('There was a problem submitting your order. Please try again.'),

    // We receive a missing order user error when we have trouble either getting a logged-in user from the request
    // or creating a user with a new email address. Usually that means the email address is missing
    MISSING_ORDER_USER: () =>
        gettext(
            'There was a problem with your email address. Please review your basic info',
        ),

    INVALID_PAYMENT_REQUEST: () =>
        gettext(
            'There were errors with your payment. Please review your payment details',
        ),
    INVENTORY_ERROR: (parsedError) => {
        if (
            get(parsedError, 'description.ERROR_NO_MATCH_FOUND') ===
            BEST_AVAILABLE_NOT_FOUND_ERROR
        ) {
            return BEST_AVAILABLE_NOT_FOUND_ERROR;
        }

        return gettext('The tickets you selected are no longer available');
    },
    UNITS_NOT_AVAILABLE_PROMO_CODE: () =>
        gettext(
            'Your requested ticket quantity exceeds the number provided by your promotional code.',
        ),
    UNKNOWN_INVENTORY_ERROR: () =>
        gettext('There was a problem submitting your order. Code 17'),
    ORDER_EXPIRED: () => gettext('Your order has expired'),
    REDIRECT_PAYMENT_REQUEST: () => 'REDIRECTED',
    DECLINED: () => gettext('Your payment was declined'),
    PAYMENT_DECLINED: () => gettext('Your payment was declined'),
    ARGUMENTS_ERROR: translateArgumentErrors({
        default: translateDynamicSurveyErrors(SURVEY_ERROR_SPEC),
    }),
    TICKETS_CONSTRAINTS_MUTUALLY_EXCLUDED: () =>
        gettext('Please select tickets with the same payment method.'),
    TEAM_INVALID_CAPACITY: () =>
        gettext(
            "The group you are registering for doesn't have enough available spaces.",
        ),
    ERROR_ATTENDEE_CREDIT_PAYMENT_FAILED: () => virtualIncentivesErrorText,
    ATTENDEE_CREDIT_PAYMENT_FAILED: () => virtualIncentivesErrorText,
    NOT_ENOUGH_VI_BALANCE: () => virtualIncentivesErrorText,
    MALFORMED_SINGLE_PAYMENT_METHOD: () =>
        gettext(
            'The price of your order has changed. Please start a new order to get the correct price',
        ),
};

const _fetchV3WithOrderErrorHandling = (url, options = {}) =>
    fetchV3WithTranslateServerErrors(ORDER_ERROR_SPEC, url, options);

/**
 * Return a Promise which is either a resolved Promise that returns
 * an empty object or a `fetch` Promise which returns an object with
 * the key `guest_token` and value is an associated signed guest
 * token.
 */
export const generateGuestToken = (eventId) => {
    let guestTokenPromise = Promise.resolve({
        response: {},
        headers: {},
    });

    if (!getCSRFToken()) {
        // No CSRFToken cookie is present. This means we're in a
        // browser that won't let us set cookies.
        guestTokenPromise = _fetchV3WithOrderErrorHandling(
            _getGenerateGuestTokenUrl(),
            {
                method: 'POST',
                body: JSON.stringify({ event_id: eventId }),
            },
        );
    }

    return guestTokenPromise;
};

/**
 * Return a `fetch` Promise to create the order
 *
 * @param {object} order info object that has the eventId and a map of the selectedTickets
 */
export const createOrder = ({
    affiliateCode,
    eventId,
    inviteToken,
    campaignToken,
    promoCode,
    referrerId,
    selectedTickets,
    teamToken,
    waitlistCode,
}) => {
    const tickets = [];

    formatTicketsForOrderSummary(selectedTickets).forEach((selectedTicket) => {
        const { selectedQuantity, id, isVariant, cost } = selectedTicket;
        let ticket = {
            ticket_class_id: id,
        };

        if (isVariant) {
            ticket = {
                variant_id: id,
            };
        }

        if (isDonationTicket(selectedTicket) && cost) {
            ticket['donation_amount'] = {
                currency: cost.currency,
                value: cost.value,
            };
        }

        if (teamToken && !isAddonTicket(selectedTicket)) {
            ticket['team_token'] = teamToken;
        }

        times(selectedQuantity, () => tickets.push(ticket));
    });

    const postParams = {
        method: 'POST',
        headers: getOrderFlowHeaders(getTokens()),

        body: JSON.stringify({
            tickets,
            affiliate_code: affiliateCode,
            application: 'embedded_web',
            event_id: eventId,
            promo_code: promoCode,
            recipient_invite_token: inviteToken,
            referrer_id: referrerId,
            team_token: teamToken,
            waitlist_code: waitlistCode,
            campaign_token: campaignToken,
        }),
    };

    return _fetchV3WithOrderErrorHandling(_getCreateOrderUrl(), postParams);
};

/**
 * Returns a `fetch` Promise to get the order's attendees
 * We need to call the endpoint and cycle through the pages
 * until we've received all attendees in the order.
 *
 * @param {string} orderId
 * @param {string=} expansion provide this param if want to customize the `?expand` expansion
 */
export const getOrderAttendees = async (orderId, expansion = 'survey') => {
    let allAttendees = [];
    let page = 1;
    let hasMoreItems;

    do {
        const payload = await _fetchV3WithOrderErrorHandling(
            _getOrderAttendeesUrl(orderId, { expansion, page }),
            {
                headers: getOrderFlowHeaders(getTokens()),
            },
        );

        hasMoreItems = get(
            payload,
            'response.pagination.has_more_items',
            false,
        );
        allAttendees = [...allAttendees, ...payload.response.attendees];
        page++;
    } while (hasMoreItems);

    return allAttendees;
};

/**
 * Return a `fetch` Promise to get the available payment methods for a certain order
 *
 * @param {string} eventId The eventId of the event.
 * @param {string} orderId The orderId of the order.
 */
export const getAvailablePaymentMethods = (eventId, orderId) =>
    _fetchV3WithOrderErrorHandling(
        _getAvailablePaymentMethodsUrl(eventId, orderId),
        {
            headers: getOrderFlowHeaders(getTokens()),
        },
    );

/**
 * Return a `fetch` Promise to update the order
 *
 * @param {string} orderId              order id to be updated
 * @param {object} formData             form data provided by checkout form
 * @param {object} deliveryMethods      delivery methods to save, if any
 */
export const saveOrder = ({ orderId, formData, deliveryMethods = null }) => {
    const formDataSurveyAnswers = omit(
        formData,
        'paymentMethod',
        'payment',
        'bancontact',
        'vaultId',
        'userInstrument',
        `buyer.${FULFILLMENT_ADDRESS}`,
    );
    const surveyAnswers = getSurveyAnswers(formDataSurveyAnswers);
    const orderInfo = {
        order: {
            answers: surveyAnswers,
        },
    };

    if (deliveryMethods) {
        set(orderInfo, 'order.delivery_methods', deliveryMethods);
    }

    const fulfillmentAddress =
        isShippingInDeliveryMethodsPayload(deliveryMethods) && formData.buyer
            ? formData.buyer[FULFILLMENT_ADDRESS]
            : {};

    if (!isEmpty(fulfillmentAddress)) {
        set(
            orderInfo,
            'order.fulfillment_address',
            transformAddressForApi(fulfillmentAddress),
        );
    }

    const body = JSON.stringify(orderInfo);

    return _fetchV3WithOrderErrorHandling(_getUpdateOrderUrl(orderId), {
        method: 'POST',
        headers: getOrderFlowHeaders(getTokens()),
        body,
    });
};

const _payloadForIdeal = (payment) => ({
    payment_methods: [
        {
            payment_instrument_details: {
                instrument_type: 'BANK',
                payment_method: PAYMENT_METHODS.IDEAL,
                bank_id: payment.bankId,
            },
        },
    ],
});

const _payloadForNonceBraintree = (payment) => ({
    payment_methods: [
        {
            payment_instrument_details: {
                nonce: payment.paypalBraintreeNonce,
                instrument_type: 'NONCE_BRAINTREE',
            },
        },
    ],
});

/**
 * Reformats expiration date input from MM/YY to MMYY,
 * how order service is expecting to receive it.
 */
const _reformatCardExpirationDate = (expirationDate) => {
    if (expirationDate && expirationDate.indexOf('/') >= 0) {
        let [month, year] = expirationDate.split('/');

        // Pad month with a leading 0 if it's only one digit. We allow
        // users to input M/YY but the backend always expects MM/YY.
        month = month.length === 1 ? `0${month}` : month;
        return [month, year].join('');
    }
    return undefined;
};

const _payloadForMaestroBancontact = (bancontact) => {
    let cardNumber;
    let expirationDate;

    if (bancontact) {
        cardNumber = bancontact.creditCardNumber;
        expirationDate = _reformatCardExpirationDate(
            bancontact.cardExpirationDate,
        );
    }

    const creditCardData = keysCamelToSnake({
        cardNumber,
        expirationDate,
    });

    return {
        payment_methods: [
            {
                payment_instrument_details: {
                    instrument_type: 'CREDIT',
                    payment_method: PAYMENT_METHODS.MAESTRO_BANCONTACT,
                    ...creditCardData,
                },
            },
        ],
    };
};

const _payloadForSofort = () => ({
    payment_methods: [
        {
            payment_instrument_details: {
                instrument_type: 'BANK',
                payment_method: PAYMENT_METHODS.SOFORT,
            },
        },
    ],
});

const _payloadForBoletoBancario = ({
    identificationNumber,
    identificationMethod,
}) => ({
    payment_methods: [
        {
            payment_instrument_details: {
                instrument_type: PAYMENT_METHODS.BOLETO_BANCARIO,
                tax_identifier: identificationNumber,
                tax_identifier_type: identificationMethod,
            },
        },
    ],
});

const _payloadForBarcode = ({ variant, identificationNumber }) => ({
    payment_methods: [
        {
            payment_instrument_details: {
                instrument_type: PAYMENT_METHODS.BARCODE,
                payment_method: variant,
                tax_identifier: identificationNumber,
            },
        },
    ],
});

const _defaultPayload = (paymentType) => ({
    payment_methods: [
        {
            payment_instrument_details: {
                instrument_type: paymentType,
                payment_method: paymentType,
            },
        },
    ],
});

const _payloadForSepa = (payment) => {
    let sepaData;

    if (payment && payment.ibanAgreement) {
        const ownerName = payment.ownerName;
        const iban = payment.iban;

        sepaData = keysCamelToSnake({
            ownerName,
            iban,
        });
    }

    return {
        payment_methods: [
            {
                payment_instrument_details: {
                    instrument_type: 'BANK_ACCOUNT_SEPA',
                    payment_method: PAYMENT_METHODS.SEPA_DIRECT_DEBIT,
                    ...sepaData,
                },
            },
        ],
    };
};

const _buildBillingInformation = (
    countryCode,
    {
        [CARD_POSTAL_CODE_FIELD_NAME]: postalCode,
        [PAYMENT_IDENTIFICATION_NUMBER]: number,
        [PAYMENT_IDENTIFICATION_METHOD]: identificationType,
    },
    currency,
) => {
    const result = {
        address: keysCamelToSnake({
            postalCode,
            // XXX: countryCode is currently based on event venue, or TLD as
            // default. It should be changed to user billing information once
            // that data is collected
            country: countryCode,
        }),
    };

    if (isIdentificationRequiredForCurrency[currency]) {
        result.identification = keysCamelToSnake({
            number,
            identificationType,
        });
    }

    return result;
};

const currenciesWithExtraParams = ['BRL', 'ARS', 'MXN'];

export const formatCreditCardInstrument = ({
    countryCode,
    payment,
    currency,
    instrumentType,
}) => {
    let cardNumber;
    let cvv;
    let expirationDate;
    let analyticsToken;
    let billingInformation = {
        address: {},
    };
    const extraParams = {};

    if (payment) {
        cardNumber = payment[CREDIT_CARD_FIELD_NAME];
        cvv = payment[CARD_SECURITY_CODE_FIELD_NAME];
        expirationDate = _reformatCardExpirationDate(
            payment[CARD_EXPIRATION_DATE_FIELD_NAME],
        );
        billingInformation = _buildBillingInformation(
            countryCode,
            payment,
            currency,
        );
        analyticsToken = payment[SQ_ANALYTICS_TOKEN]
            ? payment[SQ_ANALYTICS_TOKEN]
            : null;
    }

    if (currenciesWithExtraParams.includes(currency)) {
        extraParams.cardholderName = payment.cardholder;
        if (payment.selectIssuer > 0) {
            extraParams.issuingBank = payment.selectIssuer;
            if (payment.installments) {
                extraParams.installments = payment.installments;
            }
        }
    }

    const instrument = keysCamelToSnake({
        instrumentType,
        cvv,
        cardNumber,
        expirationDate,
        analyticsToken,
        ...extraParams,
    });

    return {
        instrument,
        billingInformation,
    };
};

const _payloadForCredit = ({ countryCode, payment, currency }) => {
    const { instrument, billingInformation } = formatCreditCardInstrument({
        countryCode,
        payment,
        currency,
        instrumentType: PAYMENT_METHODS.CREDIT,
    });

    return {
        payment_methods: [
            {
                payment_instrument_details: instrument,
                billing_information: billingInformation,
            },
        ],
    };
};

const _payloadForAuthnet = ({ countryCode, payment, currency }) => {
    const { instrument, billingInformation } = formatCreditCardInstrument({
        countryCode,
        payment,
        currency,
        instrumentType: PAYMENT_METHODS.AUTHNET,
    });

    return {
        payment_methods: [
            {
                payment_instrument_details: instrument,
                billing_information: billingInformation,
            },
        ],
    };
};

const _payloadForUserInstrument = (userInstrument) => ({
    payment_methods: [
        {
            payment_instrument_details: deepKeysToSnake(userInstrument),
        },
    ],
});

/**
 * Returns the payload used to pay with a virtual incentives card
 *
 * @param {object} params
 * @param {Array<VirtualIncentive>} params.virtualIncentives
 * @param {number} params.totalAmountInCents
 */
const _payloadForVirtualIncentives = ({
    virtualIncentives,
    totalAmountInCents,
}) => {
    if (virtualIncentives.length === 0) {
        throw new Error('Virtual incentives array was empty');
    }

    const currency = virtualIncentives[0].balance.currency;
    const totalValue = Math.min(
        getSelectedVirtualIncentivesAggregatedTotal(virtualIncentives),
        totalAmountInCents,
    );

    return {
        payment_methods: [
            {
                payment_instrument_details: {
                    instrument: {
                        instrument_type: InstrumentType.VIRTUAL_INCENTIVES_CARD,
                    },
                    instrument_type: PaymentMethodType.USER_INSTRUMENT,
                },
                amount: {
                    currency,
                    value: totalValue,
                },
            },
        ],
    };
};

const _getPayloadForSplitPayment = ({
    currency,
    totalAmountInCents,
    virtualIncentives,
    paymentMethodBodyBuilder,
}) => {
    const payloadForVirtualIncentives = _payloadForVirtualIncentives({
        virtualIncentives,
        totalAmountInCents,
    });

    const amountInCentsPayedWithVirtualIncentives =
        payloadForVirtualIncentives.payment_methods[0].amount.value;
    const amountInCentsPayedWithAdditionalPaymentMethod =
        totalAmountInCents - amountInCentsPayedWithVirtualIncentives;
    const additionalPaymentMethodPayload = paymentMethodBodyBuilder();

    const payloadForSplitPaymentMethods = [
        ...payloadForVirtualIncentives.payment_methods,
        {
            ...additionalPaymentMethodPayload.payment_methods[0],
            amount: {
                currency,
                value: amountInCentsPayedWithAdditionalPaymentMethod,
            },
        },
    ];

    return {
        payment_methods: payloadForSplitPaymentMethods,
    };
};

const _getPayloadForSinglePayment = ({
    currency,
    totalAmountInCents,
    virtualIncentives,
    paymentMethodBodyBuilder,
}) => {
    return virtualIncentives
        ? _payloadForVirtualIncentives({
              virtualIncentives,
              totalAmountInCents,
          })
        : {
              payment_methods: [
                  {
                      ...paymentMethodBodyBuilder().payment_methods[0],
                      amount: {
                          currency,
                          value: totalAmountInCents,
                      },
                  },
              ],
          };
};

const _getPayloadForPaymentMethod = ({
    bancontact,
    countryCode,
    currency,
    payment,
    paymentMethod,
    totalAmountInCents,
    userInstrument,
    virtualIncentives,
}) => {
    const payloadForPaymentMethod = {
        [PAYMENT_METHODS.BOLETO_BANCARIO]: () =>
            _payloadForBoletoBancario(payment),
        [PAYMENT_METHODS.RAPIPAGO]: () =>
            _payloadForBarcode({
                ...payment,
                variant: paymentMethod,
            }),
        [PAYMENT_METHODS.PAGOFACIL]: () =>
            _payloadForBarcode({
                ...payment,
                variant: paymentMethod,
            }),
        [PAYMENT_METHODS.BARCODE]: () => _payloadForBarcode(payment),
        [PAYMENT_METHODS.CASH]: () => _defaultPayload(PAYMENT_METHODS.CASH),
        [PAYMENT_METHODS.CHECK]: () => _defaultPayload(PAYMENT_METHODS.CHECK),
        [PAYMENT_METHODS.CREDIT]: () =>
            _payloadForCredit({ countryCode, payment, currency }),
        [PAYMENT_METHODS.AUTHNET]: () =>
            _payloadForAuthnet({ countryCode, payment, currency }),
        [PAYMENT_METHODS.FPP_PAYPAL]: () =>
            _defaultPayload(PAYMENT_METHODS.FPP_PAYPAL),
        [PAYMENT_METHODS.IDEAL]: () => _payloadForIdeal(payment),
        [PAYMENT_METHODS.INVOICE]: () =>
            _defaultPayload(PAYMENT_METHODS.INVOICE),
        [PAYMENT_METHODS.MAESTRO_BANCONTACT]: () =>
            _payloadForMaestroBancontact(bancontact),
        [PAYMENT_METHODS.NONCE_BRAINTREE]: () =>
            _payloadForNonceBraintree(payment),
        [PAYMENT_METHODS.SEPA_DIRECT_DEBIT]: () => _payloadForSepa(payment),
        [PAYMENT_METHODS.SOFORT]: () => _payloadForSofort(),
        [PAYMENT_METHODS.USER_INSTRUMENT]: () =>
            _payloadForUserInstrument(userInstrument),
    };

    const paymentMethodBodyBuilder =
        payloadForPaymentMethod[paymentMethod] ||
        payloadForPaymentMethod[PAYMENT_METHODS.CREDIT];
    if (!virtualIncentives) {
        return _getPayloadForSinglePayment({
            currency,
            totalAmountInCents,
            virtualIncentives,
            paymentMethodBodyBuilder,
        });
    }

    const amountInCentsPayedWithVirtualIncentives = getSelectedVirtualIncentivesAggregatedTotal(
        virtualIncentives,
    );
    const isAdditionalPaymentMethodNeededToPayCosts =
        totalAmountInCents > amountInCentsPayedWithVirtualIncentives;

    return isAdditionalPaymentMethodNeededToPayCosts
        ? _getPayloadForSplitPayment({
              currency,
              paymentMethodBodyBuilder,
              totalAmountInCents,
              virtualIncentives,
          })
        : _getPayloadForSinglePayment({
              currency,
              totalAmountInCents,
              virtualIncentives,
              paymentMethodBodyBuilder,
          });
};

/**
 * @param {Record<string, any>} object
 * @returns boolean
 */
const hasSomeUndefinedValue = (object) =>
    !!Object.keys(object).find((key) =>
        isPlainObject(object[key]) || isArray(object[key])
            ? hasSomeUndefinedValue(object[key])
            : object[key] === undefined,
    );

const PAYMENT_METHODS_REQUIRING_INFO_FOR_ORDER = {
    // Requires identification method
    [PAYMENT_METHODS.BOLETO_BANCARIO]: true,
    // Requires identification method
    [PAYMENT_METHODS.RAPIPAGO]: true,
    // Requires identification method
    [PAYMENT_METHODS.PAGOFACIL]: true,
    // Requires identification method
    [PAYMENT_METHODS.BARCODE]: true,
    // Requires card number, expiration date, etc
    [PAYMENT_METHODS.CREDIT]: true,
    // Requires card number, expiration date, etc
    [PAYMENT_METHODS.AUTHNET]: true,
    // Requires bank id
    [PAYMENT_METHODS.IDEAL]: true,
    // Requires card number, expiration date, etc
    [PAYMENT_METHODS.MAESTRO_BANCONTACT]: true,
    // Requires PayPal Braintree nonce
    [PAYMENT_METHODS.NONCE_BRAINTREE]: false,
    // Requires owner name and iban
    [PAYMENT_METHODS.SEPA_DIRECT_DEBIT]: true,
    // Requires user instrument
    [PAYMENT_METHODS.USER_INSTRUMENT]: true,
};

export const isAllInfoRequiredToSavePaymentMethodAvailable = ({
    countryCode,
    bancontact,
    payment,
    paymentMethod,
    totalAmountInCents,
    userInstrument,
    virtualIncentives,
    currency,
}) => {
    const body = _getPayloadForPaymentMethod({
        bancontact,
        countryCode,
        currency,
        payment,
        paymentMethod,
        totalAmountInCents,
        userInstrument,
        virtualIncentives,
    });

    return PAYMENT_METHODS_REQUIRING_INFO_FOR_ORDER[
        paymentMethod || PAYMENT_METHODS.CREDIT
    ]
        ? !hasSomeUndefinedValue(body)
        : true;
};

/**
 * Return a `fetch` Promise to save payment methods
 *
 * @param {string} orderId order id to be updated
 * @param {string} countryCode
 * @param {object} formData form data provided by checkout form
 * @param {string} currency
 */
export const savePaymentMethods = (
    orderId,
    countryCode,
    {
        bancontact,
        payment,
        paymentMethod,
        totalAmountInCents,
        userInstrument,
        virtualIncentives,
    },
    currency,
) => {
    const body = _getPayloadForPaymentMethod({
        bancontact,
        countryCode,
        currency,
        payment,
        paymentMethod,
        totalAmountInCents,
        userInstrument,
        virtualIncentives,
    });

    return _fetchV3WithOrderErrorHandling(_getSavePaymentMethodUrl(orderId), {
        method: 'POST',
        headers: {
            ...getOrderFlowHeaders(getTokens()),
            // TODO: EB-136121 Move this header to a common place for all requests
            'X-Checkout-AppVersion': packageJson.version,
        },
        body: JSON.stringify(body),
    });
};

/**
 * Removes order's payment methods
 *
 * @param {string} orderId order id to be updated
 */
export const clearPaymentMethods = (orderId) =>
    _fetchV3WithOrderErrorHandling(_getClearPaymentMethodUrl(orderId), {
        method: 'DELETE',
        headers: {
            ...getOrderFlowHeaders(getTokens()),
            // TODO: EB-136121 Move this header to a common place for all requests
            'X-Checkout-AppVersion': packageJson.version,
        },
    });

/**
 * Return a `fetch` Promise to place the order
 *
 * @param {string} orderId order id to be placed
 */
export const placeOrder = (orderId, body = {}) =>
    _fetchV3WithOrderErrorHandling(_getPlaceOrderUrl(orderId), {
        headers: getOrderFlowHeaders(getTokens()),
        method: 'POST',
        body: JSON.stringify(body),
    });

/**
 * Return a `fetch` Promise to place the order
 *
 * @note If server returns an unknown error 500 the request will be retried up to 5 times.
 *
 * @param {string} orderId order id to be placed
 */
// TODO: EB-130249 Remove this function once race condition is fixed
export const placeOrderRetrying = async (
    orderId,
    body = {},
    loggerAdditionalInfo = {},
) => {
    const isErrorRetryable = (error) =>
        error.extraData.response.status === 500 &&
        error.extraData.parsedError.error === INTERNAL_SERVER_ERROR;

    const setLoggerGroupingHash = (report) => {
        // eslint-disable-next-line no-param-reassign
        report.groupingHash = 'response-status-in-place-order';
    };

    const loggerOptions = {
        beforeSend: setLoggerGroupingHash,
    };

    /**
     * @param {number} timesAttempted
     */
    const attemptPlaceOrder = async (timesAttempted) => {
        const result = await placeOrder(orderId, body);

        if (timesAttempted > 0) {
            logger.info(
                `placeOrder retried and succeeded after ${timesAttempted} attempts`,
                {
                    ...loggerAdditionalInfo,
                    timesAttempted,
                    orderId,
                },
                loggerOptions,
            );
        }

        return result;
    };

    /**
     * @param {Error} error
     * @param {number} timesAttempted
     */
    const onFailedLastAttempt = (error, timesAttempted) => {
        const bugsnagErrorMessage = isErrorRetryable(error)
            ? `placeOrder failed after ${timesAttempted} attempts`
            : `placeOrder failed after ${timesAttempted} attempts due to an error that normally would not be retried`;

        logger.error(
            bugsnagErrorMessage,
            {
                ...loggerAdditionalInfo,
                timesAttempted,
                orderId,
                isErrorRetryable: isErrorRetryable(error),
            },
            loggerOptions,
        );
    };

    return retry(attemptPlaceOrder, {
        maxAttempts: 5,
        minDelayInMilliseconds: 2500,
        shouldRetry: isErrorRetryable,
        onFailedLastAttempt,
    });
};

const _handleResponse = (response) => {
    const responseHeaders = response.headers;

    return response.json().then((responseData) =>
        Promise.resolve({
            response: responseData,
            headers: responseHeaders,
        }),
    );
};

/**
 * Return a `fetch` Promise to update the order
 *
 * @param {string} orderId order id to be updated
 * @param {object} stateTokens tokens from state used to authorize order_service calls
 */
export const updateOrder = (orderId, stateTokens) => {
    // Prevent this ajax call from getting cached by adding a cache buster to the url
    const currentTime = new Date().getTime();
    const url = `${_getUpdateOrderUrl(orderId)}?cb=${currentTime}`;

    //TODO: EB-130741 we should not have to rely on locally saved tokens (getTokens())
    // when we have them on the state
    const tokens = stateTokens || getTokens();

    return _fetchV3WithOrderErrorHandling(url, {
        headers: getOrderFlowHeaders(tokens),
        method: 'GET',
    });
};

/**
 * Return a `fetch` Promise to retrieve payment history for an order.
 *
 * @param  {object} orderId order id to check payment history for
 */
export const getPaymentHistory = (orderId) => {
    // Prevent this ajax call from getting cached by adding a cache buster to the url
    const currentTime = new Date().getTime();
    const paymentHistoryEndpoint = getPaymentHistoryUrl(currentTime);

    const url = `${paymentHistoryEndpoint}&reference_id=${orderId}`;

    return () => fetch(url).then(_handleResponse);
};

/**
 * Return a `fetch` Promise to retrieve the last payment status for an order.
 *
 * @param  {object} orderId order id to check payment history for
 */
export const getLastPaymentStatus = (orderId) => {
    // Prevent this ajax call from getting cached by adding a cache buster to the url
    const currentTime = new Date().getTime();

    const urlLastPaymentStatus = `${getLastPaymentStatusUrl(
        currentTime,
    )}&reference_id=${orderId}`;

    return () =>
        fetch(urlLastPaymentStatus).then((response) => {
            return _handleResponse(response);
        });
};

export const getAdyenBanks = (url) => {
    const fullUrl = `https://cors-anywhere.herokuapp.com/${url}`;

    return () => fetch(fullUrl).then(_handleResponse);
};

/**
 * Return a `fetch` Promise to resend the order confirmation email
 *
 * @param {string} orderId order id to be placed
 * @param {string} recipientEmail recipient email
 */
export const resendEmail = (orderId, recipientEmail) =>
    _fetchV3WithOrderErrorHandling(_getResendEmailUrl(orderId), {
        method: 'POST',
        headers: getOrderFlowHeaders(getTokens()),

        body: JSON.stringify({
            recipient_email: recipientEmail,
        }),
    });

/**
 * Return a `fetch` Promise to abandon the order
 *
 * @param {string} orderId order id to be abandoned
 */
export const abandonOrder = (orderId) =>
    _fetchV3WithOrderErrorHandling(_getAbandonOrderUrl(orderId), {
        method: 'POST',
        headers: getOrderFlowHeaders(getTokens()),
    });

/**
 * Return a `fetch` Promise to update the order owner's email preferences
 *
 * @param {string} orderId The order for which we want to update the owner's email address.
 * @param {boolean} ebMarketingOptIn Whether or not the user wants marketing emails from eb.
 */
export const updateEmailPreferences = (orderId, ebMarketingOptIn) =>
    _fetchV3WithOrderErrorHandling(_updateEmailPreferencesUrl(orderId), {
        method: 'POST',
        headers: getOrderFlowHeaders(getTokens()),
        body: JSON.stringify({
            eb_marketing_opt_in: ebMarketingOptIn,
        }),
    }).catch((error) => {
        // 403 FORBIDDEN occurs when the order owner is different from the logged in user.
        // This happens when the user is already logged into eb.com under one email,
        // but uses another email in the buyer info fields.
        //
        // We are silencing this error because we are choosing to just not update
        // the user's email preferences in this case.
        if (error.extraData.response.status !== 403) {
            throw error;
        }
    });

/**
 * Return a `fetch` Promise to retrieve the order
 *
 * @param {string} orderId order id to be retrieved
 */
export const getOrder = (orderId) =>
    _fetchV3WithOrderErrorHandling(_getOrderUrl(orderId), {
        method: 'GET',
        headers: getOrderFlowHeaders(getTokens()),
    });

/**
 * Check whether the order is eligible to be placed.
 * Checkout Order Eligibility endpoint
 * https://eventbriteapiv3.docs.apiary.io/#reference/order/order-operations/check-order-eligibility
 */
export const getOrderEligibility = (orderId) =>
    _fetchV3WithOrderErrorHandling(_getOrderEligibilityUrl(orderId), {
        method: 'GET',
        headers: getOrderFlowHeaders(getTokens()),
    });
