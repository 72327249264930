import { isUserLoggedIn } from '../selectors/user';
import {
    isAddAttendeesOrder,
    isOrganizerInitiatedTransferOrder,
} from '../selectors/order';

export const getEmailToPrefill = (state) => {
    const {
        app: { inviteEmail, campaignToken, inviteToken },
        order: { email: orderEmail } = {},
        user: { email: userEmail } = {},
    } = state;

    if ((campaignToken || inviteToken) && inviteEmail) {
        return inviteEmail;
    }

    if (isUserLoggedIn(state)) {
        if (isOrganizerInitiatedTransferOrder(state)) {
            return orderEmail;
        }

        if (!isAddAttendeesOrder(state)) {
            return userEmail;
        }
    }

    return null;
};

export const getFirstNameToPrefill = (state) => {
    const {
        order: { firstName: orderFirstName } = {},
        user: { firstName: userFirstName } = {},
    } = state;

    if (isOrganizerInitiatedTransferOrder(state)) {
        return orderFirstName;
    }

    return userFirstName;
};

export const getLastNameToPrefill = (state) => {
    const {
        order: { lastName: orderLastName } = {},
        user: { lastName: userLastName } = {},
    } = state;

    if (isOrganizerInitiatedTransferOrder(state)) {
        return orderLastName;
    }

    return userLastName;
};
