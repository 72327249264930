import { formatUrl } from 'url-lib';
import { sdkRequest, translateServerErrors } from '@eb/http';
import { fetchV3WithTranslateServerErrors, getOrderFlowHeaders } from './base';
import {
    EVENT_DEFAULT_ERROR_MESSAGE,
    EVENT_NOT_FOUND_MESSAGE,
    PROMO_CODE_FAILURE_MESSAGE,
} from '../constants';
import { getTokens } from '../actions/tokens';
import {
    getCheckoutWidgetAjaxUrl,
    getDeliveryMethodsUrl,
    getPaymentConstraintsByEventUrl,
    getAvailablePaymentMethodIssuersInformationUrl,
    getShippingRatesUrl,
} from './constants';

// If we move to API calls instead of SOA calls, we could remove this and call the API directly, see EB-52778
const _getEventAndTicketsUrl = (
    eventId,
    promoCode = '',
    waitlistCode,
    inviteToken,
    campaignToken,
) => {
    // Prevent this ajax call from getting cached by adding a cache buster to the url
    const currentTime = new Date().getTime();
    const checkoutWidgetEndpoint = getCheckoutWidgetAjaxUrl(
        eventId,
        currentTime,
    );

    const url = checkoutWidgetEndpoint;
    const params = {};

    if (promoCode) {
        params['promo_code'] = promoCode;
    }

    if (inviteToken) {
        params.invite = inviteToken;
    }

    if (campaignToken) {
        params.mipa = campaignToken;
    }

    if (waitlistCode) {
        params.w = waitlistCode;
    }

    return formatUrl(url, params);
};

/**
 * How to handle errors on the event lifecycle
 */
const EVENT_ERROR_SPEC = {
    default: () => EVENT_DEFAULT_ERROR_MESSAGE,
    NOT_FOUND: () => EVENT_NOT_FOUND_MESSAGE,
};

const PROMO_CODE_ERROR_SPEC = {
    default: () => PROMO_CODE_FAILURE_MESSAGE,
};

/**
 * Return a `fetch` Promise to retrieve updated event and ticket details
 *
 * @param  {object} eventId  id for event we want to get updated info for
 */
export const getEventAndTickets = (
    eventId,
    promoCode,
    waitlistCode,
    inviteToken,
    campaignToken,
) => {
    const errorSpec = promoCode ? PROMO_CODE_ERROR_SPEC : EVENT_ERROR_SPEC;

    return fetchV3WithTranslateServerErrors(
        errorSpec,
        _getEventAndTicketsUrl(
            eventId,
            promoCode,
            waitlistCode,
            inviteToken,
            campaignToken,
        ),
    );
};

/**
 * Return a `fetch` Promise to get event level delivery method settings
 * for all methods offered on the event. Note that this is EVENT level, not
 * ticket level. Event level settings define properties like a backup method
 * and cutoff date.
 * @param  {string} eventId
 *
 */
export const getEventDeliveryMethodSettings = (eventId) => {
    const deliveryMethodsEndpoint = getDeliveryMethodsUrl(eventId);

    return fetchV3WithTranslateServerErrors(
        EVENT_ERROR_SPEC,
        deliveryMethodsEndpoint,
        {
            headers: getOrderFlowHeaders(getTokens()),
        },
    );
};

/**
 * Fetch tickets payment constraints for a specific event id
 *
 * @param eventId - the id of the event
 * @param ticketClasses - array of ticket classes ids.
 * @returns {Promise}
 */
export const getPaymentConstraintsByEventId = async (eventId) => {
    const url = getPaymentConstraintsByEventUrl(eventId);

    try {
        return await sdkRequest(url);
    } catch (err) {
        return translateServerErrors(err);
    }
};

export const getAvailablePaymentMethodIssuersInformation = (eventId) => {
    const availablePaymentMethodIssuersInformationEndpoint = getAvailablePaymentMethodIssuersInformationUrl(
        eventId,
    );

    return fetchV3WithTranslateServerErrors(
        EVENT_ERROR_SPEC,
        availablePaymentMethodIssuersInformationEndpoint,
        {
            headers: getOrderFlowHeaders(getTokens()),
        },
    );
};

/**
 * Fetch shipping rates for an event id
 *
 * @param eventId - the id of the event
 */
export const getShippingRates = ({
    eventId,
    currency,
    envCountryCode,
    orgId,
}) =>
    fetchV3WithTranslateServerErrors(
        EVENT_ERROR_SPEC,
        getShippingRatesUrl({ eventId, currency, envCountryCode, orgId }),
        {
            headers: getOrderFlowHeaders(getTokens()),
        },
    );
