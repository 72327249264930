import isArray from 'lodash/isArray';
import {
    isPristine,
    isInvalid,
    isSubmitting,
    hasSubmitSucceeded,
} from 'redux-form';

import {
    CTA_COMPLETE_FREE_TEXT,
    CTA_COMPLETE_PAID_TEXT,
    CTA_CHECKOUT_TEXT,
    CTA_JOIN_WAITLIST,
} from '../constants';
import {
    allTicketsFree,
    hasEnteredDonationAmount,
} from '../containers/ticketSelection/utils/ticketUtils';
import { getSelectedTickets } from '../selectors/tickets';

export const isFormSubmitting = (state, formName) =>
    isSubmitting(formName)(state);
export const isFormSuccessfullySubmitted = (state, formName) =>
    hasSubmitSucceeded(formName)(state);
export const isFormSubmitted = (state, formName) =>
    isFormSubmitting(state, formName) ||
    isFormSuccessfullySubmitted(state, formName);

export const isSubmitDisabled = (state, formName, disableOnSucceeded = true) =>
    isPristine(formName)(state) ||
    isFormSubmitting(state, formName) ||
    (disableOnSucceeded && isFormSuccessfullySubmitted(state, formName)) ||
    isInvalid(formName)(state);

/**
 * TODO: Move to selectors/event.js or tickets.js
 * CTA text for the ticket selection screen. On initial widget load, if there
 * are no selected tickets, we base the CTA text off of the available tickets.
 * @param  {object} app         The app state
 * @return {string}             CTA text
 */
// TODO: Move selector to `/selectors/event.js`
export const getTicketSelectDefaultCtaText = ({ event: { isFree } }) =>
    isFree ? CTA_COMPLETE_FREE_TEXT : CTA_CHECKOUT_TEXT;
/**
 * Returns the CTA text for the special ticket selection page when the event capacity
 * waitlist has been triggered.
 * @param {object} state
 * TODO: Move to `selectors/tickets.js`
 */
// TODO: Move selector to `/selectors/tickets.js`, use getTicketsById
export const getEventCapacityWaitlistTicketsPageCtaText = (state) => {
    const {
        tickets: { ticketsById },
    } = state;

    if (hasEnteredDonationAmount(ticketsById)) {
        return CTA_CHECKOUT_TEXT;
    }

    return CTA_JOIN_WAITLIST;
};

// TODO: Move selector to `/selectors/tickets.js`
export const getCheckoutDefaultCtaText = (state) => {
    const selectedTickets = getSelectedTickets(state);
    const showFreeCta = allTicketsFree(selectedTickets);

    if (showFreeCta) {
        return CTA_COMPLETE_FREE_TEXT;
    }

    return CTA_COMPLETE_PAID_TEXT;
};

export const flattenFormData = (data) => {
    const formData = isArray(data) ? [...data] : { ...data };

    for (const key in formData) {
        if (Object.prototype.hasOwnProperty.call(formData, key)) {
            let value = formData[key];

            if (
                typeof value !== 'object' ||
                value === null ||
                !formData.hasOwnProperty(key) ||
                isArray(value)
            ) {
                continue;
            }

            // for multi-level flattening
            value = flattenFormData(value);

            for (const nestedKey in value) {
                if (!value.hasOwnProperty(nestedKey)) {
                    continue;
                }

                formData[`${key}.${nestedKey}`] = value[nestedKey];
            }

            delete formData[key];
        }
    }

    return formData;
};
