import { formatNumber, unformat, getCurrencyPrecision } from '@eb/intl';
import { stripNonDigits } from './general';

export const preparePriceForApi = (cost, currency, currencyFormat) => {
    const currencyPrecision = getCurrencyPrecision(currency);
    const formattedCost = formatNumber(
        unformat(cost, currencyFormat),
        currencyFormat,
        {
            precision: currencyPrecision,
        },
    );

    return stripNonDigits(formattedCost);
};
