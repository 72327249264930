export const DISPLAY_AMOUNT_NONE = 'none';
export const DISPLAY_AMOUNT_SMALL = 'short';
export const DISPLAY_AMOUNT_MEDIUM = 'intermediate';
export const DISPLAY_AMOUNT_LARGE = 'tall';

export const DISPLAY_AMOUNT_TYPES = [
    DISPLAY_AMOUNT_LARGE,
    DISPLAY_AMOUNT_MEDIUM,
    DISPLAY_AMOUNT_SMALL,
    DISPLAY_AMOUNT_NONE,
];

/**
 * These values should match the ones defined in readMore.scss
 * $small-height: 75px;
 * $medium-height: 150px;
 * $large-height: 225px;
 */
const DISPLAY_AMOUNT_HEIGHT_NONE = 0;
const DISPLAY_AMOUNT_HEIGHT_SMALL = 75;
const DISPLAY_AMOUNT_HEIGHT_MEDIUM = 150;
const DISPLAY_AMOUNT_HEIGHT_LARGE = 225;

export const DISPLAY_AMOUNT_HEIGHT_MAP = {
    [DISPLAY_AMOUNT_NONE]: DISPLAY_AMOUNT_HEIGHT_NONE,
    [DISPLAY_AMOUNT_SMALL]: DISPLAY_AMOUNT_HEIGHT_SMALL,
    [DISPLAY_AMOUNT_MEDIUM]: DISPLAY_AMOUNT_HEIGHT_MEDIUM,
    [DISPLAY_AMOUNT_LARGE]: DISPLAY_AMOUNT_HEIGHT_LARGE,
};

export const TOGGLE_LOCATION_TOP = 'top';
export const TOGGLE_LOCATION_BOTTOM = 'bottom';

export const TOGGLE_LOCATION_TYPES = [
    TOGGLE_LOCATION_TOP,
    TOGGLE_LOCATION_BOTTOM,
];
