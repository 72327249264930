import React from 'react';
import gettext from '@eb/gettext';
import classNames from 'classnames';

import { Illustration } from '@eb/eds-illustration';
import { NotificationBar } from '@eb/eds-notification';
import { TYPE_ERROR } from '@eb/eds-notification';

import { ModalBody } from '@eb/eds-modal';
import { ProgressIndicator } from '@eb/eds-progress-indicator';
import { Icon } from '@eb/eds-icon';

import {
    BACK_TO_SERIES_EVENTS_TITLE,
    CHECKOUT_STEP_CART,
    CHECKOUT_STEP_CHECKOUT,
    CHECKOUT_STEP_VISIT,
} from '../../constants';

import './WaitingRoomPage.scss';

import { ArrowLeftChunky } from '@eb/eds-iconography';
import { TicketChunky } from '@eb/eds-iconography';
import { CartChunky } from '@eb/eds-iconography';
import { CheckChunky } from '@eb/eds-iconography';
import { WaitingRoomIllustration } from '@eb/eds-iconography';

export const getWaitingRoomProgressSection = (
    currentStep,
    displayStep,
    iconType,
    title,
) => {
    const steps = [
        CHECKOUT_STEP_VISIT,
        CHECKOUT_STEP_CART,
        CHECKOUT_STEP_CHECKOUT,
    ];
    const hasProceeded =
        steps.indexOf(currentStep) > steps.indexOf(displayStep);
    const titleClass = classNames(
        'eds-text-bs eds-text-weight--heavy eds-l-pad-top-3',
        {
            'eds-text-color--grey-800': hasProceeded,
            'eds-text-color--grey-500': !hasProceeded,
        },
    );
    const progressBarClass = classNames('waiting-room__step-progress', {
        'waiting-room__step-progress--proceeded': hasProceeded,
    });
    let progressIndicator;

    // Display a loading progress bar.
    if (displayStep === currentStep) {
        progressIndicator = (
            <div data-spec={`waiting-room-${displayStep}-step-loader`}>
                <ProgressIndicator
                    style="dark"
                    shape="linear"
                    type="indeterminate"
                />
            </div>
        );
    }

    return (
        <div className="eds-g-cell eds-g-cell-4-12">
            <section className="waiting-room__step">
                <div className="waiting-room__step-content">
                    <Icon
                        type={iconType}
                        color={hasProceeded ? 'grey-800' : 'grey-500'}
                    />
                    <div className={titleClass}>{title}</div>
                </div>
                <div className={progressBarClass}>{progressIndicator}</div>
            </section>
        </div>
    );
};

export default class WaitingRoomPage extends React.PureComponent {
    componentDidMount() {
        this.props.onPageDidMount();
    }

    _getHeaderProps() {
        const { onBackToSeries, shouldShowBackToSeriesLink } = this.props;
        let headerProps;

        // For repeating event, can go back to the parent series page.
        if (shouldShowBackToSeriesLink) {
            headerProps = {
                headerActionIconType: <ArrowLeftChunky />,
                headerActionTitle: BACK_TO_SERIES_EVENTS_TITLE,
                onHeaderAction: onBackToSeries,
            };
        }

        return headerProps;
    }

    _browserIsUnSupported() {
        // EB-108571: Safari browser doesn't support fetch till 10.1
        return typeof fetch !== 'function';
    }

    render() {
        const { eventTitle, eventDate, checkoutStep } = this.props;

        let headerText;
        let bodyText;
        let tipText;
        let errorNotification;

        if (this._browserIsUnSupported()) {
            errorNotification = (
                <div className="eds-g-cell eds-g-cell-12-12">
                    <NotificationBar type={TYPE_ERROR}>
                        {gettext(
                            "Your browser's version is not supported. Please update or use a different browser.",
                        )}
                    </NotificationBar>
                </div>
            );
        }

        if (checkoutStep === CHECKOUT_STEP_VISIT) {
            headerText = gettext(
                "You're in line for tickets! Thanks for waiting.",
            );
            bodyText = gettext(
                "This event is popular, but we're getting people through — first come, first served.",
            );
            tipText = gettext(
                "Don't refresh this page to keep your spot in line.",
            );
        } else {
            headerText = gettext(
                "We're looking for the tickets you requested.",
            );
            bodyText = gettext(
                "We'll get those tickets for you, if they're available. Don't refresh this page while we're looking.",
            );
            tipText = gettext('Thanks again for waiting.');
        }

        return (
            <ModalBody
                title={eventTitle}
                secondaryTitle={eventDate}
                {...this._getHeaderProps()}
            >
                {errorNotification}
                <div className="eds-g-grid waiting-room__height--full">
                    <div className="eds-g-group eds-text--center waiting-room__height--full waiting-room__container">
                        <div className="eds-bg-color--grey-200 waiting-room__illustration--container">
                            <div className="eds-g-cell eds-g-cell-12-12 eds-g-cell-sw-10-12 eds-g-cell-md-8-12">
                                <Illustration
                                    type={<WaitingRoomIllustration />}
                                    width="100%"
                                    height="auto"
                                />
                            </div>
                        </div>

                        <div className="eds-l-mar-vert-14 eds-l-sn-mar-vert-8 eds-l-pad-hor-4">
                            <div className="eds-g-cell eds-g-cell-12-12 eds-g-cell-sw-10-12 eds-g-cell-md-8-12">
                                {getWaitingRoomProgressSection(
                                    checkoutStep,
                                    CHECKOUT_STEP_VISIT,
                                    <TicketChunky />,
                                    gettext('Tickets'),
                                )}
                                {getWaitingRoomProgressSection(
                                    checkoutStep,
                                    CHECKOUT_STEP_CART,
                                    <CartChunky />,
                                    gettext('Checkout'),
                                )}
                                {getWaitingRoomProgressSection(
                                    checkoutStep,
                                    CHECKOUT_STEP_CHECKOUT,
                                    <CheckChunky />,
                                    gettext('Complete'),
                                )}
                            </div>
                        </div>

                        <div className="eds-g-cell eds-g-cell-12-12 eds-l-pad-hor-2 waiting-room__textbox--container">
                            <h2
                                className="eds-text-hs eds-text-weight--regular eds-l-mar-bot-2"
                                data-spec="waiting-room-header"
                            >
                                {headerText}
                            </h2>
                            <p className="eds-text-bm eds-text-weight--regular eds-text-color--grey-600 eds-l-mar-bot-20 eds-l-sn-mar-bot-10">
                                {bodyText}
                            </p>
                            <p className="eds-text-bm eds-text-weight--heavy eds-l-mar-top-10 eds-l-sn-mar-top-6">
                                {tipText}
                            </p>
                        </div>
                    </div>
                </div>
            </ModalBody>
        );
    }
}
