import omit from 'lodash/omit';
import groupBy from 'lodash/groupBy';
import { sdkRequest } from '@eb/http';
import { CountryWithNestedMobileData } from './models/country';
import { Region } from './models/region';

/**
 * Maps the array of regions to a hash by country code
 */
const getRegionsByCountry = (regions: Array<Region>) =>
    groupBy(regions, 'country_code');

/**
 * Fetches all the regions in the entirety of the world, then groups them by country.
 */
export const fetchRegionsByCountry = (): Promise<{
    regionsByCountry: Record<string, Array<Region>>;
}> =>
    sdkRequest('/api/v3/system/regions/').then(
        (response: { regions: Array<Region> }) => ({
            regionsByCountry: getRegionsByCountry(response.regions),
        }),
    );

export type CountryWithDeinflatedMobileData<
    MobileData extends Record<string, unknown>
> = Omit<CountryWithNestedMobileData<MobileData>, 'mobile'> & MobileData;

/**
 * Extract the nested mobile expansion parameters to be toplevel params for the
 * country endpoint
 */
const _deinflateMobile = <NestedMobileData extends Record<string, unknown>>(
    countries: Array<CountryWithNestedMobileData<NestedMobileData>>,
): Array<CountryWithDeinflatedMobileData<NestedMobileData>> =>
    countries.map((country) => ({
        ...omit(country, 'mobile'),
        ...country.mobile,
    }));

/**
 * Fetch the countries supported by Eventbrite.
 */
export const fetchCountries = <
    MobileData extends Record<string, unknown>
>(): Promise<{
    countries: Array<CountryWithDeinflatedMobileData<MobileData>>;
}> =>
    sdkRequest('/api/v3/system/countries/?expand=mobile').then(
        (response: {
            countries: Array<CountryWithNestedMobileData<MobileData>>;
        }) => ({
            countries: _deinflateMobile(response.countries),
        }),
    );
