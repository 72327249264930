import isEmpty from 'lodash/isEmpty';

import {
    CHECKOUT_FORM_NAME,
    PAYMENT_METHODS,
    PAYMENT_OPTIONS_METHODS_TYPES,
} from '../constants';
import {
    PaymentMethodType,
    PAYMENT_METHODS_INFO,
} from '../models/paymentMethod';
import {
    getConstrainedInstrumentType,
    getConstrainedPaymentMethods,
} from './tickets';

const INSTRUMENT_TYPE_RELATIVE_ORDER_BY_COUNTRY = {
    NL: {
        [PAYMENT_METHODS.FPP_PAYPAL]: 0, // We want to show FPP PayPal first over any other
        [PAYMENT_METHODS.IDEAL]: 1,
        [PAYMENT_METHODS.CREDIT]: 2,
        [PAYMENT_METHODS.NONCE_BRAINTREE]: 3,
        [PAYMENT_METHODS.MAESTRO_BANCONTACT]: 4,
        [PAYMENT_METHODS.SOFORT]: 5,
    },
    BE: {
        [PAYMENT_METHODS.FPP_PAYPAL]: 0, // We want to show FPP PayPal first over any other
        [PAYMENT_METHODS.MAESTRO_BANCONTACT]: 1,
        [PAYMENT_METHODS.CREDIT]: 2,
        [PAYMENT_METHODS.NONCE_BRAINTREE]: 3,
        [PAYMENT_METHODS.IDEAL]: 4,
        [PAYMENT_METHODS.SOFORT]: 5,
    },
    DE: {
        [PAYMENT_METHODS.FPP_PAYPAL]: 0, // We want to show FPP PayPal first over any other
        [PAYMENT_METHODS.CREDIT]: 1,
        [PAYMENT_METHODS.NONCE_BRAINTREE]: 2,
        [PAYMENT_METHODS.SEPA_DIRECT_DEBIT]: 3,
        [PAYMENT_METHODS.SOFORT]: 4,
        [PAYMENT_METHODS.IDEAL]: 5,
        [PAYMENT_METHODS.MAESTRO_BANCONTACT]: 6,
    },
    BR: {
        [PAYMENT_METHODS.FPP_PAYPAL]: 0, // We want to show FPP PayPal first over any other
        [PAYMENT_METHODS.CREDIT]: 1,
        [PAYMENT_METHODS.BOLETO_BANCARIO]: 2,
    },
    AR: {
        [PAYMENT_METHODS.FPP_PAYPAL]: 0, // We want to show FPP PayPal first over any other
        [PAYMENT_METHODS.CREDIT]: 1,
        [PAYMENT_METHODS.BARCODE]: 2,
    },
    MX: {
        [PAYMENT_METHODS.FPP_PAYPAL]: 0, // We want to show FPP PayPal first over any other
        [PAYMENT_METHODS.CREDIT]: 1,
        [PAYMENT_METHODS.BARCODE]: 2,
    },
};
const DEFAULT_COUNTRY_INSTRUMENT_TYPE_RELATIVE_ORDER = {
    [PAYMENT_METHODS.FPP_PAYPAL]: 0, // We want to show FPP PayPal first over any other
    [PAYMENT_METHODS.CREDIT]: 1,
    [PAYMENT_METHODS.NONCE_BRAINTREE]: 2,
    [PAYMENT_METHODS.SOFORT]: 3,
    [PAYMENT_METHODS.IDEAL]: 4,
    [PAYMENT_METHODS.MAESTRO_BANCONTACT]: 5,
    [PAYMENT_METHODS.SEPA_DIRECT_DEBIT]: 6,
};
const UNKNOWN_INSTRUMENT_TYPE_RELATIVE_ORDER = Number.MAX_SAFE_INTEGER;

export const getAvailablePaymentMethods = (state) => {
    const filterDisabledPaymentMethods = (paymentMethod) => {
        switch (paymentMethod.type) {
            case PAYMENT_METHODS.NONCE_BRAINTREE:
                return state.app.featureFlags.enableNonceBraintreePayments;
            default:
                return true;
        }
    };

    const filterBasedOnPaymentConstraints = (paymentMethod) => {
        const constrainedInstrumentType = getConstrainedInstrumentType(state);
        const constrainedPaymentMethods = getConstrainedPaymentMethods(state);

        if (isEmpty(constrainedPaymentMethods)) {
            return true;
        }

        return (
            paymentMethod.type === constrainedInstrumentType ||
            paymentMethod.variants.some((variant) =>
                constrainedPaymentMethods.includes(variant),
            )
        );
    };

    if (!state.order.availablePaymentMethods) {
        return [];
    }

    const availableMethodTypes = [
        PAYMENT_OPTIONS_METHODS_TYPES.EVENTBRITE,
        PAYMENT_OPTIONS_METHODS_TYPES.OFFLINE,
        PAYMENT_OPTIONS_METHODS_TYPES.PAYPAL,
        PAYMENT_OPTIONS_METHODS_TYPES.AUTHNET,
    ];

    const venue = state.event.venue || {};
    const { country } = venue.address || {};

    const relativeOrderForInstrumentType =
        INSTRUMENT_TYPE_RELATIVE_ORDER_BY_COUNTRY[country] ||
        DEFAULT_COUNTRY_INSTRUMENT_TYPE_RELATIVE_ORDER;

    return state.order.availablePaymentMethods
        .filter((method) => availableMethodTypes.includes(method.type))
        .flatMap((method) => method.instrumentTypes)
        .filter((instrument) => filterDisabledPaymentMethods(instrument))
        .filter((instrument) => filterBasedOnPaymentConstraints(instrument))
        .flatMap((instrument) => {
            const {
                event: { currency },
            } = state;
            const isRapipagoPagofacil =
                instrument.type === PAYMENT_METHODS.BARCODE &&
                currency === 'ARS';
            const pagofacilPaymentMethod = {
                type: PAYMENT_METHODS.PAGOFACIL,
                additionalData: {
                    note: instrument.note,
                },
                variants: [],
            };
            const rapipagoPaymentMethod = {
                type: PAYMENT_METHODS.RAPIPAGO,
                additionalData: {
                    note: instrument.note,
                },
                variants: [],
            };

            return isRapipagoPagofacil
                ? [pagofacilPaymentMethod, rapipagoPaymentMethod]
                : [
                      {
                          type: instrument.type,
                          additionalData: {
                              note: instrument.note,
                          },
                          variants: instrument.variants || [],
                      },
                  ];
        })
        .sort((lhs, rhs) => {
            const lhsRelativeOrder =
                lhs.type in relativeOrderForInstrumentType
                    ? relativeOrderForInstrumentType[lhs.type]
                    : UNKNOWN_INSTRUMENT_TYPE_RELATIVE_ORDER;

            const rhsRelativeOrder =
                rhs.type in relativeOrderForInstrumentType
                    ? relativeOrderForInstrumentType[rhs.type]
                    : UNKNOWN_INSTRUMENT_TYPE_RELATIVE_ORDER;

            return lhsRelativeOrder - rhsRelativeOrder;
        });
};

export const getIsPayingWithVirtualIncentives = (state) =>
    state.form[CHECKOUT_FORM_NAME]?.values?.payment?.payWithVirtualIncentives ??
    false;

export const getIsPayingWithVirtualIncentivesSupportedBySomeAvailablePaymentMethod = (
    state,
) =>
    getAvailablePaymentMethods(state).some(({ type }) => {
        const paymentMethodInfo = PAYMENT_METHODS_INFO[type];
        return paymentMethodInfo?.allowsSplitPaymentsWithVirtualIncentives;
    });

export const getIsPayingWithVirtualIncentivesSupportedBySelectedPaymentMethod = (
    state,
) => {
    const selectedPaymentMethodType = getSelectedPaymentMethod(state);

    if (selectedPaymentMethodType === PaymentMethodType.NOT_SELECTED) {
        return true;
    }

    const paymentMethodInfo = PAYMENT_METHODS_INFO[selectedPaymentMethodType];

    return paymentMethodInfo?.allowsSplitPaymentsWithVirtualIncentives ?? false;
};

export const getIsTogglingVirtualIncentivesSupportedBySelectedPaymentMethod = (
    state,
) => {
    const selectedPaymentMethodType = getSelectedPaymentMethod(state);

    if (selectedPaymentMethodType === PaymentMethodType.NOT_SELECTED) {
        return true;
    }

    const paymentMethodInfo = PAYMENT_METHODS_INFO[selectedPaymentMethodType];

    return (
        paymentMethodInfo?.allowsChangingVirtualIncentivesWhenSelected ?? false
    );
};

export const getPaypalEmail = (state) => {
    if (
        state.form[CHECKOUT_FORM_NAME].values &&
        state.form[CHECKOUT_FORM_NAME].values.payment
    ) {
        return state.form[CHECKOUT_FORM_NAME].values.payment.paypalEmail
            ? state.form[CHECKOUT_FORM_NAME].values.payment.paypalEmail
            : undefined;
    }

    return undefined;
};

export const getSelectedPaymentMethod = (state) => {
    if (state.form[CHECKOUT_FORM_NAME].values) {
        return state.form[CHECKOUT_FORM_NAME].values.paymentMethod
            ? state.form[CHECKOUT_FORM_NAME].values.paymentMethod
            : PAYMENT_METHODS.NOT_SELECTED;
    }

    return PAYMENT_METHODS.NOT_SELECTED;
};

export const getPaymentHistory = (state) => state.paymentHistory;

export const getPaymentStatus = (state) => state.paymentHistory.payment_status;

export const isPaymentMethodSelectorFormComplete = (state) => {
    const selectedPaymentMethod = getSelectedPaymentMethod(state);
    const availablePaymentMethods = getAvailablePaymentMethods(state);
    const paymentMethodIsMissing =
        availablePaymentMethods.length > 1 &&
        selectedPaymentMethod === PAYMENT_METHODS.NOT_SELECTED;

    const isPaypalSelected =
        selectedPaymentMethod === PAYMENT_METHODS.NONCE_BRAINTREE;
    const paypalEmail = getPaypalEmail(state);
    const isPayPalEmailValid = !!paypalEmail;

    if (paymentMethodIsMissing) {
        return false;
    }

    if (isPaypalSelected) {
        return isPayPalEmailValid;
    }

    return true;
};
