import gettext from '@eb/gettext';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

import { NotificationBar } from '@eb/eds-notification';
import { TYPE_ERROR } from '@eb/eds-notification';
import { Icon } from '@eb/eds-icon';
import { PaymentIcon } from '@eb/eds-payment-icon';
import { InputField } from '@eb/eds-input-field';
import { ValidationFormField } from '@eb/eds-validation-form-field';
import * as constants from './constants';
import { cardTypeForNumber } from '@eb/eds-utils';
import { CardExpirationDateField } from '@eb/eds-card-expiration-date-field';

import './paymentInfoFields.scss';

import { CreditCardFront } from '@eb/eds-iconography';
import { AVAILABLE_CARD_TYPES, getCardDef } from '@eb/eds-utils';

const TopLevelError = ({ error }) => {
    let component = null;

    if (error) {
        component = (
            <div className="eds-g-cell eds-g-cell-1-1 eds-l-mar-bot-3">
                <NotificationBar type={TYPE_ERROR} hasCloseButton={true}>
                    <span>{error}</span>
                </NotificationBar>
            </div>
        );
    }

    return component;
};

const CreditCardField = ({
    cardType,
    onChange,
    onBlur,
    value,
    shouldDisplayError,
    shouldDisplayRequired,
    paymentConstraintAnnotationNote,
    constrainedPaymentMethodError,
}) => {
    let creditCardIcon = null;
    let validationFormShouldDisplayError = shouldDisplayError;
    let creditCardMaxLength = constants.MAX_CREDIT_CARD_LENGTH;

    if (AVAILABLE_CARD_TYPES.includes(cardType)) {
        const cardTypeInfo = getCardDef(cardType);

        creditCardIcon = <PaymentIcon type={cardTypeInfo.icon} size="small" />;
        creditCardMaxLength = cardTypeInfo.maxLength;
    } else {
        creditCardIcon = (
            <Icon type={<CreditCardFront />} size="small" color="grey-300" />
        );
    }

    if (constrainedPaymentMethodError) {
        validationFormShouldDisplayError = () => true;
    }

    return (
        <ValidationFormField
            shouldDisplayError={validationFormShouldDisplayError}
            name={constants.CREDIT_CARD_FIELD_NAME}
            required={shouldDisplayRequired}
            v2={true}
        >
            <InputField
                annotationNote={paymentConstraintAnnotationNote}
                autoComplete="cc-number"
                id="credit-card-number"
                label={gettext('Card number')}
                required={shouldDisplayRequired}
                maxLength={creditCardMaxLength}
                onBlur={onBlur}
                onChange={onChange}
                placeholder={gettext('e.g. 1111222233334444')}
                suffix={creditCardIcon}
                type="number"
                value={value}
                data-automation="credit-card-field"
            />
        </ValidationFormField>
    );
};

const CardSecurityCodeField = ({
    cardType,
    onChange,
    onBlur,
    value,
    shouldDisplayError,
    shouldDisplayRequired,
}) => {
    const codeName = AVAILABLE_CARD_TYPES.includes(cardType)
        ? getCardDef(cardType).securityCodeName || 'CSC'
        : 'CSC';

    return (
        <ValidationFormField
            shouldDisplayError={shouldDisplayError}
            name={constants.CARD_SECURITY_CODE_FIELD_NAME}
            required={shouldDisplayRequired}
            v2={true}
        >
            <InputField
                autoComplete="cc-csc"
                id="csc"
                label={codeName}
                required={shouldDisplayRequired}
                maxLength={constants.MAX_CARD_SECURITY_CODE_LENGTH}
                onBlur={onBlur}
                onChange={onChange}
                type="tel"
                value={value}
                data-automation="card-security-code-field"
            />
        </ValidationFormField>
    );
};

const PostalCodeField = ({
    onChange,
    onBlur,
    value,
    shouldDisplayError,
    shouldDisplayRequired,
}) => (
    <ValidationFormField
        shouldDisplayError={shouldDisplayError}
        name={constants.CARD_POSTAL_CODE_FIELD_NAME}
        required={shouldDisplayRequired}
        v2={true}
    >
        <InputField
            autoComplete="cc-postal-code"
            id="postal-code"
            label={gettext('Zip/Postal')}
            required={shouldDisplayRequired}
            maxLength={constants.MAX_POSTAL_CODE_LENGTH}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            data-automation="card-postal-code-field"
        />
    </ValidationFormField>
);

const INITIAL_STATE = {
    cardExpirationDate: '',
    cardType: null,
};

export default class PaymentInfoFields extends PureComponent {
    static propTypes = {
        /**
         * Error message to display above the payment info fields.
         * ex. 'Please enter a valid credit card number.'
         */
        error: PropTypes.node,
        /**
         * A callback triggered when the value of input changes, passing in the value and name of the field changes
         * ex. (value, name) => { console.log(value, name) // '05/99', 'cardExpirationDate' }
         */
        onChange: PropTypes.func,
        /**
         * A callback triggered when the input is blurred.
         */
        onBlur: PropTypes.func,
        /**
         * A callback triggered when the credit card type changes
         */
        onCardTypeChange: PropTypes.func,
        /**
         * A callback triggered when the bin changes (first six digits of CC)
         */
        onBinChange: PropTypes.func,
        /**
         * A function passed to ValidationFormField to determine if errors should be shown
         */
        shouldDisplayError: PropTypes.func,
        /**
         * Whether to show asterisk to indicate field is required
         */
        shouldDisplayRequired: PropTypes.bool,
        /**
         * Whether to show the Postal Code field that may not be required for a given country
         */
        shouldDisplayPostalCode: PropTypes.bool,
        /**
         * An object with data to populate the payment info fields.
         */
        values: constants.PAYMENT_INFO_VALUES_PROP,
        /**
         * OnCardTypeChange fires back to CreditPaymentFields which determines whether there is a
         * paymentConstraint error. This can't be in the validators because it needs access to state
         */
        constrainedPaymentMethodError: PropTypes.bool,
        /**
         * Content to be displayed as annotation below the input
         * element when there is a payment constraint error.
         */
        paymentConstraintAnnotationNote: PropTypes.node,
    };

    static defaultProps = {
        values: {},
        onChange: noop,
        onBlur: noop,
        onBinChange: noop,
        onCardTypeChange: noop,
        shouldDisplayPostalCode: true,
    };

    state = INITIAL_STATE;

    _handleChange(name, value) {
        this.props.onChange(value, name);
    }

    _handleBlur(name, value) {
        this.props.onBlur(value, name);
    }

    _handleCCNumberChange(value) {
        const cardTypeMatch = cardTypeForNumber(value);
        const cardType = cardTypeMatch
            ? { cardType: cardTypeMatch }
            : INITIAL_STATE;
        const binNumber =
            value.length >= constants.BIN_LENGTH
                ? value.substr(0, constants.BIN_LENGTH)
                : '';

        this.props.onBinChange(binNumber);
        this.props.onCardTypeChange(cardType.cardType);
        this.setState(cardType);
    }

    _getCardExpirationDateFieldClasses = () => {
        const { shouldDisplayPostalCode } = this.props;
        const width = shouldDisplayPostalCode
            ? 'eds-g-cell-5-12'
            : 'eds-g-cell-8-12';

        return `eds-g-cell ${width} eds-l-pad-right-2`;
    };

    _getCardSecurityCodeFieldClasses = () => {
        const { shouldDisplayPostalCode } = this.props;
        const width = shouldDisplayPostalCode
            ? 'eds-g-cell-3-12 eds-l-pad-hor-2'
            : 'eds-g-cell-4-12 eds-l-pad-left-2';

        return `eds-g-cell ${width}`;
    };

    render() {
        const {
            values,
            error,
            shouldDisplayRequired,
            // XXX: It's a real pain having to pass this prop to every single ValidationFormField
            // even though it's same for the entire form. Maybe add something to formValidate that
            // does this for us?
            shouldDisplayError,
            shouldDisplayPostalCode,
            constrainedPaymentMethodError,
            paymentConstraintAnnotationNote,
        } = this.props;

        return (
            <div className="eds-payment-info-fields eds-g-group">
                <main className="eds-payment-info-input-fields-container eds-l-pad-bot-2">
                    <TopLevelError error={error} />
                    <div
                        className="eds-g-cell eds-g-cell-12-12 eds-l-pad-bot-2"
                        data-spec="eds-credit-card-field"
                    >
                        <CreditCardField
                            shouldDisplayError={shouldDisplayError}
                            shouldDisplayRequired={shouldDisplayRequired}
                            cardType={this.state.cardType}
                            onChange={this._handleCCNumberChange.bind(this)}
                            onBlur={this._handleBlur.bind(
                                this,
                                'creditCardNumber',
                            )}
                            value={values.creditCardNumber}
                            constrainedPaymentMethodError={
                                constrainedPaymentMethodError
                            }
                            paymentConstraintAnnotationNote={
                                paymentConstraintAnnotationNote
                            }
                        />
                    </div>
                    <div
                        className={this._getCardExpirationDateFieldClasses()}
                        data-spec="eds-card-expiration-date-field"
                    >
                        <CardExpirationDateField
                            shouldDisplayError={shouldDisplayError}
                            shouldDisplayRequired={shouldDisplayRequired}
                            // onBlur event is intentionally ignored to prevent inifinite loops when Chrome autofills a credit card!
                            name={constants.CARD_EXPIRATION_DATE_FIELD_NAME}
                        />
                    </div>
                    <div
                        className={this._getCardSecurityCodeFieldClasses()}
                        data-spec="eds-card-security-code-field"
                    >
                        <CardSecurityCodeField
                            shouldDisplayError={shouldDisplayError}
                            shouldDisplayRequired={shouldDisplayRequired}
                            cardType={this.state.cardType}
                            onChange={this._handleChange.bind(
                                this,
                                'cardSecurityCode',
                            )}
                            onBlur={this._handleBlur.bind(
                                this,
                                'cardSecurityCode',
                            )}
                            value={values.cardSecurityCode}
                        />
                    </div>
                    {shouldDisplayPostalCode && (
                        <div
                            className="eds-g-cell eds-g-cell-4-12 eds-l-pad-left-2"
                            data-spec="eds-card-postal-code-field"
                        >
                            <PostalCodeField
                                shouldDisplayError={shouldDisplayError}
                                shouldDisplayRequired={shouldDisplayRequired}
                                onChange={this._handleChange.bind(
                                    this,
                                    'cardPostalCode',
                                )}
                                onBlur={this._handleBlur.bind(
                                    this,
                                    'cardPostalCode',
                                )}
                                value={values.cardPostalCode}
                            />
                        </div>
                    )}
                </main>
            </div>
        );
    }
}
