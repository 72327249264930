import React from 'react';
import classNames from 'classnames';
import { MEDIUM_COLOR_CIRCLE_SIZE, COLOR_CIRCLE_SIZES } from './constants';
import './TierColorCircle.scss';

/**
 * This component was duplicated from
 *  `js/src/common/components/tierColorCircle/TierColorCircle.js`
 * for `checkoutWidget` to remove dependencies on `../common` as part of IPJ.
 */
export const TierColorCircle = ({
    tierColor,
    colorCircleSize = MEDIUM_COLOR_CIRCLE_SIZE,
    customClassNames,
}) => {
    if (!COLOR_CIRCLE_SIZES.includes(colorCircleSize)) {
        throw new Error(`Invalid circle size: ${colorCircleSize}`);
    }

    const circleSizeClassNames = classNames(
        'eds-g-cell',
        'eds-g-cell--align-center',
        'tier-list-item--color',
        `tier-list-color-circle--${colorCircleSize}`,
        customClassNames,
    );

    return (
        <span
            className={circleSizeClassNames}
            style={{ backgroundColor: tierColor }}
            data-spec="tier-color"
        />
    );
};
