import {
    INITIALIZE_WAITING_ROOM,
    RESET_WAITING_ROOM,
    UPDATE_WAITING_ROOM,
} from '../actions/waitingRoom';

export const waitingRoomReducer = (state = {}, action) => {
    switch (action.type) {
        case RESET_WAITING_ROOM:
            return {
                queueId: state.queueId,
                queueServer: state.queueServer,
                registerServer: state.registerServer,
                isLive: true,
                isInWaitingRoom: false,
                isWaitingRoomShown: false,
            };

        case INITIALIZE_WAITING_ROOM: {
            return {
                ...state,
                ...action.payload.waitingRoom,
                isInWaitingRoom: action.payload.waitingRoom.isLive === false,
                isWaitingRoomShown: false,
            };
        }

        case UPDATE_WAITING_ROOM: {
            return {
                ...state,
                ...action.payload.waitingRoom,
                isInWaitingRoom: action.payload.waitingRoom.isLive === false,
            };
        }

        default:
            return state;
    }
};
