import PropTypes from 'prop-types';
import { translationPropType } from '@eb/i18n';

export const TEMPLATE_PROP_TYPE_SHAPE = PropTypes.shape({
    /**
     * True if the event is configured as reserved seating event.
     */
    isReservedSeating: PropTypes.bool,
    /**
     * The number of tickets selected
     */
    totalTicketQuantity: PropTypes.number,
    /**
     * The total cost to display in the footer.
     */
    totalCostDisplay: translationPropType,
    /**
     * True if the page form is submitting
     */
    isSubmitting: PropTypes.bool,
});
