import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { push } from 'react-router-redux';
import { joinPath } from '@eb/path-utils';
import { isSubmitting } from 'redux-form';

import {
    BASE_URL,
    CHECKOUT_STEP_CART,
    PROMO_CODE_FORM_NAME,
    TICKET_LEVEL,
    TICKETS_PAGE_FORM_NAME,
    TICKETS_PATH,
    WAITLIST_PATH,
} from '../../constants';
import {
    getTicketSelectDefaultCtaText,
    isFormSubmitted,
} from '../../utils/forms';
import { getPromoCodeFormProps } from '../../utils/promoCodes';
import {
    getEventTitle,
    getFormattedEventDate,
    getIsDraftEvent,
    getIsReservedSeating,
    getEventGroupType,
    getIsNewlyCreatedGroup,
} from '../../selectors/event';
import { getTemplateProps } from '../template';
import { resetEventAndTickets } from '../../actions/initialize';
import { initializeGrylls as initializeGryllsAction } from '../../actions/pixelAnalytics';
import {
    ticketQuantityChange as ticketQuantityChangeAction,
    ticketDonationChange as ticketDonationChangeAction,
    ticketVariantChange as ticketVariantChangeAction,
    updateSelectedPaymentConstraints as updateSelectedPaymentConstraintsAction,
} from '../../actions/tickets';
import { notifyParentTicketQuantityChange as notifyParentTicketQuantityChangeAction } from '../../actions/notifyParent';
import {
    getPromoCodeFormActionHandlers,
    promoCodeSubmit as promoCodeSubmitAction,
} from '../../actions/promoCode';
import { submitFromTicketSelectionPage } from '../../actions/order/common';
import { updateWaitingRoomStep as updateWaitingRoomStepAction } from '../../actions/waitingRoom';
import { navigateToSeriesPage as navigateToSeriesPageAction } from '../../actions/series';
import {
    goToPageBeforeJoin as goToPageBeforeJoinAction,
    resetGroupRegistration as resetGroupRegistrationAction,
} from '../../actions/groupRegistration';
import { getSalesDateForAllTickets } from './utils/ticketDateUtils';
import {
    getTotalSelectedTickets,
    getEventLevelValueForKey,
} from './utils/ticketUtils';
import {
    trackJoinWaitlistClick,
    trackPromoCodeFormOpen,
    trackPromoCodeFormShown,
    trackBackClick,
    trackCartIconClick,
} from '../../actions/eventAnalytics';
import { shouldShowBackToSeriesLink } from '../series/utils';
import {
    getFormattedTicketClassesByCategory,
    getShouldShowTicketCategoryGroupHeaders,
} from '../../selectors/tickets';
import * as constants from './constants';

export const getTicketClassMetaProps = (state) => {
    const ticketClasses = Object.keys(state.ticketsById).map(
        (t) => state.ticketsById[t],
    );
    const statusForAllTickets = getEventLevelValueForKey(
        ticketClasses,
        'statusKey',
    );
    const salesDateForAllTickets = getSalesDateForAllTickets(
        ticketClasses,
        statusForAllTickets,
    );

    // TODO: consider moving logic to a selector
    const shouldHideStatusOnTickets = !!(
        statusForAllTickets &&
        salesDateForAllTickets &&
        !(
            statusForAllTickets === constants.UNAVAILABLE ||
            statusForAllTickets === constants.SOLD_OUT
        )
    );

    return {
        statusForAllTickets,
        salesDateForAllTickets,
        shouldHideStatusOnTickets,
    };
};

const _getEventResellerProps = ({ ticketReseller, isSoldOut }) =>
    !isEmpty(ticketReseller)
        ? { ...ticketReseller, isEventSoldOut: isSoldOut }
        : {};

const _getFooterProps = (state) => {
    const ctaText = getTicketSelectDefaultCtaText(state);
    const tickets = state.tickets.ticketsById;

    return {
        ctaText,
        // XXX: getFormButtonProps gives us isSubmitDisabled, but we have to override it because isPristine isn't
        // working for ticketSelection right now as it's not properly hooked up to redux form
        //
        // https://jira.evbhome.com/browse/EB-47911
        isDisabled:
            isFormSubmitted(state, TICKETS_PAGE_FORM_NAME) ||
            getTotalSelectedTickets(tickets) < 1,
    };
};

const _mapStateToProps = (state) => ({
    templateProps: getTemplateProps({
        state,
        formName: TICKETS_PAGE_FORM_NAME,
    }),
    submitPromoCodeFormFailed:
        state.form[PROMO_CODE_FORM_NAME]?.submitFailed ?? false,
    errors: state.app.errors,
    isModal: state.app.isModal,
    isRegEvent: state.app.isRegEvent,
    shouldShowBackToSeriesLink: shouldShowBackToSeriesLink(state),
    shouldShowWaitingRoomCTA: state.waitingRoom.isWaitingRoomShown,
    ticketReseller: _getEventResellerProps(state.event),
    waitlistSettings: state.event.waitlistSettings,
    ticketClassesByCategory: getFormattedTicketClassesByCategory(state),
    shouldShowTicketCategoryGroupHeaders: getShouldShowTicketCategoryGroupHeaders(
        state,
    ),
    isFormSubmitting: isSubmitting(TICKETS_PAGE_FORM_NAME)(state),
    eventTitle: getEventTitle(state),
    eventDate: getFormattedEventDate(state),
    isReservedSeating: getIsReservedSeating(state),
    isDraftEvent: getIsDraftEvent(state),
    hasAdvancedTeamsEnabled: state.event.hasAdvancedTeamsEnabled,
    groupType: getEventGroupType(state),
    showNewlyCreatedGroupNotification: getIsNewlyCreatedGroup(state),
    ...getTicketClassMetaProps,
    ..._getFooterProps(state),
    ...getPromoCodeFormProps(state),
});

export const _mapDispatchToProps = (dispatch) => ({
    onPageWillMount: (shouldShowPromoCodeForm) => {
        dispatch(initializeGryllsAction());
        dispatch(updateWaitingRoomStepAction(CHECKOUT_STEP_CART));
        // Temporarily recording an action to know when the promo code
        // is shown in the checkoutWidget https://jira.evbhome.com/browse/EB-126752
        if (shouldShowPromoCodeForm) {
            dispatch(trackPromoCodeFormShown(TICKETS_PATH));
        }
    },
    onSubmit: () => dispatch(submitFromTicketSelectionPage()),
    onQuantityChange: (data) => {
        dispatch(ticketQuantityChangeAction(data, TICKETS_PATH));
        dispatch(updateSelectedPaymentConstraintsAction());
        dispatch(notifyParentTicketQuantityChangeAction(data));
    },
    onVariantQuantityChange: (data) =>
        dispatch(ticketVariantChangeAction(data)),
    onDonationChange: (data) => dispatch(ticketDonationChangeAction(data)),
    promoCodeFormHandlers: {
        ...getPromoCodeFormActionHandlers(dispatch),
        // Note: the onSubmit function should dispatch the action, but
        // should NOT return anything. Keep the curly braces!
        onSubmit: (data) => {
            dispatch(promoCodeSubmitAction(data, TICKETS_PATH))
                // Do nothing, errors are caught in promoCodeSubmitAction
                .catch(() => null);
        },
    },
    onPromoCodeLinkClick: () => dispatch(trackPromoCodeFormOpen(TICKETS_PATH)),
    onJoinWaitlist: () => {
        dispatch(trackJoinWaitlistClick(TICKETS_PATH, TICKET_LEVEL));
        dispatch(push(joinPath(BASE_URL, WAITLIST_PATH)));
    },
    onBackToSeries: () => {
        dispatch(trackBackClick(TICKETS_PATH));
        dispatch(navigateToSeriesPageAction());
    },
    onBackToGroupTypeSelection: () => {
        dispatch(trackBackClick(TICKETS_PATH));
        dispatch(resetEventAndTickets());
        dispatch(resetGroupRegistrationAction());
        dispatch(goToPageBeforeJoinAction());
    },
    onCartIconClick: (isPaneOpen) =>
        dispatch(trackCartIconClick(isPaneOpen, TICKETS_PATH)),
});

export const connectTicketSelectionPage = (ComponentClass) =>
    connect(_mapStateToProps, _mapDispatchToProps)(ComponentClass);
