import React, { Component } from 'react';
import omit from 'lodash/omit';
import findKey from 'lodash/findKey';
import PropTypes from 'prop-types';
import { translationPropType } from '@eb/i18n';
import classNames from 'classnames';
import gettext from '@eb/gettext';
import { lazyGettext } from '@eb/lazy-gettext';

import { ValidationFormField } from '@eb/eds-validation-form-field';
import { Select } from '@eb/eds-select';
import { SelectField } from '@eb/eds-input-field';
import { TextInput } from '@eb/eds-text-input';
import { InputField } from '@eb/eds-input-field';
import { NotificationBar } from '@eb/eds-notification';

import { TYPE_ERROR } from '@eb/eds-notification';
import * as constants from './constants';

/**
 * Due to redux-form implementation of event callbacks, we need to
 * transform the event arguments to match our contract
 *
 * @param {function} eventHandler handler to call when event is fired
 */
const wrapHandleEvent = (eventHandler) => (_event, newValue) => {
    if (eventHandler) {
        eventHandler(newValue);
    }
};

const ToplevelError = ({ error }) => {
    let component = null;

    if (error) {
        component = (
            <div className="eds-g-cell eds-g-cell-1-1">
                <NotificationBar type={TYPE_ERROR} hasCloseButton={true}>
                    <span>{error}</span>
                </NotificationBar>
            </div>
        );
    }

    return component;
};

const OptionalLegend = ({ legend, v2 }) => {
    let component = null;
    const componentClasses = classNames('eds-g-cell', 'eds-g-cell-1-1', {
        'eds-l-mar-bot-1': !v2,
        'eds-l-mar-bot-2': v2,
    });

    if (legend) {
        component = (
            <div
                className={componentClasses}
                data-spec="optional-address-field-legend"
            >
                <legend className="eds-label-primary eds-text-bl">
                    {legend}
                </legend>
            </div>
        );
    }

    return component;
};

const AddressFormInputField = ({
    v2,
    shouldDisplayError,
    disabled,
    onChange,
    onBlur,
    ...inputProps
}) => {
    let flowThroughProps = inputProps;
    const propsWithNoHideLabel = omit(inputProps, 'hideLabel');
    let InputComponent = TextInput;

    if (v2) {
        flowThroughProps = propsWithNoHideLabel;
        InputComponent = InputField;
    }

    return (
        <ValidationFormField
            v2={v2}
            shouldDisplayError={shouldDisplayError}
            onChange={wrapHandleEvent(onChange)}
            onBlur={wrapHandleEvent(onBlur)}
            {...flowThroughProps}
        >
            <InputComponent {...propsWithNoHideLabel} disabled={disabled} />
        </ValidationFormField>
    );
};

const AddressFormSelectField = ({
    v2,
    shouldDisplayError,
    disabled,
    onChange,
    onBlur,
    ...inputProps
}) => {
    let flowThroughProps = inputProps;
    const propsWithNoHideLabel = omit(inputProps, 'hideLabel');
    let SelectComponent = Select;

    if (v2) {
        flowThroughProps = propsWithNoHideLabel;
        SelectComponent = SelectField;
    }

    return (
        <ValidationFormField
            v2={v2}
            shouldDisplayError={shouldDisplayError}
            onChange={wrapHandleEvent(onChange)}
            onBlur={wrapHandleEvent(onBlur)}
            {...flowThroughProps}
        >
            <SelectComponent {...propsWithNoHideLabel} disabled={disabled} />
        </ValidationFormField>
    );
};

const REGION_COMPONENT_NAMES = {
    SelectField: (values, v2) => values && v2,
    Select: (values, v2) => values && !v2,
    InputField: (values, v2) => !values && v2,
    TextInput: (values, v2) => !values && !v2,
};

const REGION_COMPONENTS = {
    SelectField,
    Select,
    InputField,
    TextInput,
};

const RegionComponent = ({ v2, values, label, ...inputProps }) => {
    const props = omit(inputProps, ['hideLabel']);
    const componentName = findKey(REGION_COMPONENT_NAMES, (value, key) =>
        REGION_COMPONENT_NAMES[key](values, v2),
    );
    const NewRegionComponent = REGION_COMPONENTS[componentName];
    let placeholder = gettext('e.g. California');

    if (values) {
        // Translators: e.g. "Choose State" or "Choose Region"
        placeholder = gettext('Choose %(label)s', { label });
    }

    return (
        <NewRegionComponent
            {...props}
            values={values}
            label={label}
            placeholder={placeholder}
        />
    );
};

const PostalField = ({
    label,
    required,
    shown,
    name,
    id,
    onChange,
    onBlur,
    defaultValue,
    value,
    hideLabel,
    ...inputProps
}) => {
    let component = null;

    if (shown) {
        component = (
            <AddressFormInputField
                {...inputProps}
                name={name}
                label={label}
                required={required}
                defaultValue={defaultValue}
                hideLabel={hideLabel}
                id={id}
                data-spec="address-postal-field"
                maxLength={constants.MAX_POSTAL_CODE_LENGTH}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
            />
        );
    }

    return component;
};

export default class BaseAddressFields extends Component {
    static propTypes = {
        /**
         * An array of objects with country display names and values
         * ex. [{value: 'england', display: 'England'}, {value: 'usa', display: 'United States of America'}]
         **/
        countryValues: constants.COUNTRY_VALUES_PROP.isRequired,
        /**
         * An object with address fields to be set as default
         * ex. {address1: '123 Fifth St.', address2: 'Unit 1', city: 'San Francisco', region: 'CA', postal: '94103', country: 'United States'}
         **/
        defaultValues: constants.ADDRESS_VALUES_PROP,
        /**
         * An object with address fields to be disabled
         * ex. {country: true}
         */
        disabledFields: constants.DISABLED_FIELDS_PROP,
        /**
         * Error message
         * ex. 'The address you entered is invalid'
         **/
        error: PropTypes.node,
        /**
         * Optional prefix in front of ids
         **/
        idPrefix: PropTypes.string,
        /**
         * Optional prefix in front of name values
         **/
        namePrefix: PropTypes.string,
        /**
         * A callback triggered when the value of input changes, passing in the value and name of the field changes
         * ex. (value, name) => { console.log(value, name) // 'California', 'region' }
         **/
        onChange: PropTypes.func,
        /**
         * A callback triggered when the input is blurred.
         **/
        onBlur: PropTypes.func,
        /**
         * A callback triggered when the value of the country changes
         * ex. (value) => {}
         **/
        onCountryChange: PropTypes.func,
        /**
         * Label for postal code, ex. 'Zip Code'
         **/
        postalLabel: translationPropType,
        /**
         * Whether or not the postal code is required
         **/
        postalRequired: PropTypes.bool,
        /**
         * Whether or not the postal code should be shown
         **/
        postalShown: PropTypes.bool,
        /**
         * Label for region, ex. 'State'
         **/
        regionLabel: translationPropType,
        /**
         * Whether or not the region is required
         **/
        regionRequired: PropTypes.bool,
        /**
         * An array of objects with values and display pertaining to a region
         * ex. [{value: ca, display:California}, {value:quebec, dispaly:Quebec}]
         * If regionValues is not supplied, region will render as a TextInput
         **/
        regionValues: constants.REGION_VALUES_PROP,
        /**
         * Whether or not the address is required, adds a * next to the fields if true
         **/
        required: PropTypes.bool,
        /**
         * An object with address fields that overrides defaultValues with fixed values
         * ex. {address1: '123 Fifth St.', address2: 'Unit 1', city: 'San Francisco', region: 'CA', postal: '94103', country: 'United States'}
         **/
        values: constants.ADDRESS_VALUES_PROP,
        /**
         * Text to render label-like above the fields
         */
        label: PropTypes.node,
        /**
         * Hides label above the fields
         */
        hideLabels: PropTypes.bool,

        /**
         * Determines whether it should use or not a FormField wrapper.
         */
        v2: PropTypes.bool,

        /**
         * Function to determine whether we should
         * display an error given the input state. Has a sensible default.
         */
        shouldDisplayError: PropTypes.func,
    };

    static defaultProps = {
        idPrefix: '',
        namePrefix: '',
        required: false,
        defaultValues: {},
        disabledFields: {},
        values: {},
        regionLabel: lazyGettext('State'),
        regionRequired: true,
        postalLabel: lazyGettext('Postal Code'),
        postalRequired: true,
        postalShown: true,
    };

    _handleChange(name, value) {
        if (this.props.onChange) {
            this.props.onChange(value, name);
        }
    }

    _handleBlur(name, value) {
        if (this.props.onBlur) {
            this.props.onBlur(value, name);
        }
    }

    _handleCountryChange(value) {
        this._handleChange('country', value);

        if (this.props.onCountryChange) {
            this.props.onCountryChange(value);
        }
    }

    render() {
        const {
            countryValues,
            required,
            disabledFields,
            idPrefix,
            namePrefix,
            error,
            regionLabel,
            regionRequired,
            regionValues,
            postalLabel,
            postalRequired,
            postalShown,
            defaultValues,
            values,
            label,
            hideLabels,
            v2,
            shouldDisplayError,
        } = this.props;
        const makeName = (suffix) => namePrefix + suffix;
        const makeId = (suffix) => idPrefix + suffix;
        const extraMarginsForV2 = v2 ? { 'eds-l-mar-top-2': v2 } : {};
        const formFieldClassNames = classNames(
            'eds-g-cell',
            'eds-g-cell-1-1',
            'eds-g-cell-mn-1-2',
            'eds-l-mn-pad-right-2',
            'eds-l-md-pad-right-2',
            'eds-l-mw-pad-right-2',
            'eds-l-ln-pad-right-2',
            'eds-l-lg-pad-right-2',
            'eds-l-lw-pad-right-2',
            extraMarginsForV2,
        );
        const addressClassNames = classNames(
            'eds-g-cell',
            'eds-g-cell-1-1',
            'eds-g-cell-mn-1-2',
            'eds-l-mn-pad-left-2',
            'eds-l-md-pad-left-2',
            'eds-l-mw-pad-left-2',
            'eds-l-ln-pad-left-2',
            'eds-l-lg-pad-left-2',
            'eds-l-lw-pad-left-2',
            extraMarginsForV2,
        );
        const regionClassNames = classNames(
            'eds-g-cell',
            'eds-g-cell-1-2',
            'eds-g-cell-mn-3-12',
            'eds-l-mn-pad-right-2',
            'eds-l-md-pad-right-2',
            'eds-l-mw-pad-right-2',
            'eds-l-ln-pad-right-2',
            'eds-l-lg-pad-right-2',
            'eds-l-lw-pad-right-2',
            'eds-l-mn-pad-left-2',
            'eds-l-md-pad-left-2',
            'eds-l-mw-pad-left-2',
            'eds-l-ln-pad-left-2',
            'eds-l-lg-pad-left-2',
            'eds-l-lw-pad-left-2',
            extraMarginsForV2,
        );
        const postalClassNames = classNames(
            'eds-g-cell',
            'eds-g-cell-1-2',
            'eds-g-cell-mn-3-12',
            'eds-l-pad-left-2',
            extraMarginsForV2,
        );

        return (
            <div className="eds-g-grid eds-base-address-fields">
                <div className="eds-g-group">
                    <fieldset>
                        <ToplevelError error={error} />
                        <OptionalLegend legend={label} v2={v2} />
                        <div
                            className={formFieldClassNames}
                            data-spec="address-one-field-wrapper"
                        >
                            <AddressFormInputField
                                name={makeName('address1')}
                                label={gettext('Address 1')}
                                placeholder={gettext('e.g. 155 5th Street')}
                                required={required}
                                disabled={disabledFields.address1}
                                hideLabel={hideLabels}
                                defaultValue={defaultValues.address1}
                                v2={v2}
                                id={makeId('address1')}
                                onChange={this._handleChange.bind(
                                    this,
                                    'address1',
                                )}
                                onBlur={this._handleBlur.bind(this, 'address1')}
                                value={values.address1}
                                data-spec="address-one-field"
                                shouldDisplayError={shouldDisplayError}
                            />
                        </div>
                        <div className={addressClassNames}>
                            <AddressFormInputField
                                name={makeName('address2')}
                                label={gettext('Address 2')}
                                hideLabel={hideLabels}
                                defaultValue={defaultValues.address2}
                                disabled={disabledFields.address2}
                                v2={v2}
                                id={makeId('address2')}
                                onChange={this._handleChange.bind(
                                    this,
                                    'address2',
                                )}
                                onBlur={this._handleBlur.bind(this, 'address2')}
                                placeholder={gettext('e.g. Apt, Suite, Bldg')}
                                shouldDisplayError={shouldDisplayError}
                            />
                        </div>
                        <div className={formFieldClassNames}>
                            <AddressFormInputField
                                name={makeName('city')}
                                label={gettext('City')}
                                hideLabel={hideLabels}
                                placeholder={gettext('e.g. San Francisco')}
                                required={required}
                                disabled={disabledFields.city}
                                defaultValue={defaultValues.city}
                                v2={v2}
                                id={makeId('city')}
                                onChange={this._handleChange.bind(this, 'city')}
                                onBlur={this._handleBlur.bind(this, 'city')}
                                value={values.city}
                                shouldDisplayError={shouldDisplayError}
                            />
                        </div>
                        <div className={regionClassNames}>
                            <ValidationFormField
                                name={makeName('region')}
                                label={regionLabel}
                                hideLabel={hideLabels}
                                required={required && regionRequired}
                                values={regionValues}
                                v2={v2}
                                shouldDisplayError={shouldDisplayError}
                            >
                                <RegionComponent
                                    id={makeId('region')}
                                    data-spec="address-region-field"
                                    values={regionValues}
                                    onChange={this._handleChange.bind(
                                        this,
                                        'region',
                                    )}
                                    onBlur={this._handleBlur.bind(
                                        this,
                                        'region',
                                    )}
                                    value={values.region}
                                    disabled={disabledFields.region}
                                    label={regionLabel}
                                    v2={v2}
                                />
                            </ValidationFormField>
                        </div>
                        <div className={postalClassNames}>
                            <PostalField
                                shown={postalShown}
                                name={makeName('postal')}
                                label={postalLabel}
                                hideLabel={hideLabels}
                                placeholder={gettext('e.g. 94103')}
                                required={required && postalRequired}
                                disabled={disabledFields.postal}
                                id={makeId('postal')}
                                onChange={this._handleChange.bind(
                                    this,
                                    'postal',
                                )}
                                onBlur={this._handleBlur.bind(this, 'postal')}
                                defaultValue={defaultValues.postal}
                                value={values.postal}
                                v2={v2}
                                shouldDisplayError={shouldDisplayError}
                            />
                        </div>
                        <div className={formFieldClassNames}>
                            <AddressFormSelectField
                                name={makeName('country')}
                                label={gettext('Country')}
                                hideLabel={hideLabels}
                                placeholder={gettext('Choose a country')}
                                required={required}
                                disabled={disabledFields.country}
                                values={countryValues}
                                id={makeId('country')}
                                onChange={this._handleCountryChange.bind(this)}
                                onBlur={this._handleBlur.bind(this, 'country')}
                                data-spec="address-country-field"
                                value={values.country}
                                v2={v2}
                                shouldDisplayError={shouldDisplayError}
                            />
                        </div>
                    </fieldset>
                </div>
            </div>
        );
    }
}
