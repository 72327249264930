import get from 'lodash/get';
import { getCountryNameFromTwoLetterCode } from '@eb/intl';

import gettext from '@eb/gettext';
import {
    INVITATION_REQUIRED,
    PASSWORD_EXPIRED,
    PASSWORD_REQUIRED,
} from '../constants';
import { EVENT_DRAFT_STATUS } from '../containers/status/constants';

import {
    getFormattedEventDate as formatEventDate,
    getFormattedDueDate as formatDueDate,
} from '@eb/event-date-time';

import { createSelector } from './utils/selector';

/**
 * @param   {string}   country
 * @param   {string}   userLocale
 * @return  {Boolean}  True if the userLocale is determined to be the same as the country.
 */
const _isLocaleInCountry = (country, userLocale) => {
    if (userLocale && country) {
        return userLocale.slice(-2).toLowerCase() === country.toLowerCase();
    }

    return false;
};

/**
 * Return a formatted string representing the event's location. If the user's locale is
 * the same as the country for the event venue, don't display the country to the user.
 *
 * @param  {string} localizedAreaDisplay    The area information for the event venue location. Example: "Palm Springs, CA"
 * @param  {string} country                 The country code for the event venue location. Example: "US"
 * @param  {string} userLocale              The locale of the browser. Example: "en_US"
 * @return {string}                         Formatted location string. Example: "Palm Springs, CA, United States"
 */
export const _getFormattedEventLocation = (
    { localizedAreaDisplay, country } = {},
    userLocale,
) => {
    let formattedLocation = '';

    if (localizedAreaDisplay && country) {
        const localizedCountryName = getCountryNameFromTwoLetterCode(country);

        // Translators: The 'Area' is the full city name. For example, 'Palm Springs, CA'.
        const localizedAreaWithCountry = gettext(
            '%(localizedAreaDisplay)s, %(localizedCountryName)s',
            {
                localizedAreaDisplay,
                localizedCountryName,
            },
        );

        formattedLocation = _isLocaleInCountry(country, userLocale)
            ? localizedAreaDisplay
            : localizedAreaWithCountry;
    }

    return formattedLocation;
};

/**
 * Selector that can be used to retrieve the event id
 */
export const getEventId = (state) => get(state, 'event.id', '');

/**
 * Selector that can be used to retrieve the event Title
 */
export const getEventTitle = (state) => get(state, 'event.name.text', '');

export const getIsEventOnline = (state) =>
    get(state, 'event.onlineEvent', false);

/**
 * Selector that can be used to retrieve the full event date range string to display
 */
export const getFormattedEventDate = createSelector(
    (state) => state.event,
    (state) => state.app.locale,
    (event, userLocale) => formatEventDate(event, userLocale),
);

/**
 * Selector that can be used to retrieve the due payment date string to display
 */
export const getFormattedDueDate = createSelector(
    (state) => state.event,
    (state) => state.app.locale,
    (state) => state.app.deferredPaymentDueDays,
    (event, userLocale, deferredPaymentDueDays) =>
        formatDueDate(event, userLocale, deferredPaymentDueDays),
);

/**
 * Selector that can be used to retrieve the event's location.
 */
export const getFormattedEventLocation = createSelector(
    (state) => get(state, 'event.venue.address'),
    (state) => state.app.locale,
    (address, userLocale) => _getFormattedEventLocation(address, userLocale),
);

/**
 * Selector that can be used to retrieve the event's reserved seating prop
 */
export const getIsReservedSeating = (state) =>
    get(state, 'event.isReservedSeating', false);

/**
 * Selector that can be used to check if the event is Draft
 */
export const getIsDraftEvent = (state) =>
    state.event.status === EVENT_DRAFT_STATUS;

/**
 * Selector that can be used to return the checkout groups
 */
export const getCheckoutGroups = (state) =>
    get(state, 'event.checkoutInfo.checkoutGroups', []);

/**
 * Selector that can be used to return the seatmap thumbnail src
 */
export const getEventSeatMapThumbnailSrc = (state) =>
    get(state, 'event.properties.seatmapThumbnailUrl');

/**
 * Selector that can be used to get event's shipping settings.
 * @param {Object} state
 */
export const getEventShippingSettings = (state) =>
    get(state, 'event.shippingSettings');

/**
 * Selector that can be used to know if the current event is protected
 * An event is considered protected if isProtected is true, and privacySetting equals invite_only or password_protected
 * Password_expired is a special case that exists only on UI side: it is not returned by the BE, and is set via UPDATE_EVENT_PRIVACY_SETTING action when the event's password
 * is either changed by the organizer while the user is buying, or when the password has expired.
 * @param {Object} state the application state
 * @return {Boolean}
 */
export const getIsEventProtected = (state) => {
    const {
        event: { privacySetting, properties: { isProtected } = {} },
    } = state;

    return Boolean(
        isProtected &&
            [PASSWORD_EXPIRED, PASSWORD_REQUIRED, INVITATION_REQUIRED].includes(
                privacySetting,
            ),
    );
};

/**
 * Selector that can be used to return the group type in case of group registration
 */
export const getEventGroupType = (state) =>
    get(state, 'event.groupSettings.groupType');

export const getEventImageSrc = (state) =>
    get(state, 'event.properties.image', null);

export const getWaitlistTicketId = (state) =>
    get(state, 'event.waitlistSettings.ticketId', null);

export const getEventGroupSettings = (state) => {
    const {
        teamPasswordIncluded: hasPassword,
        teamPasswordRequired: isPasswordRequired,
        teamPstIncluded: hasPreferredStartTime,
        teamPstRequired: isPreferredStartTimeRequired,
        teamPstChoices: preferredStartTimeChoices,
    } = get(state, 'event.groupSettings');

    return {
        hasPassword,
        isPasswordRequired,
        hasPreferredStartTime,
        isPreferredStartTimeRequired,
        preferredStartTimeChoices,
    };
};

export const getIsNewlyCreatedGroup = (state) =>
    get(state, 'groupRegistration.teamSettings.isNewlyCreatedGroup');

export const getTeamSettingsErrors = (state) =>
    get(state, 'groupRegistration.teamSettings.errors');

export const getTeamSettings = (state) =>
    get(state, 'groupRegistration.teamSettings');

export const getCurrency = (state) => get(state, 'event.currency');

/**
 * Selector that can be used to retrieve the event url
 */
export const getEventUrl = (state) => get(state, 'event.url');

export const getEventCountryCode = (state) =>
    get(state, 'event.venue.address.country', '');
