import React from 'react';
import gettext from '@eb/gettext';
import classNames from 'classnames';
import zxcvbn from 'zxcvbn';

import {
    PASSWORD_TOO_SHORT,
    PASSWORD_MIN_LENGTH,
    PASSWORD_STRENGTH_LABELS,
} from './constants';

export const getPasswordStrength = (inputPassword) => {
    /*
    Calculate the strength statistics for input passwords with zxcvbn
    */

    let passwordStrengthLabel = PASSWORD_TOO_SHORT;
    let passwordScore = 0;
    let isPasswordValidLength = false;
    let fullPasswordStats;

    if (inputPassword && inputPassword.length >= PASSWORD_MIN_LENGTH) {
        fullPasswordStats = zxcvbn(inputPassword);
        passwordStrengthLabel =
            PASSWORD_STRENGTH_LABELS[fullPasswordStats.score];
        passwordScore = fullPasswordStats.score;

        // set the strength bar to be 25% of 25% for a score of zero ("very weak")
        // so the user gets some feedback
        if (passwordScore === 0) {
            passwordScore = 0.25;
        }

        isPasswordValidLength = true;
    }

    return {
        passwordScore,
        passwordStrengthLabel,
        isPasswordValidLength,
    };
};

export const getPasswordStrengthLabelMarkup = (
    passwordStrengthLabel,
    isPasswordValidLength,
) => {
    const passwordStrengthLabelClassName = classNames({
        'eds-text-weight--heavy': isPasswordValidLength,
    });
    const gettextWithMarkup = {
        // Translators: This indicates password strength (e.g. "Your password is weak")
        __html: gettext(
            '<span>Your password </span><span class="%(passwordStrengthLabelClassName)s">%(passwordStrengthLabel)s</span>',
            {
                passwordStrengthLabelClassName,
                passwordStrengthLabel,
            },
        ),
    };

    // eslint-disable-next-line react/no-danger
    return (
        <div
            className="eds-l-pad-top-2 eds-text-bs"
            dangerouslySetInnerHTML={gettextWithMarkup}
        />
    );
};
