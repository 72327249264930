import {
    getEventDeliveryMethodSettings as getEventDeliveryMethodSettingsApi,
    getPaymentConstraintsByEventId as getPaymentConstraintsByEventIdApi,
    getAvailablePaymentMethodIssuersInformation as getAvailablePaymentMethodIssuersInformationApi,
    getShippingRates as getShippingRatesApi,
} from '../api/event';
import { transformEventData } from '../api/transformations/event';
import { transformInstallmentsData } from '../api/transformations/installments';
import { transformShippingSettings } from '../api/transformations/deliveryMethod';
import { anyOrderAttendeesTicketClassHasShipping } from '../utils/deliveryMethod';
import { saveEventShippingSettingsAction } from './order';

export const INITIALIZE_EVENT = 'initializeEvent';
export const SAVE_EVENT_SHIPPING_RATES = 'saveEventShippingRates';
export const UPDATE_EVENT_PRIVACY_SETTING = 'updateEventPrivacySetting';
export const UPDATE_AVAILABLE_INSTALLMENTS = 'updateAvailableInstallments';

export const initializeEvent = (data) => ({
    type: INITIALIZE_EVENT,
    payload: transformEventData(data),
});

export const _updateAvailableInstallments = (data) => ({
    type: UPDATE_AVAILABLE_INSTALLMENTS,
    payload: data,
});

export const _saveShippingRates = (data) => ({
    type: SAVE_EVENT_SHIPPING_RATES,
    payload: data,
});

/**
 * Returns a promise to get and save event level shipping settings if
 * shipping is available for the selected tickets, and calls a method to
 * update any applicable backup delivery methods on each ticket class.
 */
export const getEventShippingSettings = () => (dispatch, getState) => {
    const {
        event: {
            id: eventId,
            currency,
            organization: { id: orgId } = { id: null },
        },
        order: { attendees = [] },
        app: { envCountryCode },
    } = getState();

    getShippingRatesApi({ eventId, currency, envCountryCode, orgId }).then(
        ({ response }) => {
            dispatch(_saveShippingRates(response));
        },
    );
    if (anyOrderAttendeesTicketClassHasShipping(attendees)) {
        return getEventDeliveryMethodSettingsApi(eventId)
            .then((data) => data.response)
            .then((response) => {
                dispatch(
                    saveEventShippingSettingsAction(
                        transformShippingSettings(response),
                    ),
                );
            });
    }
    return Promise.resolve();
};

export const getPaymentConstraintsByEventId = async (data) => {
    const { event } = data;

    return getPaymentConstraintsByEventIdApi(event.id);
};

/**
 * Updates the privacy setting of the event, a typical use is when the event's password has expired or was changed by the organizer
 * @param {Object} data: the new privacy setting to set
 */
export const updateEventPrivacySetting = (data) => ({
    type: UPDATE_EVENT_PRIVACY_SETTING,
    payload: data,
});

export const getPaymentMethodIssuersInformation = () => async (
    dispatch,
    getState,
) => {
    const {
        event: { id: eventId },
    } = getState();

    const data = await getAvailablePaymentMethodIssuersInformationApi(eventId);
    const normalizedData = transformInstallmentsData(data.response);

    return dispatch(_updateAvailableInstallments(normalizedData));
};
