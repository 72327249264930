import { UserAction, UserActionType } from '../actions/user';
import { UserNamespace } from '../state/user';

export const userReducer = (
    state: UserNamespace = {
        userInstruments: [],
        hasPassword: false,
        hasSocialLogin: false,
    },
    action: UserAction,
): UserNamespace => {
    switch (action.type) {
        case UserActionType.initializeUser:
            return {
                ...state,
                ...(action.payload ?? {}),
            };
        case UserActionType.updateUserInstruments:
            return {
                ...state,
                userInstruments: action.payload,
            };

        case UserActionType.setVirtualIncentive:
            return {
                ...state,
                virtualIncentive: action.payload,
            };

        case UserActionType.setUserAsLoggedIn:
            return {
                ...state,
                ...action.payload,
            };
        case UserActionType.setUserSiginInMethods:
            return {
                ...state,
                ...action.payload,
            };
        case UserActionType.clearUserData:
            return {
                userInstruments: [],
                hasPassword: false,
                hasSocialLogin: false,
            };

        default:
            return state;
    }
};
