import { joinPath } from '@eb/path-utils';
import {
    ERROR_TO_STATUS_PATH_MAPPING,
    EVENT_ALL_TICKETS_REQUIRE_UNLOCK_STATUS,
    EVENT_SOLD_OUT_WITH_CAPACITY_WAITLIST_STATUS,
    EVENT_SOLD_OUT_WITH_TICKET_RESELLER_STATUS,
} from '../containers/status/constants';
import {
    BASE_URL,
    EXISTING_ORDER_PATH,
    GROUP_REG_JOIN_PATH,
    GROUP_REG_TYPE_SELECTION_PATH,
    PAY_IN_PATH,
    PROTECTED_PATH,
    RESERVED_TICKETS_PATH,
    SERIES_PATH,
    STATUS_PATH,
    TICKETS_PATH,
    TICKETS_WITH_CAPACITY_WAITLIST_PATH,
    TICKETS_WITH_TICKET_RESELLER_PATH,
    UNLOCK_TICKETS_PATH,
    WAITING_ROOM_PATH,
} from '../constants';
import { getIsEventProtected } from '../selectors/event';
import { getGroupRegistrationTickets } from '../selectors/tickets';
import { getStatusForEvent } from './status';
import {
    shouldShowWaitlistDisabledStatusPage,
    shouldShowWaitlistInvalidStatusPage,
} from './waitlist';
import isEmpty from 'lodash/isEmpty';

/* Waiting room should be displayed if:
    1) Event has queue activated.
    2) Client token indicates they should be in line (isLive is false).
*/
const _shouldShowWaitingRoom = ({ isActivatedForEvent, isLive }) =>
    isActivatedForEvent && !isLive;

/**
 * Returns the correct path to group group registration:
 * If we have individual tickets => Groupt type selection
 * Else Group join page
 * @param {Object} state the application's current state
 */
const _getGroupRegistrationPath = (state) => {
    const {
        event: { groupSettings = {} },
        groupRegistration = {},
    } = state;

    if (isEmpty(groupRegistration)) {
        const path = groupSettings.hasIndividualTicket
            ? GROUP_REG_TYPE_SELECTION_PATH
            : GROUP_REG_JOIN_PATH;

        return joinPath(BASE_URL, path);
    }

    // we first need to check if we have tickets for this group type
    const tickets = getGroupRegistrationTickets(state);

    if (tickets.length > 0) {
        return joinPath(BASE_URL, TICKETS_PATH);
    }

    return joinPath(BASE_URL, UNLOCK_TICKETS_PATH);
};

export const getTicketsSelectionPagePath = (state) => {
    const {
        waitingRoom,
        event: {
            isSeriesParent,
            isReservedSeating,
            hasAdvancedTeamsEnabled,
        } = {},
    } = state;

    if (_shouldShowWaitingRoom(waitingRoom)) {
        return joinPath(BASE_URL, WAITING_ROOM_PATH);
    }

    if (isSeriesParent) {
        return joinPath(BASE_URL, SERIES_PATH);
    }

    if (isReservedSeating) {
        return joinPath(BASE_URL, RESERVED_TICKETS_PATH);
    }

    /**
     * Only route to group join and registration selections pages
     * when team settings are enabled, and you are not coming from ticket selection page
     * This enables us to stay on ticket selection page in case of error after having chosen the group reg type.
     */
    if (hasAdvancedTeamsEnabled) {
        return _getGroupRegistrationPath(state);
    }

    return joinPath(BASE_URL, TICKETS_PATH);
};

/**
 * Additional 'or' conditions can be added here in the case of
 * other errors being returned from the backend
 * @param {string} eventStatus
 * @param {array} errors
 * @return {Boolean}
 */
const _shouldShowErrorStatusPage = (eventStatus, errors) =>
    shouldShowWaitlistInvalidStatusPage(eventStatus, errors) ||
    shouldShowWaitlistDisabledStatusPage(errors);

/**
 * Returns the correct initial path to be routed to on app load
 * @param {object} state    the full state tree
 */
export const getInitialPath = (state) => {
    const {
        app: { errors, waitlistCode, existingOrderId },
        event,
        tickets,
    } = state;

    const { payins: { isPayinsFlow } = {} } = state.app;

    // Protected events surpasses all conditions:
    // If the event is proteced, we should always show FIRST the protected banner.
    if (getIsEventProtected(state)) {
        return joinPath(BASE_URL, PROTECTED_PATH);
    }

    // If we have an order ID, we consider the order has been created manually previously
    // Therefore: no waiting room, no event series parent, no event statuses (no sold out, sales ended, unlock...)
    if (existingOrderId) {
        return joinPath(BASE_URL, EXISTING_ORDER_PATH);
    }

    // Trigger the PayIn Flow
    if (isPayinsFlow) {
        return joinPath(BASE_URL, PAY_IN_PATH);
    }

    let initialPath = getTicketsSelectionPagePath(state);

    const eventStatus = getStatusForEvent(event, tickets, waitlistCode);

    if (eventStatus) {
        if (_shouldShowErrorStatusPage(eventStatus, errors)) {
            const error = errors[0].error;
            const path = ERROR_TO_STATUS_PATH_MAPPING[error];

            initialPath = joinPath(BASE_URL, STATUS_PATH, path);
        } else if (eventStatus === EVENT_ALL_TICKETS_REQUIRE_UNLOCK_STATUS) {
            // In case we have group settings enabled, the group selection is always shown first before the access code page.
            const path = event.hasAdvancedTeamsEnabled
                ? _getGroupRegistrationPath(state)
                : UNLOCK_TICKETS_PATH;

            initialPath = joinPath(BASE_URL, path);
        } else if (
            eventStatus === EVENT_SOLD_OUT_WITH_CAPACITY_WAITLIST_STATUS
        ) {
            initialPath = joinPath(
                BASE_URL,
                TICKETS_WITH_CAPACITY_WAITLIST_PATH,
            );
        } else if (eventStatus === EVENT_SOLD_OUT_WITH_TICKET_RESELLER_STATUS) {
            initialPath = joinPath(BASE_URL, TICKETS_WITH_TICKET_RESELLER_PATH);
        } else {
            initialPath = joinPath(BASE_URL, STATUS_PATH, eventStatus);
        }
    }
    return initialPath;
};
