import { Schema } from 'normalizr';
import { transformUtil } from '@eb/transformation-utils';
import { EventbriteBackendEvent, EventbriteFrontendEvent } from './event.types';

const EVENT_SCHEMA = new Schema('event');

const EVENT_RESPONSE_FIELDS = [
    'id',
    'name',
    'text',
    'url',
    'currency',
    'start',
    'utc',
    'timezone',
    'end',
    'has_tax_country_exemptions',
    'hide_start_date',
    'hide_end_date',
    'capacity',
    'is_free',
    'status',
    'properties',
    'is_ended',
    'is_reserved_seating',
    'is_series',
    'is_series_parent',
    'is_sales_ended',
    'tx_time_limit',
    'shareable',
    'image',
    // Keys for organization vertical
    'organization',
    'name',
    'vertical',
    'id',
    // Keys for listing_display_flags
    'listing_display_flags',
    'should_show_promo_code_link',
    'should_show_timezone',
    'should_show_waitlist_at_capacity',
    'status_is_not_yet_started',
    // Keys for venue
    'venue',
    'address',
    'localized_area_display',
    'country',
    // Keys for payment options
    'payment_options',
    'has_payment_options',
    'seatmap_thumbnail_url',
    'should_accept_amex_for_payment',
    'should_accept_discover_for_payment',
    'should_accept_visa_for_payment',
    'should_accept_visa_debit_for_payment',
    'should_accept_mastercard_for_payment',
    'should_accept_mastercard_debit_for_payment',
    'should_show_paypal_payment_method',
    'should_accept_ideal',
    'should_accept_boleto_bancario',
    'should_accept_invoice',
    'should_accept_offline',
    'should_accept_oxxo',
    'should_accept_pagofacil',
    'should_accept_rapipago',
    'should_accept_sepa_direct_debit',
    'should_accept_sofort',
    // Keys for ticket availability info
    'remaining_capacity',
    'has_available_hidden_tickets',
    'has_available_tickets',
    'is_sold_out',
    // Keys for discount-related info
    'has_access_code',
    'show_pick_a_seat',
    // Keys for waitlist settings
    'waitlist_settings',
    'auto_respond',
    'full',
    'information_collected',
    'ticket_id',
    // Keys for ticket resellers
    'ticket_reseller',
    'label',
    'text',
    // Keys for checkout groupings
    'checkout_info',
    'checkout_groups',
    'title',
    // Keys for protected event
    'privacy_setting',
    'is_protected',
    // Keys for event sales status
    'event_sales_status',
    'tickets_sold_out',
    'default_message',
    'message',
    'message_code',
    'message_type',
    'tickets_with_sales_ended',
    // Keys for groups settings
    'group_settings',
    'group_type',
    'has_advanced_teams_enabled',
    'has_individual_ticket',
    'has_teams',
    'max_quantity',
    'team_password_included',
    'team_password_required',
    'team_pst_included',
    'team_pst_required',
    'team_pst_choices',
    // Keys for online events
    'online_event',
];

/**
 * Transforms the service response into a map by ID, performs a whiteList to remove unwanted fields,
 * and finally applies camelcase to each key.
 **/
export const transformEventData = (data: {
    event: Partial<EventbriteBackendEvent>;
    ticketAvailabilityInfo: Partial<EventbriteBackendEvent>;
}) => {
    // We use a single schema for event and ticketAvailabilityInfo because
    // ticketAvailabilityInfo has no id/is technically keyed by event
    const eventData = {
        ...data.event,
        ...data.ticketAvailabilityInfo,
    } as EventbriteBackendEvent;

    const transformedEvent = transformUtil({
        response: eventData,
        schema: EVENT_SCHEMA,
        fields: EVENT_RESPONSE_FIELDS,
        isWhiteList: true,
    });

    if (!transformedEvent) {
        return null;
    }

    return {
        event: transformedEvent.entities.event[
            transformedEvent.result
        ] as EventbriteFrontendEvent,
    };
};
