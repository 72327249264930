export const ISO_8601_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const ISO_8601_DATE_FORMAT = 'YYYY-MM-DD';

export const NUMERAL_DATE_FORMAT = 'L';

export const DEFAULT_DATETIME_FORMAT = 'llll';
export const DEFAULT_TIMEZONE = 'UTC';
export const DEFAULT_LANGUAGE = 'en_us';

export const FULL_TIME_FORMAT = 'HH:mm:ss A';
export const DEFAULT_TIME_FORMAT = 'LT';
