// External libraries
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';

// LocaL imports
import PriceInformation from './PriceInformation';
import TicketCardStatus from './TicketCardStatus';
import TicketCardGraphicWrapper from './TicketCardGraphicWrapper';
import TicketPaymentConstraints from './TicketPaymentConstraints';
import RemainingTickets from './RemainingTickets';
import {
    CSS_TICKET_DISABLED,
    SPEC_TICKET_CONTENT,
} from '../../containers/ticketSelection/constants';
import TicketAvailabilityField from '../../containers/ticketSelection/TicketAvailabilityField';
import {
    DATA_PROP_TYPES,
    NOT_AVAILABLE_FOR_SALE_STATUSES,
    DONATION_FEE_TAX_COPIES,
} from './constants';
import { isDonationTicket } from '../../utils/tickets';
import {
    shouldDisableTicketContent,
    shouldShowPrice,
} from '../../containers/ticketSelection/utils/ticketUtils';
import TicketDescription from './TicketDescription';

const donationFeeTaxCases = (includeFee, includeTax) => ({
    feePassedOn: () => !includeFee && includeTax === undefined,
    feeAbsorbed: () => includeFee && includeTax === undefined,
    feeAndTaxPassedOn: () => !includeFee && includeTax === false,
    feeAbsorbedTaxPassedOn: () => includeFee && includeTax === false,
    feePassedOnTaxAbsorbed: () => !includeFee && includeTax === true,
    feeAndTaxAbsorbed: () => includeFee && includeTax === true,
});

// Generate fee and tax text copy for donation ticket.
const _getDonationFeeTaxText = (includeFee, includeTax) => {
    const getDonationFeeTaxCases = donationFeeTaxCases(includeFee, includeTax);
    const getDonationFeeTaxCasesKeys = Object.keys(getDonationFeeTaxCases);
    const currentDonationFeeTaxCase = (feeTaxCase) =>
        getDonationFeeTaxCases[feeTaxCase]() === true;
    const donationFeeTaxCase = getDonationFeeTaxCasesKeys.find((feeTaxCase) =>
        currentDonationFeeTaxCase(feeTaxCase),
    );
    const feeTaxCopy = DONATION_FEE_TAX_COPIES[donationFeeTaxCase];

    return feeTaxCopy;
};

export default class TicketContent extends React.PureComponent {
    static propTypes = {
        ...DATA_PROP_TYPES,

        /**
         * A node can be placed at the head of the ticket content card. E.g. ticket image, color cirle, icon, etc.
         */
        graphic: PropTypes.node,
        /**
         * Callback for when the selected value changes
         **/
        onQuantityChange: PropTypes.func,
        /**
         * Whether or not the card is expanded initially for a ticket with public discounts
         */
        initiallyExpanded: PropTypes.bool,
        /**
         * Callback to be triggered when the content is expanded or collapsed for a ticket with public discounts
         * Ex. (isExpanded) => {};
         */
        onToggle: PropTypes.func,
    };

    render() {
        const {
            id,
            name,
            hideSelect,
            quantityRemaining,
            shouldHideStatus,
            hideSaleDates,
            statusKey,
            salesStart,
            salesEnd,
            displayPrice,
            feeAndTax,
            description,
            primaryPrice,
            includeFee,
            includeTax,
            isTicketTiered,
            graphic,
            paymentConstraints,
            isFormSubmitting,
        } = this.props;

        let paymentConstraintsDisplay = null;
        let priceInfo = null;
        let ticketFeeTaxDescription = null;
        let hideStatus = false;

        const dataAutomation = `ticket-card-content-${id}`;
        const canBeSelected =
            !hideSelect &&
            !includes(NOT_AVAILABLE_FOR_SALE_STATUSES, statusKey);

        if (!isEmpty(paymentConstraints)) {
            paymentConstraintsDisplay = (
                <TicketPaymentConstraints
                    paymentConstraints={paymentConstraints}
                />
            );
        }

        if (shouldShowPrice(this.props)) {
            priceInfo = (
                <PriceInformation
                    primaryPrice={primaryPrice}
                    price={displayPrice}
                    feeAndTax={feeAndTax}
                    statusKey={statusKey}
                />
            );
        }

        if (shouldHideStatus || hideSaleDates) {
            hideStatus = true;
        }

        if (canBeSelected && isDonationTicket(this.props)) {
            const donationFeeText = _getDonationFeeTaxText(
                includeFee,
                includeTax,
            );

            if (donationFeeText) {
                ticketFeeTaxDescription = (
                    <span data-spec="donation-fee-text">{donationFeeText}</span>
                );
            }
        }

        const ticketGraphicWrapper = graphic ? (
            <TicketCardGraphicWrapper graphic={graphic} />
        ) : null;
        const ticketStatusContent = canBeSelected ? (
            <TicketCardStatus
                shouldHideStatus={hideStatus}
                statusKey={statusKey}
                salesStart={salesStart}
                salesEnd={salesEnd}
                isTicketTiered={isTicketTiered}
            />
        ) : null;

        const ticketCardBodyClass = classNames(
            'ticket-card-content__body',
            'eds-g-cell eds-g-cell-12-12',
            {
                [`${CSS_TICKET_DISABLED}`]: shouldDisableTicketContent(
                    this.props,
                ),
            },
        );
        const ticketCardPrimaryContentClass = classNames(
            'eds-ticket-card-content eds-ticket-display-card-content eds-l-pad-all-0',
        );

        return (
            <div className="eds-ticket-card-content__container eds-align--left eds-l-pad-vert-4">
                {ticketGraphicWrapper}
                <div
                    className={ticketCardBodyClass}
                    data-automation={dataAutomation}
                    data-spec={SPEC_TICKET_CONTENT}
                >
                    <div className={ticketCardPrimaryContentClass}>
                        <div>
                            <h3 className="ticket-title">{name}</h3>
                            <div>
                                {paymentConstraintsDisplay}
                                <div className="eds-l-mar-bot-1">
                                    {priceInfo}
                                </div>
                                {ticketFeeTaxDescription}
                            </div>
                        </div>
                        <TicketAvailabilityField
                            {...this.props}
                            isFormSubmitting={isFormSubmitting}
                            canBeSelected={canBeSelected}
                        />
                    </div>
                    <div className="ticket-card__secondary-content">
                        {quantityRemaining && (
                            <RemainingTickets
                                quantityRemaining={quantityRemaining}
                            />
                        )}
                        {ticketStatusContent}
                        {description && (
                            <TicketDescription description={description} />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
