import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { htmlPropType } from '@eb/eds-utils';

import {
    TOOLTIP_CONTENT_PROPTYPE,
    ARROW_LOCATION_PROPTYPE,
    STYLE_PROPTYPE,
    ANIMATION_MAP,
    STANDARD_ARROW_SPACING,
} from './constants';

import { getLocation } from './utils';

import './tooltip.scss';

const _updatePosition = (initialLocation, finalArrowLocation) => {
    const verticalVals = ['bottom', 'top'];
    const negativeVals = ['bottom', 'right'];
    let key = 'left';
    let spacing = STANDARD_ARROW_SPACING;

    if (verticalVals.indexOf(finalArrowLocation) > -1) {
        key = 'top';
    }

    if (negativeVals.indexOf(finalArrowLocation) > -1) {
        spacing = -spacing;
    }

    const adjustedValue = initialLocation[key] + spacing;

    return {
        ...initialLocation,
        [key]: adjustedValue,
    };
};

export default class Tooltip extends React.PureComponent {
    static propTypes = {
        /**
         * The text to be set as id for tooltip
         **/
        tooltipId: PropTypes.string,

        /**
         * The text or React element to be displayed.
         * If text is provided, it must be a label, no sentences, with a max character count of 32.
         **/
        children: TOOLTIP_CONTENT_PROPTYPE.isRequired,

        /**
         * HTML DOM node of the element which when hovered over will
         * trigger the Tooltip to appear.
         **/
        targetNode: htmlPropType,

        /**
         * Location of arrow relative to targetNode. The direction chosen
         * relates to which side of the Tooltip the arrow will appear.
         * Options: left, right, top, bottom
         **/
        arrowLocation: ARROW_LOCATION_PROPTYPE,

        /**
         * The background color of the Tooltip. Options: basic, phab
         **/
        style: STYLE_PROPTYPE,
    };

    static defaultProps = {
        arrowLocation: 'top',
        style: 'basic',
    };

    constructor(props) {
        super(props);

        this.state = {
            targetNode: props.targetNode,
            height: -1,
            width: -1,
        };
    }

    componentWillReceiveProps({ targetNode }) {
        this.setState({
            targetNode,
        });
    }

    componentDidUpdate() {
        if (this._toolTipNode && !this._componentIsRendered()) {
            /* Needed to trigger a re-render once the component has actually
            been mounted and we have access to its height and width */
            //eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                height: this._toolTipNode.offsetHeight,
                width: this._toolTipNode.offsetWidth,
            });
        }
    }

    _setToolTipNode(node) {
        if (node) {
            this._toolTipNode = node;
        }
    }

    _componentIsRendered() {
        return this.state.height > -1 && this.state.width > -1;
    }

    render() {
        if (!this.state.targetNode) {
            return null;
        }

        let finalLocation;
        const { children, arrowLocation, style, tooltipId } = this.props;
        const { targetNode, height, width } = this.state;

        if (this._componentIsRendered()) {
            const initialLocation = getLocation(
                targetNode,
                arrowLocation,
                width,
                height,
            );

            finalLocation = _updatePosition(initialLocation, arrowLocation);
        }

        const className = classNames(
            'eds-tooltip',
            `eds-tooltip__arrow-${arrowLocation}`,
            `eds-tooltip--${style}`,
            {
                [ANIMATION_MAP[arrowLocation]]: finalLocation,
            },
        );

        return (
            <div
                id={tooltipId}
                className={className}
                style={finalLocation}
                ref={this._setToolTipNode.bind(this)}
                data-spec="tooltip"
                role="tooltip"
            >
                {children}
            </div>
        );
    }
}
