import gettext from '@eb/gettext';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import has from 'lodash/has';
import { push, replace } from 'react-router-redux';
import { setSubmitFailed, change, submit } from 'redux-form';

import { joinPath } from '@eb/path-utils';
import { CustomSubmissionError } from '@eb/redux-form-tools';
import {
    transformUserInstruments,
    transformVirtualIncentive,
} from '../../api/transformations/userInstruments';
import { ORDER_TOKEN_HEADER, WAITING_ROOM_TOKEN_HEADER } from '../../api/base';
import {
    abandonOrder as abandonOrderApi,
    createOrder as createOrderApi,
    generateGuestToken as generateGuestTokenApi,
    getAvailablePaymentMethods as getAvailablePaymentMethodsApi,
    getOrder as getOrderApi,
    getOrderAttendees as getOrderAttendeesApi,
    getOrderEligibility as getOrderEligibilityApi,
    getPaymentHistory as getPaymentHistoryApi,
    resendEmail as resendEmailApi,
    updateEmailPreferences as updateEmailPreferencesApi,
    updateOrder as updateOrderApi,
} from '../../api/order';
import { getInitSurveysActionFromOrderResponse } from '../survey';
import {
    addEmailOptInToOrganization as addEmailOptInToOrganizationApi,
    getOrganizer as getOrganizerApi,
} from '../../api/organization';
import {
    getVaultInstruments as getVaultInstrumentsApi,
    getUserAccountDetails as getUserAccountDetailsApi,
    getCurrentUserAccountDetails,
} from '../../api/users';
import { getBraintreeClientToken as getBraintreeClientTokenApi } from '../../api/braintreeClient';
import { postMessage as postMessageUtil } from '../../utils/postMessage';

import {
    BASE_URL,
    CAPACITY,
    CHECKOUT_FORM_NAME,
    CHECKOUT_PATH,
    DEFAULT_COUNTRY,
    DELIVERY_METHODS_PATH,
    EXTERNAL_PAYMENT_PATH,
    INSTRUMENT_TYPES,
    NOT_FOUND,
    PAYMENT_METHODS,
    PICK_A_SEAT_PATH,
    POST_MESSAGE_STATUS,
    RESEND_EMAIL_PATH,
    RESEND_EMAIL_FIELD,
    RESEND_EMAIL_FORM_NAME,
    RESERVED_TICKETS_CONFIRMATION_PATH,
    STATUS_PATH,
    TICKETS_PATH,
    TICKETS_WITH_CAPACITY_WAITLIST_PATH,
    WAITING_ROOM_PATH,
    WAITLIST_PATH,
    CHECKOUT_STEP_TIME_OUT,
} from '../../constants';
import { InstrumentType } from '../../models/paymentMethod';
import { OrderStatus } from '../../models/order';
import {
    CONFIRM_TO_CHECKOUT_STATUS,
    CONFIRM_CLOSE_STATUS,
    TIME_EXPIRED_STATUS,
    timeExpiredContent,
    STATUS_CONTROL_ORDER_TIME_EXPIRED,
} from '../../containers/status/constants';
import { sendNativeCheckoutPurchaseAction } from '../../containers/checkout/utils';
import {
    anyOrderAttendeesTicketClassHasMultipleDeliveryMethods,
    anyOrderAttendeesTicketClassHasShipping,
    getSelectedDeliveryMethodForCountry,
} from '../../utils/deliveryMethod';
import { refreshEventAndTickets as refreshEventAndTicketsAction } from '../initialize';
import { getVariantPromoCode } from '../../utils/promoCodes';
import { ADD_TO_CART, triggerTrackingPixelAction } from '../pixelAnalytics';
import {
    trackAbandonOrder,
    trackAbandonOrderCancel,
    trackAbandonOrderConfirm,
    trackBackClick,
    trackEmailPreferences,
    trackEmailPreferencesError,
    trackOrderSuccess,
    trackReopenXPTab,
    trackResendEmailClick,
    trackResendEmailCancel,
    trackResendEmailSubmit,
    trackXPBackToCheckout,
    trackTicketsPageSubmit,
    trackJoinWaitlistClick,
    trackTimerExpired,
} from '../eventAnalytics';
import { getEventShippingSettings as getEventShippingSettingsAction } from '../event';
import {
    navigateToTimeExpiredStatusPage,
    startBackgroundTimer as startBackgroundTimerAction,
    updateBackgroundTimer,
} from '../timer';
import {
    resetWaitingRoomToken as resetWaitingRoomTokenAction,
    updateTokensWithGuestToken as updateTokensWithGuestTokenAction,
    updateTokensWithOrderToken as updateTokensWithOrderTokenAction,
} from '../tokens';
import {
    resetWaitingRoom as resetWaitingRoomAction,
    updateWaitingRoomStep as updateWaitingRoomStepAction,
    updateWaitingRoomWithToken as updateWaitingRoomWithTokenAction,
} from '../waitingRoom';
import { updateDonationFeeAndTax } from '../tickets';
import { selectors as orderSelectors } from '../../reducers/order';

import {
    updateBraintreeClientTokenAction,
    updateOrderAction,
    updateRedirectInstructionsAction,
    updateOrderAttendeesAction,
    updateAvailablePaymentMethodsAction,
    updateOrderEmailAction,
} from '../../actions/order';
import {
    hasDonationTickets,
    hasEnteredDonationAmount,
} from '../../containers/ticketSelection/utils/ticketUtils';
import {
    getAttendees,
    isPaidOrder as isOrderToBePaid,
    isOrganizerManagingAnAlienOrder,
} from '../../selectors/order';
import { getSelectedTickets } from '../../selectors/tickets';
import { getUserId, isUserLoggedIn } from '../../selectors/user';
import { showPurchaseConfirmation as showPurchaseConfirmationAction } from '../purchaseConfirmation';
import {
    CHECKOUT_NATIVE_ORDER_PLACED,
    PAYMENT_CARD_EXPIRATION_DATE,
    PAYMENT_CARD_POSTAL_CODE,
    PAYMENT_CARD_SECURITY_CODE,
    PAYMENT_CREDIT_CARD_NUMBER,
    PAYMENT_SAVE_CREDIT_CARD,
} from '../../containers/checkout/constants';
import {
    CLEAR_PICK_A_SEAT_BEST_AVAILABLE_WARNING,
    SET_PICK_A_SEAT_BEST_AVAILABLE_WARNING,
} from './constants';
import { isIdentificationRequiredForCurrency } from '../../models/currency';
import {
    getUpdateUserInstrumentsAction,
    setVirtualIncentiveAction,
    setUserSiginInMethodsAction,
    clearUserDataAction,
    setUserAsLoggedInAction,
} from '../user';
import {
    setOrganizerMarketingOptInAction,
    retrieveOrderFailureAction,
} from '../app';
import { BEST_AVAILABLE_NOT_FOUND_ERROR } from '../../api/constants';
import {
    getPostMessageActionParamsFromState,
    handleOrderError,
    redirectToExternalPayment,
} from './utils';
import { notifyParentOrderComplete as notifyParentOrderCompleteAction } from '../notifyParent';
import { getCurrency } from '../../selectors/event';
import { isFormSubmitted } from '../../utils/forms';
import { isTransferredOrder } from '../../selectors/order';

export const setPickASeatBestAvailableWarning = (message) => ({
    type: SET_PICK_A_SEAT_BEST_AVAILABLE_WARNING,
    payload: message,
});

export const clearPickASeatBestAvailableWarning = () => ({
    type: CLEAR_PICK_A_SEAT_BEST_AVAILABLE_WARNING,
});

export const updateOrderAttendeesDefaultDeliveryMethodByCountry = (
    selectedCountry,
) => (dispatch, getState) => {
    const state = getState();
    const attendees = getAttendees(state);
    const attendeesWithDefaultDeliveryMethod = attendees.map(
        ({ deliveryMethod, ticketClass = {}, ...rest }) => ({
            ...rest,
            ticketClass,
            deliveryMethod: getSelectedDeliveryMethodForCountry({
                deliveryMethods: ticketClass.deliveryMethods,
                selectedDeliveryMethod: deliveryMethod,
                selectedCountry,
            }),
        }),
    );

    dispatch(updateOrderAttendeesAction(attendeesWithDefaultDeliveryMethod));
};

export const _getOrderFromState = (getState) => getState().order;

export const _handleUpdateOrder = (dispatch, orderResponse) => {
    dispatch(updateOrderAction(orderResponse));
};

export const _handleUpdateOrderToken = (dispatch, headers) =>
    dispatch(updateTokensWithOrderTokenAction(headers.get(ORDER_TOKEN_HEADER)));

export const setUserSiginInMethods = () => async (dispatch, getState) => {
    const {
        user: { email },
    } = getState();

    if (email) {
        const { response } = await getUserAccountDetailsApi(email);

        if (response && response.sign_in_methods) {
            dispatch(setUserSiginInMethodsAction(response.sign_in_methods));
        }
    }
};

export const getOrganizerMarketingOptIn = () => async (dispatch, getState) => {
    const {
        app: { event },
    } = getState();

    if (has(event, 'organizer_id')) {
        const organizerData = await getOrganizerApi(event['organizer_id']);

        return dispatch(
            setOrganizerMarketingOptInAction(
                !organizerData.response.disable_marketing_opt_in,
            ),
        );
    }

    return dispatch(setOrganizerMarketingOptInAction(false));
};

export const _initializeOrder = (orderState) => (dispatch, getState) => {
    const state = getState();
    const { eventId, widgetParentUrl } = getPostMessageActionParamsFromState(
        state,
    );

    _handleUpdateOrder(dispatch, orderState);

    postMessageUtil(POST_MESSAGE_STATUS.startOrder, eventId, widgetParentUrl);
};

const _shouldManageUserSavedPaymentData = (state) =>
    isUserLoggedIn(state) && !isOrganizerManagingAnAlienOrder(state);

const _shouldSavePaymentMethods = (state) => {
    // [EB-112961] Currently we can't support vault instruments in currencies requiring identification (like BRL and ARG)
    const isVaultSupportedCurrency = !isIdentificationRequiredForCurrency[
        getCurrency(state)
    ];

    return isVaultSupportedCurrency && _shouldManageUserSavedPaymentData(state);
};

export const getAndSaveAvailablePaymentMethods = () => async (
    dispatch,
    getState,
) => {
    const {
        user: { publicId: userId } = {},
        tickets: {
            constrainedInstrumentType = '',
            constrainedPaymentMethods = [],
        } = {},
        order: { id: orderId },
        event: { id: eventId, isFree: isFreeEvent },
    } = getState();

    if (isFreeEvent) {
        return undefined;
    }

    const state = getState();

    const {
        response: availablePaymentData,
    } = await getAvailablePaymentMethodsApi(eventId, orderId);

    let result = dispatch(
        updateAvailablePaymentMethodsAction(availablePaymentData),
    );

    const virtualIncentiveCardInstrument = availablePaymentData.user_instruments?.find(
        (userInstrument) =>
            userInstrument.instrument?.instrument_type ===
            InstrumentType.VIRTUAL_INCENTIVES_CARD,
    );

    if (virtualIncentiveCardInstrument) {
        const transformedVirtualIncentiveCardInstrument = transformVirtualIncentive(
            virtualIncentiveCardInstrument,
        );

        if (transformedVirtualIncentiveCardInstrument) {
            dispatch(
                setVirtualIncentiveAction(
                    transformedVirtualIncentiveCardInstrument,
                ),
            );
        }
    }

    const isPaidOrder = isOrderToBePaid(state);

    const shouldSelectPaymentMethod = orderSelectors.hasOnlyOnePaymentMethodAndInstrumentType(
        state.order,
    );

    if (
        orderSelectors.haveAvailablePaymentMethods(state.order) &&
        orderSelectors.hasNoPaymentMethodsAvailable(state.order)
    ) {
        throw new CustomSubmissionError(
            {
                _error: gettext(
                    'This event is not available for purchase yet. Please contact the organizer in order to complete the purchase.',
                ),
            },
            {},
        );
    }

    if (shouldSelectPaymentMethod && isPaidOrder) {
        result = dispatch(
            change(
                CHECKOUT_FORM_NAME,
                'paymentMethod',
                orderSelectors.getFirstAvailableInstrumentType(state.order)
                    .type,
            ),
        );
    }

    // Fetch and save user's stored CC payment methods if user is logged in
    if (_shouldSavePaymentMethods(state)) {
        const { response: instrumentsResponse } = await getVaultInstrumentsApi(
            userId,
            new Date(),
        );

        const transformed = transformUserInstruments(instrumentsResponse);
        const eppMethods = getState().order.availablePaymentMethods.find(
            (method) => method.type === 'eventbrite',
        );

        if (eppMethods) {
            let allowedUserInstruments = transformed.userInstruments;
            const creditCardInstrument = eppMethods.instrumentTypes.find(
                (instrument) => instrument.type === PAYMENT_METHODS.CREDIT,
            );

            // Filter out user instruments that do not fit in with the allowed CC variants.
            // ex) Don't show stored VISA card if the order can only use AMEX cards.
            if (creditCardInstrument) {
                const allowedCards = creditCardInstrument.variants;

                allowedUserInstruments = allowedUserInstruments
                    .filter((userInstrument) =>
                        allowedCards.includes(
                            userInstrument.instrument.cardType,
                        ),
                    )
                    .filter(
                        (userInstrument) =>
                            isEmpty(constrainedPaymentMethods) ||
                            constrainedPaymentMethods.includes(
                                userInstrument.instrument.cardType,
                            ),
                    );
            }

            dispatch(getUpdateUserInstrumentsAction(allowedUserInstruments));

            if (
                !isEmpty(allowedUserInstruments) ||
                constrainedInstrumentType === INSTRUMENT_TYPES.CREDIT_CARD
            ) {
                /**
                 * If there are stored payment methods or constrained instrument type is 'CREDIT_CARD',
                 * payment method selection is pre-set to CREDIT and the first stored payment method in
                 * the list is pre-selected. If there are no stored payment methods, fall back to 'CREDIT',
                 * which corresponds to the "add a new card" option.
                 */
                const allowedVaultId = allowedUserInstruments.length
                    ? allowedUserInstruments[0].vaultId
                    : PAYMENT_METHODS.CREDIT;

                dispatch(
                    change(
                        CHECKOUT_FORM_NAME,
                        'paymentMethod',
                        PAYMENT_METHODS.CREDIT,
                    ),
                );
                dispatch(
                    change(
                        CHECKOUT_FORM_NAME,
                        'payment.vaultId',
                        allowedVaultId,
                    ),
                );
            } else {
                return dispatch(
                    change(
                        CHECKOUT_FORM_NAME,
                        'payment.vaultId',
                        PAYMENT_METHODS.CREDIT,
                    ),
                );
            }
        }
    }

    return result;
};

export const _checkAndUpdateDonationFeeAndTax = (
    response,
    selectedTickets,
) => async (dispatch) => {
    if (!hasDonationTickets(selectedTickets)) {
        return undefined;
    }

    return dispatch(updateDonationFeeAndTax(response));
};

export const getBraintreeClientToken = () => async (dispatch, getState) => {
    const {
        app: {
            featureFlags: { enableNonceBraintreePayments },
        },
        event: { currency },
        order: { availablePaymentMethods },
    } = getState();
    let braintreeInstrument = null;

    if (!isEmpty(availablePaymentMethods)) {
        braintreeInstrument = availablePaymentMethods
            .find((method) => method.type === 'eventbrite')
            .instrumentTypes.find(
                (instrument) =>
                    instrument.type === PAYMENT_METHODS.NONCE_BRAINTREE,
            );
    }

    if (!enableNonceBraintreePayments || !braintreeInstrument) {
        // Braintree is not a payment method. No need for a client token.
        return {
            response: {
                client_token: undefined,
            },
        };
    }

    return getBraintreeClientTokenApi(currency);
};

export const _handleOnCreateOrderError = (dispatch) => async (error) => {
    // first, check if the error is an instance of CustomSubmissionError

    if (error && error.extraData) {
        const {
            response: { status, headers } = {},
            parsedError,
        } = error.extraData;

        // check if server respond with a redirect and the WR header
        // and save the info into the store and navigate to WR view
        if (status === 307 && headers.has(WAITING_ROOM_TOKEN_HEADER)) {
            dispatch(
                updateWaitingRoomWithTokenAction(
                    headers.get(WAITING_ROOM_TOKEN_HEADER),
                ),
            );
            dispatch(push(joinPath(BASE_URL, WAITING_ROOM_PATH)));

            return;
        }

        // In case of Existing Order, there is a dim chance that the newly created order is not found
        // This will result in a 404 that will be caught here, we'll then notify the user.
        if (status === 404 && parsedError.error === NOT_FOUND) {
            dispatch(retrieveOrderFailureAction(parsedError));
            throw error;
        }
    }

    await dispatch(refreshEventAndTicketsAction(null, error));
    throw error;
};

export const _handleEmailPreferencesError = (error) => {
    if (error.errors) {
        trackEmailPreferencesError(error.errors._error);

        throw error;
    }
};

/**
 * Saves and places an order that is expected to be a redirected payment (ex. iDEAL, Bancontact, etc...).
 * After placing the order, it will save the redirect instructions returned from the order service and return
 * them at the end of the promise chain.
 */
export const _getRedirectedPaymentOrderPromise = async ({
    dispatch,
    saveAndPlacePromise,
}) => {
    let redirectInstructions;
    let dispatchPayment;
    let updateOrderRequest;

    try {
        const placeOrderResponse = await saveAndPlacePromise;

        if ('redirect_instructions' in placeOrderResponse.response) {
            redirectInstructions =
                placeOrderResponse.response.redirect_instructions;

            if (redirectInstructions) {
                const orderIdNum = placeOrderResponse.response.id;

                dispatchPayment = () => getPaymentHistoryApi(orderIdNum);
                updateOrderRequest = () => updateOrderApi(orderIdNum);
            }
        } else {
            // Redirect instructions are required, and if they are not available, that is a serious internal error.
            throw new CustomSubmissionError(
                {
                    _error: gettext(
                        "There was an error redirecting you to your payment provider's site",
                    ),
                },
                {},
            );
        }

        await dispatch(dispatchPayment());

        const updateOrderResponse = await updateOrderRequest();

        await _handleUpdateOrder(dispatch, updateOrderResponse.response);
        await _handleUpdateOrderToken(dispatch, updateOrderResponse.headers);

        await dispatch(startBackgroundTimerAction());

        if (redirectInstructions) {
            dispatch(updateRedirectInstructionsAction(redirectInstructions));
            dispatch(replace(joinPath(BASE_URL, EXTERNAL_PAYMENT_PATH)));
        }

        return redirectInstructions;
    } catch (error) {
        return handleOrderError(dispatch, error);
    }
};

/**
 * Saves and places an order that will be paid by standard credit card (ex. Visa, Mastercard, etc...).
 * After placing the order, the app will continue to the order confirmation page.
 */
const _getCreditCardOrderPromise = async ({ dispatch, order }) => {
    try {
        await order.saveAndPlacePromise;
        await dispatch(
            sendNativeCheckoutPurchaseAction(
                CHECKOUT_NATIVE_ORDER_PLACED,
                showPurchaseConfirmationAction,
            ),
        );
        await dispatch(resetWaitingRoomAction());
        return dispatch(resetWaitingRoomTokenAction());
    } catch (error) {
        return Promise.reject(handleOrderError(dispatch, error));
    }
};

const _tryToRedirectToExternalPayment = (
    paymentMethod,
    order,
    bankId,
    getState,
) => {
    const { availablePaymentMethods } = _getOrderFromState(getState);

    return new Promise(async (resolve, reject) => {
        await redirectToExternalPayment(
            paymentMethod,
            () => _getRedirectedPaymentOrderPromise(order),
            availablePaymentMethods,
            bankId,
            getState,
        ).catch((errorMessage) => {
            reject(
                new CustomSubmissionError({
                    _error: errorMessage,
                }),
            );
        });
        window.addEventListener('redirectError', (e) => {
            if (e && e.detail) {
                reject(e.detail);
            }
            resolve();
        });
    });
};

const _requestIdealPayment = ({ order, getState, dispatch }) => {
    if (!order.formData.payment) {
        return new Promise(() => {
            throw new CustomSubmissionError(
                {
                    payment: { bankId: gettext('This field is required') },
                    _error: gettext(
                        'Please correct the highlighted fields below',
                    ),
                },
                {},
            );
        }).catch((error) => handleOrderError(dispatch, error));
    }

    return _tryToRedirectToExternalPayment(
        PAYMENT_METHODS.IDEAL,
        order,
        order.formData.payment.bankId,
        getState,
    );
};

const _requestNonceBraintreePayment = ({ order, dispatch }) => {
    if (!order.formData.payment) {
        return new Promise(() => {
            throw new CustomSubmissionError(
                {
                    _error: gettext(
                        'You must authorize with PayPal before you can submit your order',
                    ),
                },
                {},
            );
        }).catch((error) => handleOrderError(dispatch, error));
    }
    return _getCreditCardOrderPromise({ order, dispatch });
};

const _requestMaestroBancontactPayment = ({ order, getState }) =>
    _tryToRedirectToExternalPayment(
        PAYMENT_METHODS.MAESTRO_BANCONTACT,
        order,
        null,
        getState,
    );

const _requestSofortPayment = ({ order, getState }) =>
    _tryToRedirectToExternalPayment(
        PAYMENT_METHODS.SOFORT,
        order,
        null,
        getState,
    );

const _requestFPPPaypalPayment = ({ order, getState }) =>
    _tryToRedirectToExternalPayment(
        PAYMENT_METHODS.FPP_PAYPAL,
        order,
        null,
        getState,
    );

const _requestSepaPayment = ({ order, dispatch }) => {
    const formFields = ['ownerName', 'iban', 'ibanAgreement'];
    const firstEmptyField = formFields
        .filter((field) => !get(order.formData.payment, field))
        .shift();

    if (firstEmptyField || !order.formData.payment) {
        return new Promise(() => {
            throw new CustomSubmissionError(
                {
                    payment: {
                        [firstEmptyField]: gettext('This field is required'),
                    },
                    _error: gettext(
                        'Please correct the highlighted fields below',
                    ),
                },
                {},
            );
        }).catch((error) => handleOrderError(dispatch, error));
    }

    return _getCreditCardOrderPromise({ order, dispatch });
};

export const requestPayment = (paymentMethod, order) => (
    dispatch,
    getState,
) => {
    const paymentActions = {
        [PAYMENT_METHODS.IDEAL]: _requestIdealPayment,
        [PAYMENT_METHODS.MAESTRO_BANCONTACT]: _requestMaestroBancontactPayment,
        [PAYMENT_METHODS.NONCE_BRAINTREE]: _requestNonceBraintreePayment,
        [PAYMENT_METHODS.SOFORT]: _requestSofortPayment,
        [PAYMENT_METHODS.SEPA_DIRECT_DEBIT]: _requestSepaPayment,
        [PAYMENT_METHODS.FPP_PAYPAL]: _requestFPPPaypalPayment,
    };

    const action = paymentActions[paymentMethod] || _getCreditCardOrderPromise;

    return action({ order, getState, dispatch }).catch((error) =>
        handleOrderError(dispatch, error),
    );
};

export const updateEmailPreferencesForOrderOwner = (paymentPromise) => (
    dispatch,
    getState,
) =>
    paymentPromise.then(() => {
        const state = getState();
        const {
            app: {
                enableOrganizerMarketingOptIn,
                featureFlags: { enableEBMarketingOptIn },
            },
            form: {
                checkoutForm: {
                    values: {
                        ebMarketingOptIn,
                        organizationMarketingOptIn,
                        buyer: {
                            'N-first_name': firstName,
                            'N-last_name': lastName,
                            'N-email': email,
                        },
                    },
                },
            },
            order: { id: orderId },
            event: { organization },
        } = state;

        let ebOptInPromise = Promise.resolve();
        let organizationOptInPromise = Promise.resolve();

        if (ebMarketingOptIn) {
            ebOptInPromise = updateEmailPreferencesApi(
                orderId,
                ebMarketingOptIn,
            );
        }

        if (enableOrganizerMarketingOptIn && organizationMarketingOptIn) {
            organizationOptInPromise = addEmailOptInToOrganizationApi(
                organization.id,
                email,
                firstName,
                lastName,
            );
        }

        // We track the state of both boxes when the user submits their order so we can count how
        // many people actually saw the boxes
        if (enableEBMarketingOptIn) {
            dispatch(
                trackEmailPreferences('RF_EB_EMAIL_OPT_IN', ebMarketingOptIn),
            );
        }

        if (enableOrganizerMarketingOptIn && get(organization, 'name')) {
            dispatch(
                trackEmailPreferences(
                    'RF_ORG_EMAIL_OPT_IN',
                    organizationMarketingOptIn,
                ),
            );
        }

        return Promise.all([
            ebOptInPromise,
            organizationOptInPromise,
        ]).catch((error) => _handleEmailPreferencesError(error));
    });

/**
 * This generates the token needed in the header to complete the order
 * It is NOT needed when there is already an existing order.
 *
 * @param {function} dispatch redux dispatch function
 * @param {string} eventId the event ID
 */
const _generateToken = async (dispatch, eventId) => {
    const { response: tokenResponse } = await generateGuestTokenApi(eventId);

    await dispatch(
        updateTokensWithGuestTokenAction(tokenResponse['guest_token']),
    );
};

/**
 * Calls the relevant api to create / retrieve the order
 * In case no order exists (existingOrderId is null/undefined) we are in the standard flow, and we call the usual `createOrderApi` endpoint
 * If the order has previously been created (Add Attendees flow for instance) we just retrieve the order using the new `getOrderApi` endpoint
 *
 * @param {function} dispatch redux dispatch function
 * @param {Object} orderInfo object containing necessary info related to the order
 */
const _callOrderApi = async (dispatch, orderInfo) => {
    const { existingOrderId, eventId } = orderInfo;

    if (existingOrderId) {
        const { response, headers } = await getOrderApi(existingOrderId);

        return {
            orderResponse: {
                order: response,
                attendees: [],
                // Force fetching attendees in order to handle cases with > 50
                forceFetchAttendees: true,
            },
            orderHeaders: headers,
        };
    }

    await _generateToken(dispatch, eventId);

    const { response, headers } = await createOrderApi(orderInfo);

    return {
        orderResponse: response,
        orderHeaders: headers,
    };
};

const _processOrder = async (dispatch, getState) => {
    const {
        app: {
            affiliateCode,
            existingOrderId,
            inviteToken,
            campaignToken,
            referrerId,
            waitlistCode,
        },
        event: { id: eventId, isReservedSeating },
        groupRegistration: { teamSettings: { token: teamToken } = {} } = {},
    } = getState();

    const selectedTickets = getSelectedTickets(getState());
    const promoCode = getVariantPromoCode(selectedTickets);
    const orderInfo = {
        affiliateCode,
        campaignToken,
        eventId,
        existingOrderId,
        isReservedSeating,
        inviteToken,
        promoCode,
        referrerId,
        selectedTickets,
        teamToken,
        waitlistCode,
    };

    const { orderResponse, orderHeaders } = await _callOrderApi(
        dispatch,
        orderInfo,
    );
    const attendeesExpansions = `survey,survey_responses,${
        isReservedSeating ? 'ticket_class.reserved_seating' : 'ticket_class'
    }`;
    // When creating an "empty" order e.g. use Pick-a-seat to select ticket
    // the response returned from order summary is flattened, not having `order` root element.
    const orderData = orderResponse.order || orderResponse;

    // This needs to happen before GET /order/:orderId/attendees fetch, because the request needs token setup.
    await _handleUpdateOrderToken(dispatch, orderHeaders);

    // Create the order first, the response includes `attendees` get paginated and could have a limit to 50.
    // The expected approach is: after order created, paginate over order/:orderId/attendees to get all the attendees
    // This follows the same approach already implemented in organizer app
    const attendees = await getOrderAttendeesApi(
        orderData.id,
        attendeesExpansions,
    );
    const orderStatePayload = {
        ...orderData,
        attendees,
    };

    await dispatch(_initializeOrder(orderStatePayload));
    await dispatch(getInitSurveysActionFromOrderResponse(orderStatePayload));
    await dispatch(
        _checkAndUpdateDonationFeeAndTax(orderResponse, selectedTickets),
    );
    await dispatch(getEventShippingSettingsAction());
    await dispatch(
        updateOrderAttendeesDefaultDeliveryMethodByCountry(DEFAULT_COUNTRY),
    );

    // XXX: we are currently dispatching the getAndSaveAvailablePaymentMethods thunk in a couple
    // different places in the checkout flow where GA and Reserved diverged but we should only
    // have to do this at the last step before checkout (ie when the CheckoutPage is rendered).
    if (orderData && !isEmpty(attendees)) {
        await dispatch(getAndSaveAvailablePaymentMethods());
    }

    await dispatch(
        updateWaitingRoomWithTokenAction(
            orderHeaders.get(WAITING_ROOM_TOKEN_HEADER),
        ),
    );

    return dispatch(startBackgroundTimerAction());
};

const _updateIfNewUserLoggedIn = async (dispatch, getState, userInfo) => {
    // There's a logged in user, check if it's the same than in the app state
    const state = getState();
    const userId = getUserId(state);

    if (userId !== userInfo.id) {
        // Not the same user, clear previous user and set the new one
        await dispatch(clearUserDataAction());
        return dispatch(setUserAsLoggedInAction(userInfo));
    }
};

export const updateLoggedInUserIfChanged = async (dispatch, getState) => {
    // Check against the API if the user in the App is still valid.
    // If not, update the state.
    try {
        const {
            response: userInfoResponse,
        } = await getCurrentUserAccountDetails();

        return _updateIfNewUserLoggedIn(dispatch, getState, userInfoResponse);
    } catch (e) {
        // Check if the user is logged out. If so, only update if the app initialized with a user
        const parsedError = e?.extraData?.parsedError;
        const state = getState();

        if (parsedError?.error === 'NOT_AUTHORIZED' && isUserLoggedIn(state)) {
            dispatch(clearUserDataAction(state));
        }
    }
};

export const getAndSetBraintreeToken = () => async (dispatch, getState) => {
    let {
        order: { braintreeClientToken },
    } = getState();

    // No need to fetch it again if it's already set in the state
    if (!braintreeClientToken) {
        const { response } = await dispatch(getBraintreeClientToken());

        braintreeClientToken = response.client_token;
    }

    await dispatch(updateBraintreeClientTokenAction(braintreeClientToken));
    return braintreeClientToken;
};

export const createPickASeatOrder = () => async (dispatch, getState) => {
    try {
        await _processOrder(dispatch, getState);
        return dispatch(push(joinPath(BASE_URL, PICK_A_SEAT_PATH)));
    } catch (customSubmissionError) {
        /**
         * When "ERROR_NO_MATCH_FOUND" inventory error raised after clicking "Buy on Map"
         * Clear the selected tickets and go to pick a seat with emtpy order.
         * Set warning state so that an warning notification would be displayed in pick a seat view.
         */
        if (
            get(customSubmissionError, 'errors._error') ===
            BEST_AVAILABLE_NOT_FOUND_ERROR
        ) {
            await dispatch(
                refreshEventAndTicketsAction(null, customSubmissionError),
            );
            await dispatch(
                setPickASeatBestAvailableWarning(
                    BEST_AVAILABLE_NOT_FOUND_ERROR,
                ),
            );

            return dispatch(createPickASeatOrder());
        }

        return _handleOnCreateOrderError(dispatch)(customSubmissionError);
    }
};

const shouldShowDeliveryMethodPage = (attendees) => {
    const hasMultipleDeliveryMethods = anyOrderAttendeesTicketClassHasMultipleDeliveryMethods(
        attendees,
    );
    const hasShippingDeliveryMethod = anyOrderAttendeesTicketClassHasShipping(
        attendees,
    );

    return hasMultipleDeliveryMethods || hasShippingDeliveryMethod;
};

const getPathAfterReservedSeating = (state) => {
    const attendees = getAttendees(state);

    return shouldShowDeliveryMethodPage(attendees)
        ? DELIVERY_METHODS_PATH
        : CHECKOUT_PATH;
};

export const goToPageAfterReservedSeating = () => (dispatch, getState) => {
    const path = getPathAfterReservedSeating(getState());

    return dispatch(push(joinPath(BASE_URL, path)));
};

export const getPathAfterTicketSelection = ({
    shouldShowReservedTicketsFlow,
    attendees,
}) => {
    let path = CHECKOUT_PATH;

    if (shouldShowReservedTicketsFlow) {
        path = RESERVED_TICKETS_CONFIRMATION_PATH;
    } else if (shouldShowDeliveryMethodPage(attendees)) {
        path = DELIVERY_METHODS_PATH;
    }

    return joinPath(BASE_URL, path);
};

export const createOrder = () => async (dispatch, getState) => {
    try {
        await _processOrder(dispatch, getState);
        const state = getState();
        const {
            event: { isReservedSeating },
        } = state;
        const shouldShowReservedTicketsFlow =
            isReservedSeating && !isTransferredOrder(state);
        const attendees = getAttendees(state);

        return dispatch(
            push(
                getPathAfterTicketSelection({
                    shouldShowReservedTicketsFlow,
                    attendees,
                }),
            ),
        );
    } catch (e) {
        return _handleOnCreateOrderError(dispatch)(e);
    }
};

/**
 * Try to abandon the order, and if something went wrong we will ignore the error
 * Order will be abandoned anyway when its TTL gets to 0 inside Redis
 */
export const abandonOrder = () => async (dispatch, getState) => {
    const state = getState();
    const orderId = orderSelectors.getOrderId(state.order);

    try {
        await abandonOrderApi(orderId);
        return dispatch(trackAbandonOrderConfirm());
    } catch (e) {
        return undefined;
    }
};

/**
 * The user abandons the order by closing the browser tab or window before paying
 */
export const abandonOrderOnClose = () => (dispatch, getState) => {
    const {
        order: { id: orderId, status: orderStatus },
    } = getState();

    if (
        orderId &&
        (orderStatus === OrderStatus.started ||
            orderStatus === OrderStatus.pending)
    ) {
        abandonOrderApi(orderId);
        return dispatch(trackAbandonOrderConfirm());
    }
};

/**
 * The order is not abandoned and the user remains in the checkout flow.
 * User will be taken back to a page of the given path, default is the checkout page.
 * A tracking action is also triggered to save the user action.
 */
export const backToPathOnCancelAbandonOrder = (pathName = CHECKOUT_PATH) => (
    dispatch,
) => {
    dispatch(trackAbandonOrderCancel());

    return dispatch(push(joinPath(BASE_URL, pathName)));
};

/**
 * The user has attempted to abandon the order by clicking the back button. This sends the user to a page
 * with a confirmation dialog and triggers a tracking action to save the user action.
 */
export const abandonOrderBackAttemptWithStatusPath = (
    pathName = CHECKOUT_PATH,
    statusPageSubPath = CONFIRM_TO_CHECKOUT_STATUS,
) => (dispatch) => {
    dispatch(trackBackClick(pathName));
    return dispatch(push(joinPath(BASE_URL, STATUS_PATH, statusPageSubPath)));
};

export const goToPageBeforeCheckout = () => (dispatch, getState) => {
    const state = getState();
    const attendees = getAttendees(state);

    if (shouldShowDeliveryMethodPage(attendees)) {
        dispatch(trackBackClick(CHECKOUT_PATH));
        return dispatch(push(joinPath(BASE_URL, DELIVERY_METHODS_PATH)));
    }

    return dispatch(abandonOrderBackAttemptWithStatusPath(CHECKOUT_PATH));
};

/**
 * The user has attempted to abandon the order by clicking the modal close button. This sends the user to a page
 * with a confirmation dialog and triggers a tracking action to save the user action.
 */
export const abandonOrderCloseAttemptWithStatusPath = (
    pathName,
    statusPath = CONFIRM_CLOSE_STATUS,
) => (dispatch) => {
    dispatch(trackAbandonOrder(pathName));
    return dispatch(push(joinPath(BASE_URL, STATUS_PATH, statusPath)));
};

/**
 * The user has attempted to back out of an external payment by clicking the back button. This sends the user
 * back to the checkout form page with all the fields filled out how they were before.
 */
export const backToCheckoutFromExternalPayment = () => (dispatch) => {
    dispatch(trackXPBackToCheckout());
    dispatch(setSubmitFailed(CHECKOUT_FORM_NAME));

    return dispatch(replace(joinPath(BASE_URL, CHECKOUT_PATH)));
};

const _isExternalPaymentPageActiveWindow = () => document.hidden;

/**
 * The timer expired. This sends the user back to the checkout and show the timer expired message.
 */
export const onTimerExpiredFromExternalPayment = () => (dispatch, getState) => {
    const {
        order: { status: orderStatus },
    } = getState();

    if (!STATUS_CONTROL_ORDER_TIME_EXPIRED.includes(orderStatus)) {
        dispatch(trackTimerExpired(EXTERNAL_PAYMENT_PATH));
        dispatch(replace(joinPath(BASE_URL, STATUS_PATH, TIME_EXPIRED_STATUS)));
        if (_isExternalPaymentPageActiveWindow()) {
            alert(timeExpiredContent({}).alertText); // eslint-disable-line no-alert
        }
    }
};

/**
 * The user has attempted to reopen the tab to their bank's website from the external payment page. This will
 * open a new tab to the same URL used before.
 */
export const reopenExternalPaymentTab = () => (dispatch, getState) => {
    const state = getState();
    const {
        checkoutForm: {
            values: { paymentMethod },
        },
    } = state.form;

    const { availablePaymentMethods } = _getOrderFromState(getState);
    const redirectInstructions = _getOrderFromState(getState)
        .redirectInstructions;
    const userTriggered = true;

    let bankId;

    if (paymentMethod === PAYMENT_METHODS.IDEAL) {
        const {
            checkoutForm: {
                values: { payment },
            },
        } = state.form;

        bankId = payment.bankId;
    }

    dispatch(trackReopenXPTab());

    redirectToExternalPayment(
        paymentMethod,
        async () => redirectInstructions,
        availablePaymentMethods,
        bankId,
        getState,
        userTriggered,
    );
};

/**
 * Prefilling email input to resend the confirmation email with the email used in the order
 */
export const prefillResendEmailInput = () => (dispatch, getState) => {
    const {
        order: { email: orderEmail },
    } = getState();

    dispatch(
        change(RESEND_EMAIL_FORM_NAME, RESEND_EMAIL_FIELD, orderEmail, true),
    );
};

/**
 * There is an attempt to resend the order to another email, a form to enter the new email is shown.
 * A tracking action is triggered to save the user action.
 */
export const resendEmailAttempt = () => (dispatch) => {
    dispatch(trackResendEmailClick());

    return dispatch(push(joinPath(BASE_URL, RESEND_EMAIL_PATH)));
};

/**
 * The order is sent to the new email.
 * A tracking action is triggered to save the user action.
 */
export const resendEmail = ({ resendEmail: email }) => async (
    dispatch,
    getState,
) => {
    const state = getState();
    const orderId = orderSelectors.getOrderId(state.order);

    await resendEmailApi(orderId, email);
    await dispatch(updateOrderEmailAction({ email }));
    await dispatch(showPurchaseConfirmationAction());

    return dispatch(trackResendEmailSubmit());
};

/**
 * The order is not sent to the new email and the user goes back to the status page.
 * A tracking action is triggered to save the user action.
 */
export const cancelResendEmail = () => (dispatch) => {
    dispatch(trackResendEmailCancel());

    return dispatch(showPurchaseConfirmationAction());
};

/**
 * This is triggered when clicking "Checkout" from reserved event "Buy on Map" flow
 * It will make sure the selected tickets have correct delivery methods,
 * such as use backup method if shipping cut-off date has passed, and select the correct
 * default delivery method.
 */
export const checkoutFromBuyOnMap = () => async (dispatch, getState) => {
    const state = getState();
    const { order } = state;

    try {
        const { response: eligibility } = await getOrderEligibilityApi(
            order.id,
        );
        const orderWithEligibility = {
            ...order,
            eligibility,
        };

        dispatch(updateOrderAction(orderWithEligibility));

        if (eligibility.eligible) {
            // XXX: we are currently dispatching the getAndSaveAvailablePaymentMethods thunk in a couple
            // different places in the checkout flow where GA and Reserved diverged but we should only
            // have to do this at the last step before checkout (ie when the CheckoutPage is rendered).
            await dispatch(getAndSaveAvailablePaymentMethods());
            await dispatch(getEventShippingSettingsAction());
            await dispatch(
                updateOrderAttendeesDefaultDeliveryMethodByCountry(
                    DEFAULT_COUNTRY,
                ),
            );
            await dispatch(goToPageAfterReservedSeating());
        }
    } catch (e) {
        throw e;
    }
};

/**
 * Dispatch the correct actions on clicking Checkout from the Ticket Selection Page
 */
export const submitFromTicketSelectionPage = () => async (
    dispatch,
    getState,
) => {
    dispatch(triggerTrackingPixelAction(ADD_TO_CART));
    dispatch(trackTicketsPageSubmit(TICKETS_PATH));
    await updateLoggedInUserIfChanged(dispatch, getState);
    await dispatch(createOrder());
};

/**
 * This handles what onSubmit should do for events that have their event capacity waitlist triggered:
 * - Happy path: There are no unlimited donation tickets: clicking submit (Join Waitlist) should go to the regular Waitlist flow.
 * - With an unlimited donation ticket:
 *      - No donation amount entered: clicking submit (Join Waitlist) should go to the Waitlist flow.
 *      - When a donation amount is entered, clicking submit (Checkout) should dispatch the regular order actions to start an order.
 */
export const submitFromCapacityWaitlistTicketsPage = () => (
    dispatch,
    getState,
) => {
    const {
        tickets: { ticketsById },
    } = getState();

    if (hasEnteredDonationAmount(ticketsById)) {
        dispatch(submitFromTicketSelectionPage());
    } else {
        dispatch(
            trackJoinWaitlistClick(
                TICKETS_WITH_CAPACITY_WAITLIST_PATH,
                CAPACITY,
            ),
        );
        dispatch(push(joinPath(BASE_URL, WAITLIST_PATH)));
    }
};

export const clearPaymentFields = () => (dispatch) => {
    dispatch(change(CHECKOUT_FORM_NAME, PAYMENT_CREDIT_CARD_NUMBER, ''));
    dispatch(change(CHECKOUT_FORM_NAME, PAYMENT_CARD_EXPIRATION_DATE, ''));
    dispatch(change(CHECKOUT_FORM_NAME, PAYMENT_CARD_SECURITY_CODE, ''));
    dispatch(change(CHECKOUT_FORM_NAME, PAYMENT_CARD_POSTAL_CODE, ''));
    dispatch(change(CHECKOUT_FORM_NAME, PAYMENT_SAVE_CREDIT_CARD, false));
};

export const completeOrderActions = () => (dispatch) => {
    dispatch(trackOrderSuccess());
    dispatch(notifyParentOrderCompleteAction());
};

export const onTimerExpired = () => (dispatch, getState) => {
    const isSubmitted = isFormSubmitted(getState(), CHECKOUT_FORM_NAME);

    dispatch(updateBackgroundTimer());

    if (isSubmitted) {
        return;
    }

    dispatch(trackTimerExpired(CHECKOUT_PATH));
    dispatch(updateWaitingRoomStepAction(CHECKOUT_STEP_TIME_OUT));
    dispatch(navigateToTimeExpiredStatusPage());
};

export const handleCheckoutRouting = () => (dispatch, getState) => {
    dispatch(submit(CHECKOUT_FORM_NAME));
};
