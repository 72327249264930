/* eslint-disable no-undef */
//     Grylls.js
//     http://github.com/eventbrite/grylls
//     TL;DR: An Eventbrite specific adapter for analytics.js.

/**
 * @title Grylls.js
 * @overview TL;DR: An Eventbrite specific adapter for analytics.js.
 * @author Alby Barber
 */

// NOTE: Adapted from: https://github.com/umdjs/umd/blob/master/templates/returnExports.js
(function (root, factory) {
    'use strict';
    // NOTE: requirejs isn't able to follow relative dependencies in AMD so we're using this
    // "absolute" path. It's defined in the grylls_config.js file to point to the
    // eb.analytics.js file w/in this module.
    if (typeof define === 'function' && define.amd) {
        define(['underscore', './eb.analytics'], function (_, analyticsjs) {
            return (root.grylls = factory(_, analyticsjs));
        });
    } else if (typeof module === 'object' && module.exports) {
        module.exports = factory(
            require('underscore'),
            require('./eb.analytics'),
        );
    }
    // eslint-disable-next-line no-invalid-this
})(this, function (_, analyticsjs) {
    'use strict';

    function Grylls() {
        // Pixel Loading
        // --------------
        // Load in `tracking data` and `enviromental` options
        // so that we can feed them into the analytics.js library.

        /**
         * Loads Tracking data and enviroment options into grylls
         *
         * Calling this will initialize intergration tracking data
         * and enviromental settings
         * In esm envrionment, this is already done in the `intialize` call
         *
         * @param  {object} trackingData name of tracking intergration settings
         * @param  {object} options name of enviromental options
         */
        this.load = function (trackingData, options) {
            var defaultOptions = {
                    debugging: false,
                    enabled: false,
                    identify: false,
                    identifyData: {},
                    launchSupportMultipleFacebookPixels: false,
                },
                pixelsToClean = [
                    'Google Analytics',
                    'Twitter Ads',
                    'Facebook Pixel',
                    'AdWords',
                ];

            // Extend default options with options passed in
            this.options = _.extend(defaultOptions, options);

            // if there is no tracking data passed or the feature is
            // disabled then return
            if (_.isEmpty(trackingData) || !this.options.enabled) {
                return;
            }

            // if there is an empty tracking pixelID for GA, AdWords, Facebook, or TwitterAds remove them from
            // the list before they are formatted for analytics.js

            pixelsToClean.forEach(
                function (pixel) {
                    if (!_.isEmpty(trackingData[pixel])) {
                        trackingData[pixel] = this.filterEmptyPixelIds(
                            trackingData[pixel],
                        );
                    }
                }.bind(this),
            );

            // Format our raw tracking data for `analytics.js`
            this.trackingData = this.format(trackingData);
            this.analyticsjs = analyticsjs;

            // Set up any debugging requested via `options`
            this.debug();
            // Direct call to `analytics.js` initializing our tracking data
            this.analyticsjs.initialize(this.trackingData, this.options);

            if (this.options.identify) {
                // If specifed via `options` identify the user
                this.identify(this.options.identifyData);
            }
        };

        // Proxy
        // ------
        // Proxy function that is used as a Façade for `analytics.js` calls
        // Caling via this proxy function allows grylls funcions
        // to be executed in the correct order at even when they
        // are called at any point.
        this.proxy = function (func) {
            var args = Array.prototype.slice.call(arguments, 1);

            if (this.analyticsjs && this.analyticsjs.initialized) {
                this.analyticsjs[func].apply(null, args);
                this.logInfo('Grylls event: ', func, args);
            }
        };

        // Debug
        // ------
        // Log to JS console if `option` is enabled
        this.debug = function () {
            if (this.options.debugging) {
                // Eventbrite logging
                /* eslint-disable no-console */
                console.group('::TRACKING_PIXEL_DEBUG::');
                console.log('TrackingData:', this.trackingData);
                console.log('Options:', this.options);
                console.groupEnd();
                /* eslint-enable no-console */

                // `anaytics.js` logging
                this.proxy('debug');
            }
        };

        // Log info
        // ---------
        // Log to JS console Grylls events if `option` is enabled
        this.logInfo = function (msg, func, args) {
            var message = msg + func;

            if (!this.options.debugging) {
                return;
            }

            if (args.length) {
                message = message + ' - ' + args;
            }
            // eslint-disable-next-line no-console
            console.log(
                message,
                'trackingPixelArguments: ',
                JSON.stringify(args),
            );
        };

        // Proxied functions
        // ------------------
        // Proxied and abstracted analytics.js functions
        // called via grylls API

        /**
         * Identify user
         *
         * The `identify` call is how you tie one of your users
         * and their actions to a recognizable userId and traits.
         *
         * @param  {object} properties used to idenify the user
         */
        this.identify = function (properties) {
            this.proxy('identify', this.options.uuid, properties);
        };

        /**
         * Page track
         *
         * The `page` call lets you record whenever a user sees
         * a page of your website, along with any properties about
         * the page.
         *
         * @param  {string} pageName of page
         */
        this.page = function (pageName) {
            this.proxy('page', pageName);
        };

        /**
         * Track event
         *
         * The `track` call records any actions your users perform,
         * along with any properties that describe the action.
         *
         * @param  {string} name of event you are tracking
         * @param  {object} properties that describe the action
         */
        this.track = function (name, properties) {
            this.proxy('track', name, properties);
        };

        // Helpers
        // --------
        // Helpers used to format tracking data objects

        // Renames an objects key (old to new)
        this.renameKey = function (obj, oldKey, newKey) {
            if (oldKey === newKey) {
                return obj;
            }
            obj[newKey] = obj[oldKey];
            delete obj[oldKey];

            return obj;
        };

        // Renames a set of attributes in an array of objects
        // Object passed in can be undefined but if it is it will return safely
        this.renameKeyInObjectArray = function (arr, oldKey, newKey) {
            if (!arr) {
                return null;
            }

            arr.forEach(
                function (obj) {
                    this.renameKey(obj, oldKey, newKey);
                }.bind(this),
            );

            return arr;
        };

        // Deletes an empty object node if it is empty
        this.cleanEmptyAttr = function (data, attr) {
            _.each(data, function (value) {
                if (_.isEmpty(value[attr])) {
                    delete value[attr];
                }
            });
            return data;
        };

        // Filters out pixel objects with empty pixelIds
        this.filterEmptyPixelIds = function (pixelObj) {
            return pixelObj.filter(function (pixel) {
                return !_.isEmpty(pixel.pixelId);
            });
        };

        // Formats object to ensure that analytics.js can consume it.
        this.format = function (obj) {
            this.renameKeyInObjectArray(
                obj['Google Analytics'],
                'pixelId',
                'trackingId',
            );
            this.renameKeyInObjectArray(obj.AdWords, 'pixelId', 'conversionId');
            this.renameKeyInObjectArray(obj['Twitter Ads'], 'pixelId', 'page');

            return this.cleanEmptyAttr(obj, 'events');
        };
    }

    return new Grylls();
});
