import flatten from 'lodash/flatten';
import flattenDeep from 'lodash/flattenDeep';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import gettext from '@eb/gettext';
import { change } from 'redux-form';

import {
    isInventoryQuestion,
    ADDRESS_QUESTIONS,
    SELECTED_QUESTION_GROUP_BUYER,
} from '../models/survey';

import { getErrorFromSpec } from '@eb/http';
import {
    API_ADDRESS_FIELD_TO_EDS_ADDRESS_FIELD,
    API_PAYMENT_FIELD_TO_CHECKOUT_PAGE_PAYMENT_FIELD,
    API_SOURCE_INSTRUMENT_FIELD_TO_CHECKOUT_PAGE_TICKET_BUYER_FIELD,
} from '../containers/checkout/constants';
import { CHECKOUT_FORM_NAME } from '../constants';

const SURVEY_ANSWER_PREFIX = 'order.answers.';
const PAYMENT_ANSWER_PREFIX = 'payment_methods.';
const SOURCE_INSTRUMENT_BILLING_INFORMATION_PREFIX =
    'source_instrument_billing_information.';
const INVENTORY_SURVEY_ERROR = 'INVENTORY_ERROR';
const INVALID_ERROR = 'INVALID';

export const TICKET_BUYER_SECTION_NAME = 'buyer';
export const PAYMENT_SECTION_NAME = 'payment';

let _checkoutPageForm;

const _isAddressQuestion = (questionId) =>
    ADDRESS_QUESTIONS.indexOf(questionId) >= 0;
const _isPaymentQuestion = (questionId) =>
    questionId.indexOf(PAYMENT_SECTION_NAME) >= 0;
const _isTickerBuyerQuestion = (questionId) =>
    !!API_SOURCE_INSTRUMENT_FIELD_TO_CHECKOUT_PAGE_TICKET_BUYER_FIELD[
        questionId
    ];

/**
 * setCheckoutPageForm saves a reference to the checkout page's
 * reduxForm component. `translateDynamicSurveyErrors` would not
 * otherwise have access to the form and its underlying values.
 *
 * @param {ref} checkoutPageFormInstance
 */
export const setCheckoutPageForm = (checkoutPageFormInstance) =>
    (_checkoutPageForm = checkoutPageFormInstance);

/**
 * Extract the attendee id, question id, and question subfield of a question that
 * failed validation, based on the error question identifier returned by the API.
 *
 * @param {string} questionIdentifier    String returned by API in error message to indicate
 *                                           which question has failed validation. Examples
 *                                           are '4' or '4.region' if error is in subfield.
 * @param {array} answerIndexToIdArray   Value returned from `_buildAnswerIndexToIdArray`
 *
 * @return [attendeeId, questionId, questionSubfield]
 *                                       Example return value is ['A-acd3e', 'N-age', null] or
 *                                           ['buyer', 'N-work', 'address1']. Note that if the
 *                                           subfield is in an address question, the EDS name
 *                                           such as 'address1' will be returned instead of the
 *                                           API name such as 'address_1'.
 */
export const _parseErrorQuestionIdentifier = (
    questionIdentifier,
    answerIndexToIdArray,
) => {
    let questionIndex = questionIdentifier;
    let questionSubfield = null;

    if (questionIdentifier.indexOf('.') >= 0) {
        [questionIndex, questionSubfield] = questionIdentifier.split('.');

        const apiFieldToCheckoutField = _isPaymentQuestion(questionIdentifier)
            ? API_PAYMENT_FIELD_TO_CHECKOUT_PAGE_PAYMENT_FIELD
            : API_ADDRESS_FIELD_TO_EDS_ADDRESS_FIELD;

        questionSubfield =
            apiFieldToCheckoutField[questionSubfield] || questionSubfield;
    }

    const [attendeeId, questionId] = answerIndexToIdArray[
        Number(questionIndex)
    ];

    return [attendeeId, questionId, questionSubfield];
};

/**
 * _makeSectionErrorObject is a helper function to create an error
 * object detailing the error message that applies to a specific field
 * and form section.
 *
 * @param {string or null} sectionId - string indicating the form section with the error
 * @param {string} errorFieldName - name of the field on which the error should be displayed
 * @param {string} errorMessage - error message text to surface to the end user
 */
const _makeSectionErrorObject = (sectionId, errorFieldName, errorMessage) => ({
    [sectionId || TICKET_BUYER_SECTION_NAME]: {
        [errorFieldName]: errorMessage,
    },
});

const getFormFieldErrorForBackendFieldError = (errorFieldName, sectionId) => {
    const backendFieldName = errorFieldName.split('.').slice(-1)[0];
    const errorMessage = gettext('This field is required');

    const sectionsWithDifferentNamesForFields = [
        {
            isFieldInSection: _isPaymentQuestion,
            formFieldSectionId: PAYMENT_SECTION_NAME,
            formFieldForBackendField: API_PAYMENT_FIELD_TO_CHECKOUT_PAGE_PAYMENT_FIELD,
        },
        {
            isFieldInSection: _isTickerBuyerQuestion,
            formFieldSectionId: TICKET_BUYER_SECTION_NAME,
            formFieldForBackendField: API_SOURCE_INSTRUMENT_FIELD_TO_CHECKOUT_PAGE_TICKET_BUYER_FIELD,
        },
    ];

    for (const sectionRequiringFieldNameMapping of sectionsWithDifferentNamesForFields) {
        if (sectionRequiringFieldNameMapping.isFieldInSection(errorFieldName)) {
            return {
                sectionId: sectionRequiringFieldNameMapping.formFieldSectionId,
                errorFieldName:
                    sectionRequiringFieldNameMapping.formFieldForBackendField[
                        backendFieldName
                    ],
                errorMessage,
            };
        }
    }

    return {
        sectionId,
        errorFieldName,
        errorMessage,
    };
};

/**
 * Declarative specification of how to handle errors on individual
 * refer to the documentation on `translateDynamicSurveyErrors` for a
 * complete description.
 */
export const SURVEY_ERROR_SPEC = {
    default: () =>
        // TODO: need to throw an error and log to bugsnag further up.
        gettext('There was an error with this field'),

    EXCEEDS_MAX_LENGTH: (
        questionIdentifier,
        answerIndexToIdArray,
        maxLength,
    ) => {
        /**
         * This is currently used for address field max length validation
         */
        const [
            attendeeId,
            questionId,
            questionSubfield,
        ] = _parseErrorQuestionIdentifier(
            questionIdentifier,
            answerIndexToIdArray,
        );
        const errorMessage = gettext(
            `Answer may not exceed ${maxLength} characters`,
        );
        let errorData = errorMessage;

        if (questionSubfield) {
            errorData = {
                [questionSubfield]: errorMessage,
            };
        }

        return _makeSectionErrorObject(attendeeId, questionId, errorData);
    },

    INVALID_EMAIL: (questionIndex, answerIndexToIdArray) => {
        /**
         * This is used when an email is invalid but not caught by FE validation. The only case I can see this
         * happening is when an email address ends in a hyphen.
         */
        const [attendeeId, questionId] = answerIndexToIdArray[
            Number(questionIndex)
        ];

        return _makeSectionErrorObject(
            attendeeId,
            questionId,
            gettext('Please enter a valid email address'),
        );
    },

    DATE_OUT_OF_RANGE: (questionIndex, answerIndexToIdArray) => {
        /**
         * This error is returned by the API only when the birth date canned question
         * is answered with a complete but invalid date, e.g. February 31 1950. Given
         * our current frontend UI which uses a series of month/day/year dropdowns, the
         * only possible invalid value is the day.
         */
        const [attendeeId, questionId] = answerIndexToIdArray[
            Number(questionIndex)
        ];
        const errorMessage = gettext('Please select a valid day');
        const errorData = {
            day: errorMessage,
        };

        return _makeSectionErrorObject(attendeeId, questionId, errorData);
    },

    LESS_THAN_MINIMUM: (questionIndex, answerIndexToIdArray, minValue) => {
        /**
         * This is used to validate that the Age answer is > 0
         */
        const [attendeeId, questionId] = answerIndexToIdArray[
            Number(questionIndex)
        ];

        return _makeSectionErrorObject(
            attendeeId,
            questionId,
            gettext(`Answer must be greater than ${minValue}`),
        );
    },

    MISSING: (questionId, answerIndexToIdArray, optionalSectionId) => {
        const {
            sectionId,
            errorFieldName,
            errorMessage,
        } = getFormFieldErrorForBackendFieldError(
            questionId,
            optionalSectionId,
        );

        return _makeSectionErrorObject(sectionId, errorFieldName, errorMessage);
    },
};

const _getAttendeeIdForKey = (key) => {
    let attendeeId = null;

    if (key.indexOf('A-') === 0) {
        attendeeId = key;
    }

    return attendeeId;
};

export const transformAddressForApi = (formAddress) => ({
    address_1: formAddress.address1,
    address_2: formAddress.address2,
    city: formAddress.city,
    region: formAddress.region,
    country: formAddress.country,
    postal_code: formAddress.postal,
});

/**
 * Given a questionId and the answer as it is stored in the Redux form data, return
 * the answer in the format expected by the API. For address questions, some
 * transformation is necessary.
 */
export const _getFormattedAnswer = (questionId, rawAnswer) => {
    let formattedAnswer = rawAnswer;

    if (_isAddressQuestion(questionId)) {
        formattedAnswer = transformAddressForApi(rawAnswer);
    }

    return formattedAnswer;
};

// TODO THIS IS A TEMPORARY WORKAROUND for EB-123850
// This should be removed, we should NOT rely on any order coming from the front end, this is a temporary fix.
// This should be removed after it has been implemented in the backend in task EB-123853
export const _sortQuestionIds = (keyA, keyB) => {
    if (isNaN(keyA) || isNaN(keyB)) {
        const qIdA = parseInt(keyA.substring(2), 10);
        const qIdB = parseInt(keyB.substring(2), 10);

        if (!isNaN(qIdA) && !isNaN(qIdB)) {
            return qIdA - qIdB;
        }

        if (keyA < keyB) {
            return -1;
        }

        if (keyA > keyB) {
            return 1;
        }

        return 0;
    }

    return keyA - keyB;
};

const _getAnswersForFormSection = (answers, formSectionKey) => {
    const attendeeId = _getAttendeeIdForKey(formSectionKey);
    const sortedQuestionIds = Object.keys(answers).sort(_sortQuestionIds);

    return sortedQuestionIds.map((questionId) => ({
        attendee_id: attendeeId,
        question_id: questionId,
        answer: _getFormattedAnswer(questionId, answers[questionId]),
    }));
};

export const getSurveyAnswers = (formData) =>
    flatten(
        Object.keys(formData)
            .sort()
            .map((formSectionKey) =>
                _getAnswersForFormSection(
                    formData[formSectionKey],
                    formSectionKey,
                ),
            ),
    );

/**
 * Convert survey answer values from the redux-form format, such as:
 * formData =
 * {
 *     'A-a5abb125b6934dcf8d93e2e3e56ab6dd': {
 *        'N-cell_phone': '555-555-5555'
 *     },
 *     'buyer': {
 *         'N-email': 'cubes@example.com',
 *         'N-first_name': 'cubes',
 *         'N-last_name': 'Silverstein'
 *     },
 *     'payment': {
 *         cardExpirationDate: '1220',
 *         cardPostalCode: '12345',
 *         cardSecurityCode: '123',
 *         creditCardNumber: '4111111111111111',
 *     },
 * }
 *
 * To an array with the same ordering as that returned by
 * `getSurveyAnswers` indicating the respondent and question id at
 * each index, omitting the section with the sectionName:
 * Example _buildSectionIndexToIdArray = (formData, PAYMENT_SECTION_NAME)
 * [
 *     ['A-a5abb125b6934dcf8d93e2e3e56ab6dd', 'N-cell_phone'],
 *     ['buyer', 'N-email'],
 *     ['buyer', 'N-first_name'],
 *     ['buyer', 'N-last_name']
 * ]
 *
 * This array is used by survey error spec functions to convert a
 * questionIndex to the associated respondent id (aka form section)
 * and question id.
 *
 * @param {object} formData - values from reduxFrom component
 * @param {string} sectionName - sectionName to omit (i.e. TICKET_BUYER_SECTION_NAME or PAYMENT_SECTION_NAME)
 * @returns {array}
 */
export const _buildSectionIndexToIdArray = (formData, sectionName) => {
    const formDataSurveyAnswers = omit(formData, sectionName);
    const sortedFormSectionKeys = Object.keys(formDataSurveyAnswers).sort();

    return flatten(
        sortedFormSectionKeys.map((formSectionKey) => {
            const sortedQuestionIds = Object.keys(
                formData[formSectionKey],
            ).sort();

            return sortedQuestionIds.map((questionId) => [
                formSectionKey,
                questionId,
            ]);
        }),
    );
};

/**
 * _getErrorCodeAndArgsFromDescriptor is a helper function to convert
 * an errorDescriptor into a string error code, and an array of
 * context arguments that provide additional context about the error.
 *
 * @param {string|Array<string>} errorDescriptor - either a bare string
 *   error code, such as 'INVALID_EMAIL', or an array whose first
 *   element is an error code, and the subsequent elements provide
 *   error context, example:
 *   ['MISSING', 'A-00acd3ed3e7b4f2783a2ddf44055a584']
 * @returns object with keys errorCode and contextArgs.
 */
export const _getErrorCodeAndArgsFromDescriptor = (errorDescriptor) => {
    if (typeof errorDescriptor !== 'string') {
        return {
            errorCode: errorDescriptor[0],
            contextArgs: errorDescriptor.slice(1),
        };
    }

    return { errorCode: errorDescriptor, contextArgs: [] };
};

/**
 * _getDynamicErrorsFromDetail
 *
 * @param {object} errorSpec - survey error spec in the format
 *   described in the documentation for `translateDynamicSurveyErrors`.
 * @param {array} indexToIdArray - value returned from
 *   `_buildSectionIndexToIdArray`.
 * @param {string} errorField - key from the
 *   error_detail['ARGUMENTS_ERROR'] object, such as 'order.answers.6'
 *   or 'order.answers.N-cell_phone'.
 * @param {array} errorDescriptors - array of error descriptors
 *   describing errors on errorField.
 * @param {string} fieldPrefix - prefix of the field such as 'payment_methods'
 *   that precedes the questionId withinin the errorField
 * @returns array of error objects describing errors on errorField.
 */
export const _getDynamicErrorsFromDetail = (
    errorSpec,
    indexToIdArray,
    errorField,
    errorDescriptors,
    fieldPrefix,
) => {
    const questionIndexOrId = errorField.replace(fieldPrefix, '');

    return errorDescriptors.map((descriptor) => {
        const { errorCode, contextArgs } = _getErrorCodeAndArgsFromDescriptor(
            descriptor,
        );

        return getErrorFromSpec(
            errorSpec,
            errorCode,
            questionIndexOrId,
            indexToIdArray,
            ...contextArgs,
        );
    });
};

/**
 * _mergeSectionErrorObjects transforms a list of individual error
 * objects as returned by `_makeSectionErrorObject`, and merges them
 * into the nested object format that redux-form expects.
 *
 * This method is necessary vs. using `_.extend` or `Object.assign`
 * because `_.extend` and `Object.assign` both perform a shallow copy
 * of properties.
 *
 * As an example, this input:
 *
 * [
 *     {
 *         'buyer': {
 *             'N-email': 'Please enter a valid email address'
 *         }
 *     },
 *     {
 *         'A-00acd3ed3e7b4f2783a2ddf44055a584': {
 *             'N-cell_phone': 'This field is required'
 *         }
 *     },
 *     {
 *         'A-00acd3ed3e7b4f2783a2ddf44055a584': {
 *             'U-659470': 'This field is required'
 *         }
 *     },
 *     {
 *         'A-00acd3ed3e7b4f2783a2ddf44055a584': {
 *             'N-work': {
 *                  'region': 'Answer may not exceed 30 characters'
 *              }
 *          }
 *     },
 *     {
 *         'A-00acd3ed3e7b4f2783a2ddf44055a584': {
 *             'N-work': {
 *                  'postal': 'Answer may not exceed 10 characters'
 *              }
 *         }
 *     },
 *     {
 *         'A-3df52b509e934ed2b89da2c80fce9c8b': {
 *             'N-cell_phone': 'This field is required'
 *         }
 *     },
 *     {
 *         'A-3df52b509e934ed2b89da2c80fce9c8b': {
 *             'N-work': {
 *                  'address1': 'This field is required',
 *                  'city': 'This field is required',
 *                  'region': 'This field is required',
 *                  'country': 'This field is required',
 *              }
 *         }
 *     },
 *     {
 *         'A-3df52b509e934ed2b89da2c80fce9c8b': {
 *             'N-work': {
 *                  'postal': 'Answer may not exceed 10 characters'
 *              }
 *         }
 *     }
 * ]
 *
 * Would result in the following ouput:
 * {
 *     'A-00acd3ed3e7b4f2783a2ddf44055a584': {
 *         'N-cell_phone': 'This field is required',
 *         'U-659470': 'This field is required'
 *         'N-work': {
 *              'region': 'Answer may not exceed 30 characters',
 *              'postal': 'Answer may not exceed 10 characters',
 *          }
 *     },
 *     'A-3df52b509e934ed2b89da2c80fce9c8b': {
 *         'N-cell_phone': 'This field is required'
 *         'N-work': {
 *              'address1': 'This field is required',
 *              'city': 'This field is required',
 *              'region': 'This field is required',
 *              'country': 'This field is required',
 *              'postal': 'Answer may not exceed 10 characters',
 *          }
 *     },
 *     'buyer': {
 *         'N-email': 'Please enter a valid email address',
 *     }
 * }
 *
 * @param {Array<Record<string, Record<string, string | Record<string, string>>>>} errorObjects
 */
export const _mergeSectionErrorObjects = (errorObjects) =>
    errorObjects.reduce((accum, singleFieldSection) => {
        const sectionName = Object.keys(singleFieldSection)[0];
        const existingFieldsInSection = accum[sectionName] || {};
        const currentSection = singleFieldSection[sectionName];
        const fieldName = Object.keys(singleFieldSection[sectionName])[0];
        const existingSubfieldsInField = existingFieldsInSection[fieldName];
        const currentField = currentSection[fieldName];

        return typeof currentField === 'string'
            ? {
                  ...accum,
                  [sectionName]: {
                      ...existingFieldsInSection,
                      [fieldName]: currentField,
                  },
              }
            : {
                  ...accum,
                  [sectionName]: {
                      ...existingFieldsInSection,
                      [fieldName]: {
                          ...existingSubfieldsInField,
                          ...currentField,
                      },
                  },
              };
    }, {});

/**
 * @param {string} errorAttr
 * @returns {Array<string>}
 */
const getFormFieldPrefixReferencedByError = (errorAttr) =>
    [
        SURVEY_ANSWER_PREFIX,
        PAYMENT_ANSWER_PREFIX,
        SOURCE_INSTRUMENT_BILLING_INFORMATION_PREFIX,
    ].find((prefix) => errorAttr.indexOf(prefix) === 0);

/**
 * @param {string} errorAttr
 * @returns {boolean}
 */
const isErrorReferencingFormField = (errorAttr) =>
    !!getFormFieldPrefixReferencedByError(errorAttr);

/**
 * @param {string} fieldPrefix
 * @returns {Array<[string, string]>} Array of pairs where first value is the prefix and second is the field name
 */
const getFormFieldsIdentifiersIncludedInPrefix = (fieldPrefix) => {
    const surveyIndexToIdArray = _buildSectionIndexToIdArray(
        _checkoutPageForm.values,
        PAYMENT_SECTION_NAME,
    );
    const paymentIndexToIdArray = _buildSectionIndexToIdArray(
        _checkoutPageForm.values,
        TICKET_BUYER_SECTION_NAME,
    );

    const sourceInstrumentSectionName = SOURCE_INSTRUMENT_BILLING_INFORMATION_PREFIX.replace(
        /\.$/,
        '',
    );

    const sourceInstrumentBillingInfoIndexToIdArray = Object.keys(
        API_SOURCE_INSTRUMENT_FIELD_TO_CHECKOUT_PAGE_TICKET_BUYER_FIELD,
    ).map((backendFieldName) => [
        sourceInstrumentSectionName,
        backendFieldName,
    ]);

    const formFieldsIdentifiersForPrefix = {
        [SURVEY_ANSWER_PREFIX]: surveyIndexToIdArray,
        [PAYMENT_ANSWER_PREFIX]: paymentIndexToIdArray,
        [SOURCE_INSTRUMENT_BILLING_INFORMATION_PREFIX]: sourceInstrumentBillingInfoIndexToIdArray,
    };

    return formFieldsIdentifiersForPrefix[fieldPrefix];
};

/**
 * @param {Object} parsedError
 * @param {string} parsedError.error
 * @param {Record<string, Array<string>>} [parsedError.argumentErrors]
 * @returns {boolean}
 */
const isInvalidCreditCardNumberError = (parsedError) =>
    parsedError.error === 'ARGUMENTS_ERROR' &&
    parsedError.argumentErrors &&
    parsedError.argumentErrors.hasOwnProperty(
        'source_instrument.card_number',
    ) &&
    parsedError.argumentErrors['source_instrument.card_number'][0] ===
        INVALID_ERROR;

/**
 * @param {Object} parsedError
 * @param {string} parsedError.error
 * @param {Record<string, Array<string>>} [parsedError.argumentErrors]
 * @returns {boolean}
 */
const isInvalidCreditCardExpirationDateError = (parsedError) =>
    parsedError.error === 'ARGUMENTS_ERROR' &&
    parsedError.argumentErrors &&
    parsedError.argumentErrors.hasOwnProperty(
        'source_instrument.expiration_date',
    ) &&
    parsedError.argumentErrors['source_instrument.expiration_date'][0] ===
        INVALID_ERROR;

/**
 * @param {Object} parsedError
 * @param {string} parsedError.error
 * @param {Record<string, Array<string>>} [parsedError.argumentErrors]
 * @returns {boolean}
 */
const isInventorySurveyError = (parsedError) =>
    parsedError.error === 'ARGUMENTS_ERROR' &&
    parsedError.argumentErrors &&
    parsedError.argumentErrors.hasOwnProperty('order.answers') &&
    parsedError.argumentErrors['order.answers'][0] === INVENTORY_SURVEY_ERROR;

/**
 * @param {Object} parsedError
 * @param {string} parsedError.error
 * @param {Record<string, Array<string>>} [parsedError.argumentErrors]
 * @returns {string|null}
 */
const getGeneralFormErrorMessage = (parsedError) => {
    if (isInvalidCreditCardNumberError(parsedError)) {
        return gettext(
            'Credit card number is invalid. Double check it or use a different card or payment method.',
        );
    }

    if (isInvalidCreditCardExpirationDateError(parsedError)) {
        return gettext(
            'Credit card expiration date is invalid. Double check it or use a different card or payment method.',
        );
    }

    if (isInventorySurveyError(parsedError)) {
        return gettext(
            'This option is not available anymore. Please choose a different option.',
        );
    }

    return null;
};

/**
 * translateDynamicSurveyErrors will further translate survey-specific
 * arugmentErrors from a declarative spec. For use with
 * `utils.v3.translateArgumentErrors`
 *
 * The purpose of `translateDynamicSurveyErrors` is to translate the
 * JSON error object returned by the v3 order API when a survey
 * related error occurs into a JavaScript error object in the format
 * expected by redux-form.
 *
 * NOTE: This function is used only for server returned errors (400s)
 * related to survey questions. Most field errors, like missing fields,
 * are already caught via front end validation and do not rely on
 * server validation and this translate function.
 *
 * Here's an example JSON error object that might be returned by the
 * v3 order API:
 * {
 *   "error": "ARGUMENTS_ERROR",
 *   "error_description": "There are errors with your arguments...",
 *   "error_detail": {
 *       "ARGUMENTS_ERROR": {
 *           "order.answers.6": ["INVALID_EMAIL"],
 *           "order.answers.0": ["LESS_THAN_MINIMUM", "0"],
 *       },
 *   },
 *   "status_code": 400
 * }
 *
 * And here's the corresponding JavaScript error object that
 * redux-form would expect:
 * {
 *     'A-00acd3ed3e7b4f2783a2ddf44055a584' {
 *         'N-age': 'Answer must be greater than 0',
 *     }
 *     'A-3df52b509e934ed2b89da2c80fce9c8b': {
 *         'N-age': 'Answer must be greater than 0'
 *     }
 *     'buyer': {
 *         'N-email': 'Please enter a valid email address',
 *     },
 *     '_error': 'Please correct the highlighted fields below'
 * }
 *
 * The errorSpec input to `translateDynamicSurveyErrors` maps from
 * error code key strings, such as `"INVALID_EMAIL"` or `"MISSING"`,
 * to functions which return an object that details:
 * a) which field (and form section) has an error
 * b) the error message text to surface to the end user
 * that is, something like this:
 * 'A-00acd3ed3e7b4f2783a2ddf44055a584' {
 *     'N-age': 'Answer must be greater than 0'
 * }
 *
 * All functions in the errorSpec map are of the form:
 * (questionIndexOrId, answerIndexToIdArray, ...contextArgs) => {
 *     // return an error object as described above.
 * }
 *
 * The source of truth listing error codes is in the
 * "Surveys - the missing spec" document at:
 * https://docs.google.com/document/d/16D1blzLZ96F0uPtLkCkyE7AGYEG7uTllwO4Lbh10mGY/edit#bookmark=id.qbcaynnd0979
 *
 * it may be the case that the error response is like this:
 *   "error_detail": {
 *       "ARGUMENTS_ERROR": {
 *           "order.answers": ["INVENTORY_ERROR"],
 * where it's not defined any id in survey, and it´s because an option is not available already in the purchase moment
 *
 * Example usage:
 * let surveyErrorSpec = {
 *     'default': () => gettext('There was an error with this field'),
 *     'INVALID_EMAIL': (questionIndex, answerIndexToIdArray) => {
 *         let [attendeeId, questionId] = answerIndexToIdArray[Number(questionIndex)];
 *
 *         return _makeSectionErrorObject(
 *             attendeeId,
 *             questionId,
 *             gettext('Please enter a valid email address')
 *         );
 *     },
 *     'MISSING': (questionId, answerIndexToIdArray, attendeeId) => (
 *         _makeSectionErrorObject(
 *             attendeeId,
 *             questionId,
 *             gettext('This field is required')
 *         )
 *     )
 * };
 *
 * let argumentErrorSpec = {
 *     'default': translateDynamicSurveyErrors(surveyErrorSpec)
 * };
 *
 * let serverErrorSpec = {
 *     'default': () => gettext('There was a problem submitting your order'),
 *     'INVENTORY_ERROR': () => gettext('The tickets you selected are no longer available'),
 *     'ORDER_EXPIRED': () => gettext('Your order has expired'),
 *     'ARGUMENTS_ERROR': translateArgumentErrors(argumentErrorSpec)
 * };
 *
 * return fetchV3(
 *     '/api/v3/orders/<order_id>/',
 *     {
 *         method: 'POST',
 *         body: JSON.stringify({
 *             'order': {<order body>}
 *         })
 *     }
 * )
 * .catch(translateServerErrors(serverErrorSpec)
 *
 * @param {object} errorSpec - declarative specification of known survey errors
 */
export const translateDynamicSurveyErrors = (errorSpec) => (
    parsedError,
    response,
) => {
    const errorObjects = flattenDeep(
        Object.keys(parsedError.argumentErrors || {})
            .filter((errorAttr) => isErrorReferencingFormField(errorAttr))
            .map((errorAttr) => {
                const fieldPrefix = getFormFieldPrefixReferencedByError(
                    errorAttr,
                );
                const indexToIdArray = getFormFieldsIdentifiersIncludedInPrefix(
                    fieldPrefix,
                );

                return _getDynamicErrorsFromDetail(
                    errorSpec,
                    indexToIdArray,
                    errorAttr,
                    parsedError.argumentErrors[errorAttr],
                    fieldPrefix,
                );
            }),
    );

    if (errorObjects.length) {
        return {
            ..._mergeSectionErrorObjects(errorObjects),
            _error: gettext('Please correct the highlighted fields below'),
        };
    }

    const result = getErrorFromSpec(
        errorSpec,
        'default',
        parsedError,
        response,
    );

    const errorMessage = getGeneralFormErrorMessage(parsedError);
    if (errorMessage) {
        return {
            ...result,
            _error: errorMessage,
        };
    }

    if (!isEmpty(result)) {
        return {
            ...result,
            _error: gettext('Please correct the highlighted fields below'),
        };
    }

    return result;
};

export const changeSurveyField = (
    field,
    value,
    formName = CHECKOUT_FORM_NAME,
) => (dispatch) =>
    /**
     * redux form action CHANGE signature
     * form: string
     * field: string
     * value: any
     * touch?: boolean
     * persistentSubmitErrors?: boolean
     *
     * https://github.com/erikras/redux-form/blob/v6.2.0/src/actions.js#L61
     */
    dispatch(change(formName, field, value, true));

/**
 * Checks if we should keep track and update the current inventory for this question
 * @param {Number} numberOfAttendees the current number of Attendees
 * @param {Array} choices the array of choices for this question
 * @param {String} attendeeId the current attendee ID
 * @param {Boolean} isOutOfInventory true when this question is out of inventory
 */
export const shouldUpdateInventory = (
    numberOfAttendees,
    choices,
    attendeeId,
    isOutOfInventory,
) =>
    numberOfAttendees > 1 &&
    isInventoryQuestion(choices) &&
    attendeeId !== SELECTED_QUESTION_GROUP_BUYER &&
    !isOutOfInventory;
