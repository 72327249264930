import includes from 'lodash/includes';
import { snakeToCamel } from '@eb/string-utils';
import gettext from '@eb/gettext';
import {
    QuestionType,
    CannedQuestionName,
    QuestionChoice,
    QuestionId,
    QuestionsCollection,
    QuestionTrigger,
    InteractiveQuestion,
} from '../api/models/survey';

export const SELECTED_QUESTION_GROUP_BUYER = 'buyer';

export const LOCALIZED_SURVEY_QUESTIONS_BY_QUESTION_ID: Partial<Record<
    CannedQuestionName,
    string
>> = {
    [CannedQuestionName.prefix]: gettext('Prefix'),
    [CannedQuestionName.firstName]: gettext('First name'),
    [CannedQuestionName.lastName]: gettext('Last name'),
    [CannedQuestionName.suffix]: gettext('Suffix'),
    [CannedQuestionName.email]: gettext('Email address'),
    [CannedQuestionName.homePhone]: gettext('Home phone'),
    [CannedQuestionName.cellPhone]: gettext('Cell phone'),
    [CannedQuestionName.company]: gettext('Company'),
    [CannedQuestionName.nameTaxReceipt]: gettext('Name on Tax Receipt'),
    [CannedQuestionName.individualTaxAddress]: gettext('Address'),
    [CannedQuestionName.businessTaxAddress]: gettext('Work address'),
    [CannedQuestionName.companyTax]: gettext('Company'),
    [CannedQuestionName.work]: gettext('Work address'),
    [CannedQuestionName.home]: gettext('Home address'),
    [CannedQuestionName.ship]: gettext('Shipping address'),
    [CannedQuestionName.jobTitle]: gettext('Job title'),
    [CannedQuestionName.workPhone]: gettext('Work phone'),
    [CannedQuestionName.website]: gettext('Website'),
    [CannedQuestionName.blog]: gettext('Blog'),
    [CannedQuestionName.sex]: gettext('Gender'),
    [CannedQuestionName.birthDate]: gettext('Birthdate'),
    [CannedQuestionName.age]: gettext('Age'),
    [CannedQuestionName.taxRegistrationId]: gettext('Tax Registration ID'),
    [CannedQuestionName.isBusiness]: gettext(
        'Registration Type & Tax Receipt Information',
    ),
};

export const QUESTIONS_TO_OMIT_FOR_ATTENDEE_QUESTIONS = [
    CannedQuestionName.cc,
    CannedQuestionName.bill,
];

export const QUESTIONS_FOR_BASIC_INFO = [
    CannedQuestionName.email,
    CannedQuestionName.firstName,
    CannedQuestionName.lastName,
    CannedQuestionName.prefix,
    CannedQuestionName.suffix,
];

export const QUESTIONS_TO_OMIT_FOR_BUYER_QUESTIONS = [
    ...QUESTIONS_FOR_BASIC_INFO,
    CannedQuestionName.cc,
    CannedQuestionName.bill,
];

export const ADDRESS_QUESTIONS = [
    CannedQuestionName.bill,
    CannedQuestionName.work,
    CannedQuestionName.home,
    CannedQuestionName.ship,
    CannedQuestionName.individualTaxAddress,
    CannedQuestionName.businessTaxAddress,
];

export const LOCALIZED_QUESTIONS_CHOICES_BY_QUESTION_ID: Partial<Record<
    CannedQuestionName,
    Array<QuestionChoice>
>> = {
    [CannedQuestionName.isBusiness]: [
        {
            answer: {
                text: gettext('An Individual'),
                html: gettext('An Individual'),
            },
            id: '0',
            quantityAvailable: null,
        },
        {
            answer: {
                text: gettext('A Business'),
                html: gettext('A Business'),
            },
            id: '1',
            quantityAvailable: null,
        },
    ],
};

export interface SurveyQuestion {
    choices: Array<QuestionChoice>;
    required: boolean;
    type: QuestionType;
    name: QuestionId;
    text: string;
    html: string;
    waiverText?: string;
    renderConditionTrigger: null | QuestionTrigger;
    isOutOfInventory: boolean;
}

const CUSTOM_QUESTION_PATTERN = /^U-\d+/;

export const isCustomSurveyQuestion = (questionId: QuestionId): boolean =>
    CUSTOM_QUESTION_PATTERN.test(questionId);

/**
 * Checks if this question is an Inventory Question depending of its choices.
 * By default, all choices have quantityAvailable = null
 */
export const isInventoryQuestion = (
    choices: Array<QuestionChoice> = [],
): boolean => choices[0] && choices[0].quantityAvailable !== null;

const getChoicesText = (
    choices: Array<QuestionChoice>,
    questionId: QuestionId,
): QuestionChoice | any => {
    if (isCustomSurveyQuestion(questionId)) {
        return choices;
    }

    const cannedQuestionName = questionId as CannedQuestionName;

    return (
        LOCALIZED_QUESTIONS_CHOICES_BY_QUESTION_ID[cannedQuestionName] ||
        choices
    );
};

const getQuestionLabel = (
    htmlAndText: { html: string; text: string },
    key: 'html' | 'text',
    questionId: QuestionId,
    taxIdName?: string,
) => {
    if (questionId === CannedQuestionName.taxRegistrationId && taxIdName) {
        return taxIdName;
    }

    const cannedQuestionName = questionId as CannedQuestionName;

    return isCustomSurveyQuestion(questionId)
        ? htmlAndText[key]
        : LOCALIZED_SURVEY_QUESTIONS_BY_QUESTION_ID[cannedQuestionName];
};

export const shouldIncludeInAttendeeQuestions = (questionId: QuestionId) =>
    !includes(QUESTIONS_TO_OMIT_FOR_ATTENDEE_QUESTIONS, questionId);

export const shouldIncludeInBasicInfo = (questionId: QuestionId) =>
    includes(QUESTIONS_FOR_BASIC_INFO, questionId);

export const shouldIncludeInBuyerQuestions = (questionId: QuestionId) =>
    !includes(QUESTIONS_TO_OMIT_FOR_BUYER_QUESTIONS, questionId);

/**
 * Return all the information needed to compose an individual survey on the checkout form. A survey can be:
 *     - basic info: email and first/last name only
 *          - One exception: when Prefix and/or Suffix are collected AND the event is Buyer Info survey type, basic info
 *            also includes those fields.
 *     - buyer questions: questions asked only once on the form
 *     - attendee questions: questions asked of the attendee associated with a specific ticket type
 *
 * These are the valid combinations:
 *     - basic info only (Basic Info survey type)
 *     - basic info + buyer questions (Buyer Info survey type)
 *     - basic info + attendee questions (Each Attendee survey type)
 */
export const getBuiltSurvey = (
    questions: QuestionsCollection,
    interactiveQuestions: Array<InteractiveQuestion>,
    shouldIncludeQuestionHelper: (questionId: QuestionId) => boolean,
    taxIdName?: string,
): Array<SurveyQuestion> =>
    interactiveQuestions
        .filter(({ questionId }) => shouldIncludeQuestionHelper(questionId))
        .map(({ questionId, required, trigger }) => {
            const { question, type, choices, waiver } = questions[
                snakeToCamel(questionId)
            ];

            const isOutOfInventory = isInventoryQuestion(choices)
                ? !choices.find(
                      ({ quantityAvailable }) => (quantityAvailable ?? 0) > 0,
                  )
                : false;

            return {
                choices: getChoicesText(choices, questionId),
                required,
                type,
                name: questionId,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                text: getQuestionLabel(
                    question,
                    'text',
                    questionId,
                    taxIdName,
                )!,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                html: getQuestionLabel(
                    question,
                    'html',
                    questionId,
                    taxIdName,
                )!,
                waiverText: waiver,
                renderConditionTrigger: trigger,
                isOutOfInventory,
            };
        });

export type Survey = {};

export interface Attendee {
    id: string;
    survey: Array<Survey>;
}

export interface PrefillSurveyFieldsInfo {
    formName?: string;
    emailToPrefill?: string;
    firstNameToPrefill?: string;
    lastNameToPrefill?: string;
    attendeeWithSurvey?: Attendee;
    isTransferredOrder?: boolean;
    shouldManageUserSavedSurveyData?: boolean;
    surveyResponseNameValues?: Record<string, string>;
}
