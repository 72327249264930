import React from 'react';
import PropTypes from 'prop-types';

import {
    getTicketInfoFromAssignedUnits,
    getTitleSectionText,
    hasSectionTickets,
} from '../../containers/ticketSelection/utils/ticketInfoTableUtils';
import {
    TicketTable,
    TicketInfo,
} from '../../containers/ticketSelection/TicketInfoTable';
import { RESERVED_SEATING_ASSIGNED_UNIT_SHAPE_OBJECT } from '../../constants';

export const UnitInfoTable = ({
    assignedUnitsWithTicketId,
    hasDeleteColumn,
    onClickRemoveUnit,
    isRegEvent,
}) => {
    const ticketInfo = getTicketInfoFromAssignedUnits(
        assignedUnitsWithTicketId,
    );

    const titleSectionText = getTitleSectionText(isRegEvent);

    const ticketTables = Object.keys(ticketInfo).map((ticketType) =>
        ticketInfo[ticketType].map((ticketTable) => (
            <TicketTable
                collateRows={true}
                columns={ticketTable.cols}
                hasDeleteColumn={hasDeleteColumn}
                key={ticketTable.key}
                noSectionTickets={!hasSectionTickets(ticketInfo)}
                onClickRemoveUnit={onClickRemoveUnit}
                rows={ticketTable.rows}
                type={ticketType}
            />
        )),
    );

    return (
        <TicketInfo ticketTables={ticketTables} titleText={titleSectionText} />
    );
};

UnitInfoTable.propTypes = {
    /** A function to be called when the trash icon next to a particular unit is clicked. Passes the unit id and its ticket class id as parameters */
    onClickRemoveUnit: PropTypes.func,
    hasDeleteColumn: PropTypes.bool,
    /** An array of the assigned units */
    assignedUnitsWithTicketId: PropTypes.arrayOf(
        PropTypes.shape({
            ...RESERVED_SEATING_ASSIGNED_UNIT_SHAPE_OBJECT,
            ticketClassId: PropTypes.string.isRequired,
        }),
    ),
    isRegEvent: PropTypes.bool,
};
