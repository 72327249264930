import * as React from 'react';

const DiscoverPaymentSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 48 32" {...props}>
        <path
            fill="#FFEEDA"
            d="M0 3c0-1.6 1.4-3 3-3h42c1.7 0 3 1.4 3 3v25.9c0 1.7-1.3 3-3 3H3c-1.6 0-3-1.3-3-3V3z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFA821"
            d="M19 32h26c1.7 0 3-1.3 3-3V14c-4.4 8.2-15.1 14.8-29 18z"
        />
        <ellipse
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFA821"
            cx={24}
            cy={16.2}
            rx={6.7}
            ry={6.6}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FA9A06"
            d="M28.4 11.2c-1.2-1-2.7-1.6-4.4-1.6-3.7 0-6.7 3-6.7 6.6 0 2.1 1 4 2.5 5.2 1.2-4.5 4.4-8.2 8.6-10.2z"
        />
    </svg>
);

DiscoverPaymentSvg.displayName = 'DiscoverPaymentSvg';
export default DiscoverPaymentSvg;
