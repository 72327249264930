import React from 'react';
import gettext from '@eb/gettext';

export const WaitingRoomCTA = ({ shouldShowCTA }) => {
    let component = null;

    // only show the CTA when the user has seen the waiting room more than once
    if (shouldShowCTA) {
        component = (
            <div className="eds-text--center eds-l-pad-hor-4">
                <h3 className="eds-text-hs eds-l-pad-top-4">
                    {gettext("It's your turn!")}
                </h3>

                <h4 className="eds-text-bm eds-l-pad-top-3">
                    {gettext(
                        'Please place your order quickly, this event may sell out soon.',
                    )}
                </h4>
            </div>
        );
    }

    return component;
};

export default WaitingRoomCTA;
