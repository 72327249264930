import { PaypalNamespace, PaypalScriptStatus } from '../state/paypal';
import { PaypalAction, PaypalActionType } from '../actions/paypal';

export const paypalReducer = (
    state: PaypalNamespace = {
        scriptStatus: PaypalScriptStatus.init,
    },
    action: PaypalAction,
): PaypalNamespace => {
    switch (action.type) {
        case PaypalActionType.setScriptStatus:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
