import { Dispatch } from 'redux';

import { UserInstrument, VirtualIncentive } from '../models/user';
import { getCurrentUserAccountDetails } from '../api/users';
import {
    transformCurrentUserData,
    transformUserSiginMethods,
    UserSignInMethodsData,
    LoggedInUserData,
    UserDataResponse,
} from '../api/transformations/user';
import { WithUserNamespace, UserNamespace } from '../state/user';

export enum UserActionType {
    updateUserInstruments = 'updateUserInstruments',
    setVirtualIncentive = 'setVirtualIncentive',
    setUserAsLoggedIn = 'setUserAsLoggedIn',
    setUserSiginInMethods = 'setUserSiginInMethods',
    clearUserData = 'clearUserData',
    initializeUser = 'initializeUser',
}

export interface InitializeUserAction {
    type: UserActionType.initializeUser;
    payload?: UserNamespace;
}

export interface UpdateUserInstrumentsAction {
    type: UserActionType.updateUserInstruments;
    payload: Array<UserInstrument>;
}

export interface SetVirtualIncentiveAction {
    type: UserActionType.setVirtualIncentive;
    payload: VirtualIncentive;
}

export interface SetUserAsLoggedInAction {
    type: UserActionType.setUserAsLoggedIn;
    payload: LoggedInUserData;
}

export interface SetUserSiginInMethodsAction {
    type: UserActionType.setUserSiginInMethods;
    payload: UserSignInMethodsData;
}

export interface ClearUserDataAction {
    type: UserActionType.clearUserData;
}

export const getUpdateUserInstrumentsAction = (
    userInstruments: Array<UserInstrument>,
): UpdateUserInstrumentsAction => ({
    type: UserActionType.updateUserInstruments,
    payload: userInstruments,
});

export const setUserAsLoggedInAction = (
    userDataResponse: UserDataResponse,
): SetUserAsLoggedInAction => ({
    type: UserActionType.setUserAsLoggedIn,
    payload: transformCurrentUserData(userDataResponse),
});

export const setVirtualIncentiveAction = (
    virtualIncentive: VirtualIncentive,
): SetVirtualIncentiveAction => ({
    type: UserActionType.setVirtualIncentive,
    payload: virtualIncentive,
});

export const setUserSiginInMethodsAction = (
    signInMethods: Array<string>,
): SetUserSiginInMethodsAction => ({
    type: UserActionType.setUserSiginInMethods,
    payload: transformUserSiginMethods(signInMethods),
});

export const clearUserDataAction = (): ClearUserDataAction => ({
    type: UserActionType.clearUserData,
});

export const initializeUserAction = (
    initialData: WithUserNamespace,
): InitializeUserAction => ({
    type: UserActionType.initializeUser,
    payload: initialData.user,
});

export const handleLoggedInUserInfo = () => async (dispatch: Dispatch<any>) => {
    const { response } = await getCurrentUserAccountDetails();

    dispatch(setUserAsLoggedInAction(response));
};

export type UserAction =
    | UpdateUserInstrumentsAction
    | SetUserAsLoggedInAction
    | SetVirtualIncentiveAction
    | SetUserSiginInMethodsAction
    | ClearUserDataAction
    | InitializeUserAction;
