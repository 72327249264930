import { moment } from '@eb/date';
import { fetchV3WithTranslateServerErrors } from './base';
import {
    getSeriesEventsUrl,
    SERIES_PAGE_SIZE,
    DEFAULT_SERIES_DATE_FILTER,
    DEFAULT_SERIES_EXPANSIONS,
} from './constants';
import { SERIES_DEFAULT_ERROR_MESSAGE } from '../constants';

const SERIES_ERROR_SPEC = {
    default: () => SERIES_DEFAULT_ERROR_MESSAGE,
};

const _getSeriesEvents = (
    eventId,
    {
        expansions = DEFAULT_SERIES_EXPANSIONS,
        pageSize = SERIES_PAGE_SIZE,
        continuationToken = '',
        dateFilter = DEFAULT_SERIES_DATE_FILTER,
    },
) => {
    const seriesEndpoint = getSeriesEventsUrl({
        eventId,
        expansions,
        pageSize,
        continuationToken,
        dateFilter,
    });

    return fetchV3WithTranslateServerErrors(SERIES_ERROR_SPEC, seriesEndpoint);
};

/**
 *
 * @param {array} events
 * @param {object} pagination
 * @param {string or object} startDateTo  a moment date or a date string
 */
export const _hasMoreSeriesPages = (events, pagination, startDateTo) => {
    if (pagination['has_more_items'] !== true) {
        return false;
    }

    const lastEventDate = moment(events[events.length - 1].start.local);

    return moment(startDateTo).isAfter(lastEventDate, 'day');
};

/**
 * Fetch series events with series events API.
 *
 * @param eventId
 * @param options: {continuationToken, pageSize, expansions, startDateTo}
 *
 * {pageSize} currently is not passed, it uses the the default size from the constants.
 * {expansions} currently is always ['series_dates', 'ticket_availability', 'venue']
 *
 * {continuationToken} is how we track which page of results that we have been on, and
 * it's used for fetching the next page.
 *
 * For example, on very first page load, we call
 *     /series/${eventId}/events
 * results:
 *     {
 *          pagination: {
 *              continuation: 'token1',
 *              has_more_items: true,
 *          },
 *          events: [{event1}, {event2}, ... {event10}]
 *     }
 *
 * Next when user scroll down the page and trigger the next page, we then call
 *     /series/${eventId}/events?continuation=token1
 * results:
 *     {
 *          pagination: {
 *              continuation: 'token2',
 *              has_more_items: true,
 *          },
 *          events: [{event11}, {event12}, ... {event20}]
 *     }
 *
 * Continue with this pattern, till we have the final page (32 events total)
 * results:
 *     {
 *          pagination: {
 *              has_more_items: false,
 *          },
 *          events: [{event31}, {event32}}]
 *     }
 *
 * {startDateTo} is the end date range option received when user click through the calender by months.
 * When user select a month, or click through a month, in order to show all the correct status (available or sold out)
 * for the events, we have to fetch all the events in this month.
 * Example, if user click on 02-01-2019, the startDateTo will be 02-28-2019 23:59:59 (end date of Feb.)
 *
 * ** Why not pass startDateFrom and startDateTo directly to API as filters? Why with the While loop?
 *
 *    This is to keep continuationToken consistent. The contination token will not function correctly once
 *    the API calls with different sets of filters.
 *    Desired behavior: If user click through month of May on calendar, the events are loaded till the end
 *    of May. Then user should still be able to scroll down and see page loads the next page of events of June.
 *    Thus, instead of passing date range to API and lose the previous continuation token.
 *    We keeps calling API with next page till the last event's date fetched has passed the end of May,
 *    or if all series events have been fetched.
 *
 */
export const getSeriesEvents = async (eventId, { ...options }) => {
    const { startDateTo } = options;

    // User clicks on calendar, going through months.
    if (startDateTo) {
        let result;
        const allPages = {
            events: [],
            pagination: {},
        };
        let continuationToken = options.continuationToken;
        let hasMorePages = true;

        while (hasMorePages) {
            try {
                result = await _getSeriesEvents(eventId, {
                    ...options,
                    continuationToken,
                });

                const {
                    response: { events, pagination },
                } = result;

                allPages.events = allPages.events.concat(events);
                allPages.pagination = pagination;

                continuationToken = pagination.continuation;
                hasMorePages = _hasMoreSeriesPages(
                    events,
                    pagination,
                    startDateTo,
                );
            } catch (error) {
                hasMorePages = false;
                throw error;
            }
        }

        return {
            headers: result.headers,
            response: allPages,
        };
    }

    // User scrolls down the page, or the first page load.
    return _getSeriesEvents(eventId, options);
};

/*
 * Get events for a selected date with series event API.
 *
 * @param eventId
 * @param startDate: selected date, ex: "2020-04-01"
 *
 * It is very rare when an organizer schedule a lot of events in one day.
 * A page size 30 should be enough for a single date's events. Or increase
 * it if needed.
 */
export const getSeriesEventsByDate = async (eventId, startDate) => {
    const dateFilter = {
        'start_date.range_start': startDate,
        'start_date.range_end': startDate,
    };
    const pageSize = 30;

    return _getSeriesEvents(eventId, { dateFilter, pageSize });
};
