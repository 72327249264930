// This file contains helper functions for payment info fields.

import last from 'lodash/last';
import { lazyGettext } from '@eb/lazy-gettext';
import { makeValidator } from '@eb/eds-utils';
import { isValidExpirationDate } from '@eb/eds-utils';
import { CARD_EXPIRATION_DATE_FIELD_NAME } from './constants';

/**
 * @param  {string} date     The new date to be parsed.
 * @param  {string} oldDate  The old date to compare and know if a `/` should be added.
 * @return {string}
 */
export const normalizeExpirationDate = (date, oldDate) => {
    let cardExpirationDate = date;

    // When an input of 2 or more chars is present. This allows to paste text typing, and deleting
    if (cardExpirationDate.length >= 2) {
        let isGoingBackwards = last(oldDate) === '/';
        let [month = '', year = ''] = date.split('/');

        // In case a value like `1234` or `123/` is received, the month will have an invalid value.
        if (month.length > 2) {
            isGoingBackwards = false;
            /**
             * Since the month should only have 2 digits the rest is taken as the year.
             * In case a value like `121/5` is received it should not delete the last number of the year
             * we had.
             */
            year = month.substr(2, 2) + year;
            month = month.substr(0, 2);
        }

        // If there was no date at start or we are not deleting the input to change something
        if (!oldDate || !isGoingBackwards) {
            cardExpirationDate = `${month}/${year}`;
        }
    }

    return cardExpirationDate;
};

// This validator object should be imported by each Redux Form that needs to
// use the CardExpirationDateField component. You can see an example of how to use
// it here:
// https://github.com/eventbrite/eventbrite_design_system/tree/master/website/docs/components/cardExpirationDateField/exampleForm.js
export const CARD_EXPIRATION_DATE_FIELD_VALIDATOR = makeValidator({
    [CARD_EXPIRATION_DATE_FIELD_NAME]: {
        validator: (expirationDate) => isValidExpirationDate(expirationDate),
        errorMessage: lazyGettext('Please enter a valid expiration date.'),
    },
});
