import PropTypes from 'prop-types';
import { translationPropType } from '@eb/i18n';

export const VALUES_PROP_TYPE = PropTypes.arrayOf(
    PropTypes.shape({
        value: PropTypes.string.isRequired,
        display: translationPropType.isRequired,
    }),
);

export const PLACEHOLDER_DUMMY_VALUE = '';
