import { getLastPaymentStatus as getLastPaymentStatusApi } from '../api/order';

export const UPDATE_PAYMENT_HISTORY = 'updatePaymentHistory';

/**
 * Updates the payment history with its new value
 * @param {Object} data: the new payment history
 */
export const updatePaymentHistory = (data) => ({
    type: UPDATE_PAYMENT_HISTORY,
    payload: data,
});

/**
 * Fetches the last payment status for a given order
 * @param {String} orderId the order ID to fetch the payment history for
 */
export const getLastPaymentStatus = (orderId) => async (dispatch) => {
    const paymentResponse = await dispatch(getLastPaymentStatusApi(orderId));

    dispatch(updatePaymentHistory(paymentResponse.response));
};
