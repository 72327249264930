import * as React from 'react';

const PaypalPaymentSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 48 32" {...props}>
        <path
            fill="#0070BA"
            d="M3 0h42c1.7 0 3 1.3 3 3v26c0 1.7-1.3 3-3 3H3c-1.7 0-3-1.3-3-3V3c0-1.7 1.3-3 3-3z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#ADD0E6"
            d="M22.9 11.2H28c2.7 0 3.8 1.4 3.6 3.4-.3 3.3-2.3 5.2-5 5.2h-1.4c-.4 0-.6.2-.7.9l-.6 3.8c0 .2-.2.4-.4.4h-3.2c-.3 0-.4-.2-.3-.7L22 12c.1-.5.4-.8.9-.8z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFF"
            d="M19.7 7h5.1c1.4 0 3.1 0 4.3 1 .8.7 1.2 1.7 1.1 2.9-.3 3.9-2.6 6.1-5.8 6.1h-2.5c-.4 0-.7.3-.8 1l-.7 4.4c0 .3-.2.5-.4.5h-3.1c-.3 0-.5-.3-.4-.8l2.3-14.3c0-.5.3-.8.9-.8z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#E7F0F7"
            d="M21.1 17.6L22 12c.1-.5.3-.7.9-.7H28c.8 0 1.5.1 2.1.4-.5 3.5-2.7 5.4-5.7 5.4h-2.5c-.4-.1-.6.1-.8.5z"
        />
    </svg>
);

PaypalPaymentSvg.displayName = 'PaypalPaymentSvg';
export default PaypalPaymentSvg;
