import get from 'lodash/get';
import { getSquareAnalytics } from '../selectors/squareToken';
import { SQ_ANALYTICS_TOKEN } from '../constants';

export const UPDATE_WAITING_ROOM_TOKEN = 'updateWaitingRoomToken';
export const UPDATE_GUEST_TOKEN = 'updateGuestToken';
export const UPDATE_ORDER_TOKEN = 'updateOrderToken';
export const RESET_GUEST_TOKEN = 'resetGuestToken';
export const RESET_ORDER_TOKEN = 'resetOrderToken';
export const RESET_WAITING_ROOM_TOKEN = 'resetWaitingRoomToken';
export const UPDATE_SQUARE_ANALYTICS_TOKEN = 'updateSquareAnalyticsToken';

const _updateWaitingRoomToken = (data) => ({
    type: UPDATE_WAITING_ROOM_TOKEN,
    payload: data,
});

const _updateGuestToken = (data) => ({
    type: UPDATE_GUEST_TOKEN,
    payload: data,
});

const _updateOrderToken = (data) => ({
    type: UPDATE_ORDER_TOKEN,
    payload: data,
});

const _resetGuestToken = () => ({
    type: RESET_GUEST_TOKEN,
});

const _resetOrderToken = () => ({
    type: RESET_ORDER_TOKEN,
});

const _resetWaitingRoomToken = () => ({
    type: RESET_WAITING_ROOM_TOKEN,
});

const _updateSquareAnalyticsToken = (data) => ({
    type: UPDATE_SQUARE_ANALYTICS_TOKEN,
    payload: data,
});

/**
 * We keep a copy of the tokens portion of the state store here and
 * export the accessor method getTokens so api functions, which don't
 * have access to getState can get at the tokens. Maintaining state
 * outside of the store is an antipattern so try not to use this
 * approach elsewhere.
 * TODO: EB-130741 [antipattern] header tokens in checkoutWidget are saved locally, apart from the state
 */
let tokens = {};

export const getTokens = () => tokens;

export const setTokensFromState = (state) => {
    if (state) {
        tokens = state.tokens;
    }
};

export const updateTokensWithWaitingRoomToken = (waitingRoomToken) => (
    dispatch,
    getState,
) => {
    if (waitingRoomToken) {
        dispatch(
            _updateWaitingRoomToken({
                waitingRoomToken,
            }),
        );
    }
    tokens = getState().tokens;
    return Promise.resolve();
};

export const updateTokensWithGuestToken = (guestToken) => (
    dispatch,
    getState,
) => {
    if (guestToken) {
        dispatch(
            _updateGuestToken({
                guestToken,
            }),
        );
    }
    tokens = getState().tokens;
    return Promise.resolve();
};

export const updateTokensWithOrderToken = (orderToken) => (
    dispatch,
    getState,
) => {
    if (orderToken) {
        dispatch(
            _updateOrderToken({
                orderToken,
            }),
        );
    }
    tokens = getState().tokens;
    return Promise.resolve();
};

export const resetGuestToken = () => (dispatch, getState) => {
    dispatch(_resetGuestToken());
    tokens = getState().tokens;
    return Promise.resolve();
};

export const resetOrderToken = () => (dispatch, getState) => {
    dispatch(_resetOrderToken());
    tokens = getState().tokens;
    return Promise.resolve();
};

export const resetWaitingRoomToken = () => (dispatch, getState) => {
    dispatch(_resetWaitingRoomToken());
    tokens = getState().tokens;
    return Promise.resolve();
};

const isSqAnalyticsTokenGenerated = (state) =>
    Boolean(get(state, ['tokens', SQ_ANALYTICS_TOKEN]));

export const generateSquareToken = () => (dispatch, getState) => {
    if (getState().app.featureFlags.enableSquareCreditCardCheckout) {
        if (!isSqAnalyticsTokenGenerated(getState())) {
            const squareAnalytics = getSquareAnalytics(getState());

            squareAnalytics.getToken((sqAnalyticsToken) =>
                dispatch(_updateSquareAnalyticsToken({ sqAnalyticsToken })),
            );
        }
    }
};

export const handleAnalyticsTokenOnError = (dispatch, getState) => {
    if (getState().app.featureFlags.enableSquareCreditCardCheckout) {
        dispatch(_updateSquareAnalyticsToken({ sqAnalyticsToken: null }));
        dispatch(generateSquareToken());
    }
};
