import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { translationPropType } from '@eb/i18n';
import { getInitialValue } from '@eb/eds-inputs';
import { getAdditionalProps } from '@eb/eds-utils';
import { VALUES_PROP_TYPE, PLACEHOLDER_DUMMY_VALUE } from './constants';

import { Icon } from '@eb/eds-icon';

import './select.scss';

import { ChevronDownChunky } from '@eb/eds-iconography';

/**
 * Helper component for Select options
 * @prop {string} value - the value of the field if this option is selected
 * @prop {string} display - the text of option displayed to user
 * @example
 * // <SelectOption value="option_01" display="Option 01" />
 */
const SelectOption = ({ value, display }) => (
    <option value={value} data-spec="select-option">
        {display}
    </option>
);

/**
 * An initial implementation of a single-select component
 * @extends PureComponent
 * @example
 * // <Select
 * //   id="my-field"
 * //   name="my-field"
 * //   values={[{value: '01', display: 'Choice 01'}, {value: '02', display: 'Choice 02'}]}
 * //   onChange={this.selectChanged.bind(this)}
 * // />
 **/
export default class Select extends PureComponent {
    static propTypes = {
        /**
         * Array of formatted options
         */
        values: VALUES_PROP_TYPE.isRequired,
        /**
         * Form name of select component
         */
        name: PropTypes.string,
        /**
         * ID of select component
         */
        id: PropTypes.string,
        /**
         * Default value selected from the values array
         */
        defaultValue: PropTypes.string,
        value: PropTypes.string,
        /**
         * Text to be displayed when no option is selected
         */
        placeholder: translationPropType,
        disabled: PropTypes.bool,
        required: PropTypes.bool,
        hasError: PropTypes.bool,
        __containerClassName: PropTypes.string,
        /**
         * Function to be invoked when select value changes
         */
        onChange: PropTypes.func,
        /**
         * Function to be invoked when the component is on blur
         */
        onBlur: PropTypes.func,
        /**
         * Function to be invoked when the component is on focus
         */
        onFocus: PropTypes.func,
        /**
         * Identifier for tests
         */
        'data-spec': PropTypes.string,
    };

    static defaultProps = {
        'data-spec': 'select-input',
    };

    constructor(props) {
        super(props);

        const { value, defaultValue, placeholder, values } = props;

        this.state = {
            value: getInitialValue(value || defaultValue, values, placeholder),
            active: false,
        };
    }

    componentWillReceiveProps({ value, values, placeholder }) {
        // allows setting an existing `input` element's value via props from a parent
        if (value !== undefined) {
            this.setState({
                value: getInitialValue(value, values, placeholder),
            });
        }
    }

    _handleChange(e) {
        const { onChange, placeholder } = this.props;
        const value = e.target.value;

        // If there's a placeholder and the user picks that placeholder first value
        // (presumably after picking a normal value), we'll treat it as a no-op.
        // We definitely don't want to fire the onChange event, but also not setting
        // the state so that onBlur & onFocus don't unintentionally pass back the
        // value we don't them to
        if (placeholder && value === PLACEHOLDER_DUMMY_VALUE) {
            return;
        }

        this.setState({ value });

        if (onChange) {
            onChange(value);
        }
    }

    _handleBlur() {
        this.setState({ active: false });

        if (this.props.onBlur) {
            this.props.onBlur(this.state.value);
        }
    }

    _handleFocus() {
        this.setState({ active: true });

        if (this.props.onFocus) {
            this.props.onFocus(this.state.value);
        }
    }

    render() {
        let { values } = this.props;
        const {
            name,
            id,
            placeholder,
            required,
            hasError,
            disabled,
            'data-spec': dataSpec,
            __containerClassName,
        } = this.props;
        const extraAttrs = getAdditionalProps(this);
        const { value: selectedValue, active } = this.state;
        let selectedDisplay;

        if (placeholder) {
            values = [
                { display: placeholder, value: PLACEHOLDER_DUMMY_VALUE },
                ...values,
            ];
        }

        const wrapperClassName = classNames(
            'eds-select',
            {
                'eds-select--disabled': disabled,
                'eds-select--focus': active,
                'eds-select--error': hasError,
            },
            __containerClassName,
        );

        // Build up the list of optionComponents and determine the selected
        // display in one iteration. We're determining this derived value here
        // instead of storing it in state
        const optionComponents = values.map((valueInfo) => {
            // setting this value is a side effect of the single iteration
            if (valueInfo.value === selectedValue) {
                selectedDisplay = valueInfo.display;
            }

            return (
                <SelectOption
                    value={valueInfo.value}
                    display={valueInfo.display}
                    key={valueInfo.value}
                />
            );
        });

        return (
            <div className={wrapperClassName} data-spec="select-wrapper">
                <label
                    htmlFor={id}
                    className="eds-select__value"
                    data-spec="select-display"
                >
                    {selectedDisplay}
                    <span className="eds-select__icon">
                        <Icon type={<ChevronDownChunky />} color="grey-600" />
                    </span>
                </label>
                <select
                    className="eds-select__input"
                    data-spec={dataSpec}
                    {...extraAttrs}
                    id={id}
                    name={name}
                    disabled={disabled}
                    aria-required={required}
                    aria-invalid={hasError}
                    value={selectedValue}
                    onChange={this._handleChange.bind(this)}
                    onBlur={this._handleBlur.bind(this)}
                    onFocus={this._handleFocus.bind(this)}
                >
                    {optionComponents}
                </select>
            </div>
        );
    }
}
