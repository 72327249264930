import { lazyGettext } from '@eb/lazy-gettext';

const COUNTRY_CODE_TO_NAME = {
    AF: lazyGettext('Afghanistan'),
    AX: lazyGettext('Åland Islands'),
    AL: lazyGettext('Albania'),
    DZ: lazyGettext('Algeria'),
    AS: lazyGettext('American Samoa'),
    AD: lazyGettext('Andorra'),
    AO: lazyGettext('Angola'),
    AI: lazyGettext('Anguilla'),
    AQ: lazyGettext('Antarctica'),
    AG: lazyGettext('Antigua and Barbuda'),
    AR: lazyGettext('Argentina'),
    AM: lazyGettext('Armenia'),
    AW: lazyGettext('Aruba'),
    AU: lazyGettext('Australia'),
    AT: lazyGettext('Austria'),
    AZ: lazyGettext('Azerbaijan'),
    BS: lazyGettext('Bahamas'),
    BH: lazyGettext('Bahrain'),
    BD: lazyGettext('Bangladesh'),
    BB: lazyGettext('Barbados'),
    BY: lazyGettext('Belarus'),
    BE: lazyGettext('Belgium'),
    BZ: lazyGettext('Belize'),
    BJ: lazyGettext('Benin'),
    BM: lazyGettext('Bermuda'),
    BT: lazyGettext('Bhutan'),
    BO: lazyGettext('Bolivia'),
    BQ: lazyGettext('Bonaire, Sint Eustatius and Saba'),
    BA: lazyGettext('Bosnia and Herzegovina'),
    BW: lazyGettext('Botswana'),
    BV: lazyGettext('Bouvet Island'),
    BR: lazyGettext('Brazil'),
    IO: lazyGettext('British Indian Ocean Territory'),
    BN: lazyGettext('Brunei Darussalam'),
    BG: lazyGettext('Bulgaria'),
    BF: lazyGettext('Burkina Faso'),
    BI: lazyGettext('Burundi'),
    KH: lazyGettext('Cambodia'),
    CM: lazyGettext('Cameroon'),
    CA: lazyGettext('Canada'),
    CV: lazyGettext('Cape Verde'),
    KY: lazyGettext('Cayman Islands'),
    CF: lazyGettext('Central African Republic'),
    TD: lazyGettext('Chad'),
    CL: lazyGettext('Chile'),
    CN: lazyGettext('China'),
    CX: lazyGettext('Christmas Island'),
    CC: lazyGettext('Cocos  Islands'),
    CO: lazyGettext('Colombia'),
    KM: lazyGettext('Comoros'),
    CG: lazyGettext('Congo'),
    CD: lazyGettext('Congo, The Democratic Republic Of The'),
    CK: lazyGettext('Cook Islands'),
    CR: lazyGettext('Costa Rica'),
    CI: lazyGettext("Côte D'Ivoire"),
    HR: lazyGettext('Croatia'),
    CU: lazyGettext('Cuba'),
    CW: lazyGettext('Curaçao'),
    CY: lazyGettext('Cyprus'),
    CZ: lazyGettext('Czech Republic'),
    DK: lazyGettext('Denmark'),
    DJ: lazyGettext('Djibouti'),
    DM: lazyGettext('Dominica'),
    DO: lazyGettext('Dominican Republic'),
    EC: lazyGettext('Ecuador'),
    EG: lazyGettext('Egypt'),
    SV: lazyGettext('El Salvador'),
    GQ: lazyGettext('Equatorial Guinea'),
    ER: lazyGettext('Eritrea'),
    EE: lazyGettext('Estonia'),
    ET: lazyGettext('Ethiopia'),
    FK: lazyGettext('Falkland Islands'),
    FO: lazyGettext('Faroe Islands'),
    FJ: lazyGettext('Fiji'),
    FI: lazyGettext('Finland'),
    FR: lazyGettext('France'),
    GF: lazyGettext('French Guiana'),
    PF: lazyGettext('French Polynesia'),
    TF: lazyGettext('French Southern Territories'),
    GA: lazyGettext('Gabon'),
    GM: lazyGettext('Gambia'),
    GE: lazyGettext('Georgia'),
    DE: lazyGettext('Germany'),
    GH: lazyGettext('Ghana'),
    GI: lazyGettext('Gibraltar'),
    GR: lazyGettext('Greece'),
    GL: lazyGettext('Greenland'),
    GD: lazyGettext('Grenada'),
    GP: lazyGettext('Guadeloupe'),
    GU: lazyGettext('Guam'),
    GT: lazyGettext('Guatemala'),
    GG: lazyGettext('Guernsey'),
    GW: lazyGettext('Guinea-Bissau'),
    GN: lazyGettext('Guinea'),
    GY: lazyGettext('Guyana'),
    HT: lazyGettext('Haiti'),
    HM: lazyGettext('Heard & McDonald Islands'),
    HN: lazyGettext('Honduras'),
    HK: lazyGettext('Hong Kong'),
    HU: lazyGettext('Hungary'),
    IS: lazyGettext('Iceland'),
    IN: lazyGettext('India'),
    ID: lazyGettext('Indonesia'),
    IR: lazyGettext('Iran'),
    IQ: lazyGettext('Iraq'),
    IE: lazyGettext('Ireland'),
    IM: lazyGettext('Isle of Man'),
    IL: lazyGettext('Israel'),
    IT: lazyGettext('Italy'),
    JM: lazyGettext('Jamaica'),
    JP: lazyGettext('Japan'),
    JE: lazyGettext('Jersey'),
    JO: lazyGettext('Jordan'),
    KZ: lazyGettext('Kazakhstan'),
    KE: lazyGettext('Kenya'),
    KI: lazyGettext('Kiribati'),
    KP: lazyGettext("Korea, Democratic People's Republic Of"),
    KR: lazyGettext('Korea, Republic of'),
    KW: lazyGettext('Kuwait'),
    KG: lazyGettext('Kyrgyzstan'),
    LA: lazyGettext('Laos'),
    LV: lazyGettext('Latvia'),
    LB: lazyGettext('Lebanon'),
    LS: lazyGettext('Lesotho'),
    LR: lazyGettext('Liberia'),
    LY: lazyGettext('Libya'),
    LI: lazyGettext('Liechtenstein'),
    LT: lazyGettext('Lithuania'),
    LU: lazyGettext('Luxembourg'),
    MO: lazyGettext('Macau'),
    MK: lazyGettext('Macedonia, the Former Yugoslav Republic Of'),
    MG: lazyGettext('Madagascar'),
    MW: lazyGettext('Malawi'),
    MY: lazyGettext('Malaysia'),
    MV: lazyGettext('Maldives'),
    ML: lazyGettext('Mali'),
    MT: lazyGettext('Malta'),
    MH: lazyGettext('Marshall Islands'),
    MQ: lazyGettext('Martinique'),
    MR: lazyGettext('Mauritania'),
    MU: lazyGettext('Mauritius'),
    YT: lazyGettext('Mayotte'),
    MX: lazyGettext('Mexico'),
    FM: lazyGettext('Micronesia, Federated States Of'),
    MD: lazyGettext('Moldova'),
    MC: lazyGettext('Monaco'),
    MN: lazyGettext('Mongolia'),
    ME: lazyGettext('Montenegro'),
    MS: lazyGettext('Montserrat'),
    MA: lazyGettext('Morocco'),
    MZ: lazyGettext('Mozambique'),
    MM: lazyGettext('Myanmar'),
    NA: lazyGettext('Namibia'),
    NR: lazyGettext('Nauru'),
    NP: lazyGettext('Nepal'),
    NL: lazyGettext('Netherlands'),
    NC: lazyGettext('New Caledonia'),
    NZ: lazyGettext('New Zealand'),
    NI: lazyGettext('Nicaragua'),
    NG: lazyGettext('Nigeria'),
    NE: lazyGettext('Niger'),
    NU: lazyGettext('Niue'),
    NF: lazyGettext('Norfolk Island'),
    MP: lazyGettext('Northern Mariana Islands'),
    NO: lazyGettext('Norway'),
    OM: lazyGettext('Oman'),
    PK: lazyGettext('Pakistan'),
    PW: lazyGettext('Palau'),
    PS: lazyGettext('Palestine'),
    PA: lazyGettext('Panama'),
    PG: lazyGettext('Papua New Guinea'),
    PY: lazyGettext('Paraguay'),
    PE: lazyGettext('Peru'),
    PH: lazyGettext('Philippines'),
    PN: lazyGettext('Pitcairn'),
    PL: lazyGettext('Poland'),
    PT: lazyGettext('Portugal'),
    PR: lazyGettext('Puerto Rico'),
    QA: lazyGettext('Qatar'),
    RE: lazyGettext('Reunion'),
    RO: lazyGettext('Romania'),
    RU: lazyGettext('Russia'),
    RW: lazyGettext('Rwanda'),
    SH: lazyGettext('Saint Helena, Ascension and Tristan Da Cunha'),
    KN: lazyGettext('Saint Kitts And Nevis'),
    LC: lazyGettext('Saint Lucia'),
    VC: lazyGettext('Saint Vincent And The Grenadines'),
    WS: lazyGettext('Samoa'),
    SM: lazyGettext('San Marino'),
    ST: lazyGettext('Sao Tome and Principe'),
    SA: lazyGettext('Saudi Arabia'),
    SN: lazyGettext('Senegal'),
    RS: lazyGettext('Serbia'),
    SC: lazyGettext('Seychelles'),
    GS: lazyGettext('S.Georgia & S.Sandwich Islands'),
    SL: lazyGettext('Sierra Leone'),
    SG: lazyGettext('Singapore'),
    SK: lazyGettext('Slovakia'),
    SI: lazyGettext('Slovenia'),
    SB: lazyGettext('Solomon Islands'),
    SO: lazyGettext('Somalia'),
    ZA: lazyGettext('South Africa'),
    SS: lazyGettext('South Sudan'),
    ES: lazyGettext('Spain'),
    LK: lazyGettext('Sri Lanka'),
    BL: lazyGettext('St. Barthélemy'),
    SX: lazyGettext('St. Maarten'),
    MF: lazyGettext('St. Martin'),
    PM: lazyGettext('St. Pierre & Miquelon'),
    SD: lazyGettext('Sudan'),
    SR: lazyGettext('Suriname'),
    SJ: lazyGettext('Svalbard & Jan Mayen Islands'),
    SZ: lazyGettext('Swaziland'),
    SE: lazyGettext('Sweden'),
    CH: lazyGettext('Switzerland'),
    SY: lazyGettext('Syria'),
    TW: lazyGettext('Taiwan'),
    TJ: lazyGettext('Tajikistan'),
    TZ: lazyGettext('Tanzania'),
    TH: lazyGettext('Thailand'),
    TL: lazyGettext('East Timor'),
    TG: lazyGettext('Togo'),
    TK: lazyGettext('Tokelau'),
    TO: lazyGettext('Tonga'),
    TT: lazyGettext('Trinidad and Tobago'),
    TN: lazyGettext('Tunisia'),
    TR: lazyGettext('Turkey'),
    TM: lazyGettext('Turkmenistan'),
    TC: lazyGettext('Turks & Caicos Islands'),
    TV: lazyGettext('Tuvalu'),
    UG: lazyGettext('Uganda'),
    UA: lazyGettext('Ukraine'),
    AE: lazyGettext('United Arab Emirates'),
    GB: lazyGettext('United Kingdom'),
    UM: lazyGettext('United States Minor Outlying Islands'),
    US: lazyGettext('United States'),
    UY: lazyGettext('Uruguay'),
    UZ: lazyGettext('Uzbekistan'),
    VU: lazyGettext('Vanuatu'),
    VA: lazyGettext('Vatican City'),
    VE: lazyGettext('Venezuela'),
    VN: lazyGettext('Viet Nam'),
    VG: lazyGettext('Virgin Islands'),
    VI: lazyGettext('Virgin Islands'),
    WF: lazyGettext('Wallis & Futuna Islands'),
    EH: lazyGettext('Western Sahara'),
    YE: lazyGettext('Yemen'),
    ZM: lazyGettext('Zambia'),
    ZW: lazyGettext('Zimbabwe'),
};

const COUNTRIES_REQUIRING_REGION = {
    US: true,
    CA: true,
    IE: true,
    AU: true,
    BR: true,
};

/**
 * @typedef {{code: string, label: string, zip_required: boolean}} CountryInfo
 */

/**
 * Find a country object by country code in the country array in the format
 * returned by `fetchCountries` in ./api. For our purposes, all that matters is
 * that it's an array of objects with a `code` key that specifies the country
 * code.
 *
 * @param {CountryInfo[]} countries
 * @param {string} countryCode
 * @returns {CountryInfo}
 */
const _findCountryInCountries = (countries = [], countryCode) =>
    countries.find(({ code }) => code === countryCode);

/**
 * Returns whether region is a required field for this country.
 * @param {string} countryCode The country code (i.e. 'US', 'CA', etc)
 * @returns {boolean} Whether or not a region is required for the country
 */
export const isRegionRequired = (countryCode) =>
    !!COUNTRIES_REQUIRING_REGION[countryCode];

/**
 * Returns whether postal code is required for this country. Takes an array of
 * country objects as returned by the API like so:
 *
 * [{code: 'US', zip_required: true}, ...]
 * @param {CountryInfo[]} countries List of countries
 * @param {string} countryCode Country code to test
 * @returns {boolean} Whether or not a postal code is required for the country
 */
export const isPostalRequired = (countries, countryCode) => {
    const countryObj = _findCountryInCountries(countries, countryCode);

    return !!(countryObj && countryObj['zip_required']);
};

/**
 * Retrieves the country name for a given country code. If a match cannot
 * be found, just return the country code.
 *
 * @param  {string} [twoLetterCode='US'] Country code ("US", "CA")
 * @return {string} Localized Country Name
 */
export const getCountryNameFromTwoLetterCode = (twoLetterCode = 'US') => {
    // Fallback to country code.
    let countryName = twoLetterCode;
    const uppercaseCode = twoLetterCode.toUpperCase();

    if (COUNTRY_CODE_TO_NAME[uppercaseCode]) {
        countryName = COUNTRY_CODE_TO_NAME[uppercaseCode];
    }

    return countryName;
};
