import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { STYLE_NONE } from '@eb/eds-button';
import { ToggleButton } from '@eb/eds-button';
import { Icon } from '@eb/eds-icon';
import { ChevronUpChunky } from '@eb/eds-iconography';
import { ChevronDownChunky } from '@eb/eds-iconography';
import { translationPropType } from '@eb/i18n';

const LinkText = ({ text, isExpanded }) => {
    const iconType = isExpanded ? <ChevronUpChunky /> : <ChevronDownChunky />;
    const className = classNames('eds-expansion-panel__link');

    return (
        <div className={className}>
            <p>{text}</p>
            <Icon type={iconType} size="small" />
        </div>
    );
};

export default class ToggleExpansionLink extends React.PureComponent {
    static propTypes = {
        /**
         * Content to be displayed on the link
         */
        text: translationPropType,
        /**
         * Content to be used as the link's alt text
         */
        altText: translationPropType,
        /**
         * Trailing text added to the toggle as info message
         */
        trailingText: translationPropType,
        /**
         * If expansion is expanded
         */
        isExpanded: PropTypes.bool,
        /**
         * Callback when the link gets clicked
         */
        onToggle: PropTypes.func,
    };

    _handleClick = () => {
        const { onToggle } = this.props;

        if (onToggle) {
            onToggle();
        }
    };

    render() {
        const { text, trailingText, isExpanded, altText } = this.props;
        const toggleChildren = <LinkText text={text} isExpanded={isExpanded} />;
        const trailingTextNode = trailingText ? (
            <p className="eds-align--right eds-text-color--grey-600">
                {trailingText}
            </p>
        ) : null;

        const toggleChildrenAlt = (
            <LinkText text={altText || text} isExpanded={isExpanded} />
        );

        return (
            <div className="toggle-expansion-link eds-align--space-between">
                <ToggleButton
                    childrenAlt={toggleChildrenAlt}
                    onPress={this._handleClick}
                    style={STYLE_NONE}
                    isPressed={isExpanded}
                >
                    {toggleChildren}
                </ToggleButton>
                {trailingTextNode}
            </div>
        );
    }
}
