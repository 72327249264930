import {
    AR_LOCALE,
    AT_LOCALE,
    AU_LOCALE,
    BE_LOCALE,
    BR_LOCALE,
    CA_LOCALE,
    DE_CH_LOCALE,
    FR_CH_LOCALE,
    CL_LOCALE,
    CO_LOCALE,
    DE_LOCALE,
    DK_LOCALE,
    ES_LOCALE,
    FI_LOCALE,
    FR_BE_LOCALE,
    FR_CA_LOCALE,
    FR_LOCALE,
    GB_LOCALE,
    HK_LOCALE,
    IE_LOCALE,
    IN_LOCALE,
    IT_LOCALE,
    MX_LOCALE,
    NO_LOCALE,
    PE_LOCALE,
    NL_LOCALE,
    NZ_LOCALE,
    PT_LOCALE,
    SE_LOCALE,
    SG_LOCALE,
    US_LOCALE,
} from '@eb/intl';

// Built this mapping using i18n/locale_defaults.py
export const LOCALE_TO_TLD = {
    [AR_LOCALE]: '.com.ar',
    [AT_LOCALE]: '.at',
    [AU_LOCALE]: '.com.au',
    [BE_LOCALE]: '.be',
    [BR_LOCALE]: '.com.br',
    [CA_LOCALE]: '.ca',
    [DE_CH_LOCALE]: '.ch',
    [FR_CH_LOCALE]: '.ch',
    [CL_LOCALE]: '.cl',
    [CO_LOCALE]: '.co',
    [DE_LOCALE]: '.de',
    [ES_LOCALE]: '.es',
    [DK_LOCALE]: '.dk',
    [FR_BE_LOCALE]: '.be',
    [FR_CA_LOCALE]: '.ca',
    [FI_LOCALE]: '.fi',
    [FR_LOCALE]: '.fr',
    [GB_LOCALE]: '.co.uk',
    [HK_LOCALE]: '.hk',
    [IE_LOCALE]: '.ie',
    [IN_LOCALE]: '.in',
    [IT_LOCALE]: '.it',
    [MX_LOCALE]: '.com.mx',
    [PE_LOCALE]: '.com.pe',
    [NL_LOCALE]: '.nl',
    [NZ_LOCALE]: '.co.nz',
    [NO_LOCALE]: '.no',
    [PT_LOCALE]: '.pt',
    [SE_LOCALE]: '.se',
    [SG_LOCALE]: '.sg',
    [US_LOCALE]: '.com',
};

// It is ok to just use prod here because these redirects always redirect to prod anyway.
export const getEventbriteUrl = (locale) => {
    const tld = LOCALE_TO_TLD[locale];

    return tld ? `https://eventbrite${tld}` : 'https://eventbrite.com';
};
