import React, { PureComponent } from 'react';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Cart } from '@eb/eds-iconography';
import { Icon } from '@eb/eds-icon';
import { NotificationBar } from '@eb/eds-notification';
import { OrderSummary } from '@eb/eds-order-summary';

import {
    BASE_ORDER_ATTENDEE_SHAPE,
    REFUND_POLICY_PROP_SHAPE,
    RESERVED_ORDER_COSTS_SHAPE,
    VIRTUAL_INCENTIVE_SHAPE,
} from '../../constants';
import {
    getAssignedUnitsWithTicketId,
    getFeeComponents,
    getReservedSeatingOrderSummary,
    getShippingFees,
} from '../../components/reserved/utils';
import { UnitInfoTable } from '../../components/unitInfoTable/UnitInfoTable';

import { getRefundPolicyContent } from './utils';

import './pane.scss';

export default class ReservedPane extends PureComponent {
    static propTypes = {
        attendees: PropTypes.arrayOf(BASE_ORDER_ATTENDEE_SHAPE),
        buyOnMapPaneContent: PropTypes.element,
        currency: PropTypes.string,
        currencyFormat: PropTypes.string,
        eventImageSrc: PropTypes.string,
        eventTitle: PropTypes.string,
        hasDeleteColumn: PropTypes.bool,
        /** Flagging an existing order ID -> take into account free tickets can have an amount, and include the fees and taxes in the ticket price */
        isAddAttendeesOrder: PropTypes.bool,
        /** A function to be called when the trash icon next to a particular unit is clicked. Passes the unit id and its ticket class id as parameters */
        onClickRemoveUnit: PropTypes.func,
        /** The cost object from order v3 api */
        orderCosts: RESERVED_ORDER_COSTS_SHAPE,
        refundPolicyProps: REFUND_POLICY_PROP_SHAPE,
        /**
         * Array of selected delivery methods to show in order summary
         */
        selectedDeliveryMethods: PropTypes.arrayOf(PropTypes.string),
        /** Attendees sorted by ticket class category: Admission, Donation, Add-on */
        sortedAttendeesByTicketCategory: PropTypes.arrayOf(
            BASE_ORDER_ATTENDEE_SHAPE,
        ),
        /**
         * Array of tickets grouped by shared delivery method options
         */
        ticketGroups: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)),
        totalCost: PropTypes.string,
        selectedVirtualIncentives: PropTypes.arrayOf(VIRTUAL_INCENTIVE_SHAPE),
    };

    // TODO: Add isRequired or defaultProps for all props
    // static defaultProps = {};

    render() {
        const {
            attendees,
            buyOnMapPaneContent,
            currencyFormat,
            eventImageSrc,
            eventTitle,
            hasDeleteColumn = false,
            isAddAttendeesOrder,
            isRegEvent,
            onClickRemoveUnit = () => undefined,
            orderCosts,
            quantityErrorMessage,
            refundPolicyProps,
            selectedDeliveryMethods,
            shippingRates,
            showCartIconInPane,
            sortedAttendeesByTicketCategory,
            ticketGroups,
            selectedTickets,
            selectedVirtualIncentives,
            unformattedOrderCost,
            hasFeeBreakdown,
            isTransferredOrder,
        } = this.props;
        let assignedUnitsWithTicketId;
        const attendeesWithAssignedUnits = filter(attendees, 'assignedUnit');

        if (!isEmpty(attendeesWithAssignedUnits)) {
            assignedUnitsWithTicketId = getAssignedUnitsWithTicketId(
                attendeesWithAssignedUnits,
            );
        }

        let eventImage = null;
        let paneContent;

        if (showCartIconInPane) {
            paneContent = (
                <div
                    className="eds-align--center ticket-page--empty-cart-container"
                    data-spec="pane-empty-icon"
                >
                    <Icon
                        __containerClassName="ticket-page--empty-cart-image"
                        type={<Cart />}
                        size="medium"
                        color="grey-300"
                    />
                </div>
            );
        }

        if (eventImageSrc) {
            eventImage = (
                <div className="ticket-page--event-image-container eds-show-up-mn">
                    <img
                        className="ticket-page--event-image eds-max-img"
                        alt={eventTitle}
                        src={eventImageSrc}
                        data-spec="ticket-page-image"
                    />
                    <div className="ticket-page--event-image-padding-fix" />
                </div>
            );
        }

        if (!isEmpty(attendees)) {
            const paneContentClassName = classNames(
                'eds-g-cell',
                'eds-g-cell-sw-10-12 eds-g-offset-sw-1-12',
                'eds-g-cell-sn-12-12',
                'eds-g-cell-12-12',
                'eds-l-sn-pad-hor-4',
                'eds-l-sm-pad-hor-6',
                'eds-l-mar-bot-10',
                'eds-l-pad-bot-10',
                'eds-l-sn-mar-top-12',
                'eds-l-sm-mar-top-12',
                'eds-l-sw-mar-top-12',
                {
                    'eds-l-mar-top-6': eventImageSrc,
                    'eds-l-mar-top-20': !eventImageSrc,
                },
            );

            const shippingFees = !isTransferredOrder
                ? getShippingFees(selectedTickets)
                : [];

            const transferFeeComponents = [
                ...(orderCosts.feeComponents || []),
                ...(orderCosts.shippingComponents || []),
            ];
            const checkoutFeeComponents =
                hasFeeBreakdown || !isEmpty(shippingFees)
                    ? getFeeComponents(selectedTickets)
                    : [];

            const costs = {
                ...orderCosts,
                feeComponents: isTransferredOrder
                    ? transferFeeComponents
                    : checkoutFeeComponents,
            };

            const orderSummaryProps = getReservedSeatingOrderSummary({
                attendees: sortedAttendeesByTicketCategory,
                currencyFormat,
                orderCosts: costs,
                priceWithTaxesAndFees: isAddAttendeesOrder,
                selectedDeliveryMethods,
                shippingRates,
                shippingFees,
                shouldHandleFreeTickets: isAddAttendeesOrder,
                ticketGroups,
                selectedVirtualIncentives,
                unformattedOrderCost,
            });

            paneContent = (
                <div className="ticket-page--order-summary-container">
                    <div className="eds-show-up-mn">
                        {quantityErrorMessage && (
                            <NotificationBar
                                __containerClassName="quantity-error--bar"
                                type="error"
                            >
                                <p
                                    // eslint-disable-next-line react/no-danger
                                    dangerouslySetInnerHTML={{
                                        __html: quantityErrorMessage,
                                    }}
                                />
                            </NotificationBar>
                        )}
                    </div>
                    <div
                        className={paneContentClassName}
                        data-spec="reserved-pane-order-summary"
                    >
                        {assignedUnitsWithTicketId && (
                            <UnitInfoTable
                                assignedUnitsWithTicketId={
                                    assignedUnitsWithTicketId
                                }
                                onClickRemoveUnit={onClickRemoveUnit}
                                collateRows={false}
                                hasDeleteColumn={hasDeleteColumn}
                                isRegEvent={isRegEvent}
                            />
                        )}
                        <OrderSummary {...orderSummaryProps} />
                        {getRefundPolicyContent(refundPolicyProps)}
                    </div>
                </div>
            );
        }

        return (
            <aside
                className="eds-collapsible-pane-layout__content checkout-widget__ticket-page--pane-content"
                data-spec="reserved-pane"
            >
                {eventImage}
                {buyOnMapPaneContent}
                {paneContent}
            </aside>
        );
    }
}
