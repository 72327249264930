import { DeliveryMethod } from '../models/deliveryMethod';

/**
 * Return an object with the shipping settings, including backupDeliveryMethod and
 * cutoffDate for the shipping delivery method on an event.
 * @param  {array} data     the data response from the delivery_method events call
 */
export const transformShippingSettings = (data) => {
    const deliveryMethods = data['delivery_methods'];

    const shippingDeliveryMethod = deliveryMethods.find(
        (item) => item.id === DeliveryMethod.standardShipping,
    );

    if (shippingDeliveryMethod) {
        return {
            shippingSettings: {
                backupDeliveryMethod:
                    shippingDeliveryMethod.backup_delivery_method,
                cutoffDate: shippingDeliveryMethod.cutoff_date,
            },
        };
    }
    return {};
};
