import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import gettext from '@eb/gettext';

import { ValidationFormField } from '@eb/eds-validation-form-field';
import { InputField } from '@eb/eds-input-field';

import * as constants from './constants';
import { normalizeExpirationDate } from './utils';
import { getAdditionalProps } from '@eb/eds-utils';

export default class CardExpirationDateField extends PureComponent {
    static propTypes = {
        /**
         * A name for the ValidationFormField
         */
        name: PropTypes.string,
        /**
         * An id for the InputField
         */
        id: PropTypes.string,
        /**
         * A label for the InputField
         */
        label: PropTypes.string,
        /**
         * A placeholder for the InputField
         */
        placeholder: PropTypes.string,
        /**
         * A callback triggered when the input is blurred
         */
        onBlur: PropTypes.func,
        /**
         * A function passed to ValidationFormField to determine if errors should be shown
         */
        shouldDisplayError: PropTypes.func,
        /**
         * Whether to show asterisk to indicate field is required
         */
        shouldDisplayRequired: PropTypes.bool,
    };

    static defaultProps = {
        id: 'expiration-date',
        name: constants.CARD_EXPIRATION_DATE_FIELD_NAME,
        label: gettext('Expiration Date'),
        placeholder: gettext('MM/YY'),
    };

    render() {
        const {
            id,
            name,
            label,
            placeholder,
            onBlur,
            shouldDisplayError,
            shouldDisplayRequired,
        } = this.props;

        const extraProps = getAdditionalProps(this);

        return (
            <ValidationFormField
                shouldDisplayError={shouldDisplayError}
                required={shouldDisplayRequired}
                name={name}
                normalize={normalizeExpirationDate}
                v2={true}
            >
                <InputField
                    id={id}
                    label={label}
                    placeholder={placeholder}
                    type="number"
                    autoComplete="cc-exp"
                    required={shouldDisplayRequired}
                    maxLength={constants.MAX_EXPIRATION_DATE_LENGTH}
                    onBlur={onBlur}
                    data-automation="card-expiration-date-field"
                    {...extraProps}
                />
            </ValidationFormField>
        );
    }
}
