import { translationPropType } from '@eb/i18n';
import classNames from 'classnames';
import { Button } from '@eb/eds-button';
import { CardList } from '@eb/eds-card-list';
import interpolate from '@eb/interpolate-lazy-string';
import partial from 'lodash/partial';
import PropTypes from 'prop-types';
import React from 'react';

import {
    CTA_JOIN_WAITLIST,
    PROMO_CODE_TYPES,
    TICKET_CLASS_CATEGORIES,
} from '../../constants';
import {
    MAX_TICKET_PER_ORDER,
    MAX_TICKET_PER_ORDER_ZERO,
    SOLD_OUT_TEXT,
    SPEC_TICKET_SOLDOUT,
} from '../../containers/ticketSelection/constants';
import { shouldShowSoldOut } from '../../containers/ticketSelection/utils/ticketUtils';
import gettext from '@eb/gettext';
import { getTicketForImage, getTicketImageUrls } from '../../utils/tickets';
import TicketImageThumbnail from '../ticketImageThumbnail/TicketImageThumbnail';
import { STATUSKEY_PROP_TYPE } from './constants';
import RemainingTickets from './RemainingTickets';
import './ticketCardContent.scss';
import TicketCardGraphicWrapper from './TicketCardGraphicWrapper';
import TicketCardStatus from './TicketCardStatus';
import TicketContent from './TicketContent';
import ToggleExpansionLink from './ToggleExpansionLink';
import { getDonationChangeActionPayload } from './utils';
import { VARIANTS_PROP_TYPE } from './variantConstants';

const VariantList = ({ items }) => (
    <div
        className="ticket-variants-list"
        data-spec="ticket-variants-list-wrapper"
        data-automation="ticket-variants-list-wrapper"
    >
        <div data-automation="ticket-variant-list-items">
            <CardList items={items} />
        </div>
    </div>
);

export default class DisplayContentWithMultipleInputVariants extends React.PureComponent {
    static propTypes = {
        /**
         * ID of the ticket to be passed up along with the selected value in the callback
         **/
        id: PropTypes.string.isRequired,
        /**
         * Represents the original cost of the ticket prior to discounts applied
         **/
        displayPrice: translationPropType,
        /**
         * The array of string translations representing the associated fees and taxes. Should include the appropriate
         * copy. If not included, the section will not be rendered.
         **/
        feeAndTax: PropTypes.arrayOf(translationPropType),
        /**
         * The currently selected quantity of the ticket. Should represent current state of the
         * select box.
         **/
        selectedQuantity: PropTypes.number,
        /**
         * Whether ticket select dropdown should be disabled or not. Dropdown is enabled by default
         **/
        isDisabled: PropTypes.bool,
        /**
         * The name of the ticket to be displayed
         **/
        name: PropTypes.string.isRequired,
        /**
         * Description for a the given ticket. If not included, no description will be rendered.
         **/
        description: PropTypes.node,
        /**
         * List of variants that will be rendered in the dropdown
         **/
        variants: VARIANTS_PROP_TYPE,
        /**
         * A key tied to the ticketStatus map located in ticketStatusConstants.js. If nothing is passed in, then
         * no status will be displayed. Otherwise will render the associated status and also hide the
         * appropriate components as well.
         **/
        statusKey: STATUSKEY_PROP_TYPE,
        /**
         * The end datetime for ticket sales to be shown. Only required when certain statuses need
         * to be rendered.
         **/
        salesEnd: translationPropType,
        /**
         * The start datetime for ticket sales. Only required when rendering a status that needs the
         * date.
         **/
        salesStart: translationPropType,
        /**
         * The number of tickets remaining for this specific ticket type. If passed in, it will be displayed.
         **/
        quantityRemaining: PropTypes.number,
        /**
         * Callback for when the selected value changes
         **/
        onQuantityChange: PropTypes.func.isRequired,
        /**
         * Callback when join waitlist button clicked
         */
        onJoinWaitlist: PropTypes.func.isRequired,
        /**
         * This is a necessary flag for cases where the tickets may have a status causing elements to appear
         * or disappear but the status itself should not be displayed. An example of this is event-wide
         * sales not started or sales ended. Each ticket should not display it individually, but no select
         * should appear.
         **/
        shouldHideStatus: PropTypes.bool,
        onToggle: PropTypes.func,
        initiallyExpanded: PropTypes.bool,
        isFormSubmitting: PropTypes.bool,
        maximumQuantityPerOrder: PropTypes.number,
    };

    constructor(props) {
        super(props);

        this.state = { isExpanded: props.initiallyExpanded };
    }

    _handleQuantityChange = (variantId, quantity) => {
        const { id, onQuantityChange } = this.props;

        let payload = {
            id,
            quantity: parseInt(quantity, 10),
        };

        if (variantId) {
            payload = {
                ...payload,
                variantId,
            };
        }

        onQuantityChange(payload);
    };

    _handleDonationChange = (variantId, amount) => {
        const { id, currencyFormat, onDonationChange } = this.props;

        if (onDonationChange) {
            onDonationChange(
                getDonationChangeActionPayload(
                    id,
                    amount,
                    currencyFormat,
                    variantId,
                ),
            );
        }
    };

    _handleToggleExpansion = () => {
        const { onToggle } = this.props;

        this.setState(
            ({ isExpanded: prevIsExpanded }) => ({
                isExpanded: !prevIsExpanded,
            }),
            () => {
                if (onToggle) {
                    onToggle(this.state.isExpanded);
                }
            },
        );
    };

    _calculateMaxQuantityAvailable = (
        maximumQuantityPerOrder,
        totalTicketsSelected,
        selectedQuantity,
    ) => maximumQuantityPerOrder - totalTicketsSelected + selectedQuantity;

    _getVariantListItems = () => {
        const {
            isDisabled: primaryTicketDisabled,
            selectedQuantity: primarySelectedQuantity,
            hasPrimaryVariant,
            variants,
            featureFlags,
            isFormSubmitting,
            maximumQuantityPerOrder: maximumQuantityPerTicketGroup,
        } = this.props;

        // All admission variants share maximum quantity per order - this should be edited
        const maximumQuantityPerOrder = this._getMaximumQuantityPerOrder();
        const totalTicketsSelected = variants.reduce(
            (acc, singleVariant) => acc + singleVariant.selectedQuantity,
            0,
        );

        return variants.map((variant) => {
            const {
                primary: isPrimaryVariant,
                discountType,
                ...otherProps
            } = variant;
            const ticketId = isPrimaryVariant ? this.props.id : variant.id;
            // We should get the largest number from the ticket options range in order to get the max quantity
            const variantMaxQuantity = Math.max(...variant.ticketOptionsRange);
            let primaryPrice;
            let primaryTicketOverrideProps = {};
            let ticketOptionsRange = variant.ticketOptionsRange;
            // Admission variants range need to be computed. Addon variants do not.
            if (variant.category === TICKET_CLASS_CATEGORIES.ADMISSION) {
                /**
                 *  Rule 1
                 *     If the variant level's variantMaxQuantity is not null
                 *     then variants must respect that
                 *  Rule 2
                 *    If the ticket level's maximumQuantityPerTicketGroup is not null,
                 *    then the sum of quantity for the variants for the ticket must
                 *    also respect that in addition to variant level's variantMaxQuantity
                 *  Rule 3
                 *    If the ticket level's maximumQuantityPerTicketGroup is null, then we only care
                 *    about the variants variantMaxQuantity
                 */
                const validRangeOptionMax = maximumQuantityPerTicketGroup
                    ? this._calculateMaxQuantityAvailable(
                          maximumQuantityPerTicketGroup,
                          totalTicketsSelected,
                          variant.selectedQuantity,
                      )
                    : variantMaxQuantity;

                ticketOptionsRange = variant.ticketOptionsRange.filter(
                    (ticketOption) => ticketOption <= validRangeOptionMax,
                );
            }

            /**
             *   Specific to public discounted items.
             *   For public discounted items, we want to:
             *    - show the display price (public discounted price) as well as the full price.
             **/
            if (discountType === PROMO_CODE_TYPES.PUBLIC) {
                primaryPrice = this.props.displayPrice;
            }

            if (isPrimaryVariant) {
                primaryTicketOverrideProps = {
                    isDisabled: primaryTicketDisabled,
                };
            }

            const ticketForImage = getTicketForImage(this.props, variant);
            const ticketGraphic = ticketForImage ? (
                <TicketImageThumbnail
                    title={ticketForImage.name}
                    {...getTicketImageUrls(ticketForImage)}
                />
            ) : null;

            return {
                id: ticketId,
                content: (
                    <TicketContent
                        id={ticketId}
                        variantId={variant.id}
                        primaryPrice={primaryPrice}
                        onQuantityChange={this._handleQuantityChange}
                        onDonationChange={partial(
                            this._handleDonationChange,
                            ticketId,
                        )}
                        shouldHideStatus={hasPrimaryVariant}
                        graphic={ticketGraphic}
                        paymentConstraints={variant.paymentConstraints}
                        featureFlags={featureFlags}
                        {...otherProps}
                        {...primaryTicketOverrideProps}
                        isFormSubmitting={isFormSubmitting}
                        ticketOptionsRange={ticketOptionsRange}
                    />
                ),
            };
        });
    };

    _getMaximumQuantityPerOrder() {
        const { variants } = this.props;
        const { maximumQuantityPerOrder, maxQuantity } = variants.find(
            (e) => !!e,
        );

        return maximumQuantityPerOrder || maxQuantity || 0;
    }

    render() {
        const {
            name,
            description,
            statusKey,
            salesStart,
            salesEnd,
            priceRange,
            quantityRemaining,
            shouldHideStatus,
            hideSaleDates,
            onJoinWaitlist,
            showJoinWaitlist,
        } = this.props;
        const items = this._getVariantListItems();
        let hideStatus = false;

        if (description) {
            items.push({
                id: 'ticketDescription',
                content: <div className="eds-l-mar-all-4">{description}</div>,
            });
        }

        if (shouldHideStatus || hideSaleDates) {
            hideStatus = true;
        }

        const containerClassNames = classNames(
            'eds-g-cell eds-g-cell-12-12 eds-l-pad-vert-4',
            'ticket-border',
        );

        const primaryDisplayClassNames = classNames(
            'eds-g-cell eds-g-cell-12-12 eds-ticket-card-content eds-ticket-display-card-content eds-l-pad-all-0',
            {
                'eds-ticket-display-card-content--waitlist': showJoinWaitlist,
                'eds-ticket-card-content--is-disabled': shouldShowSoldOut(
                    this.props,
                ),
                'eds-ticket-display-card-content--multiple-variants': !shouldShowSoldOut(
                    this.props,
                ),
            },
        );

        const expandedContent = this.state.isExpanded ? (
            <VariantList items={items} />
        ) : null;
        const ticketForImage = getTicketForImage(this.props);
        const ticketGrahicWrapper = ticketForImage ? (
            <TicketCardGraphicWrapper
                graphic={
                    <TicketImageThumbnail
                        title={ticketForImage.name}
                        {...getTicketImageUrls(ticketForImage)}
                    />
                }
            />
        ) : null;

        const maximumQuantityPerOrder = this._getMaximumQuantityPerOrder();
        const extraTextTicketPerOrder =
            maximumQuantityPerOrder == 0
                ? MAX_TICKET_PER_ORDER_ZERO
                : MAX_TICKET_PER_ORDER(maximumQuantityPerOrder);
        const extraTextTicketPerOrderInterpolated = interpolate(
            extraTextTicketPerOrder,
            { maximumQuantityPerOrder },
            true,
        );

        return (
            <div
                className={containerClassNames}
                data-spec="content-with-multiple-variants-container"
            >
                <div
                    className={primaryDisplayClassNames}
                    data-automation="ticket-card-primary-content-wrapper"
                >
                    {ticketGrahicWrapper}
                    <div className="eds-ticket-card-content__primary-content eds-g-cell eds-g-cell-12-12">
                        <h3 className="ticket-title">{name}</h3>
                        <ToggleExpansionLink
                            text={priceRange}
                            altText={priceRange}
                            extraText={extraTextTicketPerOrderInterpolated}
                            isExpanded={this.state.isExpanded}
                            onToggle={this._handleToggleExpansion}
                        />
                    </div>

                    {shouldShowSoldOut(this.props) && (
                        <div
                            className="ticket-sold-out eds-text-bm eds-text-height--open eds-text-color--ui-500 eds-l-pad-left-2"
                            data-spec={SPEC_TICKET_SOLDOUT}
                        >
                            {' '}
                            {gettext(SOLD_OUT_TEXT)}
                        </div>
                    )}

                    {showJoinWaitlist && (
                        <div
                            className="eds-ticket-card-content__join-waitlist-button"
                            data-spec="join-waitlist-button"
                            data-automation={`join-waitlist-ticket-${this.props.id}-btn`}
                        >
                            <Button onClick={onJoinWaitlist}>
                                {CTA_JOIN_WAITLIST}
                            </Button>
                        </div>
                    )}
                </div>
                {expandedContent}
                {quantityRemaining && (
                    <RemainingTickets quantityRemaining={quantityRemaining} />
                )}
                <TicketCardStatus
                    shouldHideStatus={hideStatus}
                    statusKey={statusKey}
                    salesStart={salesStart}
                    salesEnd={salesEnd}
                />
            </div>
        );
    }
}
