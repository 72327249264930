import {
    trackTaxInvoiceInputFieldEnter,
    trackTaxInvoiceAddressDropdownChange,
} from '../actions/eventAnalytics';

/* Redux-Form tracked fields */
export const BUSINESS_TAX_ADDRESS_COUNTRY_FIELD =
    'buyer.N-business_tax_address.country';
export const IS_BUSINESS_FIELD = 'buyer.N-is_business';

export const trackedTaxInputFields = [
    'buyer.N-name_tax_receipt',
    'buyer.N-company_tax',
    'buyer.N-tax_registration_id',
    'buyer.N-business_tax_address.address1',
    'buyer.N-business_tax_address.address2',
    'buyer.N-business_tax_address.city',
    'buyer.N-business_tax_address.postal',
    'buyer.N-individual_tax_address.address1',
    'buyer.N-individual_tax_address.address2',
    'buyer.N-individual_tax_address.city',
    'buyer.N-individual_tax_address.postal',
];

export const trackedTaxSelectFields = [
    'buyer.N-individual_tax_address.region',
    'buyer.N-individual_tax_address.country',
    'buyer.N-business_tax_address.region',
    BUSINESS_TAX_ADDRESS_COUNTRY_FIELD,
];

export const trackedTaxRadioFields = [IS_BUSINESS_FIELD];

export const trackedTaxFields = [
    {
        fieldList: trackedTaxInputFields,
        action: trackTaxInvoiceInputFieldEnter,
    },
    {
        fieldList: trackedTaxSelectFields,
        action: trackTaxInvoiceAddressDropdownChange,
    },
];

export const TAX_INVOICE_REGISTRATION_TYPES = ['INDIVIDUAL', 'BUSINESS'];

export const TAX_INVOICE_FORM_FIELDS = trackedTaxInputFields.concat(
    trackedTaxSelectFields,
    trackedTaxRadioFields,
);
