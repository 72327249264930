//XXX: helper note from Shigeki on https://eventbrite.slack.com/archives/waiting_room/p1485977533001474
// waiting_room_orders_started:
// - get_allocation (queue_rpc/get) was called
//
// waiting_room_orders_completed:
// - free_allocation (queue_rpc/free) was called and
// a client was successfully marked as removed.
// - this usually happens at order completion.
//
// waiting_room_orders_renewed:
// - renew_allocation (queue_rpc/renew) was called with client details.
// - this happens usually while in waiting room.
//
// waiting_room_orders_refreshed:
// - renew_allocation (queue_rpc/renew) was called without client details.
// - this usually happens on register page.
//
// waiting_room_orders_expired:
// - a client was expired due to inactivity.
// - this includes expiration from either waiting room or active list.
//
// waiting_room_orders_revived:
// - renew_allocation (queue_rpc/renew) was called on expired or
// removed client id, causing the client to get revived.

import { fetchEB, fetchJSON } from '@eb/http';
import {
    _getQueueAcquireUrl,
    _getQueueRenewUrl,
    _getQueueRefreshUrl,
    _getQueueFreeUrl,
} from './constants';

const noop = () => undefined;

const _fetchWithErrorHandler = (fetchFunc, url) =>
    // we don't really care about errors on this calls, the show must go on and
    // we definitely don't have to block the checkout flow
    fetchFunc(url, {
        // We ping inline.ebdomain.com instead of ebdomain.com
        useCORS: true,
    }).catch(noop);

export const queueAcquire = ({ queueServer, queueId, step }) =>
    _fetchWithErrorHandler(
        fetchJSON,
        _getQueueAcquireUrl(queueServer, queueId, step),
    );

export const queueRenew = ({
    queueServer,
    clientId,
    queueId,
    isLive,
    allocationTime,
    hashCode,
    step,
}) =>
    _fetchWithErrorHandler(
        fetchJSON,
        _getQueueRenewUrl(
            queueServer,
            clientId,
            queueId,
            isLive,
            allocationTime,
            hashCode,
            step,
        ),
    );

export const queueRefresh = ({ queueServer, clientId, step }) =>
    _fetchWithErrorHandler(
        fetchJSON,
        _getQueueRefreshUrl(queueServer, clientId, step),
    );

export const queueFree = ({ clientId, allocationTime, hashCode }) =>
    _fetchWithErrorHandler(
        fetchEB,
        _getQueueFreeUrl(clientId, allocationTime, hashCode),
    );
