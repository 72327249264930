import { navigateToTicketSelection as navigateToTicketSelectionAction } from './initialize';
import { updateTokensWithWaitingRoomToken as updateTokensWithWaitingRoomTokenAction } from './tokens';
import { WAITING_ROOM_TOKEN_DELIMITER } from '../api/base';
import {
    transformWaitingRoomData,
    transformWaitingRoomDataToToken,
} from '../api/transformations/waitingRoom';
import {
    queueAcquire as queueAcquireApi,
    queueFree as queueFreeApi,
    queueRefresh as queueRefreshApi,
    queueRenew as queueRenewApi,
} from '../api/waitingRoom';
import { CHECKOUT_STEP_CHECKOUT, INVALID_CLIENT_ID } from '../constants';
import { hasExistingOrder } from '../selectors/order';

export const INITIALIZE_WAITING_ROOM = 'initializeWaitingRoom';
export const RESET_WAITING_ROOM = 'resetWaitingRoom';
export const UPDATE_WAITING_ROOM = 'updateWaitingRoom';

const noop = () => undefined;

export const resetWaitingRoom = () => ({
    type: RESET_WAITING_ROOM,
});

export const initializeWaitingRoom = (data) => ({
    type: INITIALIZE_WAITING_ROOM,
    payload: transformWaitingRoomData(data),
});

export const updateWaitingRoom = (data) => ({
    type: UPDATE_WAITING_ROOM,

    payload: {
        waitingRoom: data,
    },
});

const _updateWaitingRoomWithTransformation = (data) => ({
    type: UPDATE_WAITING_ROOM,
    payload: transformWaitingRoomData(data),
});

export const setWaitingRoomClientIdAsInvalid = () => (dispatch, getState) => {
    const { waitingRoom } = getState();

    dispatch(
        updateWaitingRoom({
            ...waitingRoom,
            clientId: INVALID_CLIENT_ID,
        }),
    );
};

export const _updateWaitingRoomAndNavigateToTicketSelection = (
    response = {},
) => (dispatch, getState) => {
    // If queue renew api returns false instead of a dict of waiting room queue status.
    // which means the client id validation failed.
    // https://github.com/eventbrite/core/blob/6da46fab3e48941e28b3ff333c6cc25010a74d71/django/src/services/waiting_room/lib/client_pool.py#L478
    // This can happen after a queue token is freed, ex: if user let the cart time out (EB-84235).
    // Here to mark the client id as invalid and let the poll function to acquire a new queue token.
    if (response === false) {
        dispatch(setWaitingRoomClientIdAsInvalid());
        return;
    }

    dispatch(_updateWaitingRoomWithTransformation(response));

    const { waitingRoom } = getState();

    if (waitingRoom.isLive) {
        const waitingRoomToken = transformWaitingRoomDataToToken(response);

        dispatch(updateTokensWithWaitingRoomTokenAction(waitingRoomToken));
        dispatch(navigateToTicketSelectionAction());
    }
};

export const updateWaitingRoomStep = (step) => (dispatch, getState) => {
    const { waitingRoom } = getState();

    dispatch(
        updateWaitingRoom({
            ...waitingRoom,
            step,
        }),
    );
};

export const setIsWaitingRoomShown = () => (dispatch, getState) => {
    const { waitingRoom } = getState();

    dispatch(
        updateWaitingRoom({
            ...waitingRoom,
            isWaitingRoomShown: true,
        }),
    );
};

export const updateWaitingRoomWithToken = (waitingRoomToken) => (dispatch) => {
    if (waitingRoomToken) {
        // Order matters here
        const [
            clientId,
            queueId,
            isLive,
            allocationTime,
            hashCode,
        ] = waitingRoomToken.split(WAITING_ROOM_TOKEN_DELIMITER);

        dispatch(
            updateWaitingRoom({
                clientId,
                queueId,
                isLive: isLive === 'true',
                allocationTime,
                hashCode,
            }),
        );

        dispatch(updateTokensWithWaitingRoomTokenAction(waitingRoomToken));
    }

    return Promise.resolve();
};

export const queueRenew = () => (dispatch, getState) => {
    const { waitingRoom } = getState();

    if (
        (waitingRoom.isActivatedForEvent && !waitingRoom.clientId) ||
        waitingRoom.clientId === INVALID_CLIENT_ID
    ) {
        return queueAcquireApi(waitingRoom)
            .then((response) =>
                dispatch(
                    _updateWaitingRoomAndNavigateToTicketSelection(response),
                ),
            )
            .catch(noop);
    } else if (waitingRoom.isInWaitingRoom) {
        return queueRenewApi(waitingRoom)
            .then((response) =>
                dispatch(
                    _updateWaitingRoomAndNavigateToTicketSelection(response),
                ),
            )
            .catch(noop);
        // Always refresh on checkout step to claim the spot
        // no matter if waiting room is activated or not.
        // ... Unless if we have an order ID already: with and existing order ID we do not create a waiting room clientID as we skip the ticket selection step
        // And therefore the following call would result in a 404
    } else if (
        waitingRoom.step === CHECKOUT_STEP_CHECKOUT &&
        !hasExistingOrder(getState())
    ) {
        return queueRefreshApi(waitingRoom).catch(noop);
    }

    return Promise.resolve();
};

export const queueFree = () => (dispatch, getState) => {
    const { waitingRoom } = getState();

    // first check if we were in a waiting room situation
    if (waitingRoom.isWaitingRoomShown) {
        // then make the call to the server
        return (
            queueFreeApi(waitingRoom)
                // silently fail, we don't care about errors here
                .catch(noop)
                .then(() => dispatch(resetWaitingRoom()))
        );
    }

    return Promise.resolve();
};
