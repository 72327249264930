import * as React from 'react';

const NativaPaymentSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width={36} height={24} viewBox="0 0 48 32" {...props}>
        <defs>
            <path id="nativa-payment_svg__a" d="M0 .023h4.947V8.69H0z" />
            <path id="nativa-payment_svg__c" d="M.041.013h1.8V11.6h-1.8z" />
        </defs>
        <g fill="none" fillRule="evenodd">
            <path
                d="M3 31.5C1.6 31.5.5 30.4.5 29V3C.5 1.6 1.6.5 3 .5h42c1.4 0 2.5 1.1 2.5 2.5v26c0 1.4-1.1 2.5-2.5 2.5H3z"
                fill="#FFF"
            />
            <path
                d="M45 1c1.1 0 2 .9 2 2v26c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h42zm0-1H3C1.4 0 0 1.4 0 3v26c0 1.6 1.4 3 3 3h42c1.6 0 3-1.4 3-3V3c0-1.6-1.4-3-3-3z"
                fill="#D2D6DF"
                fillRule="nonzero"
            />
            <g transform="matrix(1.33333 0 0 1.33333 5.333 9.547)">
                <mask id="nativa-payment_svg__b" fill="#fff">
                    <use xlinkHref="#nativa-payment_svg__a" />
                </mask>
                <path
                    d="M4.312.023v2.234L3.04.324l.67 2.152L1.907.896 3.199 2.81.989 1.702 2.793 3.28.337 2.692l2.209 1.11L0 3.787l2.456.588L0 4.927h2.546l-2.21 1.11 2.457-.588L.989 7.027 3.2 5.919 1.907 7.834 3.71 6.256l-.67 2.15 1.272-1.934V8.69l.635-2.082a9.953 9.953 0 01-.264-2.234c0-.74.089-1.49.264-2.25l-.635-2.1z"
                    fill="#F1B800"
                    mask="url(#nativa-payment_svg__b)"
                />
            </g>
            <g transform="matrix(1.33333 0 0 1.33333 11.932 8)">
                <mask id="nativa-payment_svg__d" fill="#fff">
                    <use xlinkHref="#nativa-payment_svg__c" />
                </mask>
                <path
                    d="M.97 1.564l-.125.29A9.856 9.856 0 00.29 3.543a9.96 9.96 0 00-.25 2.282c0 .797.085 1.551.25 2.267.119.532.304 1.096.554 1.686l.124.289c.261.558.551 1.068.873 1.534-.368-.752-.661-1.5-.873-2.25a12.939 12.939 0 01-.498-3.526c0-1.192.165-2.374.498-3.545.212-.749.505-1.503.873-2.266-.299.444-.589.96-.873 1.55"
                    fill="#003772"
                    mask="url(#nativa-payment_svg__d)"
                />
            </g>
            <path
                d="M17.646 12.774h-.764v6.053h.764v-3.804c0-.623.145-1.097.436-1.427.246-.264.565-.398.96-.398.799 0 1.2.467 1.2 1.402v4.227h.764v-4.27c0-.551-.096-.975-.284-1.272-.277-.431-.77-.645-1.485-.645-.611 0-1.133.282-1.57.848h-.021v-.714zM25.828 16.749c0 .41-.179.758-.536 1.05a1.882 1.882 0 01-1.266.458c-.425 0-.75-.117-.976-.348a.426.426 0 01-.072-.09c-.163-.202-.246-.465-.246-.785 0-.292.083-.534.246-.723.179-.23.46-.39.853-.48l1.997-.153v1.071zm-2.56-1.398c-.1.028-.195.057-.29.089-.766.288-1.147.836-1.147 1.636 0 .523.163.947.488 1.267.179.16.397.284.66.373.241.075.52.11.825.11.909 0 1.591-.284 2.048-.853h.027v.743h.901l-.099-4.5c0-.658-.286-1.11-.853-1.355-.326-.146-.79-.221-1.39-.221-.583 0-1.07.096-1.46.285a1.002 1.002 0 00-.17.089c-.489.26-.75.668-.782 1.223h.924c0-.089.008-.167.028-.242a.82.82 0 01.39-.544c.241-.174.575-.263 1-.263.551 0 .932.131 1.142.391.21.22.318.562.318 1.028v.502l-2.56.242zM28.695 11.093h-.807v1.377H26.78v.586h1.108v4.553c0 .407.101.712.3.913.214.202.593.305 1.13.305l.762-.023v-.654l-.646.045c-.307 0-.506-.068-.6-.209-.094-.14-.14-.422-.14-.84v-4.09h1.386v-.586h-1.385v-1.377zM30.905 18.827h.825V12.64h-.825zM30.905 11.867h.825v-.774h-.825zM33.442 12.64h-.887l2.03 6.187h.91l2.009-6.187h-.754l-1.663 5.231h-.026zM40.032 18.257c-.42 0-.754-.117-1-.348-.21-.22-.314-.513-.314-.875 0-.627.365-1.029 1.096-1.203l2-.153v1.071c0 .41-.17.758-.512 1.05a1.9 1.9 0 01-1.27.458m1.488-4.678c.195.22.294.562.294 1.028v.502l-2.56.242c-.96.245-1.44.822-1.44 1.725 0 .523.17.947.511 1.267.342.324.838.484 1.489.484.908 0 1.583-.285 2.024-.854h.047v.743h.878a14.908 14.908 0 01-.072-1.508v-2.992c0-1.05-.758-1.576-2.27-1.576-.679 0-1.23.124-1.655.373-.472.26-.723.67-.758 1.224h.929c0-.352.138-.612.412-.786.242-.174.584-.263 1.024-.263.536 0 .921.131 1.147.391"
                fill="#003772"
            />
            <g fillRule="nonzero">
                <path
                    fill="#F1B800"
                    d="M34.856 21.55l.158-.82.56.82h.04l.217-1.132H35.7l-.16.832-.563-.832h-.04l-.217 1.132zM36.137 21.55l.245-.379h.467l.103.38h.14l-.301-1.178h-.04l-.761 1.177z"
                />
                <path fill="#FFF" d="M36.815 21.037h-.345l.246-.382z" />
                <path
                    d="M37.762 21.578a.733.733 0 00.546-.235l-.089-.079a.598.598 0 01-.415.187c-.145 0-.255-.044-.33-.132a.376.376 0 01-.073-.33.558.558 0 01.204-.333c.11-.09.23-.136.36-.136a.4.4 0 01.36.195l.117-.079a.43.43 0 00-.181-.183.587.587 0 00-.28-.063.748.748 0 00-.717.588c-.033.173-.003.317.09.43.092.113.228.17.408.17zM38.945 21.55l.217-1.132h-.133l-.217 1.132zM40.247 20.39c.11 0 .207.026.29.078a.433.433 0 01.177.216.535.535 0 01.023.3.76.76 0 01-.394.516.678.678 0 01-.315.078.526.526 0 01-.286-.078.43.43 0 01-.177-.215.536.536 0 01-.021-.298.76.76 0 01.394-.517.666.666 0 01.31-.08zm-.02.13a.524.524 0 00-.352.136.567.567 0 00-.193.329.383.383 0 00.071.329.36.36 0 00.296.134.54.54 0 00.352-.131.55.55 0 00.197-.33.386.386 0 00-.071-.332.366.366 0 00-.3-.135zM41.043 21.55l.157-.82.56.82h.04l.218-1.132h-.132l-.16.832-.562-.832h-.04l-.217 1.132z"
                    fill="#F1B800"
                />
            </g>
        </g>
    </svg>
);

NativaPaymentSvg.displayName = 'NativaPaymentSvg';
export default NativaPaymentSvg;
