import { addErrorReportingTag } from './tags';

// These process env vars have to be defined with the webpack.DefinePlugin
window.__ebdebug__ = {
    environment: {
        node_env: process.env.NODE_ENV,
        api_host: process.env.API_HOST,
        ssr: process.env.SSR,
        react_app_target: process.env.REACT_APP_TARGET,
    },
    errorReportingTags: {
        react_version: process.env.REACT_VERSION,
    },
};

for (const [name, value] of Object.entries<string>(
    window.__ebdebug__.errorReportingTags,
)) {
    addErrorReportingTag({ name, value });
}
