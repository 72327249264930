import React from 'react';
import PropTypes from 'prop-types';

import { SortableList, SortableItem, arrayMove } from './_internal/Sortable';
import { Card } from '@eb/eds-card';
import { STYLE_PROP_TYPE } from '@eb/eds-card';
import { CARD_ITEMS_PROP_TYPE } from './constants';

import './cardList.scss';

export default class CardList extends React.PureComponent {
    static propTypes = {
        /**
         * Array of Card items. Each must include a unique id to be
         * used as a key for the list item.
         */
        items: CARD_ITEMS_PROP_TYPE.isRequired,
        /**
         * Changes the style of all the card items.
         * Useful if all cards should have the same style applied
         * and you don't want to specify a style for each one.
         */
        style: STYLE_PROP_TYPE,
        /**
         * Pass this function to make the card list sortable.
         * We call it when sorting is completed with the oldIndex,
         * and newIndex of the dragged item, and a function named arrayMove.
         * Reorder your items by calling arrayMove(items, oldIndex, newIndex)
         * which will in turn re-render the UI in Card List.
         */
        onSortEnd: PropTypes.func,
        /**
         * An element to use as the drag handle for each sortable item.
         * By default a sortable list gets a drag handle.
         * You can also pass in null, to make the entire item draggable.
         * Only applicable if onSortEnd was passed in.
         */
        dragHandle: PropTypes.node,
    };

    _handleSortEnd = ({ oldIndex, newIndex }) => {
        const { onSortEnd } = this.props;

        if (onSortEnd) {
            onSortEnd({ arrayMove, oldIndex, newIndex });
        }
    };

    _getCardListItems(items, cardListStyle) {
        return items.map(({ id, content, style, isSelected }) => (
            <li
                className="eds-card-list__item"
                key={id}
                data-spec="eds-card-list-item"
            >
                <Card style={style || cardListStyle} isSelected={isSelected}>
                    {content}
                </Card>
            </li>
        ));
    }

    _getSortableItems(items, cardListStyle, dragHandle) {
        return items.map(
            ({ id, content, style, isSortable, isSelected }, index) => {
                if (isSortable !== false) {
                    return (
                        <SortableItem
                            key={id}
                            index={index}
                            content={content}
                            style={style || cardListStyle}
                            dragHandle={dragHandle}
                            isSelected={isSelected}
                        />
                    );
                }

                return (
                    <li key={id} className="eds-card-list__item">
                        <Card style={style || cardListStyle}>{content}</Card>
                    </li>
                );
            },
        );
    }

    render() {
        const { items, style, dragHandle, onSortEnd } = this.props;

        if (onSortEnd) {
            return (
                <SortableList
                    items={this._getSortableItems(items, style, dragHandle)}
                    axis="y"
                    lockAxis="y"
                    useDragHandle={dragHandle !== null}
                    lockToContainerEdges={true}
                    onSortEnd={this._handleSortEnd}
                    helperClass="eds-card-list__sortable--dragged"
                />
            );
        }

        return (
            <ul className="eds-card-list">
                {this._getCardListItems(items, style)}
            </ul>
        );
    }
}
