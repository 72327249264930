import compact from 'lodash/compact';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import find from 'lodash/find';
import includes from 'lodash/includes';
import union from 'lodash/union';
import { createSelector } from './utils/selector';
import { anyOrderAttendeesTicketClassHasShipping } from '../utils/deliveryMethod';
import { DELIVERY_METHOD_FORM_NAME, DEFAULT_COUNTRY } from '../constants';
import { DeliveryMethod } from '../api/models/deliveryMethod';
import { getEventShippingSettings } from './event';

// Refactor in-progress - temporarily removing dependency
// TODO: Figure out how to mock this properly. In selectors/order.unit.spec.js it was causing:
// Error: Selector creators expect all input-selectors to be functions, instead received the following types: [undefined]
// Replaced with (state) => get(state, 'order.attendees', [])
// import {getAttendees} from './order';
////////////////////////////////////////////////////////////////////////////////

export const getFormSelectedCountry = (state) =>
    get(
        state,
        ['form', DELIVERY_METHOD_FORM_NAME, 'values', 'shippingCountry'],
        DEFAULT_COUNTRY,
    );

/**
 * Returns an array of ticket groups separated by ticket class.
 * Each ticket group is an array of tickets.
 *
 * @example When we have 3 ticket1's and 4 ticket2's
 * return [[ticket1, ticket2]] --> length of array is 2, length of array[0] is 3, length of array[1] is 4
 *
 * @param tickets the array of selected tickets.
 * @returns {Array} Array of Arrays of ticket objects
 */
export const getTicketGroups = createSelector(
    (state) => get(state, 'order.attendees', []),
    getFormSelectedCountry,
    (attendees) => {
        const attendeeTicketClasses = attendees.map(
            ({ id, deliveryMethod, ticketClass = {} }) => ({
                ...ticketClass,
                attendeeId: id,
                attendeeDeliveryMethod: deliveryMethod,
            }),
        );

        return attendeeTicketClasses.reduce((acc, ticketClass) => {
            const ticketGroupWithSameTicketClass = find(acc, (ticketGroup) =>
                isEqual(ticketGroup[0].id, ticketClass.id),
            );

            if (ticketGroupWithSameTicketClass) {
                ticketGroupWithSameTicketClass.push(ticketClass);

                return acc;
            }

            return acc.concat([[ticketClass]]);
        }, []);
    },
);

export const getShouldShowCountrySelector = createSelector(
    (state) => get(state, 'order.attendees', []),
    getEventShippingSettings,
    (attendees, shippingSettings) =>
        !!shippingSettings &&
        anyOrderAttendeesTicketClassHasShipping(attendees),
);

const getAttendeesTicketClassAvailableDeliveryMethods = createSelector(
    (state) => get(state, 'order.attendees', []),
    (attendees) =>
        union(
            attendees.reduce((acc, { ticketClass: { deliveryMethods } }) => {
                Object.keys(deliveryMethods).forEach((country) => {
                    acc.push(...deliveryMethods[country]);
                });

                return acc;
            }, []),
        ),
);

/**
 * @param {Object} state
 * @returns {Array} a unique, no undefined array of delivery method strings
 */
const getAttendeesSelectedDeliveryMethods = createSelector(
    (state) => get(state, 'order.attendees', []),
    (attendees) =>
        compact(union(attendees.map(({ deliveryMethod }) => deliveryMethod))),
);

const hasOnlyETicketDeliveryMethodAvailable = createSelector(
    getAttendeesTicketClassAvailableDeliveryMethods,
    (availableDeliveryMethods) =>
        availableDeliveryMethods.length === 1 &&
        availableDeliveryMethods[0] === DeliveryMethod.eTicket,
);

/**
 * Returns true if any of the attendees has shipping as a selected delivery method
 * @param {Object} state
 */
export const isShippingDeliveryMethodSelected = createSelector(
    getAttendeesSelectedDeliveryMethods,
    (attendeesSelectedDeliveryMethods) =>
        includes(
            attendeesSelectedDeliveryMethods,
            DeliveryMethod.standardShipping,
        ),
);

/**
 * Returns true when Will Call is the only delivery method available
 * across all of the attendees
 * @param {Object} state
 */
export const hasOnlyWillCallDeliveryMethodAvailable = createSelector(
    getAttendeesTicketClassAvailableDeliveryMethods,
    (availableDeliveryMethods) =>
        availableDeliveryMethods.length === 1 &&
        availableDeliveryMethods[0] === DeliveryMethod.willCall,
);

export const getSelectedDeliveryMethodsForDisplay = createSelector(
    getAttendeesSelectedDeliveryMethods,
    hasOnlyETicketDeliveryMethodAvailable,
    (attendeesSelectedDeliveryMethods, hasOnlyETicketAvailable) =>
        // Since we are charging costs related to MOD we must always show selected delivery method in order summary
        !hasOnlyETicketAvailable
            ? attendeesSelectedDeliveryMethods
            : [DeliveryMethod.eTicket],
);
