import camelCase from 'lodash/camelCase';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';

import {
    QUANTITY,
    GA,
    TABLE,
    SECTION,
    SEAT_SEPARATOR,
    GROUP_SEPARATOR,
    COMMA_SEPARATOR,
    SEATS_INFO_TABLE_HEADLINE,
    TICKET_INFO_TABLE_HEADLINE,
} from '../constants';

import gettext from '@eb/gettext';

const findCurrent = (ticketInfo, type, current) =>
    find(ticketInfo[type], (item) => item.key === current);

const findMatchingColumns = (ticketInfo, type, columns) =>
    find(ticketInfo[type], (item) => isEqual(item.cols, columns));

const findMatchingRow = (item, col1Id, col1Value, col2Id, col2Value) =>
    item &&
    find(
        item.rows,
        (rowObj) =>
            rowObj[col1Id] === col1Value && rowObj[col2Id] === col2Value,
    );

const getColumnsFromValues = (values) =>
    Object.assign({}, ...values.map((item) => ({ [item.id]: item.title })));

const getRowFromValues = (values) =>
    Object.assign(
        {},
        ...values.map((item) => ({
            [item.id]: item.value,
            unitId: item.unitId,
            ticketClassId: item.ticketClassId,
        })),
    );

const getTicketValuesForIndex = (ticket, i) => ({
    id: camelCase(ticket.titles[i]),
    title: ticket.titles[i],
    value: ticket.labels[i],
    unitId: ticket.unitId,
    ticketClassId: ticket.ticketClassId,
});

const getValuesForTicket = (ticket) =>
    ticket.labels.map((label, i) => getTicketValuesForIndex(ticket, i));

const addGeneralAdmissionToTicketInfo = ({ ticket, ticketInfo, section }) => {
    const values = getValuesForTicket(ticket);
    const colQtyId = camelCase(QUANTITY);
    const cols = getColumnsFromValues(values);
    const newRow = getRowFromValues(values);

    cols[colQtyId] = QUANTITY;
    newRow[colQtyId] = 1;

    const currentGA = findCurrent(ticketInfo, GA, section);
    const matchingColumns = findMatchingColumns(ticketInfo, GA, cols);
    const matchingRow = findMatchingRow(
        currentGA || matchingColumns,
        values[0].id,
        values[0].value,
    );

    if (currentGA) {
        matchingRow[colQtyId]++;
    } else if (matchingColumns) {
        if (matchingRow) {
            matchingRow[colQtyId]++;
        } else {
            matchingColumns.rows.push(newRow);
        }
    } else {
        ticketInfo[GA].push({
            key: section,
            cols,
            rows: [newRow],
        });
    }
};

const addTablesToTicketInfo = ({ ticket, ticketInfo, table, collateRows }) => {
    const values = getValuesForTicket(ticket);
    const cols = getColumnsFromValues(values);
    const newRow = getRowFromValues(values);
    const currentTable = findCurrent(ticketInfo, TABLE, table);
    const matchingColumns = findMatchingColumns(ticketInfo, TABLE, cols);
    const matchingRow = findMatchingRow(
        currentTable || matchingColumns,
        values[0].id,
        values[0].value,
    );

    if (currentTable && collateRows) {
        matchingRow[values[1].id] += `${COMMA_SEPARATOR} ${values[1].value}`;
    } else if (matchingColumns) {
        if (matchingRow && collateRows) {
            matchingRow[
                values[1].id
            ] += `${COMMA_SEPARATOR} ${values[1].value}`;
        } else {
            matchingColumns.rows.push(newRow);
        }
    } else {
        ticketInfo[TABLE].push({
            key: `${table}`,
            cols,
            rows: [newRow],
        });
    }
};

const addSectionsToTicketInfo = ({
    ticket,
    ticketInfo,
    section,
    row,
    collateRows,
}) => {
    const sectionRowKey = `${section}_${row}`;
    const values = getValuesForTicket(ticket);
    const cols = getColumnsFromValues(values);
    const newRow = getRowFromValues(values);
    const currentSection = findCurrent(ticketInfo, SECTION, sectionRowKey);
    const matchingColumns = findMatchingColumns(ticketInfo, SECTION, cols);
    const matchingRow = findMatchingRow(
        currentSection || matchingColumns,
        values[0].id,
        values[0].value,
        values[1].id,
        values[1].value,
    );

    if (currentSection && collateRows) {
        matchingRow[values[2].id] += `${COMMA_SEPARATOR} ${values[2].value}`;
    } else if (matchingColumns) {
        if (matchingRow && collateRows) {
            matchingRow[
                values[2].id
            ] += `${COMMA_SEPARATOR} ${values[2].value}`;
        } else {
            matchingColumns.rows.push(newRow);
        }
    } else {
        ticketInfo[SECTION].push({
            key: `${sectionRowKey}`,
            cols,
            rows: [newRow],
        });
    }
};

export const hasSectionTickets = (ticketInfo) =>
    ticketInfo[SECTION] && ticketInfo[SECTION].length !== 0;

export const getTicketInfoFromAssignedUnits = (units = [], collateRows) => {
    const ticketInfo = {
        [GA]: [],
        [TABLE]: [],
        [SECTION]: [],
    };

    units.forEach((ticket) => {
        let seat;
        let area;
        let table;
        const ids = ticket.unitId.split(SEAT_SEPARATOR);

        if (ids.length !== 1) {
            seat = ids[1];
        }

        if (seat) {
            area = ids[0].split(GROUP_SEPARATOR);
        }

        if (area && area.length === 1) {
            table = area[0];
        }

        const sectionRow = area && area.length === 2 ? area : [];
        const section = seat ? sectionRow[0] : ids[0];
        const row = sectionRow[1];

        if (!seat) {
            addGeneralAdmissionToTicketInfo({ ticket, ticketInfo, section });
        } else if (table) {
            addTablesToTicketInfo({ ticket, ticketInfo, table, collateRows });
        } else if (row) {
            addSectionsToTicketInfo({
                ticket,
                ticketInfo,
                section,
                row,
                collateRows,
            });
        }
    });
    return ticketInfo;
};

export const getTitleSectionText = (isRegEvent) => {
    return isRegEvent ? SEATS_INFO_TABLE_HEADLINE : TICKET_INFO_TABLE_HEADLINE;
};
