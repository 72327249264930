import { PaypalScriptStatus } from '../state/paypal';

export enum PaypalActionType {
    setScriptStatus = 'setScriptStatus',
}

export interface SetPaypalScriptStatusAction {
    type: PaypalActionType.setScriptStatus;
    payload: {
        scriptStatus: PaypalScriptStatus;
    };
}
export const setPaypalScriptStatusAction = (
    scriptStatus: PaypalScriptStatus,
): SetPaypalScriptStatusAction => ({
    type: PaypalActionType.setScriptStatus,
    payload: {
        scriptStatus,
    },
});

export type PaypalAction = SetPaypalScriptStatusAction;
