import React from 'react';
import gettext from '@eb/gettext';
import classNames from 'classnames';

import { TYPE_BUTTON } from '@eb/eds-button';
import { Button } from '@eb/eds-button';
import { Debounced } from '@eb/eds-debounced';
import { InputField } from '@eb/eds-input-field';
import { ProgressIndicator } from '@eb/eds-progress-indicator';
import { ValidationFormField } from '@eb/eds-validation-form-field';

import { shouldDisplayError } from '@eb/redux-form-tools';

const InputCtaText = ({ className, text }) => (
    <span className={className}>{text}</span>
);

const InputLoadingSpinner = () => (
    <ProgressIndicator
        size="small-chunky"
        shape="circular"
        type="indeterminate"
        style="gradient"
    />
);

export const PromoCodeFormLink = ({ onClick }) => (
    <div className="eds-g-cell eds-g-cell-8-12 eds-text-bm--fixed eds-text-color--ui-blue eds-button--none">
        <button
            className="eds-btn--none eds-link "
            onClick={onClick}
            data-automation="promo-code-form-link"
            data-spec="promo-code-form-link"
        >
            {gettext('Enter promo code')}
        </button>
    </div>
);

export const PromoCodeCtaText = ({
    isLoading,
    isDisabled,
    ctaText,
    onClick,
}) => {
    const ctaClassName = classNames('eds-text-bm--fixed', {
        'eds-text-color--ui-blue': !isDisabled,
        'eds-text-color--grey-500': isDisabled,
    });

    const inputCtaText = (
        <InputCtaText className={ctaClassName} text={ctaText} />
    );

    const ctaContent = isLoading ? <InputLoadingSpinner /> : inputCtaText;

    return (
        <Button
            style="none"
            onClick={onClick}
            disabled={isDisabled}
            data-automation="promo-code-form-cta-text"
            data-spec="promo-code-form-cta-text"
        >
            {ctaContent}
        </Button>
    );
};

export const PromoCodeCtaButton = ({ isDisabled, onClick }) => (
    <Button
        type={TYPE_BUTTON}
        style="fill"
        size="block"
        onClick={onClick}
        data-automation="checkout-widget-submit-promo-button"
        disabled={isDisabled}
        data-spec="promo-code-form-cta-button"
    >
        {gettext('Apply')}
    </Button>
);

export const PromoCodeInputField = ({
    handleSubmit,
    query,
    cta,
    onChange,
    inputLabel,
    inputPlaceholder,
    disablePromoCodeAutoFocus,
}) => {
    const formClassName = classNames('responsive-form', 'eds-g-group');

    const debouncedInputField = (
        <Debounced
            wrappedComponent={InputField}
            callbacksToDebounce={['onChange']}
            debounceTime={500}
            id="promo-code-field"
            value={query}
            role="textbox"
            style="static"
            data-automation="promo-code-field"
            data-spec="promo-code-field"
            label={inputLabel}
            placeholder={inputPlaceholder}
            suffix={cta}
            onChange={onChange}
            defaultIsActive={!disablePromoCodeAutoFocus}
        />
    );

    return (
        <form
            className={formClassName}
            onSubmit={handleSubmit}
            data-automation="promo-code-form"
        >
            <div className="eds-g-cell eds-g-cell-12-12">
                <ValidationFormField
                    name="promo"
                    shouldDisplayError={shouldDisplayError}
                    v2={true}
                >
                    {debouncedInputField}
                </ValidationFormField>
            </div>
        </form>
    );
};
