import isPlainObject from 'lodash/isPlainObject';
import isFunction from 'lodash/isFunction';
import isEmpty from 'lodash/isEmpty';

/**
 * Util to validate HEX strings
 * Regex breakdown:
 * (^#[0-9A-F]{6}$) - Allows Num/Char string with 6 digits
 * (^#[0-9A-F]{3}$) - Allows Num/Char string with 3 digits
 * /i - ignore case
 *
 * Explicitly ignore 8 digit HEX colors as we do not want to allow transparency
 */
export const hexValidator = (hexColor: string): boolean =>
    /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(hexColor);

/**
 * TODO: Get validation schema from EDS once theming is complete
 * At the moment, we are only allowing customization of the
 * primary branding (button and badges) in the checkout widget
 * https://jira.evbhome.com/browse/EB-133528
 *
 * In the future, we should get all of the available theme tokens
 * and validation info directly from somewehere such as eds-settings:
 * https://github.com/eventbrite/eds-settings
 */
export const THEME_VALIDATION_SCHEMA = {
    'primary-brand': hexValidator,
    'primary-brand--hover': hexValidator,
    'inverse-primary-brand': hexValidator,
};

export type ThemeSettingsValidationFunction = (key: any) => boolean;
export type ThemeSettingsValidationSchema = {
    [key: string]:
        | ThemeSettingsValidationFunction
        | ThemeSettingsValidationSchema;
};

/**
 * This validation function takes a themeSettings object and returns a sanitized version
 * of it, if it contains valid theme data.
 *
 * @param {Object} themeSettings an object with theme override data, where the keys are theme tokens.
 * @param {Object} validationSchema an object where the keys are theme tokens, and the values are validation functions
 * The default value is currently a subset of theme tokens made available for users to override
 * @return {Object} An object with valid theme data, ready to be consumed by EDS
 */
export const getValidThemeSettings = <
    ThemeSettings extends Record<string, any>,
    ValidThemeSettings extends object
>(
    themeSettings: ThemeSettings,
    validationSchema: ThemeSettingsValidationSchema = THEME_VALIDATION_SCHEMA,
): ValidThemeSettings | undefined => {
    if (!themeSettings) {
        return undefined;
    }

    // Only validate tokens which are present in the schema
    const validTokens = Object.keys(themeSettings).filter((token) =>
        validationSchema.hasOwnProperty(token),
    );

    // Validate the value of each themeToken against the validation schema
    const validThemeSettings = validTokens.reduce(
        (validThemeSettings, token) => {
            if (isFunction(validationSchema[token])) {
                const validationFunction = validationSchema[
                    token
                ] as ThemeSettingsValidationFunction;
                const isValidTheme = validationFunction(themeSettings[token]);

                if (!isValidTheme) {
                    return validThemeSettings;
                }

                return {
                    ...validThemeSettings,
                    [token]: themeSettings[token],
                };
            }

            if (isPlainObject(validationSchema[token])) {
                const childValidationSchema = validationSchema[
                    token
                ] as ThemeSettingsValidationSchema;

                const childThemeSetting = getValidThemeSettings(
                    themeSettings[token],
                    childValidationSchema,
                );

                if (isEmpty(childThemeSetting)) {
                    return validThemeSettings;
                }

                return {
                    ...validThemeSettings,
                    [token]: childThemeSetting,
                };
            }

            return validThemeSettings;
        },
        {} as ValidThemeSettings,
    );

    return isEmpty(validThemeSettings) ? undefined : validThemeSettings;
};
