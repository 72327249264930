import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@eb/eds-modal';
import './page.scss';
import { Structure } from '@eb/eds-structure';
import { THEME_SETTINGS_PROP } from '../constants';
import { translationPropType } from '@eb/i18n';

const POLL_THRESHOLD = 10000;

const ThemeWrapper = ({ themeSettings, children }) => {
    if (themeSettings) {
        return (
            <Structure rootTheme={themeSettings} fullScreenMain={true}>
                {children}
            </Structure>
        );
    }
    return children;
};

const _getCloseFn = (onClose, isNativeApp) =>
    onClose && onClose.bind(null, isNativeApp);

const CheckoutContainer = ({
    children,
    isModal,
    isNativeApp,
    modalAriaLabel,
    onClose,
    themeSettings,
}) => {
    if (isModal || isNativeApp) {
        return (
            <ThemeWrapper themeSettings={themeSettings}>
                <Modal
                    type="aperture"
                    ariaLabel={modalAriaLabel}
                    ariaModal={true}
                    disableCloseOnClickOutside={true}
                    isShown={true}
                    modalBodyIsEmpty={true}
                    noPadding={true}
                    onClose={_getCloseFn(onClose, isNativeApp)}
                    roleIsDialog={true}
                >
                    {children}
                </Modal>
            </ThemeWrapper>
        );
    }

    return (
        // XXX: The .eds-modal class provides necessary styling to make the fixed header/footer work.
        // That CSS should be factored out into a separate class so we can use that class instead of the
        // modal class. Ticket here: https://jira.evbhome.com/browse/EB-60185
        // Also we need to overwrite fixed position, applied by eds-modal class, because after zooming-in the
        // checkout is not displayed correctly on iOs Version 12.0.
        <ThemeWrapper themeSettings={themeSettings}>
            <div
                data-reactroot
                className="checkout-widget-container eds-modal eds-modal--active eds-fx--fade-in eds-bg-color--white u-position-absolute"
                data-automation="checkout-widget-container"
            >
                {children}
            </div>
        </ThemeWrapper>
    );
};

export default class Page extends React.Component {
    static propTypes = {
        /**
         * action to abandon order when closing window
         */
        abandonOrderOnClose: PropTypes.func,
        /**
         * The component to be rendered inside the Page.
         */
        children: PropTypes.element,
        isModal: PropTypes.bool,
        /**
         * Always show modal view when in native app
         */
        isNativeApp: PropTypes.bool,
        performTabless: PropTypes.bool,
        /**
         * Theme override data for customization
         */
        onClose: PropTypes.func,
        /**
         * Function to be called periodically to let the waiting room know we are alive
         */
        onQueueRenew: PropTypes.func.isRequired,
        themeSettings: THEME_SETTINGS_PROP,
        /**
         * Accessible title for the Modal component
         */
        modalAriaLabel: translationPropType,
    };

    _handleBeforeUnload = () => this.props.abandonOrderOnClose();

    _setWindowClosingActions = () => {
        if (!this.props.performTabless) {
            window.addEventListener('beforeunload', this._handleBeforeUnload);
        }
    };

    _unsetWindowClosingActions = () => {
        window.removeEventListener('beforeunload', this._handleBeforeUnload);
    };

    componentDidMount() {
        this._startPoll();
        this._setWindowClosingActions();
    }

    componentWillUnmount() {
        clearInterval(this.pollFunction);
        this._unsetWindowClosingActions();
    }

    _startPoll() {
        this.props.onQueueRenew();
        this.pollFunction = setInterval(
            () => this.props.onQueueRenew(),
            POLL_THRESHOLD,
        );
    }

    render() {
        const { children, isModal, isNativeApp, modalAriaLabel, onClose } = this.props;

        return (
            <CheckoutContainer
                isModal={isModal}
                isNativeApp={isNativeApp}
                modalAriaLabel={modalAriaLabel}
                onClose={onClose}
            >
                {children}
            </CheckoutContainer>
        );
    }
}
