import React, { PureComponent } from 'react';

import { Checkbox } from '@eb/eds-checkbox';

export default class MultiselectOption extends PureComponent {
    render() {
        const {
            parentId,
            value,
            isSelected,
            onItemChange,
            display,
        } = this.props;

        /* Accessibility is not fully developed yet because it may need extra work. See: https://jira.evbhome.com/browse/EB-73967 */
        /* eslint-disable jsx-a11y/click-events-have-key-events */
        return (
            <li
                role="option"
                aria-selected={isSelected}
                className="eds-field-multiselect__list-item eds-text-color--grey-700 eds-l-pad-vert-3 eds-l-pad-hor-4"
                onClick={onItemChange}
            >
                <Checkbox
                    id={`checkbox-${parentId}-${value}`}
                    name={`checkbox-${parentId}-${value}`}
                    htmlValue={value}
                    value={isSelected}
                >
                    {display}
                </Checkbox>
            </li>
        );
        /* eslint-enable jsx-a11y/click-events-have-key-events */
    }
}
