import { GenericLazyString } from '@eb/i18n';
import gettext from '@eb/gettext';
import ngettext from '@eb/ngettext';

export const SALES_END_PROP_KEY = 'salesEnd';
const SALES_START_PROP_KEY = 'salesStart';

export const AVAILABLE_TICKET_STATUS = 'AVAILABLE';
export const TICKET_NOT_YET_ON_SALE = 'NOT_YET_ON_SALE';
export const SALES_NOT_STARTED = 'salesNotStarted';
export const SALES_NOT_STARTED_TEXT = gettext('Sales start on %(date)s');
export const SALES_START_SOON = 'salesStartSoon';
export const SALES_START_SOON_TEXT = gettext('Sales start in %(date)s');
export const ON_SALE = 'onSale';
export const ON_SALE_TEXT = gettext('Sales end on %(date)s');
export const SALES_END_SOON = 'salesEndSoon';
export const SALES_END_SOON_TEXT = gettext('Sales end in %(date)s');
export const SOLD_OUT = 'soldOut';
export const SOLD_OUT_TEXT = 'Sold Out';
export const UNAVAILABLE = 'unavailable';
export const MAX_TICKET_PER_ORDER_ZERO = gettext(
    'There are no tickets available to purchase',
);
export const MAX_TICKET_PER_ORDER = (maximumQuantityPerOrder: number) =>
    //Translators: 1 ticket available per purchase
    //Translators: 2 tickets available per purchase
    ngettext(
        '%(maximumQuantityPerOrder)s ticket available per purchase',
        '%(maximumQuantityPerOrder)s tickets available per purchase',
        maximumQuantityPerOrder,
    );

export const CSS_TICKET_DISABLED = 'eds-ticket-card-content--is-disabled';
export const SPEC_TICKET_SOLDOUT = 'sold-out-text';
export const SPEC_TICKET_CONTENT = 'ticket-content';

export const TICKET_INFO_TABLE_HEADLINE = gettext('Your Tickets');
export const SEATS_INFO_TABLE_HEADLINE = gettext('Your Seats');
export const TICKET_EDIT_CTA = gettext('Edit Tickets');
export const SEAT_EDIT_CTA = gettext('Edit Seats');

export const QUANTITY = gettext('Qty');
export const GA = 'GA';
export const TABLE = 'TABLE';
export const SECTION = 'SECTION';
export const SEAT_SEPARATOR = ':';
export const GROUP_SEPARATOR = '-';
export const COMMA_SEPARATOR = ',';

// Donation amount should be no less than 1 or greater than 1M.
export const DONATION_AMOUNT_MAX = 1000000;
export const DONATION_AMOUNT_MIN = 1;

const SALES_NOT_STARTED_DISPLAY_FORMAT = {
    textColor: 'eds-text-color--grey-600',
    getDisplayText: (dateString: string | GenericLazyString) =>
        gettext('Sales start on %(date)s', { date: dateString.toString() }),
    datePropKey: SALES_START_PROP_KEY,
};

const SALES_START_SOON_DISPLAY_FORMAT = {
    textColor: 'eds-text-color--ui-orange',
    getDisplayText: (dateString: string | GenericLazyString) =>
        gettext('Sales start in %(date)s', { date: dateString.toString() }),
    datePropKey: SALES_START_PROP_KEY,
};

const ON_SALE_DISPLAY_FORMAT = {
    textColor: 'eds-text-color--grey-600',
    getDisplayText: (dateString: string | GenericLazyString) =>
        gettext('Sales end on %(date)s', { date: dateString.toString() }),
    datePropKey: SALES_END_PROP_KEY,
};

const SALES_END_SOON_DISPLAY_FORMAT = {
    textColor: 'eds-text-color--ui-orange',
    getDisplayText: (dateString: string | GenericLazyString) =>
        gettext('Sales end in %(date)s', { date: dateString.toString() }),
    datePropKey: SALES_END_PROP_KEY,
};

const SOLD_OUT_DISPLAY_FORMAT = {
    textColor: 'eds-text-color--grey-600',
    getDisplayText: (dateString: string | GenericLazyString) =>
        gettext('Sales end on %(date)s', { date: dateString.toString() }),
    datePropKey: SALES_END_PROP_KEY,
};

const UNAVAILABLE_DISPLAY_FORMAT = {
    textColor: 'eds-text-color--grey-600',
    getDisplayText: (dateString: string | GenericLazyString) =>
        gettext('Sales end on %(date)s', { date: dateString.toString() }),
    datePropKey: SALES_END_PROP_KEY,
};

export const VARIANT_INPUT_TYPE = {
    SINGLE: 'single',
    MULTIPLE: 'multiple',
};

export const TIERED_VARIANT_TYPE = 'single';

export const TICKET_SALES_STATUS_MAP = {
    [SALES_NOT_STARTED]: SALES_NOT_STARTED_DISPLAY_FORMAT,
    [SALES_START_SOON]: SALES_START_SOON_DISPLAY_FORMAT,
    [ON_SALE]: ON_SALE_DISPLAY_FORMAT,
    [SALES_END_SOON]: SALES_END_SOON_DISPLAY_FORMAT,
    [SOLD_OUT]: SOLD_OUT_DISPLAY_FORMAT,
    [UNAVAILABLE]: UNAVAILABLE_DISPLAY_FORMAT,
};

export const TICKET_SALES_STATUS_PROP_TYPE = Object.keys(
    TICKET_SALES_STATUS_MAP,
);

export const TIER_DEFAULT_COLOR = '#00FF00';

export const EVENT_IN_DRAFT_TEXT = gettext(
    "Please note, you won't be able to cart tickets until your event is published.",
);
