import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ToggleButton } from '@eb/eds-button';
import { Icon } from '@eb/eds-icon';
import { Divider } from '@eb/eds-divider';

import { isSmallDown } from '@eb/eds-utils';
import { HAS_WINDOW } from '@eb/eds-utils';

import { STYLE_NONE } from '@eb/eds-button';

import {
    STYLES_PROP_TYPE,
    STYLE_CONTAINER,
    STYLE_PROTOTYPE,
    STYLE_CONTAINER_DIVIDER,
    STYLE_ORDER_SUMMARY,
    VERTICAL_MARGIN_TYPES,
    VERTICAL_MARGIN_BOTH,
    VERTICAL_MARGIN_TOP,
    VERTICAL_MARGIN_BOTTOM,
} from './constants';

import './expansionPanel.scss';

import { ChevronUpChunky } from '@eb/eds-iconography';
import { ChevronDownChunky } from '@eb/eds-iconography';

const LinkText = ({ text, isExpanded, linkClassNames, linkTextClassNames }) => {
    const iconType = isExpanded ? <ChevronUpChunky /> : <ChevronDownChunky />;

    return (
        <div className={linkClassNames}>
            <div className={linkTextClassNames}>{text}</div>
            <Icon type={iconType} size="small" />
        </div>
    );
};

const ContentDivider = ({ hasDivider }) => {
    let component = null;

    if (hasDivider) {
        component = <Divider />;
    }

    return component;
};

export default class ExpansionPanel extends React.PureComponent {
    static propTypes = {
        /**
         * The content to be expanded or collapsed
         **/
        children: PropTypes.node.isRequired,
        /**
         * The display text of the link
         **/
        linkText: PropTypes.node.isRequired,
        /**
         * The alternate display text of the link (optional)
         **/
        altLinkText: PropTypes.node,
        /**
         * Whether or not the component should be expanded by default
         **/
        defaultIsExpanded: PropTypes.bool,
        /**
         * Whether or not the component should be expanded, overriding any existing state
         **/
        isExpanded: PropTypes.bool,
        /**
         * Callback that is called when the toggle occurs
         **/
        onToggle: PropTypes.func,
        /**
         * user agent is mobile
         **/
        isMobile: PropTypes.bool,
        /**
         * The value displayed on the right side of the expansion panel
         * i.e. $14.00
         */
        suffix: PropTypes.node,
        /**
         * Style variant for component
         * Available styles: default, container, container-divider, order-summary
         **/
        style: STYLES_PROP_TYPE,
        /**
         * Has 16px left margin for linktext when style is container or container-divider
         **/
        hasLinkMargin: PropTypes.bool,
        /**
         * Type of vertical margin for the link container
         * Options are 'top', 'bottom' and 'both' for vertical margin. Adds 4 grid units of margin
         **/
        verticalMargin: VERTICAL_MARGIN_TYPES,
    };

    static defaultProps = {
        defaultIsExpanded: false,
        isMobile: false,
        style: STYLE_PROTOTYPE,
        hasLinkMargin: false,
        verticalMargin: VERTICAL_MARGIN_BOTH,
    };

    constructor(props) {
        super(props);

        const isExpanded =
            props.isExpanded === undefined
                ? props.defaultIsExpanded
                : props.isExpanded;

        this.state = { isExpanded };
    }

    componentWillReceiveProps({ isExpanded }) {
        // We explicitly test for undefined for the case when isExpanded is
        // specifically not passed
        if (isExpanded !== undefined) {
            this.setState({ isExpanded });
        }
    }

    _handleClick() {
        const { onToggle } = this.props;

        this.setState(
            ({ isExpanded: prevIsExpanded }) => ({
                isExpanded: !prevIsExpanded,
            }),
            () => {
                if (onToggle) {
                    onToggle(this.state.isExpanded);
                }
            },
        );
    }

    _isMobile() {
        const { isMobile } = this.props;

        return isMobile || (HAS_WINDOW && isSmallDown());
    }

    _getStyle() {
        const { style } = this.props;

        // for small screens the container style is used unless container-divider is passed in as style prop
        if (
            style !== STYLE_ORDER_SUMMARY &&
            style !== STYLE_CONTAINER_DIVIDER &&
            this._isMobile()
        ) {
            return STYLE_CONTAINER;
        }

        return style;
    }

    _getLinkTextClassNames() {
        const { style, hasLinkMargin } = this.props;

        return classNames('eds-expansion-panel__link-text', {
            'eds-l-mar-left-4': hasLinkMargin && style !== STYLE_PROTOTYPE,
        });
    }

    _getClassNames() {
        const style = this._getStyle();

        return classNames('eds-expansion-panel', {
            [`eds-expansion-panel--${style}`]: style !== STYLE_PROTOTYPE,
        });
    }

    _getLinkClassNames() {
        const style = this._getStyle();
        const { verticalMargin } = this.props;

        return classNames('eds-expansion-panel__link', {
            'eds-l-mar-vert-4':
                style !== STYLE_PROTOTYPE &&
                style !== STYLE_ORDER_SUMMARY &&
                verticalMargin === VERTICAL_MARGIN_BOTH,
            'eds-l-mar-top-4': verticalMargin === VERTICAL_MARGIN_TOP,
            'eds-l-mar-bottom-4': verticalMargin === VERTICAL_MARGIN_BOTTOM,
            'eds-l-mar-vert-2': style === STYLE_ORDER_SUMMARY,
            [`eds-expansion-panel__link--${style}`]: style !== STYLE_PROTOTYPE,
        });
    }

    _getContentClassNames() {
        const { isExpanded } = this.state;

        return classNames('eds-expansion-panel__content', {
            'eds-hide': !isExpanded,
        });
    }

    render() {
        const { children, linkText, style, altLinkText, suffix } = this.props;

        const { isExpanded } = this.state;

        const expansionPanelClassNames = this._getClassNames();
        const contentClassNames = this._getContentClassNames();
        const linkTextClassNames = this._getLinkTextClassNames();
        const linkClassNames = this._getLinkClassNames();
        const hasDivider = style === STYLE_CONTAINER_DIVIDER;
        let suffixContainer;

        const toggleChildren = (
            <LinkText
                text={linkText}
                isExpanded={isExpanded}
                linkTextClassNames={linkTextClassNames}
                linkClassNames={linkClassNames}
            />
        );

        const toggleChildrenAlt = (
            <LinkText
                text={altLinkText || linkText}
                isExpanded={isExpanded}
                linkTextClassNames={linkTextClassNames}
                linkClassNames={linkClassNames}
            />
        );

        if (suffix) {
            const suffixClasses = classNames(
                'eds-g-cell',
                'eds-g-cell-6-12',
                'eds-text--right',
                'eds-l-mar-vert-2',
            );

            suffixContainer = (
                <div className={suffixClasses} role="gridcell">
                    <span className="eds-text-bm--fixed">{suffix}</span>
                </div>
            );
        }

        return (
            <div>
                <div
                    className={expansionPanelClassNames}
                    data-spec="expansion-panel-button-wrapper"
                >
                    <ToggleButton
                        childrenAlt={toggleChildrenAlt}
                        onPress={this._handleClick.bind(this)}
                        style={STYLE_NONE}
                        isPressed={isExpanded}
                    >
                        {toggleChildren}
                    </ToggleButton>
                </div>
                {suffixContainer}
                <ContentDivider hasDivider={hasDivider} />
                <div
                    className={contentClassNames}
                    data-spec="expansion-panel-content"
                >
                    {children}
                </div>
            </div>
        );
    }
}
