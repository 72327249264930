import React from 'react';
import PropTypes from 'prop-types';

import { PASSWORD_STRENGTH_TO_METER_CONVERSION } from './constants';

import { ProgressIndicator } from '@eb/eds-progress-indicator';

import { getPasswordStrength, getPasswordStrengthLabelMarkup } from './utils';

export default class PasswordStrengthMeter extends React.PureComponent {
    /*
    A password strength meter (e.g. "weak", "strong") to provide feedback
    to the user as to the strength of input passwords on signup. Note that
    this component does not show on login.
    */

    static propTypes = {
        /**
         *  The plaintext password to analyze
         */
        inputPassword: PropTypes.string.isRequired,
    };

    render() {
        const { inputPassword } = this.props;
        const {
            passwordScore,
            passwordStrengthLabel,
            isPasswordValidLength,
        } = getPasswordStrength(inputPassword);

        return (
            <div
                className="eds-l-mar-top-4 eds-l-mar-bot-8"
                data-automation="password-strength-meter"
            >
                <ProgressIndicator
                    type="determinate"
                    shape="linear"
                    style="progress"
                    progress={
                        passwordScore * PASSWORD_STRENGTH_TO_METER_CONVERSION
                    }
                />
                {getPasswordStrengthLabelMarkup(
                    passwordStrengthLabel,
                    isPasswordValidLength,
                )}
            </div>
        );
    }
}
