import { DeliveryMethod } from '../api/models/deliveryMethod';

export type DeliveryMethodsByCountry = Record<
    'US' | 'Other',
    Array<DeliveryMethod>
>;

export const DELIVERY_METHOD_DISPLAY_ORDER: Record<DeliveryMethod, number> = {
    [DeliveryMethod.eTicket]: 0,
    [DeliveryMethod.willCall]: 1,
    [DeliveryMethod.standardShipping]: 2,
};

export interface ShippingSettings {
    /**
     * String with format: `1999-12-11T02:00:00Z`
     */
    cutoffDate: string;
    backupDeliveryMethod: DeliveryMethod;
}
