import * as React from 'react';

const VoucherSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 24 24" {...props}>
        <path
            d="M14.005 8L17 5.042v-.026h.027L17.043 5l.015.015L18 5h4v14H2V5h12v1H3v12h11v-1h1v1h6V6h-3v3h-3v1h-1V8h.005zm1.45 0H17V6.455L15.455 8zM5 14v-1h6v1H5zm0-2v-1h6v1H5zm0-2V9h6v1H5zm9 3v-2h1v2h-1zm0 3v-2h1v2h-1z"
            fillRule="evenodd"
        />
    </svg>
);

VoucherSvg.displayName = 'VoucherSvg';
export default VoucherSvg;
