import { parseUrl as _parseUrl } from 'url-lib';
import memoize from 'lodash/memoize';

import { SingleValueValidator } from './types';

const parseUrl = memoize(_parseUrl);
const DOMAIN_REGEX = /(.+)\.(.+)/;

export const _isProtocolValid: SingleValueValidator<string> = (value) =>
    value === 'http:' || value === 'https:';
export const _isHostValid: SingleValueValidator<string> = (value = '') =>
    !!value.match(DOMAIN_REGEX);

export const isValidUrl: SingleValueValidator<string> = (value) => {
    const url = parseUrl(value);

    return _isProtocolValid(url.protocol) && _isHostValid(url.host);
};

export const isValidUrlNoProtocol: SingleValueValidator<string> = (value) => {
    const url = parseUrl(value);

    const isProtocolValid =
        url.protocol === '' || _isProtocolValid(url.protocol);

    return isProtocolValid && _isHostValid(url.href);
};
