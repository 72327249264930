import { AppNamespace } from '../state/app';
import { ParsedError, ParsedErrorType } from '../models/error';

export enum AppActionType {
    initializeApp = 'initializeApp',
    setReloadAfterCheckout = 'setReloadAfterCheckout',
    setOrganizerMarketingOptIn = 'setOrganizerMarketingOptIn',
    retrieveOrderFailure = 'retrieveOrderFailure',
    removeAppError = 'removeAppError',
    updatePayinLockStatus = 'updatePayinLockStatus',
}

export interface InitializeAppAction {
    type: AppActionType.initializeApp;
    payload: AppNamespace;
}

export interface SetReloadAfterCheckoutAction {
    type: AppActionType.setReloadAfterCheckout;
    payload: boolean;
}

export interface SetOrganizerMarketingOptInAction {
    type: AppActionType.setOrganizerMarketingOptIn;
    payload: boolean;
}

export interface RetrieveOrderFailureAction {
    type: AppActionType.retrieveOrderFailure;
    payload: ParsedError;
}

export interface RemoveAppErrorAction {
    type: AppActionType.removeAppError;
    payload: ParsedErrorType;
}

export interface UpdatePayinLockStatusAction {
    type: AppActionType.updatePayinLockStatus;
    payload: boolean;
}

export const initializeAppAction = (
    initialData: AppNamespace,
): InitializeAppAction => ({
    type: AppActionType.initializeApp,
    payload: initialData,
});

export const setReloadAfterCheckoutAction = (
    reloadAfterCheckout: boolean,
): SetReloadAfterCheckoutAction => ({
    type: AppActionType.setReloadAfterCheckout,
    payload: reloadAfterCheckout,
});

export const setOrganizerMarketingOptInAction = (
    enableOrganizerMarketingOptIn: boolean,
): SetOrganizerMarketingOptInAction => ({
    type: AppActionType.setOrganizerMarketingOptIn,
    payload: enableOrganizerMarketingOptIn,
});

export const retrieveOrderFailureAction = (
    error: ParsedError,
): RetrieveOrderFailureAction => ({
    type: AppActionType.retrieveOrderFailure,
    payload: error,
});

export const removeAppErrorAction = (
    errorType: ParsedErrorType,
): RemoveAppErrorAction => ({
    type: AppActionType.removeAppError,
    payload: errorType,
});

const updatePayinLockStatusAction = (
    locked: boolean,
): UpdatePayinLockStatusAction => ({
    type: AppActionType.updatePayinLockStatus,
    payload: locked,
});

export const lockPayinAmountAction = () => updatePayinLockStatusAction(true);
export const unlockPayinAmountAction = () => updatePayinLockStatusAction(false);

export type AppAction =
    | InitializeAppAction
    | SetReloadAfterCheckoutAction
    | SetOrganizerMarketingOptInAction
    | RetrieveOrderFailureAction
    | RemoveAppErrorAction
    | UpdatePayinLockStatusAction;
