import range from 'lodash/range';
import PropTypes from 'prop-types';

import { moment } from '@eb/date';
import { translationPropType } from '@eb/i18n';

import { AddressFields } from '@eb/eds-address-fields';
import { Checkbox } from '@eb/eds-checkbox';
import { CheckboxGroup } from '@eb/eds-checkbox-group';
import { InputField } from '@eb/eds-input-field';
import { Radio } from '@eb/eds-radio';
import { SelectField } from '@eb/eds-input-field';
import { inputFieldConstants } from '@eb/eds-input-field';

import gettext from '@eb/gettext';

export const getFieldName = (attendeeId: string, fieldName: string): string =>
    `${attendeeId}.${fieldName}`;

export const MAX_ATTENDEE_AGE = 110;

import { CannedQuestionName, QuestionType } from '../../api/models/survey';
import { SELECTED_QUESTION_GROUP_BUYER } from '../../models/survey';

export const CANNED_QUESTION_PREFIX = CannedQuestionName.prefix;
export const CANNED_QUESTION_FIRST_NAME = CannedQuestionName.firstName;
export const CANNED_QUESTION_LAST_NAME = CannedQuestionName.lastName;
export const CANNED_QUESTION_SUFFIX = CannedQuestionName.suffix;
export const CANNED_QUESTION_EMAIL_ADDRESS = CannedQuestionName.email;
export const CANNED_QUESTION_HOME_PHONE = CannedQuestionName.homePhone;
export const CANNED_QUESTION_CELL_PHONE = CannedQuestionName.cellPhone;
export const CANNED_QUESTION_COMPANY = CannedQuestionName.company;
export const CANNED_QUESTION_NAME_TAX_RECEIPT =
    CannedQuestionName.nameTaxReceipt;
export const CANNED_QUESTION_INDIVIDUAL_TAX_ADDRESS =
    CannedQuestionName.individualTaxAddress;
export const CANNED_QUESTION_BUSINESS_TAX_ADDRESS =
    CannedQuestionName.businessTaxAddress;
export const CANNED_QUESTION_COMPANY_TAX = CannedQuestionName.companyTax;
export const CANNED_QUESTION_WORK_ADDRESS = CannedQuestionName.work;
export const CANNED_QUESTION_HOME_ADDRESS = CannedQuestionName.home;
export const CANNED_QUESTION_SHIPPING_ADDRESS = CannedQuestionName.ship;
export const CANNED_QUESTION_JOB_TITLE = CannedQuestionName.jobTitle;
export const CANNED_QUESTION_WORK_PHONE = CannedQuestionName.workPhone;
export const CANNED_QUESTION_WEBSITE = CannedQuestionName.website;
export const CANNED_QUESTION_BLOG = CannedQuestionName.blog;
export const CANNED_QUESTION_GENDER = CannedQuestionName.sex;
export const CANNED_QUESTION_BIRTH_DATE = CannedQuestionName.birthDate;
export const CANNED_QUESTION_AGE = CannedQuestionName.age;
export const CANNED_QUESTION_CREDIT_CARD_INFO = CannedQuestionName.cc;
export const CANNED_QUESTION_BILLING_ADDRESS = CannedQuestionName.bill;
export const CANNED_QUESTION_TAX_ID = CannedQuestionName.taxRegistrationId;
export const CANNED_QUESTION_TAX_INVOICE = CannedQuestionName.isBusiness;
export const CANNED_QUESTION_CONFIRM_EMAIL =
    CannedQuestionName.confirmEmailAddress;

export const QUESTION_TYPE_ADDRESS = QuestionType.address;
export const QUESTION_TYPE_CHECKBOX = QuestionType.checkbox;
export const QUESTION_TYPE_DATE = QuestionType.date;
export const QUESTION_TYPE_DROPDOWN = QuestionType.dropdown;
export const QUESTION_TYPE_EMAIL = QuestionType.email;
export const QUESTION_TYPE_NUMBER = QuestionType.number;
export const QUESTION_TYPE_PARAGRAPH = QuestionType.paragraph;
export const QUESTION_TYPE_PHONE = QuestionType.phone;
export const QUESTION_TYPE_RADIO = QuestionType.radio;
export const QUESTION_TYPE_TEXT = QuestionType.text;
export const QUESTION_TYPE_URL = QuestionType.url;
export const QUESTION_TYPE_WAIVER = QuestionType.waiver;

export const SEND_EMAIL_TO_ATTENDEES_FIELD = 'sendEmailToAttendees';
export const EB_MARKETING_OPT_IN_FIELD = 'ebMarketingOptIn';
export const ORG_MARKETING_OPT_IN_FIELD = 'organizationMarketingOptIn';
export const PAYMENT_FIELDS_GROUP = 'payment';

const ORGANIZER_MARKETING_OPT_IN_TEXT_WITH_NAME = (name: string) =>
    gettext(
        'Keep me updated on the latest news, events, and exclusive offers from %(name)s.',
        { name },
    );
const ORGANIZER_MARKETING_OPT_IN_TEXT_DEFAULT = gettext(
    'Keep me updated on the latest news, events, and exclusive offers from this event organizer.',
);
export const ORGANIZER_MARKETING_OPT_IN_TEXT = (name: string) =>
    name
        ? ORGANIZER_MARKETING_OPT_IN_TEXT_WITH_NAME(name)
        : ORGANIZER_MARKETING_OPT_IN_TEXT_DEFAULT;

export const AMOUNT_FIELD = 'amount';
export const PAY_WITH_VIRTUAL_INCENTIVES_FIELD = 'payWithVirtualIncentives';
export const CREDIT_CARD_NUMBER_FIELD = 'creditCardNumber';
export const CARD_EXPIRATION_DATE_FIELD = 'cardExpirationDate';
export const CARD_POSTAL_CODE_FIELD = 'cardPostalCode';
export const CARD_SECURITY_CODE_FIELD = 'cardSecurityCode';
export const SAVE_CREDIT_CARD_FIELD = 'saveCreditCard';
export const VAULT_ID_FIELD = 'vaultId';

export const PAYMENT_AMOUNT = getFieldName(PAYMENT_FIELDS_GROUP, AMOUNT_FIELD);
export const PAYMENT_PAY_WITH_VIRTUAL_INCENTIVES = getFieldName(
    PAYMENT_FIELDS_GROUP,
    PAY_WITH_VIRTUAL_INCENTIVES_FIELD,
);
export const PAYMENT_CREDIT_CARD_NUMBER = getFieldName(
    PAYMENT_FIELDS_GROUP,
    CREDIT_CARD_NUMBER_FIELD,
);
export const PAYMENT_CARD_EXPIRATION_DATE = getFieldName(
    PAYMENT_FIELDS_GROUP,
    CARD_EXPIRATION_DATE_FIELD,
);
export const PAYMENT_CARD_POSTAL_CODE = getFieldName(
    PAYMENT_FIELDS_GROUP,
    CARD_POSTAL_CODE_FIELD,
);
export const PAYMENT_CARD_SECURITY_CODE = getFieldName(
    PAYMENT_FIELDS_GROUP,
    CARD_SECURITY_CODE_FIELD,
);
export const PAYMENT_SAVE_CREDIT_CARD = getFieldName(
    PAYMENT_FIELDS_GROUP,
    SAVE_CREDIT_CARD_FIELD,
);
export const PAYMENT_VAULT_ID = getFieldName(
    PAYMENT_FIELDS_GROUP,
    VAULT_ID_FIELD,
);

export const BUYER_FIRST_NAME_FIELD = getFieldName(
    SELECTED_QUESTION_GROUP_BUYER,
    CANNED_QUESTION_FIRST_NAME,
);
export const BUYER_LAST_NAME_FIELD = getFieldName(
    SELECTED_QUESTION_GROUP_BUYER,
    CANNED_QUESTION_LAST_NAME,
);
export const BUYER_EMAIL_FIELD = getFieldName(
    SELECTED_QUESTION_GROUP_BUYER,
    CANNED_QUESTION_EMAIL_ADDRESS,
);
export const BUYER_CONFIRM_EMAIL_FIELD = getFieldName(
    SELECTED_QUESTION_GROUP_BUYER,
    CANNED_QUESTION_CONFIRM_EMAIL,
);
export const BUYER_TAX_INVOICE = getFieldName(
    SELECTED_QUESTION_GROUP_BUYER,
    CANNED_QUESTION_TAX_INVOICE,
);

export const BUYER_TAX_ID = getFieldName(
    SELECTED_QUESTION_GROUP_BUYER,
    CANNED_QUESTION_TAX_ID,
);

export const BUYER_BUSINESS_TAX_ADDRESS_COUNTRY = getFieldName(
    getFieldName(
        SELECTED_QUESTION_GROUP_BUYER,
        CANNED_QUESTION_BUSINESS_TAX_ADDRESS,
    ),
    'country',
);

export const QUESTION_GROUP_BUYER = 'questionsForBuyer';
export const QUESTION_GROUP_ATTENDEES = 'questionsForAttendees';

export const SURVEY_QUESTION_TYPES = [
    QUESTION_TYPE_ADDRESS,
    QUESTION_TYPE_CHECKBOX,
    QUESTION_TYPE_DATE,
    QUESTION_TYPE_DROPDOWN,
    QUESTION_TYPE_EMAIL,
    QUESTION_TYPE_NUMBER,
    QUESTION_TYPE_PARAGRAPH,
    QUESTION_TYPE_PHONE,
    QUESTION_TYPE_RADIO,
    QUESTION_TYPE_TEXT,
    QUESTION_TYPE_URL,
    QUESTION_TYPE_WAIVER,
];

export const COMPONENT_BY_QUESTION_TYPE = {
    [QUESTION_TYPE_ADDRESS]: AddressFields,
    [QUESTION_TYPE_CHECKBOX]: CheckboxGroup,
    [QUESTION_TYPE_DATE]: SelectField,
    [QUESTION_TYPE_DROPDOWN]: SelectField,
    [QUESTION_TYPE_EMAIL]: InputField,
    [QUESTION_TYPE_NUMBER]: InputField,
    [QUESTION_TYPE_PARAGRAPH]: InputField,
    [QUESTION_TYPE_PHONE]: InputField,
    [QUESTION_TYPE_RADIO]: Radio,
    [QUESTION_TYPE_TEXT]: InputField,
    [QUESTION_TYPE_URL]: InputField,
    [QUESTION_TYPE_WAIVER]: Checkbox,
};

export const TEXT_INPUT_TYPE_BY_QUESTION_TYPE = {
    [QUESTION_TYPE_EMAIL]: inputFieldConstants.TYPE_EMAIL,
    [QUESTION_TYPE_NUMBER]: inputFieldConstants.TYPE_NUMBER,
    [QUESTION_TYPE_PARAGRAPH]: inputFieldConstants.TYPE_TEXT,
    [QUESTION_TYPE_PHONE]: inputFieldConstants.TYPE_TEL,
    [QUESTION_TYPE_TEXT]: inputFieldConstants.TYPE_TEXT,
    [QUESTION_TYPE_URL]: inputFieldConstants.TYPE_URL,
};

export const PLACEHOLDER_BY_QUESTION_TYPE = {
    [QUESTION_TYPE_DATE]: gettext('Choose one'),
    [QUESTION_TYPE_DROPDOWN]: gettext('Choose one'),
    // Translators: This is a placeholder phone number in a form.
    [QUESTION_TYPE_PHONE]: gettext('(555) 555-5555'),
    [QUESTION_TYPE_URL]: 'https://',
};

export const ADDRESS_INPUTS_PREFIX_IDS = {
    [CANNED_QUESTION_SHIPPING_ADDRESS]: CANNED_QUESTION_SHIPPING_ADDRESS,
    [CANNED_QUESTION_HOME_ADDRESS]: CANNED_QUESTION_HOME_ADDRESS,
    [CANNED_QUESTION_WORK_ADDRESS]: CANNED_QUESTION_WORK_ADDRESS,
};

export const MONTHS = [
    { value: '1', display: gettext('January') },
    { value: '2', display: gettext('February') },
    { value: '3', display: gettext('March') },
    { value: '4', display: gettext('April') },
    { value: '5', display: gettext('May') },
    { value: '6', display: gettext('June') },
    { value: '7', display: gettext('July') },
    { value: '8', display: gettext('August') },
    { value: '9', display: gettext('September') },
    { value: '10', display: gettext('October') },
    { value: '11', display: gettext('November') },
    { value: '12', display: gettext('December') },
];

/**
 * EDS and Order Service API use slightly different names to identify
 * address subfields. Map those names to each other for use when
 * translating between the API and frontend components.
 */
export const API_ADDRESS_FIELD_TO_EDS_ADDRESS_FIELD = {
    address_1: 'address1',
    address_2: 'address2',
    city: 'city',
    region: 'region',
    country: 'country',
    postal_code: 'postal',
};

/**
 * The checkout page uses different names from the Order Service API
 * to identify the input fields in the Payment Info section. Map those
 * names to each other for use when translating between the API and
 * frontend components.
 */
export const API_PAYMENT_FIELD_TO_CHECKOUT_PAGE_PAYMENT_FIELD = {
    card_number: 'creditCardNumber',
    cvv: 'cardSecurityCode',
    expiration_date: 'cardExpirationDate',
};

export const API_SOURCE_INSTRUMENT_FIELD_TO_CHECKOUT_PAGE_TICKET_BUYER_FIELD = {
    first_name: CannedQuestionName.firstName,
    last_name: CannedQuestionName.lastName,
    email: CannedQuestionName.email,
};

/**
 * Return an array of options for the "day" field of the birthdate
 * question. It returns options for 1-31 regardless of the month,
 * as the backend will reject invalid dates.
 */
const getValidDaysForBirthdate = () =>
    range(1, 32).map((day) => ({
        value: `${day}`,
        display: `${day}`,
    }));

/**
 * Return an array of options for the "year" field of the birthdate
 * question. It returns an option starting with the year 1900 up to the current
 * year.
 */
const getValidYearsForBirthdate = () => {
    const currentYear = moment().year();

    return range(currentYear, currentYear - MAX_ATTENDEE_AGE).map((year) => ({
        value: `${year}`,
        display: `${year}`,
    }));
};

export const DATE_CHOICES_MAP = {
    month: MONTHS,
    day: getValidDaysForBirthdate(),
    year: getValidYearsForBirthdate(),
};

// Constants for typechecking
export const SELECTED_FORM_BUYER_VALUES_PROPS = PropTypes.object;
export const SELECTED_FORM_VALUES_PROPS = PropTypes.object;

export const RENDER_CONDITION_TRIGGER_PROPS = PropTypes.shape({
    choiceId: PropTypes.string,
    parentQuestionId: PropTypes.string,
});

export const SURVEY_QUESTION_CHOICES_SHAPE = PropTypes.arrayOf(
    PropTypes.shape({
        answer: PropTypes.object,
        id: PropTypes.string,
        quantityAvailable: PropTypes.node,
    }).isRequired,
);

export const SURVEY_QUESTION_SHAPE = PropTypes.shape({
    choices: SURVEY_QUESTION_CHOICES_SHAPE.isRequired,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    text: translationPropType.isRequired,
    type: PropTypes.oneOf(SURVEY_QUESTION_TYPES).isRequired,
    waiver: PropTypes.string,
    extraProps: PropTypes.object,
});

export const SURVEY_SHAPE = PropTypes.arrayOf(SURVEY_QUESTION_SHAPE);

export const ATTENDEE_SURVEYS_SHAPE = PropTypes.arrayOf(
    PropTypes.shape({
        id: PropTypes.string.isRequired,
        survey: PropTypes.arrayOf(SURVEY_QUESTION_SHAPE).isRequired,
        ticketClassName: PropTypes.string.isRequired,
    }),
);

export const DISABLED_FIELDS_PROP = PropTypes.shape({
    country: PropTypes.bool,
    address1: PropTypes.bool,
    address2: PropTypes.bool,
    city: PropTypes.bool,
    region: PropTypes.bool,
    postal: PropTypes.bool,
});

export const METHODS_OF_DELIVERY = {
    Print: 'Print at Home',
};

export const SURVEY_QUESTION_MAX_LENGTH_MAP = {
    [CANNED_QUESTION_AGE]: 3,
    [CANNED_QUESTION_FIRST_NAME]: 50,
    [CANNED_QUESTION_LAST_NAME]: 50,
    [CANNED_QUESTION_EMAIL_ADDRESS]: 254,
    [CANNED_QUESTION_PREFIX]: 10,
    [CANNED_QUESTION_SUFFIX]: 10,
    [CANNED_QUESTION_JOB_TITLE]: 50,
    [CANNED_QUESTION_COMPANY]: 80,
    [CANNED_QUESTION_GENDER]: 10,
    [CANNED_QUESTION_CELL_PHONE]: 20,
    [CANNED_QUESTION_HOME_PHONE]: 20,
    [CANNED_QUESTION_WORK_PHONE]: 20,
    [CANNED_QUESTION_WEBSITE]: 55,
    [CANNED_QUESTION_BLOG]: 55,
};

export const VALIDATION_ERRORS_MAP = {
    'N-email': gettext('Please enter a valid email address.'),
    'N-first_name': gettext('First name is required.'),
    'N-last_name': gettext('Last name is required.'),
    bankId: gettext('Please select your bank.'),
    ownerName: gettext("Account holder's name is required."),
    iban: gettext('A valid IBAN code is required.'),
    ibanAgreement: gettext('Agreement is required.'),
    identificationNumber: gettext('An identification number is required.'),
    identificationMethod: gettext('An identification method is required.'),
    'N-home_phone': gettext('Please enter a valid phone number.'),
    selectIssuer: gettext('An issuer is required.'),
    cardholder: gettext('A cardholder is required.'),
    confirmEmailAddress: gettext("Email address doesn't match"),
    cpf: gettext('Not a valid CPF.'),
    cnpj: gettext('Not a valid CNPJ.'),
    argentinaDni: gettext('Please enter a valid format.'),
    ci: gettext('Not a valid CI.'),
    lc: gettext('Not a valid LC.'),
    le: gettext('Not a valid LE.'),
};

export const DEFAULT_VALIDATION_ERROR = gettext(
    'Please provide a valid value for this field.',
);

export const INTERNAL_SERVER_ERROR = 'INTERNAL_ERROR';

// This const is use in partiallyIndexedQuestions to make a index on survey question.
export const PARENT_FIELD_MULTIPLIER = 100;

export const CHECKOUT_NATIVE_IOS = 'CHECKOUT/NATIVE/IOS';
export const CHECKOUT_NATIVE_ANDROID = 'CHECKOUT/NATIVE/ANDROID';
export const CHECKOUT_NATIVE_WEB = 'CHECKOUT/NATIVE/WEB';
export const CHECKOUT_NATIVE_ORDER_PLACED = 'OrderStatusPlaced';
export const CHECKOUT_NATIVE_ORDER_CANCELED = 'OrderStatusCanceled';
export const CHECKOUT_NATIVE_PAY_IN_PLACED = 'PayInStatusPlaced';
export const DefaultMobileCallBack = () => window.history.back();

export const COPY_DATA_FROM_ATTENDEE_SELECTOR_PREFIX =
    'copy-data-from-attendee-selector-';

/**
 * Checkout Order Eligibility Disqualifications
 */

export const ORDER_DISQUALIFICATIONS = {
    BELOW_MINIMUM_QUANTITY: 'below_qty_minimum',
    EXCEED_MAXIMUM_QUANTITY: 'exceed_qty_maximum',
    UNKNOWN: 'unknown',
};

export const ORDER_DISQUALIFICATIONS_FIELD_MAP = {
    BELOW_MINIMUM_QUANTITY: 'minimumQuantity',
};

export enum TaxExemptionStatus {
    init = 'INIT',
    verifying = 'VERIFYING',
    invalidTaxId = 'INVALID_TAX_ID',
    validTaxIdWithoutTaxExemption = 'VALID_TAX_ID_NO_TAX_EXEMPTION',
    validTaxIdAndTaxExemption = 'VALID_TAX_ID_AND_TAX_EXEMPTION',
}

export const TAX_EXEMPTION_DATA_SHAPE = PropTypes.shape({
    status: PropTypes.oneOf([
        TaxExemptionStatus.init,
        TaxExemptionStatus.verifying,
        TaxExemptionStatus.invalidTaxId,
        TaxExemptionStatus.validTaxIdWithoutTaxExemption,
        TaxExemptionStatus.validTaxIdAndTaxExemption,
    ]),
    hasTaxCountryExemptions: PropTypes.bool.isRequired,
});
