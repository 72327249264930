import React from 'react';

import { Icon } from '@eb/eds-icon';
import { Money } from '@eb/eds-iconography';

import gettext from '@eb/gettext';

import { REFUND_POLICY_CODE, terminology } from './constants';

import './refundPolicy.scss';

export interface RefundPolicyProperties {
    enableRefundFeeRetentionPolicy: boolean;
    refundPolicyCode: REFUND_POLICY_CODE;
}

export default class RefundPolicy extends React.Component<
    RefundPolicyProperties
> {
    render() {
        const {
            enableRefundFeeRetentionPolicy,
            refundPolicyCode = REFUND_POLICY_CODE.NOT_DEFINED,
        } = this.props;

        return (
            <div className="eds-l-mar-top-4 eds-text-bs refund-policy--container">
                <div className="eds-text-weight--heavy">
                    <Icon size="xsmall" type={<Money />} color="grey-600" />
                    <span className="eds-l-pad-left-1">
                        {gettext('Refund Policy')}
                    </span>
                </div>
                <div
                    className="eds-l-mar-top-2"
                    dangerouslySetInnerHTML={{
                        __html: terminology[refundPolicyCode],
                    }}
                />
                {enableRefundFeeRetentionPolicy ? (
                    <div>{gettext("Eventbrite's fee is nonrefundable.")}</div>
                ) : null}
            </div>
        );
    }
}
