export const postMessage = (
    messageName: string,
    eventId: string,
    widgetParentUrl: string,
    additionalData: object = {},
): Promise<void> => {
    // You would only not have a widgetParentUrl if you access /checkout-external directly,
    // which sometimes we want to do for testing
    if (widgetParentUrl) {
        window.parent.postMessage(
            {
                ...additionalData,
                messageName,
                eventId,
            },
            widgetParentUrl,
        );
    }

    return Promise.resolve();
};
