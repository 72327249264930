const COMMA_SPACE_MULTI_SPACE_REGEX = /(,(\s+)?|\s\s+)/g;

/**
 * Splits a string by commas and spaces, removing extra white space
 */
export const splitByCommaOrSpace = (str: string): Array<string> =>
    str
        // change all commas to spaces and trim all multispace to single space
        .replace(COMMA_SPACE_MULTI_SPACE_REGEX, ' ')
        // split on single space
        .split(' ')
        // filter empties
        .filter((s) => !!s);
