import PropTypes from 'prop-types';
import { translationPropType } from '@eb/i18n';
import { lazyGettext } from '@eb/lazy-gettext';

export const REGION_LABELS_BY_COUNTRY = {
    US: lazyGettext('State'),
    CA: lazyGettext('Province'),
    IE: lazyGettext('County'),
    AU: lazyGettext('State/Territory'),
    DEFAULT: lazyGettext('State/Province'),
};

export const POSTAL_LABELS_BY_COUNTRY = {
    US: lazyGettext('ZIP Code'),
    DEFAULT: lazyGettext('Postal Code'),
};

export const MAX_POSTAL_CODE_LENGTH = 10;

export const ADDRESS_VALUES_PROP = PropTypes.shape({
    country: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    region: PropTypes.string,
    postal: PropTypes.string,
});

export const DISABLED_FIELDS_PROP = PropTypes.shape({
    country: PropTypes.bool,
    address1: PropTypes.bool,
    address2: PropTypes.bool,
    city: PropTypes.bool,
    region: PropTypes.bool,
    postal: PropTypes.bool,
});

export const COUNTRY_VALUES_PROP = PropTypes.arrayOf(
    PropTypes.shape({
        value: PropTypes.string.isRequired,
        display: translationPropType.isRequired,
    }),
);

export const REGION_VALUES_PROP = PropTypes.arrayOf(
    PropTypes.shape({
        value: PropTypes.string.isRequired,
        display: translationPropType.isRequired,
    }),
);
