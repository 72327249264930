import {
    WAITLIST_ARGUMENT_ERROR_MESSAGE,
    WAITLIST_DEFAULT_ERROR_MESSAGE,
    WAITLIST_FULL_ERROR_MESSAGE,
} from '../constants';
import { fetchV3WithTranslateServerErrors } from './base';
import { getAddToWaitlistUrl } from './constants';

const WAITLIST_ERROR_SPEC = {
    default: () => WAITLIST_DEFAULT_ERROR_MESSAGE,
    ARGUMENTS_ERROR: () => WAITLIST_ARGUMENT_ERROR_MESSAGE,
    WAITLIST_FULL: () => WAITLIST_FULL_ERROR_MESSAGE,
};

export const addToWaitlist = (eventId, buyerData) => {
    const addToWaitlistEndpoint = getAddToWaitlistUrl(eventId);
    const { firstName, lastName, email, phone } = buyerData;

    return fetchV3WithTranslateServerErrors(
        WAITLIST_ERROR_SPEC,
        addToWaitlistEndpoint,
        {
            method: 'POST',
            body: JSON.stringify({
                first_name: firstName,
                last_name: lastName,
                email,
                home_phone: phone,
            }),
        },
    );
};
