// DISABLING ESLINT COMMENT RULES TO ENABLE WEBPACK MAGIC COMMENTS
/* eslint-disable no-inline-comments */
import React from 'react';
import { Route, IndexRoute } from 'react-router';
import { getLoaderComponent } from './components/RouteLoader';

import LoadingPage from './components/LoadingPage';
import Page from './containers/Page';
import StatusPage from './containers/status/StatusPage';
import TicketSelectionPage from './containers/ticketSelection/TicketSelectionPage';
import WaitingRoomPage from './containers/waitingRoom/WaitingRoomPage';
import { connectPage } from './containers/connectPage';
import { connectStatusPage } from './containers/status/connectStatusPage';
import { connectTicketSelectionPage } from './containers/ticketSelection/connectTicketSelectionPage';
import { connectWaitingRoomPage } from './containers/waitingRoom/connectWaitingRoomPage';
import {
    BASE_URL,
    CHECKOUT_PATH,
    DELIVERY_METHODS_PATH,
    EXTERNAL_PAYMENT_PATH,
    EXISTING_ORDER_PATH,
    GROUP_REG_CREATE_PATH,
    GROUP_REG_JOIN_PATH,
    GROUP_REG_TYPE_SELECTION_PATH,
    LOGIN_PATH,
    PAYMENTS_PATH,
    PAY_IN_PATH,
    PAY_IN_CONFIRMATION_PATH,
    PICK_A_SEAT_PATH,
    PROTECTED_PATH,
    PURCHASE_CONFIRMATION_PATH,
    RESEND_EMAIL_PATH,
    RESERVED_TICKETS_CONFIRMATION_PATH,
    RESERVED_TICKETS_PATH,
    SERIES_PATH,
    STATUS_PATH,
    TICKETS_WITH_CAPACITY_WAITLIST_PATH,
    TICKETS_WITH_TICKET_RESELLER_PATH,
    TICKETS_PATH,
    UNLOCK_TICKETS_PATH,
    WAITLIST_CONFIRMATION_PATH,
    WAITLIST_PATH,
    WAITING_ROOM_PATH,
} from './constants';

/**
 * This is a map of paths to route resolver function.
 * Paths defined on this object will be code-splitted from the main checkoutWidget bundle
 * and required on-demand when the route is requested.
 *
 * A webpackChunkName magic comment can be provided to define the file name that the code-splitted
 * bundle will have.
 */
const asyncPaths = {
    [CHECKOUT_PATH]: () =>
        import(
            /* webpackChunkName: "checkoutWidget-checkout"*/
            './routes/ConnectedCheckoutPage'
        ),
    [DELIVERY_METHODS_PATH]: () =>
        import(
            /* webpackChunkName: "checkoutWidget-delivery-methods"*/
            './routes/ConnectedDeliveryMethodForm'
        ),
    [EXISTING_ORDER_PATH]: () =>
        import(
            /* webpackChunkName: "checkoutWidget-existing-order"*/
            './routes/ConnectedExistingOrderPage'
        ),
    [EXTERNAL_PAYMENT_PATH]: () =>
        import(
            /* webpackChunkName: "checkoutWidget-external-payment"*/
            './routes/ConnectedExternalPaymentPage'
        ),
    [GROUP_REG_CREATE_PATH]: () =>
        import(
            /* webpackChunkName: "group-registration-create"*/
            './routes/ConnectedGroupRegCreatePage'
        ),
    [GROUP_REG_JOIN_PATH]: () =>
        import(
            /* webpackChunkName: "group-registration-join"*/
            './routes/ConnectedGroupRegJoinPage'
        ),
    [GROUP_REG_TYPE_SELECTION_PATH]: () =>
        import(
            /* webpackChunkName: "checkoutWidget-group-reg-type-selection"*/
            './routes/ConnectedGroupRegTypeSelectionPage'
        ),
    [LOGIN_PATH]: () =>
        import(
            /* webpackChunkName: "checkoutWidget-login"*/
            './routes/ConnectedLoginPage'
        ),
    [PAYMENTS_PATH]: () =>
        import(
            /* webpackChunkName: "checkoutWidget-payments"*/
            './routes/ConnectedPaymentsPage'
        ),
    [PICK_A_SEAT_PATH]: () =>
        import(
            /* webpackChunkName: "checkoutWidget-pick-a-seat"*/
            './routes/ConnectedPickASeatPage'
        ),
    [PROTECTED_PATH]: () =>
        import(
            /* webpackChunkName: "checkoutWidget-protected"*/
            './routes/ConnectedProtectedEventPage'
        ),
    [PURCHASE_CONFIRMATION_PATH]: () =>
        import(
            /* webpackChunkName: "checkoutWidget-purchase-confirmation"*/
            './routes/ConnectedPurchaseConfirmationPage'
        ),
    [RESEND_EMAIL_PATH]: () =>
        import(
            /* webpackChunkName: "checkoutWidget-resend-email"*/
            './routes/ConnectedResendEmailForm'
        ),
    [RESERVED_TICKETS_CONFIRMATION_PATH]: () =>
        import(
            /* webpackChunkName: "checkoutWidget-reserved-tickets-confirmation"*/
            './routes/ConnectedReservedTicketsConfirmationPage'
        ),
    [RESERVED_TICKETS_PATH]: () =>
        import(
            /* webpackChunkName: "checkoutWidget-reserved-ticket-selection"*/
            './routes/ConnectedReservedTicketSelectionPage'
        ),
    [SERIES_PATH]: () =>
        import(
            /* webpackChunkName: "checkoutWidget-series" */
            './routes/ConnectedSeriesPage'
        ),
    [TICKETS_WITH_CAPACITY_WAITLIST_PATH]: () =>
        import(
            /* webpackChunkName: "checkoutWidget-tickets-with-capacity-waitlist"*/
            './routes/ConnectedEventCapacityWaitlistTicketsPage'
        ),
    [TICKETS_WITH_TICKET_RESELLER_PATH]: () =>
        import(
            /* webpackChunkName: "checkoutWidget-event-ticket-reseller"*/
            './routes/ConnectedEventTicketResellerPage'
        ),
    [UNLOCK_TICKETS_PATH]: () =>
        import(
            /* webpackChunkName: "checkoutWidget-unlock-tickets"*/
            './routes/ConnectedUnlockTicketsPage'
        ),
    [WAITLIST_PATH]: () =>
        import(
            /* webpackChunkName: "checkoutWidget-waitlist"*/
            './routes/ConnectedWaitlistPage'
        ),
    [WAITLIST_CONFIRMATION_PATH]: () =>
        import(
            /* webpackChunkName: "checkoutWidget-waitlist-confirmation"*/
            './routes/ConnnectedWaitlistConfirmationPage'
        ),
    [PAY_IN_PATH]: () =>
        import(
            /* webpackChunkName: "checkoutWidget-pay-in"*/
            './routes/ConnectedPayInPage'
        ),
    [PAY_IN_CONFIRMATION_PATH]: () =>
        import(
            /* webpackChunkName: "checkoutWidget-pay-in-confirmation"*/
            './routes/ConnectedPayInConfirmationPage'
        ),
};

const getRoutes = () => {
    const ConnectedPage = connectPage(Page);
    const ConnectedStatusPage = connectStatusPage(StatusPage);
    const ConnectedTicketsPage = connectTicketSelectionPage(
        TicketSelectionPage,
    );
    const ConnectedWaitingRoom = connectWaitingRoomPage(WaitingRoomPage);

    /**
     * Routes defined directly under the main Route will be bundled directly on the checkoutWidget
     * main bundle.
     * Routes declared as asyncPaths will each have it's own bundle that will be loaded on demand.
     * This is done at build time by webpack.
     *
     * For better js load performance most routes should be code-splitted.
     * The most common criteria to NOT code-split a path is that such path is
     * VERY likely to be the landing route for MOST events.
     */
    return (
        <Route path={BASE_URL} component={ConnectedPage}>
            <IndexRoute component={LoadingPage} />
            <Route path={WAITING_ROOM_PATH} component={ConnectedWaitingRoom} />
            <Route path={TICKETS_PATH} component={ConnectedTicketsPage} />
            <Route
                path={`${STATUS_PATH}/:statusType`}
                component={ConnectedStatusPage}
            />
            {Object.entries(asyncPaths).map(([path, loader]) => (
                <Route
                    key={path}
                    path={path}
                    component={getLoaderComponent(loader)}
                />
            ))}
        </Route>
    );
};

export default getRoutes;
