import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import includes from 'lodash/includes';
import { translationPropType } from '@eb/i18n';
import interpolate from '@eb/interpolate-lazy-string';
import {
    SOLD_OUT_STATUS,
    SALES_ENDED_STATUS,
    UNAVAILABLE_STATUS,
    TICKET_STATUS_MAP,
    STATUSKEY_PROP_TYPE,
} from './constants';

import {
    ON_SALE_TEXT,
    SALES_END_SOON_TEXT,
    SALES_NOT_STARTED_TEXT,
    SALES_START_SOON_TEXT,
} from '../../containers/ticketSelection/constants';

TICKET_STATUS_MAP.onSale.displayText = ON_SALE_TEXT;
TICKET_STATUS_MAP.salesNotStarted.displayText = SALES_NOT_STARTED_TEXT;
TICKET_STATUS_MAP.salesEndSoon.displayText = SALES_END_SOON_TEXT;
TICKET_STATUS_MAP.salesStartSoon.displayText = SALES_START_SOON_TEXT;

export const STATUS_TEXT_SIZE = {
    LARGE: 'large',
    MEDIUM: 'medium',
    SMALL: 'small',
};

export default class TicketCardStatus extends React.PureComponent {
    static defaultProps = {
        textSize: STATUS_TEXT_SIZE.MEDIUM,
    };

    static propTypes = {
        textSize: PropTypes.oneOf([
            STATUS_TEXT_SIZE.LARGE,
            STATUS_TEXT_SIZE.MEDIUM,
            STATUS_TEXT_SIZE.SMALL,
        ]),
        /**
         * This is a necessary flag for cases where the tickets may have a status causing elements to appear
         * or disappear but the status itself should not be displayed. An example of this is event-wide
         * sales not started or sales ended. Each ticket should not display it individually, but no select
         * should appear.
         **/
        shouldHideStatus: PropTypes.bool,
        /**
         * A key tied to the ticketStatus map located in ticketStatusConstants.js. If nothing is passed in, then
         * no status will be displayed. Otherwise will render the associated status and also hide the
         * appropriate components as well.
         **/
        statusKey: STATUSKEY_PROP_TYPE,
        /**
         * The end datetime for ticket sales to be shown. Only required when certain statuses need
         * to be rendered.
         **/
        salesEnd: translationPropType,
        /**
         * The start datetime for ticket sales. Only required when rendering a status that needs the
         * date.
         **/
        salesStart: translationPropType,

        /**
         * If this ticket is tiered. It should not show salesStart date when status is not yet
         * started.
         **/
        isTicketTiered: PropTypes.bool,
    };

    render() {
        const {
            customClassNames,
            shouldHideStatus,
            statusKey,
            salesEnd,
            salesStart,
            textSize,
        } = this.props;
        let ticketStatusComponent = null;

        if (!shouldHideStatus && statusKey) {
            const statusInfo = TICKET_STATUS_MAP[statusKey];
            const ticketStatusClassNames = classNames(
                'ticket-status',
                'eds-text-color--ui-600',
                {
                    'eds-text-bl': textSize === STATUS_TEXT_SIZE.LARGE,
                    'eds-text-bm': textSize === STATUS_TEXT_SIZE.MEDIUM,
                    'eds-text-bs': textSize === STATUS_TEXT_SIZE.SMALL,
                    'ticket-status--no-wrap': includes(
                        [
                            SALES_ENDED_STATUS,
                            SOLD_OUT_STATUS,
                            UNAVAILABLE_STATUS,
                        ],
                        statusKey,
                    ),
                },
                customClassNames,
            );

            let date = '';

            if (statusInfo.datePropKey) {
                date =
                    statusInfo.datePropKey === 'salesEnd'
                        ? salesEnd
                        : salesStart;
            }

            ticketStatusComponent = (
                <span
                    className={ticketStatusClassNames}
                    data-spec="eds-ticket-card-content-status"
                >
                    {interpolate(
                        statusInfo.displayText.toString(),
                        { date },
                        true,
                    )}
                </span>
            );
        }

        return ticketStatusComponent;
    }
}
