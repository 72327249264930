import { arrayOf, Schema } from 'normalizr';
import { transformUtil, deepKeysToCamel } from '@eb/transformation-utils';
import isEmpty from 'lodash/isEmpty';

import { TEAM_SETTINGS_SERVER_ERRORS } from '../../containers/groupRegistration/terminology';

const TEAM_SETTINGS_SCHEMA = new Schema('teamSettings');

const TEAM_SETTINGS_RESPONSE_FIELDS = [
    'has_password',
    'id',
    'is_full',
    'name',
    'spots_left',
    'token',
    'team_url',
    'preferred_start_time',
];

export const transformTeamSettingsData = (data) => {
    const transformedTeamSettings = transformUtil({
        response: data,
        schema: arrayOf(TEAM_SETTINGS_SCHEMA),
        fields: TEAM_SETTINGS_RESPONSE_FIELDS,
        isWhiteList: true,
    });
    const teamSettings = transformedTeamSettings.result.map(
        (result) => transformedTeamSettings.entities.teamSettings[result],
    );

    return teamSettings;
};

export const transformTeamSettingsError = (data) => {
    const tranformedErrorKeys = deepKeysToCamel(data);
    const transformTeamSettingsError = {};

    Object.keys(tranformedErrorKeys).forEach((errorField) => {
        transformTeamSettingsError[errorField] =
            !isEmpty(tranformedErrorKeys[errorField]) &&
            TEAM_SETTINGS_SERVER_ERRORS[errorField];
    });

    return transformTeamSettingsError;
};
