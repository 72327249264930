import React, { PureComponent } from 'react';
import { LoadingOverlay } from '@eb/eds-loading-overlay';

/**
 * This component serves as a placeholder while a final component
 * to be displayed gets resolved (usually from an `import()` call promise).
 *
 * Upon being mounted, usually as part of a Route resolution, it will trigger the
 * `resolver` callback to initiate the component request and then render a loading screen.
 *
 * Once the `resolver` promise is finished it will pickup the retrieved component and render it.
 */
export class RouteLoader extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            Component: null,
        };
    }

    async componentWillMount() {
        if (!this.state.Component) {
            const { default: Component } = await this.props.resolver();

            this.setState({ Component });
        }
    }

    render() {
        const { Component } = this.state;

        if (Component) {
            return <Component />;
        }

        return <LoadingOverlay isShown={true} />;
    }
}

/**
 * Generates a RouteLoader given a component `resolver` function,
 * useful for generating the `component` param in a `Route`.
 *
 * @param {function} resolver The component resolver function, usually returs the prise generated by `import()` calls.
 */
export const getLoaderComponent = (resolver) =>
    function RouteLoaderWrapper() {
        return <RouteLoader resolver={resolver} />;
    };
