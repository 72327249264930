import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';
import values from 'lodash/values';
import some from 'lodash/some';
import {
    INITIALIZE_TICKETS,
    QUANTITY_CHANGE,
    DONATION_CHANGE,
    VARIANT_CHANGE,
    UPDATE_DONATION_FEE_AND_TAX,
    UPDATE_SELECTED_PAYMENT_CONSTRAINTS,
} from '../actions/tickets';
import {
    APPLY_PROMO_CODE,
    REMOVE_PROMO_CODE,
    UNLOCK_TICKETS,
} from '../actions/promoCode';
import {
    addMissingFieldsToState,
    getDonationTicketFeeAndTax,
    getTicketWithUpdatedSelectedQuantity,
    getTicketWithUpdatedDonationAmount,
    getUpdatedTickets,
    getTicketsWithDiscount,
    getTicketsByIdWithUpdatedMaxQuantity,
    getTotalRemainingCapacity,
    isAddOnTicket,
    getTicketWithUpdatedVariants,
} from '../containers/ticketSelection/utils/ticketUtils';
import { PROMO_CODE_TYPES } from '../constants';
import {
    isDonationTicket,
    initializePaymentConstraints,
} from '../utils/tickets';
import { getPromoCodeInformation } from '../utils/promoCodes';

const _initializeTicketsReducer = (state = {}, action) => {
    const { tickets, ticketIds } = action.payload;
    const ticketsById = addMissingFieldsToState(tickets, action.error);
    const {
        constrainedInstrumentType,
        constrainedPaymentMethods,
        constrainingTicketIds,
    } = initializePaymentConstraints({ ticketIds, ticketsById });

    return {
        ...state,
        ticketsById,
        ticketIds,
        constrainedInstrumentType,
        constrainedPaymentMethods,
        constrainingTicketIds,
    };
};

const _quantityChangeReducer = (state = {}, action) => {
    const {
        id,
        variantId,
        discountId,
        quantity,
        remainingCapacity,
    } = action.payload;
    const ticketIds = action.payload.ticketIds || state.ticketIds;
    const { [id]: selectedTicket, ...nonSelectedTickets } = state.ticketsById;
    const variantOrDiscountId = variantId || discountId;
    const updatedSelectedTicket = getTicketWithUpdatedSelectedQuantity(
        selectedTicket,
        quantity,
        variantOrDiscountId,
    );
    const ticketsThatCountAgainstCapacity = omitBy(
        nonSelectedTickets,
        isAddOnTicket,
    );

    /**
     * When a a ticket that counts against capacity is selected, updating the maximum allowed quantities
     * available for each ticket should not affect non-admission inventory (ie Add-ons). Therefore
     * we only run the necessary quantity update transforms against tickets that count against capacity.
     *
     * Admission
     * able tickets currently may be one of admission, donation, or free.
     */
    if (!isAddOnTicket(selectedTicket)) {
        const ticketsWithUpdated = {
            ...ticketsThatCountAgainstCapacity,
            [id]: updatedSelectedTicket,
        };
        const totalRemainingCapacity = getTotalRemainingCapacity(
            remainingCapacity,
            ticketsWithUpdated,
        );
        const updatedTicketsThatCountAgainstCapacity = getTicketsByIdWithUpdatedMaxQuantity(
            ticketsWithUpdated,
            totalRemainingCapacity,
        );

        return {
            ...state,
            ticketsById: {
                ...state.ticketsById,
                ...updatedTicketsThatCountAgainstCapacity,
                [id]: updatedSelectedTicket,
            },
            ticketIds,
        };
    }

    return {
        ...state,
        ticketsById: {
            ...state.ticketsById,
            [id]: updatedSelectedTicket,
        },
        ticketIds,
    };
};

const _donationChangeReducer = (state = {}, action) => {
    const {
        id,
        variantId,
        amount,
        currencyFormat,
        remainingCapacity,
    } = action.payload;
    const ticketIds = action.payload.ticketIds || state.ticketIds;
    const { [id]: selectedTicket, ...nonSelectedTickets } = state.ticketsById;

    let updatedSelectedTicket;

    const { variants } = selectedTicket;

    if (isDonationTicket(selectedTicket)) {
        updatedSelectedTicket = getTicketWithUpdatedDonationAmount(
            selectedTicket,
            amount,
            currencyFormat,
        );
    } else if (variantId) {
        updatedSelectedTicket = {
            ...selectedTicket,
            variants: variants.map((variant) =>
                variant.id === variantId
                    ? getTicketWithUpdatedDonationAmount(
                          variant,
                          amount,
                          currencyFormat,
                      )
                    : variant,
            ),
        };
    }
    /**
     * When a a ticket that counts against capacity is selected, updating the maximum allowed quantities
     * available for each ticket should not affect non-admission inventory (ie Add-ons). Therefore
     * we only run the necessary quantity update transforms against tickets that count against capacity.
     */
    const ticketsThatCountAgainstCapacity = omitBy(
        nonSelectedTickets,
        isAddOnTicket,
    );
    const ticketsWithUpdated = {
        ...ticketsThatCountAgainstCapacity,
        [id]: updatedSelectedTicket,
    };
    const totalRemainingCapacity = getTotalRemainingCapacity(
        remainingCapacity,
        ticketsWithUpdated,
    );
    const updatedTicketsThatCountAgainstCapacity = getTicketsByIdWithUpdatedMaxQuantity(
        ticketsWithUpdated,
        totalRemainingCapacity,
    );

    return {
        ...state,
        ticketsById: {
            ...state.ticketsById,
            ...updatedTicketsThatCountAgainstCapacity,
            [id]: updatedSelectedTicket,
        },
        ticketIds,
    };
};

const _variantChangeReducer = (state = {}, action) => {
    const { id: ticketId } = action.payload;
    const { ticketsById } = state;
    const { [ticketId]: ticket } = ticketsById;
    const ticketWithUpdatedVariants = getTicketWithUpdatedVariants(
        ticket,
        action,
    );

    return {
        ...state,
        ticketsById: {
            ...ticketsById,
            [ticketWithUpdatedVariants.id]: ticketWithUpdatedVariants,
        },
    };
};

const _getVariantsWithFeeAndTaxFromAttendee = (
    variants,
    attendee,
    currencyFormat,
) => {
    const variantId = attendee['variant_id'];

    if (!variantId) {
        return variants;
    }

    const updatedVariantsWithFeeAndTax = variants.map((variant) => {
        if (variant.id === variantId) {
            return {
                ...variant,
                ...getDonationTicketFeeAndTax(
                    attendee,
                    currencyFormat,
                    variant,
                ),
            };
        }
        return variant;
    });

    return updatedVariantsWithFeeAndTax;
};

/* Update the fee and tax with the create order api response. */
const _updateDonationFeeAndTaxReducer = (state = {}, action) => {
    const { attendees, currencyFormat } = action.payload;
    const ticketsById = { ...state.ticketsById };

    attendees.forEach((attendee) => {
        const ticketId = attendee['ticket_class_id'];
        const ticket = ticketsById[ticketId];

        const ticketHasDonationVariant = values(
            ticketsById,
        ).find(({ variants = [] }) =>
            some(variants, (variant) => variant.id === attendee['variant_id']),
        );

        if (!ticket && ticketHasDonationVariant) {
            ticketsById[ticketHasDonationVariant.id] = {
                ...ticketHasDonationVariant,
                variants: _getVariantsWithFeeAndTaxFromAttendee(
                    ticketHasDonationVariant.variants,
                    attendee,
                    currencyFormat,
                ),
            };
        } else if (isDonationTicket(ticket)) {
            ticketsById[ticketId] = {
                ...ticket,
                ...getDonationTicketFeeAndTax(attendee, currencyFormat, ticket),
            };
        }
    });

    return {
        ...state,
        ticketsById,
    };
};

const _applyPromoCodeReducer = (state = {}, action) => {
    const { constrainedPaymentMethods, ticketsById } = state;
    const { tickets, ticketIds, remainingCapacity } = action.payload;
    const updatedTickets = getUpdatedTickets(ticketIds, tickets, ticketsById);
    const ticketsWithMissingFields = addMissingFieldsToState(updatedTickets);

    /**
     * When a ticket that counts against capacity is selected, updating the maximum allowed quantities
     * available for each ticket should not affect non-admission inventory (ie Add-ons). Therefore
     * we only run the necessary quantity update transforms against tickets that count against capacity.
     */
    const ticketsThatCountAgainstCapacity = omitBy(
        ticketsWithMissingFields,
        isAddOnTicket,
    );
    const totalRemainingCapacity = getTotalRemainingCapacity(
        remainingCapacity,
        ticketsThatCountAgainstCapacity,
    );
    const updatedTicketsById = getTicketsByIdWithUpdatedMaxQuantity(
        ticketsThatCountAgainstCapacity,
        totalRemainingCapacity,
    );
    const paymentConstraintConflicts = {};

    Object.values(updatedTicketsById).forEach((ticket) => {
        const conflictCheck =
            !isEmpty(ticket.paymentConstraints) &&
            !isEmpty(constrainedPaymentMethods) &&
            !ticket.paymentConstraints.some(({ paymentMethod }) =>
                constrainedPaymentMethods.includes(paymentMethod),
            );

        if (!ticket.isDisabled && conflictCheck) {
            paymentConstraintConflicts[ticket.id] = {
                ...ticket,
                isDisabled: true,
                conflictingPayment: true,
            };
        }
    });

    return {
        ...state,
        ticketsById: {
            ...ticketsWithMissingFields,
            ...updatedTicketsById,
            ...paymentConstraintConflicts,
        },
        ticketIds,
    };
};

const _removePromoCodeReducer = (state = {}, action) => {
    const { ticketsById } = state;
    const { tickets, ticketIds, remainingCapacity } = action.payload;
    const ticketsHavePromoCode = getTicketsWithDiscount(ticketsById);

    // getUpdatedTickets handles removing the promo code from reserved seating variants
    const updatedTickets = addMissingFieldsToState(
        getUpdatedTickets(ticketIds, tickets, ticketsById),
    );
    const totalRemainingCapacity = getTotalRemainingCapacity(
        remainingCapacity,
        updatedTickets,
    );
    const ticketsByIdWithUpdatedMaxQuantity = getTicketsByIdWithUpdatedMaxQuantity(
        updatedTickets,
        totalRemainingCapacity,
    );
    let promoType;
    let updatedTicketsById = ticketsByIdWithUpdatedMaxQuantity;

    if (!isEmpty(ticketsHavePromoCode)) {
        // Determine the type (discount vs. access) of removed promo code
        promoType = getPromoCodeInformation(ticketsById).discountType;
    }

    if (promoType && promoType === PROMO_CODE_TYPES.DISCOUNT) {
        /**
         * When a ticket that counts against capacity is selected, updating the maximum allowed quantities
         * available for each ticket should not affect non-admission inventory (ie Add-ons). Therefore
         * we only run the necessary quantity update transforms against tickets that count against capacity.
         */
        const ticketsThatCountAgainstCapacity = omitBy(
            updatedTickets,
            isAddOnTicket,
        );
        const totalRemainingCapacity = getTotalRemainingCapacity(
            remainingCapacity,
            ticketsThatCountAgainstCapacity,
        );
        const updatedTicketsWithMaxQuantity = getTicketsByIdWithUpdatedMaxQuantity(
            ticketsThatCountAgainstCapacity,
            totalRemainingCapacity,
        );

        updatedTicketsById = {
            ...ticketsByIdWithUpdatedMaxQuantity,
            ...updatedTickets,
            ...updatedTicketsWithMaxQuantity,
        };
    }

    return {
        ...state,
        ticketIds,
        ticketsById: updatedTicketsById,
    };
};

const _updateSelectedPaymentConstraintsReducer = (state = {}, action) => {
    const {
        constrainedInstrumentType,
        constrainedPaymentMethods,
        constrainingTicketIds,
        ticketsById,
    } = action.payload;

    return {
        ...state,
        ticketsById: {
            ...state.ticketsById,
            ...ticketsById,
        },
        constrainedInstrumentType,
        constrainedPaymentMethods,
        constrainingTicketIds,
    };
};

const ACTION_REDUCER_MAP = {
    [INITIALIZE_TICKETS]: _initializeTicketsReducer,
    [QUANTITY_CHANGE]: _quantityChangeReducer,
    [DONATION_CHANGE]: _donationChangeReducer,
    [VARIANT_CHANGE]: _variantChangeReducer,
    [APPLY_PROMO_CODE]: _applyPromoCodeReducer,
    [REMOVE_PROMO_CODE]: _removePromoCodeReducer,
    // TODO: unify UNLOCK_TICKTS / INITIALIZE
    [UNLOCK_TICKETS]: _initializeTicketsReducer,
    [UPDATE_DONATION_FEE_AND_TAX]: _updateDonationFeeAndTaxReducer,
    [UPDATE_SELECTED_PAYMENT_CONSTRAINTS]: _updateSelectedPaymentConstraintsReducer,
};

export const ticketsReducer = (state = {}, action) => {
    const reducer = ACTION_REDUCER_MAP[action.type];
    let nextState = { ...state };

    if (reducer) {
        nextState = reducer(state, action);
    }

    return nextState;
};
