import { createSelector } from './utils/selector';

if (typeof window !== 'undefined') {
    require('../utils/vendor/square_analytics');
}

export const getSquareAnalytics = createSelector(
    (state) => state.app.squareMarketAccount,
    (state) => state.app.squareApiHost,
    (squareMarketAccount, squareApiHost) =>
        new window.Square.Analytics(squareMarketAccount, squareApiHost),
);
