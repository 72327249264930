import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { translationPropType } from '@eb/i18n';
import { SelectField } from '@eb/eds-input-field';
import { STYLE_BASIC } from '@eb/eds-input-field';
import { getSelectPropValues } from '@eb/eds-select';
import gettext from '@eb/gettext';
import './ticketQuantitySelector.scss';

export default class TicketQuantitySelector extends React.PureComponent {
    static propTypes = {
        /**
         * ID of the ticket to be passed up along with the selected value in the callback.
         * Is required for data-automation tags.
         **/
        id: PropTypes.string.isRequired,
        /**
         * The name of the ticket to be displayed
         **/
        name: translationPropType.isRequired,

        /**
         * The currently selected quantity of the ticket. Should represent current state of the
         * select box.
         **/
        selectedQuantity: PropTypes.number,
        /**
         * Whether ticket select dropdown should be disabled or not. Dropdown is enabled by default
         **/
        isDisabled: PropTypes.bool,
        /**
         * Dispatches action to change quantity
         **/
        onQuantityChange: PropTypes.func,
        /**
         * The range to be converted to selection options. This array is computed by the backend
         * which incorporates mininum and maximum values set on the ticket level by the creator,
         * as well as other inputs like waitlist data. If additional behavior is required make sure
         * to add to the appropriate methods in Ticket Availability Service, eg.
         * get_ticket_options_range()
         */
        ticketOptionsRange: PropTypes.arrayOf(PropTypes.number).isRequired,
    };

    render() {
        const {
            id,
            name,
            selectedQuantity,
            onQuantityChange,
            isDisabled,
            ticketOptionsRange,
        } = this.props;

        if (isEmpty(ticketOptionsRange)) {
            return null;
        }
        const formattedId = `ticket-quantity-selector-${id}`;
        const labelText = gettext('Select quantity: ') + name;
        const values = getSelectPropValues(ticketOptionsRange);

        return (
            <div className="eds-ticket-card-content__quantity-selector eds-l-sn-mar-left-3 eds-l-mar-left-6 eds-l-mar-top-1">
                <SelectField
                    id={formattedId}
                    values={values}
                    defaultValue={`${selectedQuantity}`}
                    value={`${selectedQuantity}`}
                    name="ticket-quantity-selector"
                    label={labelText}
                    onChange={onQuantityChange}
                    disabled={isDisabled}
                    data-automation={formattedId}
                    data-spec="ticket-quantity-selector"
                    style={STYLE_BASIC}
                    bottomSpacing={0}
                />
            </div>
        );
    }
}
