import React from 'react';
import classNames from 'classnames';
import isNull from 'lodash/isNull';

import { CollapsiblePaneLayout } from '@eb/eds-modal';
import { ModalBody } from '@eb/eds-modal';
import CartSummary from '../../components/CartSummary';
import ReservedPane from '../pane/ReservedPane';
import Pane from '../pane/Pane';
import { connectPane } from '../pane/connectPane';
import { getDisplayClasses } from '../../utils/displayClasses';

import './pageTemplate.scss';

const ConnectedPaneComponent = connectPane(Pane);
const ConnectedReservedPaneComponent = connectPane(ReservedPane);

const PageTemplate = ({
    children,
    containerClassName,
    ctaText,
    extraModalBodyProps,
    extraPaneProps,
    headerActionIconType,
    isPaneOpen,
    isSubmitDisabled,
    handleSubmit,
    hasTopMargin = true,
    hasHorizontalGutters = true,
    headerActionTitle,
    notificationOptions,
    onPaneToggle,
    onHeaderAction,
    secondaryTitle,
    templateProps,
    fullWidthTopContent,
    title,
    dataSpec,
    tertiaryNode,
}) => {
    const {
        isReservedSeating,
        totalTicketQuantity,
        totalCostDisplay,
        isSubmitting,
    } = templateProps;
    const PaneComponent = isReservedSeating
        ? ConnectedReservedPaneComponent
        : ConnectedPaneComponent;
    const containerClasses = hasHorizontalGutters
        ? classNames(getDisplayClasses({ hasTopMargin }), containerClassName)
        : containerClassName;
    const __footerContainerClassName = classNames(
        getDisplayClasses(),
        'modal-footer',
    );

    return (
        <CollapsiblePaneLayout
            pane={
                <PaneComponent
                    {...extraPaneProps}
                    ticketQuantity={totalTicketQuantity}
                />
            }
            content={
                <ModalBody
                    title={title}
                    secondaryTitle={secondaryTitle}
                    primaryType="fill"
                    primaryText={ctaText}
                    primaryIsDisabled={isSubmitDisabled}
                    primaryIsLoading={isSubmitting}
                    onPrimaryAction={handleSubmit}
                    notificationOptions={notificationOptions}
                    tertiaryNode={
                        tertiaryNode || (
                            <CartSummary
                                totalCost={totalCostDisplay}
                                ticketQuantity={totalTicketQuantity}
                                isPaneOpen={isPaneOpen}
                                onClick={onPaneToggle}
                            />
                        )
                    }
                    headerActionIconType={headerActionIconType}
                    headerActionTitle={headerActionTitle}
                    onHeaderAction={onHeaderAction}
                    __footerContainerClassName={__footerContainerClassName}
                    {...extraModalBodyProps}
                >
                    {fullWidthTopContent}
                    <div className={containerClasses} data-spec={dataSpec}>
                        {children}
                    </div>
                </ModalBody>
            }
            isPaneOpen={isPaneOpen}
        />
    );
};

export default PageTemplate;
