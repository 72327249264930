import React from 'react';
import PropTypes from 'prop-types';
import lodashIsString from 'lodash/isString';
import debounce from 'lodash/debounce';
import classNames from 'classnames';
import gettext from '@eb/gettext';
import { Button } from '@eb/eds-button';
import { Icon } from '@eb/eds-icon';

import * as constants from './constants';

import './readMore.scss';

import { ChevronDownChunky } from '@eb/eds-iconography';
import { ChevronUpChunky } from '@eb/eds-iconography';

const DEFAULT_COLLAPSED_LINK_TEXT = (
    <div>
        {gettext('Show Details')}
        <Icon type={<ChevronDownChunky />} size="small" />
    </div>
);

const DEFAULT_EXPANDED_LINK_TEXT = (
    <div>
        {gettext('Hide Details')}
        <Icon type={<ChevronUpChunky />} size="small" />
    </div>
);

const BASE_CSS_CLASSNAME = 'eds-read-more';

export default class ReadMore extends React.PureComponent {
    static propTypes = {
        /**
         * The content to be displayed or hidden
         **/
        children: PropTypes.node.isRequired,
        /**
         * The display text of the link when collapsed
         **/
        collapsedLinkText: PropTypes.node,
        /**
         * The display text of the link when expanded
         **/
        expandedLinkText: PropTypes.node,
        /**
         * The amount of the content to display when in collapsed state.
         **/
        collapsedDisplayAmount: PropTypes.oneOf(constants.DISPLAY_AMOUNT_TYPES),
        /**
         * Default state for if initial state should be expanded.
         **/
        defaultIsExpanded: PropTypes.bool,
        /**
         * Callback that is called when the toggle occurs passing along the isExpanded state (bool)
         **/
        onToggleExpanded: PropTypes.func,
        /**
         * Option to set location of the collapse/expand toggle
         **/
        toggleLocation: PropTypes.oneOf(constants.TOGGLE_LOCATION_TYPES),
    };

    static defaultProps = {
        collapsedLinkText: DEFAULT_COLLAPSED_LINK_TEXT,
        expandedLinkText: DEFAULT_EXPANDED_LINK_TEXT,
        collapsedDisplayAmount: constants.DISPLAY_AMOUNT_NONE,
        defaultIsExpanded: false,
        toggleLocation: constants.TOGGLE_LOCATION_TOP,
    };

    constructor(props) {
        super(props);

        this.state = {
            isExpanded: props.defaultIsExpanded,
            shouldTruncateContent: false,
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleTruncateOnWindowResize);

        this.updateShouldTruncateContent();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleTruncateOnWindowResize);
    }

    handleTruncateOnWindowResize = debounce(() => {
        this.setState(
            {
                shouldTruncateContent: false,
            },
            () => {
                this.updateShouldTruncateContent();
            },
        );
    }, 250);

    updateShouldTruncateContent() {
        const { collapsedDisplayAmount } = this.props;
        const contentDivHeight = this.contentDivRef.offsetHeight;

        this.setState({
            shouldTruncateContent:
                contentDivHeight >
                constants.DISPLAY_AMOUNT_HEIGHT_MAP[collapsedDisplayAmount],
        });
    }

    _isTextContent() {
        const { children } = this.props;

        return lodashIsString(children);
    }

    _getClassNames() {
        const { collapsedDisplayAmount } = this.props;

        const { isExpanded, shouldTruncateContent } = this.state;

        const isTextContent = this._isTextContent();

        if (shouldTruncateContent) {
            return classNames(
                BASE_CSS_CLASSNAME,
                `${BASE_CSS_CLASSNAME}--${collapsedDisplayAmount}`,
                {
                    [`${BASE_CSS_CLASSNAME}--collapsed`]: !isExpanded,
                    [`${BASE_CSS_CLASSNAME}--fade`]: !isTextContent,
                    [`${BASE_CSS_CLASSNAME}--truncate`]: isTextContent,
                },
            );
        }

        return BASE_CSS_CLASSNAME;
    }

    _handleClick() {
        const { onToggleExpanded } = this.props;

        this.setState(
            ({ isExpanded: prevIsExpanded }) => ({
                isExpanded: !prevIsExpanded,
            }),
            () => {
                if (onToggleExpanded) {
                    onToggleExpanded(this.state.isExpanded);
                }
            },
        );
    }

    _setContentDivRef = (ref) => {
        this.contentDivRef = ref;
    };

    render() {
        const {
            collapsedLinkText,
            expandedLinkText,
            children,
            toggleLocation,
        } = this.props;

        const { isExpanded, shouldTruncateContent } = this.state;
        const readMoreClassNames = this._getClassNames();

        let component = null;
        const toggleButton = shouldTruncateContent ? (
            <Button
                style="anchor"
                onClick={this._handleClick.bind(this)}
                data-spec="read-more-toggle"
                __containerClassName="eds-read-more--btn"
            >
                {isExpanded ? expandedLinkText : collapsedLinkText}
            </Button>
        ) : null;
        const readMoreContent = (
            <div
                className="eds-read-more__content"
                ref={this._setContentDivRef}
            >
                {children}
            </div>
        );

        switch (toggleLocation) {
            case constants.TOGGLE_LOCATION_BOTTOM:
                component = (
                    <div className={readMoreClassNames}>
                        {readMoreContent}
                        {toggleButton}
                    </div>
                );
                break;
            // Deliberately fall through to default toggle location (top)
            case constants.TOGGLE_LOCATION_TOP:
            default:
                component = (
                    <div className={readMoreClassNames}>
                        {toggleButton}
                        {readMoreContent}
                    </div>
                );
        }

        return component;
    }
}
