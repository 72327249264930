import { SurveyAction, SurveyActionType } from '../actions/survey';
import { SurveyNamespace } from '../state/survey';

export const surveyReducer = (
    state: SurveyNamespace = {
        questionsCollection: {},
        interactiveQuestionsByAttendeeId: {},
        surveyResponsesByAttendeeId: {},
    },
    action: SurveyAction,
): SurveyNamespace => {
    switch (action.type) {
        case SurveyActionType.initSurveys:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
