import { postMessage as postMessageUtil } from '../utils/postMessage';
import { POST_MESSAGE_STATUS } from '../constants';
import { trackAbandonOrder } from './eventAnalytics';

/**
 * Regex to match checkout url
 *
 * http(s)://                           Optional
 * www.                                 Optional
 * eventbrite || evbqa(##) || evbdev    Required - One EB's domains
 * .com, .uk, .etcetera                 Required - TLD of length 2 or more
 * /e/event-1234                        Required - /e/ + Event name dash public id
 * ?params=true&more=false              Optional - Captures possible query params + end of string
 */
/*eslint-disable no-useless-escape */
const EB_URLS_REGEX = new RegExp(
    '^(https?://)?(www.)?(eventbrite|evbqa(\\d)*|evbdev).\\w{2,}/e/([\\w\\d]+-)+(\\d{4,})([?].+&?)?$',
);

const _getPostMessageActionParamsFromState = (getState) => {
    let {
        app: { widgetParentUrl, reloadAfterCheckout = false },
        event: { id: eventId, url: canonicalEventUrl, isSeries },
        series: { seriesId },
        order: { id: orderId },
    } = getState();

    // For series event, we need to pass the series id back so it matches the id in the parent url.
    if (isSeries && seriesId) {
        eventId = seriesId;
    }

    return {
        canonicalEventUrl,
        eventId,
        widgetParentUrl,
        orderId,
        reloadAfterCheckout,
    };
};

export const notifyParentStartCheckout = () => (dispatch, getState) => {
    const { eventId, widgetParentUrl } = _getPostMessageActionParamsFromState(
        getState,
    );

    return postMessageUtil(
        POST_MESSAGE_STATUS.startOrder,
        eventId,
        widgetParentUrl,
    );
};

export const notifyParentBackToTicketSelection = () => (dispatch, getState) => {
    const { eventId, widgetParentUrl } = _getPostMessageActionParamsFromState(
        getState,
    );

    return postMessageUtil(
        POST_MESSAGE_STATUS.backToTicketSelection,
        eventId,
        widgetParentUrl,
    );
};

/**
 * Set the information to call the parent window to prevent page reload that gets the user out of the
 * checkout process
 *
 * @param {Hash} action - Hash expected to be in the shape of: {disabled: true} or be empty.
 */
export const notifyParentProtectCheckout = (action = {}) => (
    dispatch,
    getState,
) => {
    const { eventId, widgetParentUrl } = _getPostMessageActionParamsFromState(
        getState,
    );

    return postMessageUtil(
        POST_MESSAGE_STATUS.protectCheckout,
        eventId,
        widgetParentUrl,
        action,
    );
};

export const triggerReloadOnParentWindow = () => {
    window.parent.location.reload();
};

export const notifyParentWidgetModalClose = (pathName = null) => (
    dispatch,
    getState,
) => {
    const {
        eventId,
        widgetParentUrl,
        reloadAfterCheckout,
    } = _getPostMessageActionParamsFromState(getState);

    if (pathName) {
        dispatch(trackAbandonOrder(pathName));
    }

    if (
        reloadAfterCheckout &&
        EB_URLS_REGEX.test(widgetParentUrl) &&
        widgetParentUrl === window.parent.location.href
    ) {
        triggerReloadOnParentWindow();
    }

    return postMessageUtil(
        POST_MESSAGE_STATUS.widgetModalClose,
        eventId,
        widgetParentUrl,
    );
};

export const notifyParentTicketQuantityChange = (changedTicketData) => (
    dispatch,
    getState,
) => {
    const { eventId, widgetParentUrl } = _getPostMessageActionParamsFromState(
        getState,
    );

    return postMessageUtil(
        POST_MESSAGE_STATUS.ticketQuantityChange,
        eventId,
        widgetParentUrl,
        changedTicketData,
    );
};

export const notifyParentOrderComplete = () => (dispatch, getState) => {
    const {
        eventId,
        widgetParentUrl,
        orderId,
    } = _getPostMessageActionParamsFromState(getState);

    return postMessageUtil(
        POST_MESSAGE_STATUS.completeOrder,
        eventId,
        widgetParentUrl,
        { orderId },
    );
};

export const notifyParentPayInComplete = () => (dispatch, getState) => {
    const { eventId, widgetParentUrl } = _getPostMessageActionParamsFromState(
        getState,
    );

    return postMessageUtil(
        POST_MESSAGE_STATUS.completeOrder,
        eventId,
        widgetParentUrl,
    );
};
