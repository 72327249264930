import { EXPIRY_FORMAT_REGEX } from './creditCardConstants';

/*
 * Get expiration month based on input value.
 *
 * This is a Date object containing the first day of the month after the user's input,
 * as cards are not considered expired until then.
 *
 * @param  {string} value  A string in the format of M/YY or MM/YY.
 * @return  {Object}  The date to check current time against
 */
const _getExpirationDate = (value) => {
    const [monthAsMM, yearAsYY] = value.split('/');

    // Apologies when this breaks in 3000. Long live EB.
    const year = parseInt(yearAsYY, 10) + 2000;
    const nextMonth = parseInt(monthAsMM, 10);

    // JavaScript uses zero-based indexing for months (0 is January). We're not correcting for it
    // because a card is not considered expired until the month after the expiration date, so
    // being a month ahead is actually the correct behavior.
    return new Date(year, nextMonth, 1);
};

/*
 * Check whether the expiration date is in the past.
 *
 * @param  {Object} date  Date object containing the expiration date
 * @return  {Boolean}  Whether the card is expired
 */
const _isExpired = (date) => date < new Date();

/*
 * Check whether the user has input a valid expiration date.
 *
 * This verifies the user's input is in the current format (after we've performed our own input
 * masking/manipulation), parses a date, and checks if it is in the past.
 *
 * @param  {string} value  A string in the format of M/YY or MM/YY
 * @return  {Boolean}  Whether the expiration date is valid
 */
export const isValidExpirationDate = (value) => {
    if (!EXPIRY_FORMAT_REGEX.test(value)) {
        return false;
    }

    const expirationDate = _getExpirationDate(value);

    return !_isExpired(expirationDate);
};
