import { push } from 'react-router-redux';

import { joinPath } from '@eb/path-utils';

import {
    BASE_URL,
    GROUP_REG_CREATE_PATH,
    GROUP_REG_TYPE_SELECTION_PATH,
    GROUP_REG_JOIN_PATH,
    TICKETS_PATH,
    UNLOCK_TICKETS_PATH,
} from '../constants';

import {
    getGroupEligibleTypeTickets,
    getIndividualEligibleTypeTickets,
} from '../selectors/tickets';

import { REGISTRATION_TYPE_INDIVIDUAL } from '../containers/groupRegistration/constants';

import { createGroup as createGroupApi } from '../api/groupRegistration';
import { transformTeamSettingsError } from '../api/transformations/teamSettings';
import { deepKeysToCamel } from '@eb/transformation-utils';

export const SAVE_GROUP_REGISTRATION_INDIVIDUAL_SETTINGS =
    'saveGroupRegistrationIndividualSettings';
export const SAVE_GROUP_REGISTRATION_TEAM_SETTINGS =
    'saveGroupRegistrationTeamSettings';
export const RESET_GROUP_REGISTRATION = 'resetGroupRegistration';
export const RESET_IS_NEWLY_CREATED_GROUP = 'resetIsNewlyCreatedGroup';

/**
 * Saves the selected team settings in the app's state
 * @param {Object} data: the selected team
 */
export const saveGroupRegistrationTeamSettings = (data) => ({
    type: SAVE_GROUP_REGISTRATION_TEAM_SETTINGS,
    payload: data,
});

/**
 * Saves the selected individual settings in the app's state
 * @param {Boolean} isIndividualSelected: weather we are in an individual flow or not
 */
export const saveGroupRegistrationIndividualSettings = (
    isIndividualSelected,
) => ({
    type: SAVE_GROUP_REGISTRATION_INDIVIDUAL_SETTINGS,
    isIndividualSelected,
});

/**
 * Resets the selected team and individual settings to null
 */
export const resetGroupRegistration = () => ({
    type: RESET_GROUP_REGISTRATION,
});

/**
 * Resets the isNewlyCreatedGroup flag in order to clear the notification
 */
export const resetIsNewlyCreatedGroup = () => ({
    type: RESET_IS_NEWLY_CREATED_GROUP,
});

/**
 * Navigates to the page after the group type selection page
 * @param {String} selectedRegType individual or group/team/...
 */
export const goToPageAfterTypeSelection = (selectedRegType) => (
    dispatch,
    getState,
) => {
    if (selectedRegType === REGISTRATION_TYPE_INDIVIDUAL) {
        const individualTickets = getIndividualEligibleTypeTickets(getState());

        if (individualTickets.length > 0) {
            return dispatch(push(joinPath(BASE_URL, TICKETS_PATH)));
        }
        return dispatch(push(joinPath(BASE_URL, UNLOCK_TICKETS_PATH)));
    }
    return dispatch(push(joinPath(BASE_URL, GROUP_REG_JOIN_PATH)));
};

/**
 * Navigates to the page before the join group page
 */
export const goToPageBeforeJoin = () => (dispatch, getState) => {
    const {
        event: { groupSettings = {} },
    } = getState();

    const path = groupSettings.hasIndividualTicket
        ? GROUP_REG_TYPE_SELECTION_PATH
        : GROUP_REG_JOIN_PATH;

    return dispatch(push(joinPath(BASE_URL, path)));
};

/**
 * Navigates to the page after the join group page
 */
export const goToGroupTicketsPage = () => (dispatch, getState) => {
    const groupTickets = getGroupEligibleTypeTickets(getState());

    if (groupTickets.length > 0) {
        return dispatch(push(joinPath(BASE_URL, TICKETS_PATH)));
    }

    return dispatch(push(joinPath(BASE_URL, UNLOCK_TICKETS_PATH)));
};

/**
 * Navigates to the group creation page
 */
export const goToGroupCreationPage = () => (dispatch) => {
    const path = GROUP_REG_CREATE_PATH;

    return dispatch(push(joinPath(BASE_URL, path)));
};

/**
 * Navigates to the group join page
 */
export const goToPageBeforeCreate = () => (dispatch) => {
    const path = GROUP_REG_JOIN_PATH;

    return dispatch(push(joinPath(BASE_URL, path)));
};

/**
 * Creates a group
 * @param {Object} groupData the group dtata to create
 */
export const createGroup = (groupData) => async (dispatch, getState) => {
    const {
        event: { id: eventId },
    } = getState();

    const { response } = await createGroupApi(eventId, groupData);

    if (response.errors) {
        const errors = transformTeamSettingsError(response.errors);

        dispatch(saveGroupRegistrationTeamSettings({ errors }));
    } else {
        const isNewlyCreatedGroup = true;
        const createdTeamSettings = deepKeysToCamel(response);

        dispatch(
            saveGroupRegistrationTeamSettings({
                ...createdTeamSettings,
                isNewlyCreatedGroup,
            }),
        );
        dispatch(goToGroupTicketsPage());
    }
};

/**
 * Extracts team setting from initial context data and saves it to store
 * @param {Object} teamSettings the team data to save
 * @param {String} teamToken the team token to save
 */
export const initializeTeamSettings = ({ teamSettings, teamToken }) => (
    dispatch,
) => {
    let teamSettingsWithToken = {
        ...teamSettings,
        token: teamToken,
    };

    teamSettingsWithToken = deepKeysToCamel(teamSettingsWithToken);

    dispatch(saveGroupRegistrationTeamSettings({ ...teamSettingsWithToken }));
};
