import * as React from 'react';

const SofortPaymentSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 48 32" {...props}>
        <path
            fill="#EC7F22"
            d="M0 3c0-1.6 1.4-3 3-3h42c1.7 0 3 1.4 3 3v26c0 1.6-1.3 3-3 3H3c-1.6 0-3-1.4-3-3V3z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFF"
            d="M28.4 14c1.4-.1-.1 3.9-1.3 4.1-1.7.2-.2-4.1 1.3-4.1zm-11.7 0c1.4-.1-.2 3.8-1.2 4.1-1.9.4-.4-4.1 1.2-4.1zm17.4-.1c.7 0 1.4 0 1 .9-.3.7-.9.8-1.6.8.1-.5.3-1.1.6-1.7zm3-1.4c.5.4.7 1 .7 1.7h1.3c-.7 1.8-1.3 3.6-2 5.4.5 0 1.2.1 1.7 0 .4-.1.3 0 .4-.5.5-1.3.9-2.5 1.4-3.8.1-.2.2-1 .4-1.1.2-.2 1.1 0 1.4 0 .4 0 1 .1 1.2-.1.3-.2.4-1.2.6-1.6h-7.1zm-4.5 0c-.9 2.4-1.7 4.7-2.6 7.1.5 0 1.8.2 2.2 0 .2-.2.4-1 .5-1.3.1-.4.3-.8.4-1.2.2.4.2.8.3 1.2.1.2.1 1.1.3 1.3.1.1.9 0 1.1 0H36c-.2-1-.4-2.2-1-3 1.5-.5 3.4-3.2 1.1-3.9-1-.4-2.4-.2-3.5-.2zm-10 0c-2 0-2.5 1.8-3 3.3-.5 1.3-.9 2.5-1.4 3.8.5 0 1.8.2 2.1 0 .1-.1.2-.6.3-.8.2-.5.4-1 .5-1.5.1-.2.1-.5.3-.5.3-.2 1 0 1.4 0 .4 0 .4 0 .6-.2.2-.3.3-.9.5-1.3H22c.4-1 .5-1.1 1.5-1.1.4 0 1.2.1 1.5-.1.3-.2.6-.7.8-.9.3-.3.6-.5.9-.7h-4.1zm-11.7 0c-1 0-1.9.1-2.5.9-.5.7-1.2 2-.6 2.7.3.4 1.9.6 1.9 1.2-.1.6-.9.5-1.3.5-1.1 0-2.3-.1-3.5 0-.5 0-.3-.1-.6.3s-.4 1-.5 1.5h3.7c1.7 0 3.5-.1 4.3-1.9.5-1.2.2-2.1-1-2.5-.3-.1-1.1-.2-.8-.7.1-.3.5-.3.8-.3.7 0 1.4.1 2 0 .9-.1 1.3-1.2 2.1-1.7h-4zm18-.1c-2.3 0-4 2.2-4.5 4.2-.5 1.9.5 3.3 2.5 3 1.8-.2 2.9-1.6 3.6-3.2.7-1.6.9-4-1.6-4zm-11.7 0c-2.3 0-4 2.2-4.5 4.2-.5 1.9.5 3.3 2.5 3 1.8-.2 2.9-1.6 3.6-3.2.7-1.6.9-4-1.6-4z"
        />
    </svg>
);

SofortPaymentSvg.displayName = 'SofortPaymentSvg';
export default SofortPaymentSvg;
