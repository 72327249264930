import { UPDATE_PAYMENT_HISTORY } from '../actions/paymentHistory';

export const paymentHistoryReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_PAYMENT_HISTORY:
            return {
                ...action.payload,
            };

        default:
            return state;
    }
};
