import { fetchV3WithTranslateServerErrors } from './base';
import { _getMissiveOrgMarketingOptInUrl, _getOrganizerUrl } from './constants';
import { EVENT_DEFAULT_ERROR_MESSAGE } from '../constants';

/**
 * How to handle errors on the event lifecycle
 */
const ERROR_SPEC = {
    default: () => EVENT_DEFAULT_ERROR_MESSAGE,
};

export const addEmailOptInToOrganization = (
    organizationId,
    email,
    firstName,
    lastName,
) =>
    fetchV3WithTranslateServerErrors(
        ERROR_SPEC,
        _getMissiveOrgMarketingOptInUrl(organizationId),
        {
            method: 'POST',
            body: JSON.stringify({
                contact_list_item: {
                    first_name: firstName,
                    last_name: lastName,
                    contact: email,
                },
                contact_list_type: 'organization_marketing_opt_in',
            }),
        },
    );

export const getOrganizer = (organizationId) =>
    fetchV3WithTranslateServerErrors(
        ERROR_SPEC,
        _getOrganizerUrl(organizationId),
    );
