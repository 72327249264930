import PropTypes from 'prop-types';
import { lazyGettext } from '@eb/lazy-gettext';
import { makeValidator } from '@eb/eds-utils';
import { isProvided, isAWholeNumber } from '@eb/eds-utils';
import { cardTypeForNumber, isValidCardNumberForCardType } from '@eb/eds-utils';
import { isValidExpirationDate } from '@eb/eds-utils';

// Constants for the CreditCardField
export const BIN_LENGTH = 6;
export const MAX_CREDIT_CARD_LENGTH = 20;
export const MAX_CARD_SECURITY_CODE_LENGTH = 4;
export const MAX_POSTAL_CODE_LENGTH = 10;
export const MAX_EXPIRATION_DATE_LENGTH = 5;

export const CREDIT_CARD_FIELD_NAME = 'creditCardNumber';
export const CARD_SECURITY_CODE_FIELD_NAME = 'cardSecurityCode';
export const CARD_POSTAL_CODE_FIELD_NAME = 'cardPostalCode';
export const CARD_EXPIRATION_DATE_FIELD_NAME = 'cardExpirationDate';

export const PAYMENT_INFO_VALUES_PROP = PropTypes.shape({
    [CREDIT_CARD_FIELD_NAME]: PropTypes.string,
    [CARD_SECURITY_CODE_FIELD_NAME]: PropTypes.string,
    [CARD_POSTAL_CODE_FIELD_NAME]: PropTypes.string,
});

// This validator object should be imported by each Redux Form that needs to
// use the PaymentInfoFields component. You can see an example of how to use
// it here:
// https://github.com/eventbrite/eventbrite_design_system/tree/master/website/docs/components/paymentInfoFields/exampleForm.js
export const PAYMENT_INFO_FIELDS_VALIDATOR = makeValidator({
    [CREDIT_CARD_FIELD_NAME]: {
        validator: (cardNumber) => {
            const cardType = cardTypeForNumber(cardNumber);

            if (cardType) {
                return isValidCardNumberForCardType(
                    cardNumber,
                    cardType.toLowerCase(),
                );
            }

            return false;
        },
        errorMessage: lazyGettext('Please enter a valid credit card number.'),
    },
    [CARD_EXPIRATION_DATE_FIELD_NAME]: {
        validator: (expirationDate) => isValidExpirationDate(expirationDate),
        errorMessage: lazyGettext('Please enter a valid expiration date.'),
    },
    [CARD_SECURITY_CODE_FIELD_NAME]: {
        // Our number validators don't work on strings, so convert to float first
        validator: (value) =>
            isProvided(value) && isAWholeNumber(parseFloat(value)),
        errorMessage: lazyGettext('Please enter a valid security code.'),
    },
    [CARD_POSTAL_CODE_FIELD_NAME]: {
        validator: isProvided,
        errorMessage: lazyGettext('Please enter a valid postal code.'),
    },
});
