import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from '@eb/eds-button';
import { STYLE_NONE } from '@eb/eds-button';
import gettext from '@eb/gettext';

import './ImagePreviewModalBody.scss';

export default class ImagePreviewModalBody extends Component {
    state = {
        isImageMaximized: false,
    };

    _handleToggleImageMaximizedState = () => {
        this.setState((state) => ({
            isImageMaximized: !state.isImageMaximized,
        }));
    };

    static propTypes = {
        imageUrl: PropTypes.string.isRequired,
    };

    render() {
        const { isImageMaximized } = this.state;
        const { imageUrl } = this.props;

        return (
            <div
                className={classNames(
                    'addon-form__image-preview',
                    isImageMaximized
                        ? 'addon-form__image-preview--full'
                        : 'addon-form__image-preview--small',
                )}
            >
                <Button
                    style={STYLE_NONE}
                    onClick={this._handleToggleImageMaximizedState}
                >
                    {isImageMaximized ? (
                        <img
                            data-spec="addon-form__full-image"
                            src={imageUrl}
                            alt={gettext('Full Size')}
                        />
                    ) : (
                        <div
                            data-spec="addon-form__contained-image"
                            className="addon-form__image-container"
                            style={{ backgroundImage: `url(${imageUrl})` }}
                        />
                    )}
                </Button>
            </div>
        );
    }
}
