import * as React from 'react';

const CartChunkySvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 24 24" {...props}>
        <path d="M7.02 14l12.95-1 2.21-8H8.99v2h10.57l-1.14 4.12-11.4.87V2H2v2h3.02v13h15.03v-2.01H7.02V14z" />
        <circle cx={7} cy={20} r={2} />
        <circle cx={18} cy={20} r={2} />
    </svg>
);

CartChunkySvg.displayName = 'CartChunkySvg';
export default CartChunkySvg;
