import React from 'react';
import { Button } from '@eb/eds-button';
import { NotificationBar } from '@eb/eds-notification';
import { TYPE_ERROR } from '@eb/eds-notification';
import gettext from '@eb/gettext';

const DEFAULT_ERROR_MESSAGE = gettext(
    'Oops... something is wrong at this moment',
);

const GenericErrorMessage = ({ error }) => {
    const _handleRetryClick = () => location.reload();
    const errorMessage = error || DEFAULT_ERROR_MESSAGE;

    return (
        <div>
            <NotificationBar type={TYPE_ERROR}>{errorMessage}</NotificationBar>
            <div className="eds-g-grid">
                <div className="eds-g-cell eds-g-cell-12-12">
                    <div className="eds-l-pad-all-4 eds-align--center">
                        <Button onClick={_handleRetryClick}>
                            {gettext('Retry')}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GenericErrorMessage;
