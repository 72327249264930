import React from 'react';
import PropTypes from 'prop-types';

import Status from './Status';
import { ModalBody } from '@eb/eds-modal';
import LanguageAndBrandContainer from '../LanguageAndBrandContainer';

import { ABANDON_ORDER_STATUSES } from './constants';
import { BACK_TO_SERIES_EVENTS_TITLE } from '../../constants';

import { resetFocusToInitialElement } from '../../utils/accessibility';
import { FOCUSABLE_ELEMENTS } from '../../constants/focusableElements';

import { ArrowLeftChunky } from '@eb/eds-iconography';

export default class StatusPage extends React.PureComponent {
    static propTypes = {
        statusType: PropTypes.string.isRequired,
        toCheckout: PropTypes.func.isRequired,
        toLogin: PropTypes.func.isRequired,
        toTicketSelection: PropTypes.func.isRequired,
        toDeliveryMethod: PropTypes.func,
        toPickASeat: PropTypes.func,
        toReservedTicketConfirmation: PropTypes.func,
        closeModal: PropTypes.func,
        shouldShowBackToSeriesLink: PropTypes.bool,
        onBackToSeries: PropTypes.func,
        toPaymentSelection: PropTypes.func,
    };

    _getHeaderProps() {
        const {
            eventTitle,
            eventDate,
            onBackToSeries,
            shouldShowBackToSeriesLink,
        } = this.props;
        let headerProps;

        // For repeating event, can go back to the parent series page.
        if (shouldShowBackToSeriesLink) {
            headerProps = {
                title: eventTitle,
                secondaryTitle: eventDate,
                headerActionIconType: <ArrowLeftChunky />,
                headerActionTitle: BACK_TO_SERIES_EVENTS_TITLE,
                onHeaderAction: onBackToSeries,
            };
        }

        return headerProps;
    }

    focusRef = React.createRef();

    componentDidMount() {
        resetFocusToInitialElement({
            rootEl: this.focusRef.current,
            focusableEls: FOCUSABLE_ELEMENTS,
        });
    }

    render() {
        const {
            statusType,
            toCheckout,
            toDeliveryMethod,
            toLogin,
            toPickASeat,
            toReservedTicketConfirmation,
            toTicketSelection,
            toPaymentSelection,
            closeModalActions,
            closeModalOnNativeActions,
            eventSalesStatus,
            isNativeApp,
            featureFlags,
        } = this.props;

        let languageAndBrand = null;

        // Language Selector should only be shown on non-Abandon status pages
        // because it currently triggers a reload
        if (!ABANDON_ORDER_STATUSES.includes(statusType)) {
            languageAndBrand = <LanguageAndBrandContainer isVertical={true} />;
        }

        return (
            <ModalBody {...this._getHeaderProps()}>
                <div className="g-vert-full-height eds-text--center">
                    <div
                        className="eds-align--center eds-l-pad-all-4"
                        ref={this.focusRef}
                    >
                        <Status
                            statusType={statusType}
                            toCheckout={toCheckout}
                            toDeliveryMethod={toDeliveryMethod}
                            toLogin={toLogin}
                            toPickASeat={toPickASeat}
                            toReservedTicketConfirmation={
                                toReservedTicketConfirmation
                            }
                            toTicketSelection={toTicketSelection}
                            toPaymentSelection={toPaymentSelection}
                            closeModalActions={closeModalActions}
                            closeModalOnNativeActions={
                                closeModalOnNativeActions
                            }
                            eventSalesStatus={eventSalesStatus}
                            featureFlags={featureFlags}
                            isNativeApp={isNativeApp}
                        />
                    </div>
                    <div>{languageAndBrand}</div>
                </div>
            </ModalBody>
        );
    }
}
