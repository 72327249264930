import { VirtualIncentive } from '../models/user';

export const getSelectedVirtualIncentivesAggregatedTotal = (
    selectedVirtualIncentives: Array<VirtualIncentive>,
) =>
    selectedVirtualIncentives
        .map((incentive) => incentive.balance.value)
        .reduce((acc, curr) => acc + curr, 0);

export const getTotalDiscountingVirtualIncentives = (
    totalValue: number,
    selectedVirtualIncentives: Array<VirtualIncentive>,
) => {
    const virtualIncentivesTotalValue = getSelectedVirtualIncentivesAggregatedTotal(
        selectedVirtualIncentives,
    );

    if (virtualIncentivesTotalValue >= totalValue) {
        return 0;
    }

    return totalValue - virtualIncentivesTotalValue;
};
