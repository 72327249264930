import PropTypes from 'prop-types';
import { translationPropType } from '@eb/i18n';

export const REGIONS_BY_COUNTRY_PROP = PropTypes.shape({
    isLoading: PropTypes.bool,
    content: PropTypes.objectOf(
        PropTypes.arrayOf(
            PropTypes.shape({
                code: PropTypes.string.isRequired,
                label: translationPropType.isRequired,
            }),
        ),
    ),
});

export const COUNTRIES_PROP = PropTypes.shape({
    isLoading: PropTypes.bool,
    content: PropTypes.arrayOf(
        PropTypes.shape({
            code: PropTypes.string.isRequired,
            label: translationPropType.isRequired,
            zip_required: PropTypes.bool,
        }),
    ),
});
