// XXX: These are copied from core. Once this lands, delete from core

import isEmpty from 'lodash/isEmpty';

const INTEGER_REGEX = /^(-{1})?[0-9]+(\.0*)?(?:e[+-][0-9]+)?$/;
const NUMBER_REGEX = /^(?:[0-9]+|[0-9]*\.[0-9]+)(?:e[+-][0-9]+)?$/;

/**
 * Generator: Makes a matchesRegex validator.
 * Validator: Tests that the value satisfies the `regexPattern`s `test` method.
 *
 * @param regexPattern
 * @returns {function}
 */
export const makeMatchesRegex = (regexPattern) => (value) =>
    !value || regexPattern.test(`${value}`);

/**
 * Validator: Tests that the value is present and not empty. Useful for `required`.
 *
 * @param value
 * @returns {boolean}
 */
export const isProvided = (value) => !isEmpty(value);

/**
 * Validator: Tests that the value is an integer
 *
 * @param value
 * @returns {boolean}
 */
export const isAnInteger = makeMatchesRegex(INTEGER_REGEX);

/**
 * Validator: Tests that the value is an number
 *
 * @param value
 * @returns {boolean}
 */
export const isANumber = makeMatchesRegex(NUMBER_REGEX);

/**
 * Validator: Tests that the value is a whole number
 *
 * @param value
 * @returns {boolean}
 */
export const isAWholeNumber = (value) =>
    isAnInteger(value) && parseInt(value, 10) >= 0;
