// External libraries
import range from 'lodash/range';
import { unformat } from '@eb/intl';

// Own libraries
import { getSelectPropValues } from '@eb/eds-select';

export const getSelectValues = (max, min, includeZero) => {
    let values = range(min, max + 1);

    if (includeZero && min > 0) {
        values = [0, ...values];
    }

    return getSelectPropValues(values);
};

export const getDonationChangeActionPayload = (
    ticketId,
    amount,
    currencyFormat,
    variantId,
) => {
    const payload = {
        id: ticketId,
        amount: unformat(amount, currencyFormat),
    };

    if (variantId) {
        payload.variantId = variantId;
    }

    return payload;
};
