import * as React from 'react';

const EyeOffChunkySvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 24 24" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.5 9.7L9.8 11c0 .2-.1.4-.1.5 0 1.4 1.1 2.5 2.5 2.5.2 0 .4 0 .5-.1l1.3 1.3c-.6.3-1.2.4-1.9.4-2.3 0-4.2-1.9-4.2-4.2.2-.5.3-1.1.6-1.7zM3.8 5.1l2 1.9.4.4C4.8 8.5 3.7 9.9 3 11.6c1.5 3.7 5.1 6.3 9.3 6.3 1.3 0 2.6-.3 3.7-.7l.4.4 2.5 2.5L20 19 4.9 4 3.8 5.1zM12.1 9.1l2.7 2.7v-.1c0-1.4-1.1-2.5-2.5-2.5l-.2-.1z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.3 7.4c2.3 0 4.2 1.9 4.2 4.2 0 .5-.1 1.1-.3 1.5l2.5 2.5c1.3-1.1 2.3-2.4 2.9-4-1.5-3.7-5.1-6.3-9.3-6.3-1.2 0-2.3.2-3.4.6l1.8 1.8c.5-.2 1-.3 1.6-.3z"
        />
    </svg>
);

EyeOffChunkySvg.displayName = 'EyeOffChunkySvg';
export default EyeOffChunkySvg;
